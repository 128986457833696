import { Model } from 'libx'
import uniqueId from 'lodash/uniqueId'
import { action, computed, observable } from 'mobx'
import { required } from 'validx'
import { snapshotable } from '../data/serialization'
import { addGenericErrorForProperty, receiveErrors } from '../data/serverErrors'
import { validatable } from '../mixins/validation'
import MappingEditor from './MappingEditor'

@validatable({ liveValidation: true })
@snapshotable
export class TemplateEditor extends Model {
  cid = uniqueId('template-')

  @observable id = ''

  /**
   * Template name.
   */
  @observable
  name = 'Template'

  /**
   * Template mappings.
   */
  @observable
  mappings = []

  @observable
  selectedMappingEditor = null

  rules = {
    name: [required('Name is required')],
    mappings: [
      () =>
        this.mappings.map((m) => m.validateAll()).every((x) => x) ||
        'There are invalid mappings.',
    ],
  }

  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  constructor(attrs, opts) {
    super()
    this.legendEditor = opts.legendEditor
    this.templateDataSources = [
      {
        id: 'CLIENT_NAME',
        valueType: { type: 'String' },
      },
      {
        id: 'CLIENT_NUMBER',
        valueType: { type: 'String' },
      },
      {
        id: 'TOTAL_ASSETS',
        valueType: { type: 'Number' },
      },
      {
        id: 'GROSS_SALES',
        valueType: { type: 'Number' },
      },
      {
        id: 'PAGE_COUNT',
        valueType: { type: 'Number' },
      },
      {
        id: 'FORMS',
        valueType: { type: 'List', itemType: { type: 'String' } },
      },
      {
        id: 'STATES',
        valueType: { type: 'List', itemType: { type: 'String' } },
      },
      {
        id: 'K1',
        valueType: { type: 'List', itemType: { type: 'String' } },
      },
      {
        id: 'FLOW_THROUGH_K1',
        valueType: { type: 'List', itemType: { type: 'String' } },
      },
      {
        id: 'RENTALS',
        valueType: { type: 'List', itemType: { type: 'String' } },
      },
    ]
    this.id = this.legendEditor.newId(this.cid, 'TEMPLATE')
    this.set(attrs, opts)
  }

  /**
   * Updates the mappings so we have one per question.
   *
   * @param questions All the intake questions.
   */
  @action
  updateMappings(questions) {
    // for each question add a mapping
    const allQuestions = relevantQuestions(questions)

    const oldMappings = new Map(this.mappings.map((m) => [m.questionId, m]))
    this.mappings = allQuestions.map((question) => {
      const existingMapping = oldMappings.get(question.id)
      if (existingMapping) {
        return existingMapping
      }

      return MappingEditor.create(
        {
          kind: { type: 'Expr', expr: '' },
          questionEditor: question,
        },
        this._templateOpts()
      )
    })

    this.validateAll()
  }

  parse({ mappings, template_data_sources, ...rest }) {
    const allQuestions = relevantQuestions(this.legendEditor.questions)

    const newMappings = mappings
      .map((m) => {
        const questionEditor = allQuestions.find((q) => q.id === m.questionId)
        if (!questionEditor) {
          return null
        }
        return MappingEditor.fromJS(
          {
            ...m,
            questionEditor,
          },
          this._templateOpts()
        )
      })
      .filter(Boolean)

    this.mappings.replace(newMappings)

    return {
      // this will allow new old templates to be upgraded with the new data sources
      // templateDataSources: template_data_sources,
      ...rest,
    }
  }

  toJSON() {
    return {
      id: this.id,
      mappings: this.mappings.map((m) => m.toJSON()),
      template_data_sources: this.templateDataSources,
      name: this.name,
    }
  }

  /**
   * Starts editing a mapping.
   *
   * @param mapping
   */
  @action.bound
  edit(mapping) {
    this.validateAll()
    this.selectedMappingEditor = mapping
  }

  /**
   * Receives errors and populates the validation context with them.
   *
   * @param {*} descriptor
   */
  receiveErrors(descriptor) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'mappings',
      'There are invalid mappings.'
    )
    receiveErrors(this, descriptor.inner)
  }

  _templateOpts() {
    return {
      legendEditor: this.legendEditor,
      templateEditor: this,
    }
  }
}

TemplateEditor.create = function create(attrs, opts) {
  return new TemplateEditor(attrs, opts)
}

TemplateEditor.fromJS = function fromJS(attrs, opts) {
  return TemplateEditor.create(
    { ...attrs },
    {
      ...opts,
      parse: true,
      stripUndefined: true,
    }
  )
}

function relevantQuestions(questions) {
  return questions.filter(
    (q) =>
      q.type !== 'Breaker' &&
      q.type !== 'Documents' &&
      !q.constructor.isMetaElement
  )
}
