import React from 'react'

import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet-async'

import Icon from 'components/Icon'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import ContentLoading from 'components/ContentLoading'
import EmptyState from 'components/EmptyState'
import ScreenProtector from 'iam/containers/ScreenProtector'
import Searchbar from 'components/Searchbar'
import CircularIconButton from 'components/CircularIconButton'
// import { translate as T } from 'utils/arrayUtil'

import styles from './Coupon.m.sass'
import CouponList from './CouponList'
import CouponFacetedSearch from './CouponFacetedSearch'
import CouponSaveModal from './CouponSaveModal'
import UserCouponListModal from './UserCouponListModal'

@observer
class CouponsScreen extends React.Component {
  constructor(props) {
    super(props)

    this.couponsScreenStore = this.props.rootStore.couponsScreenStore
  }

  componentDidMount() {
    this.couponsScreenStore.activate()
  }

  renderEmpty() {
    return (
      <EmptyState className={styles.emptyState} title="Coupons..">
        Couldn't find any coupons that matches your query.
      </EmptyState>
    )
  }

  renderCoupons() {
    const isEmpty = this.couponsScreenStore.items.length === 0

    const coupons = isEmpty ? (
      this.renderEmpty()
    ) : this.couponsScreenStore.scrollProvider.fetch.pending ? (
      this.renderEmpty()
    ) : (
      <CouponList
        coupons={this.couponsScreenStore.items}
        store={this.couponsScreenStore}
      />
    )

    return (
      <>
        <div className={styles.couponsContainer}>
          {coupons}
          <CouponFacetedSearch store={this.couponsScreenStore} />
        </div>
        <InfiniteScrollZone
          scrollProvider={this.couponsScreenStore.scrollProvider}
        >
          {({ isDone }) => (isDone ? null : <ContentLoading smaller />)}
        </InfiniteScrollZone>
      </>
    )
  }

  render() {
    return (
      <ScreenProtector allOf={['ADMIN_COUPONS_MANAGE']}>
        <CouponSaveModal state={this.couponsScreenStore.couponSaveModalState} />
        <UserCouponListModal
          state={this.couponsScreenStore.userCouponListModalState}
        />

        <div className="coupon-screen">
          <Helmet title="Coupons" />
          <div className="list-area">
            <Searchbar
              placeholder={'Search for coupons'}
              value={this.couponsScreenStore.searchText}
              onChange={this.couponsScreenStore.changeSearchText}
              data-test="job-search-field"
            >
              <CircularIconButton
                small
                info
                style={{ marginRight: '10px' }}
                onClick={this.couponsScreenStore.newCoupon.bind(this)}
              >
                <Icon name="plus" size={16} />
              </CircularIconButton>

              <CircularIconButton
                small
                info
                style={{ marginRight: '10px' }}
                onClick={this.couponsScreenStore.toggleFilter}
              >
                <Icon name="filter" size={16} />
              </CircularIconButton>
            </Searchbar>
            {this.renderCoupons()}
          </div>
        </div>
      </ScreenProtector>
    )
  }
}

export default CouponsScreen
