import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import { FormGroup } from 'components/Form'
import PermissionsEditor from 'components/PermissionsEditor'
import './EditUserPermissionsModal.sass'

@observer
export default class EditUserPermissionsModal extends React.Component {
  render() {
    const store = this.props.store
    return (
      <Modal
        onRequestClose={() => store.hide()}
        isOpened={store.showing}
        position="top"
      >
        <Dialog className="user-permissions-editor">
          <DialogTitle>Edit user permissions</DialogTitle>
          <DialogContent>
            <FormGroup label="Permissions">
              <PermissionsEditor
                readonly={this.props.readonly}
                value={store.permissions}
                disabled={store.save.pending}
                availablePermissions={store.availablePermissions}
                onChange={store.changePermissions.bind(store)}
              />
            </FormGroup>
          </DialogContent>
          <DialogFooter>
            <DialogButton
              disabled={store.save.pending}
              onClick={() => store.hide()}
            >
              {this.props.readonly ? 'Close' : 'Cancel'}
            </DialogButton>
            {!this.props.readonly && (
              <DialogButton
                disabled={store.save.pending}
                primary
                onClick={() => store.save()}
                data-test="save-user-permissions-button"
              >
                {store.save.pending ? 'Saving' : 'Save'}
              </DialogButton>
            )}
          </DialogFooter>
        </Dialog>
      </Modal>
    )
  }
}
