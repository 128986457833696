import ContentError from 'components/ContentError'
import ContentLoading from 'components/ContentLoading'
import {
  Dialog,
  DialogButton,
  DialogContent,
  DialogFooter,
} from 'components/Dialog'
import { Form, Input } from 'components/Form'
import Modal from 'components/Modal'
import { SelectContainer } from 'legend-builder/components/ContainerControls'
import {
  CheckboxControl,
  FormGroup,
  Help,
  InputControl,
  Label,
} from 'legend-builder/components/Controls'
import { observer } from 'mobx-react'
import React from 'react'
import styles from './CreateUserModal.m.sass'
import NumericInput from 'components/NumericInput'
import SkillSelector from 'components/SkillSelector'
import TeamSelector from 'components/TeamSelector'

@observer
export default class CreateUserModal extends React.Component {
  render() {
    const { state } = this.props
    return (
      <Modal
        position="top"
        isOpened={state.showing}
        onRequestClose={state.close}
      >
        <CreateDialog state={state} />
      </Modal>
    )
  }
}

const CreateDialog = observer(function CreateDialog({ state }) {
  return (
    <Dialog>
      {state.activate.match({
        pending: () => (
          <DialogContent>
            <ContentLoading />
          </DialogContent>
        ),
        rejected: (err) => (
          <DialogContent>
            <ContentError error={err} />
          </DialogContent>
        ),
        resolved: () => (
          <DialogContent>
            <div className={styles.intro}>
              <p className={styles.title}>
                Manually add users to your workspace
              </p>
              <p className={styles.description}>
                If they are already registered with Worklayer they will be added
                to this workspace. A new user will be created otherwise and they
                will have to reset their password.
              </p>
            </div>
            <div className={styles.form}>
              <Form>
                <div className={styles.twoColumns}>
                  <Input
                    model={state}
                    field="given_name"
                    label="First Name"
                    help=""
                    data-test="user-first-name-input"
                  />
                  <Input
                    model={state}
                    field="family_name"
                    label="Last Name"
                    help=""
                    data-test="user-last-name-input"
                  />
                </div>
                <div className={styles.twoColumns}>
                  <Input
                    model={state}
                    field="email"
                    label="Email"
                    help=""
                    data-test="user-email-input"
                  />
                  <Input
                    model={state}
                    field="phone"
                    label="Phone"
                    help=""
                    data-test="user-phone-input"
                  />
                </div>

                <SelectContainer
                  label="Role"
                  field="roleId"
                  model={state}
                  help="The role the user is given."
                  items={state.roles.map((role) => [role.id, role.name])}
                />

                <FormGroup label="Skills" help="The skills the user is given.">
                  <SkillSelector
                    isMulti
                    workspaceId={state.sessionStore.workspace.id}
                    skillStore={state.skillStore}
                    onChange={state.selectSkills}
                    value={state.skills}
                  />
                </FormGroup>

                <FormGroup label="Team" help="The team the user is added to.">
                  <TeamSelector
                    workspaceId={state.sessionStore.workspace.id}
                    teamStore={state.teamStore}
                    onChange={state.selectTeam}
                    value={state.team}
                  />
                </FormGroup>
                {state.team && state.team?.id !== '-1' && (
                  <SelectContainer
                    label="Team Role"
                    field="teamRoleId"
                    model={state}
                    help="The role the user is given within the team."
                    items={state.teamRoles.map((teamRole) => [
                      teamRole.id,
                      teamRole.name,
                    ])}
                  />
                )}
                <SelectContainer
                  model={state}
                  field="completeRegistrationEmail"
                  label="Email"
                  help="Send registration email to user with link to set password"
                  items={[
                    [true, 'Send'],
                    [false, 'Do not send'],
                  ]}
                />
                {state.completeRegistrationEmail && (
                  <SelectContainer
                    label="Portal"
                    field="portal"
                    model={state}
                    help="Which portal should the user be sent to?"
                    items={[
                      ['CustomerPortal', 'Customer Portal'],
                      ['Portal', 'Work Portal'],
                    ]}
                  />
                )}
                {state.memberMetaDataFields.map((x) =>
                  memberMetadataField(x, state)
                )}
              </Form>
            </div>
          </DialogContent>
        ),
      })}
      <DialogFooter bordered>
        <DialogButton onClick={state.close}>Close</DialogButton>
        <DialogButton
          type="primary"
          onClick={state.createUser}
          disabled={state.activate.pending || state.createUser.pending}
          loading={state.createUser.pending}
          data-test="create-user"
        >
          Create
        </DialogButton>
      </DialogFooter>
    </Dialog>
  )
})

function memberMetadataField(field, store) {
  const value = store.memberMetadata[field.id]
  switch (field.type) {
    case 'NUMERIC':
      return (
        <div key={field.id}>
          <Label>{field.title}</Label>
          <NumericInput
            precision={2}
            min={0}
            step={1}
            value={value}
            disabled={!field.permissions.includes('ADMIN_WRITE')}
            onChange={(v) => store.onMetadataChange(field.id, v)}
          >
            {(inputProps, actions) => (
              <InputControl
                onKeyPress={actions.keyPressPreventNonNumeric}
                type="text"
                {...inputProps}
              />
            )}
          </NumericInput>
          <Help>{field.description}</Help>
        </div>
      )
    case 'TEXT':
      return (
        <div key={field.id}>
          <Label>{field.title}</Label>
          <InputControl
            type="text"
            value={value || ''}
            disabled={!field.permissions.includes('ADMIN_WRITE')}
            onChange={(e) => store.onMetadataChange(field.id, e.target.value)}
          />
          <Help>{field.description}</Help>
        </div>
      )
    case 'BOOL':
      return (
        <div key={field.id} className={styles.checkboxContainer}>
          <Label>{field.title}</Label>
          <CheckboxControl
            checked={value}
            disabled={!field.permissions.includes('ADMIN_WRITE')}
            onChange={(e) => store.onMetadataChange(field.id, !value)}
          />
          <Help>{field.description}</Help>
        </div>
      )
    default:
      return <></>
  }
}
