import { observable, computed, action, reaction } from 'mobx'
import orderBy from 'lodash/orderBy'
import { validatable, cascadeFieldValidation } from '../mixins/validation'
import { Types } from '../data/Type'
import identity from 'lodash/identity'
import { required } from 'validx'
import { snapshotable } from '../data/serialization'
import { asapScheduler, Subject } from 'rxjs'
import { observeOn, share } from 'rxjs/operators'

/**
 * Symbol Connector.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class SymbolConnector {
  /**
   * Used to trigger focus.
   */
  focusRequestSubject = new Subject()

  /**
   * Used to focus the input externally.
   */
  focus$ = this.focusRequestSubject.pipe(observeOn(asapScheduler), share())

  /**
   * Currently selected symbol ID.
   */
  @observable
  symbolId = null

  /**
   * Search text.
   */
  @observable
  searchText = ''

  /**
   * Initializes the connector.
   *
   * @param {LegendEditorViewStore} opts.legendEditor the legend editor instance
   * @param {Array<Function>} opts.predicates an array of predicates used to filter the entity list
   * @param {any} opts.context used to ask the editor what entities are available
   * @param {boolean} opts.required whether or not the symbol is required
   */
  constructor({ legendEditor, predicates, context, required }) {
    this.legendEditor = legendEditor
    this.required = required
    this.context = context
    this.predicates = predicates || [identity]
    this.focus$.subscribe()
    cascadeFieldValidation(this, {
      symbol: ['symbol'],
    })
  }

  /**
   * Validation rules.
   */
  get rules() {
    return {
      symbol: [this.required && required('Please select a symbol.')],
    }
  }

  /**
   * The symbol that the `symbolId` is referring to.
   */
  @computed
  get symbol() {
    if (!this.symbolId) {
      return null
    }

    const found = this.availableEntityDescriptors.find(
      (x) => x.entity.id === this.symbolId
    )

    if (!found) {
      return null
    }

    return found.entity
  }

  /**
   * The available entity descriptors for `this.context`.
   */
  @computed
  get availableEntityDescriptors() {
    return this.legendEditor
      .getAvailableEntityDescriptorsFor(this.context)
      .filter((e) => !e.hidden && this.predicates.every((p) => p(e)))
  }

  /**
   * Gets the longest ID in the available descriptors.
   * Used by the view to calculate input length.
   *
   * @returns {number}
   */
  @computed
  get longestId() {
    return this.availableEntityDescriptors.reduce((prev, entityDescriptor) => {
      const id = entityDescriptor.entity.id
      return id.length > prev ? id.length : prev
    }, 0)
  }

  /**
   * The value type of the selected symbol.
   */
  @computed
  get valueType() {
    const symbol = this.symbol
    if (!symbol || !symbol.valueType) {
      return Types.nothing
    }

    return symbol.valueType
  }

  /**
   * Filtered descriptors for the UI.
   */
  @computed
  get filteredEntityDescriptors() {
    const descriptors = orderBy(this.availableEntityDescriptors, [
      (x) => x.entity.id,
    ])
    if (!this.searchText) {
      return descriptors
    }
    return descriptors.filter((x) =>
      x.entity.id.toLowerCase().includes(this.searchText.toLowerCase())
    )
  }

  /**
   * Changes the search text.
   *
   * @param {string} value
   */
  @action
  setSearchText(value) {
    this.searchText = value ?? ''
  }

  /**
   * Clears the search text.
   */
  @action
  clearSearch() {
    this.searchText = ''
  }

  /**
   * Sets the symbol.
   *
   * @param {string | any} symbol
   */
  @action
  setSymbol(symbol) {
    this.symbolId = symbol ? symbol.id : null
    this.searchText = symbol ? symbol.id : ''
  }

  /**
   * Requests focus.
   */
  focus() {
    this.focusRequestSubject.next()
  }

  /**
   * Clears the symbol and search text.
   */
  @action
  clear() {
    this.symbolId = null
    this.searchText = ''
  }

  /**
   * Hydrates the symbol.
   *
   * @param {string} symbol
   */
  hydrate(symbol) {
    this.symbolId = symbol || null
    this.searchText = symbol || ''
  }

  /**
   * Sets up error bubbling.
   *
   * @param {*} target
   * @param {*} field
   */
  bubbleErrorsTo(target, field) {
    // Validate field on target when the symbol props change.
    return reaction(
      () => this.symbol && [this.symbol.id, this.symbol.valueType],
      () => target.validate([field]),
      {
        fireImmediately: !!this.symbolId,
      }
    )
  }

  /**
   * Converts this to a JSON representation, which is just the symbol.
   */
  toJSON() {
    return this.symbol ? this.symbol.id : null
  }
}
