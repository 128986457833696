import QuestionEditor from '../../QuestionEditor'
import { snapshotable, serializable } from 'legend-builder/data/serialization'
import { cascadeFieldValidation } from 'legend-builder/mixins/validation'
import { Types } from 'legend-builder/data/Type'
import { observable, computed } from 'mobx'
import RynoEditor from 'legend-builder/ryno-editor/RynoEditor'
import { func } from 'validx'

@snapshotable
export default class VariableQuestionEditor extends QuestionEditor {
  static friendlyType = 'Variable'
  static type = 'Variable'
  static viewOrder = 2
  static isMetaElement = true
  static iconColor = '#763EF4'

  /**
   * Variable label.
   */
  @observable
  @serializable
  label = ''

  /**
   * The initial variable value expression.
   */
  @serializable
  expr

  /**
   * The Variable value type.
   */
  @observable.ref
  variableValueType = Types.number

  rules = {
    expr: [
      func(
        (ctx) => (ctx.value.value ? ctx.value.analyze() : true),
        'There are formula errors.'
      ),
    ],
  }

  constructor(attrs, opts) {
    super(null, { ...opts, parse: false })
    this.expr = new RynoEditor({
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => this.variableValueType,
    })
    this.set(attrs, opts)
    cascadeFieldValidation(this, {
      variableValueType: ['expr'],
    })
  }

  /**
   * Tells the shell what type of output this element has.
   */
  @computed
  get valueType() {
    return this.variableValueType
  }

  /**
   * The symbol table to use for the Ryno Semantic Analyzer.
   */
  @computed
  get symbolTable() {
    return this.legendEditor.getSymbolTableFor(this)
  }

  parse({ valueType, expr, ...attrs }, opts) {
    this.expr.setValue(expr)
    return {
      variableValueType: valueType ? Types.fromJS(valueType) : Types.number,
      ...super.parse(attrs, opts),
    }
  }

  toJSON() {
    return {
      valueType: Types.toJS(this.variableValueType),
      ...super.toJSON(...arguments),
    }
  }

  receiveErrors(descriptor = {}) {
    super.receiveErrors(descriptor)
  }
}
