import { snapshotable } from 'legend-builder/data/serialization'
import { validatable } from 'legend-builder/mixins/validation'
import { action, computed, observable, reaction } from 'mobx'
import { addGenericErrorForProperty, receiveErrors } from '../data/serverErrors'
import { TemplateEditor } from './TemplateEditor'

/**
 * Questions builder.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class TemplatesBuilder {
  @observable
  templates = []

  @observable
  selectedTemplateEditor = null

  disposeUpdateReaction = null

  rules = {
    templates: [
      () =>
        this.templates.map((m) => m.validateAll()).every((x) => x) ||
        'There are invalid templates.',
    ],
  }

  /**
   * Constructs a templates builder.
   *
   * @param {LegendEditorViewStore} opts.legendEditor
   * The legend editor.
   */
  constructor({ legendEditor }) {
    this.legendEditor = legendEditor
    // When the questions update, update the mappings of all templates.
    this.disposeUpdateReaction = reaction(
      () => this.legendEditor.questions,
      action((questions) =>
        this.templates.forEach((x) => x.updateMappings(questions))
      )
    )
  }

  dispose() {
    this.disposeUpdateReaction()
  }

  @computed
  get entityDescriptors() {
    const result = []
    for (let i = 0; i < this.templates.length; i++) {
      const template = this.templates[i]
      result.push({ type: 'Template', entity: template })
      for (let j = 0; j < template.mappings.length; j++) {
        const mapping = template.mappings[j]
        result.push({ type: 'Mapping', entity: mapping })
      }
    }
    return result
  }

  /**
   * Whether the editor is disabled.
   *
   * @returns {*}
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Adds a new template.
   */
  @action.bound
  add() {
    const template = TemplateEditor.create({}, this._templateOpts())
    template.updateMappings(this.legendEditor.questions)
    this.templates.push(template)
    this.edit(template)
  }

  /**
   * Starts editing the plan.
   *
   * @param plan
   */
  @action.bound
  edit(template) {
    this.validateAll()
    this.selectedTemplateEditor = template
  }

  /**
   * Removes the specified plan.
   *
   * @param plan
   */
  @action.bound
  remove() {
    if (!this.selectedTemplateEditor) {
      return
    }
    this.templates.remove(this.selectedTemplateEditor)
    this.selectedTemplateEditor = null
    this.validateAll()
  }

  /**
   * Hydrates the builder with questions.
   * @param {Legend} legend
   */
  hydrate(templates = []) {
    this.templates.replace(
      templates.map((p) => TemplateEditor.fromJS(p, this._templateOpts()))
    )
    this.validateAll()
  }

  /**
   * Returns a plain JS object representing the builder state.
   */
  toJSON() {
    return this.templates.map((x) => x.toJSON())
  }

  /**
   * Delivers errors to each question.
   *
   * @param {*} descriptor
   */
  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'templates',
      'There are invalid templates.'
    )
    receiveErrors(this.templates, descriptor.inner)
  }

  _templateOpts() {
    return {
      legendEditor: this.legendEditor,
    }
  }
}
