import { action, observable } from 'mobx'

export default class CouponSaveModalState {
  @observable
  showing = false

  @observable
  coupon

  constructor(flashMessageStore, couponsScreenStore) {
    this.flashMessageStore = flashMessageStore
    this.couponsScreenStore = couponsScreenStore
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.coupon.cancelEditing()
    this.showing = false
  }

  @action.bound
  async save() {
    const isNew = this.coupon.isNew
    const message = this.flashMessageStore.create({
      message: 'Saving coupon...',
      inProgress: true,
    })

    try {
      if (await this.coupon.save()) {
        message.done('Coupon saved!').autoDismiss()

        this.showing = false

        if (isNew) {
          await this.couponsScreenStore.findCoupons()
        }
      } else {
        message.dismiss()
      }
    } catch (e) {
      message.failed('Something went wrong while saving. ')

      if (e.isAxiosError) {
        message.failed(e.response.data.message)
      }
    }
  }
}
