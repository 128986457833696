import { Store } from 'libx'
import { task } from 'mobx-task'
import { computed, action, observable } from 'mobx'
import ConfirmDialogState from 'components/ConfirmDialog/ConfirmDialogState'
import InfiniteScrollProvider from 'data/InfiniteScrollProvider'

export default class SkillTagsScreenStore extends Store {
  @observable
  tagToDelete = null

  constructor() {
    super(...arguments)
    this.confirmDelete = new ConfirmDialogState()
    this.scrollProvider = new InfiniteScrollProvider({
      limit: 50,
      fetchItems: this._loadSkillTags.bind(this),
    })

    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.scrollProvider.reset()
    })
  }

  @computed
  get tags() {
    return this.rootStore.skillTagStore.forWorkspace(
      this.rootStore.sessionStore.workspace.id
    )
  }

  @task
  activate() {
    return this._loadSkillTags()
  }

  @action.bound
  create() {
    this.rootStore.skillTagEditorStore.show()
  }

  @action.bound
  edit(tag) {
    return this.rootStore.skillTagEditorStore.show(tag)
  }

  @action.bound
  delete(tag) {
    this.tagToDelete = tag
    return this.confirmDelete.show().then((shouldDelete) => {
      if (!shouldDelete) {
        return
      }

      return this.rootStore.skillTagStore.delete(tag.id)
    })
  }

  _loadSkillTags(paging) {
    return this.rootStore.skillTagStore.fetchTags({
      ...paging,
      workspace_id: this.rootStore.sessionStore.workspace.id,
    })
  }

  loadDocumentTags() {
    return this.scrollProvider.fetch()
  }
}
