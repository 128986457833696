import ContentError from 'components/ContentError'
import ContentLoading from 'components/ContentLoading'
import {
  Dialog,
  DialogButton,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'components/Dialog'
import Modal from 'components/Modal'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/Table'
import { observer } from 'mobx-react'
import React from 'react'
import styles from './AddRolesDialog.m.sass'
import { rolesTableColumns } from '../../../stores/ui/AddRolesStore'
import ProgressSpinner from 'components/ProgressSpinner'
import PermissionsSummary from 'components/PermissionsSummary'

const AddRolesDialog = ({ store }) => {
  React.useEffect(() => store.activate(), [])
  return (
    <Modal
      position="top"
      isOpened={store.showing}
      onRequestClose={() => store.hide()}
    >
      <Dialog className={styles.skillEditorDialog}>
        <DialogTitle>Add Roles</DialogTitle>
        {store.activate.match({
          pending: () => (
            <DialogContent>
              <ContentLoading />
            </DialogContent>
          ),
          rejected: (err) => (
            <DialogContent>
              <ContentError error={err} />
            </DialogContent>
          ),
          resolved: () => (
            <>
              <DialogContent>
                <Table
                  searchText={store.searchText}
                  onSearchTextChange={store.setSearchText}
                >
                  <TableHead>
                    {rolesTableColumns.map((x) => (
                      <TableHeader
                        key={x.key}
                        canSort={x.sortable}
                        onSort={(newSort) => store.sort(x.key, newSort)}
                        sortOrder={
                          store.sortField === x.key ? store.sortOrder : 'none'
                        }
                      >
                        {x.label}
                      </TableHeader>
                    ))}
                    <TableHeader centered>Selected</TableHeader>
                  </TableHead>
                  <TableBody>
                    {store.filteredElements.map((r) => (
                      <TableRow key={r.id}>
                        <TableCell>
                          <p className={styles.main}>{r.name}</p>
                        </TableCell>
                        <TableCell>
                          <p className={styles.secondary}>{r.description}</p>
                        </TableCell>
                        <TableCell>
                          <PermissionsSummary
                            descriptions={r.permissionDescriptions}
                          />
                        </TableCell>
                        <TableCell centered>
                          {store.handleChange.match({
                            pending: (roles) =>
                              roles.id === r.id && (
                                <div className={styles.centered}>
                                  <ProgressSpinner blue />
                                </div>
                              ),
                            resolved: () => (
                              <input
                                disabled={false}
                                type="checkbox"
                                checked={
                                  store.selectedElements.find(
                                    (x) => x.id === r.id
                                  ) || false
                                }
                                onChange={(e) =>
                                  store.handleChange(r, e.target.checked)
                                }
                              />
                            ),
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogFooter>
                <DialogButton onClick={() => store.hide()}>Close</DialogButton>
              </DialogFooter>
            </>
          ),
        })}
      </Dialog>
    </Modal>
  )
}

export default observer(AddRolesDialog)
