import React from 'react'

/**
 * Counts React nodes that would result in an empty render.
 *
 * @param node the node to check.
 * @param breakOnFirstFoundChild if `true`, stops counting after the first child is found. Default is false.
 */
export function nonEmptyChildrenCount(node, breakOnFirstFoundChild = false) {
  let count = 0
  let broke = false
  React.Children.forEach(node, (c) => {
    if (broke) {
      return
    }

    if (
      c === null ||
      c === undefined ||
      c === false ||
      c === '' ||
      (Array.isArray(c) && c.length === 0)
    ) {
      return
    }

    // Check fragments, all other node types would result in output.
    if (c.type === React.Fragment) {
      // This might seem terrible, but in reality it never goes that deep, and it happens
      // very rarely.
      if (
        nonEmptyChildrenCount(c.props?.children, breakOnFirstFoundChild) === 0
      ) {
        return
      }
    }

    count++
    if (breakOnFirstFoundChild) {
      broke = true
    }
  })

  return count
}

/**
 * Check if children are going to result in an empty render,
 * this is faster than getting the full child count.
 *
 * @param node
 */
export function isChildrenEmpty(node) {
  return nonEmptyChildrenCount(node, true) === 0
}
