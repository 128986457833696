import { action, observable } from 'mobx'

/**
 * Makes the specified target a Tab Manager.
 * @param {*} defaultTab
 */
export function tabManager(defaultTab) {
  return function tabManagerDecorator(target) {
    return class TabManager extends target {
      @observable currentTab = defaultTab

      @action.bound
      setTab(tab) {
        this.currentTab = tab
        return this
      }
    }
  }
}

/**
 * Tab manager as a class instead of a mixin.
 */
export class TabManager {
  @observable currentTab

  constructor(defaultTab) {
    this.currentTab = defaultTab
  }

  @action.bound
  setTab(tab) {
    this.currentTab = tab
    return this
  }
}
