import { computed } from 'mobx'
import RoutingContextSourcesBuilder from './RoutingContextSourcesBuilder'
import { snapshotable } from '../data/serialization'
import { addGenericErrorForProperty } from '../data/serverErrors'
import { validatable } from '../mixins/validation'
import { validateObject } from '../../utils/validx-validators'

/**
 * Data sources editor.
 */
@snapshotable
@validatable()
export default class DataSourcesEditor {
  rules = {
    routingContextSources: [
      validateObject('There are invalid routing context sources.'),
    ],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.routingContextSources = new RoutingContextSourcesBuilder({
      legendEditor,
    })
  }

  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Hydrates state from a Legend.
   */
  hydrate(dataSources) {
    const routingContextSources = dataSources?.routing_context_sources || []
    this.routingContextSources.hydrate(routingContextSources)
  }

  /**
   * Serializes the state to JSON.
   */
  toJSON() {
    return {
      routing_context_sources: this.routingContextSources.toJSON(),
    }
  }

  /**
   * Receives errors from the server.
   *
   * @param descriptor
   */
  receiveErrors(descriptor) {
    addGenericErrorForProperty(
      this,
      descriptor.inner?.routing_context_sources,
      'routingContextSources',
      'There are invalid routing context sources'
    )
    this.routingContextSources.receiveErrors(
      descriptor.inner?.routing_context_sources
    )
  }
}
