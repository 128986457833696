import { toJS, observable, computed } from 'mobx'
import { Model } from 'libx'
import orderBy from 'lodash/orderBy'

export default class Legend extends Model {
  @observable id = ''
  @observable workspace_id = ''
  @observable inactive = false
  @observable date_deactivated = null
  @observable deactivated_by_id = null

  @computed
  get name() {
    return this.latestVersion && this.latestVersion.name
  }

  @computed
  get latestVersion() {
    const versions = this.rootStore.legendStore.legendVersions.filter(
      (v) => v.id === this.id
    )
    return orderBy(versions, 'version', 'desc')[0]
  }

  constructor(attrs, opts) {
    super(null, opts)
    this.set(attrs, opts)
  }

  parse(json) {
    const {
      id,
      inactive,
      date_deactivated,
      deactivated_by_id,
      workspace_id,
      ...rest
    } = json
    this.rootStore.legendStore.legendVersions.set({
      id,
      workspace_id,
      ...rest,
    })
    return {
      id,
      inactive,
      workspace_id,
      date_deactivated,
      deactivated_by_id,
    }
  }

  toJSON() {
    const { rootStore, set, pick, parse, toJSON, ...rest } = this
    return toJS(rest)
  }
}
