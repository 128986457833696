const CH_DOT = '.'.charCodeAt(0)
const CH_BRACKET_BEGIN = '['.charCodeAt(0)
const CH_BRACKET_END = ']'.charCodeAt(0)

/**
 * Easy way to add a generic error for a nested property (builders or arrays).
 * See PathQuestionEditor for an example.
 *
 * @param {*} target
 * @param {*} descriptor
 * @param {string} prop
 * @param {string} message
 */
export function addGenericErrorForProperty(
  target,
  descriptor,
  prop,
  message = 'There are validation errors.'
) {
  const hasErrors =
    descriptor &&
    ((descriptor.inner && Object.keys(descriptor.inner).length > 0) ||
      (descriptor.errors && descriptor.errors.length > 0))
  if (hasErrors) {
    target.validation.addErrors({ [prop]: [message] })
  }
}

/**
 * Recursively tries to deliver errors to components.
 *
 * @param {*} target
 * @param {*} descriptorMap
 */
export function receiveErrors(target, descriptorMap) {
  if (!descriptorMap) {
    return
  }

  const keys = Object.keys(descriptorMap)
  for (const key of keys) {
    const prop = target[key]
    const descriptor = descriptorMap[key]
    if (!descriptor) {
      continue
    }
    if (
      descriptor.errors &&
      target.validation &&
      descriptor.errors.length > 0
    ) {
      target.validation.addErrors({ [key]: descriptor.errors })
    }
    if (descriptor.inner && prop) {
      if (Array.isArray(prop)) {
        receiveErrors(prop, descriptor.inner)
      } else if (prop.receiveErrors) {
        prop.receiveErrors(descriptor)
      }
    }
  }
}

/**
 * Determines if the operation result has errors.
 */
export function filterOperationResultErrors(result) {
  if (!result.errors) {
    return []
  }

  // Filter out warnings for now..
  return result.errors.filter((e) => e.type === 'error')
}

/**
 * Inflates server errors.
 *
 * @param {*} errors
 * @returns [ErrorDescriptor[]]
 */
export function inflateServerErrors(errors) {
  const entries = new Map()
  for (const error of errors) {
    const [segment, remaining] = getNextPathSegment(error.field)
    let entry = entries.get(segment)
    if (!entry) {
      entry = {
        errors: [],
        pending: [],
      }
      entries.set(segment, entry)
    }

    if (!remaining) {
      // This is an error at this level.
      entry.errors.push(error.message)
    } else {
      entry.pending.push({
        field: remaining,
        message: error.message,
      })
    }
  }

  return Array.from(entries.entries()).reduce((accum, [key, entry]) => {
    accum = accum || {} // Always use an object.
    accum[key] = {
      errors: entry.errors,
      inner: inflateServerErrors(entry.pending),
    }
    return accum
  }, null)
}

/**
 * Given an object path, returns the next part of the path (string = prop, number = array element)
 * as well as the new relative path.
 *
 * Examples:
 *   "questions[0].title" => ["questions", "[0].title"]
 *   "[0].title" => [0, "title"]
 *
 * @param {string} path
 * @returns {[string | number, string | null]}
 */
function getNextPathSegment(path) {
  const len = path.length
  let result = ''
  for (let i = 0; i < len; i++) {
    let ch = path.charCodeAt(i)
    switch (ch) {
      case CH_DOT:
        if (i === 0) continue
        return [result, path.substring(i)]
      case CH_BRACKET_BEGIN:
        if (i === 0) {
          let numStr = ''
          i++
          while ((ch = path.charCodeAt(i)) !== CH_BRACKET_END && i < len) {
            numStr += path[i++]
          }
          i++
          result = Number(numStr)
          if (path.charCodeAt(i) === CH_DOT) {
            i++
          }
          return [result, path.substring(i)]
        }
        return [result, path.substring(i)]
      default:
        result += path[i]
    }
  }
  return [result, null]
}
