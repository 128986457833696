import InfiniteScrollProvider from 'data/InfiniteScrollProvider'
import { Store } from 'libx'
import debounce from 'lodash/debounce'
import { action, observable } from 'mobx'
import { task } from 'mobx-task'
import { extractMessageFromError } from 'utils/errorUtil'

export default class ScopingJobsScreenStore extends Store {
  @observable
  scopingJobs = []

  @observable
  searchText = ''

  @observable
  sortField = null

  @observable
  sortOrder = null

  @observable
  selectedScopingJobs = []

  @observable
  facets = []

  @observable
  selectedFacets = new Map()

  @observable
  showFilter = true

  constructor() {
    super(...arguments)
    this.debouncedFetchScopingJobs = debounce(this.findScopingJobs, 200)
    this.scrollProvider = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: 30,
      fetchItems: this._loadScopingJobs.bind(this),
    })
    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.scrollProvider.reset()
      this.scopingJobs.replace([])
      this.selectedScopingJobs.replace([])
      this.searchText = ''
    })
  }

  async activate() {
    this.findScopingJobs()
    this.loadScopingAggregations()
  }

  @task
  async loadScopingAggregations() {
    const aggs = await this.rootStore.scopeWorkStore.getAggregations()
    if (aggs) {
      this.facets.replace(aggs)
      this.selectedFacets = new Map()
    }
  }

  @action.bound
  handleSelection(id) {
    if (this.selectedScopingJobs.indexOf(id) > -1) {
      this.selectedScopingJobs.remove(id)
    } else {
      this.selectedScopingJobs.push(id)
    }
  }

  @action.bound
  toggleFilter() {
    this.showFilter = !this.showFilter
  }

  @task.resolved
  async rerun(id) {
    const msg = this.rootStore.flashMessageStore.create({
      inProgress: true,
      message: 'Running current step again.',
    })

    return this.rootStore.scopeWorkStore
      .rerun(id)
      .then(() => {
        msg.done('Done.').autoDismiss()
      })
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
      })
  }

  @action.bound
  selectFacet(key, value) {
    const currentSelection = this.selectedFacets.get(key)
    if (currentSelection && currentSelection.includes(value)) {
      if (currentSelection.length === 1) {
        this.selectedFacets.delete(key)
      } else {
        currentSelection.remove(value)
      }
    } else {
      if (!currentSelection) {
        this.selectedFacets.set(key, [value])
      } else {
        currentSelection.push(value)
      }
    }
    this.debouncedFetchScopingJobs()
  }

  @task
  async findScopingJobs() {
    this.scrollProvider.reset()
    this.scopingJobs.replace([])
    this.selectedScopingJobs.replace([])
    await this.scrollProvider.fetch()
  }

  /**
   * Loads more users.
   */
  loadMore() {
    return this.scrollProvider.more()
  }

  @action.bound
  changeSort(field, order) {
    this.sortField = field
    this.sortOrder = order
    this.findScopingJobs()
  }

  @action.bound
  changeSearchText(newText) {
    this.searchText = newText
    this.debouncedFetchScopingJobs()
  }

  /**
   * Actual load call, called by the paging manager.
   *
   * @param  {object} paging
   * @return {object}
   */
  async _loadScopingJobs(p) {
    const workspace = this.rootStore.sessionStore.workspace
    const orderBy = !this.sortField
      ? undefined
      : `${this.sortField} ${this.sortOrder}`

    const searchText = this.searchText && this.searchText.trim()

    const selectedFacetKeys = [...this.selectedFacets.keys()]
    const aggregations = selectedFacetKeys.reduce((prev, curr) => {
      // so we get something like: Legend
      const currentKey = curr

      // now we join all the selected legends withs a semi colon so we get something like: name1;name2;name3
      const selectedFactes = this.selectedFacets.get(currentKey).join(';')

      // our final value looks key:value1;value2;value3
      // we have todo this cause values can have spaces between them
      const value = `${currentKey}:${selectedFactes}`

      // if we had a previous value lets append it with a comma - so the final thing looks like
      // key1:value1;value2;value3,key2:value4;value5
      if (prev.length > 0) {
        return `${value},${prev}`
      }
      return value
    }, '')

    const params = {
      workspace_id: workspace && workspace.id,
      search_text: searchText,
      order_by: orderBy,
      aggregations: aggregations,
      ...p,
    }

    const result = await this.rootStore.scopeWorkStore.search(params)
    this.scopingJobs.push(...result.data)

    return result
  }
}
