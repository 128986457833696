import { observable, action } from 'mobx'
import { required } from 'validx'
import { validateObject } from 'utils/validx-validators'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import FilterEditor from '../../FilterEditor'

@snapshotable
export default class IfEditor extends FilterEditor {
  static friendlyName = 'If'
  static friendlyDescription =
    'If (some filter) then (some other filter) else (some different filter)'

  @observable
  @serializable
  conditionRule = null

  @observable
  @serializable
  thenRule = null

  @observable
  @serializable
  elseRule = null

  rules = {
    conditionRule: [required(), validateObject('Condition rule is invalid.')],
    thenRule: [required(), validateObject('Then rule is invalid.')],
    elseRule: [required(), validateObject('Else rule is invalid.')],
  }

  parse(json, opts) {
    const parseFilter = (field) => ({
      [field]: json[field] && this.parseSubFilter(json[field]),
    })
    return {
      ...json,
      ...parseFilter('conditionRule'),
      ...parseFilter('thenRule'),
      ...parseFilter('elseRule'),
    }
  }

  @action
  setFilter(prop, data) {
    this[prop] = this.createSubFilter(data)
  }

  @action
  removeFilter(prop) {
    this[prop] = null
  }
}
