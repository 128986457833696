import { observable } from 'mobx'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import FilterEditor from '../../FilterEditor'

@snapshotable
export default class ExpressionEditor extends FilterEditor {
  static friendlyName = 'Formula'
  static hidden = true
  static friendlyDescription = 'Write a formula that resolves to a yes/no.'

  @observable
  @serializable
  expr = ''
}
