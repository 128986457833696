import cx from 'classnames'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import {
  Section,
  SectionItem,
  Sections,
  Sidebar,
  SidebarFooter,
} from 'components/Sidebar'
import RequirePerms from 'iam/containers/RequirePerms'
import link from 'misc/link'
import { observer } from 'mobx-react'
import React from 'react'
import { isDisplayDocumentTagsToggleEnabled } from 'screens/DocumentTags/document-tags-toggle'

import AppHeader from './AppHeader'

import './App.sass'

const AppSidebar = observer(({ hidden, sessionStore }) => {
  return (
    <Sidebar
      className={cx('app__hq-sidebar', hidden && 'app__hq-sidebar--hidden')}
    >
      <Sections>
        <Section title={sessionStore.workspace.name}>
          {sessionStore.workspace.features.analyticsConfig &&
            sessionStore.workspace.features.analyticsConfig.enabled && (
              <RequirePerms allOf={['ADMIN_ANALYTICS_VIEW']}>
                <SectionItem to={link.analytics(sessionStore.workspace.slug)}>
                  Analytics
                </SectionItem>
              </RequirePerms>
            )}
          <RequirePerms allOf={['ADMIN_USER_LIST']}>
            <SectionItem
              to={link.userSearch(sessionStore.workspace.slug)}
              data-test="sidebar-item-users"
            >
              Users
            </SectionItem>
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_TEAMS_VIEW']}>
            <SectionItem
              to={link.teams(sessionStore.workspace.slug)}
              data-test="sidebar-item-teams"
            >
              Teams
            </SectionItem>
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_LIST']}>
            <SectionItem
              to={link.jobs(sessionStore.workspace.slug)}
              data-test="sidebar-item-jobs"
            >
              {T('Web.Common.Jobs', 'Jobs')}
            </SectionItem>
          </RequirePerms>
          <RequirePerms
            allOf={['ADMIN_SCOPE_WORK_VIEW']}
            data-test="sidebar-item-scoping-jobs"
          >
            <SectionItem to={link.scopingJobs(sessionStore.workspace.slug)}>
              Scoping Jobs
            </SectionItem>
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_COUPONS_MANAGE']}>
            <SectionItem
              to={link.coupons(sessionStore.workspace.slug)}
              data-test="sidebar-item-coupons"
            >
              Coupons
            </SectionItem>
          </RequirePerms>
          <RequirePerms anyOf={['ADMIN_SKILLS_MANAGE', 'ADMIN_SKILLS_VIEW']}>
            <SectionItem
              to={link.skills(sessionStore.workspace.slug)}
              data-test="sidebar-item-skills"
            >
              Skills
            </SectionItem>
          </RequirePerms>
          <RequirePerms
            allOf={['ADMIN_SKILLS_TAGS_MANAGE']}
            data-test="sidebar-item-skill-tags"
          >
            <SectionItem to={link.skillTags(sessionStore.workspace.slug)}>
              Skill Tags
            </SectionItem>
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_ROLE_LIST']}>
            <SectionItem
              to={link.roles(sessionStore.workspace.slug)}
              data-test="sidebar-item-roles"
            >
              Roles
            </SectionItem>
          </RequirePerms>
          {isDisplayDocumentTagsToggleEnabled() && (
            <RequirePerms allOf={['ADMIN_DOCUMENT_TAGS_MANAGE']}>
              <SectionItem
                to={link.documentTags(sessionStore.workspace.slug)}
                data-test="sidebar-item-document-tags"
              >
                Document Tags
              </SectionItem>
            </RequirePerms>
          )}
          {sessionStore.workspace.config.message_bank_config &&
            sessionStore.workspace.config.message_bank_config.enabled && (
              <RequirePerms allOf={['MESSAGE_BANK_CREATE']}>
                <SectionItem
                  to={link.messageBank(sessionStore.workspace.slug)}
                  data-test="sidebar-item-message-bank"
                >
                  Message Bank
                </SectionItem>
              </RequirePerms>
            )}
          {sessionStore.workspace.features.legendsBuilderConfig &&
          sessionStore.workspace.features.legendsBuilderConfig.enabled ? (
            <RequirePerms allOf={['ADMIN_LEGEND_VIEW']}>
              <SectionItem
                to={link.legends(sessionStore.workspace.slug)}
                data-test="sidebar-item-legends"
              >
                Legends
              </SectionItem>
            </RequirePerms>
          ) : (
            sessionStore.user &&
            sessionStore.user.platformAdmin && (
              <SectionItem
                to={link.legends(sessionStore.workspace.slug)}
                data-test="sidebar-item-legends"
              >
                Legends
              </SectionItem>
            )
          )}
        </Section>
        {sessionStore.user && sessionStore.user.platformAdmin && (
          <Section title="Platform">
            <SectionItem
              to={link.workspaceManagement()}
              data-test="sidebar-item-workspace-management"
            >
              All Workspaces
            </SectionItem>
          </Section>
        )}
      </Sections>
      {!sessionStore.workspace.corporate && (
        <SidebarFooter>
          <div className="version-info">Worklayer HQ v{__VERSION__}</div>
        </SidebarFooter>
      )}
    </Sidebar>
  )
})

/**
 * The root component.
 */
@observer
export default class App extends React.Component {
  render() {
    const { rootStore } = this.props
    return (
      <div className="app">
        <React.Fragment>
          <AppHeader store={rootStore} />
          <div className="app__body">
            <AppSidebar
              hidden={!rootStore.sidebarStore.showing}
              sessionStore={rootStore.sessionStore}
            />
            <div
              className={cx(
                'app__content',
                rootStore.sidebarStore.showing && 'app__content--offset'
              )}
            >
              {this.props.children}
            </div>
          </div>
        </React.Fragment>
      </div>
    )
  }
}
