import { observable } from 'mobx'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import FilterEditor from '../../FilterEditor'

@snapshotable
export default class ConstantEditor extends FilterEditor {
  static friendlyName = 'Constant'
  static friendlyDescription = 'Returns the specified value.'

  @observable
  @serializable
  value = true
}
