import React from 'react'
import { observer } from 'mobx-react'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import Modal from 'components/Modal'
import { DatePicker, Form, Input, Select } from 'components/Form'

const CouponSaveModal = observer(({ state }) => {
  return (
    <Modal isOpened={state.showing} onRequestClose={state.hide} position="top">
      <Dialog>
        <Form onSubmit={state.save}>
          <DialogTitle>Coupon</DialogTitle>
          <DialogContent>
            <CouponEditor editor={state.coupon} />
          </DialogContent>
          <DialogFooter>
            <DialogButton onClick={state.hide}>Cancel</DialogButton>
            <DialogButton submit data-test="save-modal" type="primary">
              Save
            </DialogButton>
          </DialogFooter>
        </Form>
      </Dialog>
    </Modal>
  )
})

const CouponEditor = observer(({ editor }) => {
  return (
    <>
      <div className="columns">
        <div className="column">
          <Input
            field="code"
            label="Code"
            help={
              `The actual code the ${T(
                'Web.Common.Client',
                'Client'
              )} has to enter.` +
              (editor.isNew
                ? ''
                : ' You cannot change the code of an existing coupon.')
            }
            disabled={editor.disabled || editor.isNew === false}
            model={editor}
            data-test="coupon-code"
          />
        </div>
        <div className="column">
          <Input
            field="friendlyName"
            label="Friendly name"
            help={`Optional friendly name to display to the ${T(
              'Web.Common.Client',
              'Client'
            )}`}
            data-test="coupon-friendly-name"
            model={editor}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Input
            field="description"
            label="Description"
            help={`Optional description for the ${T(
              'Web.Common.Client',
              'Client'
            )}.`}
            data-test="coupon-description"
            model={editor}
          />
        </div>
        <div className="column">
          <Select
            label="Duration"
            field="duration"
            model={editor}
            help={`How many times can a single ${T(
              'Web.Common.Client',
              'Client'
            )} can use the coupon?`}
          >
            <option value="ONCE">Once (and that's it)</option>
            <option value="FOREVER">
              Forever (after having redeemed it, can add it again)
            </option>
          </Select>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Input
            field="maxTotalRedemptions"
            type="number"
            label="Max total redemptions"
            help={`How many times can this coupon be redeemed in total? Not per ${T(
              'Web.Common.Client',
              'Client'
            )}, but in TOTAL (0 = unlimited).`}
            min={0}
            model={editor}
            data-test="coupon-max-redemptions"
          />
        </div>
        <div className="column">
          <DatePicker
            field="dateExpires"
            label="Expires"
            help="Does the coupon expire? If so, after this date the coupon can no longer be added (it can still be redeemed, though)."
            model={editor}
            data-test="coupon-date-picker"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Select
            label="Off type"
            field="offType"
            model={editor}
            help="What unit is the discount in?"
          >
            <option value="AMOUNT_OFF">Amount off</option>
            <option value="PERCENT_OFF">Percent off</option>
          </Select>
        </div>
        <div className="column">
          <Input
            field="offValue"
            type="number"
            min={0}
            label={
              editor.offType === 'AMOUNT_OFF' ? 'Amount off' : 'Percent off'
            }
            help={
              editor.offType === 'AMOUNT_OFF'
                ? 'How many dollars off?'
                : 'How many % off? Applied after amount-offs.'
            }
            model={editor}
            data-test="coupon-amount-off"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Input
            field="minimumTotal"
            type="number"
            label="Minimum job total"
            help={
              'The job must be this amount or greater for the coupon to apply.'
            }
            min={0}
            model={editor}
            data-test="coupon-minimum-total"
          />
        </div>
        <div className="column">
          <Input
            field="maxOff"
            type="number"
            min={0}
            label={
              editor.offType === 'AMOUNT_OFF'
                ? 'Max percent off'
                : 'Max amount off'
            }
            help={
              editor.offType === 'AMOUNT_OFF'
                ? 'The percent limit a customer can save with this coupon. Number must be between 1 and 100.'
                : 'The dollar limit a customer can save with this coupon.'
            }
            model={editor}
            data-test="coupon-max-off"
          />
        </div>
      </div>
    </>
  )
})

export default CouponSaveModal
