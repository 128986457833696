import { extractMessageFromError } from 'utils/errorUtil'
import QuestionVariable from './QuestionVariable'

import QuestionStoreBase from 'jobs/QuestionStore'

/**
 * Question store.
 */
export default class QuestionStore extends QuestionStoreBase {
  variables = this.collection({
    model: QuestionVariable,
    getDataId: (data) => `${data.jobId}/${data.id}`,
    getModelId: (model) => `${model.jobId}/${model.id}`,
  })

  variablesForJob = (jobId) => this.variables.filter((q) => q.jobId === jobId)

  /**
   * Fetch job variables used to compute questions.
   *
   * @param {string} jobId.
   */
  async fetchVariables(jobId) {
    const response = await this.rootStore.api.jobAdminV3
      .listQuestionVariables(jobId)
      .catch((e) => {
        this.rootStore.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })

    this.variables.clear()
    this.variables.set(
      response.variables.map((dto) => ({ jobId: jobId, ...dto }))
    )
  }
}
