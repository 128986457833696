import { observable, computed } from 'mobx'
import { required } from 'validx'
import QuestionEditor from '../../QuestionEditor'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import { Types } from 'legend-builder/data/Type'

@snapshotable
export default class TextQuestionEditor extends QuestionEditor {
  static friendlyType = 'Text'
  static type = 'Text'
  static viewOrder = 3
  static iconColor = '#40A0F4'

  valueType = Types.string

  @observable
  @serializable
  question = 'New Text Question'

  @observable
  @serializable
  description = ''

  rules = {
    question: [required('Question is required')],
  }

  @computed
  get answerOptions() {
    return {
      type: 'text',
    }
  }
}
