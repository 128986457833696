import React from 'react'
import { observer } from 'mobx-react'
import PromptsList from '@taxfyle/web-commons/lib/jobs/components/Prompts/PromptsList'
import styles from './Prompts.m.sass'
import EmptyState from 'components/EmptyState'

/**
 * Prompts.
 */
export default observer(function Prompts({ store }) {
  return (
    <div className={styles.root}>
      <PromptsList
        store={store.promptStore}
        prompts={store.prompts}
        view={store.promptDialogStore.show}
        scrollProvider={store.promptsScrollProvider}
      />
      {store.promptsScrollProvider.isDone &&
        store.promptsScrollProvider.items.length === 0 && (
          <EmptyState title="Prompts">
            There are no prompts for this job.
          </EmptyState>
        )}
    </div>
  )
})
