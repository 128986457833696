import { Model } from 'libx'
import { observable } from 'mobx'

export default class QuestionVariable extends Model {
  @observable id = null
  @observable label = null
  @observable value = null
  @observable valueType = null

  /**
   * Parses the DTO to model properties.
   */
  parse(attrs) {
    return {
      jobId: attrs.jobId,
      id: attrs.id,
      label: attrs.label,
      value: attrs.value,
      valueType: attrs.valueType,
    }
  }
}
