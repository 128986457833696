import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ContentLoading from 'components/ContentLoading'
import ErrorLabel from 'components/ErrorLabel'
import { FormGroup, Input, Help, getError } from 'components/Form'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import QuestionGrid from 'jobs/components/QuestionsEditor'
import { HtmlClass } from 'components/ClassName'
import styles from './DraftJobUpdateDialog.sass'
import UserSelector from 'components/UserSelector'
import { filterOutAllMembersOf } from 'utils/userSelectorFilterUtil'

@observer
class DraftJobUpdateDialog extends React.Component {
  renderDialog() {
    const { store } = this.props
    if (!store.job) return null

    return (
      <div className={styles.draftJobUpdateDialog}>
        <DialogTitle> Update draft job</DialogTitle>
        <DialogContent>
          <p>
            <strong>IMPORTANT:</strong> Jobs submitted from HQ default to
            external billing. Meaning, if payment is required from the client
            the job must be submitted from the client portal.
          </p>
          <hr />

          <Input
            label="Name"
            placeholder="The Name of the Job"
            model={store}
            field="name"
            help="The main name of the Job."
          />

          {store.billUserAllowed && (
            <Input
              label="Bill External"
              type="checkbox"
              disabled={store.billingExternalDisabled}
              model={store}
              field="billExternal"
              help="Should we charge the default billing method or will this be handled manually."
            />
          )}

          {store.allowProSelection && (
            <FormGroup label="Assign a provider to the job:">
              <UserSelector
                allowEmpty
                userFilter={filterOutAllMembersOf(store.job)}
                workspaceId={store.rootStore.sessionStore.workspace.id}
                memberStore={store.rootStore.memberStore}
                name="new-provider"
                onChange={store.selectProvider}
                value={store.assignToPro}
              />
            </FormGroup>
          )}
          <FormGroup label="Questions">
            <QuestionGrid
              questions={store.questions}
              answers={store.answers}
              disabled={store.fetchQuestions.pending}
              loading={
                store.questions.length === 0 && store.fetchQuestions.pending
              }
              onChange={store.setAnswers}
              onUpdate={store.fetchQuestions}
            />
            <Help error={getError(store, 'answers')}></Help>
          </FormGroup>
          <ErrorLabel>
            {store.error ||
              (store.hasBreaker ? 'Cannot save a job with a breaker.' : null)}
          </ErrorLabel>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide}>Cancel</DialogButton>
          <DialogButton
            primary
            onClick={store.save}
            disabled={store.hasBreaker || store.busy}
          >
            Save & Close
          </DialogButton>
          <DialogButton
            danger
            onClick={store.submit}
            disabled={store.hasBreaker || store.busy}
          >
            Submit & Close
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        position="top"
        dismissOnOutsideClick={false}
      >
        <HtmlClass className="disable-scrolling" />
        <Dialog>{store.busy ? <ContentLoading /> : this.renderDialog()}</Dialog>
      </Modal>
    )
  }
}

export default DraftJobUpdateDialog
