import { observable, action, computed, runInAction } from 'mobx'
import { task } from 'mobx-task'
import { validationContext } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'

export default class DraftJobUpdateState {
  @observable showing = false
  @observable error = ''

  @observable job = null
  @observable name = ''
  @observable assignToPro = null
  @observable billExternal = true
  @observable questions = []
  @observable answers = []

  validation = validationContext(this, {
    answers: [
      () =>
        this.save.pending ||
        this.requiredQuestionsAnswered ||
        'Please answer all questions.',
    ],
  })

  constructor({ rootStore }) {
    this.rootStore = rootStore
    this.flashMessageStore = rootStore.flashMessageStore
    this.draftStore = rootStore.draftStore
    this.questionStore = rootStore.questionStore
    this.memberStore = rootStore.memberStore
    this.jobsV3Api = rootStore.api.jobsV3
    this.jobStore = rootStore.jobStore

    this.fetchAndSetProvider = this.fetchAndSetProvider.bind(this)
    this.fetchQuestions = this.fetchQuestions.bind(this)
    this.save = this.save.bind(this)
    this.submit = this.submit.bind(this)
  }

  @action.bound
  init(job) {
    this.validation.reset()

    this.job = job
    this.name = job.name
    this.billExternal = job.billingType && job.billingType === 'EXTERNAL'

    this.assignToPro = null
    this.questions = []
    this.answers = []

    this.initLoad()
  }

  @computed
  get requiredQuestionsAnswered() {
    const unanswered = this.questions.filter((q) => !q.isAnswered)
    return unanswered.legend === 0 || unanswered.every((q) => q.isDocument)
  }

  @computed
  get allowProSelection() {
    return this.job?.legend?.allowProSelection
  }

  @computed
  get billUserAllowed() {
    return (
      this.rootStore.sessionStore.workspace.features.billing.enabled &&
      this.job?.legend.skipBilling
    )
  }

  @computed
  get providerUserPublicId() {
    return this.assignToPro?.userPublicId ?? null
  }

  @computed
  get hasBreaker() {
    return this.questions.some((x) => x.type === 'BREAKER')
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @action.bound
  selectProvider(user) {
    this.assignToPro = user
  }

  @action.bound
  setAnswers(answers) {
    this.answers = answers
  }

  @computed
  get busy() {
    return this.initLoad.pending || this.save.pending || this.submit.pending
  }

  @task
  async initLoad() {
    await this.maybeUpgradeLegend()
    await Promise.all([this.fetchAndSetProvider(), this.fetchQuestions()])
  }

  @task
  async fetchAndSetProvider() {
    if (!this.job.requestedProPublicId) return

    const provider = await this.memberStore.fetchByPublicId({
      workspace_id: this.job.workspaceId,
      user_public_id: this.job.requestedProPublicId,
    })

    runInAction(() => {
      this.assignToPro = provider
    })
  }

  @task
  async fetchQuestions() {
    if (!this.job) {
      return
    }

    const questionsDictionary = this.questions.reduce(
      (dictionary, question) => {
        dictionary[question.id] = question
        return dictionary
      },
      {}
    )

    // Add default answer for questions of boolean type
    this.questions.forEach((question) => {
      if (question.isBool && !question.isAnswered) {
        this.answers.push({
          id: question.id,
          answer: false,
        })
      }
    })

    const questionsWithAnswers = this.answers.map((answer) => {
      const question = questionsDictionary[answer.id]
      question.setAnswer(answer.answer)
      return question
    })

    await this.questionStore.fetchQuestions(this.job.id, questionsWithAnswers)

    const questions = this.questionStore.questionsForJob(this.job.id)

    // if we get any new boolean question that has no answer we need to call fetch question
    if (questions.some((q) => q.isBool && !q.isAnswered)) {
      this.questions = questions
      this.answers = questions.map((q) => ({
        id: q.id,
        answer: q.answer,
      }))
      await this.fetchQuestions()
      return
    }

    runInAction(() => {
      this.questions = questions
      this.answers = questions.map((q) => ({
        id: q.id,
        answer: q.answer,
      }))
    })
  }

  @task
  async renameJob(jobId) {
    if (this.name === this.job.name) return
    await this.jobsV3Api.rename(jobId, this.name)
  }

  @task
  async requestChampionProvider() {
    if (
      !this.allowProSelection ||
      this.providerUserPublicId === this.job.requestedProPublicId
    ) {
      return
    }
    await this.draftStore.requestChampionProvider(
      this.job.id,
      this.providerUserPublicId
    )
  }

  /**
   * Attempts to upgrade the Legend version to the latest published version
   */
  async maybeUpgradeLegend() {
    if (this.job.status !== 'UNDER_CONSTRUCTION') {
      return
    }

    const latestPublishedLegend = await this.rootStore.publishedLegendStore.fetchPublishedLegendVersion(
      this.job.legendId,
      null,
      true
    )

    if (latestPublishedLegend.version <= this.job.legendVersion) {
      // It's up to date, nothing to do here.
      return
    }

    if (!this.showing) {
      // We closed the dialog while we were waiting, so don't do anything else.
      return
    }

    const msg = this.flashMessageStore.create({
      inProgress: true,
      message: 'There is a new version of the Legend; upgrading...',
    })
    try {
      await this.jobStore.changeLegendVersion(
        this.job.id,
        latestPublishedLegend.id,
        latestPublishedLegend.version
      )
      msg.done('The Legend was upgraded!').autoDismiss(10000)
    } catch (err) {
      msg.failed(extractMessageFromError(err))
    }
  }

  @task.resolved
  async save() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const msg = this.flashMessageStore.create({
      inProgress: true,
      message: 'Preparing Job',
    })

    try {
      await this.renameJob(this.job.id)
      await this.requestChampionProvider()
      await this.draftStore.replaceQuestionAnswers(this.job.id, this.questions)
      msg.done('Job was saved').autoDismiss()
      this.hide()
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
    } finally {
      await this.jobStore.getJob(this.job.id)
    }
  }

  @task.resolved
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const msg = this.flashMessageStore.create({
      inProgress: true,
      message: 'Preparing Job',
    })

    try {
      await this.renameJob(this.job.id)
      await this.requestChampionProvider()
      await this.draftStore.replaceQuestionAnswers(this.job.id, this.questions)
      await this.draftStore.submitDraft(this.job.id, null, this.billExternal)
      msg.done('Job was submitted').autoDismiss()
      this.hide()
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
    } finally {
      await this.jobStore.getJob(this.job.id)
    }
  }
}
