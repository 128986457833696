import React from 'react'
import EntitySelector from '../EntitySelector'

const TeamSelector = ({ teamStore, workspaceId, ...other }) => {
  return (
    <EntitySelector
      {...other}
      defaultOptions
      pageSize={100}
      getOptionLabel={(o) => o.name}
      allowEmpty
      noOptionsMessage={() => 'Type to search for teams.'}
      closeMenuOnSelect={true}
      loadEntities={async (p) =>
        teamStore.findTeams({
          workspace_id: workspaceId,
          ...p,
        })
      }
      entityFilter={(team) => team.inactive === false}
    />
  )
}

export default TeamSelector
