import React from 'react'
import { observer } from 'mobx-react'
import { Form, FormGroup, Input, Select } from 'components/Form'
import Button from 'components/Button'
import './styles.sass'

const AmendmentEditor = ({ editor }) => {
  return (
    <div className="amendment-editor">
      <Form onSubmit={() => editor.save()}>
        <Input
          label="Description"
          field="description"
          help="E.g. the Client wanted additional services (bookkeeping, whatever), or requested refund"
          model={editor}
        />
        <Input
          label="Internal note"
          field="internalNote"
          help="For our own internal reference."
          model={editor}
        />
        <Select label="Visibility" field="visibility" model={editor}>
          <option value="" disabled>
            Select...
          </option>
          <option value="INTERNAL">Internal (only visible to HQ)</option>
          <option value="PUBLIC">Public (visible to Client and Pro)</option>
        </Select>
        <div className="columns">
          <div className="column">
            <Input
              label="Client amount"
              type="number"
              step="any"
              field="customerAmount"
              model={editor}
              help="If positive, Client pays more. If negative, Client is refunded."
            />
          </div>
          <div className="column">
            <Input
              label="Pro amount"
              type="number"
              step="any"
              field="providerAmount"
              model={editor}
              help="If positive, Pro gets more dough. If negative, Pro is penalized."
            />
          </div>
        </div>
        <FormGroup>
          <Button submit type="success" disabled={editor.disabled}>
            Save
          </Button>
          &nbsp;
          <Button onClick={() => editor.cancelEditing()}>Cancel</Button>
        </FormGroup>
      </Form>
    </div>
  )
}

export default observer(AmendmentEditor)
