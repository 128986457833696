import { action, observable } from 'mobx'
import { task } from 'mobx-task'
import { pattern, required, validationContext } from 'validx'

/**
 * Assign job to a team UI state.
 */
export default class CloneJobState {
  @observable
  showing = false

  @observable
  error = ''

  @observable
  job

  @observable
  amount = 0

  /**
   * Validation context.
   */
  validation = validationContext()

  /**
   * Validation rules.
   */
  rules = {
    amount: [
      required('Amount is required.'),
      pattern({
        pattern: /^\d+$/, // regex pattern for numbers
        msg: 'Amount must be a natural number greater than 0.',
      }),
      (opts) => {
        return opts.value > 0 || 'Amount must be greater than 0.'
      },
    ],
  }

  constructor({ rootStore }) {
    this.rootStore = rootStore
  }

  @action.bound
  async init(job) {
    this.validation.reset()
    this.job = job
    this.amount = 0
    this.cloneJob.reset()
  }

  @task.resolved
  async cloneJob() {
    if (!this.validation.reset().validate(this, this.rules).isValid) {
      return
    }
    if (this.amount > 0) {
      await this.rootStore.jobStore.cloneJob(this.job.id, {
        amount: this.amount,
      })
      this.hide()
    }
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }
}
