import React from 'react'
import * as Duration from 'dration'
import { action, observable } from 'mobx'
import Textarea from 'react-autosize-textarea'
import NumericInput from '@taxfyle/web-commons/lib/components/NumericInput'
import Icon from 'components/Icon'
import { observer } from 'mobx-react'
import Arrow from './ic_dropdown.i.svg'
import cx from 'classnames'
import styles from './Controls.sass'
import Select from 'react-select'
import { useObservableCallback } from '../../../hooks/rx-hooks'

export const Form = ({
  onSubmit,
  className,
  children,
  pad,
  constrain,
  ...rest
}) => (
  <form
    onSubmit={(e) => e.preventDefault() || (onSubmit && onSubmit())}
    className={cx(
      className,
      styles.form,
      pad && styles.pad,
      constrain && styles.constrain
    )}
    {...rest}
  >
    {children}
  </form>
)

export const Help = ({ className, children, ...rest }) => (
  <div className={cx(styles.help, className)} {...rest}>
    {children}
  </div>
)

export const Row = ({ className, children, loose, pad }) => (
  <div className={cx(styles.row, className, pad && styles.pad)}>
    {React.Children.map(
      children,
      (c) =>
        c && <div className={cx(styles.column, loose && styles.loose)}>{c}</div>
    )}
  </div>
)

export const FormGroup = ({
  children,
  inline,
  before,
  label,
  stretch,
  inlineSwitch,
  help,
  errors,
  labelAsSpan,
  padless,
  className,
  ...rest
}) => (
  <div
    className={cx(styles.formGroup, className, padless && styles.padless)}
    {...rest}
  >
    {label ? (
      <Label
        labelAsSpan={labelAsSpan}
        before={before}
        stretch={stretch}
        inlineSwitch={inlineSwitch}
        inline={inline}
        label={label}
      >
        {children}
      </Label>
    ) : (
      children
    )}
    <ControlErrors errors={errors} />
    {help && <Help>{help}</Help>}
  </div>
)

export const ControlErrors = ({ errors }) => {
  if (!errors || errors.length === 0) {
    return null
  }

  return (
    <div className={styles.controlErrors}>
      {errors.map((x, i) => (
        <div key={i} className={styles.controlError}>
          {x}
        </div>
      ))}
    </div>
  )
}

export const FormHorizontal = ({ children, className, stretch }) => (
  <div className={cx(styles.formHoriz, className, stretch && styles.stretch)}>
    {React.Children.map(children, (c) => (
      <div className={styles.formHorizItem}>{c}</div>
    ))}
  </div>
)

export const Label = ({
  before,
  labelAsSpan,
  stretch,
  inlineSwitch,
  inline,
  children,
  label,
}) => {
  const inner = children && (
    <div className={cx(styles.labelInner)}>{children}</div>
  )

  // The reason for this is that some components want to display
  // a label, but not have it be an actual <label> because the real
  // label brings the focus-first-input-on-click behavior.
  const LabelComponent = labelAsSpan ? 'span' : 'label'
  return (
    <LabelComponent
      className={cx(
        styles.label,
        inline && styles.labelInline,
        before && styles.labelBefore,
        stretch && styles.labelStretch,
        inlineSwitch && styles.labelSwitch
      )}
    >
      {before && inner}
      {label}
      {!before && inner}
    </LabelComponent>
  )
}

export class SelectControl extends React.Component {
  change = (index) => {
    const items = this.props.items
    const value = items[index][0]
    this.props.onChange(value)
  }

  render() {
    const {
      children,
      items,
      className,
      placeholder,
      value,
      onChange,
      ...rest
    } = this.props
    if (!items) {
      return (
        <select
          className={cx(styles.select, className)}
          value={value}
          onChange={onChange && ((e) => onChange(e.target.value))}
          {...rest}
        >
          {children}
        </select>
      )
    }

    const selectValue =
      value === undefined || value === null
        ? -1
        : items.findIndex(([key]) => key === value)

    return (
      <select
        className={cx(styles.select, className)}
        onChange={(e) => this.change(parseInt(e.target.value, 10))}
        value={selectValue === -1 ? '' : selectValue}
        {...rest}
      >
        {placeholder && (
          <option value="" readOnly disabled>
            {placeholder}
          </option>
        )}
        {items.map((item, index) => (
          <option key={index} value={index}>
            {item[1] || item[0]}
          </option>
        ))}
      </select>
    )
  }
}

/** Need to provide items cause we cant select them otherwise */
export class MultiSelectControl extends React.Component {
  change = (obj) => {
    if (!obj) {
      this.props.onChange([])
    } else {
      this.props.onChange(obj.map((x) => x.value))
    }
  }

  render() {
    const { items, value, disabled } = this.props
    const formattedItems = items.map((x) => {
      if (x.length === 1) {
        return { label: x[0], value: x[0] }
      }
      return { label: x[1], value: x[0] }
    })
    return (
      <Select
        isMulti
        onChange={(opts) => this.change(opts)}
        value={formattedItems.filter((x) => value.includes(x.value))}
        options={formattedItems}
        isDisabled={disabled}
      />
    )
  }
}

export const SearchableSelectControl = ({
  items,
  placeholder,
  value,
  onChange,
  disabled,
}) => {
  return (
    // classNamePrefix is used by this component for styling with modules
    <Select
      className={styles.reactSelectContainer}
      classNamePrefix="react-select"
      options={items}
      value={items.filter((item) => item.value === value)}
      placeholder={placeholder}
      onChange={onChange}
      isDisabled={disabled}
    />
  )
}

export const CheckboxControl = (props) => {
  const { checked, className, ...rest } = props
  return (
    <input
      type="checkbox"
      className={cx(styles.checkbox, className)}
      checked={checked}
      {...rest}
    />
  )
}

@observer
export class InputControl extends React.Component {
  @observable
  value = ''

  componentDidMount() {
    this.value = this.props.value
  }

  @action.bound
  change(e) {
    const value = e.target.value
    const { onChange, mode } = this.props
    if (mode === 'blur') {
      this.value = value
      return
    }

    onChange(e)
  }

  @action.bound
  blur(e) {
    const { onChange, mode } = this.props
    if (mode === 'blur') {
      onChange(e)
    }

    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  @action
  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.mode === 'blur' && this.props.value !== prevProps.value) {
      return {
        value: this.props.value,
      }
    }
    return null
  }

  @action
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
      this.value = snapshot.value
    }
  }

  render() {
    const { mode = 'live', value: _value, ...rest } = this.props
    const value = mode === 'live' ? _value : this.value
    return (
      <InputControlInner
        value={value}
        {...rest}
        onChange={this.change}
        onBlur={this.blur}
      />
    )
  }
}

function InputControlInner({
  multi,
  autosize,
  className,
  naked,
  canResize,
  precision,
  numeric,
  rows = 3,
  hexColor,
  value,
  onChange,
  onBlur,
  focus$,
  ...rest
}) {
  const ref = React.useRef()
  useObservableCallback(
    focus$,
    () => {
      ref.current?.focus()
      ref.current?.select()
    },
    [focus$]
  )
  const renderInput = (moreProps) =>
    React.createElement(multi ? (autosize ? Textarea : 'textarea') : 'input', {
      ...rest,
      value,
      ...(multi && { rows }),
      className: cx(
        !naked &&
          (multi
            ? styles.textarea
            : rest.type === 'range'
            ? styles.range
            : styles.input),
        className,
        !canResize && styles.disableResize
      ),
      onChange,
      onBlur,
      ...moreProps,
      ref,
    })
  if (numeric) {
    return (
      <NumericInput
        precision={precision}
        onChange={(v) =>
          onChange({ fakeEventFromNumeric: true, target: { value: v } })
        }
        onBlur={onBlur}
        value={value}
      >
        {(inputProps, actions) =>
          renderInput({
            ...inputProps,
            onKeyPress: actions.keyPressPreventNonNumeric,
          })
        }
      </NumericInput>
    )
  }
  if (hexColor) {
    // Color Input
    const color = value
    const validColor = /^#[0-9A-F]{6}$/i.test(color)
    return (
      <div className={cx(styles.colorInput)}>
        {renderInput()}
        <div
          className={cx(styles.colorInputHint)}
          style={{
            backgroundColor: validColor ? color : 'transparent',
          }}
        />
      </div>
    )
  }
  return renderInput()
}

export function CheckList({ compact, children }) {
  return (
    <div className={cx(styles.checklist, compact && styles.compact)}>
      {children}
    </div>
  )
}

export function CheckListItem({ checked, children, disabled, onClick }) {
  return (
    <div
      role="checkbox"
      disabled={disabled}
      aria-checked={checked ? 'checked' : ''}
      aria-disabled={disabled ? 'true' : 'false'}
      className={cx(styles.checklistItem, disabled && styles.disabled)}
      onClick={disabled ? null : onClick}
    >
      <div
        className={cx(
          styles.checklistItemIconContainer,
          checked && styles.checked
        )}
      >
        <Icon
          material
          name={checked ? 'check box' : 'check box outline blank'}
        />
      </div>
      <div className={styles.checklistItemText}>{children}</div>
    </div>
  )
}

export function DurationControl({
  value = 0,
  onChange,
  disabled,
  allowedUnits = ['Day', 'Hour', 'Minute'],
}) {
  return (
    <div className={cx(styles.durationControl, disabled && styles.disabled)}>
      {allowedUnits.map((unit) => (
        <DurationUnit
          key={unit}
          unit={unit}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      ))}
    </div>
  )
}

const DurationUnit = ({ unit, disabled, value, onChange }) => {
  const helper = durationHelpers[unit](value, onChange)
  const unitValue = helper.value
  const change = helper.onChange
  const inputRef = React.useRef()
  const focusInput = (e) =>
    e.preventDefault() || (inputRef.current && inputRef.current.focus())
  return (
    <div className={styles.durationUnitContainer}>
      <div className={styles.durationUnitInputContainer}>
        <NumericInput
          value={unitValue}
          onChange={change}
          disabled={disabled}
          mode="blur"
          precision={0}
        >
          {(inputProps, actions) => (
            <input
              type="text"
              className={styles.durationUnitInput}
              disabled={disabled}
              ref={inputRef}
              style={{
                // Try our damn bestest to auto-fit the width of the input
                // based on the length of the value.
                // It took a bit of fiddling to arive at these numbers.
                width: Math.max(
                  13,
                  inputProps.value.length * (8 - inputProps.value.length * 0.1)
                ),
              }}
              {...inputProps}
              onKeyPress={actions.keyPressPreventNonNumeric}
              onKeyDown={helper.handleArrowKeys}
            />
          )}
        </NumericInput>
        <span onClick={focusInput}>{helper.suffix}</span>
      </div>
      <div className={styles.durationUnitArrows}>
        <Arrow
          className={cx(styles.durationArrowUp, disabled && styles.disabled)}
          onClick={(e) => {
            if (disabled) {
              return
            }
            helper.increment()
            focusInput(e)
          }}
        />
        <Arrow
          className={cx(styles.durationArrowDown, disabled && styles.disabled)}
          onClick={(e) => {
            if (disabled) {
              return
            }
            helper.decrement()
            focusInput(e)
          }}
        />
      </div>
    </div>
  )
}

/**
 * Creates helper factories for each unit.
 */
const durationHelpers = {
  Second: createDurationHelperFactory('s', Duration.getSeconds, (c, v) =>
    Duration.of(
      Duration.getMilliseconds(c),
      v,
      Duration.getMinutes(c),
      Duration.getHours(c),
      Duration.getDays(c)
    )
  ),
  Minute: createDurationHelperFactory('m', Duration.getMinutes, (c, v) =>
    Duration.of(
      Duration.getMilliseconds(c),
      Duration.getSeconds(c),
      v,
      Duration.getHours(c),
      Duration.getDays(c)
    )
  ),
  Hour: createDurationHelperFactory('h', Duration.getHours, (c, v) =>
    Duration.of(
      Duration.getMilliseconds(c),
      Duration.getSeconds(c),
      Duration.getMinutes(c),
      v,
      Duration.getDays(c)
    )
  ),
  Day: createDurationHelperFactory('d', Duration.getDays, (c, v) =>
    Duration.of(
      Duration.getMilliseconds(c),
      Duration.getSeconds(c),
      Duration.getMinutes(c),
      Duration.getHours(c),
      v
    )
  ),
}

/**
 * Creates a helper factory for a unit using a getter and a setter.
 *
 * @param {*} suffix
 * @param {*} getter
 * @param {*} setter
 */
function createDurationHelperFactory(suffix, getter, setter) {
  /**
   * Creates a helper using the current total value in seconds and a change handler.
   */
  return (currentValue, onChange) => {
    const convertUnitValue = (newValue) => {
      newValue = parseInt(newValue, 10) || 0
      return Math.floor(
        Duration.getTotalSeconds(
          setter(Duration.fromSeconds(currentValue), newValue)
        )
      )
    }

    const unitValue = getter(Duration.fromSeconds(currentValue))
    const change = (v) => onChange(Math.max(v, 0))
    const increment = () => change(convertUnitValue(unitValue + 1))
    const decrement = () => change(convertUnitValue(unitValue - 1))
    return {
      suffix,
      increment,
      decrement,
      value: unitValue,
      onChange: (newUnitValue) => onChange(convertUnitValue(newUnitValue)),
      handleArrowKeys: (e) => {
        switch (e.keyCode) {
          case 38:
            // Arrow up
            e.preventDefault()
            return increment()
          case 40:
            // Arrow down
            e.preventDefault()
            return decrement()
        }
      },
    }
  }
}
