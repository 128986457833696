import { serializable, snapshotable } from 'legend-builder/data/serialization'
import QuestionEditor from 'legend-builder/questions/QuestionEditor'
import { action, observable, computed } from 'mobx'
import { required } from 'validx'

@snapshotable
export default class InventoryItemQuestionEditor extends QuestionEditor {
  static friendlyType = 'Service Item'
  static type = 'InventoryItem'
  static viewOrder = 4
  static isMetaElement = true
  static iconColor = '#763EF4'

  @observable
  @serializable
  label = ''

  @observable
  @serializable
  inventory_item_id = ''

  @observable
  @serializable
  quantity = 0

  rules = {
    inventory_item_id: [required('The service item ID is required.')],
    quantity: [required('Quantity required')],
  }

  @action.bound
  changeInventoryItem(inventoryItemId) {
    const item = this.legendEditor.allowedWorkspaceInventoryItems.find(
      (i) => i.id === inventoryItemId
    )
    this.id =
      'ITEM_' +
      item.name
        .trim()
        .replaceAll('-', '__')
        .replace(/[^a-zA-Z0-9_ ]/g, '')
        .replaceAll(' ', '_')
        .toUpperCase()
    this.label = item.name
    this.inventory_item_id = inventoryItemId
  }

  @computed
  get inventoryItemElementOptions() {
    const questionsBuilder = this.legendEditor.questionsBuilder
    const inventoryItemQuestions = questionsBuilder.flattenedQuestions.filter(
      (q) => q.type === 'InventoryItem'
    )
    const alreadyAddedItems = inventoryItemQuestions.flatMap(
      (q) => q.inventory_item_id
    )
    return this.legendEditor.allowedWorkspaceInventoryItems
      .filter(
        (item) =>
          !alreadyAddedItems.includes(item.id) ||
          item.id === this.inventory_item_id
      )
      .map((item) => ({
        value: item.id,
        label: `${item.name} - ${item.shortId}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }
}
