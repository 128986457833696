import flatten from 'lodash/flatten'
import { observable, computed, action } from 'mobx'
import { validatable } from 'legend-builder/mixins/validation'
import { tabManager } from 'legend-builder/mixins/tab-manager'
import { snapshotable } from 'legend-builder/data/serialization'
import {
  addGenericErrorForProperty,
  receiveErrors,
} from 'legend-builder/data/serverErrors'
import MilestoneEditor from './MilestoneEditor'

@snapshotable
@tabManager('GENERAL')
@validatable({ liveValidation: true })
export default class MilestonesBuilder {
  @observable
  milestones = []

  /**
   * This can be either a task or a milestone.
   */
  @observable
  selectedEditor = null

  rules = {
    milestones: [
      () =>
        this.milestones.map((m) => m.validateAll()).every((x) => x) ||
        'There are invalid milestones.',
    ],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this._parseMilestone = this._parseMilestone.bind(this)
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  @computed
  get entityDescriptors() {
    const result = []
    for (let i = 0; i < this.milestones.length; i++) {
      const milestone = this.milestones[i]
      result.push({ type: 'Milestone', entity: milestone })
      for (let j = 0; j < milestone.tasks.tasks.length; j++) {
        const task = milestone.tasks.tasks[j]
        result.push({ type: 'Task', entity: task })
      }
    }
    return result
  }

  @computed
  get allTasks() {
    return flatten(this.milestones.map((m) => m.tasks.tasks.slice()))
  }

  @action
  add() {
    this.milestones.push(MilestoneEditor.create({}, this._milestoneOpts()))
  }

  @action
  remove(editor) {
    this.milestones.remove(editor)
    if (
      editor === this.selectedEditor ||
      editor.tasks.tasks.some((c) => c === this.selectedEditor)
    ) {
      this.edit(null)
    }
  }

  @action
  edit(editor) {
    if (editor === null) {
      this.selectedEditor = null
      return
    }

    if (
      this.allTasks.indexOf(editor) > -1 ||
      this.milestones.indexOf(editor) > -1
    ) {
      this.selectedEditor = editor
    }
  }

  @action
  hydrate(milestones = []) {
    this.milestones.replace(milestones.map(this._parseMilestone))
  }

  toJSON() {
    return this.milestones.map((x) => x.toJSON())
  }

  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'milestones',
      'There are invalid milestones'
    )
    receiveErrors(this.milestones, descriptor.inner)
  }

  _parseMilestone(milestone) {
    return MilestoneEditor.fromJS(milestone, this._milestoneOpts())
  }

  _milestoneOpts() {
    return {
      legendEditor: this.legendEditor,
      milestonesBuilder: this,
    }
  }
}
