import React from 'react'
import cx from 'classnames'
import { Dropdown, DropdownItem } from 'components/Dropdown'
import Icon from 'components/Icon'
import styles from './List.sass'

/**
 * Used by mutators, select options and more.
 *
 * @param  {string} options.className
 *
 * @param  {string} options.title
 * Optional title. First child will not have border radius applied if a
 * title is present.
 *
 * @param  {Array<{ id, title, description, ...}>} options.dropdownItems
 * Items to render in the dropdown.
 *
 * @param {Function} onAddClick
 * If no `dropdownItems` have been provided, use this to attach a click
 * handler to the Add button.
 *
 * @param  {Node} options.children
 * The list items.
 *
 * @param  {Function} options.onAdd
 * Invoked with the object representing the dropdown item that was clicked.
 */
export const List = ({
  className,
  title,
  dropdownItems,
  onAddClick,
  children,
  onAdd,
  flat,
  disabled,
  embedded,
  icon,
  spaced = false,
}) => {
  const shouldRoundButton =
    spaced || (!title && React.Children.count(children) === 0)
  const makeButton = ({ innerRef, ...buttonProps }) => (
    <ListAddButton
      flat={flat}
      spaced={spaced}
      rounded={shouldRoundButton}
      embedded={embedded}
      innerRef={innerRef}
      disabled={disabled}
      {...buttonProps}
    />
  )
  return (
    <div
      className={cx(
        styles.root,
        title && styles.hasTitle,
        spaced ? styles.spaced : styles.connected,
        embedded && styles.embedded,
        className
      )}
    >
      {title && (
        <div className={styles.listHeader}>
          {icon && <div className={styles.listHeaderIcon}>{icon}</div>}
          <div className={styles.listTitle}>{title}</div>
        </div>
      )}
      <div className={styles.items}>{children}</div>
      {!dropdownItems && onAddClick && makeButton({ onClick: onAddClick })}
      {dropdownItems && onAdd && (
        <Dropdown stretch className={styles.dropdown} button={makeButton}>
          {dropdownItems.map((d) => (
            <DropdownItem key={d.id} onClick={() => onAdd(d)}>
              <ItemDescription title={d.title}>{d.description}</ItemDescription>
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </div>
  )
}

export const ItemDescription = ({ title, children }) => (
  <div className={styles.itemDescription} data-test={`${title}-item`}>
    <div className={styles.itemDescriptionTitle}>{title}</div>
    <div className={styles.itemDescriptionContent}>{children}</div>
  </div>
)

export const ListItem = ({
  children,
  hoverThresholdFactor,
  source,
  index,
  className,
  pad,
  flex,
  center,
  selected,
  clickable,
  error,
  isEmptyState,
  flat,
  innerRef,
  ...rest
}) => {
  return (
    <div
      className={cx(
        styles.item,
        className,
        flex && styles.itemFlex,
        center && styles.center,
        pad && styles.itemPad,
        selected && styles.itemSelected,
        clickable && styles.itemClickable,
        isEmptyState && styles.itemIsEmptyState,
        error && styles.itemHasError,
        flat && styles.flat
      )}
      ref={innerRef}
      {...rest}
    >
      {children}
    </div>
  )
}

export const ListItemHeader = ({
  className,
  innerRef,
  children,
  borderless,
}) => {
  return (
    <div
      className={cx(
        styles.itemHeader,
        className,
        borderless && styles.borderless
      )}
      ref={innerRef}
    >
      {children}
    </div>
  )
}

export const ListItemHeaderTitle = ({ className, innerRef, children }) => {
  return (
    <div className={cx(styles.itemHeaderTitle, className)} ref={innerRef}>
      {children}
    </div>
  )
}

export const ListItemHeaderActions = ({ className, innerRef, children }) => {
  return (
    <div className={cx(styles.itemHeaderActions, className)} ref={innerRef}>
      {children}
    </div>
  )
}

export const ListItemHeaderInput = ({
  className,
  innerRef,
  value,
  onChange,
  ...rest
}) => {
  return (
    <div className={cx(styles.itemHeaderInput, className)} ref={innerRef}>
      <input
        type="text"
        className={styles.itemHeaderInputField}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        onKeyUp={(e) => e.keyCode === 13 && e.target.blur()}
        {...rest}
      />
    </div>
  )
}

export const ListItemBody = ({
  className,
  flex,
  center,
  pad,
  innerRef,
  children,
}) => {
  return (
    <div
      className={cx(
        className,
        flex && styles.itemBodyFlex,
        pad && styles.itemBodyPad,
        center && styles.center
      )}
      ref={innerRef}
    >
      {children}
    </div>
  )
}

export const ListItemContent = ({ children, className, pad }) => (
  <div className={cx(styles.itemContent, className, pad && styles.pad)}>
    {children}
  </div>
)

export const ListItemActions = ({ children, className, center, pad }) => (
  <div
    className={cx(
      styles.itemActions,
      className,
      center && styles.center,
      pad && styles.pad
    )}
  >
    {children}
  </div>
)

export const ListItemDraggingOverlay = ({ children, visible }) => (
  <div
    className={cx(
      styles.draggingOverlay,
      visible && styles.draggingOverlayVisible
    )}
  >
    {children}
  </div>
)

export const ListItemBadge = ({ children, type = 'default' }) => (
  <span className={cx(styles.itemBadge, styles[type])}>{children}</span>
)

export const ListAddButton = ({
  className,
  innerRef,
  rounded,
  spaced,
  borderless,
  embedded,
  flat,
  ...rest
}) => (
  <a
    {...rest}
    ref={innerRef}
    className={cx(
      'button',
      className,
      styles.addButton,
      !spaced && styles.connected,
      rounded && styles.fullyRounded,
      borderless && styles.borderless,
      embedded && styles.embedded,
      flat && styles.flat
    )}
    data-test="add-button"
  >
    <Icon material name="add" small />
  </a>
)

export const ListItemContentTitle = ({ children, muted }) => (
  <div className={cx(styles.itemContentTitle, muted && styles.muted)}>
    {children}
  </div>
)

export const ListItemContentDescription = ({ children, muted, monospace }) => (
  <div
    className={cx(
      styles.itemContentDescription,
      muted && styles.muted,
      monospace && styles.monospace
    )}
  >
    {children}
  </div>
)
