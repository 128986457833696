import Avatar from 'components/Avatar'
import ContentLoading from 'components/ContentLoading'
import {
  Dropdown,
  DropdownItem,
  DropdownSection,
  DropdownSectionTitle,
} from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import { JobItem, JobItemAvatar, JobItemText } from 'components/JobItem'
import ScrollPanel from 'components/ScrollPanel'
import UserCouponListItem from 'components/UserCouponListItem'
import {
  Widget,
  WidgetContent,
  WidgetHeaderSegment,
  WidgetHeader,
  WidgetTitle,
} from 'components/Widget'
import DocumentList from 'documents/components/DocumentList'
import DocumentSidebar from 'documents/components/DocumentSidebar'
import RequirePerms from 'iam/containers/RequirePerms'
import ScreenProtector from 'iam/containers/ScreenProtector'
import SkillEditorDialog from 'iam/containers/SkillEditorDialog'
import link from 'misc/link'
import { observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { browserHistory } from 'react-router'
import moment from 'moment'
import CreateJobModal from './CreateJobModal'
import ScopeWorkModal from './ScopeWorkModal'
import EditUserModal from './EditUserModal'
import EditUserPermissionsModal from './EditUserPermissionsModal'
import EditUserRolesModal from './EditUserRolesModal'
import AddToTeamModal from './AddToTeamModal'
import { useDropzone } from '@taxfyle/web-commons/lib/hooks/use-dropzone'
import Button from 'components/Button'
import FileButton from 'components/FileButton'
import cx from 'classnames'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import './UserDetails.sass'
import TeamListItem from 'components/TeamListItem'
import WorkspaceListItem from 'components/WorkspaceListItem'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import { anyNonNil } from 'is-uuid'
import { Tooltip } from 'components/Tooltip'
import IconSvg from 'components/IconSvg'
import InfoIcon from 'assets/icons/info-blue.i.svg'
import { toJobLink } from 'utils/generateJobLinkUtil'
import { isDisplayDocumentTagsToggleEnabled } from 'screens/DocumentTags/document-tags-toggle'

const noAccessEmptyState = (
  <EmptyState title="No access">
    You do not have the required permissions to view this information.
  </EmptyState>
)

@observer
export default class UserDetails extends React.Component {
  get lookupPublicId() {
    return this.props.rootStore.userIdLookup.lookupPublicIds
  }

  get store() {
    return this.props.rootStore.userDetailsScreenStore
  }

  get workspaceSlug() {
    return (
      this.props.rootStore.sessionStore.workspace?.slug?.toLowerCase() || ''
    )
  }

  get isMemberPermissionsReadonly() {
    return this.props.rootStore.sessionStore.workspace.config
      ?.member_permissions_config?.readonly
  }

  async componentDidMount() {
    let userId = this.props.params.userPublicId
    const userType = this.props.type

    if (!anyNonNil(userId)) {
      userId = (await this.lookupPublicId([userId]))[userId]

      const userUrl = link.user(
        userId,
        this.store.rootStore.sessionStore.workspace.slug
      )

      browserHistory.replace(userUrl)
    }

    this.store.activate(userId, userType)
  }

  renderUserHeader() {
    const { member, userData } = this.store

    if (!member || !userData) {
      return false
    }

    const currentUser = this.store.rootStore.sessionStore.user

    return (
      <div className="user-header">
        <Helmet title={member.displayName} />
        <div className="user-header__user-info">
          <div className="user-header__avatar-container">
            <Avatar url={member.avatar} size={64} />
          </div>

          <div className="user-header__user-details">
            <div className="user-header__name">{member.displayName}</div>
            <div className="user-header__misc">
              <div className="user-header__info">
                <RequirePerms allOf={['ADMIN_USER_READ']}>
                  <div className="user-header__info-item">
                    User ID: {member.userPublicId}
                  </div>
                  <div className="user-header__info-item">
                    Legacy User ID: {member.userId}
                  </div>
                  <div className="user-header__info-item">
                    Email: {member.email}
                  </div>
                  {member.phone && (
                    <div className="user-header__info-item">
                      Phone: {member.phone}
                    </div>
                  )}
                  <div className="user-header__info-item">
                    Last Update Date:{' '}
                    {moment(member.dateModified).format('LL LT')}
                  </div>
                </RequirePerms>
                <div className="user-header__info-item">
                  Payout Method: <span>{this.store.payoutMethodLabel}</span>
                </div>
                {member.referrer && (
                  <div className="user-header__info-item">
                    Referrer: {member.referrer}
                  </div>
                )}
                {this.store.jobCount && (
                  <>
                    <div className="user-header__info-item">
                      Jobs in current workspace:{' '}
                      {this.store.jobCount.currentWorkspace}
                    </div>

                    <div className="user-header__info-item">
                      Workspace capacity:{' '}
                      {this.store.jobCount.openInCurrentWorkspace}/
                      {member.maxConcurrentProviderJobs || '10000'}
                      <Tooltip
                        content="Count of In Progress and Idle jobs within the workspace"
                        placement="top"
                      >
                        <IconSvg
                          size={15}
                          color="#40A0F4"
                          className="user-header__tooltip"
                        >
                          <InfoIcon />
                        </IconSvg>
                      </Tooltip>
                    </div>

                    <div className="user-header__info-item">
                      Jobs in all workspaces: {this.store.jobCount.all}
                    </div>

                    <div className="user-header__info-item">
                      Global capacity: {this.store.jobCount.open}/
                      {userData.jobCapacity || '10000'}
                      <Tooltip
                        content="Count of In Progress and Idle jobs across all workspaces"
                        placement="top"
                      >
                        <IconSvg
                          size={15}
                          color="#40A0F4"
                          className="user-header__tooltip"
                        >
                          <InfoIcon />
                        </IconSvg>
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
              <div className="user-header__info">
                {Object.keys(member.memberMetadata).map((key) => {
                  const value = member.memberMetadata[key]
                  const memberMetadataFields =
                    this.store.rootStore.sessionStore.workspace
                      .custom_metadata_fields?.member_metadata_fields || [] // member_metadata_fields can be nullish
                  const field = memberMetadataFields.find(
                    (x) => x.id === key && x.active
                  )
                  return field ? (
                    <div key={key} className="user-header__info-item">
                      {field.title}: {String(value)}
                    </div>
                  ) : null
                })}
              </div>
            </div>
          </div>
          <div className="user-header__actions">
            <Dropdown
              position="right"
              button={
                <a className="button is-icon-only">
                  <Icon name="cog" />
                </a>
              }
            >
              <DropdownSection>
                <DropdownSectionTitle>Update</DropdownSectionTitle>
                <RequirePerms allOf={['ADMIN_USER_MODIFY', 'ADMIN_USER_READ']}>
                  <DropdownItem onClick={this.store.showUserUpdate}>
                    Edit User
                  </DropdownItem>
                </RequirePerms>
                <RequirePerms allOf={['ADMIN_USER_SKILLS_MODIFY']}>
                  <DropdownItem onClick={this.store.showSkillsEditor}>
                    Edit User Skills
                  </DropdownItem>
                </RequirePerms>
                <RequirePerms allOf={['ADMIN_USER_ROLES_MODIFY']}>
                  <DropdownItem
                    onClick={this.store.showRolesEditor}
                    data-test="edit-user-roles-item"
                  >
                    Edit User Roles
                  </DropdownItem>
                </RequirePerms>
                <RequirePerms allOf={['ADMIN_USER_PERMISSIONS_MODIFY']}>
                  {this.isMemberPermissionsReadonly ? (
                    <DropdownItem
                      data-test="view-user-permissions-item"
                      onClick={this.store.showPermissionsEditor}
                    >
                      View User Permissions
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      data-test="edit-user-permissions-item"
                      onClick={this.store.showPermissionsEditor}
                    >
                      Edit User Permissions
                    </DropdownItem>
                  )}
                </RequirePerms>
                <RequirePerms allOf={['ADMIN_MANAGE_TEAM_MEMBER']}>
                  <DropdownItem onClick={this.store.showTeamAddModal}>
                    Add User to Team
                  </DropdownItem>
                </RequirePerms>
              </DropdownSection>
              <RequirePerms allOf={['ADMIN_JOB_CREATE']}>
                <DropdownSection>
                  <DropdownSectionTitle>Admin</DropdownSectionTitle>
                  <DropdownItem onClick={this.store.showCreateJobEditor}>
                    Create Job For User
                  </DropdownItem>
                </DropdownSection>
              </RequirePerms>
              <RequirePerms allOf={['SCOPE_WORK']}>
                <DropdownSection>
                  <DropdownItem onClick={this.store.showScopeWorkModal}>
                    Scope Work For User
                  </DropdownItem>
                </DropdownSection>
              </RequirePerms>
              <RequirePerms
                anyOf={[
                  'ADMIN_CLIENT_IMPERSONATE',
                  'ADMIN_PROVIDER_IMPERSONATE',
                ]}
              >
                <DropdownSection>
                  <DropdownSectionTitle>Impersonation</DropdownSectionTitle>
                  <RequirePerms allOf={['ADMIN_CLIENT_IMPERSONATE']}>
                    <DropdownItem
                      onClick={this.store.impersonate.bind(
                        this.store,
                        'client'
                      )}
                    >
                      Impersonate on {T('Web.Common.Client', 'Client')} Portal
                    </DropdownItem>
                  </RequirePerms>
                  <RequirePerms allOf={['ADMIN_PROVIDER_IMPERSONATE']}>
                    <DropdownItem
                      onClick={this.store.impersonate.bind(
                        this.store,
                        'provider'
                      )}
                    >
                      Impersonate on {T('Web.Common.Provider', 'Pro')} Portal
                    </DropdownItem>
                  </RequirePerms>
                </DropdownSection>
              </RequirePerms>
              {currentUser.platformAdmin && (
                <RequirePerms allOf={['ADMIN_EXPORT_CONVERSATION_HISTORY']}>
                  <DropdownSection>
                    <DropdownSectionTitle>Data</DropdownSectionTitle>
                    <DropdownItem onClick={this.store.downloadMessageHistory}>
                      Download All Message History
                    </DropdownItem>
                  </DropdownSection>
                </RequirePerms>
              )}
            </Dropdown>
          </div>
        </div>
      </div>
    )
  }

  renderLoader() {
    return this.store.activate.pending && <ContentLoading />
  }

  renderJobs(jobs, asType) {
    const user = this.store.member
    const opposite = asType === 'client' ? 'provider' : 'customer'

    if (jobs.length === 0) {
      return (
        <EmptyState>
          {asType === 'client'
            ? `${user.displayName} has not submitted any jobs`
            : `${user.displayName} has not picked up any jobs`}
        </EmptyState>
      )
    }

    return (
      <>
        {jobs.map((job) => (
          <JobItem
            key={job.id}
            {...toJobLink(
              job.id,
              this.store.rootStore.sessionStore.workspace.slug
            )}
          >
            {job[opposite] && (
              <JobItemAvatar
                picture={job[opposite].avatar}
                name={job[opposite].displayName}
              />
            )}
            <JobItemText
              title={job.name}
              forms={job.jobSpecs}
              states={job.states}
              status={job.status}
            />
          </JobItem>
        ))}
        <InfiniteScrollZone
          scrollProvider={
            asType === 'client'
              ? this.store.clientJobsScrollProvider[
                  this.store.selectedClientFilter.value
                ]
              : this.store.proJobsScrollProvider[
                  this.store.selectedProFilter.value
                ]
          }
        >
          {({ isDone }) => !isDone && <ContentLoading small />}
        </InfiniteScrollZone>
      </>
    )
  }

  renderCoupons() {
    const userCoupons = this.store.userCoupons || []
    return (
      <div className="coupons">
        {userCoupons.length === 0 && (
          <EmptyState>
            {this.store.member.displayName} has no coupons.
          </EmptyState>
        )}
        {userCoupons.map((coupon) => (
          <UserCouponListItem userCoupon={coupon} key={coupon.id} />
        ))}
      </div>
    )
  }

  renderTeams() {
    const activeTeams = this.store.activeTeams || []
    return (
      <div className="teams">
        {activeTeams.length === 0 && (
          <EmptyState>
            {this.store.member.displayName} is not part of any teams.
          </EmptyState>
        )}
        {activeTeams.map((team) => (
          <TeamListItem key={team.id} team={team} />
        ))}
      </div>
    )
  }

  renderMemberships() {
    const memberships = this.store.memberships || []
    return (
      <div className="memberships">
        {memberships.length === 0 && (
          <EmptyState>
            {this.store.member.displayName} is not part of any workspaces.
          </EmptyState>
        )}
        {memberships.map((m) => (
          <WorkspaceListItem key={m.workspace.id} workspace={m.workspace} />
        ))}
      </div>
    )
  }

  renderWidgets() {
    if (!this.store.member || !this.store.activate.resolved) {
      return null
    }

    const showTags = isDisplayDocumentTagsToggleEnabled()

    return (
      <div className="widgets">
        <div className="columns">
          <div className="column">
            <Widget
              title={
                <>
                  {T('Web.Common.Client', 'Client')} Jobs{' '}
                  <FilterView
                    filterOptions={this.store.filterOptions}
                    changeFilter={this.store.changeClientFilter}
                    selectedFilter={this.store.selectedClientFilter}
                  />
                </>
              }
            >
              <WidgetContent>
                <ScrollPanel
                  maxHeight={500}
                  minHeight={500}
                  className="client-jobs"
                >
                  <RequirePerms allOf={['ADMIN_USER_JOB_LIST']}>
                    {(can) =>
                      can
                        ? this.renderJobs(this.store.clientJobs, 'client')
                        : noAccessEmptyState
                    }
                  </RequirePerms>
                </ScrollPanel>
              </WidgetContent>
            </Widget>
            <br />
            <Widget title={`Workspace Memberships`}>
              <WidgetContent>
                <ScrollPanel
                  maxHeight={500}
                  minHeight={300}
                  className="workspace-memberships"
                >
                  {this.renderMemberships()}
                </ScrollPanel>
              </WidgetContent>
            </Widget>
          </div>
          <div className="column">
            <Widget
              title={
                <>
                  {T('Web.Common.Provider', 'Pro')} Jobs
                  <FilterView
                    filterOptions={this.store.filterOptions}
                    changeFilter={this.store.changeProFilter}
                    selectedFilter={this.store.selectedProFilter}
                  />
                </>
              }
            >
              <WidgetContent>
                <ScrollPanel
                  maxHeight={500}
                  minHeight={500}
                  className="pro-jobs"
                >
                  <RequirePerms allOf={['ADMIN_USER_JOB_LIST']}>
                    {(can) =>
                      can
                        ? this.renderJobs(this.store.providerJobs, 'provider')
                        : noAccessEmptyState
                    }
                  </RequirePerms>
                </ScrollPanel>
              </WidgetContent>
            </Widget>
            <br />
            <RequirePerms allOf={['ADMIN_COUPONS_MANAGE']}>
              <Widget title="Coupons">
                <WidgetContent>{this.renderCoupons()}</WidgetContent>
              </Widget>
            </RequirePerms>
            <br />
            <RequirePerms allOf={['ADMIN_TEAMS_VIEW']}>
              <Widget title="Teams">
                <WidgetContent>{this.renderTeams()}</WidgetContent>
              </Widget>
            </RequirePerms>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <DocumentsView store={this.store} />
          </div>
        </div>
        <DocumentSidebar
          documentStore={this.store.rootStore.documentStore}
          showTags={showTags}
        />
      </div>
    )
  }

  render() {
    return (
      <ScreenProtector allOf={['ADMIN_USER_LIST']}>
        <div className="user-details">
          {this.renderUserHeader()}
          {this.renderLoader()}
          {this.renderWidgets()}
          <AddToTeamModal store={this.props.rootStore.addToTeamStore} />
          <EditUserModal store={this.props.rootStore.editUserStore} />
          <SkillEditorDialog
            allowSelectAllSkills
            store={this.props.rootStore.skillEditorStore}
          />
          <EditUserRolesModal
            store={this.props.rootStore.userRolesEditorStore}
          />
          <EditUserPermissionsModal
            readonly={this.isMemberPermissionsReadonly}
            store={this.props.rootStore.userPermissionsEditorStore}
          />
          <CreateJobModal store={this.props.rootStore.createJobEditorStore} />
          <ScopeWorkModal store={this.props.rootStore.scopeWorkEditorStore} />
        </div>
      </ScreenProtector>
    )
  }
}

const FilterView = observer(function FilterView({
  filterOptions,
  changeFilter,
  selectedFilter,
}) {
  return (
    <Dropdown
      position="right"
      button={({ ref, ...buttonProps }) => (
        <Button
          outline
          borderless
          className="dropdownButton"
          iconRight={<Icon material small name="arrow drop down" />}
          size="small"
          innerRef={ref}
          {...buttonProps}
        >
          {selectedFilter.label}
        </Button>
      )}
    >
      {filterOptions.map((option, i) => (
        <DropdownItem key={i} onClick={() => changeFilter(option)}>
          {option.label}
        </DropdownItem>
      ))}
    </Dropdown>
  )
})

const DocumentsView = observer(function DocumentsView({ store }) {
  const emptyText = `${store.member.displayName} has no documents.`
  const [{ isOver }, dropTargetRef] = useDropzone((files) =>
    store.uploadFiles(files, store.member.userPublicId)
  )
  return (
    <Widget
      className={cx('documents-container', isOver && 'active')}
      ref={dropTargetRef}
    >
      <WidgetHeader>
        <WidgetTitle className="documents-widget-title">
          User Documents
        </WidgetTitle>
        <WidgetHeaderSegment className="file-button-container">
          <RequirePerms allOf={['ADMIN_DOCUMENT_MANAGE']}>
            <FileButton
              onChange={(files) =>
                store.uploadFiles(files, store.member.userPublicId)
              }
              multiple
            >
              <Button
                className="file-button"
                iconLeft={<Icon material small name="file upload" />}
              >
                Upload
              </Button>
            </FileButton>
          </RequirePerms>
        </WidgetHeaderSegment>
      </WidgetHeader>
      <WidgetContent>
        <RequirePerms allOf={['ADMIN_DOCUMENT_MANAGE']}>
          {(can) =>
            can ? (
              <div className="documents">
                <ScrollPanel
                  maxHeight={500}
                  minHeight={300}
                  className="job-documents"
                >
                  <DocumentList
                    emptyText={emptyText}
                    documentStore={store.rootStore.documentStore}
                    documents={store.documents}
                    scrollProvider={store.documentScrollProvider}
                  />
                </ScrollPanel>
              </div>
            ) : (
              noAccessEmptyState
            )
          }
        </RequirePerms>
      </WidgetContent>
    </Widget>
  )
})
