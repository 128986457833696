import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { getUnitFormatter } from 'utils/unitFormatter'
import './styles.sass'

const AmendmentsTable = ({ amendments, noBorder, store }) => (
  <table className={cx('amendments', noBorder && 'amendments--no-border')}>
    <thead>
      <tr>
        <th>Description</th>
        <th>Internal note</th>
        <th className="amendments__visibility-header">Visibility</th>
        <th className="amendments__price-header">Client Price</th>
        <th className="amendments__price-header">Pro Cut</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      {amendments.map((amendment) => (
        <Amendment key={amendment.cid} amendment={amendment} store={store} />
      ))}
    </tbody>
  </table>
)

export default observer(AmendmentsTable)

let Amendment = ({ amendment, store }) => {
  const canSeePrice = store.rootStore.sessionStore.member.hasPermission(
    'VIEW_PRICING'
  )
  const formatUnit = getUnitFormatter(
    amendment.job.legend && amendment.job.legend.priceUnit
  )
  const isCustomerAmountNegative = amendment.customerAmount < 0
  const isProviderAmountNegative = amendment.providerAmount < 0
  return (
    <tr className="amendment">
      <td className="amendment__description">{amendment.description}</td>
      <td className="amendment__internal_note">{amendment.internalNote}</td>
      <td className="amendment__visibility">
        {amendment.visibility === 'PUBLIC' ? (
          <span className="tag is-public">PUBLIC</span>
        ) : (
          <span className="tag is-internal">INTERNAL</span>
        )}
      </td>
      <td
        className={cx(
          'amendment-price amendment-price--customer',
          isCustomerAmountNegative && 'amendment-price--negative'
        )}
      >
        {canSeePrice && formatUnit(amendment.customerAmount)}
      </td>
      <td
        className={cx(
          'amendment-price amendment-price--cpa',
          isProviderAmountNegative && 'amendment-price--negative'
        )}
      >
        {canSeePrice && formatUnit(amendment.providerAmount)}
      </td>
      <td className="amendment__timestamp">
        {amendment.dateCreated.format('LL LT')}
      </td>
    </tr>
  )
}

Amendment = observer(Amendment)
