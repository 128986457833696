import { asNumber } from '@taxfyle/web-commons/lib/utils/numberUtil'
import { snakeizeKeys } from '@taxfyle/web-commons/lib/utils/objectUtil'
import { model, Store } from 'libx'
import mapValues from 'lodash/mapValues'
import pick from 'lodash/pick'
import zipObject from 'lodash/zipObject'
import { action, observable, toJS, computed } from 'mobx'
import { extractMessageFromError } from 'utils/errorUtil'
import { stringLength } from 'utils/validx-validators'
import { pattern, required, validationContext } from 'validx'

const createForm = (validationRules) => {
  const fields = Object.keys(validationRules)
  const form = {}
  form.validation = validationContext(form, validationRules)
  return model(form)
    .extendObservable(mapValues(validationRules, () => undefined))
    .withActions({
      reset() {
        form.validation.reset()
        form.set(
          zipObject(
            fields,
            fields.map((_) => undefined)
          )
        )
        return form
      },
      save() {
        if (form.validation.reset().validate().isValid) {
          return toJS(pick(this, fields))
        }

        return false
      },
    })
}

export default class EditUserStore extends Store {
  @observable
  showing = false

  @observable
  member

  form = this.createEditorForm()

  @computed
  get inputFields() {
    return this.rootStore.sessionStore.workspace.customMetafields.memberMetadataFields.filter(
      (field) => field.active
    )
  }

  @observable
  userData

  @action.bound
  show(member) {
    this.member = member
    this.userData = this.rootStore.userDataStore.userData.find(
      (x) => x.userPublicId === member.userPublicId
    )

    this.showing = true
    this.form = this.createEditorForm().set({
      ...toJS(this.member),
      userType: this.userData.userDataType,
      jobCapacity: this.userData.jobCapacity,
    })
  }

  createEditorForm() {
    return createForm({
      givenName: [required('First name is required'), stringLength(2, 40)],
      familyName: [required('Last name is required'), stringLength(2, 40)],
      email: [
        required('Email is required'),
        pattern({ pattern: 'email' }),
        stringLength(0, 320),
      ],
      phone: [required('Phone is required')],
      userType: [],
      referrer: [],
      maxConcurrentProviderJobs: [
        required(),
        pattern(/\d*/, 'Must be a number'),
      ],
      approvedTermsVersion: [],
      memberMetadata: [],
      jobCapacity: [required(), pattern(/\d*/, 'Must be a number')],
    })
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @action
  onMetadataChange(fieldId, value) {
    this.form.memberMetadata[fieldId] = value
  }

  @action.bound
  save() {
    const { memberStore } = this.rootStore
    const formResult = this.form.save()

    if (!formResult) {
      return
    }

    const msg = this.rootStore.flashMessageStore.create({
      message: 'Saving user...',
      inProgress: true,
    })

    const params = {
      user_id: this.member.userPublicId,
      workspace_id: this.member.workspaceId,
    }

    const data = snakeizeKeys({
      ...formResult,
      maxConcurrentProviderJobs: asNumber(formResult.maxConcurrentProviderJobs),
      jobCapacity: asNumber(formResult.jobCapacity),
    })

    return memberStore
      .updateByPublicId(params, data)
      .then(() => this.updateUserData(data))
      .then(() => this.onSaved(msg))
      .catch((err) => this.showError(msg, err))
  }

  @action.bound
  async updateUserData(data) {
    if (!this.rootStore.authStore.user.platformAdmin) {
      return
    }

    return this.rootStore.userDataStore.updateByPublicId(
      this.userData.userPublicId,
      {
        type: data.user_type,
        job_capacity: data.job_capacity,
      }
    )
  }

  @action.bound
  onSaved(msg) {
    this.hide()
    msg.done('User saved!').autoDismiss()
  }

  @action.bound
  showError(msg, err) {
    msg.failed(extractMessageFromError(err)).autoDismiss()
  }
}
