import { action, observable } from 'mobx'
import { Store } from 'libx'

/**
 * Store that manages the sidebar state.
 */
class SidebarStore extends Store {
  @observable
  showing = true

  constructor() {
    super(...arguments)
    if (window.innerWidth < 768) {
      this.hide()
    }
  }

  @action.bound
  toggle() {
    this.showing = !this.showing
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @action.bound
  show() {
    this.showing = true
  }
}

export default SidebarStore
