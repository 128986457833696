import { observable, action, computed } from 'mobx'
import { receiveErrors, addGenericErrorForProperty } from '../data/serverErrors'
import { snapshotable } from '../data/serialization'
import { validatable } from '../mixins/validation'
import { validateElements } from 'utils/validx-validators'
import MutatorEditor from './MutatorEditor'

/**
 * Mutators builder.
 */
@snapshotable
@validatable()
export default class MutatorsBuilder {
  /**
   * Mutators.
   */
  @observable mutators = []

  rules = {
    mutators: [validateElements()],
  }

  /**
   * Constructor.
   * @param {import('./EffectEditor').default} effect the parent effect
   */
  constructor(effect, legendEditor) {
    this.effect = effect
    this.legendEditor = legendEditor
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Hydrates the builder with mutators.
   */
  hydrate(mutators = []) {
    this.mutators = mutators.map((x) => this._createMutator(x))
    return this
  }

  /**
   * Adds a new Mutator.
   *
   * @param {string} type
   * @param {Object} props
   */
  @action.bound
  add(type, props) {
    const mutator = this._createMutator({ ...props, type })
    this.mutators.push(mutator)
    // Focus the target after adding.
    mutator.target.focus()
    return mutator
  }

  /**
   * Removes the specified mutator.
   *
   * @param {MutatorEditor} mutator
   */
  @action.bound
  remove(mutator) {
    this.mutators.remove(mutator)
  }

  /**
   * Delivers errors to each mutator.
   *
   * @param {*} descriptor
   */
  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'mutators',
      'There are invalid mutators.'
    )
    receiveErrors(this.mutators, descriptor.inner)
  }

  /**
   * Serializes the mutators to JSON.
   */
  toJSON() {
    return this.mutators.map((m) => m.toJSON())
  }

  /**
   * Creates a mutator from a plain object.
   * @param {*} mutatorProps
   */
  _createMutator(mutatorProps) {
    return MutatorEditor.fromJS(mutatorProps, {
      effect: this.effect,
      legendEditor: this.legendEditor,
    })
  }
}
