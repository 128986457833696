import { RynoSymbol, SymbolKind } from '@taxfyle/ryno'
import { snapshotable } from 'legend-builder/data/serialization'
import { Types } from 'legend-builder/data/Type'
import { validatable } from 'legend-builder/mixins/validation'
import RynoEditor from 'legend-builder/ryno-editor/RynoEditor'
import { Model } from 'libx'
import uniqueId from 'lodash/uniqueId'
import { action, computed, observable } from 'mobx'
import { func } from 'validx'

/**
 * Mapping editor.
 */
@validatable({ liveValidation: true })
@snapshotable
export default class MappingEditor extends Model {
  cid = uniqueId('mapping-')

  /**
   * The kind of mapping
   */
  @observable
  type = 'Expr'

  @observable
  expression

  rules = {
    expression: [
      func((ctx) => ctx.value.analyze(), 'There are formula errors.'),
    ],
  }

  /**
   * Used for display etc
   */
  questionEditor = null

  constructor(attrs, opts) {
    super()
    this.legendEditor = opts.legendEditor
    this.templateEditor = opts.templateEditor
    this.questionEditor = attrs.questionEditor
    this.expression = new RynoEditor({
      required: () => this.type === 'Expr',
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => this.questionEditor.valueType,
    })
    this.set(attrs, opts)
  }

  @computed
  get questionId() {
    return this.questionEditor.id
  }

  @computed
  get templateDataSources() {
    return this.templateEditor.templateDataSources
  }

  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * The symbol table to use for the Ryno Semantic Analyzer.
   */
  @computed
  get symbolTable() {
    const table = this.legendEditor.getSymbolTableFor(this)

    this.templateDataSources.forEach((t) => {
      const type = Types.fromJS(t.valueType)
      table.setIdentifierSymbol(
        new RynoSymbol(t.id, type, SymbolKind.Identifier, true)
      )
    })

    return table
  }

  get possibleReturnValues() {
    return this.questionEditor.answerOptions?.options || []
  }

  parse({ kind, questionId, ...rest }) {
    this.type = kind.type
    if (this.type === 'Expr') {
      this.expression.setValue(kind.expr, true)
    }
    return {
      ...rest,
    }
  }

  toJSON() {
    return {
      id:
        this.id ||
        this.legendEditor.newId(
          this.cid,
          `${this.templateEditor.id}_MAPPING_${this.questionId}`
        ),
      kind: {
        type: this.type,
        ...(this.type === 'Expr' && { expr: this.expression.value }),
      },
      questionId: this.questionId,
    }
  }

  /**
   * Receives errors and populates the validation context with them.
   *
   * @param {*} descriptor
   */
  receiveErrors(descriptor) {
    const exprErrors = descriptor.inner?.kind?.inner.expr
    if (exprErrors) {
      this.validation.addErrors({ expression: exprErrors.errors })
    }
  }

  @action.bound
  setType(type) {
    this.validation.reset()
    this.type = type
  }
}

/**
 * Creates a new mapping editor.
 */
MappingEditor.create = function create(attrs, opts) {
  return new MappingEditor(attrs, opts)
}

/**
 * Converts a JS object to the correct mapping class.
 */
MappingEditor.fromJS = function fromJS(attrs, opts) {
  return MappingEditor.create(attrs, {
    parse: true,
    stripUndefined: true,
    ...opts,
  })
}
