import { Model } from 'libx'
import uniqueId from 'lodash/uniqueId'
import { observable, computed } from 'mobx'
import { validatable } from 'legend-builder/mixins/validation'
import { stringLength } from 'utils/validx-validators'
import { required } from 'validx'
import {
  serializable,
  serialize,
  snapshotable,
} from 'legend-builder/data/serialization'
import * as FilterScope from 'legend-builder/consts/FilterScope'
import FiltersBuilder from 'legend-builder/filters/FiltersBuilder'
import { Types } from 'legend-builder/data/Type'
import { receiveErrors } from 'legend-builder/data/serverErrors'

const baseRules = {
  title: [required('Title is required')],
  instructions: [stringLength(0, 80, 'Keep it short – under {max} characters')],
  triggerWhen: [
    ({ obj }) =>
      obj.triggerWhen.validateAll() || 'There are invalid conditions',
  ],
}

@snapshotable
@validatable({ liveValidation: true, liveIgnore: ['id'] }, baseRules)
export default class TaskEditor extends Model {
  cid = uniqueId('task-')

  /**
   * Task title.
   */
  @observable
  @serializable
  title = ''

  /**
   * Instructions for the completing party.
   */
  @observable
  @serializable
  description = ''

  /**
   * What party is responsible for completing this task?
   */
  @observable
  @serializable
  designee = 'Client'

  /**
   * Filters that make this task eligible.
   */
  @serializable
  triggerWhen

  /**
   * Task values are whether they have been completed or not.
   */
  valueType = Types.boolean

  constructor(attrs, opts) {
    super(null, opts)
    this.legendEditor = opts.legendEditor
    this.milestonesBuilder = opts.milestonesBuilder
    const filtersOpts = {
      legendEditor: this.legendEditor,
      scope: [FilterScope.SUBMITTED],
      context: this.milestonesBuilder,
    }
    this.triggerWhen = new FiltersBuilder(filtersOpts)
    this.set(attrs, opts)
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  @computed
  get tabValidations() {
    return {
      GENERAL:
        !this.validation.getError('title') &&
        !this.validation.getError('description'),
      CONDITIONS:
        !this.validation.getError('triggerWhen') &&
        this.triggerWhen.validation.isValid,
    }
  }

  parse({ triggerWhen, requiredFilters, ...json }) {
    this.triggerWhen.hydrate(triggerWhen)
    return {
      ...json,
    }
  }

  toJSON() {
    return {
      id: this.id || this.legendEditor.newId(this.cid, this.title),
      ...serialize(this),
    }
  }

  receiveErrors(descriptor = {}) {
    receiveErrors(this, descriptor.inner)
  }
}

/**
 * Creates a task editor of the correct type.
 */
TaskEditor.create = function create(attrs, opts) {
  return new TaskEditor(attrs, opts)
}

/**
 * Creates a task editor of the correct type and calls `parse`..
 */
TaskEditor.fromJS = function create(attrs, opts) {
  return TaskEditor.create(attrs, { parse: true, ...opts })
}
