import { ConfirmDialogState } from 'components/ConfirmDialog'
import MilestonesViewState from 'jobs/MilestonesViewState'
import { handleErrors } from 'misc/handleErrors'
import link from 'misc/link'
import { action, computed, observable, runInAction } from 'mobx'
import { task, TaskGroup } from 'mobx-task'
import { browserHistory } from 'react-router'

import env from 'misc/env'
import AddProviderState from '../../screens/JobDetails/AddProviderDialog/AddProviderState'
import UpdateDeadlineState from '../../screens/JobDetails/UpdateDeadlineDialog/UpdateDeadlineState'
import AmendmentsState from '../../screens/JobDetails/AmendmentsDialog/AmendmentsState'
import AssignJobToTeamState from '../../screens/JobDetails/AssignJobToTeamDialog/AssignJobToTeamState'
import CloneJobState from '../../screens/JobDetails/CloneJobDialog/CloneJobState'
import ChargeJobState from '../../screens/JobDetails/ChargeJobDialog/ChargeJobState'
import JobInvoiceState from '../../screens/JobDetails/JobInvoiceDialog/JobInvoiceState'
import LegacyJobUpdateState from '../../screens/JobDetails/JobUpdateDialog/LegacyJobUpdateState'
import JobUpdateState from '../../screens/JobDetails/JobUpdateDialog/JobUpdateState'
import JobTransferState from '../../screens/JobDetails/TransferJobDialog/JobTransferState'
import AddCouponToJobState from '../../screens/JobDetails/AddCouponToJobDialog/AddCouponToJobState'

import { downloadPdf } from '@taxfyle/web-commons/lib/utils/pdfUtil'
import moment from 'moment'
import { extractMessageFromError } from 'utils/errorUtil'
import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'
import DraftJobUpdateState from 'screens/JobDetails/DraftJobUpdateDialog/DraftJobUpdateState'

/**
 * Job details store.
 */
class JobDetailsStore {
  @observable job = null
  @observable error = ''
  @observable reason = '' // used by multiple dialogs
  @observable doBilling = true
  @observable billingMethodId = 'default'
  @observable resolution = 'DONE'
  @observable eventCategory = 'ALL'
  @observable _questions = []
  @observable _variables = []
  @observable qualifiedProviders = []
  @observable providersCursor = null
  @observable composerText = ''
  @observable capturedInfoTab = 'QUESTIONS'
  @observable checkedDocuments = []

  constructor({ rootStore }) {
    this.rootStore = rootStore
    this.promptStore = rootStore.promptStore
    this.promptDialogStore = rootStore.promptDialogStore
    this.jobTransferState = new JobTransferState(
      this,
      this.rootStore.memberStore,
      this.rootStore.teamMemberStore
    )
    this.legacyJobUpdateState = new LegacyJobUpdateState(this)
    this.jobUpdateState = new JobUpdateState(this)
    this.draftJobUpdateState = new DraftJobUpdateState(this)
    this.jobInvoiceState = new JobInvoiceState(this)
    this.assignJobToTeamState = new AssignJobToTeamState(this)
    this.cloneJobState = new CloneJobState(this)
    this.chargeJobState = new ChargeJobState(this)
    this.amendmentsState = new AmendmentsState(this)
    this.addProviderState = new AddProviderState(
      this,
      this.rootStore.memberStore,
      this.rootStore.teamMemberStore
    )
    this.updateDeadlineState = new UpdateDeadlineState(
      this.rootStore.jobStore,
      this
    )
    this.addCouponToJobState = new AddCouponToJobState(
      this.rootStore.couponStore,
      this.rootStore.jobStore,
      this
    )
    this.reopenJobConfirm = new ConfirmDialogState()
    this.notifyProsConfirm = new ConfirmDialogState()
    this.transferToPoolConfirm = new ConfirmDialogState()
    this.closeJobConfirm = new ConfirmDialogState()
    this.deleteJobConfirm = new ConfirmDialogState()
    this.deleteMessageConfirm = new ConfirmDialogState()

    this.activate = this.activate.bind(this)
    this.fetchMessages = this.fetchMessages.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.downloadConversation = this.downloadConversation.bind(this)
    this.deleteMessage = this.deleteMessage.bind(this)
    this.fetchDocuments = this.fetchDocuments.bind(this)
    this.fetchPrompts = this.fetchPrompts.bind(this)
    this.fetchEvents = this.fetchEvents.bind(this)
    this.removeProvider = this.removeProvider.bind(this)
    this.fetchQualifiedProviders = this.fetchQualifiedProviders.bind(this)
    this.reopenJob = this.reopenJob.bind(this)
    this.notifyPros = this.notifyPros.bind(this)
    this.transferToPool = this.transferToPool.bind(this)
    this.toggleHoldJob = this.toggleHoldJob.bind(this)
    this.closeJob = this.closeJob.bind(this)
    this.deleteJob = this.deleteJob.bind(this)
    this.linkDocument = this.linkDocument.bind(this)

    this.fetchAllQualifiedProvidersTasks = TaskGroup([
      this.fetchQualifiedProviders,
      this.fetchAllQualifiedProviders,
    ])

    this.loadAllData = this.loadAllData.bind(this)
  }

  @computed
  get isJobMenuActionDeprecated() {
    return getFeatureToggleClient().variation(
      'HQ.JobMenuActionDeprecated',
      false
    )
  }

  @computed
  get pageTitle() {
    return this.job ? this.job.name || '(Untitled Job)' : null
  }

  @computed
  get documentsScrollProvider() {
    if (!this.job) {
      return null
    }
    return this.rootStore.documentStore.getScrollProvider(
      this.job.id,
      (params) =>
        this.rootStore.documentStore.fetchDocumentsForJob({
          jobId: this.job.id,
          ...params,
        })
    )
  }

  @computed
  get documents() {
    return this.rootStore.documentStore.forJob(this.job.id)
  }

  @computed
  get variables() {
    return this._variables || []
  }

  @computed
  get questions() {
    return this._questions
  }

  @computed
  get shouldShowBilling() {
    return (
      this.rootStore.sessionStore.workspace.features.billing.enabled &&
      !this.job.legend.skipBilling &&
      this.job.billingType !== 'EXTERNAL'
    )
  }

  @computed
  get billingMethods() {
    if (!this.shouldShowBilling || !this.job || !this.job.client) {
      return []
    }
    const result = []
    const ind = this.rootStore.billingStore.individualBillingProfile(
      this.job.client.userId
    )
    if (ind) {
      result.push(
        ...ind.billingMethods.map((bm) => ({
          ...bm,
          isDefault: bm.id === ind.defaultBillingMethodId,
        }))
      )
    }
    const teamBps = this.rootStore.billingStore.teamBillingProfiles(
      this.job.client.userPublicId
    )
    if (teamBps) {
      teamBps.forEach((x) =>
        result.push(
          ...x.billingMethods.map((bm) => ({
            ...bm,
            isDefault: bm.id === x.defaultBillingMethodId,
            team: this.rootStore.teamStore.teams.find(
              (x) => x.id === bm.team_id
            ),
          }))
        )
      )
    }
    return result
  }

  @computed
  get conversation() {
    if (!this.job) {
      return null
    }

    return this.rootStore.messageStore.conversations.get(
      this.job.conversationId
    )
  }

  @computed
  get stripePurchaseTransactions() {
    if (!this.job) {
      return []
    }

    return this.rootStore.billingStore
      .transactionsForJob(this.job.transactions)
      .filter(
        (x) => x && x.transactionType === 'PURCHASE' && x.metadata.receipt_url
      )
  }

  @computed
  get promptsScrollProvider() {
    return this.promptStore.getScrollProvider(this.job.id, (params) =>
      this.fetchPrompts(params)
    )
  }

  @computed
  get prompts() {
    if (!this.job) {
      return []
    }

    return this.promptStore.forJob(this.job.id)
  }

  @computed
  get canSendPrompt() {
    return (
      this.rootStore.authStore.user.platformAdmin &&
      this.job.status !== 'CLOSED'
    )
  }

  get isQuestionsV3() {
    return getFeatureToggleClient().variation('HQ.QuestionsV3', false)
  }

  @task
  async activate(jobId) {
    this.resetState(jobId)
    this.rootStore.documentStore.setView(null)

    const hasAnyPerm = (...p) =>
      this.rootStore.sessionStore.member.hasSomePermissions(...p)

    const job = await this.rootStore.jobStore.getJob(jobId)
    if (job.archived) {
      browserHistory.replace(
        link.jobs(this.rootStore.sessionStore.workspace.slug)
      )
      return
    }
    runInAction(() => {
      this.job = job
      // if the job is not claimed, held or idle the default resolution is cancelled
      if (
        this.job.status !== 'CLAIMED' &&
        this.job.status !== 'ON_HOLD' &&
        this.job.status !== 'IDLE'
      ) {
        this.resolution = 'CANCELLED'
      }

      this.createMilestonesViewState()
    })

    if (this.rootStore.sessionStore.member.hasPermission('ADMIN_TEAMS_VIEW')) {
      await this.rootStore.teamStore.findTeams({
        workspace_id: this.rootStore.sessionStore.workspace.id,
      })
    }

    if (
      this.shouldShowBilling &&
      this.rootStore.sessionStore.member.hasPermission('ADMIN_BILLING_MANAGE')
    ) {
      if (this.job.provider) {
        await this.rootStore.billingStore.fetchAllBillingProfiles(
          job.provider.userPublicId
        )
      }
      if (this.job.client) {
        await this.rootStore.billingStore.fetchAllBillingProfiles(
          job.client.userPublicId
        )
      }
    }

    await handleErrors({
      rootStore: this.rootStore,
      fn: async () => {
        await Promise.all([
          hasAnyPerm('ADMIN_BILLING_VIEW', 'ADMIN_BILLING_MANAGE') &&
            this.fetchTransactions(),
          hasAnyPerm('ADMIN_CONVERSATION_VIEW') && this.fetchMessages(),
          hasAnyPerm('ADMIN_DOCUMENT_MANAGE') && this.fetchDocuments(),
          this.fetchEvents(),
          this.fetchQuestions(),
          this.promptsScrollProvider.fetch(),
        ])
      },
      swallow: false,
    })
  }

  @task.resolved
  async linkDocument(document) {
    return this.rootStore.documentAccessStore.createAccess(document.id, {
      access: {
        type: 'Job',
        job_id: this.job.id,
      },
    })
  }

  @task
  fetchTransactions() {
    return this.rootStore.billingStore.fetchTransactions(this.job.transactions)
  }

  @task
  fetchMessages() {
    if (!this.job.conversationId) {
      return Promise.resolve()
    }
    return this.rootStore.messageStore.fetchMessagesForConversation(
      this.job.conversationId
    )
  }

  @computed
  get messages() {
    if (!this.job.conversationId) {
      return []
    }
    return this.rootStore.messageStore.messagesIn(this.job.conversationId)
  }

  @task
  fetchDocuments() {
    return this.documentsScrollProvider.fetch()
  }

  @task
  async fetchPrompts(params) {
    if (!this.job) {
      return { data: [] }
    }

    return this.promptStore
      .fetchPromptsForJob({
        jobId: this.job.id,
        ...params,
      })
      .catch(() => {
        // Uncomment to flash error.
        // this.rootStore.flashMessageStore
        //   .create(extractMessageFromError(err))
        //   .failed()

        // Fail silently.
        return { data: [] }
      })
  }

  @task
  fetchEvents() {
    return this.rootStore.eventStore.fetchJobEvents(this.job.id)
  }

  @task
  async fetchQuestions() {
    let questions = []
    let variables = []

    if (this.isQuestionsV3) {
      await this.rootStore.questionStore.fetchQuestions(this.job.id, [])
      questions = this.rootStore.questionStore.questionsForJob(this.job.id)

      await this.rootStore.questionStore.fetchVariables(this.job.id)
      variables = this.rootStore.questionStore.variablesForJob(this.job.id)
    } else {
      ;({
        questions,
        variables,
      } = await this.rootStore.questionStore.getQuestions({
        job: this.job.id,
        include_variables: true,
      }))
    }

    runInAction(() => {
      this._questions = questions
      this._variables = variables
    })
  }

  @action.bound
  updateCheckedDocuments(documentId, isChecked) {
    if (isChecked) {
      this.checkedDocuments.push(documentId)
    } else {
      const indexToRemove = this.checkedDocuments.indexOf(documentId)
      if (indexToRemove > -1) {
        this.checkedDocuments.splice(indexToRemove, 1)
      }
    }
  }

  @action.bound
  changeEventCategory(value) {
    this.eventCategory = value
  }

  @action.bound
  changeCapturedInfoTab(tab) {
    this.capturedInfoTab = tab
  }

  @action.bound
  initiateJobTransferClient() {
    this.resetReason()
    this.jobTransferState.show('client')
  }

  @action.bound
  initiateJobTransferPro() {
    this.resetReason()
    this.jobTransferState.show('pro')
  }

  @action.bound
  initiateJobUpdate() {
    this.resetReason()
    if (this.job.status === 'UNDER_CONSTRUCTION') {
      this.draftJobUpdateState.init(this.job)
      this.draftJobUpdateState.show()
      return
    }

    if (this.isQuestionsV3) {
      this.jobUpdateState.init(this.job)
      this.jobUpdateState.show()
      return
    }

    this.legacyJobUpdateState.init(this.job)
    this.legacyJobUpdateState.show()
  }

  @action.bound
  initiateAddInvoiceToJob() {
    this.jobInvoiceState.init(this.job)
    this.jobInvoiceState.show()
  }

  @action.bound
  initiateAssignJobToTeam() {
    this.assignJobToTeamState.init(this.job)
    this.assignJobToTeamState.show()
  }

  @action.bound
  initiateCloneJob() {
    this.cloneJobState.init(this.job)
    this.cloneJobState.show()
  }

  @action.bound
  initiateUpdateDeadline() {
    this.updateDeadlineState.init()
    this.updateDeadlineState.show()
  }

  @action.bound
  initiateChargeJob() {
    this.chargeJobState.init(this.job, this.billingMethods)
    this.chargeJobState.show()
  }

  @action.bound
  initiateAmendments() {
    this.amendmentsState.init(this.job)
    this.amendmentsState.show()
  }

  @action.bound
  initiateAddCouponToJob() {
    this.addCouponToJobState.init()
    this.addCouponToJobState.show()
  }

  @action.bound
  newAmendment() {
    this.initiateAmendments()
    this.amendmentsState.newAmendment()
  }

  @action.bound
  async refreshConversation() {
    const msg = this.rootStore.flashMessageStore.create({
      inProgress: true,
      message: 'Getting latest messages.',
    })

    await this.fetchMessages()

    msg.done('Done').autoDismiss()
  }

  @action.bound
  async showAddProvider(provider) {
    this.addProviderState.show()
    if (provider) {
      const member = await this.rootStore.memberStore.fetchByPublicId({
        workspace_id: provider.workspaceId,
        user_public_id: provider.userPublicId,
      })
      this.addProviderState.selectProvider(member)
      this.addProviderState.type = 'CHAMPION'
    }
  }

  @action.bound
  async showSendPrompt() {
    this.rootStore.sendPromptDialogStore.show()
  }

  async loadAllData() {
    if (this.qualifiedProviders.length === 0) {
      this.rootStore.flashMessageStore
        .create({
          type: 'error',
          message: 'No Providers to export.',
        })
        .autoDismiss()
      return
    }

    const data = this.qualifiedProviders.map((x) => {
      let custom = []
      if (x.memberMetadata) {
        custom = Object.keys(x.memberMetadata).map((key) => ({
          [key]: x.memberMetadata[key],
        }))
      }
      const result = {
        jobId: this.job.id,
        firstName: x.givenName,
        lastName: x.familyName,
        email: x.email,
        userId: x.userId,
      }
      return Object.assign(result, ...custom)
    })

    return {
      filename: 'export.csv',
      data: data,
      headers: [
        { label: 'First Name', key: 'firstName' },
        { label: 'Last Name', key: 'lastName' },
        { label: 'Email', key: 'email' },
        { label: 'User Id', key: 'userId' },
        { label: 'Job Id', key: 'jobId' },
        ...(
          this.rootStore.sessionStore.workspace.custom_metadata_fields
            .member_metadata_fields || []
        ).map((x) => ({ label: x.title, key: x.id })),
      ],
    }
  }

  @action.bound
  changeComposerText(newValue) {
    this.composerText = newValue
  }

  @action.bound
  resetReason() {
    this.reason = ''
  }

  @action.bound
  resetState(jobId) {
    if (!this.job || this.job.id !== jobId) {
      this.fetchQualifiedProviders.reset()
      this.qualifiedProviders = []
    }
    this.reopenJobConfirm.hide()
    this.notifyProsConfirm.hide()
    this.transferToPoolConfirm.hide()
    this.closeJobConfirm.hide()
    this.deleteJobConfirm.hide()
    this.removeProvider.reset()
    this.resetReason()
    this.job = null
    this.providersCursor = null
    this.doBilling = true
    this.resolution = 'DONE'
    this.composerText = ''
  }

  @action.bound
  createMilestonesViewState() {
    this.milestonesViewState = new MilestonesViewState({
      ...this.rootStore,
      job: this.job,
    })
  }

  @action.bound
  async stageFiles(files) {
    this.rootStore.documentStore.newDocument(files, {
      access: {
        type: 'Job',
        job_id: this.job.id,
      },
    })
  }

  @action.bound
  openInWorkPortal() {
    const waitFn = `document.write('Please wait, generating job URL.')`
    const newTab = window.open('javascript:' + waitFn, '_blank')
    newTab.location.assign(`${env.PROVIDER_PORTAL_URL}/jobs/${this.job.id}`)
  }

  downloadAll = task.resolved(async () => {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Creating archive of all attached documents, please wait..',
      inProgress: true,
    })
    try {
      let result = await this.documentsScrollProvider.more()
      while (result.cursor) {
        result = await this.documentsScrollProvider.more()
      }

      await this.rootStore.documentStore.zip(this.documents)
      msg.done('Archive created, downloading now!').autoDismiss()
    } catch (err) {
      throw msg.forError(err)
    }
  })

  downloadCheckedDocuments = task.resolved(async () => {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Creating archive of documents in the list, please wait..',
      inProgress: true,
    })

    try {
      const docsToDownload = this.documents.filter((doc) =>
        this.checkedDocuments.includes(doc.id)
      )
      await this.rootStore.documentStore.zip(docsToDownload)
      msg.done('Archive created, downloading now!').autoDismiss()
    } catch (err) {
      throw msg.forError(err)
    }
  })

  hasSomePermissions(...p) {
    return this.rootStore.sessionStore.member.hasSomePermissions(...p)
  }

  hasAllPermissions(...p) {
    return this.rootStore.sessionStore.member.hasAllPermissions(...p)
  }

  @task.resolved
  async removeProvider(provider, reason) {
    // Confirmation handled in CollaboratorListItem.
    this.rootStore.jobStore.removeProvider(this.job.id, {
      provider,
      reason,
    })
  }

  @task.resolved
  async fetchAllQualifiedProviders() {
    runInAction(() => {
      this.qualifiedProviders = []
    })
    const msg = this.rootStore.flashMessageStore.create({
      message: `Fetching Providers`,
      inProgress: true,
    })
    try {
      let cursor = null
      do {
        const result = await this.rootStore.jobStore.fetchQualifiedProviders(
          this.job.id,
          cursor
        )
        cursor = result.cursor
        this.qualifiedProviders.push(...result.users)
        this.providersCursor = cursor
        msg.set({
          message: `Found ${this.qualifiedProviders.length} so far, loading more...`,
        })
      } while (cursor)
      msg
        .done(
          `Found ${this.qualifiedProviders.length} candidate${
            this.qualifiedProviders.length.length !== 1 ? 's' : ''
          }`
        )
        .autoDismiss()
    } catch (error) {
      throw msg.forError(error).autoDismiss()
    }
  }

  @task.resolved
  async fetchQualifiedProviders() {
    const msg = this.rootStore.flashMessageStore.create({
      message: `Fetching Providers`,
      inProgress: true,
    })
    try {
      const {
        users,
        cursor,
      } = await this.rootStore.jobStore.fetchQualifiedProviders(
        this.job.id,
        this.providersCursor
      )
      msg
        .done(`Found ${users.length} candidate${users.length !== 1 ? 's' : ''}`)
        .autoDismiss()
      this.qualifiedProviders.push(...users)
      this.providersCursor = cursor
    } catch (error) {
      throw msg.forError(error)
    }
  }

  @action.bound
  clearQualifiedPros() {
    this.qualifiedProviders = []
    this.providersCursor = null
  }

  @task.resolved
  async reopenJob() {
    this.resetReason()
    const shouldReopen = await this.reopenJobConfirm.show()
    if (!shouldReopen) {
      this.reopenJobConfirm.hide()
      return
    }

    try {
      await this.rootStore.jobStore.reopenJob(this.job, {
        reason: this.reason,
      })
      this.reopenJobConfirm.hide()
    } catch (err) {
      console.error(err)
      // Trigger this method again.
      this.reopenJob()
    }
  }

  @task.resolved
  async notifyPros() {
    if (!(await this.notifyProsConfirm.show())) {
      return
    }

    await this.rootStore.jobStore.notifyPros(this.job.id)
  }

  @task.resolved
  async transferToPool() {
    this.resetReason()
    if (!(await this.transferToPoolConfirm.show())) {
      return
    }

    await this.rootStore.jobStore.transferToPool(this.job.id, {
      reason: this.reason,
    })
  }

  @task.resolved
  async toggleHoldJob() {
    if (this.job.status === 'CLAIMED' || this.job.status === 'IDLE') {
      await this.rootStore.jobStore.holdJob(this.job.id)
    } else {
      await this.rootStore.jobStore.resumeJob(this.job.id)
    }
  }

  @task.resolved
  async closeJob() {
    this.resetReason()
    if (!(await this.closeJobConfirm.show())) {
      return
    }

    switch (this.resolution) {
      case 'DONE':
        await this.rootStore.jobStore.completeJob(this.job.id, {
          reason: this.reason,
          doBilling: this.doBilling && this.shouldShowBilling,
          billingMethodId: this.billingMethodId,
        })
        break
      case 'CANCELLED':
        await this.rootStore.jobStore.cancelJob(this.job.id, {
          reason: this.reason,
          doBilling: this.doBilling && this.shouldShowBilling,
          billingMethodId: this.billingMethodId,
        })
        break
      case 'TEST':
        await this.rootStore.jobStore.closeJobAsTest(this.job.id, {
          reason: this.reason,
          doBilling: this.doBilling && this.shouldShowBilling,
          billingMethodId: this.billingMethodId,
        })
        break
    }
  }

  @task.resolved
  async deleteJob() {
    if (!(await this.deleteJobConfirm.show())) {
      return
    }
    await this.rootStore.jobStore.deleteJobs([this.job.id])
    browserHistory.replace(
      link.jobs(this.rootStore.sessionStore.workspace.slug)
    )
  }

  async deleteMessage(id) {
    if (!(await this.deleteMessageConfirm.show())) {
      return
    }
    await this.rootStore.messageStore.deleteMessage(id)
  }

  async downloadConversation() {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Downloading conversation ...',
      type: 'default',
      inProgress: true,
    })
    try {
      let hasMore = this.conversation.hasMoreMessages
      while (hasMore) {
        await this.rootStore.messageStore.loadMore(
          this.job.conversationId,
          this.job.ownerTeamId
        )
        hasMore = this.conversation.hasMoreMessages
      }

      const result = [
        {
          type: 'title',
          text: `Conversation History for ${this.job.name}`,
        },
      ]
      result.push(
        ...this.rootStore.messageStore
          .messagesIn(this.job.conversationId)
          .map((m) => ({
            type: 'text',
            text: `${m.sentAt.format('MMM DD, YYYY, h:mm A')} - ${
              m.sender.displayName
            }: ${m.text}`,
          }))
      )

      await downloadPdf(
        result,
        `Conversation-${this.job.name}-${moment().format(
          'MMM DD, YYYY, h:mm A'
        )}`
      )

      msg.done('Done.').autoDismiss()
    } catch (err) {
      msg.failed(extractMessageFromError(err))
    }
  }

  async sendMessage(modeNote) {
    if (!this.composerText || !this.composerText.trim()) {
      return
    }

    if (modeNote) {
      await this.rootStore.messageStore.sendNote({
        conversationId: this.job.conversationId,
        text: this.composerText,
      })
    } else {
      await this.rootStore.messageStore.sendMessage({
        conversationId: this.job.conversationId,
        text: this.composerText,
      })
    }

    runInAction(() => {
      this.composerText = ''
    })
  }
}

export default JobDetailsStore
