import React, { useEffect } from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet-async'
import PageWrapper, { PageTitle } from 'components/PageWrapper'
import Button from 'components/Button'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import IconButton from 'components/IconButton'
import Icon from 'components/Icon'
import ReplyEditor from './ReplyEditor'
import ConfirmDialog from 'components/ConfirmDialog'
import ShadowBox from 'components/ShadowBox'
import ScreenProtector from 'iam/containers/ScreenProtector'
import styles from './MessageBankScreen.m.sass'
import { ListItem, ListItemSegment, ListItemTitle } from 'components/ListItem'

export default observer(function MessageBankScreen({ rootStore }) {
  const { messageBankScreenStore } = rootStore

  useEffect(() => messageBankScreenStore.activate(), [])

  return (
    <ScreenProtector allOf={['MESSAGE_BANK_CREATE']}>
      <Helmet title="Message Bank" />
      <PageWrapper className={cx(styles.root)}>
        {messageBankScreenStore.activate.match({
          pending: () => <ContentLoading />,
          rejected: (error) => <ContentError error={error} />,
          resolved: () => (
            <>
              {messageBankScreenStore.hasMessageBank ? (
                <>
                  <PageTitle center>
                    Message Bank &nbsp;
                    <Button
                      iconLeft={<Icon material name="add" />}
                      type="primary"
                      onClick={messageBankScreenStore.create.bind(
                        messageBankScreenStore
                      )}
                    >
                      Add saved reply
                    </Button>
                  </PageTitle>

                  <List store={messageBankScreenStore} />

                  <ConfirmDialog
                    position="top"
                    state={messageBankScreenStore.confirmDelete}
                    title="Delete saved reply"
                    yes="Delete"
                    danger
                  >
                    {messageBankScreenStore.replyToDelete && (
                      <p>
                        Are you sure that you want to permanently delete the{' '}
                        <strong>
                          {messageBankScreenStore.replyToDelete.title}
                        </strong>{' '}
                        saved reply from your message bank?
                      </p>
                    )}
                  </ConfirmDialog>
                </>
              ) : (
                <NoMessageBank store={messageBankScreenStore} />
              )}
            </>
          ),
        })}
      </PageWrapper>
    </ScreenProtector>
  )
})

const List = observer(({ store }) => {
  return (
    <div className={styles.listContainer}>
      <div className={styles.list}>
        {store.newItemVM.showing && <ReplyEditor editor={store.newItemVM} />}

        {store.replies.map((reply) => (
          <div className={styles.item} key={reply.id}>
            {store.isEditingReply(reply) ? (
              <ReplyEditor editor={store.getEditingVMFor(reply)} />
            ) : (
              <Item
                item={reply}
                onEditClick={() => store.startEditing(reply)}
                onDeleteClick={() => store.delete(reply)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
})

const Item = observer(({ item, onEditClick, onDeleteClick }) => {
  return (
    <ShadowBox>
      <ListItem>
        <ListItemSegment pad stretch>
          <ListItemTitle>{item.title}</ListItemTitle>
          <p>
            Created {item.member && <span>by {item.member.displayName}</span>}
            <span> on {item.dateCreated.format('MMMM Do, YYYY')}</span>
          </p>
        </ListItemSegment>
        <ListItemSegment pad>
          <IconButton onClick={() => onEditClick()}>
            <Icon material name="edit" />
          </IconButton>
          &nbsp;
          <IconButton onClick={onDeleteClick}>
            <Icon material name="delete" />
          </IconButton>
        </ListItemSegment>
      </ListItem>
    </ShadowBox>
  )
})

export const NoMessageBank = observer(function NoMessageBank({ store }) {
  return (
    <div className={styles.emptyContainer}>
      <div className={styles.emptyIllustration}>
        <img src={require('./message_bank_empty.svg')} alt="Illustration" />
      </div>
      <div className={styles.emptyDetails}>
        <h1 className={styles.emptyHeader}>Message bank</h1>
        <p className={styles.emptySubheader}>
          Curate a collection of saved replies that are available to use through
          the workspace during conversations.
        </p>

        <Button
          loading={store.onEmptyScreenAddReply.pending}
          type="primary"
          onClick={store.onEmptyScreenAddReply.bind(store)}
        >
          Add Saved Reply
        </Button>
      </div>
    </div>
  )
})
