import React from 'react'
import moment from 'moment'
import cx from 'classnames'
import { numberToLocaleString } from 'utils/numberUtil'
import styles from './TypeValue.m.sass'
import {
  isNumberType,
  isStringType,
  isBoolType,
  isDateType,
} from '@taxfyle/ryno'

const TypeValue = ({ value, children, type }) => {
  const styleName = typeToStyleName(type)
  return (
    <div className={cx(styles.root, styles[styleName])}>
      {renderValue(value, styleName) || children}
    </div>
  )
}

export default TypeValue

function renderValue(value, styleName) {
  if (value === null || value === undefined) {
    return ''
  }

  switch (styleName) {
    case 'number':
      return numberToLocaleString(value)
    case 'date':
      return moment(value).format('LL LT')
    case 'bool':
      return value ? 'Yes' : 'No'
    default:
      return value.toString()
  }
}

function typeToStyleName(type) {
  if (!type) {
    return 'unknown'
  }

  if (typeof type === 'string') {
    return type
  }

  switch (true) {
    case isNumberType(type) || type.type === 'Number':
      return 'number'
    case isStringType(type) || type.type === 'String':
      return 'string'
    case isBoolType(type) || type.type === 'Boolean':
      return 'bool'
    case isDateType(type) || type.type === 'Date':
      return 'date'
    default:
      return 'unknown'
  }
}
