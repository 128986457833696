import { observable, computed, reaction, action } from 'mobx'
import { validateObject } from 'utils/validx-validators'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import { cascadeValidation } from 'legend-builder/mixins/validation'
import FilterEditor from '../../FilterEditor'
import { receiveErrors } from '../../../data/serverErrors'
import { Types } from '../../../data/Type'

@snapshotable
export default class BinaryOperationEditor extends FilterEditor {
  static friendlyName = 'Binary Operation'
  static friendlyDescription = 'Perform a binary operation on an answer.'

  @serializable
  target = null

  @observable
  @serializable
  op = 'Eq'

  @observable
  @serializable
  value = null

  rules = {
    target: [validateObject()],
    op: [this._validateOp.bind(this)],
    value: [this._validateValue.bind(this)],
  }

  constructor(attrs, opts) {
    super(null, { ...opts, parse: false })

    this.target = this.legendEditor.createSymbolConnector({
      context: this.context,
      required: true,
    })

    // We want `target.validation.errors` to appear in `this.validation.errors['target']`.
    cascadeValidation(this, ['target'])

    this.set(attrs, { parse: true })

    // Invalidate the Op in case the available ops change.
    reaction(
      () => this.availableOps,
      (ops) => {
        if (ops.some((x) => this.op === x[0]) === false) {
          this.op = ops[0][0]
        }
      }
    )
    // Validate the value when the symbol type changes
    reaction(
      () => this.target.valueType,
      (type) => {
        if (this.value !== null) {
          this._tryCoerceValue(type)
          this.validate(['value'])
        }
      },
      {
        fireImmediately: true,
      }
    )
    // Validate the symbol ref when the symbol changes
    this.target.bubbleErrorsTo(this, 'target')
  }

  parse({ target, ...rest }) {
    this.target.hydrate(target)
    return rest
  }

  @computed
  get valueInputType() {
    return Types.isListType(this.target.valueType)
      ? this.target.valueType.elementType
      : this.target.valueType
  }

  @computed
  get availableOps() {
    if (Types.isListType(this.target.valueType)) {
      return [
        ['Contains', 'contains'],
        ['NotContains', 'does not contain'],
      ]
    }

    if (Types.isNumberType(this.target.valueType)) {
      return [
        ['Eq', 'is'],
        ['NotEq', 'is not'],
        ['Gt', 'greater than'],
        ['Gte', 'greater than or equal'],
        ['Lt', 'less than'],
        ['Lte', 'less than or equal'],
      ]
    }

    return [
      ['Eq', 'is'],
      ['NotEq', 'is not'],
    ]
  }

  receiveErrors(descriptor) {
    const { inner = {} } = descriptor
    receiveErrors(this, {
      target: inner.target,
      op: inner.op,
      value: inner.eq || inner.value,
    })
  }

  _validateValue({ value }) {
    if (
      this.target.symbol &&
      this.target.symbol.answerOptions &&
      this.target.symbol.answerOptions.type === 'select'
    ) {
      return (
        this.target.symbol.answerOptions.options.some(
          (o) => o.value === value
        ) || `${value} is not an option for ${this.target.symbol.id}`
      )
    }

    const result = this.legendEditor.validateValueType(
      this.valueInputType,
      value
    )

    return result ? result[0] : true
  }

  _validateOp({ value }) {
    if (this.value === null || !this.target) {
      return true
    }

    const available = this.availableOps.some(([op]) => op === value)
    if (!available) {
      return `This operation is not available for type "${this.target.valueType.name}"`
    }
    return true
  }

  /**
   * When the type changes, try to coerce the value we have into the required type.
   *
   * @param {*} type
   */
  @action
  _tryCoerceValue(type) {
    if (!type) {
      return
    }

    if (Types.isListType(type)) {
      return this._tryCoerceValue(type.elementType)
    }

    if (Types.isStringType(type)) {
      if (typeof this.value === 'boolean') {
        this.value = this.value ? 'Yes' : 'No'
      } else {
        this.value = this.value.toString()
      }
      return
    }

    if (Types.isNumberType(type)) {
      const num = Number(this.value)
      this.value = !Number.isNaN(num) ? num : 0
      return
    }

    if (Types.isBoolType(type)) {
      this.value = Boolean(this.value)
    }
  }
}
