import { Model } from 'libx'
import { observable, action } from 'mobx'
import { validationContext, required } from 'validx'
import ConfirmDialogState from 'components/ConfirmDialog/ConfirmDialogState'
import { concat, map } from 'lodash'

export default class CustomMenuLinksBuilder {
  @observable
  links = []

  @observable
  roles = []

  confirmDestruction = new ConfirmDialogState()

  validation = validationContext(this, {
    links: [
      ({ value }) =>
        value.every((r) => r.validation.reset().validate().isValid) ||
        'Some links are invalid.',
    ],
  })

  activate(links = [], roleStore, workspaceId) {
    this.links.replace(links.map((r) => createCustomLinkEditor(r, this)))

    /*
        TODO: the role selections need to capture the role id. This array will probably
        need to be role objects with name and id.
    */
    if (workspaceId) {
      roleStore.api.roles.find({ workspace_id: workspaceId }).then((result) => {
        const someRoles = concat(
          [[{ text: 'All', value: '0' }]],
          map(result, (r) => [{ text: r.name, value: r.id }])
        )
        this.roles.replace(someRoles)
      })
    }
  }

  toJSON() {
    return this.links.map((x) => x.toJSON())
  }

  @action.bound
  add(data) {
    this.links.push(createCustomLinkEditor(data, this))
  }

  @action.bound
  remove(editor) {
    return this.confirmDestruction.show().then((shouldDelete) => {
      if (!shouldDelete) {
        return
      }
      this.links.remove(editor)
    })
  }

  @action.bound
  startEditing(editor) {
    editor.isNew = false
    editor.previousValues = {
      label: editor.label,
      url: editor.url,
      target: editor.target,
      audience: editor.audience,
      icon: editor.icon,
    }
    editor.editing = true
  }

  @action.bound
  save(editor) {
    const context = editor.validation.reset().validate()
    if (context.errorsMap.size === 0) {
      editor.editing = false
    }
  }

  @action.bound
  cancelEditing(editor) {
    if (editor.isNew) {
      this.links.pop()
    } else {
      // reset values
      editor.label = editor.previousValues.label
      editor.url = editor.previousValues.url
      editor.icon = editor.previousValues.icon
      editor.target = editor.previousValues.target
      editor.audience = editor.previousValues.audience
      editor.previousValues = {}
    }

    editor.editing = false
  }
}

class CustomLinkEditor extends Model {
  type = 'customLink'
  isNew = true
  previousValues = {}

  @observable
  label = ''

  @observable
  url = ''

  @observable
  target = 'All'

  @observable
  audience = 'All'

  @observable
  icon = ''

  @observable
  editing = false

  validation = validationContext(this, {
    label: [required()],
    url: [required()], // we dont do url validation bc pwc wants mailto:help@pwc.com
    target: [required()],
    audience: [required()],
  })

  toJSON() {
    return {
      label: this.label,
      url: this.url,
      target: this.target,
      audience: this.audience,
      icon: this.icon,
    }
  }

  parse({
    label = '',
    url = '',
    target = 'All',
    audience = 'All',
    icon = '',
    editing = false,
  }) {
    return { label, url, target, audience, icon, editing }
  }
}

function createCustomLinkEditor(data, builder) {
  return new CustomLinkEditor(data, {
    builder,
    parse: true,
    stripUndefined: true,
  })
}
