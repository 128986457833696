import { computed, observable } from 'mobx'
import { required } from 'validx'
import { validateObject } from 'utils/validx-validators'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import { addGenericErrorForProperty } from 'legend-builder/data/serverErrors'
import { Types } from 'legend-builder/data/Type'
import QuestionEditor from '../../QuestionEditor'

@snapshotable
export default class BoolQuestionEditor extends QuestionEditor {
  static friendlyType = 'Yes/No'
  static type = 'Bool'
  static viewOrder = 1
  static iconColor = '#0967B9'

  valueType = Types.boolean

  @observable
  @serializable
  question = 'New Yes/No Question'

  @observable
  @serializable
  description = ''

  @serializable
  yes

  @serializable
  no

  rules = {
    question: [required('Question is required')],
    yes: [validateObject('There are invalid effects')],
    no: [validateObject('There are invalid effects')],
  }

  constructor(attrs, opts) {
    // Doing this will call prop initializers before calling `set`.
    super(null, opts)
    this.yes = this.createEffect()
    this.no = this.createEffect()
    this.set(attrs, opts)
  }

  /**
   * Used by the UI to determine how to prompt for a possible answer,
   * as well as validations to ensure that answers are valid.
   */
  @computed
  get answerOptions() {
    return {
      type: 'select',
      options: [
        {
          id: 'yes',
          value: true,
          text: this.textYes || 'Yes',
        },
        {
          id: 'no',
          value: false,
          text: this.textNo || 'No',
        },
      ],
    }
  }

  /**
   * Used by the Effects tab to render an editor for each possible effect.
   */
  @computed
  get effectDescriptors() {
    return [
      {
        id: 'yes',
        title: 'Yes',
        effect: this.yes,
      },
      {
        id: 'no',
        title: 'No',
        effect: this.no,
      },
    ]
  }

  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor.inner && descriptor.inner.yes,
      'yes',
      'There are invalid effects.'
    )
    addGenericErrorForProperty(
      this,
      descriptor.inner && descriptor.inner.no,
      'no',
      'There are invalid effects.'
    )
    super.receiveErrors(descriptor)
  }

  parse(json) {
    if (!json) {
      return undefined
    }

    const { yes, no, ...attrs } = json
    this.yes.hydrate(yes)
    this.no.hydrate(no)
    return {
      ...super.parse(attrs),
    }
  }
}
