import ContentError from 'components/ContentError'
import ContentLoading from 'components/ContentLoading'
import {
  Dialog,
  DialogButton,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'components/Dialog'
import Modal from 'components/Modal'
import ProgressSpinner from 'components/ProgressSpinner'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/Table'
import { observer } from 'mobx-react'
import React from 'react'
import { skillsTableColumns } from '../../../stores/ui/AddSkillsStore'
import styles from './AddSkillsDialog.m.sass'

const AddSkillsDialog = ({ store }) => {
  React.useEffect(() => store.activate(), [])

  return (
    <Modal
      position="top"
      isOpened={store.showing}
      onRequestClose={() => store.hide()}
    >
      <Dialog className={styles.skillEditorDialog}>
        <DialogTitle>Add Skills</DialogTitle>
        {store.activate.match({
          pending: () => (
            <DialogContent>
              <ContentLoading />
            </DialogContent>
          ),
          rejected: (err) => (
            <DialogContent>
              <ContentError error={err} />
            </DialogContent>
          ),
          resolved: () => (
            <>
              <DialogContent>
                <Table
                  searchText={store.searchText}
                  onSearchTextChange={store.setSearchText}
                >
                  <TableHead>
                    {skillsTableColumns.map((x) => (
                      <TableHeader
                        key={x.key}
                        canSort={x.sortable}
                        onSort={(newSort) => store.sort(x.key, newSort)}
                        sortOrder={
                          store.sortField === x.key ? store.sortOrder : 'none'
                        }
                      >
                        {x.label}
                      </TableHeader>
                    ))}
                    <TableHeader centered>Selected</TableHeader>
                  </TableHead>
                  <TableBody>
                    {store.filteredElements.map((s) => (
                      <TableRow key={s.id}>
                        <TableCell>
                          <p className={styles.main}>{s.name}</p>
                        </TableCell>
                        <TableCell>
                          <p className={styles.secondary}>{s.description}</p>
                        </TableCell>
                        <TableCell>
                          {s.tags.map((x) => x.label).join(', ')}
                        </TableCell>
                        <TableCell centered>
                          {store.handleChange.match({
                            pending: (roles) =>
                              roles.id === s.id && (
                                <div className={styles.centered}>
                                  <ProgressSpinner blue />
                                </div>
                              ),
                            resolved: () => (
                              <input
                                disabled={false}
                                type="checkbox"
                                checked={
                                  store.selectedElements.find(
                                    (x) => x.id === s.id
                                  ) || false
                                }
                                onChange={(e) =>
                                  store.handleChange(s, e.target.checked)
                                }
                              />
                            ),
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogFooter>
                <DialogButton onClick={() => store.hide()}>Close</DialogButton>
              </DialogFooter>
            </>
          ),
        })}
      </Dialog>
    </Modal>
  )
}

export default observer(AddSkillsDialog)
