import { serializable, snapshotable } from 'legend-builder/data/serialization'
import { Types } from 'legend-builder/data/Type'
import { computed, observable } from 'mobx'
import { required } from 'validx'

import QuestionEditor from '../../QuestionEditor'

@snapshotable
export default class DateTimeQuestionEditor extends QuestionEditor {
  static friendlyType = 'Date & Time'
  static type = 'DateTime'
  static viewOrder = 4
  static iconColor = '#4EC4FF'

  valueType = Types.date

  @observable
  @serializable
  question = 'New Date & Time Question'

  @observable
  @serializable
  description = ''

  @observable
  @serializable
  collect_time = false

  @observable
  @serializable
  disallow_past = false

  @observable
  @serializable
  disallow_future = false

  rules = {
    question: [required('Question is required')],
    disallow_past: [
      () => {
        if (this.disallow_future && this.disallow_past) {
          return 'Can only select future or past.'
        }
        return true
      },
    ],
    disallow_future: [
      () => {
        if (this.disallow_future && this.disallow_past) {
          return 'Can only select future or past.'
        }
        return true
      },
    ],
  }

  @computed
  get answerOptions() {
    return null
  }
}
