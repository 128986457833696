import { Store } from 'libx'
import { computed, observable, action } from 'mobx'
import { task } from 'mobx-task'
import WorkspaceDetailsState from './screens/WorkspaceManagement/WorkspaceDetailsState'
import NewWorkspaceState from './screens/WorkspaceManagement/NewWorkspaceState'
import { browserHistory } from 'react-router'
import link from 'misc/link'

export default class WorkspaceManagementStore extends Store {
  @observable
  selected = null

  @observable
  view = { type: 'none' }

  @observable
  searchText = ''

  @computed
  get workspaces() {
    const lowerCaseSearchText = this.searchText.toLowerCase()
    if (this.searchText) {
      return this.rootStore.workspaceStore.workspaces
        .filter(
          (w) =>
            w.name.toLowerCase().indexOf(lowerCaseSearchText) >= 0 ||
            w.slug.toLowerCase().indexOf(lowerCaseSearchText) >= 0
        )
        .slice()
    }
    return this.rootStore.workspaceStore.workspaces.slice()
  }

  @task.resolved
  async activate(idOrSlug) {
    const user = this.rootStore.sessionStore.user
    if (!user.platformAdmin) {
      throw new Error('You are not a platform administrator.')
    }

    await this.rootStore.workspaceStore.find()

    this.showWorkspaceDetails(idOrSlug)
  }

  @action.bound
  changeSearchText(newSearchText) {
    this.searchText = newSearchText
  }

  @action.bound
  showWorkspaceDetails(idOrSlug) {
    const found = idOrSlug
      ? this.rootStore.workspaceStore.workspaces.find(
          (x) => x.id === idOrSlug || x.slug === idOrSlug
        )
      : null

    if (found) {
      this.select(found)
    }
  }

  @action.bound
  async select(workspace) {
    this.selected = workspace
    this.view = new WorkspaceDetailsState({
      workspace,
      workspaceManagementStore: this,
      rootStore: this.rootStore,
    })

    await this.view.activate()

    browserHistory.push(link.workspaceManagementDetails(workspace.slug))
  }

  @action.bound
  async newWorkspace() {
    this.selected = null
    this.view = new NewWorkspaceState({
      rootStore: this.rootStore,
      workspaceManagementStore: this,
    })

    await this.view.activate()

    browserHistory.push(link.workspaceManagement())
  }
}
