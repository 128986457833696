import { action, observe, toJS, reaction } from 'mobx'
import pick from 'lodash/pick'
import without from 'lodash/without'
import identity from 'lodash/identity'
import flatMap from 'lodash/flatMap'
import { validationContext } from 'validx'

/**
 * Wraps the target in a class that adds validation functionality.
 */
export function validatable(
  opts = { liveValidation: false, liveIgnore: [] },
  baseRules = {}
) {
  return function validatableMixin(target) {
    const VALIDATION = Symbol('validationContext')
    return class Validatable extends target {
      constructor() {
        super(...arguments)
        this.validate = action(this.validate.bind(this))
        if (opts.liveValidation) {
          observe(this, (change) => {
            this.validate([change.name])
          })
        }
      }

      /**
       * This might get accessed before the Validatable constructor has run.
       * That's why it needs to be a getter defined on the prototype.
       */
      get validation() {
        if (!this[VALIDATION]) {
          this[VALIDATION] = validationContext()
        }
        return this[VALIDATION]
      }

      validate(fields) {
        let rules = {
          ...baseRules,
          ...this.rules,
        }
        if (fields) {
          fields = without(fields, ...(opts.liveIgnore || []))
          rules = pick(rules, fields)
          fields.forEach((f) => {
            this.validation.clearErrors(f)
          })
        }

        return this.validation.validate(this, rules).isValid
      }

      validateAll() {
        this.validation.reset()
        return this.validate()
      }
    }

    // NOTE: Keeping this here in case I have to revert.
    // If so, downgrade @babel/core to 7.8 as some change in 7.9 broke the code below.

    // Object.assign(target.prototype, {
    //   validate(fields) {
    //     let rules = {
    //       ...baseRules,
    //       ...this.rules
    //     }
    //     if (fields) {
    //       fields = without(fields, ...(opts.liveIgnore || []))
    //       rules = pick(rules, fields)
    //       fields.forEach(f => {
    //         this.validation.clearErrors(f)
    //       })
    //     }

    //     return this.validation.validate(this, rules).isValid
    //   },

    //   validateAll() {
    //     this.validation.reset()
    //     return this.validate()
    //   }
    // })

    // function Validatable() {
    //   this.validation = validationContext()
    //   target.apply(this, arguments)
    //   this.validate = action(this.validate.bind(this))
    //   if (opts.liveValidation) {
    //     observe(this, change => {
    //       this.validate([change.name])
    //     })
    //   }
    //   return this
    // }

    // Validatable.prototype = target.prototype
    // return Validatable
  }
}

/**
 * Sets up field validation cascading. For example, when `field1` changes, validate `field2` and `field3`.
 *
 *   cascadeFieldValidation(this, {
 *     field1: ['field2', 'field3']
 *   })
 */
export function cascadeFieldValidation(that, obj) {
  Object.keys(obj).forEach((key) => {
    observe(that, key, () => {
      const fieldsToTrigger = obj[key]
      that.validate(fieldsToTrigger)
    })
  })
}

/**
 * Sets up child object validation cascading. For example, if `field1` is an object that can be validated,
 * we want to put its' errors into `field1` on the target.
 * @param {*} that
 * @param {*} obj
 */
export function cascadeValidation(target, fields) {
  fields.forEach((field) => {
    reaction(
      () =>
        toJS(
          assertValidationContext(`cascadeValidation(${field})`, target[field])
            .validation.errors
        ),
      (errors) => {
        const messages = flatMap(errors, identity)
        target.validation.clearErrors(field).addErrors({ [field]: messages })
      }
    )
  })
}

function assertValidationContext(caller, ctx) {
  if (!ctx) {
    throw new Error(
      `${caller}: trying to cascade validation but the context was not set. This is probably because ${caller} was called in a class where the validatable decorator has not run on yet.`
    )
  }
  return ctx
}
