import { observable, computed, action } from 'mobx'
import { task } from 'mobx-task'

/**
 * Update Deadline state
 */
export default class UpdateDeadlineState {
  @observable
  showing = false

  @observable
  deadline = null

  constructor(jobStore, detailsStore) {
    this.detailsStore = detailsStore
    this.jobStore = jobStore
    this.updateDeadline = this.updateDeadline.bind(this)
    this.init()
  }

  @computed
  get job() {
    return this.detailsStore.job
  }

  @action.bound
  init() {
    this.updateDeadline.reset()
  }

  @action.bound
  show() {
    this.init()
    this.showing = true
    this.deadline = this.job.dateDeadline
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @task.resolved
  async updateDeadline() {
    if (!this.deadline) return

    await this.jobStore
      .updateDeadline(this.job.id, {
        deadline: this.deadline,
      })
      .catch(
        action((err) => {
          this.showing = true
          throw err
        })
      )
    this.hide()
  }
}
