import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import Icon from 'components/Icon'
import styles from './Login.m.sass'
import Logo from './logo.r.svg'

/**
 * Login screen.
 */
class Login extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  login = async () => {
    await this.props.rootStore.authStore.login()
  }

  render() {
    return (
      <section className={styles.root}>
        <Helmet title="Worklayer HQ — Sign in" />
        <div className={styles.left}>
          <div className={styles.header}>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.content}>
            <h1 className={styles.title}>Welcome to Worklayer HQ!</h1>
            <p className={styles.description}>
              This is the Worklayer management portal for workspace
              administrators.
            </p>
            <div className={styles.buttonContainer}>
              <a onClick={this.login} className={styles.button}>
                Sign in to Worklayer HQ
                <span className={styles.buttonIcon}>
                  <Icon material auto name="keyboard arrow right" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <img
            className={styles.illustration}
            src={require('./illustration.svg')}
            alt="Illustration of a woman sitting on a bench with a dog, man in the background in front of a computer."
          />
        </div>
      </section>
    )
  }
}

export default Login
