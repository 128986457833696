import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ErrorLabel from 'components/ErrorLabel'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'

import { Input, Select } from 'components/Form'
import styles from './ChargeJobDialog.m.sass'

@observer
export default class ChargeJobDialog extends React.Component {
  renderDialog() {
    const { store } = this.props
    if (!store.job) return null

    return (
      <div data-test="charge-job-dialog" className={styles.chargeJobDialog}>
        <DialogTitle>Charge/Refund this Job</DialogTitle>
        <DialogContent>
          <Select label="Billing Method" field="billingMethodId" model={store}>
            {store.billingMethods.map((pm) => (
              <option key={pm.id} value={pm.id}>
                {pm.type === 'Credit'
                  ? `${pm.team?.name || 'Unknown'} Team Credit`
                  : `${pm.team ? pm.team.name + ': ' : ''} ${
                      pm.brand
                    } ending in ${pm.last4} ${pm.isDefault ? '(Default)' : ''}`}
              </option>
            ))}
          </Select>
          <Input
            model={store}
            field="amount"
            label="Amount"
            help="How much do you want to charge? If negative the money will be refunded. Refunds can only be done when there has been a transaction on the job."
            data-test="charge-refund-amount-to-job"
          />
          <Input
            model={store}
            field="description"
            label="Description"
            help="Add a description"
            data-test="charge-refund-job-description"
          />
          <ErrorLabel>{store.error}</ErrorLabel>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide}>Close</DialogButton>
          <DialogButton
            primary
            onClick={store.chargeJob.bind(store)}
            data-test="do-charge-refund"
          >
            Charge/Refund
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        className="charge-modal"
        position="top"
      >
        <Dialog>
          {store.chargeJob.match({
            pending: () => <ContentLoading small />,
            rejected: (err) => <ContentError error={err} />,
            resolved: () => this.renderDialog(),
          })}
        </Dialog>
      </Modal>
    )
  }
}
