import { required } from 'validx'
import { stringLength } from 'utils/validx-validators'
import { makeMomentSorter } from '@taxfyle/web-commons/lib/utils/arrayUtil'
import {
  editor,
  inlineEditor,
  inlineEditorHost,
  field,
  destroyable,
  crudStore,
} from 'data/editor'
import { Store } from 'libx'
import { observable, action } from 'mobx'
import { sortArray } from 'utils/sortUtil'
import { extractMessageFromError } from 'utils/errorUtil'
import Papa from 'papaparse'
import InfiniteScrollProvider from 'data/InfiniteScrollProvider'

@destroyable()
@editor()
@inlineEditor()
class SkillViewModel {
  @field
  name

  @field
  description

  @field
  tagIds = []

  validationRules = {
    name: [
      required('Name is required'),
      stringLength(0, 50, 'Keep it short, max {max} characters.'),
    ],
    description: [
      stringLength(
        0,
        50,
        'Thats a wee bit long - max {max} characters please.'
      ),
    ],
  }

  mapToModel(data) {
    return {
      ...data,
      name: data.name.trim(),
      description: data.description || '',
      tagIds: data.tagIds.slice() || [],
    }
  }

  @action.bound
  setTagIds(tagIds) {
    this.tagIds = tagIds
  }
}

@inlineEditorHost()
@crudStore({
  itemsProp: 'skills',
})
class SkillsScreenStore extends Store {
  ViewModel = SkillViewModel

  @observable
  searchText = ''

  @observable
  sort = this.sortOptions[0]

  sortOptions = [
    { key: 'name', type: 'string', direction: 'asc', label: 'Name (Asc)' },
    { key: 'name', type: 'string', direction: 'desc', label: 'Name (Desc)' },
    {
      key: 'dateCreated',
      type: 'date',
      direction: 'asc',
      label: 'Date Created (Asc)',
    },
    {
      key: 'dateCreated',
      type: 'date',
      direction: 'desc',
      label: 'Date Created (Desc)',
    },
  ]

  constructor(opts) {
    super(...arguments)
    this.modelStore = this.rootStore.skillStore
    this.getCsvData = this.getCsvData.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
    this.scrollProvider = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: 100,
      fetchItems: this.fetchSkills.bind(this),
    })
    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.scrollProvider.reset()
    })
  }

  async activate() {
    await this.scrollProvider.fetch()
  }

  getCsvData() {
    const data = this.getItems()

    return {
      filename: 'skills.csv',
      data: data.map((x) => [
        (x.name || '').trim(),
        (x.description || '').trim(),
        x.tags.map((x) => x.label.toLowerCase().trim()).join(','),
      ]),
    }
  }

  getItems() {
    let data = this.rootStore.skillStore
      .forWorkspace(this.rootStore.sessionStore.workspace.id)
      .slice()

    if (this.searchText.length > 0) {
      const lowerCase = this.searchText.toLowerCase()

      const textParts = lowerCase.split(' ')
      data = data.filter((x) => {
        let hasAllParts = 0
        for (const search of textParts) {
          if (
            x.name.toLowerCase().includes(search.toLowerCase()) ||
            x.description.toLowerCase().includes(search.toLowerCase()) ||
            x.tags
              .map((x) => x.label.toLowerCase())
              .join(' ')
              .includes(search.toLowerCase())
          ) {
            hasAllParts++
          }
        }
        return hasAllParts === textParts.length
      })
    }

    const sorted =
      this.sort.type === 'date'
        ? data.sort(
            makeMomentSorter(this.sort.direction, (x) => x[this.sort.key])
          )
        : sortArray(data, this.sort.key, this.sort.direction === 'asc')

    return sorted
  }

  @action.bound
  changeSorting(sort) {
    this.sort = sort
  }

  async fetchSkills(params) {
    return this.rootStore.skillStore.find(params)
  }

  @action.bound
  async onChangeFile(files) {
    const csv = await new Promise((resolve, reject) => {
      Papa.parse(files[0], {
        complete: (results) => {
          resolve(results.data)
        },
      })
    })

    let doneCount = 0

    const formatMessage = () =>
      `Creating skills, ${doneCount} of ${csv.length}...`

    const msg = this.rootStore.flashMessageStore.create({
      inProgress: true,
      message: formatMessage(),
    })

    const allTags = csv
      .map((x) => {
        if (x.length === 3) {
          return x[2].split(',')
        }
        return null
      })
      .filter(Boolean)
      .flat()
    // deal with all the tags first
    const taglabels = Array.from(new Set(allTags))
    await Promise.all(
      taglabels.map((t) => {
        if (!t || t.length === 0) {
          return null
        }
        const tag = this.rootStore.skillTagStore.tags.find(
          (x) => x.label.toLowerCase() === t.toLowerCase()
        )
        if (!tag) {
          return this.rootStore.skillTagStore.create({
            workspace_id: this.rootStore.sessionStore.workspace.id,
            label: t,
            color: '#C0C0C8',
          })
        }
        return null
      })
    )

    const results = await Promise.all(
      csv.map((e) => {
        if (e.length !== 2 && e.length !== 3) {
          return {
            name: e.toString(),
            error: 'Bad formatting',
          }
        } else {
          let tagIds = []
          if (e.length === 3) {
            tagIds = e[2]
              .split(',')
              .map((t) => {
                const tag = this.rootStore.skillTagStore.tags.find(
                  (x) => x.label.toLowerCase() === t.trim()
                )
                return tag && tag.id
              })
              .filter(Boolean)
          }

          return this.rootStore.skillStore
            .save({
              tagIds,
              name: e[0],
              description: e[1],
            })
            .then(() => {
              doneCount++
              msg.set({ message: formatMessage() })
              return { e, success: true }
            })
            .catch((err) => {
              return { name: e[0], error: err }
            })
        }
      })
    )

    const failed = results.filter((r) => r.error)
    if (failed.length === 0) {
      msg
        .done(
          doneCount === 1 ? 'Skill imported!' : `Imported ${doneCount} skills!`
        )
        .autoDismiss()
      this.close()
      return
    }

    const errorMessages = failed
      .map((f) => `${f.name} - ${extractMessageFromError(f.error)}`)
      .join('\n')

    msg.warn(
      doneCount > 0
        ? `Imported ${doneCount} skills, but ${failed.length} didn't go through. ${errorMessages}`
        : `None of the skills were imported. ${errorMessages}`
    )
  }

  @action.bound
  setSearchText(text) {
    this.searchText = text
  }
}

export default SkillsScreenStore
