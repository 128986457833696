import { Model } from 'libx'
import { match } from 'utils/patternMatchUtil'
import { observable, action } from 'mobx'
import { validationContext, required } from 'validx'

export default class SignupRulesBuilder {
  @observable
  rules = []

  validation = validationContext(this, {
    rules: [
      ({ value }) =>
        value.every((r) => r.validation.reset().validate().isValid) ||
        'Some rules are invalid.',
    ],
  })

  activate(rules = []) {
    this.rules.replace(rules.map((r) => createSignupRuleEditor(r, this)))
  }

  toJSON() {
    return this.rules.map((x) => x.toJSON())
  }

  @action.bound
  add(data) {
    this.rules.push(createSignupRuleEditor(data, this))
  }

  @action.bound
  remove(editor) {
    this.rules.remove(editor)
  }
}

class AndOrEditor extends Model {
  type = 'AndOr'

  @observable
  operator = 'And'

  validation = validationContext(this, {
    rules: [
      ({ value }) =>
        value.validation.reset().validate().isValid ||
        'Some rules are invalid.',
    ],
  })

  toJSON() {
    return {
      type: this.type,
      operator: this.operator,
      rules: this.rules.toJSON(),
    }
  }

  parse({ rules, operator }) {
    const builder = new SignupRulesBuilder()
    builder.activate(rules)
    return { operator, rules: builder }
  }

  @action.bound
  toggleOperator() {
    this.operator = this.operator === 'And' ? 'Or' : 'And'
  }
}

class EmailEndsWithEditor extends Model {
  type = 'EmailEndsWith'
  @observable
  endsWith = ''

  validation = validationContext(this, {
    endsWith: [required()],
  })

  toJSON() {
    return {
      type: this.type,
      ends_with: this.endsWith,
    }
  }

  parse({ ends_with = '' }) {
    return { endsWith: ends_with }
  }
}

class InviteOnlyEditor extends Model {
  type = 'InviteOnly'

  validation = validationContext(this, {})

  toJSON() {
    return {
      type: this.type,
    }
  }
}

class IdPMatchesEditor extends Model {
  type = 'IdPMatches'
  @observable
  idp = ''

  validation = validationContext(this, {
    idp: [required()],
  })

  toJSON() {
    return {
      type: this.type,
      idp: this.idp,
    }
  }

  parse({ idp = '' }) {
    return { idp: idp }
  }
}

function createSignupRuleEditor(data, builder) {
  const Class = match(data.type, {
    AndOr: () => AndOrEditor,
    EmailEndsWith: () => EmailEndsWithEditor,
    InviteOnly: () => InviteOnlyEditor,
    IdPMatches: () => IdPMatchesEditor,
  })

  return new Class(data, { builder, parse: true, stripUndefined: true })
}
