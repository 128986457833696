import { Decimal } from '@taxfyle/api-internal/shared/types/decimal_pb'

const nanoFactor = 1_000_000_000

export function numberToDecimal(number) {
  const decimal = new Decimal()
  const units = Math.floor(number)
  const nanos = Math.floor((number - units) * nanoFactor)
  decimal.setUnits(units)
  decimal.setNanos(nanos)
  return decimal
}
