import { asNumber } from 'utils/numberUtil'
import { computed, observable, action } from 'mobx'
import {
  editor,
  inlineEditor,
  inlineEditorHost,
  field,
  destroyable,
  crudStore,
} from 'data/editor'
import debounce from 'lodash/debounce'
import InfiniteScrollProvider from 'data/InfiniteScrollProvider'
import { Store } from 'libx'
import { required, func } from 'validx'
import { isAfter } from 'utils/validx-moment'
import moment from 'moment'
import remove from 'lodash/remove'
import { ConfirmDialogState } from 'components/ConfirmDialog'
import CouponSaveModalState from '../../screens/Coupons/CouponSaveModal/CouponSaveModalState'
import UserCouponListModalState from '../../screens/Coupons/UserCouponListModal/UserCouponListModalState'

@destroyable()
@editor()
@inlineEditor()
class CouponViewModel {
  @field
  code

  @field
  friendlyName

  @field
  description

  @field
  maxTotalRedemptions

  @field
  dateExpires

  @field
  offType = 'AMOUNT_OFF'

  @field
  offValue = 0

  @field
  duration = 'ONCE'

  @field
  maxOff = 0

  @field
  minimumTotal = 0

  validationRules = {
    code: [required('Code is required')],
    description: [
      func(
        ({ value }) => (value ? value.length < 120 : true),
        'Please make it shorter - 120 characters and below.'
      ),
    ],
    dateExpires: [isAfter(moment(), 'Should be later than now.')],
    offType: [required()],
    offValue: [
      required(),
      func(({ value }) => asNumber(value) > 0, "... what's the point?"),
    ],
    duration: [required()],
    minimumTotal: [
      func(({ value }) => asNumber(value) >= 0, 'Should not be negative.'),
    ],
  }

  mapFromModel({
    offType,
    friendlyName,
    maxTotalRedemptions,
    dateExpires,
    maxOff,
    minimumTotal,
    ...mapped
  }) {
    offType = offType || { type: 'AMOUNT_OFF', amount_off: 0 }
    maxOff = maxOff || { type: 'MAX_PERCENT_OFF', percentOff: 0 }

    maxOff.percentOff ??= undefined
    maxOff.amountOff ??= undefined

    return {
      ...mapped,
      maxTotalRedemptions: maxTotalRedemptions,
      dateExpires: dateExpires,
      friendlyName: friendlyName,
      code: this.model ? this.model.code : '',
      offType: offType.type,
      offValue:
        offType.type === 'AMOUNT_OFF' ? offType.amountOff : offType.percentOff,
      maxOff:
        offType.type === 'AMOUNT_OFF' ? maxOff.percentOff : maxOff.amountOff,
      minimumTotal: minimumTotal,
    }
  }

  mapToModel(mapped) {
    const {
      code,
      offValue,
      offType,
      maxTotalRedemptions,
      friendlyName,
      dateExpires,
      maxOff,
      minimumTotal,
      ...other
    } = mapped
    const isAmount = offType === 'AMOUNT_OFF'
    const offKey = isAmount ? 'amount_off' : 'percent_off'
    const maxOffType = isAmount ? 'MAX_PERCENT_OFF' : 'MAX_AMOUNT_OFF'
    const maxOffKey = isAmount ? 'percent_off' : 'amount_off'
    const val = maxOff ? asNumber(maxOff) : 0
    return {
      ...other,
      code,
      friendly_name: friendlyName ?? '',
      date_expires: dateExpires,
      max_total_redemptions: asNumber(maxTotalRedemptions),
      off_type: {
        type: offType,
        [offKey]: asNumber(offValue),
      },
      max_off: {
        type: maxOffType,
        [maxOffKey]: val,
      },
      minimum_total: asNumber(minimumTotal),
    }
  }
}

@inlineEditorHost()
@crudStore({
  itemsProp: 'coupons',
})
class CouponsScreenStore extends Store {
  ViewModel = CouponViewModel

  scrollProviders = new Map()

  @observable
  selectedCoupons = []

  @observable
  sortField = null

  @observable
  sortOrder = null

  @observable
  showFilter = true

  @observable
  coupons = []

  @observable
  facets = new Map()

  @observable
  selectedFacets = new Map()

  @observable
  fromDate = ''

  @observable
  toDate = ''

  @observable
  searchText = ''

  constructor() {
    super(...arguments)
    const that = this
    const couponStore = this.rootStore.couponStore

    this.debouncedFindCoupons = debounce(this.findCoupons, 200)
    this.deleteCouponsConfirm = new ConfirmDialogState()

    this.modelStore = {
      get coupons() {
        return that.scrollProvider.items
      },
      create: couponStore.createCoupon.bind(couponStore),
      update: couponStore.updateCoupon.bind(couponStore),
      destroy: (c) => couponStore.deleteCoupon(c.id),
    }

    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.scrollProviders.clear()
    })

    this.couponSaveModalState = new CouponSaveModalState(
      this.rootStore.flashMessageStore,
      this
    )

    this.userCouponListModalState = new UserCouponListModalState(
      this.rootStore.userCouponStore,
      this.rootStore.sessionStore
    )
  }

  activate() {
    this.facets.replace({
      'Coupon Status': {
        buckets: [
          { key: 'All' },
          { key: 'Active' },
          { key: 'Expired' },
          { key: 'Redeemed' },
        ],
      },
      Type: {
        buckets: [{ key: 'Coupon' }, { key: 'Referral' }],
      },
    })

    return this.scrollProvider.fetch()
  }

  async _fetchCoupons(p) {
    const couponTypes = !this.selectedFacets.get('Type')
      ? ['REFERRAL_CODE', 'REGULAR']
      : this.selectedFacets.get('Type') === 'Referral'
      ? ['REFERRAL_CODE']
      : ['REGULAR']

    const status = !this.selectedFacets.get('Coupon Status')
      ? 'ALL'
      : this.selectedFacets.get('Coupon Status').toUpperCase()

    const filters = {
      coupon_types: couponTypes,
      ...(this.sortField && {
        order_by: `${this.sortField} ${this.sortOrder}`,
      }),
      status,
      ...(this.fromDate.length > 0 && { fromDate: this.fromDate }),
      ...(this.toDate.length > 0 && { toDate: this.toDate }),
      search_text: this.searchText,
    }

    const result = await this.rootStore.couponStore.fetchCoupons({
      ...p,
      ...filters,
    })

    this.coupons.push(...result.data)

    const couponIds = result.data.map((coupon) => {
      return coupon.id
    })

    await this.rootStore.jobStore.fetchJobsForCoupons({ couponIds })

    return result
  }

  @computed
  get scrollProvider() {
    const existing = this.scrollProviders.get()

    if (existing) {
      return existing
    }

    const provider = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: 10,
      fetchItems: this._fetchCoupons.bind(this),
    })

    this.scrollProviders.set(provider)

    return provider
  }

  @action.bound
  async handleSelection(id) {
    if (this.selectedCoupons.indexOf(id) > -1) {
      this.selectedCoupons.remove(id)
    } else {
      this.selectedCoupons.push(id)
    }
  }

  @action.bound
  changeSort(field, order) {
    this.sortField = field
    this.sortOrder = order

    this.findCoupons()
  }

  @action
  async findCoupons() {
    this.scrollProvider.reset()
    this.selectedCoupons.replace([])
    this.coupons.replace([])

    await this.scrollProvider.fetch()
  }

  @action.bound
  async toggleFilter() {
    this.showFilter = !this.showFilter
  }

  @action.bound
  selectFacet(key, value) {
    const currentSelection = this.selectedFacets.get(key)

    if (currentSelection && currentSelection === value) {
      this.selectedFacets.delete(key)
    } else {
      this.selectedFacets.set(key, value)
    }

    this.findCoupons()
  }

  @action.bound
  changeFromDate(value) {
    this.fromDate = value

    this.debouncedFindCoupons()
  }

  @action.bound
  changeToDate(value) {
    this.toDate = value

    this.debouncedFindCoupons()
  }

  @action.bound
  changeSearchText(newSearchText) {
    this.searchText = newSearchText

    this.scrollProvider.reset()

    this.debouncedFindCoupons()
  }

  @action.bound
  editCoupon(coupon) {
    this.couponSaveModalState.coupon = coupon.startEditing()

    this.couponSaveModalState.show()
  }

  @action.bound
  userCoupons(coupon) {
    this.userCouponListModalState.coupon = coupon

    this.userCouponListModalState.show()
  }

  @action.bound
  async destroyCoupon(coupon) {
    await coupon.destroy()

    remove(this.modelStore.coupons, (c) => c.id === coupon.id)
  }

  @action.bound
  newCoupon() {
    this.couponSaveModalState.coupon = this.newItem()

    this.couponSaveModalState.show()
  }

  @action.bound
  async deleteSelected() {
    if (!(await this.deleteCouponsConfirm.show())) {
      return
    }

    this.selectedCoupons.forEach(async (id) => {
      await this.rootStore.couponStore.deleteCoupon(id)

      remove(this.modelStore.coupons, (coupon) => coupon.id === id)

      this.selectedCoupons.remove(id)
    })
  }

  @action.bound
  async loadAllData() {
    let hasMore = await this.scrollProvider.more()

    while (hasMore && hasMore.data && hasMore.data.length !== 0) {
      hasMore = await this.scrollProvider.more()
    }

    if (this.coupons.length === 0) {
      return
    }

    return {
      filename: 'export.csv',
      data: this.coupons.map((x) => {
        return {
          ...x,
          status: x.status,
          displayType: x.displayType,
          dateCreated: x.dateCreated.format('LL LT'),
          createdBy: x.createdBy ?? {},
        }
      }),
      headers: [
        {
          label: 'Id',
          key: 'id',
        },
        {
          label: 'Code',
          key: 'code',
        },
        {
          label: 'Type',
          key: 'displayType',
        },
        {
          label: 'Description',
          key: 'description',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: 'Off Type',
          key: 'offType.displayType',
        },
        {
          label: 'Duration',
          key: 'duration',
        },
        {
          label: 'Date Created',
          key: 'dateCreated',
        },
        {
          label: 'Created By',
          key: 'createdBy.displayName',
        },
        {
          label: 'redeemed',
          key: 'redeemedCount',
        },
      ],
    }
  }
}

export default CouponsScreenStore
