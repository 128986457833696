import { observable } from 'mobx'
import { Model } from 'libx'

export default class BriefLegend extends Model {
  @observable id = ''
  @observable workspaceId = ''
  @observable name = ''
  @observable published = false
  @observable inactive = false
  @observable serviceType = undefined

  constructor(attrs, opts) {
    super(null, opts)
    this.set(attrs, opts)
  }

  parse(json) {
    return {
      id: json.id,
      workspaceId: json.workspace_id,
      name: json.name,
      published: json.published,
      inactive: json.inactive,
      serviceType: json.service_type,
    }
  }
}
