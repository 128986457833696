import {
  builtinTypes as RynoBuiltins,
  typeBuilder as RynoTypeBuilder,
  isNumberType,
  isListType,
  isStringType,
  isBoolType,
  equalsType,
  getFriendlyTypeName,
  prune,
} from '@taxfyle/ryno'

export const Types = {
  ...RynoBuiltins,
  ...RynoTypeBuilder,
  isListOfType,
  isListType,
  isStringType,
  isBoolType,
  isNumberType,
  getFriendlyTypeName,
  getTypeDescriptor,
  fromJS: typeFromJS,
  toJS: typeToJS,
  equalsType: (left, right) => equalsType(prune(left), prune(right)),
}

export function getTypeDescriptor(type) {
  const common = commonTypeDescriptors.get(type)
  if (common) {
    return common
  }

  if (isListType(type)) {
    const elementCommon = getTypeDescriptor(type.elementType)
    return {
      friendlyName: `List of ${elementCommon.friendlyName}`,
      friendlyDescription: `A list of ${elementCommon.friendlyName} elements.`,
    }
  }

  return {
    friendlyName: getFriendlyTypeName(type),
    friendlyDescription: 'No description.',
  }
}

export function typeFromJS(obj) {
  switch (obj.type) {
    case 'Number':
      return Types.number
    case 'String':
      return Types.string
    case 'Date':
      return Types.date
    case 'Boolean':
      return Types.boolean
    case 'List':
      return Types.list(typeFromJS(obj.itemType))
    default:
      console.warn(
        obj,
        new Error(`Unknown type "${obj.name}", using "any" as fallback.`).stack
      )
      return Types.any
  }
}

export function typeToJS(type) {
  if (isListType(type)) {
    return {
      type: 'List',
      itemType: typeToJS(type.elementType),
    }
  }

  return {
    // The Ryno friendly type name maps to what we use in the engine spec.
    type: getFriendlyTypeName(type),
  }
}

export function isListOfType(source, elementType) {
  return isListType(source) && equalsType(source.elementType, elementType)
}

const commonTypeDescriptors = new Map([
  [Types.string, makeDescriptor('String', 'A piece of text.')],
  [Types.number, makeDescriptor('Number', 'A numeric value.')],
  [Types.boolean, makeDescriptor('Boolean', 'Can be either true or false.')],
  [Types.date, makeDescriptor('Date + Time', 'A point in time.')],
])

function makeDescriptor(friendlyName, friendlyDescription) {
  return {
    friendlyDescription,
    friendlyName,
  }
}
