import React, { useState, useEffect, useRef, Fragment } from 'react'
import propsTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import Icon from 'components/Icon'
import styles from './FacetedSearch.m.sass'

const CsvExport = ({
  asyncExportMethod,
  children,
  disable,
  className,
  style,
}) => {
  const [csvData, setCsvData] = useState(false)
  const csvInstance = useRef()

  const specialSymbols = ['-', '=', '@', '+', '"', '\t']

  // Escapes characters that can trigger code execution in excel with a single quote
  const formatToValidItems = (item) => {
    return specialSymbols.some(
      (x) => typeof item === 'string' && item.startsWith(x)
    )
      ? `'${item}`
      : item
  }

  const formatToValidProperties = (row) => {
    const newRow = {}
    for (const key in row) {
      newRow[key] = formatToValidItems(row[key])
    }

    return newRow
  }

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click()
        setCsvData(false)
      })
    }
  }, [csvData])
  return (
    <Fragment>
      <div
        style={style}
        className={className || styles.link}
        onClick={async () => {
          if (disable) {
            return
          }

          const csvData = await asyncExportMethod()
          const formattedCsvData = {
            ...csvData,
            data: csvData.data.map((row) =>
              Array.isArray(row)
                ? row.map(formatToValidItems)
                : formatToValidProperties(row)
            ),
          }

          setCsvData(formattedCsvData)
        }}
      >
        {children || (
          <>
            <Icon size={21} material name={'cloud_download'} />
            Export as CSV
          </>
        )}
      </div>
      {csvData ? (
        <CSVLink
          data={csvData.data}
          headers={csvData.headers}
          filename={csvData.filename}
          ref={csvInstance}
        />
      ) : undefined}
    </Fragment>
  )
}

export default CsvExport

CsvExport.defaultProps = {
  children: undefined,
  asyncExportMethod: () => null,
  disable: false,
}

CsvExport.propTypes = {
  children: propsTypes.node,
  asyncExportMethod: propsTypes.func,
  disable: propsTypes.bool,
}
