import { action, observable } from 'mobx'
import { task } from 'mobx-task'
import { required, validationContext } from 'validx'
import { roundToPrecision } from '@taxfyle/web-commons/lib/utils/numberUtil'

/**
 * Assign job to a team UI state.
 */
export default class ChargeJobState {
  @observable
  showing = false

  @observable
  error = ''

  @observable
  job

  @observable
  billingMethodId = 'default'

  @observable
  amount = 0

  @observable
  description = ''

  @observable
  billingMethods = []

  /**
   * Validation context.
   */
  validation = validationContext()

  /**
   * Validation rules.
   */
  rules = {
    billingMethodId: [required('Billing Method required.')],
    amount: [required('Amount is required.')],
    description: [required('Description is required.')],
  }

  constructor({ rootStore }) {
    this.rootStore = rootStore
  }

  @action.bound
  async init(job, billingMethods = []) {
    this.validation.reset()
    this.job = job
    if (billingMethods.length > 0) {
      const defaultBillingMethod = billingMethods.find((bm) => bm.isDefault)
      this.billingMethodId = defaultBillingMethod
        ? defaultBillingMethod.id
        : billingMethods[0].id
    }
    this.amount = roundToPrecision(job.total - job.paidAmount)
    this.billingMethods = billingMethods
    this.description = ''
    this.chargeJob.reset()
  }

  @task.resolved
  async chargeJob() {
    if (!this.validation.reset().validate(this, this.rules).isValid) {
      return
    }

    await this.rootStore.jobStore.chargeJob(
      this.job.id,
      this.billingMethodId,
      this.description,
      this.amount
    )
    this.hide()
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }
}
