import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'
import links from 'misc/link'
import env from 'misc/env'

/**
 * Conditionally generates the relevant link to the given job.
 */
export function toJobLink(jobId, slug = null) {
  const openInWorkPortal = getFeatureToggleClient().variation(
    'Portals.RedirectJobsAndUserDetails',
    false
  )

  return openInWorkPortal
    ? { to: `${env.PROVIDER_PORTAL_URL}/jobs/${jobId}`, target: '_blank' }
    : { to: links.job(jobId, slug) }
}
