import { observable, action, computed, runInAction } from 'mobx'
import { task } from 'mobx-task'
import { validationContext } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'

class CreateJobEditorStore {
  @observable showing = false
  @observable error = ''

  @observable name = ''
  @observable assignToPro = null
  @observable billExternal = true

  @observable user = null
  @observable teamId = ''
  @observable availableTeams = []
  @observable legends = []
  @observable selectedLegend = {}
  @observable questions = []
  @observable answers = []
  @observable job = null

  validation = validationContext(this, {
    selectedLegend: [
      (opts) =>
        (opts.value.id != null && opts.value.id !== '') || 'Legend is required',
    ],
    answers: [
      () =>
        !this.job ||
        this.save.pending ||
        this.requiredQuestionsAnswered ||
        'Please answer all questions.',
    ],
  })

  constructor({ rootStore }) {
    this.rootStore = rootStore
    this.flashMessageStore = rootStore.flashMessageStore
    this.jobStore = rootStore.jobStore
    this.draftStore = rootStore.draftStore
    this.questionStore = rootStore.questionStore
    this.userDetailsScreenStore = rootStore.userDetailsScreenStore
    this.jobsV3Api = rootStore.api.jobsV3

    this.fetchData = this.fetchData.bind(this)
    this.fetchQuestions = this.fetchQuestions.bind(this)
    this.selectLegend = this.selectLegend.bind(this)
    this.save = this.save.bind(this)
    this.submit = this.submit.bind(this)
  }

  @action.bound
  init(user) {
    this.validation.reset()
    this.name = ''
    this.assignToPro = null
    this.billExternal = true

    this.user = user
    this.teamId = ''
    this.availableTeams = []
    this.legends = []
    this.selectedLegend = {}
    this.questions = []
    this.answers = []
    this.job = null
    this.fetchData()
  }

  @computed
  get requiredQuestionsAnswered() {
    const unanswered = this.questions.filter((q) => !q.isAnswered)
    return unanswered.legend === 0 || unanswered.every((q) => q.isDocument)
  }

  @computed
  get canChange() {
    return this.job !== null
  }

  @computed
  get busy() {
    return (
      this.fetchData.pending ||
      this.selectLegend.pending ||
      this.save.pending ||
      this.submit.pending
    )
  }

  @computed
  get billUserAllowed() {
    return (
      this.rootStore.sessionStore.workspace.features.billing.enabled &&
      !this.job?.legend.skipBilling
    )
  }

  @computed
  get allowProSelection() {
    return this.job?.legend?.allowProSelection
  }

  @computed
  get hasBreaker() {
    return this.questions.some((x) => x.type === 'BREAKER')
  }

  @computed
  get providerUserPublicId() {
    return this.assignToPro?.userPublicId ?? null
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false

    if (this.job == null) return
    this.updateUserDetailsScreenStore()
  }

  @action.bound
  selectProvider(user) {
    this.assignToPro = user
  }

  @action.bound
  updateUserDetailsScreenStore() {
    this.userDetailsScreenStore.activate(this.user.userPublicId)
  }

  @action.bound
  updateLegend(legend) {
    this.selectedLegend = legend
  }

  @action.bound
  setAnswers(answers) {
    this.answers = answers
  }

  @action.bound
  async setJob(jobId) {
    this.job = await this.jobStore.getJob(jobId)
  }

  @task
  async fetchQuestions() {
    if (!this.job) {
      return
    }

    const questionsDictionary = this.questions.reduce(
      (dictionary, question) => {
        dictionary[question.id] = question
        return dictionary
      },
      {}
    )

    // Add default answer for questions of boolean type
    this.questions.forEach((question) => {
      if (question.isBool && !question.isAnswered) {
        this.answers.push({
          id: question.id,
          answer: false,
        })
      }
    })

    const questionsWithAnswers = this.answers.map((answer) => {
      const question = questionsDictionary[answer.id]
      question.setAnswer(answer.answer)
      return question
    })

    await this.questionStore.fetchQuestions(this.job.id, questionsWithAnswers)

    const questions = this.questionStore.questionsForJob(this.job.id)

    // if we get any new boolean question that has no answer we need to call fetch question
    if (questions.some((q) => q.isBool && !q.isAnswered)) {
      this.questions = questions
      this.answers = questions.map((q) => ({
        id: q.id,
        answer: q.answer,
      }))
      await this.fetchQuestions()
      return
    }

    runInAction(() => {
      this.questions = questions
      this.answers = questions.map((q) => ({
        id: q.id,
        answer: q.answer,
      }))
    })
  }

  @task
  async fetchData() {
    const [legendsPage, availableTeams] = await Promise.all([
      this.rootStore.publishedLegendStore.fetchPublishedLegends(
        this.user.workspaceId,
        {
          limit: 1000,
        }
      ),
      this.rootStore.teamMemberStore.fetchUserTeamMembersByPublicId({
        workspace_id: this.user.workspaceId,
        user_id: this.user.userPublicId,
      }),
    ])

    runInAction(() => {
      this.legends = legendsPage.data
      this.availableTeams = availableTeams
    })
  }

  @task
  async renameJob(jobId) {
    const name = this.name || this.selectedLegend.name
    if (name === this.job?.name) return
    await this.jobsV3Api.rename(jobId, name)
  }

  @task
  async requestChampionProvider() {
    if (!this.allowProSelection || this.providerUserPublicId == null) return
    await this.draftStore.requestChampionProvider(
      this.job.id,
      this.providerUserPublicId
    )
  }

  @task.resolved
  async selectLegend(legendId) {
    const legend = this.legends.find((x) => x.id === legendId)
    if (!legend) {
      this.updateLegend({})
      return
    }
    this.updateLegend(legend)
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const msg = this.flashMessageStore.create({
      inProgress: true,
      message: 'Preparing Job',
    })

    try {
      const createDraftJobPromise =
        this.teamId === ''
          ? this.draftStore.createDraftJob(
              this.selectedLegend.id,
              this.selectedLegend.version,
              this.user.userPublicId
            )
          : this.draftStore.createTeamDraftJob(
              this.selectedLegend.id,
              this.selectedLegend.version,
              this.teamId,
              this.user.userPublicId
            )
      const jobId = await createDraftJobPromise
      await this.renameJob(jobId)
      msg.done('Job was created').autoDismiss()
      await this.setJob(jobId)
      await this.fetchQuestions()
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
    }
  }

  @task.resolved
  async save() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const msg = this.flashMessageStore.create({
      inProgress: true,
      message: 'Preparing Job',
    })

    try {
      await this.renameJob(this.job.id)
      await this.requestChampionProvider()
      await this.draftStore.replaceQuestionAnswers(this.job.id, this.questions)
      msg.done('Job was saved').autoDismiss()
      this.hide()
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
    }
  }

  @task.resolved
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const msg = this.flashMessageStore.create({
      inProgress: true,
      message: 'Preparing Job',
    })

    try {
      await this.renameJob(this.job.id)
      await this.requestChampionProvider()
      await this.draftStore.replaceQuestionAnswers(this.job.id, this.questions)
      await this.draftStore.submitDraft(this.job.id, null, this.billExternal)
      msg.done('Job was submitted').autoDismiss()
      this.hide()
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
    }
  }
}

export default CreateJobEditorStore
