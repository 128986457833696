import TeamManagementContainer from '@taxfyle/web-commons/lib/iam/containers/TeamManagementContainer'
import PageWrapper from 'components/PageWrapper'
import Searchbar from 'components/Searchbar'
import ScreenProtector from 'iam/containers/ScreenProtector'
import { observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './Teams.m.sass'

@observer
class TeamsScreen extends React.Component {
  state = { shouldSearchShow: false }

  render() {
    const store = this.props.rootStore.teamManagementStore
    const {
      filtered: teams,
      searchText,
    } = this.props.rootStore.teamManagementStore
    const showSearch = !!((teams && teams.length) || searchText)

    return (
      <ScreenProtector allOf={['ADMIN_TEAMS_VIEW']}>
        <Helmet title="Teams" />
        {showSearch && !this.state.shouldSearchShow && (
          <Searchbar
            placeholder="Search teams..."
            value={store.searchText}
            onChange={(v) => store.setSearchText(v)}
          />
        )}
        <PageWrapper className="teams-screen">
          <div className={styles.hasSpacingBottom}>
            <TeamManagementContainer
              rootStore={this.props.rootStore}
              billingViews={['BILLING']}
              hideSearchBar={(state) =>
                this.setState({ shouldSearchShow: state })
              }
              allowCreation={true}
            />
          </div>
        </PageWrapper>
      </ScreenProtector>
    )
  }
}

export default TeamsScreen
