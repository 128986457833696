import Button from 'components/Button'
import Icon from 'components/Icon'
import PageWrapper from 'components/PageWrapper'
import RequirePerms from 'iam/containers/RequirePerms'
import ScreenProtector from 'iam/containers/ScreenProtector'
import { observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import Searchbar from 'components/Searchbar'
import SkillEditor from './SkillEditor'
import styles from './Skills.m.sass'
import { Dropdown, DropdownItem } from 'components/Dropdown'
import CsvExport from '../Jobs/FacetedSearch/CsvExport'
import FileButton from 'components/FileButton'
import ContentLoading from 'components/ContentLoading'
import InfiniteScrollZone from 'components/InfiniteScrollZone'

@observer
export default class SkillsScreen extends React.Component {
  componentDidMount() {
    this.props.rootStore.skillsScreenStore.activate()
  }

  render() {
    const { skillsScreenStore } = this.props.rootStore
    const { scrollProvider } = skillsScreenStore

    return (
      <ScreenProtector anyOf={['ADMIN_SKILLS_VIEW']}>
        <PageWrapper className={styles.skillsScreen} pageTitle="Skills">
          <Helmet title="Skills" />
          <div className={styles.columns}>
            <RequirePerms allOf={['ADMIN_SKILLS_MANAGE']}>
              <div className={styles.container}>
                <Button
                  iconLeft={<Icon material name="add" />}
                  type="primary"
                  onClick={() => skillsScreenStore.newItem()}
                >
                  Add Skill
                </Button>
                <CsvExport asyncExportMethod={skillsScreenStore.getCsvData}>
                  <Button
                    iconLeft={<Icon material name="cloud_download" />}
                    type="primary"
                  >
                    Export
                  </Button>
                </CsvExport>
                &nbsp;
                <FileButton
                  onChange={skillsScreenStore.onChangeFile}
                  multiple={false}
                  accept=".csv"
                >
                  <Button
                    iconLeft={<Icon material name="cloud_upload" />}
                    type="primary"
                  >
                    Import
                  </Button>
                </FileButton>
              </div>
            </RequirePerms>
            &nbsp;
            <Searchbar
              placeholder="Search skills..."
              value={skillsScreenStore.searchText}
              onChange={(v) => skillsScreenStore.setSearchText(v)}
            />
            &nbsp;
            <Dropdown
              position="right"
              button={({ ref, ...buttonProps }) => (
                <Button
                  outline
                  borderless
                  className={styles.dropdownButton}
                  iconRight={<Icon material small name="arrow drop down" />}
                  size="small"
                  innerRef={ref}
                  {...buttonProps}
                >
                  {skillsScreenStore.sort.label}
                </Button>
              )}
            >
              {skillsScreenStore.sortOptions.map((option, i) => (
                <DropdownItem
                  key={i}
                  onClick={() => skillsScreenStore.changeSorting(option)}
                >
                  {option.label}
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
          {skillsScreenStore.items.map((skill) => (
            <SkillEditor editor={skill} key={skill.id} />
          ))}
          {scrollProvider && (
            <InfiniteScrollZone scrollProvider={scrollProvider}>
              {({ isDone, active }) => !isDone && <ContentLoading smaller />}
            </InfiniteScrollZone>
          )}
        </PageWrapper>
      </ScreenProtector>
    )
  }
}
