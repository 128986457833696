import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import ErrorLabel from 'components/ErrorLabel'
import { FormGroup, Input } from 'components/Form'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import QuestionGrid from 'jobs/components/QuestionsEditor'
import { HtmlClass } from 'components/ClassName'
import RequirePerms from 'iam/containers/RequirePerms'
import './JobUpdateDialog.sass'
import UserSelector from 'components/UserSelector'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import { filterOutAllMembersOf } from 'utils/userSelectorFilterUtil'

@observer
export default class JobUpdateDialog extends React.Component {
  renderDialog() {
    const { store } = this.props
    if (!store.job) return null

    return (
      <div className="job-update-dialog">
        <DialogTitle>Update job</DialogTitle>
        <DialogContent>
          <Input
            label="Reason"
            placeholder="e.g. Client selected the wrong amount of K1's"
            model={store}
            field="reason"
            help={`This will be visible to the ${T(
              'Web.Common.Client',
              'Client'
            )}`}
          />
          <Input
            label="Name"
            placeholder="The Name of the Job"
            model={store}
            field="name"
            help="The main name of the Job."
          />
          <RequirePerms allOf={['ADMIN_JOB_CREATE']}>
            {store.job.status === 'UNDER_CONSTRUCTION' && (
              <>
                <Input
                  label="Bill External"
                  type="checkbox"
                  model={store}
                  field="billExternal"
                  help="Should we charge the default billing method or will this be handled manually."
                />
                <FormGroup label="Assign a provider to the job:">
                  <UserSelector
                    allowEmpty
                    userFilter={filterOutAllMembersOf(store.job)}
                    workspaceId={store.rootStore.sessionStore.workspace.id}
                    memberStore={store.rootStore.memberStore}
                    name="new-provider"
                    onChange={store.selectProvider}
                    value={store.assignToPro}
                  />
                </FormGroup>
              </>
            )}
          </RequirePerms>
          <FormGroup label="Questions">
            <QuestionGrid
              questions={store.questions}
              answers={store.answers}
              disabled={store.fetchQuestions.pending}
              loading={
                store.questions.length === 0 && store.fetchQuestions.pending
              }
              onChange={(answers) => store.setAnswers(answers)}
              onUpdate={store.fetchQuestions}
              errors={store}
            />
          </FormGroup>
          <ErrorLabel>
            {store.error ||
              (store.hasBreaker ? 'Cannot save a job with a breaker.' : null)}
          </ErrorLabel>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide}>Cancel</DialogButton>
          <DialogButton
            primary
            onClick={store.save}
            disabled={store.hasBreaker || store.busy}
          >
            Save
          </DialogButton>
          <RequirePerms allOf={['ADMIN_JOB_CREATE']}>
            {store.job.status === 'UNDER_CONSTRUCTION' && (
              <DialogButton
                danger
                onClick={store.submit}
                disabled={store.hasBreaker || store.busy}
              >
                Submit
              </DialogButton>
            )}
          </RequirePerms>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        className="job-update-modal"
        position="top"
        dismissOnOutsideClick={false}
      >
        <HtmlClass className="disable-scrolling" />
        <Dialog>
          {store.save.match({
            pending: () => <ContentLoading small />,
            rejected: (err) => <ContentError error={err} />,
            resolved: () => this.renderDialog(),
          })}
        </Dialog>
      </Modal>
    )
  }
}
