import { Store } from 'libx'
import { browserHistory } from 'react-router'
import { computed, observable, action } from 'mobx'
import orderBy from 'lodash/orderBy'
import links from 'misc/link'
import { task } from 'mobx-task'
import InfiniteScrollProvider from 'data/InfiniteScrollProvider'
import { required } from 'validx'
import { validatable } from 'legend-builder/mixins/validation'

/**
 * State for the Legends view.
 */
@validatable()
export default class LegendsViewStore extends Store {
  /**
   * Name for the new legend (used by the NewLegendEditor)
   */
  @observable
  newLegendName = ''

  /**
   * Whether the New Legend editor is showing.
   */
  @observable
  isShowingNewEditor = false

  /**
   * Should we include inactive legends in the list?
   */
  @observable
  includingInactive = false

  /**
   * Validation rules.
   */
  rules = {
    newLegendName: [required()],
  }

  /**
   * Initializes the state.
   */
  constructor() {
    super(...arguments)
    this.createLegend = this.createLegend.bind(this)
    this.legendScroller = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: 50,
      fetchItems: this._fetchLegends.bind(this),
    })
    this.toggleActivation = this.toggleActivation.bind(this)
    this.rootStore.sessionStore.onWorkspaceSwitched(() => {
      this.reset()
      this.legendScroller.reset()
      this.activate.reset()
    })
  }

  /**
   * All legends for this workspace, sorted accordingly.
   */
  @computed
  get allLegends() {
    return orderBy(
      this.rootStore.legendStore.legendsForWorkspace(),
      [
        (legend) => {
          // We need to derive a number for the statuses. In this case, we want non-inactive legends
          // at the top, and inactive ones at the bottom
          return legend.inactive ? 1 : 0
        },
      ],
      // Then sort by view order
      [(legend) => legend.latestVersion.view_order, 'asc']
    )
  }

  /**
   * Filtered legends.
   */
  @computed
  get legends() {
    const legends = this.allLegends
    return this.includingInactive ? legends : legends.filter((l) => !l.inactive)
  }

  /**
   * The current workspace.
   */
  @computed
  get workspace() {
    return this.rootStore.sessionStore.workspace
  }

  /**
   * When the view loads, it calls this to fetch the legends.
   */
  @task
  async activate() {
    this.reset()
    await this.legendScroller.fetch()
    this.showInactiveIfOtherwiseEmpty()
  }

  /**
   * Resets the state.
   */
  @action
  reset() {
    this.newLegendName = ''
    this.validation.reset()
  }

  /**
   * Shows the New Legend editor.
   */
  @action.bound
  showNewEditor() {
    this.reset()
    this.isShowingNewEditor = true
  }

  /**
   * Closes the New Legend editor.
   */
  @action.bound
  closeNewEditor() {
    this.isShowingNewEditor = false
  }

  /**
   * Toggles showing the inactive legends.
   */
  @action.bound
  showInactiveLegends() {
    this.includingInactive = true
  }

  /**
   * Shows the inactive legends as well, if there are no active legends.
   */
  showInactiveIfOtherwiseEmpty() {
    if (this.legends.length === 0 && this.allLegends.length > 0) {
      this.showInactiveLegends()
    }
  }

  /**
   * Creates a new legend.
   */
  @task.resolved
  async createLegend() {
    if (!this.validateAll()) {
      return
    }
    this.closeNewEditor()
    return this.rootStore.legendStore
      .create({
        name: this.newLegendName,
        workspace_id: this.rootStore.sessionStore.workspace.id,
      })
      .then((l) =>
        browserHistory.push(
          links.editLegend(this.rootStore.sessionStore.workspace.slug, l.id)
        )
      )
  }

  /**
   * Toggles whether a Legend is active.
   * @param {Legend} legend
   */
  @task.resolved
  async toggleActivation(legend) {
    await (legend.inactive
      ? this.rootStore.legendStore.activate(legend.id)
      : this.rootStore.legendStore.deactivate(legend.id))
    this.showInactiveIfOtherwiseEmpty()
  }

  /**
   * Used by the scroll provider to fetch legends and provide infinite scrolling.
   *
   * @param {*} params
   */
  _fetchLegends(params) {
    return this.rootStore.legendStore.fetchLegends(params)
  }
}
