import { observable, action, computed, runInAction } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, required } from 'validx'
import { FlashMessageTypes } from '@taxfyle/web-commons/lib/flash-messages/FlashMessageStore'

class LegacyJobUpdateState {
  @observable showing = false
  @observable error = ''
  @observable reason = ''
  @observable answers = []
  @observable availableStates = []
  @observable job
  @observable name
  @observable _questions = []
  @observable billExternal = true
  @observable assignToPro = null

  validation = validationContext()

  constructor({ rootStore }) {
    this.rootStore = rootStore
    this.fetchQuestions = this.fetchQuestions.bind(this)
  }

  @action.bound
  init(job) {
    this.validation.reset()
    this.reason = ''

    this.job = job
    this.name = job.name
    this.save = this.save.bind(this)
    this.submit = this.submit.bind(this)
    this.answers = this.job.answers.slice()
    this.billExternal = job.billingType && job.billingType === 'EXTERNAL'
    this.assignToPro = job.request_pro_id || null
    this._questions = []
    this.initialLoad()
  }

  @computed
  get questions() {
    return this._questions.map((q) => {
      const answer = this.answers.find((x) => x.id === q.id)
      return answer ? { ...q, answer: answer.answer } : q
    })
  }

  @computed
  get hasBreaker() {
    return this.questions.some((x) => x.type === 'BREAKER')
  }

  get billUserAllowed() {
    return (
      this.rootStore.sessionStore.workspace.features.billing.enabled &&
      (!this.job || !this.job.legend.skipBilling)
    )
  }

  @task
  async initialLoad() {
    await this.maybeUpgradeLegend()
    await this.fetchQuestions()
  }

  @task
  async fetchQuestions() {
    const { questions } = await this.rootStore.questionStore.getQuestions({
      job: this.job.id,
      answers: this.answers,
    })

    // let addedDefaultAnswers = false
    // questions.forEach(q => {
    //   const answer = this.answers.find(x => x.id === q.id)
    //   if (!answer && q.type === 'BOOL') {
    //     addedDefaultAnswers = true
    //     this.answers.push({
    //       id: q.id,
    //       answer: false
    //     })
    //   }
    // })

    this.answers = questions.map((q) => ({
      id: q.id,
      answer: q.answer,
    }))

    // if (addedDefaultAnswers) {
    //   await this.fetchQuestions()
    // } else {
    runInAction(() => {
      this._questions = questions
    })
    // }
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @action.bound
  selectYears(years) {
    this.filingYears = years
  }

  @action.bound
  selectStates(states) {
    this.states = states
  }

  @action.bound
  setAnswers(answers) {
    this.answers = answers
  }

  @computed
  get busy() {
    return (
      this.fetchQuestions.pending || this.save.pending || this.submit.pending
    )
  }

  @task.resolved
  async save() {
    const rules = {
      reason: [
        required(
          'Please specify a reason for this update so the Client knows we are not just hustling them.'
        ),
      ],
    }
    if (this.job.status !== 'UNDER_CONSTRUCTION') {
      this.questions.forEach((q) => {
        if (q.type && q.type !== 'DOCUMENTS') {
          rules[q.id] = [
            () =>
              q.answer === undefined ||
              q.answer === null ||
              q.answer.length === 0
                ? 'Please answer this question.'
                : true,
          ]
        }
      })
    }

    if (!this.validation.reset().validate(this, rules).isValid) {
      return
    }

    await this.rootStore.jobStore.updateJob(this.job.id, {
      name: this.name,
      billing_type: this.billExternal ? 'EXTERNAL' : 'STRIPE',
      answers: this.answers.slice(),
      reason: this.reason,
      requested_pro_id:
        this.assignToPro && this.assignToPro.userPublicId !== '-1'
          ? this.assignToPro.userPublicId
          : null,
    })

    this.hide()
  }

  @task.resolved
  async submit() {
    const rules = {
      reason: [
        required(
          'Please specify a reason for this update so the Client knows we are not just hustling them.'
        ),
      ],
    }
    this.questions.forEach((q) => {
      if (q.type && q.type !== 'DOCUMENTS') {
        rules[q.id] = [
          () =>
            q.answer === undefined || q.answer === null || q.answer.length === 0
              ? 'Please answer this question.'
              : true,
        ]
      }
    })
    if (!this.validation.reset().validate(this, rules).isValid) {
      return
    }

    await this.rootStore.jobStore.updateJob(this.job.id, {
      name: this.name,
      status: 'UNCLAIMED',
      billing_type:
        // check the checkbox
        // or
        // if we are not allowed to bill the user default to EXTERNAL
        this.billExternal || !this.billUserAllowed ? 'EXTERNAL' : 'STRIPE',
      answers: this.answers.slice(),
      reason: this.reason,
      requested_pro_id:
        this.assignToPro && this.assignToPro.userPublicId !== '-1'
          ? this.assignToPro.userPublicId
          : null,
    })

    this.hide()
  }

  @action.bound
  async selectProvider(user) {
    this.assignToPro = user
  }

  /**
   * Attempts to upgrade the Legend version to the latest published version
   * if it is out of date for a draft job.
   * @returns {Promise<void>}
   */
  async maybeUpgradeLegend() {
    // If the Job is currently a draft, attempt to upgrade the Legend to the latest
    // version.
    if (this.job.status !== 'UNDER_CONSTRUCTION') {
      return
    }

    const latestPublishedLegend = await this.rootStore.publishedLegendStore.fetchPublishedLegendVersion(
      this.job.legendId,
      null,
      true
    )

    if (!this.showing) {
      // We closed the dialog while we were waiting, so don't do anything else.
      return
    }

    if (latestPublishedLegend.version <= this.job.legendVersion) {
      // It's up to date, nothing to do here.
      return
    }

    const msg = this.rootStore.flashMessageStore.create({
      type: FlashMessageTypes.DEFAULT,
      inProgress: true,
      message: 'There is a new version of the Legend; upgrading...',
    })
    try {
      await this.rootStore.jobStore.changeLegendVersion(
        this.job.id,
        latestPublishedLegend.id,
        latestPublishedLegend.version
      )
      msg.done('The Legend was upgraded!').autoDismiss(10000)
    } catch (err) {
      msg.forError(err)
      throw err
    }
  }
}

export default LegacyJobUpdateState
