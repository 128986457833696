import { observable, action, computed } from 'mobx'
import FilterEditor from './FilterEditor'
import { validatable } from 'legend-builder/mixins/validation'
import { validateElements } from 'utils/validx-validators'
import { addGenericErrorForProperty, receiveErrors } from '../data/serverErrors'

/**
 * Filters builder.
 */
@validatable()
export default class FiltersBuilder {
  /**
   * The filters themselves.
   */
  @observable
  filters = []

  /**
   * `scope` is used to determine what filters are available based on what data
   * can be provided at the time they are needed.
   *
   * For example, question conditions cannot use provider info because it is not available at the
   * time the engine executes them. But routing filters *can* access the provider, and so any
   * filters builder that has the `provider` scope can use filters that depend on the provider.
   */
  scope = []

  /**
   * The consumer of the builder can pass in either an entity or a top-level Builder
   * that can be used to query for available symbols.
   */
  @observable.ref
  context

  /**
   * Convenience prop for displaying a filters builder in an accordion.
   */
  @observable
  expanded = false

  rules = {
    filters: [validateElements('There are invalid filters.')],
  }

  constructor({ legendEditor, scope, context }) {
    this.legendEditor = legendEditor
    this.scope = scope || []
    this.context = context
  }

  /**
   * Whether the filters are empty.
   * @returns {boolean}
   */
  @computed
  get empty() {
    return this.length === 0
  }

  @computed
  get length() {
    return this.filters.length
  }

  hydrate(filters = []) {
    this.filters.replace(
      filters
        .filter(Boolean)
        .map((f) => FilterEditor.fromJS(f, _filterOpts(this)))
    )
  }

  @action
  addFilter(data) {
    this.filters.push(FilterEditor.create(data, _filterOpts(this)))
  }

  @action
  removeFilter(filter) {
    this.filters.remove(filter)
  }

  @action.bound
  setExpanded(value) {
    this.expanded = value
  }

  receiveErrors(descriptor) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'filters',
      'There are invalid conditions'
    )
    receiveErrors(this.filters, descriptor.inner)
  }

  toJSON() {
    return this.filters.map((x) => x.toJSON())
  }
}

function _filterOpts(builder, opts) {
  return {
    legendEditor: builder.legendEditor,
    scope: builder.scope,
    context: builder.context,
    ...opts,
  }
}
