import { decimalToNumber } from '@taxfyle/web-commons/lib/utils/grpcUtil'

/**
 * Finds the most relevant bundle from a list of bundles based on given inventory item IDs.
 *
 * @param {Array.<Object>} bundles - The list of bundles to search through.
 * @param {Array.<string>} inventoryItemIds - The inventory item IDs to match against.
 * @returns {Object|null} - The most relevant bundle or null if no bundle is found.
 */
export function mostRelevantBundle(bundles, inventoryItemIds) {
  const matchingBundles = bundles.filter((b) =>
    inventoryItemIds.includes(b.baseItem.inventoryItemId)
  )
  if (matchingBundles.length === 0) {
    return null
  }

  const bundleRelevantIncludedItemsCountMap = new Map()

  for (const b of matchingBundles) {
    const count = b.includedItemsList.filter((i) =>
      inventoryItemIds.includes(i.inventoryItemId)
    ).length
    bundleRelevantIncludedItemsCountMap.set(b, count)
  }

  const mostIncludedItemCount = Math.max(
    ...Array.from(bundleRelevantIncludedItemsCountMap.values())
  )

  if (mostIncludedItemCount === 0) {
    return matchingBundles.reduce((minBundle, bundle) =>
      decimalToNumber(minBundle.price) < decimalToNumber(bundle.price)
        ? minBundle
        : bundle
    )
  }

  const bundlesWithMostIncludedItems = Array.from(
    bundleRelevantIncludedItemsCountMap
  )
    .filter(([_, itemCount]) => itemCount === mostIncludedItemCount)
    .map(([bundle, _]) => bundle)

  // If there's more than one bundle, get the one with the minimum price
  return bundlesWithMostIncludedItems.reduce((minBundle, bundle) =>
    decimalToNumber(minBundle.price) < decimalToNumber(bundle.price)
      ? minBundle
      : bundle
  )
}
