import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ErrorLabel from 'components/ErrorLabel'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import { Input } from 'components/Form'
import './InvoiceDialog.sass'

@observer
export default class JobInvoiceDialog extends React.Component {
  renderDialog() {
    const { store } = this.props
    if (!store.job) return null

    return (
      <div className="amendments-dialog">
        <DialogTitle>Add Manual Invoice To Job</DialogTitle>
        <DialogContent>
          <Input
            label="Reason"
            placeholder="e.g. Credit Card on file didn't work. Add the invoice number (INV-XXXX)."
            model={store}
            field="reason"
            help={`This will be visible to the ${T(
              'Web.Common.Client',
              'Client'
            )} & ${T('Web.Common.Provider', 'Pro')}`}
          />
          <Input
            label="Stripe Id"
            placeholder="The Stripe Charge Id (ch_XXXXXXXXXXXXXX)."
            model={store}
            field="stripeId"
            help=""
          />
          <Input
            label="Invoice Amount"
            placeholder="e.g. How much was paid with the invoice."
            model={store}
            field="amount"
            help=""
          />
          <ErrorLabel>{store.error}</ErrorLabel>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide}>Close</DialogButton>
          <DialogButton primary onClick={store.addInvoice}>
            Add Invoice
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        className="invoice-modal"
        position="top"
      >
        <Dialog>
          {store.addInvoice.match({
            pending: () => <ContentLoading small />,
            rejected: (err) => <ContentError error={err} />,
            resolved: () => this.renderDialog(),
          })}
        </Dialog>
      </Modal>
    )
  }
}
