import { Model, referenceOne } from 'libx'
import { computed, observable } from 'mobx'
import moment from 'moment'
import { omitUndefined } from 'utils/objectUtil'

export default class ScopingJob extends Model {
  @observable legendId = null
  @observable legendVersion = 0
  @observable legendName = ''
  @observable workspaceId = null
  @observable userId = ''
  @observable userPublicId = ''
  @observable supportingClientUserPublicId = ''
  @observable teamId = ''
  @observable createdById = ''
  @observable createdByPublicId = ''
  @observable status = 'CREATED'
  @observable statusName = ''
  @observable jobId = ''
  @observable dateCompleted = null
  @observable dateLastExecution = null
  @observable dateCreated = null
  @observable errorMessage = ''
  @observable confidence
  @observable jobName = ''
  @observable fileName = ''

  @computed
  get member() {
    return this.rootStore.memberStore.forWorkspaceAndUserId(
      this.workspaceId,
      this.userId
    )
  }

  @computed
  get createdBy() {
    return this.rootStore.memberStore.forWorkspaceAndUserId(
      this.workspaceId,
      this.createdById
    )
  }

  @computed
  get workspace() {
    return referenceOne(
      this.rootStore.workspaceStore.workspaces,
      this.workspaceId
    )
  }

  @computed
  get team() {
    return referenceOne(this.rootStore.teamStore.teams, this.teamId)
  }

  parse(json) {
    this.rootStore.memberStore.setAndFetch({
      workspace_id: json.workspace_id,
      user_id: json.user_id,
      user_public_id: json.user_public_id,
    })
    this.rootStore.memberStore.setAndFetch({
      workspace_id: json.workspace_id,
      user_id: json.created_by_id,
      user_public_id: json.created_by_user_public_id,
    })

    // fetch the team
    if (json.team_id) {
      this.rootStore.teamStore.fetchTeam(json.team_id)
    }

    return omitUndefined({
      id: json.id,
      userId: json.user_id,
      userPublicId: json.user_public_id,
      supportingClientUserPublicId: json.supporting_client_user_public_id,
      workspaceId: json.workspace_id,
      teamId: json.team_id,
      legendId: json.legend_id,
      legendVersion: json.legend_version,
      legendName: json.legend_name,
      createdById: json.created_by_id,
      createdByPublicId: json.created_by_user_public_id,
      status: json.status,
      statusName: json.status_name,
      jobId: json.job_id,
      jobName: json.job_name,
      fileName: json.file_name,
      dateCreated: moment(json.date_created),
      dateCompleted: json.date_completed
        ? moment(json.date_completed)
        : json.date_completed,
      dateLastExecution: json.date_last_execution
        ? moment(json.date_last_execution)
        : json.date_last_execution,
      errorMessage: json.error_message,
      confidence: json.confidence,
    })
  }
}
