import { Widget, WidgetContent } from 'components/Widget'
import { observer } from 'mobx-react'
import React from 'react'
import { getUnitFormatter } from 'utils/unitFormatter'
import { Dropdown, DropdownItem } from 'components/Dropdown'
import Icon from 'components/Icon'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'

import './JobSummary.sass'
import {
  CopyButton,
  useCopyButton,
} from '@taxfyle/web-commons/lib/components/CopyButton'

const InfoLine = ({
  title,
  value,
  tooltip,
  children,
  'data-test': dataTest,
}) => (
  <div className="info-line" title={tooltip}>
    <div className="info-line__title">{title}</div>
    {children ? (
      <div className="info-line__value">{children}</div>
    ) : (
      <div className="info-line__value" data-test={dataTest}>
        {value}
      </div>
    )}
  </div>
)

const JobSummary = ({ store }) => {
  const { job } = store
  const formatUnit = getUnitFormatter(job.legend && job.legend.priceUnit)
  const canSeePrice = store.rootStore.sessionStore.member.hasPermission(
    'VIEW_PRICING'
  )

  const canSeeProCut = store.rootStore.sessionStore.member.hasPermission(
    'ADMIN_PRO_CUT_VIEW'
  )

  const [shortIdCopyButtonProps, shortIdHoverTargetProps] = useCopyButton(
    job.shortId
  )

  return (
    <Widget
      title={T('Web.JobSummary.Title', 'Job Summary')}
      className="JobSummary"
    >
      <WidgetContent>
        <div className="columns widget-body">
          <div className="column">
            <InfoLine title="Job ID">
              <span {...shortIdHoverTargetProps} className="short-id-container">
                <span className="short-id-text">{job.shortId}</span>
                <span className="short-id-copy-button">
                  <CopyButton {...shortIdCopyButtonProps} />
                </span>
              </span>
            </InfoLine>
            {job.legendId && (
              <InfoLine
                title="Legend"
                value={`${job.legendName || job.legendId} V${
                  job.legendVersion
                }`}
              />
            )}

            <InfoLine
              title="Status"
              data-test="job-status"
              value={`${job.status.replace('_', ' ')} ${
                job.stale ? '- STALE' : ''
              }`}
            />
            {job.resolution && (
              <InfoLine
                title="Resolution"
                value={job.resolution.replace('_', ' ')}
              />
            )}
            {job.years.length > 0 && (
              <InfoLine title="Year(s)" value={job.years.join(', ')} />
            )}
            <InfoLine title={T('Web.Common.Job', 'Job')} value={job.name} />
            {job.formattedJobSpecs.length > 0 && (
              <InfoLine
                title="Specs"
                value={job.formattedJobSpecs.join(' / ')}
              />
            )}
            {job.states.length > 0 && (
              <InfoLine title="State Return" value={job.states.join(', ')} />
            )}
            {job.dateTransmitted && (
              <InfoLine
                title="Date Submitted"
                value={job.dateTransmitted.format('LL LT')}
              />
            )}
            {job.isDeadlineRelevant && (
              <InfoLine
                title="Deadline"
                value={job.dateDeadline.format('LL LT')}
              />
            )}
          </div>
          {job.status !== 'UNDER_CONSRTRUCTION' && (
            <div className="column">
              {canSeePrice && job.primaryAmount > 0 && (
                <InfoLine
                  title="Primary Amount"
                  value={formatUnit(job.primaryAmount)}
                />
              )}
              {canSeePrice && job.adminFeeAmount > 0 && (
                <InfoLine title="Fees" value={formatUnit(job.adminFeeAmount)} />
              )}
              {canSeePrice && job.couponAmount > 0 && (
                <InfoLine
                  title="Coupon Amount"
                  value={formatUnit(job.couponAmount)}
                />
              )}
              {canSeePrice && job.amendedAmount !== 0 && (
                <InfoLine
                  title="Amended Amount"
                  value={formatUnit(job.amendedAmount)}
                />
              )}
              {canSeePrice && (
                <InfoLine
                  title="Total"
                  value={formatUnit(job.total)}
                  data-test="total-amount"
                />
              )}
              {canSeePrice && canSeeProCut && (
                <InfoLine
                  title={`Total ${T('Web.Common.Provider', 'Pro')} Cut`}
                  value={formatUnit(job.providerCutAmount)}
                />
              )}
              {canSeePrice && (
                <InfoLine
                  title={`${T('Web.Common.Client', 'Client')} has paid`}
                  value={formatUnit(job.paidAmount)}
                >
                  {store.stripePurchaseTransactions.length > 0 ? (
                    <Dropdown
                      position="right"
                      button={({ innerRef, ...bp }) => (
                        <a ref={innerRef} {...bp} onClick={bp.onClick}>
                          <span
                            className="black"
                            data-test="client-paid-amount"
                          >
                            {formatUnit(job.paidAmount)}
                          </span>{' '}
                          <span className="invoice">
                            View Receipts
                            <Icon material name="keyboard_arrow_down" />
                          </span>
                        </a>
                      )}
                    >
                      {store.stripePurchaseTransactions.map((x) => (
                        <DropdownItem key={x.id} stretch>
                          <a
                            href={x.metadata.receipt_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {formatUnit(x.amount / 100)} on{' '}
                            {x.dateCreated.format('LL LT')}
                          </a>
                        </DropdownItem>
                      ))}
                    </Dropdown>
                  ) : (
                    <>{formatUnit(job.paidAmount)}</>
                  )}
                </InfoLine>
              )}
              {job.payoutTotal > 0 && (
                <InfoLine
                  title="Payout Total"
                  value={formatUnit(job.payoutTotal)}
                  tooltip={job.stripePayouts}
                />
              )}
            </div>
          )}
          <div className="column">
            {job.couponCodes.length > 0 && (
              <InfoLine
                title="Coupons Used"
                value={job.couponCodes.join(', ')}
                data-test="coupons-used-list"
              />
            )}
          </div>
        </div>
      </WidgetContent>
    </Widget>
  )
}

export default observer(JobSummary)
