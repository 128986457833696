import AuthStoreBase from 'auth/AuthStore'
import { action } from 'mobx'

export default class AuthStore extends AuthStoreBase {
  constructor({ rootStore, storage }) {
    super(...arguments)
  }

  async fetchWorkspaceMemberships(
    userId = this.rootStore.authStore.user.userId
  ) {
    return await this.rootStore.api.iam
      .getUserWorkspaceMemberships(userId)
      .then((memberships) => {
        return memberships.map((m) => ({
          workspace: this.rootStore.workspaceStore.workspaces.set(m.workspace),
          member: this.rootStore.memberStore.members.set(m.member),
        }))
      })
  }

  async fetchWorkspaceMembershipsByPublicId(
    userId = this.rootStore.authStore.user.userPublicId
  ) {
    return await this.rootStore.api.iam
      .getUserWorkspaceMembershipsByPublicId(userId)
      .then((memberships) => {
        return memberships.map((m) => ({
          workspace: this.rootStore.workspaceStore.workspaces.set(m.workspace),
          member: this.rootStore.memberStore.members.set(m.member),
        }))
      })
  }

  @action
  login(initialScreen, opts) {
    return this._showDialogAndAuthorize(initialScreen, opts)
  }

  async _showDialogAndAuthorize(initialScreen, opts) {
    if (!opts) {
      opts = {}
    }

    this.auth0.logout({
      localOnly: true,
    })

    return this.auth0.loginWithRedirect({
      ...opts,
      appState: {
        customReferrerParams: this.referrerParams,
      },
      initial_screen: initialScreen || 'login',
      referrer: document.location.href,
      connections: window.__auth0Connections.filter(
        (s) => s !== 'rocket-lawyer-sso'
      ),
    })
  }
}
