import { snapshotable } from 'legend-builder/data/serialization'
import { validatable } from 'legend-builder/mixins/validation'
import { Store } from 'libx'
import flattenDeep from 'lodash/flattenDeep'
import { action, computed, observable } from 'mobx'
import { move as _move } from 'utils/mobx-util'
import { match } from 'utils/patternMatchUtil'
import { validateElements } from 'utils/validx-validators'
import { addGenericErrorForProperty, receiveErrors } from '../data/serverErrors'
import { Types } from '../data/Type'
import PropertyInspector from './PropertyInspector'
import QuestionEditor from './QuestionEditor'

/**
 * Questions builder.
 */
@snapshotable
@validatable()
export default class QuestionsBuilder extends Store {
  idCounters = new Map()
  scope = []

  @observable
  parentQuestion = null

  @observable
  questions = []

  rules = {
    questions: [validateElements('There are invalid questions.')],
  }

  /**
   * Constructs a questions builder.
   *
   * @param {LegendEditorViewStore} opts.legendEditor
   * The legend editor.
   *
   * @param {QuestionEditor?} opts.parentQuestion
   * Parent question, if any. Used to roll up questions, as well as manage sub-questions.
   *
   * @param {Array<string>} opts.scope
   * Scope passed to filters, determines what info is available to them.
   *
   * @param {() => Array<QuestionEditor>} opts.getPossibleQuestionsSource
   * Used by filters to list possible questions to operate on.
   */
  constructor({ legendEditor, parentQuestion, scope }) {
    super({})
    this.legendEditor = legendEditor
    this.parentQuestion = parentQuestion
    this._inspector = new PropertyInspector()
    this.scope = scope || []
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Entities getter.
   */
  @computed
  get entityDescriptors() {
    const result = this.allQuestions.map((question) => ({
      type:
        question.type === 'Variable'
          ? 'Variable'
          : question.type === 'InventoryItem'
          ? 'InventoryItem'
          : 'Question',
      hidden:
        question.type === 'Path' ||
        question.type === 'Breaker' ||
        question.type === 'Effect',
      entity: question,
    }))
    return [
      ...result,
      {
        type: 'Variable',
        hidden: false,
        entity: {
          id: '__job_total',
          format: { type: 'Decimal', places: 2 },
          type: 'Numeric',
          valueType: Types.number,
        },
      },
    ]
  }

  /**
   * Gets the root builder in the tree.
   */
  @computed
  get rootBuilder() {
    if (this.parentQuestion) {
      return this.parentQuestion.parentQuestionsBuilder.rootBuilder
    }
    return this
  }

  /**
   * Property Inspector to use.
   * Nested builders must use the inspector from the root.
   */
  @computed
  get inspector() {
    if (this.rootBuilder === this) {
      return this._inspector
    }
    return this.rootBuilder.inspector
  }

  /**
   * Returns all questions in this tree.
   */
  @computed
  get allQuestions() {
    return flattenQuestions(this.rootBuilder.questions)
  }

  /**
   * Returns all questions in this builder as well as their subquestions.
   */
  @computed
  get flattenedQuestions() {
    return flattenQuestions(this.questions)
  }

  /**
   * Gets the selected question belonging to this builder.
   */
  get selectedQuestion() {
    return this.inspector.question
  }

  /**
   * Starts editing the question.
   *
   * @param {QuestionEditor} question
   */
  @action.bound
  edit(question) {
    this.inspector.activate(question)
  }

  /**
   * Adds a new question relative to the specified `relativeQuestion` if present.
   *
   * @param {string} type
   * @param {QuestionEditor?} relativeQuestion
   * @param {number} direction
   */
  @action.bound
  add(type, relativeQuestion = null, direction = 0) {
    const id =
      type === 'Effect'
        ? undefined
        : this.nextId(
            match(type, {
              Variable: () => 'VARIABLE_',
              Path: () => 'PATH_',
              InventoryItem: () => 'INVENTORY_ITEM_',
              Breaker: () => 'Breaker_',
              [match.DEFAULT]: () => 'QUESTION_',
            })
          )
    const newQuestion = QuestionEditor.create(
      { type, id },
      this._questionOpts()
    )
    const collection = relativeQuestion
      ? relativeQuestion.parentQuestionsBuilder.questions
      : this.questions

    collection.push(newQuestion)
    if (relativeQuestion) {
      const oldIndex = collection.indexOf(newQuestion)
      let newIndex = collection.indexOf(relativeQuestion) + direction
      newIndex = Math.min(collection.length - 1, newIndex)
      newIndex = Math.max(0, newIndex)
      move(collection, oldIndex, newIndex)
    }

    this.edit(newQuestion)
    newQuestion.focusIdField()
    this.legendEditor.addQuestion$.next(newQuestion)
    return newQuestion
  }

  /**
   * Moves the question.
   *
   * @param {QuestionEditor} source
   * @param {QuestionEditor} target
   * @param {number} direction
   */
  @action.bound
  move(source, target, direction = 0) {
    if (source === target) {
      return
    }

    const sourceBuilder = source.parentQuestionsBuilder
    if (!target) {
      // We're dragging into an empty list.
      // Simply reassign the builder and add to this.
      sourceBuilder.questions.remove(source)
      this.questions.push(source)
      source.parentQuestionsBuilder = this
      return
    }

    const targetBuilder = target.parentQuestionsBuilder
    if (sourceBuilder === targetBuilder) {
      const fromIndex = sourceBuilder.questions.indexOf(source)
      const toIndex = sourceBuilder.questions.indexOf(target)

      move(sourceBuilder.questions, fromIndex, toIndex + direction)
      this.legendEditor.moveQuestion$.next(source)
      return
    }

    // Need to reassign parent builder on the source, as well as remove it from it's previous builder.
    sourceBuilder.questions.remove(source)
    targetBuilder.questions.push(source)
    source.parentQuestionsBuilder = targetBuilder

    // Now we can move it to where it needs to be
    const fromIdx = targetBuilder.questions.indexOf(source)
    const toIdx = targetBuilder.questions.indexOf(target) + direction
    move(targetBuilder.questions, fromIdx, toIdx)
    this.legendEditor.moveQuestion$.next(source)
  }

  /**
   * Removes the question.
   *
   * @param {QuestionEditor} question
   */
  @action.bound
  remove(question) {
    if (this.inspector.question === question) {
      this.inspector.deactivate()
    }

    this.questions.remove(question)
    this.legendEditor.removeQuestion$.next(question)
  }

  /**
   * Removes the selected question
   */
  @action.bound
  removeSelected() {
    if (this.selectedQuestion) {
      this.selectedQuestion.remove()
    }
  }

  /**
   * Generates the next ID.
   */
  @action
  nextId(prefix = 'QUESTION_') {
    const rootBuilder = this.rootBuilder
    const allQuestions = this.allQuestions
    const upped = prefix.toUpperCase()
    let count = rootBuilder.idCounters.get(upped) || 1
    do {
      const nextId = `${prefix}${count++}`
      if (!allQuestions.find((q) => q.id === nextId)) {
        rootBuilder.idCounters.set(upped, count)
        return nextId
      }
    } while (true)
  }

  /**
   * Duplicates the question.
   */
  @action
  duplicate(question) {
    const idx = this.questions.indexOf(question)
    const json = question.toJSON()
    const copy = QuestionEditor.fromJS(json, this._questionOpts())

    this.questions.push(copy)

    // Create new IDs for sub-questions
    this.generateNewIds(copy, question.id)

    if (idx === -1) {
      return
    }

    move(this.questions, this.questions.indexOf(copy), idx + 1)
    this.edit(copy)
  }

  /**
   * Generates new IDs for duplicated questions.
   */
  @action
  generateNewIds(question) {
    const setNewId = (q) => {
      const lastKnown = q.lastKnownId
      q.id = this.nextId(lastKnown ? lastKnown + '_' : 'QUESTION_')
      // Reset the last known so if we duplicate it again, we increment the ID count.
      q.lastKnownId = lastKnown || q.id
    }
    setNewId(question)
    if (question.getAllSubQuestions) {
      question.getAllSubQuestions().forEach((q) => setNewId(q))
    }
  }

  /**
   * Hydrates the builder with questions.
   * @param {Legend} legend
   */
  hydrate(questions) {
    this.idCounters = new Map()
    this.questions = questions.map((q) =>
      QuestionEditor.fromJS(q, this._questionOpts())
    )
  }

  /**
   * Returns a plain JS object representing the builder state.
   */
  toJSON() {
    return this.questions.map((x) => x.toJSON())
  }

  /**
   * Delivers errors to each question.
   *
   * @param {*} descriptor
   */
  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'questions',
      'There are invalid questions.'
    )
    receiveErrors(this.questions, descriptor.inner)
  }

  /**
   * Options for questions.
   */
  _questionOpts() {
    return {
      legendEditor: this.legendEditor,
      parentQuestionsBuilder: this,
      scope: this.scope,
      stripUndefined: true,
    }
  }
}

/**
 * Given an ObservableArray, safely moves the item at the given index.
 */
function move(source, fromIndex, toIndex) {
  toIndex = Math.max(0, Math.min(toIndex, source.length - 1))

  _move(source, fromIndex, toIndex)
  return source
}

/**
 * Recursively flattens the specified question list.
 *
 * @param {*} questions
 */
function flattenQuestions(questions) {
  const mapped = questions.map((q) => {
    if (q.getAllSubQuestions) {
      return [q, ...(q.getAllSubQuestions() || [])]
    }
    return q
  })
  return flattenDeep(mapped)
}
