import { validatable } from '../mixins/validation'
import { observable, computed } from 'mobx'
import { snapshotable } from '../data/serialization'
import { asNumber } from 'utils/numberUtil'
import { Types } from '../data/Type'
import RynoEditor from '../ryno-editor/RynoEditor'
import { func } from 'validx'

/**
 * Payout config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class PayoutConfigEditor {
  /**
   * If using dynamic payout, this is the expression to compute it.
   */
  @observable
  expr

  /**
   * If using legacy payout, this is the amount rate (provider cut factor)
   */
  @observable
  amountRate = 0

  /**
   * Whether we are using dynamic payout.
   */
  @observable
  useDynamicPayout = false

  rules = {
    expr: [
      func(
        (ctx) => (ctx.obj.useDynamicPayout ? ctx.value.analyze() : true),
        'There are formula errors.'
      ),
    ],
    amountRate: [
      ({ value, obj }) => {
        if (obj.useDynamicPayout) {
          return true
        }
        const v = asNumber(value)
        if (v >= 0 && v <= 1) {
          return true
        }
        return 'Provider cut must be between 0 and 1.'
      },
    ],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.expr = new RynoEditor({
      required: true,
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => Types.number,
    })
  }

  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  @computed
  get symbolTable() {
    return this.legendEditor.getSymbolTableFor(this)
  }

  hydrate(payoutConfig) {
    if (!payoutConfig) {
      this.expr.setValue('')
      this.useDynamicPayout = false
      this.amountRate = 0
      return
    }

    const { payout_calculation = {} } = payoutConfig
    if (payout_calculation.type === 'Dynamic') {
      this.useDynamicPayout = true
      this.expr.setValue(payout_calculation.expr, true)
    } else {
      this.useDynamicPayout = false
      this.amountRate = asNumber(payout_calculation.amount_rate)
    }
  }

  toJSON() {
    return {
      payout_calculation: this.useDynamicPayout
        ? {
            type: 'Dynamic',
            expr: this.expr.toJSON(),
          }
        : {
            type: 'Legacy',
            amount_rate: asNumber(this.amountRate),
          },
    }
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    this.validation.addErrors({
      amountRate: inner.payout_calculation?.inner?.amount_rate?.errors,
      expr: inner.payout_calculation?.inner?.expr?.errors,
    })
  }
}
