import isFunction from 'lodash/isFunction'

/**
 * Wraps the given function in an error handler.
 * Does not call it immediately.
 *
 * @param  {Function} fn
 * The function to invoke.
 *
 * @param  {errFn} errFn
 * Optional function to invoke with the error if an error is thrown.
 *
 * @param  {Boolean} swallow
 * If true, will not re-throw errors. Default is false.
 *
 * @param {FlashMessage} msg
 * If specified, uses this message instance to indicate failure, rather than
 * creating a new one.
 *
 * @return {Function}
 */
export const makeErrorHandler = ({ fn, errFn, swallow, msg, rootStore }) => {
  return async function enhancedWithErrorHandler() {
    try {
      return await fn.apply(this, arguments)
    } catch (err) {
      msg = msg || (rootStore || this.rootStore).flashMessageStore.create({})
      msg
        .failed({
          message: err.message,
        })
        .autoDismiss(5000)
      if (errFn) {
        errFn(err)
      }
      if (!swallow) {
        throw err
      }
    }
  }
}

/**
 * Creates a handler and invokes it immediately.
 *
 * @param  {object} opts
 * @return {*}
 */
export const handleErrors = (opts) => {
  return makeErrorHandler(opts)()
}

/**
 * Creates a functionm that handles errors (sync and async) thrown by fn. Always async.
 * If specified, invokes errFn with the error as the first parameter.
 *
 * Can also be used as a decorator.
 *
 * @param {Function|object} fn|target
 * @param {Function|string} errFn|name
 * @param {Boolean|object} swallow|descriptor
 * @param {FlashMessage} msg
 * If specified, uses this message instance to indicate failure, rather than
 * creating a new one.
 */
export default async function universalDecorator(fn, errFn, swallow, msg) {
  // Regular function invocation
  if (isFunction(fn)) {
    return makeErrorHandler({ fn, errFn, swallow, msg })()
  }

  // Decorator
  const descriptor = swallow
  // errFn = name in this case
  Object.defineProperty(fn, errFn, {
    value: makeErrorHandler({ fn: descriptor.value }),
  })
  return descriptor
}
