import {
  asNumber,
  toPrecision,
} from '@taxfyle/web-commons/lib/utils/numberUtil'
import { action, computed, observable } from 'mobx'
import { task } from 'mobx-task'
import {
  extractMessageFromError,
  populateValidationContextFromError,
} from 'utils/errorUtil'
import { stringLength, vif } from 'utils/validx-validators'
import { pattern, required, validationContext, func } from 'validx'

import CustomMenuLinksBuilder from './CustomMenuLinksBuilder'
import SignupRulesBuilder from './SignupRulesBuilder'
import MemberMetadataFieldsBuilder from './MemberMetadataFieldsBuilder'
import TermsOfUseConfigViewModel from './TermsOfUseConfigViewModel'
import JobProgressionConfigViewModel from './JobProgressionConfigViewModel'
import { TranslationFieldsEditor } from './TranslationFieldsBuilder'
import Papa from 'papaparse'
import CombinedPricingConfigViewModel from './CombinedPricingConfigViewModel'
import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'
import StaffingConfigViewModel from './Config/Staffing/StaffingConfigViewModel'
import KarbonConfigViewModel from './KarbonConfigViewModel'
import RoomsConfigViewModel from './Config/Rooms/RoomsConfigViewModel'
import ScopeWorkConfigViewModel from './ScopeWorkConfigViewModel'

export const WORKSPACE_CLASSIFICATIONS = [
  'B2B',
  'B2C',
  'B2B2C',
  'B2E',
  'DEMO',
  'FIRM',
]

export default class WorkspaceEditorState {
  @observable
  id = ''

  @observable
  name = ''

  @observable
  slug = ''

  @observable
  ownerUserId

  @observable
  ownerGivenName = ''

  @observable
  ownerFamilyName = ''

  @observable
  ownerEmail = ''

  @observable
  isShowingCloneDialog = false

  @observable
  isShowingSecretsDialog = false

  @observable
  cloneName = ''

  @observable
  cloneSlug = ''

  @observable
  isShowingDeleteDialog = false

  @observable
  deleteText = ''

  @observable
  forceArchive = false

  @observable
  domains = []

  @observable
  classification = 'B2B'

  @observable
  canBackToClassicRoles = false

  @observable
  membersWithoutClassicRoles = 0

  validation = validationContext(this)

  @computed
  get canEnableExternalCustomers() {
    return this.classification === 'B2B2C' || this.classification === 'DEMO'
  }

  constructor({
    rootStore,
    onSaved,
    onSecretSaved,
    onSecretDeleted,
    newWorkspace,
  }) {
    this.rootStore = rootStore

    this.branding = new BrandingEditor()
    this.config = new ConfigEditor()
    this.customMetadataFields = new CustomMetadataFieldsEditor()
    this.translationFields = new TranslationFieldsEditor(
      rootStore.translationStore,
      rootStore.flashMessageStore
    )
    this.secret = new SecretEditor()
    this.onSaved = onSaved
    this.handleSecretSaved = async (result) => {
      await this.rootStore.workspaceSecretStore.activate(this.id)
      this.closeSecretsDialog()

      onSecretSaved && onSecretSaved(result)
    }
    this.handleSecretDeleted = async (result) => {
      await this.rootStore.workspaceSecretStore.activate(this.id)

      onSecretDeleted && onSecretDeleted(result)
    }
    this.newWorkspace = newWorkspace
    this.onChangeFile = this.onChangeFile.bind(this)
  }

  @computed
  get validationRules() {
    return {
      name: [required()],
      slug: [required(), stringLength(2)],
      ...(this.isNew && {
        ownerUserId: [required()],
        ownerGivenName: [required()],
        ownerFamilyName: [required()],
        ownerEmail: [required(), pattern('email')],
      }),
      branding: [
        ({ value }) =>
          value.validation.validate().isValid || 'Branding is invalid.',
      ],
      config: [
        ({ value }) =>
          value.validation.validate().isValid || 'Config is invalid.',
      ],
      customMetadataFields: [
        ({ value }) =>
          value.validation.validate().isValid || 'Custom Metadata is invalid.',
      ],
      classification: [
        ({ value }) =>
          WORKSPACE_CLASSIFICATIONS.includes(value) ||
          'Classification is invalid',
      ],
    }
  }

  @computed
  get isPlatformAdmin() {
    return this.rootStore.sessionStore.user.platformAdmin
  }

  @action.bound
  validate() {
    this.branding.validation.reset()
    this.config.validation.reset()
    this.customMetadataFields.validation.reset()
    return this.validation.reset().validate(this.validationRules).isValid
  }

  @computed
  get userConfirmedDeletion() {
    return this.deleteText === 'DELETE'
  }

  @computed
  get viewTitle() {
    return this.isNew ? 'New Workspace' : this.name
  }

  @computed
  get isNew() {
    return !this.id
  }

  @computed
  get disablePlatformRolesSwitch() {
    return this.config.platformRolesEnabled && !this.canBackToClassicRoles
  }

  async activate(workspace = {}) {
    this.id = workspace.id || ''
    this.name = workspace.name || ''
    this.slug = workspace.slug || ''
    this.domains = workspace.domains || []
    this.classification = workspace.classification || 'B2B'

    if (workspace.id) {
      await this.rootStore.legendStore.fetchLegendsForWorkspace(workspace.id)
      await this.rootStore.inventoryItemStore.fetchWorkspaceInventoryItems(
        workspace.id
      )
    }

    this.branding.activate(workspace.branding || {})
    this.config.activate(
      workspace.config || {},
      this.rootStore.legendStore,
      this.rootStore.roleStore,
      this.rootStore.inventoryItemStore,
      this.rootStore.memberStore,
      this.rootStore.teamMemberStore,
      this.rootStore.sessionStore.user,
      this.id
    )
    this.customMetadataFields.activate(
      workspace.custom_metadata_fields || {},
      this.id,
      this.slug
    )
    this.translationFields.activate(this.id)

    if (!workspace.id) {
      this.ownerUserId = this.rootStore.sessionStore.user.id
      this.ownerGivenName = this.rootStore.sessionStore.user.givenName
      this.ownerFamilyName = this.rootStore.sessionStore.user.familyName
      this.ownerEmail = this.rootStore.sessionStore.user.email
    }

    await this.fetchCanBackToClassicRoles()
  }

  @action.bound
  async fetchCanBackToClassicRoles() {
    const usePlatformRoles = getFeatureToggleClient().variation(
      'IamAPI.UsePlatformRoles',
      false
    )

    if (usePlatformRoles && this.id) {
      const result = await this.rootStore.roleStore.canBackToClassicRoles(
        this.id
      )

      this.canBackToClassicRoles = result.can_back_to_classic_roles
      this.membersWithoutClassicRoles =
        result.members_without_classic_roles_count
    }
  }

  @task.resolved
  async waitForArchivalCompletion(workspaceId) {
    const msg = this.rootStore.flashMessageStore.create({
      inProgress: true,
      message: 'Attempting to archive workspace.  This may take a while.',
    })
    for (let i = 1; i <= 10; i++) {
      await new Promise((resolve) => setTimeout(resolve, i * 1000))
      const status = await this.rootStore.workspaceStore.getArchivalStatus(
        workspaceId
      )
      if (status === 'ACTIVE') {
        msg.failed('This workspace could not be archived.')
        return
      }
      if (status === 'ARCHIVED') {
        msg.done('Workspace was successfully archived!').autoDismiss()
        return
      }
    }
    msg.warn('Your archival request is taking a bit long')
    throw new Error('Your archival request is taking a bit long')
  }

  @task.resolved
  async cloneWorkspace() {
    const msg = this.rootStore.flashMessageStore.create({
      inProgress: true,
      message: 'Cloning ...',
    })
    try {
      if (this.cloneName.length === 0 || this.cloneSlug.length === 0) {
        return
      }

      const workspace = await this.rootStore.workspaceStore.cloneWorkspace(
        this.id,
        {
          name: this.cloneName,
          slug: this.cloneSlug,
        }
      )

      msg.done('Workspace was successfully cloned!').autoDismiss()
      this.rootStore.workspaceManagementStore.select(workspace)
    } catch (e) {
      msg.failed(extractMessageFromError(e))
    } finally {
      this.closeCloneDialog()
    }
  }

  @task.resolved
  async delete() {
    try {
      if (!this.userConfirmedDeletion) {
        return
      }
      const id = this.id
      await this.rootStore.workspaceStore.delete(this.id, {
        force_archive: this.forceArchive,
      })
      await this.waitForArchivalCompletion(id)
    } finally {
      this.closeDeleteDialog()
    }
  }

  @task.resolved
  save() {
    if (!this.validate()) {
      return false
    }

    const workspaceStore = this.rootStore.workspaceStore
    const method = this.isNew
      ? (data) => workspaceStore.create(data)
      : (data) => workspaceStore.update(this.id, data)

    const data = this.serialize()
    return method(data)
      .then((result) => this.onSaved && this.onSaved(result))
      .catch(
        action((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            populateValidationContextFromError(this.validation, err)
          } else {
            throw err
          }
        })
      )
  }

  @task.resolved
  saveSecret() {
    if (this.secret.new) {
      return this.createSecret()
    }

    return this.updateSecret()
  }

  createSecret() {
    return this.rootStore.workspaceSecretStore
      .createSecret(this.id, this.secret.serialize())
      .then(this.handleSecretSaved)
      .catch(
        action((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            populateValidationContextFromError(this.validation, err)
          } else {
            throw err
          }
        })
      )
  }

  updateSecret() {
    return this.rootStore.workspaceSecretStore
      .updateSecret(this.secret.serialize())
      .then(this.handleSecretSaved)
      .catch(
        action((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            populateValidationContextFromError(this.validation, err)
          } else {
            throw err
          }
        })
      )
  }

  @task.resolved
  deleteSecret(key) {
    return this.rootStore.workspaceSecretStore
      .deleteSecret(key)
      .then(this.handleSecretDeleted)
      .catch(
        action((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            populateValidationContextFromError(this.validation, err)
          } else {
            throw err
          }
        })
      )
  }

  serialize() {
    return {
      name: this.name,
      slug: this.slug,
      ...(this.isNew && {
        owner_user_id: this.ownerUserId,
        owner_given_name: this.ownerGivenName,
        owner_family_name: this.ownerFamilyName,
        owner_email: this.ownerEmail,
      }),
      branding: this.branding.serialize(),
      config: this.config.serialize(),
      custom_metadata_fields: this.customMetadataFields.serialize(),
      classification: this.classification,
    }
  }

  @action.bound
  closeDeleteDialog() {
    this.isShowingDeleteDialog = false
    this.deleteText = ''
  }

  @action.bound
  openDeleteDialog() {
    this.isShowingDeleteDialog = true
    this.deleteText = ''
  }

  @action.bound
  closeCloneDialog() {
    this.isShowingCloneDialog = false
    this.cloneName = ''
    this.cloneSlug = ''
  }

  @action.bound
  openCloneDialog() {
    this.isShowingCloneDialog = true
    this.cloneName = ''
    this.cloneSlug = ''
  }

  @action.bound
  closeSecretsDialog() {
    this.isShowingSecretsDialog = false
    this.secret.clear()
  }

  @action.bound
  openSecretDialog(key, title, newKey = false) {
    this.isShowingSecretsDialog = true
    this.secret.title = title
    this.secret.key = key
    this.secret.new = newKey
  }

  @action.bound
  async onChangeFile(files) {
    const csv = await new Promise((resolve, reject) => {
      Papa.parse(files[0], {
        complete: (results) => {
          resolve(results.data)
        },
        header: true,
      })
    })

    const done = []
    const failed = []

    const formatMessage = () =>
      `Creating users, ${done.length} of ${csv.length}...`

    const msg = this.rootStore.flashMessageStore.create({
      inProgress: true,
      message: formatMessage(),
    })

    const roles = await this.rootStore.roleStore.api.roles.find({
      limit: 9999,
      workspace_id: this.id,
    })

    const skills = await this.rootStore.skillStore.api.skills.find({
      limit: 9999,
      workspace_id: this.id,
    })

    const processCSVRow = async (row) => {
      if (!row.GUID) {
        return { name: row.Email, error: 'No GUID given.' }
      }
      const role = roles.find((x) => x.name === row.Role)
      if (!role) {
        return { name: row.Email, error: 'Role not found.' }
      }

      try {
        await this.rootStore.memberStore.create({
          workspace_id: this.id,
          user_id: row.GUID.trim(),
          email: row.Email.trim(),
          given_name: row.FirstName.trim(),
          family_name: row.LastName.trim(),
          role_id: role.id,
        })
      } catch (err) {
        return { name: row.Email, error: extractMessageFromError(err) }
      }

      const skillNames = Object.keys(row)
        .map((x) => (x.startsWith('Skill') ? row[x] : null))
        .filter(Boolean)

      for (const name of skillNames) {
        const skill = skills.data.find((x) => x.name === name)
        if (!skill) {
          return { e: row, success: true }
        }

        try {
          await this.rootStore.memberStore.addSkill(
            {
              workspace_id: this.id,
              user_id: row.GUID.trim(),
            },
            skill.id
          )
        } catch (err) {
          return { name: row.Email, error: extractMessageFromError(err) }
        }
      }

      msg.set({ message: formatMessage() })
      return {
        row,
        success: true,
      }
    }

    for (const row of csv) {
      const result = await processCSVRow(row)

      if (result.error) {
        failed.push(result)
      } else {
        done.push(result)
      }
    }

    const doneCount = done.length

    if (failed.length === 0) {
      msg
        .done(
          doneCount === 1 ? 'Users imported!' : `Imported ${doneCount} Users!`
        )
        .autoDismiss()
      return
    }

    const errorMessages = failed
      .map((f) => `${f.name} - ${extractMessageFromError(f.error)}`)
      .join('\n')

    msg.warn(
      doneCount > 0
        ? `Imported ${doneCount} users, but ${failed.length} didn't go through. ${errorMessages}`
        : `None of the users were imported. ${errorMessages}`
    )
  }
}

// Quick util to only run a rule if a value is set.
const ifSet = (rule) => (ctx) => (ctx.value ? rule(ctx) : true)

class BrandingEditor {
  @observable
  primaryLogo = ''

  @observable
  primaryLogoWhite = ''

  @observable
  icon = ''

  @observable
  iconWhite = ''

  @observable
  iconSystemRobot = ''

  @observable
  primaryColor = ''

  @observable
  corporateEnabled = false

  @observable
  landingPageImage = ''

  validation = validationContext(this, {
    primaryLogo: [ifSet(pattern('url'))],
    primaryLogoWhite: [ifSet(pattern('url'))],
    icon: [ifSet(pattern('url'))],
    iconWhite: [ifSet(pattern('url'))],
    iconSystemRobot: [ifSet(pattern('url'))],
    landingPageImage: [ifSet(pattern('url'))],
    primaryColor: [
      ifSet(
        pattern(
          /^#[0-9A-Fa-f]{6}$/,
          'Primary Color should be in hex format. E.g #8ADA55'
        )
      ),
    ],
  })

  activate(data = {}) {
    const logos = data.logos || {}
    const colors = data.colors || {}
    const corporate = data.corporate || {}
    const landingPage = data.landing_page || {}
    const systemRobot = data.system_robot || {}

    this.icon = logos.icon
    this.iconWhite = logos.icon_white
    this.iconSystemRobot = systemRobot.image
    this.primaryLogo = logos.primary
    this.primaryLogoWhite = logos.primary_white

    this.primaryColor = colors.primary

    this.corporateEnabled = corporate.enabled || false
    this.landingPageImage = landingPage.image
    this.simplifiedNavBarImage = logos.primary_white

    if (Object.keys(data).length === 0) {
      this.primaryColor = '#2395F3'
    }
  }

  @action.bound
  useDefaultPrimary() {
    this.primaryColor = '#2395F3'
  }

  serialize() {
    return {
      logos: {
        primary: this.primaryLogo,
        primary_white: this.primaryLogoWhite,
        icon: this.icon,
        icon_white: this.iconWhite,
      },
      colors: {
        primary: this.primaryColor,
      },
      corporate: {
        enabled: this.corporateEnabled,
      },
      landing_page: {
        image: this.landingPageImage,
      },
      system_robot: {
        image: this.iconSystemRobot,
      },
    }
  }
}

class SecretEditor {
  @observable
  title = ''

  @observable
  key = ''

  @observable
  value = ''

  @observable
  new = false

  serialize() {
    return {
      key: this.key,
      value: this.value,
    }
  }

  @action
  clear() {
    this.value = ''
    this.key = ''
    this.new = false
    this.title = ''
  }
}

class ConfigEditor {
  @observable
  impersonationEnabled = false

  @observable
  intercomEnabled = false

  @observable
  intercomAppId = ''

  @observable
  intercomVerificationSecretWeb = ''

  @observable
  intercomVerificationSecretIOS = ''

  @observable
  intercomVerificationSecretAndroid = ''

  @observable
  zendeskEnabled = false

  @observable
  zendeskAppId = ''

  @observable
  zendeskKey = ''

  @observable
  zendeskClientId = ''

  @observable
  zendeskDepartment = ''

  @observable
  documentsAllowLinking = true

  @observable
  documentUploadConfirmationEnabled = false

  @observable
  documentUploadConfirmationText = ''

  @observable
  documentEditPermission = ''

  @observable
  combinedPricingConfig = new CombinedPricingConfigViewModel()

  @observable
  karbonConfig = new KarbonConfigViewModel({})

  @observable
  sureprepEnabled = false

  @observable
  sureprepPrepStatusString = ''

  @observable
  sureprepCompleteStatusString = ''

  @observable
  sureprepSecretKey = ''

  @observable
  sureprepUsername = ''

  @observable
  sureprepPassword = ''

  @observable
  sureprepApiKey = ''

  @observable
  sureprepJobHolderUserId = ''

  @observable
  sureprepLegendId = ''

  @observable
  welcomeMessageEnabled = false

  @observable
  welcomeMessageText = ''

  @observable
  termsOfUseConfig = new TermsOfUseConfigViewModel()

  @observable
  ppEnabled = false

  @observable
  ppUrl = ''

  @observable
  billingEnabled = false

  @observable
  payoutsEnabled = false

  @observable
  insightsEnabled = false

  @observable
  couponsEnabled = false

  @observable
  couponsReferralEnabled = true

  @observable
  couponsReferralAmount = 0

  @observable
  couponsAbandonedDraftJobEnabled = false

  @observable
  teamsCreditEnabled = false

  @observable
  teamsCreditLimit = 0

  @observable
  maxConcurrentProviderJobs = 0

  @observable
  providerCompletedJobAccess = ''

  @observable
  anonymousOpportunities = false

  @observable
  submitterChampion = false

  @observable
  jobMuteTeamAdmins = true

  @observable
  jobHideProviderTasks = false

  @observable
  jobHideClientTasks = false

  @observable
  routingMode

  @observable
  applicationPeriodDuration

  @observable
  defaultOfferExpirationAction

  @observable
  defaultOfferDuration

  @observable
  requestedProviderOfferExpirationAction

  @observable
  requestedProviderOfferDuration

  @observable
  jobProgressionFlow = false

  @observable
  jobProgressionConfig = new JobProgressionConfigViewModel()

  @observable
  simplifiedNavBarEnabled = false

  @observable
  simplifiedNavBarImage = ''

  @observable
  simplifiedNavBarTargetUrl = ''

  @observable
  tabConfigEnabled = false

  @observable
  tabConfigDetails = true

  @observable
  tabConfigEvents = true

  @observable
  tabConfigMembers = true

  @observable
  conversationMuteButtonEnabled = true

  @observable
  customChatbotImageEnabled = false

  @observable
  customChatbotImageUrl = ''

  @observable
  signupRules = new SignupRulesBuilder()

  @observable
  customMenuLinks = new CustomMenuLinksBuilder()

  @observable
  appModalDisabled = false

  @observable
  deepLink = ''

  @observable
  allowDeepLinking = false

  @observable
  clientEmailNotificationEnabled = true

  @observable
  allowSnoozing = false

  @observable
  supportEmail = ''

  @observable
  customEmailDomains = false

  @observable
  proPortalDomain = ''

  @observable
  clientPortalDomain = ''

  @observable
  notificationJobClosed = true

  @observable
  notificationJobReopened = true

  @observable
  notificationJobUpdated = true

  @observable
  notificationMilestoneCompleted = true

  @observable
  notificationNewJobAvailable = true

  @observable
  notificationCouponAddedToJob = true

  @observable
  notificationInvitationTeam = true

  @observable
  notificationUnreadMessage = true

  @observable
  notificationUnreadDocument = true

  @observable
  notificationPreferencesJobUpdatedEmail = true

  @observable
  notificationPreferencesJobUpdatedPush = true

  @observable
  notificationPreferencesUnreadDocumentEmail = true

  @observable
  notificationPreferencesUnreadDocumentPush = true

  @observable
  notificationPreferencesJobReopenedEmail = true

  @observable
  notificationPreferencesJobReopenedPush = true

  @observable
  notificationPreferencesJobClosedEmail = true

  @observable
  notificationPreferencesJobClosedPush = true

  @observable
  notificationPreferencesUnreadMessageEmail = true

  @observable
  notificationPreferencesUnreadMessagePush = true

  @observable
  notificationPreferencesMilestoneCompletedEmail = false

  @observable
  notificationPreferencesMilestoneCompletedPush = false

  @observable
  notificationPreferencesNewMessageEmail = false

  @observable
  notificationPreferencesNewMessagePush = true

  @observable
  notificationPreferencesNewJobAvailableEmail = true

  @observable
  notificationPreferencesNewJobAvailablePush = true

  @observable
  legendsBuilderEnabled = false

  @observable
  calendarIntegrationEnabled = true

  @observable
  analyticsService = ''

  @observable
  analyticsEnabled = false

  @observable
  analyticsDashboardId = ''

  @observable
  googleTagManagerEnabled = false

  @observable
  googleTagManagerContainerId = ''

  @observable
  customConnection = ''

  @observable
  passwordConnection = false

  @observable
  facebookConnection = false

  @observable
  appleConnection = false

  @observable
  googleConnection = false

  @observable
  rocketLawyerConnection = false

  @observable
  linkedinConnection = false

  @observable
  loginRedirectEnabled = false

  @observable
  loginRedirectUrl = ''

  @observable
  loginRedirectSigningSecret = ''

  @observable
  logoutUrl = ''

  @observable
  federatedLogout = false

  @observable
  externalProfiles = false

  @observable
  passwordlessConnection = false

  @observable
  lockTitle = ''

  @observable
  lockDescription = ''

  @observable
  messageBankEnabled = false

  @observable
  publicApiConnection = ''

  @observable
  publicApiSignedUrlEnabled = false

  @observable
  externalCustomersEnabled = false

  @observable
  chiliPiperEnabledAtCheckout = false

  @observable
  chiliPiperCheckoutRouterId = ''

  @observable
  ChiliPiperCheckoutFormDescriptor = ''

  @computed
  get allowExternalCustomersEditing() {
    return !this.externalCustomersEnabled
  }

  /**
   * For now we only support signed url. So we we use a computed
   * since the UI only exposes a toggle for enabling above.
   * This can be changed to a proper observable later.
   */
  @computed
  get externalCustomersAuthMethod() {
    if (this.externalCustomersEnabled) {
      return 'signed-url'
    }

    return ''
  }

  @observable
  signupConnections = []

  @observable
  platformRolesEnabled = false

  @observable
  memberPermissionsReadonly = false

  @observable
  informationGatheringEnabled = false

  @observable
  documentSignatureEnabled = false

  @observable
  workpaperEnabled = false

  @observable
  contractEnabled = false

  @observable
  contractAmount = 0

  @observable
  contractStartDate = null

  @observable
  contractEndDate = null

  @observable
  contractVmFeeAmountPerUnit = 0

  @observable
  contractVmFeeQuantity = 0

  @observable
  contractSetupFeeAmount = 0

  @observable
  staffingConfig = new StaffingConfigViewModel({})

  @observable
  roomsConfig = new RoomsConfigViewModel({})

  @observable
  scopeWorkConfig = new ScopeWorkConfigViewModel()

  @computed
  get getSignupConnectionsForPublicApi() {
    const connections = [
      ...this.signupConnections.map((i) => i.type),
      ...this.customConnection.split(',').filter((i) => Boolean(i)),
    ].sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    })
    // Remove duplicates
    const uniqueConnections = [...new Set(connections)]
    return [...[['', 'Default']], ...uniqueConnections.map((i) => [i])]
  }

  validation = validationContext(this, {
    intercomAppId: [vif(() => this.intercomEnabled, [required()])],
    intercomVerificationSecretWeb: [
      vif(() => this.intercomEnabled, [required()]),
    ],
    intercomVerificationSecretIOS: [
      vif(() => this.intercomEnabled, [required()]),
    ],
    intercomVerificationSecretAndroid: [
      vif(() => this.intercomEnabled, [required()]),
    ],
    zendeskAppId: [vif(() => this.zendeskEnabled, [required()])],
    zendeskKey: [vif(() => this.zendeskEnabled, [required()])],
    zendeskClientId: [vif(() => this.zendeskEnabled, [required()])],
    sureprepJobHolderUserId: [vif(() => this.sureprepEnabled, [required()])],
    sureprepLegendId: [vif(() => this.sureprepEnabled, [required()])],
    sureprepSecretKey: [vif(() => this.sureprepEnabled, [required()])],
    sureprepUsername: [vif(() => this.sureprepEnabled, [required()])],
    sureprepPassword: [vif(() => this.sureprepEnabled, [required()])],
    sureprepApiKey: [vif(() => this.sureprepEnabled, [required()])],
    sureprepPrepStatusString: [vif(() => this.sureprepEnabled, [required()])],
    termsOfUseConfig: [
      ({ value }) =>
        value.validation.reset().validate().isValid ||
        'Terms of Use configuration is invalid',
    ],
    karbonConfig: [
      ({ value }) =>
        value.validation.reset().validate().isValid ||
        'Karbon configuration is invalid',
    ],
    ppUrl: [
      vif(() => this.ppEnabled, [
        required(),
        pattern('url', 'Not a valid URL.'),
      ]),
    ],
    customMenuLinks: [
      ({ value }) =>
        value.validation.reset().validate().isValid ||
        'Custom links are invalid.',
    ],
    signupRules: [
      ({ value }) =>
        value.validation.reset().validate().isValid ||
        'Signup rules are invalid.',
    ],
    couponsReferralAmount: [
      vif(() => this.couponsReferralEnabled, [required()]),
    ],
    maxConcurrentProviderJobs: [pattern(/\d*/, 'Must be a number')],
    documentUploadConfirmationText: [
      vif(() => this.documentUploadConfirmationEnabled, [required()]),
    ],
    welcomeMessageText: [vif(() => this.welcomeMessageEnabled, [required()])],
    analyticsDashboardId: [vif(() => this.analyticsEnabled, [required()])],
    googleTagManagerContainerId: [
      vif(() => this.googleTagManagerEnabled, [
        required(),
        pattern(
          /^GTM-[a-z0-9]+$/i,
          'The container ID should be of the form GTM-XXXXXX'
        ),
      ]),
    ],
    deepLink: [
      vif(() => this.allowDeepLinking, [
        required(),
        pattern(/^(http|https):\/\/[^ "]+$/, 'URL is invalid'),
      ]),
    ],
    simplifiedNavBarTargetUrl: [
      vif(() => this.simplifiedNavBarEnabled, [
        required(),
        pattern('url', 'Not a valid URL.'),
      ]),
    ],
    simplifiedNavBarImage: [
      vif(() => this.simplifiedNavBarEnabled, [
        required(),
        pattern('url', 'Not a valid URL.'),
      ]),
    ],
    contractAmount: [
      vif(() => this.contractEnabled, [
        required(),
        func((opts) => opts.value > 0, 'Amount should be higher than 0'),
      ]),
    ],
    contractEndDate: [vif(() => this.contractEnabled, [required()])],
    contractVmFeeAmountPerUnit: [
      vif(() => this.contractEnabled, [
        required(),
        func((opts) => opts.value >= 0, 'Vm fee amount should be 0 or higher'),
      ]),
    ],
    contractVmFeeQuantity: [
      vif(() => this.contractEnabled, [
        required(),
        func(
          (opts) => opts.value >= 0,
          'Vm fee quantity should be 0 or higher'
        ),
      ]),
    ],
    contractSetupFeeAmount: [
      vif(() => this.contractEnabled, [
        required(),
        func(
          (opts) => opts.value >= 0,
          'Setup fee amount should be 0 or higher'
        ),
      ]),
    ],
    chiliPiperCheckoutRouterId: [
      vif(() => this.chiliPiperEnabledAtCheckout, [required()]),
    ],
    ChiliPiperCheckoutFormDescriptor: [
      vif(() => this.chiliPiperEnabledAtCheckout, [required()]),
    ],
  })

  @action.bound
  setContractStartDate(date) {
    this.contractStartDate = date
  }

  @action.bound
  setContractEndDate(date) {
    this.contractEndDate = date
  }

  activateIntercomConfig(data) {
    const intercom = data.intercom || {}
    this.intercomEnabled = intercom.enabled || false
    this.intercomAppId = intercom.app_id || ''
    this.intercomVerificationSecretWeb = intercom.verification_secret_web || ''
    this.intercomVerificationSecretIOS = intercom.verification_secret_ios || ''
    this.intercomVerificationSecretAndroid =
      intercom.verification_secret_android || ''
  }

  activateImpersonationConfig(data) {
    const impersonation = data.impersonation || {}
    this.impersonationEnabled = impersonation.enabled || false
  }

  activateZendeskConfig(data) {
    const zendesk = data.zendesk || {}
    this.zendeskEnabled = zendesk.enabled || false
    this.zendeskAppId = zendesk.app_id || ''
    this.zendeskClientId = zendesk.client_id || ''
    this.zendeskDepartment = zendesk.department || ''
    this.zendeskKey = zendesk.key || ''
  }

  activateSureprepConfig(data) {
    const sureprep = data.sureprep || {}
    this.sureprepEnabled = sureprep.enabled || false
    this.sureprepJobHolderUserId = sureprep.job_holder_user_id || ''
    this.sureprepLegendId = sureprep.legend_id || ''
    this.sureprepSecretKey = sureprep.sp_secret || ''
    this.sureprepUsername = sureprep.username || ''
    this.sureprepPassword = sureprep.password || ''
    this.sureprepApiKey = sureprep.api_key || ''
    this.sureprepPrepStatusString = sureprep.prep_status_string || ''
    this.sureprepCompleteStatusString = sureprep.complete_status_string || ''
  }

  activateCombinedPricingConfig(data, legendStore, workspaceId) {
    const pricing = data?.combined_pricing || {}
    const workspaceLegends = legendStore.briefLegendsForWorkspace(workspaceId)
    this.combinedPricingConfig.activate(
      pricing?.job_scope_change_requests_enabled,
      pricing?.job_scope_change_notification_slack_channel_ids,
      pricing?.job_scope_require_client_approval,
      pricing?.job_scope_prompt_client_approval,
      pricing?.legends,
      workspaceLegends
    )
  }

  activateKarbonConfig(
    data,
    legendStore,
    memberStore,
    teamMemberStore,
    currentUser,
    workspaceId
  ) {
    const karbon = data.karbon || {}

    this.karbonConfig = new KarbonConfigViewModel({
      legendStore,
      memberStore,
      teamMemberStore,
      currentUser,
      workspaceId,
    })

    this.karbonConfig.activate(
      karbon?.enabled,
      karbon?.job_client_user_public_id,
      karbon?.team_id,
      karbon?.legend_id
    )
  }

  activateTosConfig(data, workspaceId) {
    const tos = data.tos || {}
    this.termsOfUseConfig = new TermsOfUseConfigViewModel(workspaceId)
    this.termsOfUseConfig.activate(
      tos.enabled,
      tos.custom_source,
      tos.source_configs,
      tos.show_on_login,
      tos.hide_on_customer_portal,
      tos.hide_on_wortal
    )
  }

  activatePPConfig(data) {
    const pp = data.pp || {}
    this.ppEnabled = pp.enabled || false
    this.ppUrl = pp.url || ''
  }

  // Stripe info removed and now retrieved from envars
  activateBillingConfig(data) {
    const billing = data.billing || {}
    this.billingEnabled = billing.enabled || false
    this.couponsEnabled = billing['coupons.enabled'] || false
    this.couponsReferralEnabled = billing['coupons.referral.enabled'] || false
    this.couponsReferralAmount = billing['coupons.referral.amount'] || 0
    this.couponsAbandonedDraftJobEnabled =
      billing['coupons.abandoned_draft_job.enabled'] || false
    this.teamsCreditEnabled = billing['teams.credit.enabled'] || false
    this.teamsCreditLimit = billing['teams.credit.limit'] || 0
  }

  activateSignupConfig(data) {
    const signup = data.signup || {}
    const connections = signup.connections || []
    this.signupConnections = connections

    connections.forEach((c) => {
      switch (c.type) {
        case 'passwordless':
          this.passwordlessConnection = c.enabled
          break
        case 'apple':
          this.appleConnection = c.enabled
          break
        case 'google-oauth2':
          this.googleConnection = c.enabled
          break
        case 'rocket-lawyer-sso':
          this.rocketLawyerConnection = c.enabled
          break
        case 'linkedin':
          this.linkedinConnection = c.enabled
          break
        case 'Username-Password-Authentication':
          this.passwordConnection = c.enabled
          break
        case 'facebook':
          this.facebookConnection = c.enabled
          break
        default:
          this.customConnection += c.type + ','
          break
      }
    })
    this.lockTitle = signup.title || ''
    this.lockDescription = signup.description || ''

    this.logoutUrl = signup.logout_url || ''
    this.federatedLogout = signup.federated_logout || false
    this.externalProfiles = signup.external_profiles || false

    this.loginRedirectEnabled = signup['login_redirect.enabled']
    this.loginRedirectSigningSecret = signup['login_redirect.secret']
    this.loginRedirectUrl = signup['login_redirect.url']

    this.signupRules = new SignupRulesBuilder()
    this.signupRules.activate(signup.rules || [])
  }

  activateCustomMenuConfig(data, roleStore, workspaceId) {
    const customMenu = data.custom_menu || {}
    this.customMenuLinks = new CustomMenuLinksBuilder()
    this.customMenuLinks.activate(
      customMenu.items || [],
      roleStore,
      workspaceId
    )
  }

  activateJobConfig(data) {
    const jobConfig = data.job_config || {}
    this.maxConcurrentProviderJobs = jobConfig.max_concurrent_provider_jobs || 0
    this.providerCompletedJobAccess =
      jobConfig.provider_completed_job_access || 'VIEW_ONLY'
    this.anonymousOpportunities = jobConfig.anonymous_opportunities || false
    this.submitterChampion = jobConfig.submitter_champion || false
    this.jobMuteTeamAdmins =
      jobConfig.job_mute_team_admins !== undefined
        ? jobConfig.job_mute_team_admins
        : true
    this.jobHideClientTasks =
      jobConfig.job_hide_client_tasks !== undefined
        ? jobConfig.job_hide_client_tasks
        : false
    this.jobHideProviderTasks =
      jobConfig.job_hide_provider_tasks !== undefined
        ? jobConfig.job_hide_provider_tasks
        : false
    this.jobProgressionFlow =
      jobConfig.job_progression_flow !== undefined
        ? jobConfig.job_progression_flow
        : false
  }

  activateRoutingConfig(data) {
    const routingConfig = data.routing_config ?? {}

    this.routingMode = routingConfig.routing_mode ?? 'FIRST_COME_FIRST_SERVED'
    this.applicationPeriodDuration =
      routingConfig.application_period_duration ?? 600
    this.defaultOfferExpirationAction =
      routingConfig.default_offer_expiration_action ?? 'ACCEPT'
    this.defaultOfferDuration = routingConfig.default_offer_duration ?? 600
    this.requestedProviderOfferExpirationAction =
      routingConfig.requested_provider_offer_expiration_action ?? 'DECLINE'
    this.requestedProviderOfferDuration =
      routingConfig.requested_provider_offer_duration ?? 86400
  }

  activateJobProgressionConfig(data, legendStore, workspaceId) {
    const jobProgressionConfig = data?.job_progression_config || {}
    const taxOrLegacyTypeWorkspaceLegends = legendStore.briefTaxOrLegacyTypeLegendsForWorkspace(
      workspaceId
    )
    this.jobProgressionConfig.activate(
      jobProgressionConfig?.enabled,
      jobProgressionConfig?.legends,
      taxOrLegacyTypeWorkspaceLegends
    )
  }

  activateJobDetailsConfig(data) {
    const jobDetailsConfig = data.job_details_config || {}
    this.simplifiedNavBarEnabled =
      jobDetailsConfig['simplified_nav_bar.enabled'] || false
    this.simplifiedNavBarTargetUrl =
      jobDetailsConfig['simplified_nav_bar.target_url'] || ''
    this.simplifiedNavBarImage =
      jobDetailsConfig['simplified_nav_bar.image'] || ''
    this.tabConfigEnabled = jobDetailsConfig['tab_config.enabled'] || false
    this.tabConfigDetails =
      jobDetailsConfig['tab_config.details'] !== undefined
        ? jobDetailsConfig['tab_config.details']
        : true
    this.tabConfigEvents =
      jobDetailsConfig['tab_config.events'] !== undefined
        ? jobDetailsConfig['tab_config.events']
        : true
    this.tabConfigMembers =
      jobDetailsConfig['tab_config.members'] !== undefined
        ? jobDetailsConfig['tab_config.members']
        : true
    this.conversationMuteButtonEnabled =
      jobDetailsConfig['conversation.mute_button.enabled'] !== undefined
        ? jobDetailsConfig['conversation.mute_button.enabled']
        : true
    this.documentsAllowLinking =
      jobDetailsConfig['documents.allow_linking'] ?? true
  }

  activateDocumentConfig(data) {
    const documentConfig = data.document_config || {}
    const documentUploadConfirmation =
      documentConfig.document_upload_confirmation || {}
    this.documentEditPermission =
      documentConfig.document_edit_permission || 'Everyone'
    this.documentUploadConfirmationEnabled =
      documentUploadConfirmation.enabled || false
    this.documentUploadConfirmationText = documentUploadConfirmation.text || ''
  }

  activateInformationGathering(data) {
    const informationGatheringConfig = data.information_gathering_config || {}

    this.informationGatheringEnabled =
      informationGatheringConfig.enabled || false
  }

  activateDocumentSignatureConfig(data) {
    const documentSignatureConfig = data.document_signature_config || {}

    this.documentSignatureEnabled = documentSignatureConfig.enabled || false
  }

  activateRoomsConfig(data) {
    const roomsConfig = data.rooms_config || {}

    this.roomsConfig = new RoomsConfigViewModel()
    this.roomsConfig.activate(roomsConfig)
  }

  activateStaffingConfig(
    data,
    roleStore,
    memberStore,
    currentUser,
    workspaceId
  ) {
    const staffingConfig = data.staffing_config || {}
    this.staffingConfig = new StaffingConfigViewModel({
      roleStore,
      memberStore,
      currentUser,
      workspaceId,
    })
    this.staffingConfig.activate(staffingConfig)
  }

  activateEmailConfig(data) {
    const emailConfig = data.email_config || {}
    this.supportEmail = emailConfig.support_email || 'support@worklayer.com'
    this.customEmailDomains = emailConfig.custom_email_domains || false
    this.proPortalDomain = emailConfig['custom_email_domains.pro_portal'] || ''
    this.clientPortalDomain =
      emailConfig['custom_email_domains.client_portal'] || ''
  }

  activateMessageConfig(data) {
    const messageConfig = data.message_config || {}
    const welcomeMessage = messageConfig.welcome_message || {}

    this.welcomeMessageEnabled = welcomeMessage.enabled || false
    this.welcomeMessageText = welcomeMessage.text || ''
  }

  activateWorkpaperConfig(data) {
    const workpaperConfig = data.workpaper_config || {}

    this.workpaperEnabled = workpaperConfig.enabled || false
  }

  activatePayoutsConfig(data) {
    const payouts = data.payouts || {}
    this.payoutsEnabled = payouts.enabled || false
  }

  activateInsightsConfig(data) {
    const insightsConfig = data.insights_config || {}
    this.insightsEnabled = insightsConfig.enabled || false
  }

  activateAnalyticsConfig(data) {
    const analyticsConfig = data.analytics_config || {}
    this.analyticsEnabled = analyticsConfig.enabled || false
    this.analyticsDashboardId = analyticsConfig.dashboard_id || ''
    this.analyticsService = analyticsConfig.analytics_service || 'Metabase'
    this.googleTagManagerEnabled =
      analyticsConfig['google_tag_manager.enabled'] || false
    this.googleTagManagerContainerId =
      analyticsConfig['google_tag_manager.container_id'] || ''
  }

  activeMobileWebConfig(data) {
    const mobileWebConfig = data.mobile_web_config ?? {}

    this.appModalDisabled = mobileWebConfig.app_modal_disabled ?? true

    this.deepLink =
      mobileWebConfig.deep_link ||
      'https://worklayer.page.link/?apn=com.worklayer.WorklayerMobile&isi=1412721896&ibi=com.worklayer.WorklayerMobile'

    this.allowDeepLinking = mobileWebConfig.allow_deep_linking ?? true
  }

  activateNotificationsConfig(data) {
    const notificationsConfig = data.notifications_config || {}

    this.clientEmailNotificationEnabled =
      notificationsConfig.ClientEmailNotificationEnabled === undefined
        ? true
        : notificationsConfig.ClientEmailNotificationEnabled

    this.allowSnoozing =
      notificationsConfig.allow_snoozing === undefined
        ? false
        : notificationsConfig.allow_snoozing

    this.notificationJobClosed =
      notificationsConfig.JobClosed === undefined
        ? true
        : notificationsConfig.JobClosed

    this.notificationJobReopened =
      notificationsConfig.JobReopened === undefined
        ? true
        : notificationsConfig.JobReopened

    this.notificationJobUpdated =
      notificationsConfig.JobUpdated === undefined
        ? true
        : notificationsConfig.JobUpdated

    this.notificationMilestoneCompleted =
      notificationsConfig.MilestoneCompleted === undefined
        ? true
        : notificationsConfig.MilestoneCompleted

    this.notificationNewJobAvailable =
      notificationsConfig.NewJobAvailable === undefined
        ? true
        : notificationsConfig.NewJobAvailable

    this.notificationInvitationTeam =
      notificationsConfig.InvitationTeam === undefined
        ? true
        : notificationsConfig.InvitationTeam

    this.notificationCouponAddedToJob =
      notificationsConfig.CouponAddedToJob === undefined
        ? true
        : notificationsConfig.CouponAddedToJob

    this.notificationUnreadMessage =
      notificationsConfig.UnreadMessage === undefined
        ? true
        : notificationsConfig.UnreadMessage

    this.notificationUnreadDocument =
      notificationsConfig.UnreadDocument === undefined
        ? true
        : notificationsConfig.UnreadDocument
  }

  activateNotificationPreferencesConfig(data) {
    const notifcationPreferencesConfig =
      data.notification_preferences_config || {}

    this.notificationPreferencesJobUpdatedEmail =
      notifcationPreferencesConfig.JobUpdated !== undefined &&
      notifcationPreferencesConfig.JobUpdated.email !== undefined
        ? notifcationPreferencesConfig.JobUpdated.email.enabled
        : true
    this.notificationPreferencesJobUpdatedPush =
      notifcationPreferencesConfig.JobUpdated !== undefined &&
      notifcationPreferencesConfig.JobUpdated.push !== undefined
        ? notifcationPreferencesConfig.JobUpdated.push.enabled
        : true

    this.notificationPreferencesUnreadDocumentEmail =
      notifcationPreferencesConfig.UnreadDocument !== undefined &&
      notifcationPreferencesConfig.UnreadDocument.email !== undefined
        ? notifcationPreferencesConfig.UnreadDocument.email.enabled
        : true

    this.notificationPreferencesUnreadDocumentPush =
      notifcationPreferencesConfig.UnreadDocument !== undefined &&
      notifcationPreferencesConfig.UnreadDocument.push !== undefined
        ? notifcationPreferencesConfig.UnreadDocument.push.enabled
        : true

    this.notificationPreferencesJobReopenedEmail =
      notifcationPreferencesConfig.JobReopened !== undefined &&
      notifcationPreferencesConfig.JobReopened.email !== undefined
        ? notifcationPreferencesConfig.JobReopened.email.enabled
        : true

    this.notificationPreferencesJobReopenedPush =
      notifcationPreferencesConfig.JobReopened !== undefined &&
      notifcationPreferencesConfig.JobReopened.push !== undefined
        ? notifcationPreferencesConfig.JobReopened.push.enabled
        : true

    this.notificationPreferencesJobClosedEmail =
      notifcationPreferencesConfig.JobClosed !== undefined &&
      notifcationPreferencesConfig.JobClosed.email !== undefined
        ? notifcationPreferencesConfig.JobClosed.email.enabled
        : true

    this.notificationPreferencesJobClosedPush =
      notifcationPreferencesConfig.JobClosed !== undefined &&
      notifcationPreferencesConfig.JobClosed.push !== undefined
        ? notifcationPreferencesConfig.JobClosed.push.enabled
        : true

    this.notificationPreferencesUnreadMessageEmail =
      notifcationPreferencesConfig.UnreadMessage !== undefined &&
      notifcationPreferencesConfig.UnreadMessage.email !== undefined
        ? notifcationPreferencesConfig.UnreadMessage.email.enabled
        : true

    this.notificationPreferencesUnreadMessagePush =
      notifcationPreferencesConfig.UnreadMessage !== undefined &&
      notifcationPreferencesConfig.UnreadMessage.push !== undefined
        ? notifcationPreferencesConfig.UnreadMessage.push.enabled
        : true

    this.notificationPreferencesMilestoneCompletedEmail =
      notifcationPreferencesConfig.MilestoneCompleted !== undefined &&
      notifcationPreferencesConfig.MilestoneCompleted.email !== undefined
        ? notifcationPreferencesConfig.MilestoneCompleted.email.enabled
        : false

    this.notificationPreferencesMilestoneCompletedPush =
      notifcationPreferencesConfig.MilestoneCompleted !== undefined &&
      notifcationPreferencesConfig.MilestoneCompleted.push !== undefined
        ? notifcationPreferencesConfig.MilestoneCompleted.push.enabled
        : false

    this.notificationPreferencesNewMessageEmail =
      notifcationPreferencesConfig.NewMessage !== undefined &&
      notifcationPreferencesConfig.NewMessage.email !== undefined
        ? notifcationPreferencesConfig.NewMessage.email.enabled
        : false

    this.notificationPreferencesNewMessagePush =
      notifcationPreferencesConfig.NewMessage !== undefined &&
      notifcationPreferencesConfig.NewMessage.push !== undefined
        ? notifcationPreferencesConfig.NewMessage.push.enabled
        : true

    this.notificationPreferencesNewJobAvailableEmail =
      notifcationPreferencesConfig.NewJobAvailable !== undefined &&
      notifcationPreferencesConfig.NewJobAvailable.email !== undefined
        ? notifcationPreferencesConfig.NewJobAvailable.email.enabled
        : true

    this.notificationPreferencesNewJobAvailablePush =
      notifcationPreferencesConfig.NewJobAvailable !== undefined &&
      notifcationPreferencesConfig.NewJobAvailable.push !== undefined
        ? notifcationPreferencesConfig.NewJobAvailable.push.enabled
        : true
  }

  activateLegendsBuilderConfig(data) {
    const legendsBuilderConfig = data.legends_builder_config || {}
    this.legendsBuilderEnabled = legendsBuilderConfig.enabled || false
  }

  activateCalendarIntegrationConfig(data) {
    const calendarConfig = data.calendar_config || {}

    this.calendarIntegrationEnabled =
      calendarConfig.enabled !== undefined ? calendarConfig.enabled : true
  }

  activateMessageBankConfig(data) {
    const messageBank = data.message_bank_config || {}
    this.messageBankEnabled = messageBank.enabled || false
  }

  activatePublicApiConfig(data) {
    const publicApi = data.public_api_config || {}
    this.publicApiConnection = publicApi.connection || ''
    this.publicApiSignedUrlEnabled = publicApi.signed_url_enabled || false
  }

  activateExternalCustomersConfig(data) {
    const externalCustomers = data.external_customers_config || {}
    this.externalCustomersEnabled = externalCustomers?.enabled ?? false
  }

  activatePlatformRolesConfig(data) {
    const config = data.platform_roles_config || {}
    this.platformRolesEnabled = config.enabled || false
  }

  activateMemberPermissionsConfig(data) {
    const config = data.member_permissions_config || {}
    this.memberPermissionsReadonly = config.readonly || false
  }

  activateContractConfig(data) {
    const contractConfig = data.contract_config || {}
    const vmFees = contractConfig.vm_fees || {}
    const setupFees = contractConfig.setup_fees || {}

    this.contractEnabled = contractConfig.enabled || false
    this.contractAmount = contractConfig.amount || 0
    this.contractVmFeeAmountPerUnit = vmFees.amount_per_unit || 0
    this.contractVmFeeQuantity = vmFees.quantity || 0
    this.contractSetupFeeAmount = setupFees.amount || 0
    this.contractStartDate = contractConfig.start_date || new Date()
    this.contractEndDate = contractConfig.end_date || new Date()
  }

  activateChiliPiperConciergeConfig(data) {
    const config = data.chili_piper_concierge_config || {}
    this.chiliPiperEnabledAtCheckout = config.enabled_at_checkout || false
    this.chiliPiperCheckoutRouterId = config.checkout_router_id || ''
    this.ChiliPiperCheckoutFormDescriptor =
      config.checkout_form_descriptor || ''
  }

  activateScopeWorkConfig(data) {
    const scopeWorkConfig = data.scope_work_config || {}

    this.scopeWorkConfig = new ScopeWorkConfigViewModel()
    this.scopeWorkConfig.activate(
      scopeWorkConfig?.scope_work_notification_slack_channel_ids
    )
  }

  activate(
    data,
    legendStore,
    roleStore,
    inventoryItemStore,
    memberStore,
    teamMemberStore,
    currentUser,
    workspaceId
  ) {
    this.activateImpersonationConfig(data)
    this.activateIntercomConfig(data)
    this.activateZendeskConfig(data)
    this.activateSureprepConfig(data)
    this.activateTosConfig(data, workspaceId)
    this.activatePPConfig(data)
    this.activateBillingConfig(data)
    this.activatePayoutsConfig(data)
    this.activateInsightsConfig(data)
    this.activateSignupConfig(data)
    this.activateCustomMenuConfig(data, roleStore, workspaceId)
    this.activateJobConfig(data)
    this.activateRoutingConfig(data)
    this.activateJobProgressionConfig(data, legendStore, workspaceId)
    this.activateJobDetailsConfig(data)
    this.activateCombinedPricingConfig(data, legendStore, workspaceId)
    this.activateKarbonConfig(
      data,
      legendStore,
      memberStore,
      teamMemberStore,
      currentUser,
      workspaceId
    )
    this.activateDocumentConfig(data)
    this.activateEmailConfig(data)
    this.activateMessageConfig(data)
    this.activateWorkpaperConfig(data)
    this.activateAnalyticsConfig(data)
    this.activeMobileWebConfig(data)
    this.activateNotificationsConfig(data)
    this.activateNotificationPreferencesConfig(data)
    this.activateLegendsBuilderConfig(data)
    this.activateCalendarIntegrationConfig(data)
    this.activateMessageBankConfig(data)
    this.activatePublicApiConfig(data)
    this.activateExternalCustomersConfig(data)
    this.activatePlatformRolesConfig(data)
    this.activateMemberPermissionsConfig(data)
    this.activateInformationGathering(data)
    this.activateDocumentSignatureConfig(data)
    this.activateContractConfig(data)
    this.activateRoomsConfig(data)
    this.activateStaffingConfig(
      data,
      roleStore,
      memberStore,
      currentUser,
      workspaceId
    )
    this.activateChiliPiperConciergeConfig(data)
    this.activateScopeWorkConfig(data)

    if (!workspaceId) {
      this.lockTitle = 'Welcome'
      this.customConnection = 'taxfyle-com,'
      this.welcomeMessageEnabled = true
      this.welcomeMessageText =
        'Thank you for submitting a job with Taxfyle! Your Pro should be with you shortly. In the meantime, please upload valid Photo Identification and relevant tax documents. Your Pro should be able to provide additional guidance as necessary. If you have any questions related to platform functionality, please reach out to the Taxfyle support team via the help chat on the bottom right corner of your dashboard or send an email to support@taxfyle.com. Thanks again!'

      this.intercomEnabled = true
      this.intercomAppId = 'pgcb69wj'

      this.ppEnabled = true
      this.ppUrl = 'https://taxfyle.com/policies/privacy?__headless'
      this.impersonationEnabled = true

      this.legendsBuilderEnabled = true
      this.calendarIntegrationEnabled = true
      this.billingEnabled = true
      this.teamsCreditEnabled = true
      this.teamsCreditLimit = 5000
    }
  }

  serialize() {
    return {
      impersonation: { enabled: this.impersonationEnabled },
      intercom: {
        enabled: this.intercomEnabled,
        app_id: this.intercomAppId,
        verification_secret_web: this.intercomVerificationSecretWeb,
        verification_secret_ios: this.intercomVerificationSecretIOS,
        verification_secret_android: this.intercomVerificationSecretAndroid,
      },
      zendesk: {
        enabled: this.zendeskEnabled,
        app_id: this.zendeskAppId,
        key: this.zendeskKey,
        client_id: this.zendeskClientId,
        department: this.zendeskDepartment,
      },
      tos: this.termsOfUseConfig.toJSON(),
      pp: { enabled: this.ppEnabled, url: this.ppUrl },
      billing: {
        enabled: this.billingEnabled,
        'coupons.enabled': this.couponsEnabled,
        'coupons.referral.enabled': this.couponsReferralEnabled,
        'coupons.referral.amount': toPrecision(this.couponsReferralAmount, 2),
        'coupons.abandoned_draft_job.enabled': this
          .couponsAbandonedDraftJobEnabled,
        'teams.credit.enabled': this.teamsCreditEnabled,
        'teams.credit.limit': toPrecision(this.teamsCreditLimit, 2),
      },
      sureprep: {
        enabled: this.sureprepEnabled,
        prep_status_string: this.sureprepPrepStatusString,
        complete_status_string: this.sureprepCompleteStatusString,
        sp_secret: this.sureprepSecretKey,
        username: this.sureprepUsername,
        password: this.sureprepPassword,
        api_key: this.sureprepApiKey,
        job_holder_user_id: this.sureprepJobHolderUserId,
        legend_id: this.sureprepLegendId,
      },
      combined_pricing: this.combinedPricingConfig.toJSON(),
      karbon: this.karbonConfig.toJSON(),
      custom_menu: { items: this.customMenuLinks.toJSON() },
      email_config: {
        support_email: this.supportEmail,
        custom_email_domains: this.customEmailDomains,
        'custom_email_domains.pro_portal': this.proPortalDomain,
        'custom_email_domains.client_portal': this.clientPortalDomain,
      },
      signup: {
        rules: this.signupRules.toJSON(),
        connections: [
          { type: 'passwordless', enabled: this.passwordlessConnection },
          { type: 'apple', enabled: this.appleConnection },
          { type: 'google-oauth2', enabled: this.googleConnection },
          { type: 'rocket-lawyer-sso', enabled: this.rocketLawyerConnection },
          { type: 'linkedin', enabled: this.linkedinConnection },
          { type: 'facebook', enabled: this.facebookConnection },
          {
            type: 'Username-Password-Authentication',
            enabled: this.passwordConnection,
          },
          ...this.customConnection
            .split(',')
            .filter(Boolean)
            .map((x) => ({ type: x.trim(), enabled: true })),
        ],
        title: this.lockTitle,
        description: this.lockDescription,
        logout_url: this.logoutUrl,
        federated_logout: this.federatedLogout,
        external_profiles: this.externalProfiles,
        'login_redirect.enabled': this.loginRedirectEnabled,
        'login_redirect.secret': this.loginRedirectSigningSecret,
        'login_redirect.url': this.loginRedirectUrl,
      },
      job_config: {
        max_concurrent_provider_jobs: asNumber(this.maxConcurrentProviderJobs),
        provider_completed_job_access: this.providerCompletedJobAccess,
        anonymous_opportunities: this.anonymousOpportunities,
        submitter_champion: this.submitterChampion,
        job_mute_team_admins: this.jobMuteTeamAdmins,
        job_hide_client_tasks: this.jobHideClientTasks,
        job_hide_provider_tasks: this.jobHideProviderTasks,
        job_progression_flow: this.jobProgressionFlow,
      },
      routing_config: {
        routing_mode: this.routingMode,
        application_period_duration: asNumber(this.applicationPeriodDuration),
        default_offer_expiration_action: this.defaultOfferExpirationAction,
        default_offer_duration: asNumber(this.defaultOfferDuration),
        requested_provider_offer_expiration_action: this
          .requestedProviderOfferExpirationAction,
        requested_provider_offer_duration: asNumber(
          this.requestedProviderOfferDuration
        ),
      },
      job_progression_config: this.jobProgressionConfig.toJSON(),
      job_details_config: {
        'simplified_nav_bar.enabled': this.simplifiedNavBarEnabled,
        'simplified_nav_bar.image': this.simplifiedNavBarImage,
        'simplified_nav_bar.target_url': this.simplifiedNavBarTargetUrl,
        'tab_config.enabled': this.tabConfigEnabled,
        'tab_config.details': this.tabConfigDetails,
        'tab_config.events': this.tabConfigEvents,
        'tab_config.members': this.tabConfigMembers,
        'conversation.mute_button.enabled': this.conversationMuteButtonEnabled,
        'documents.allow_linking': this.documentsAllowLinking,
      },
      document_config: {
        document_upload_confirmation: {
          enabled: this.documentUploadConfirmationEnabled,
          text: this.documentUploadConfirmationText,
        },
        document_edit_permission: this.documentEditPermission,
      },
      message_config: {
        welcome_message: {
          enabled: this.welcomeMessageEnabled,
          text: this.welcomeMessageText,
        },
      },
      workpaper_config: {
        enabled: this.workpaperEnabled,
      },
      mobile_web_config: {
        app_modal_disabled: this.appModalDisabled,
        deep_link: this.deepLink,
        allow_deep_linking: this.allowDeepLinking,
      },
      notifications_config: {
        allow_snoozing: this.allowSnoozing,
        ClientEmailNotificationEnabled: this.clientEmailNotificationEnabled,
        JobClosed: this.notificationJobClosed,
        JobReopened: this.notificationJobReopened,
        JobUpdated: this.notificationJobUpdated,
        MilestoneCompleted: this.notificationMilestoneCompleted,
        NewJobAvailable: this.notificationNewJobAvailable,
        CouponAddedToJob: this.notificationCouponAddedToJob,
        InvitationTeam: this.notificationInvitationTeam,
        UnreadMessage: this.notificationUnreadMessage,
        UnreadDocument: this.notificationUnreadDocument,
      },
      notification_preferences_config: {
        JobUpdated: {
          email: { enabled: this.notificationPreferencesJobUpdatedEmail },
          push: { enabled: this.notificationPreferencesJobUpdatedPush },
        },
        UnreadDocument: {
          email: { enabled: this.notificationPreferencesUnreadDocumentEmail },
          push: { enabled: this.notificationPreferencesUnreadDocumentPush },
        },
        JobReopened: {
          email: { enabled: this.notificationPreferencesJobReopenedEmail },
          push: { enabled: this.notificationPreferencesJobReopenedPush },
        },
        JobClosed: {
          email: { enabled: this.notificationPreferencesJobClosedEmail },
          push: { enabled: this.notificationPreferencesJobClosedPush },
        },
        UnreadMessage: {
          email: { enabled: this.notificationPreferencesUnreadMessageEmail },
          push: { enabled: this.notificationPreferencesUnreadMessagePush },
        },
        MilestoneCompleted: {
          email: {
            enabled: this.notificationPreferencesMilestoneCompletedEmail,
          },
          push: { enabled: this.notificationPreferencesMilestoneCompletedPush },
        },
        NewMessage: {
          email: { enabled: this.notificationPreferencesNewMessageEmail },
          push: { enabled: this.notificationPreferencesNewMessagePush },
        },
        NewJobAvailable: {
          email: { enabled: this.notificationPreferencesNewJobAvailableEmail },
          push: { enabled: this.notificationPreferencesNewJobAvailablePush },
        },
      },
      legends_builder_config: { enabled: this.legendsBuilderEnabled },
      calendar_config: { enabled: this.calendarIntegrationEnabled },
      analytics_config: {
        enabled: this.analyticsEnabled,
        dashboard_id: this.analyticsDashboardId,
        analytics_service: this.analyticsService,
        'google_tag_manager.enabled': this.googleTagManagerEnabled,
        'google_tag_manager.container_id': this.googleTagManagerContainerId,
      },
      message_bank_config: {
        enabled: this.messageBankEnabled,
      },
      public_api_config: {
        connection: this.publicApiConnection,
        signed_url_enabled: this.publicApiSignedUrlEnabled,
      },
      external_customers_config: {
        enabled: this.externalCustomersEnabled,
        auth_method: this.externalCustomersAuthMethod,
      },
      payouts: {
        enabled: this.payoutsEnabled,
      },
      insights_config: {
        enabled: this.insightsEnabled,
      },
      platform_roles_config: {
        enabled: this.platformRolesEnabled,
      },
      member_permissions_config: {
        readonly: this.memberPermissionsReadonly,
      },
      information_gathering_config: {
        enabled: this.informationGatheringEnabled,
      },
      document_signature_config: {
        enabled: this.documentSignatureEnabled,
      },
      contract_config: {
        enabled: this.contractEnabled,
        vm_fees: {
          amount_per_unit: this.contractVmFeeAmountPerUnit,
          quantity: this.contractVmFeeQuantity,
        },
        setup_fees: {
          amount: this.contractSetupFeeAmount,
        },
        amount: this.contractAmount,
        start_date: this.contractStartDate,
        end_date: this.contractEndDate,
      },
      staffing_config: this.staffingConfig.toJSON(),
      rooms_config: this.roomsConfig.toJSON(),
      chili_piper_concierge_config: {
        enabled_at_checkout: this.chiliPiperEnabledAtCheckout,
        checkout_router_id: this.chiliPiperCheckoutRouterId,
        checkout_form_descriptor: this.ChiliPiperCheckoutFormDescriptor,
      },
      scope_work_config: this.scopeWorkConfig.toJSON(),
    }
  }
}

class CustomMetadataFieldsEditor {
  @observable
  memberMetadataFieldsBuilder = new MemberMetadataFieldsBuilder()

  validation = validationContext(this, {
    memberMetadataFieldsBuilder: [
      ({ value }) =>
        value.validation.reset().validate().isValid || 'Inputs are invalid.',
    ],
  })

  activate(data, workspaceId, workspaceSlug) {
    const memberMetadataFields = data.member_metadata_fields || []

    this.memberMetadataFieldsBuilder = new MemberMetadataFieldsBuilder()
    this.memberMetadataFieldsBuilder.activate(
      memberMetadataFields || [],
      workspaceId,
      workspaceSlug
    )
  }

  serialize() {
    return {
      member_metadata_fields: this.memberMetadataFieldsBuilder.toJSON(),
    }
  }
}
