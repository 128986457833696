import { observable, computed, action } from 'mobx'
import * as FilterScope from 'legend-builder/consts/FilterScope'
import { arrayLength } from 'utils/validx-validators'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import FilterEditor from '../../FilterEditor'

@snapshotable
export default class HasSkillsEditor extends FilterEditor {
  static friendlyName = 'Check Skills'
  static friendlyDescription =
    'Determines whether or not a Pro has the specified skills.'

  static scope = [FilterScope.PROVIDER]

  rules = {
    skills: [arrayLength(1, 0, 'Please select at least 1 skill.')],
  }

  @observable
  @serializable
  skills = []

  @observable
  @serializable
  op = 'All'

  @computed
  get availableSkills() {
    return this.legendEditor.skills.map((x) => ({ label: x.name, value: x.id }))
  }

  /**
   * Imported Legends used the skill name directly, so we always look for the name and ID directly.
   * This fixes that.
   */
  @computed
  get fixedSkills() {
    return this.skills
      .map((idOrText) =>
        this.legendEditor.skills.find(
          (s) => s.id.toString() === idOrText.toString() || s.name === idOrText
        )
      )
      .filter(Boolean)
      .map((s) => s.id)
  }

  @action
  setSkills(skills) {
    this.skills.replace(skills)
  }
}
