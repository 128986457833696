import { validatable } from '../mixins/validation'
import { observable, computed, action } from 'mobx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { asNumber } from 'utils/numberUtil'
import { validateObject } from '../../utils/validx-validators'
import { receiveErrors } from '../data/serverErrors'
import { Types } from '../data/Type'
import RynoEditor from '../ryno-editor/RynoEditor'
import { func } from 'validx'

/**
 * Deadline config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class DeadlineConfigEditor {
  @observable
  @serializable
  expr

  @serializable
  reminder

  rules = {
    expr: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
    reminder: [validateObject('Deadline Reminders Configuration is invalid')],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.reminder = new DeadlineRemindersConfigEditor()
    this.expr = new RynoEditor({
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => Types.date,
    })
  }

  @computed
  get symbolTable() {
    return this.legendEditor.getSymbolTableFor(this)
  }

  hydrate(deadlineConfig) {
    if (!deadlineConfig) {
      this.expr.setValue('')
      return
    }

    const { expr, reminder } = deadlineConfig
    this.expr.setValue(expr, true)
    this.reminder.hydrate(reminder)
  }

  @action.bound
  useTemplate() {
    this.expr.setValue(
      `
# Using the Time module, add 7 days to the request's submission date.
Time.AddDays(
  # Refers to the request's submission date. We are using
  # this to compute a date relative to when the request was submitted!
  Request.DateSubmitted
  # The amount of days to add to the aforementioned date.
  7
)
      `.trim()
    )
  }

  toJSON() {
    return serialize(this)
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    receiveErrors(this, inner)
  }
}

@snapshotable
@validatable({ liveValidation: true })
class DeadlineRemindersConfigEditor {
  @observable
  @serializable
  enabled = true

  @observable
  @serializable((v) => asNumber(v))
  days_before = 7

  rules = {
    days_before: [
      func(
        ({ value }) => asNumber(value) > 0 && asNumber(value) <= 21,
        'Days Before must be between 1 and 21.'
      ),
    ],
  }

  hydrate(deadlineRemindersConfig) {
    if (!deadlineRemindersConfig) {
      this.enabled = false
      this.days_before = 7
      return
    }

    const { enabled, days_before } = deadlineRemindersConfig
    this.enabled = enabled || true
    this.days_before = days_before || 7
  }

  toJSON() {
    return serialize(this)
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    receiveErrors(this, inner)
  }
}
