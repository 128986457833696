import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import {
  Dialog,
  DialogContent,
  DialogButton,
  DialogFooter,
} from 'components/Dialog'
import { Form, FormGroup, Select } from 'components/Form'
import styles from './InviteUserModal.m.sass'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import { action } from 'mobx'
import SkillSelector from 'components/SkillSelector'
import TeamSelector from 'components/TeamSelector'

@observer
export default class InviteUserModal extends React.Component {
  render() {
    const { state } = this.props
    return (
      <Modal
        position="top"
        isOpened={state.showing}
        onRequestClose={state.close}
      >
        <InviteDialog state={state} />
      </Modal>
    )
  }
}

const InviteDialog = observer(function InviteDialog({ state }) {
  return (
    <Dialog>
      {state.activate.match({
        pending: () => (
          <DialogContent>
            <ContentLoading />
          </DialogContent>
        ),
        rejected: (err) => (
          <DialogContent>
            <ContentError error={err} />
          </DialogContent>
        ),
        resolved: () => (
          <DialogContent>
            <div className={styles.intro}>
              {!state.sessionStore.workspace.corporate && (
                <img
                  className={styles.illustration}
                  src={require('./illustration.svg')}
                  alt="Illustration of a group"
                />
              )}
              <p className={styles.title}>Invite people to your workspace</p>
              <p className={styles.description}>Let's get the ball rolling.</p>
            </div>
            <div className={styles.form}>
              <Form>
                <FormGroup
                  label="Email address(es)"
                  field="email"
                  model={state}
                  help="You can comma- or newline-separate emails to invite multiple people."
                >
                  <textarea
                    className="textarea"
                    rows={5}
                    value={state.email}
                    onChange={action((e) => {
                      state.email = e.target.value
                    })}
                  />
                </FormGroup>
                <Select
                  label="Role"
                  field="roleId"
                  model={state}
                  help="When someone accepts the invitation, they are given this role."
                >
                  {state.roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Select>
                <FormGroup
                  label="Skills"
                  help="When someone accepts the invitation, they are given these skills."
                >
                  <SkillSelector
                    isMulti
                    workspaceId={state.sessionStore.workspace.id}
                    skillStore={state.skillStore}
                    onChange={state.selectSkills}
                    value={state.skills}
                  />
                </FormGroup>

                <FormGroup
                  label="Team"
                  help="When someone accepts the invitation, they will be added to this team"
                >
                  <TeamSelector
                    workspaceId={state.sessionStore.workspace.id}
                    teamStore={state.teamStore}
                    onChange={state.selectTeam}
                    value={state.team}
                  />
                </FormGroup>
                {state.team && state.team?.id !== '-1' && (
                  <Select
                    label="Team Role"
                    field="teamRoleId"
                    model={state}
                    help="When someone accepts the invitation, they are given this role in the team chosen above."
                  >
                    {state.teamRoles.map((teamRole) => (
                      <option key={teamRole.id} value={teamRole.id}>
                        {teamRole.name}
                      </option>
                    ))}
                  </Select>
                )}
                <Select
                  label="Portal"
                  field="target"
                  model={state}
                  help="Which portal should the user be sent to?"
                >
                  <option value="CustomerPortal">Customer Portal</option>
                  <option value="Portal">Work Portal</option>
                </Select>
              </Form>
            </div>
          </DialogContent>
        ),
      })}
      <DialogFooter bordered>
        <DialogButton onClick={state.close}>Close</DialogButton>
        <DialogButton
          type="primary"
          onClick={state.sendInvite}
          disabled={
            state.activate.pending ||
            state.sendInvite.pending ||
            state.emailAddresses.length === 0
          }
          loading={state.sendInvite.pending}
        >
          {formatButtonText(state.emailAddresses.length)}
        </DialogButton>
      </DialogFooter>
    </Dialog>
  )
})

function formatButtonText(count) {
  return count === 0
    ? 'Send'
    : count === 1
    ? 'Send Invite'
    : `Send ${count} Invites`
}
