import { Store } from 'libx'
import { observable } from 'mobx'
import { task } from 'mobx-task'

class AnalyticsScreenStore extends Store {
  @observable
  iframeUrl = ''

  @observable
  error = false

  constructor() {
    super(...arguments)

    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.update()
    })
  }

  @task
  async update() {
    this.iframeUrl = await this.generateUrl()
  }

  async generateUrl() {
    const cfg = this.rootStore.sessionStore.workspace.config.analytics_config
    const dashboard = cfg && cfg.dashboard_id
    const analytics_service = cfg && cfg.analytics_service
    const workspace = this.rootStore.sessionStore.workspace.id

    this.error = false

    if (!dashboard) {
      return ''
    }

    return await this.rootStore.api.analytics
      .get({
        dashboard_id: dashboard,
        workspace_id: workspace,
        analytics_service: analytics_service,
      })
      .catch(() => {
        this.error = true
      })
  }
}

export default AnalyticsScreenStore
