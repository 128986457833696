import cx from 'classnames'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './UserFacetedSearch.m.sass'

/**
 * Faceted Search component.
 */
@observer
export default class UserFacetedSearch extends React.Component {
  static defaultProps = {
    facets: new Map(),
    show: true,
  }

  render() {
    const { store } = this.props
    const { facets, selectedFacets } = store

    return (
      <div
        className={cx(styles.facetedSearch, !store.showFilter && styles.hidden)}
      >
        {Array.from(facets).map(([key, value]) => {
          if (value.buckets.length === 0) {
            return null
          }
          return (
            <div className={styles.facet} key={key}>
              <div className={styles.header}>{key}</div>
              {value.buckets.map((bucket) => {
                const name = bucket.key_as_string
                  ? bucket.key_as_string
                  : bucket.key
                const selected = selectedFacets.get(key) || []
                return (
                  <div className={styles.bucket} key={bucket.key}>
                    <input
                      type="checkbox"
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => store.selectFacet(key, name)}
                      checked={selected === name}
                    />
                    {name}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }
}

UserFacetedSearch.propTypes = {
  store: PropTypes.object,
}
