import React from 'react'

import { observer } from 'mobx-react'

import FloatingLabel from 'components/FloatingLabel'
import { ListItem, ListItemSegment, ListItemTitle } from 'components/ListItem'

import './styles.sass'

export default observer(function UserCouponListItem({ userCoupon }) {
  return (
    <ListItem className="user-coupon-list-item">
      <ListItemSegment pad stretch>
        <ListItemTitle>
          <div className="friendly-name">
            {userCoupon.coupon.code}
            <FloatingLabel
              className={`coupon-type coupon-type-${userCoupon.coupon.displayType}`}
            >
              {userCoupon.coupon.displayType}
            </FloatingLabel>
          </div>
          {userCoupon.redeemed && (
            <span className="redemption-info">
              &nbsp; &mdash; &nbsp; redeemed{' '}
              {userCoupon.dateRedeemed.format('LL LT')}
            </span>
          )}
        </ListItemTitle>
        {userCoupon.coupon.description && (
          <p className="coupon-description">{userCoupon.coupon.description}</p>
        )}
      </ListItemSegment>
      <ListItemSegment pad>
        <div className="coupon-value">
          {userCoupon.coupon.offType.type === 'AMOUNT_OFF'
            ? `$${userCoupon.coupon.offType.amountOff} off`
            : `${userCoupon.coupon.offType.percentOff}% off`}
        </div>
      </ListItemSegment>
    </ListItem>
  )
})
