import { action, observable, computed, reaction } from 'mobx'
import InfiniteScrollProvider from 'data/InfiniteScrollProvider'
import { ConfirmDialogState } from 'components/ConfirmDialog'
import { task } from 'mobx-task'

export default class AddCouponToJobState {
  @observable
  showing = false

  @observable
  searchText = ''

  @observable
  referralSearchText = ''

  scrollProvider = new InfiniteScrollProvider({
    memoizeInitial: false,
    limit: 10,
    fetchItems: this._fetchCoupons.bind(this),
  })

  constructor(couponStore, jobStore, detailsStore) {
    this.couponStore = couponStore
    this.jobStore = jobStore
    this.detailsStore = detailsStore
    this.init = this.init.bind(this)
    reaction(() => [this.searchText], this.scrollProvider.fetch, {
      delay: 200,
    })
    this.addCouponConfirm = new ConfirmDialogState()
  }

  @computed
  get job() {
    return this.detailsStore.job
  }

  @computed
  get coupons() {
    if (!this.searchText) {
      return this.couponStore.coupons.filter(
        (c) => c.couponType.type === 'REGULAR'
      )
    }
    const searchText = this.searchText.toLowerCase()
    return this.couponStore.coupons.filter(
      (c) =>
        (c.couponType.type === 'REGULAR' &&
          (c.code.toLowerCase().includes(searchText) ||
            c.friendlyName?.toLowerCase().includes(searchText))) ||
        (c.couponType.type === 'REFERRAL_CODE' &&
          c.code.toLowerCase() === searchText)
    )
  }

  @task
  init() {
    this.scrollProvider.fetch()
  }

  @action.bound
  show() {
    this.searchText = ''
    this.referralSearchText = ''
    this.showing = true
    this.referralCoupon = null
  }

  @action.bound
  hide() {
    this.addCouponConfirm.hide()
    this.showing = false
  }

  @task.resolved
  async selectCoupon(coupon) {
    if (!(await this.addCouponConfirm.show())) {
      return
    }

    await this.jobStore.addCouponToJob(this.job, coupon)
  }

  async _fetchCoupons(p) {
    return this.couponStore.fetchCoupons({
      workspace_id: this.job.workspaceId,
      search_text: this.searchText,
      status: 'ACTIVE',
      ...p,
    })
  }
}
