import { observable, computed, action } from 'mobx'
import { task } from 'mobx-task'

/**
 * Add Provider UI state.
 */
class AddProviderState {
  @observable
  provider = null

  @observable
  availableTeams = []

  @observable
  teamId = ''

  @observable
  showing = false

  @observable
  reason = ''

  @observable
  type = 'SUPPORTING'

  @observable
  error = ''

  constructor(detailsStore, memberStore, teamMemberStore) {
    this.detailsStore = detailsStore
    this.memberStore = memberStore
    this.teamMemberStore = teamMemberStore
    this.addProvider = this.addProvider.bind(this)
    this.init()
  }

  @computed
  get job() {
    return this.detailsStore.job
  }

  @action.bound
  init() {
    this.reason = ''
    this.provider = null
    this.type = 'SUPPORTING'
    this.availableTeams = []
    this.teamId = ''
    this.addProvider.reset()
  }

  @action.bound
  show() {
    this.init()
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @action.bound
  async selectProvider(user) {
    this.provider = user

    const teamMembers = await this.teamMemberStore.fetchUserTeamMembersByPublicId(
      {
        workspace_id: user.workspaceId,
        user_id: user.userPublicId,
      }
    )

    this.availableTeams = teamMembers.filter((x) => !x.team?.inactive)
  }

  @task.resolved
  async addProvider() {
    if (!this.provider) return
    this.hide()

    await this.detailsStore.rootStore.jobStore
      .addProvider(this.job.id, {
        provider: this.provider,
        reason: this.reason,
        type: this.type,
        asTeamId: this.teamId,
      })
      .catch(
        action((err) => {
          this.showing = true
          throw err
        })
      )
  }
}

export default AddProviderState
