import React from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Router, IndexRoute, Route, browserHistory } from 'react-router'
import WorkspaceGateway from 'iam/containers/WorkspaceGateway'
import makeAuthenticate from './interceptors/authenticate'
import { DndProvider } from 'react-dnd'
import App from '../App'
import ContentLoading from 'components/ContentLoading'

import ErrorBoundry from 'components/ErrorBoundry'

// Screens
import Login from 'screens/Login'
import Welcome from 'screens/Welcome'
import UserSearch from 'screens/UserSearch'
import UserDetails from 'screens/UserDetails'
import JobDetails from 'screens/JobDetails'
import Jobs from 'screens/Jobs'
import JobRedirect from '../../screens/JobRedirect'
import Coupons from 'screens/Coupons'
import Scoping from 'screens/Scoping'
import Skills from 'screens/Skills'
import Roles from 'screens/Roles'
import NotificationPreferences from 'screens/NotificationPreferences'
import Analytics from 'screens/Analytics'
import DocumentTags from 'screens/DocumentTags'
import SkillTags from 'screens/SkillTags'
import FlashMessages from 'flash-messages/components/FlashMessages'
import TeamsScreen from '../../screens/Teams'
import { observer } from 'mobx-react'
import MessageBank from 'screens/MessageBank'
import { isDisplayDocumentTagsToggleEnabled } from 'screens/DocumentTags/document-tags-toggle'

/**
 * A component that just renders it's children.
 */
const None = ({ children }) => children

/**
 * Makeshift loadable component.
 * @param {*} fn
 */
const Loadable = (fn) => {
  const Lazy = React.lazy(fn)
  return (props) => (
    <React.Suspense fallback={<ContentLoading />}>
      <Lazy {...props} />
    </React.Suspense>
  )
}

const LegendsView = Loadable(() =>
  import(
    /* webpackChunkName: "legend-builder" */ 'legend-builder/screens/LegendsView'
  )
)
const LegendEditorView = Loadable(() =>
  import(
    /* webpackChunkName: "legend-builder" */ 'legend-builder/screens/LegendEditorView'
  )
)

const WorkspaceManagement = Loadable(() =>
  import(
    /* webpackChunkName: "workspace-manager" */ 'workspace-management/screens/WorkspaceManagement'
  )
)

export default class AppRouter extends React.Component {
  render() {
    const { rootStore } = this.props
    const authenticate = makeAuthenticate({ authStore: rootStore.authStore })

    /**
     * Tells the Route how to create elements.
     * In this case, we want to pass the app store along.
     */
    const createElement = (Component, props) => (
      <ErrorBoundry>
        <Component rootStore={rootStore} {...props} />
      </ErrorBoundry>
    )

    return (
      <DndProvider backend={HTML5Backend}>
        <div>
          <Router history={browserHistory} createElement={createElement}>
            <Route path="/login" component={Login} />
            <Route
              path="/workspace-management"
              component={WorkspaceManagement}
              onEnter={authenticate}
            />
            <Route
              path="/workspace-management/:idOrSlug"
              component={WorkspaceManagement}
              onEnter={authenticate}
            />
            <Route component={WithWorkspaceGateway}>
              <Route component={App}>
                <Route path="/" component={Welcome} onEnter={authenticate} />
              </Route>
              <Route
                path="/:workspaceSlug/legends"
                component={LegendsView}
                onEnter={authenticate}
              />
              <Route
                path="/:workspaceSlug/legends/:id/edit(/:tab)"
                component={LegendEditorView}
                onEnter={authenticate}
              />
              <Route
                path="/jobs/:jobId"
                component={JobRedirect}
                rootStore={rootStore}
                onEnter={authenticate}
              />
              <Route
                path="/:workspaceSlug"
                component={App}
                onEnter={authenticate}
              >
                <IndexRoute component={Welcome} />

                <Route path="users">
                  <IndexRoute
                    component={(props) => <UserSearch {...props} />}
                  />
                  <Route
                    path=":userPublicId"
                    component={(props) => <UserDetails {...props} />}
                  />
                </Route>

                <Route
                  path="/notifications/preferences"
                  component={NotificationPreferences}
                />

                <Route path="teams" component={TeamsScreen} />

                <Route path="skills" component={Skills} />

                <Route path="skill-tags" component={SkillTags} />
                {isDisplayDocumentTagsToggleEnabled() && (
                  <Route path="document-tags" component={DocumentTags} />
                )}

                <Route path="message-bank" component={MessageBank} />

                <Route path="jobs" component={None}>
                  <IndexRoute component={Jobs} />
                  <Route path=":jobId" component={JobDetails} />
                </Route>

                <Route path="scoping-jobs" component={Scoping} />

                <Route path="coupons" component={Coupons} />

                <Route path="analytics" component={Analytics} />

                <Route path="roles" component={Roles} />
              </Route>
            </Route>
          </Router>
          <FlashMessages store={rootStore.flashMessageStore} />
        </div>
      </DndProvider>
    )
  }
}

@observer
class WithWorkspaceGateway extends React.Component {
  render() {
    return (
      <WorkspaceGateway
        rootStore={this.props.rootStore}
        workspaceSlug={this.props.params.workspaceSlug}
      >
        {() => this.props.children}
      </WorkspaceGateway>
    )
  }
}
