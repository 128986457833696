import { observable, action, computed } from 'mobx'
import { tabManager } from '../mixins/tab-manager'

/**
 * State for the property inspector.
 */
@tabManager('GENERAL')
export default class PropertyInspector {
  @observable
  question = null

  @action
  activate(question) {
    this.question = question
  }

  @action
  deactivate() {
    this.question = null
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.question.editorDisabled
  }
}
