import { Store } from 'libx'
import {
  observable,
  action,
  computed,
  reaction,
  extendObservable,
  runInAction,
  untracked,
} from 'mobx'
import { task } from 'mobx-task'
import identity from 'lodash/identity'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import flatten from 'lodash/flatten'
import takeWhile from 'lodash/takeWhile'
import isEqual from 'lodash/isEqual'
import memoize from 'memoizee'
import { browserHistory } from 'react-router'
import { debouncePromise } from '@taxfyle/web-commons/lib/utils/promiseUtil'
import links from 'misc/link'
import { localStorage, createObjectStorage } from 'misc/storage'
import env from 'misc/env'
import { generateIdFromText } from '../../utils/idUtil'
import LegendOptions from '../options/LegendOptions'
import InfoOptions from '../options/InfoOptions'
import TransferViewState from '../options/TransferViewState'
import QuestionsBuilder from '../questions/QuestionsBuilder'
import TemplatesBuilder from '../templates/TemplatesBuilder'
import RoutingEditor from '../routing/RoutingEditor'
import MilestonesBuilder from '../tasks/MilestonesBuilder'
import SymbolConnector from '../symbol-connector/SymbolConnector'
import VersionManager from '../versioning/VersionManager'
import Previewer from '../preview/Previewer'
import {
  SymbolTable,
  unify,
  Variance,
  getRynoTypeFromJsType,
  RynoSymbol,
  SymbolKind,
} from '@taxfyle/ryno'
import {
  inflateServerErrors,
  filterOperationResultErrors,
} from './serverErrors'
import { EngineAPI } from '../api/EngineAPI'
import { Subject } from 'rxjs'

/**
 * How long to wait between each change to persist to the server.
 */
const PERSIST_WAIT = 1000

/**
 * This contains (most) of the data management that has inter-model
 * relationships. For example, questions, variables, formulas, triggers, etc
 * have their own builder classes with logic for manipulating the data stores,
 * but they get this store injected to access the data.
 */
export default class LegendEditorViewStore extends Store {
  _generatedIds = new Map()
  _optionsTab = 'GENERAL' // memoizing subtabs for better UX

  @observable currentTab = 'GENERAL'
  @observable legendVersion = null
  @observable.ref lastSerialized = null
  @observable.ref initialState = null
  @observable.ref engineSymbols = null
  @observable lastSaveLatency = 0

  /**
   * Emits when an item is removed.
   */
  removeQuestion$ = new Subject()

  /**
   * Emits when an item is added.
   */
  addQuestion$ = new Subject()

  /**
   * Emits when a question was moved.
   */
  moveQuestion$ = new Subject()

  constructor() {
    super(...arguments)
    this.legendStore = this.rootStore.legendStore
    this.versionManager = new VersionManager(
      this,
      this.legendStore,
      this.rootStore.memberStore
    )
    this.previewer = new Previewer(this, this.legendStore)
    this.transfer = new TransferViewState(this)
    this.engineAPI = new EngineAPI({
      baseURL: env.LEGEND_API,
      getToken: this.rootStore.authStore.getToken,
    })
    this.fetchSymbols = memoize(this.fetchSymbols.bind(this), {
      length: 0,
      promise: true,
    })
    this._createBuilders(true)
    this.queuePersist = this._createQueuePersist()
    reaction(() => this.currentTab, this._syncRouter.bind(this))
  }

  /**
   * Creates the builder objects.
   */
  @action
  _createBuilders(initial) {
    this.templatesBuilder?.dispose()
    this.templatesBuilder = new TemplatesBuilder({
      legendEditor: this,
    })
    ;(initial ? extendObservable : Object.assign)(this, {
      questionsBuilder: new QuestionsBuilder({
        legendEditor: this,
        parentQuestion: null,
      }),
      templatesBuilder: this.templatesBuilder,
      legendOptions: new LegendOptions(this),
      milestonesBuilder: new MilestonesBuilder(this),
      routingEditor: new RoutingEditor(this),
      infoOptions: new InfoOptions(this),
    })
  }

  @computed
  get isTaxLegend() {
    return this.legendOptions.service_type === 'TAX'
  }

  @computed
  get workspaceInventoryItems() {
    return this.rootStore.inventoryItemStore.workspaceInventoryItems
  }

  @computed
  get allowedWorkspaceInventoryItems() {
    return this.workspaceInventoryItems.filter((i) =>
      allowedInventoryTypesForLegendServiceType(
        this.legendOptions.service_type,
        i.typeSpecification.spec
      )
    )
  }

  @computed
  get workspaceBundles() {
    return this.rootStore.bundleStore.workspaceBundles
  }

  /**
   * Gets an object storage for the current workspace.
   * Used by the previewer to store responses.
   */
  @computed
  get storage() {
    const workspace = this.rootStore.sessionStore.workspace
    return createObjectStorage(
      localStorage,
      workspace ? workspace.slug + '.' : 'global.'
    )
  }

  /**
   * The legend name.
   */
  @computed
  get name() {
    return this.legendOptions.name
  }

  /**
   * When `true`, the user cannot revert or discard because one of those are already running.
   */
  @computed
  get busy() {
    return (
      this.discardLocalChanges.pending ||
      this.revertToSelectedVersion.pending ||
      this.publish.pending ||
      this.transfer.busy
    )
  }

  /**
   * Disables all editor controls when viewing a historic version.
   */
  @computed
  get disabled() {
    return (
      !this.versionManager.isViewingLatestVersion ||
      this.busy ||
      this.versionManager.conflict.isOpened ||
      !this.rootStore.sessionStore.member.hasPermission('ADMIN_LEGEND_MODIFY')
    )
  }

  @computed
  get isDirty() {
    return this.lastSerialized !== this.initialState
  }

  /**
   * All builder instances ordered by symbol availability. What that means is
   * that a builder has access to symbols provided by the builder above it.
   */
  @computed
  get builders() {
    return [
      this.legendOptions,
      this.questionsBuilder,
      this.infoOptions,
      this.milestonesBuilder,
      this.legendOptions.deadline_config,
      this.legendOptions.title_config,
      this.legendOptions.specs_config,
      this.legendOptions.billing_config,
      this.legendOptions.payout_config,
      this.legendOptions.data_sources.routingContextSources,
      this.routingEditor,
      this.templatesBuilder,
    ]
  }

  /**
   * Shortcut to get all questions in the builder.
   */
  @computed
  get questions() {
    return [...this.questionsBuilder.allQuestions]
  }

  /**
   * Entity descriptors contain the entity as well as a type identifier.
   */
  @computed
  get allEntityDescriptors() {
    return flatten(this.builders.map((b) => b.entityDescriptors || []))
  }

  /**
   * Entities are objects that have IDs that must not clash.
   * Used by validation.
   */
  @computed
  get allEntities() {
    return this.allEntityDescriptors.map((x) => x.entity)
  }

  /**
   * Returns skills.
   */
  @computed
  get skills() {
    const workspace = this.rootStore.sessionStore.workspace.id
    return this.rootStore.skillStore.skills
      .filter((s) => s.workspaceId === workspace)
      .slice()
  }

  /**
   * Returns document tags.
   */
  @computed
  get documentTags() {
    return this.rootStore.documentTagStore.tags.slice()
  }

  /**
   * Used by the sidebar to determine whether a tab is invalid.
   * If it is, it will show an error icon.
   */
  @computed
  get tabValidations() {
    return {
      GENERAL: this.legendOptions,
      ROUTING: this.routingEditor,
      MILESTONES: this.milestonesBuilder,
      INFO: this.infoOptions,
    }
  }

  /**
   * Returns the symbols that are available to the target.
   * @param {*} target
   * @returns {Array<EntityDescriptor>}
   */
  getAvailableEntityDescriptorsFor(target) {
    let descriptors = this.allEntityDescriptors
    if (!target) {
      return []
    }

    const allEntities = descriptors.map((x) => x.entity)
    let builders = this.builders

    // Start by checking if the target is a builder, in which
    // case all entities in builders before are available
    // as a starting point.
    const builderIndex = builders.indexOf(target)
    if (builderIndex > -1) {
      builders = takeWhile(builders, (_, index) => index < builderIndex)
      return flatten(builders.map((b) => b.entityDescriptors || []))
    }

    // If the target is an entity, it only has access to entities before it.
    const entityIndex = allEntities.indexOf(target)
    if (entityIndex > -1) {
      descriptors = takeWhile(descriptors, (_, index) => index < entityIndex)
    } else {
      return []
    }

    return descriptors
  }

  /**
   * Creates a symbol table with entities available for the current target.
   *
   * @param {*} target
   */
  getSymbolTableFor(target) {
    const table = new SymbolTable('root')
    if (this.engineSymbols) {
      table.setIdentifierSymbolsFromObject(this.engineSymbols.identifiers)
    }
    return this.getAvailableEntityDescriptorsFor(target).reduce(
      (symbolTable, d) => {
        if (d.entity.valueType) {
          symbolTable.setIdentifierSymbol(
            new RynoSymbol(
              d.entity.id,
              d.entity.valueType,
              SymbolKind.Identifier,
              true
            )
          )
        }
        return symbolTable
      },
      table
    )
  }

  /**
   * Uses Ryno to check type compatibility.
   *
   * @param {} type
   * @param {*} value
   * @returns {Array<string> | null}
   */
  validateValueType(type, value) {
    const symbolTable = new SymbolTable()
    const { errors } = unify(
      getRynoTypeFromJsType(value),
      type,
      symbolTable,
      Variance.Covariant
    )

    return errors || null
  }

  /**
   * Creates a symbol connector.
   * @param {any} opts
   */
  createSymbolConnector(opts = {}) {
    if (!opts.context) {
      throw new Error(
        'createSymbolConnector: needs a "context" field. This can be a top-level builder or an entity.'
      )
    }

    return new SymbolConnector({
      ...opts,
      legendEditor: this,
    })
  }

  /**
   * Sets the current tab.
   *
   * @param {string} tab
   */
  @action.bound
  setTab(tab) {
    // Validate the current tab.
    const { currentTab } = this
    if (tab === currentTab) {
      return
    }

    if (currentTab) {
      const tabValidation = this.tabValidations[currentTab]
      if (tabValidation) {
        tabValidation.validation.reset()
        tabValidation.validateAll()
      }
    }
    switch (tab) {
      case 'QUESTIONS':
        this.questionsBuilder.validateAll()
        break
      case 'TEMPLATES':
        this.templatesBuilder.validateAll()
        break
      case 'PREVIEW':
        break
      case 'OPTIONS':
        tab = this._optionsTab || 'GENERAL'
      // eslint-disable-next-line no-fallthrough
      default:
        this._optionsTab = tab
    }

    this.currentTab = tab
  }

  /**
   * Activates the editor.
   *
   * @param {string} legendId
   */
  @task
  async activate(legendId, tabId = 'GENERAL') {
    tabId = tabId.toUpperCase().replace(/-/g, '_')
    this.setTab(tabId)

    if (this.legendVersion && this.legendVersion.id === legendId) {
      return
    }

    await Promise.all([
      this.selectLegendVersion(legendId).then(() => {
        // Don't wait for this.
        this.versionManager.activate()
        this.previewer.activate()
      }),
      this.rootStore.skillStore.find({ limit: 9999 }),
      this.rootStore.roleStore.find({ limit: 9999 }),
      // Not needed right now
      // this.rootStore.documentTagStore.fetchTags(),
      this.fetchSymbols(),
      this.fetchWorkspaceInventoryItems(),
      this.fetchWorkspaceBundles(),
    ])
  }

  @task
  async fetchWorkspaceInventoryItems() {
    const loadInventoryItems =
      this.rootStore.sessionStore.member.hasSomePermissions(
        'ADMIN_MANAGE_GLOBAL_INVENTORY_ITEMS',
        'MANAGE_WORKSPACE_INVENTORY_ITEMS'
      ) || this.rootStore.sessionStore.user.platformAdmin

    if (!loadInventoryItems) {
      return
    }

    await this.rootStore.inventoryItemStore.fetchWorkspaceInventoryItems(
      this.rootStore.sessionStore.workspace.id
    )
  }

  @task
  async fetchWorkspaceBundles() {
    const loadBundles =
      this.rootStore.sessionStore.member.hasSomePermissions(
        'MANAGE_WORKSPACE_INVENTORY_ITEMS'
      ) || this.rootStore.sessionStore.user.platformAdmin

    if (!loadBundles) {
      return
    }

    await this.rootStore.bundleStore.fetchWorkspaceBundles(
      this.rootStore.sessionStore.workspace.id
    )
  }

  /**
   * Selects the legend version.
   *
   * @param {string} legendId
   * @param {number | undefined} version
   */
  async selectLegendVersion(legendId, version, opts = {}) {
    const legendVersion = await this.legendStore.fetchLegendVersion(
      legendId,
      version,
      opts.skipCache
    )
    this._generatedIds.clear()
    runInAction(() => {
      this.legendVersion = legendVersion
      if (!opts.noRehydrate) {
        this.hydrateState(legendVersion)
        this.questions.forEach((question) => question.toggleCollapsed(true))
      }

      if (this.disposePersistReaction) {
        this.disposePersistReaction()
        this.disposePersistReaction = null
      }

      if (this.versionManager.isViewingLatestVersion) {
        this.disposePersistReaction = reaction(
          () => this.serialize(),
          (serialized) => this.queuePersist(serialized)
        )
      }

      // Used to restore.
      const serialized = this.serialize()
      if (!this.initialState || !opts.keepInitialState) {
        this.initialState = serialized
        // This makes sure we don't show "Discard Changes" for nothing.
        this.lastSerialized =
          this.initialState && isLegendEqual(this.initialState, serialized)
            ? this.initialState
            : serialized
      } else {
        this.lastSerialized = serialized
      }
    })

    return legendVersion
  }

  /**
   * Hydrates the state from the legend.
   * @param {Object} legend
   */
  @action
  hydrateState(legend) {
    try {
      this._createBuilders()
      this.hydrateQuestions(legend)
      this.hydrateTemplates(legend)
      this.hydrateRouting(legend)
      this.hydrateMilestones(legend)
      this.hydrateLegendOptions(legend)
      this.hydrateInfoOptions(legend)
    } catch (error) {
      this.showMessage({
        type: 'error',
        message: 'Error loading legend. Message: ' + error.message,
      })
      console.error(error)
    }
  }

  /**
   * Hydrates questions.
   * @param {Object} legend
   */
  @action
  hydrateQuestions(legend) {
    this.questionsBuilder.hydrate(legend.questions)
  }

  @action
  hydrateTemplates(legend) {
    this.templatesBuilder.hydrate(legend.templates)
  }

  /**
   * Hydrates routing config.
   * @param {Object} legend
   */
  @action
  hydrateRouting(legend) {
    this.routingEditor.hydrate(legend.routing)
  }

  /**
   * Hydrates legend options.
   * @param {Object} legend
   */
  @action
  hydrateLegendOptions(legend) {
    this.legendOptions.hydrate(legend)
  }

  /**
   * Hydrates milestones.
   * @param {Object} legend
   */
  @action
  hydrateMilestones(legend) {
    this.milestonesBuilder.hydrate(legend.milestones)
  }

  /**
   * Hydrates info options.
   * @param {Object} legend
   */
  @action
  hydrateInfoOptions(legend) {
    this.infoOptions.hydrate(legend.infoLines)
  }

  /**
   * Shows a flash message.
   * @param {Object} cfg
   */
  @action
  showMessage(cfg) {
    return this.rootStore.flashMessageStore.create(cfg)
  }

  /**
   * Validates all data is ready to be saved.
   */
  @action.bound
  validate() {
    return this.builders.map((t) => t.validateAll()).every(identity)
  }

  /**
   * Generates a new ID from the specified text, ensuring it is unique.
   * The `cid` will always return the same generated ID.
   *
   * @param {string} cid
   * @param {string} text
   * @param {string | undefined | null} text
   */
  newId(cid, text) {
    if (!text) {
      return undefined
    }

    const prev = this._generatedIds.get(cid)
    if (prev) {
      return prev
    }

    const allIds = [
      ...this.allEntities.map((x) => x.id),
      ...this._generatedIds.values(),
    ].filter(Boolean)

    const cidStem = cid.split('-')?.[0] || 'OBJ'
    const base = generateIdFromText(cidStem, text.substring(0, 30))
    let id = base
    let c = 1
    while (allIds.includes(id)) {
      id = `${base}_${c++}`
    }
    this._generatedIds.set(cid, id)
    return id
  }

  /**
   * Takes errors from the server and propagates them to the right components.
   *
   * @param {*} errors
   */
  @action.bound
  receiveErrors(errors) {
    if (errors.length > 0) {
      console.warn(
        'Analyzer reported the following errors:\n\n' +
          errors.map((e) => `${e.field}:\n${e.message}`).join('\n\n')
      )
      const inflated = inflateServerErrors(errors)
      const {
        stories,
        routing,
        infoLines,
        milestones,
        templates,
        ...topLevel
      } = inflated
      this.legendOptions.receiveErrors({ inner: topLevel })
      this.infoOptions.receiveErrors(infoLines)
      this.milestonesBuilder.receiveErrors(milestones)
      this.routingEditor.receiveErrors(routing)
      this.questionsBuilder.receiveErrors(
        get(stories, 'inner[0].inner.questions')
      )
      this.templatesBuilder.receiveErrors(templates)
    }
  }

  /**
   * Serializes the legend.
   */
  serialize(opts) {
    opts = opts || {}
    const result = {
      ...this.legendOptions.toJSON(),
      version: untracked(() => this.legendVersion.version),
      concurrency_version: this.legendVersion.concurrency_version,
      questions: this.questionsBuilder.toJSON(),
      templates: this.templatesBuilder.toJSON(),
      routing: this.routingEditor.toJSON(),
      milestones: this.milestonesBuilder.toJSON(),
      infoLines: this.infoOptions.toJSON(),
    }

    if (
      opts.serializeSkillNames &&
      result.routing &&
      result.routing.requirements.length > 0
    ) {
      // We want to serialize skill references as their name for easier import.
      // This function will mutate them inline. This is fair because we are certain that we are operating
      // on a copy of the real data.
      const skillMap = new Map(this.skills.map((s) => [s.id, s.name]))
      const mutateSkillRefs = (rule) => {
        if (!rule) {
          return
        }

        switch (rule.type) {
          case 'HasSkills': {
            rule.skills = rule.skills.map((s) => skillMap.get(s) || s)
            break
          }
          case 'If': {
            mutateSkillRefs(rule.conditionRule)
            mutateSkillRefs(rule.thenRule)
            mutateSkillRefs(rule.elseRule)
            break
          }
          case 'Conjunction': {
            rule.rules.forEach(mutateSkillRefs)
            break
          }
        }
      }

      result.routing.requirements.forEach(mutateSkillRefs)
    }

    return result
  }

  /**
   * Persists the serialized legend.
   *
   * @param {*} serialized
   * @param {boolean} doItNow Persists even if there have been no changes
   */
  @task.resolved
  async persist(serialized, doItNow) {
    const started = Date.now()
    if (!serialized) {
      return {}
    }

    if (
      !doItNow &&
      this.lastSerialized &&
      isLegendEqual(this.lastSerialized, serialized)
    ) {
      return {}
    }

    const lastPersisted = this.lastSerialized
    runInAction(() => {
      // If we doing a persist right now, it was user initiated, so
      // we can't assume that there has been any changes.
      // To not confuse the user with "Discard Changes", we check for equality
      // so we don't change the last serialized reference.
      this.lastSerialized =
        doItNow && isLegendEqual(this.lastSerialized, serialized)
          ? this.lastSerialized
          : serialized
    })

    const data = {
      ...serialized,
      version: this.versionManager.latestVersion.version,
      concurrency_version: this.legendVersion.concurrency_version,
    }

    try {
      // We made a change to the latest published version.
      if (
        this.legendVersion.published &&
        this.versionManager.latestVersion.version === data.version
      ) {
        const newVersionNumber = data.version + 1
        const result = await this.legendStore.save(this.legendVersion.id, {
          ...data,
          version: newVersionNumber,
        })

        await this.selectLegendVersion(
          this.legendVersion.id,
          newVersionNumber,
          {
            // We serialized the same state as the one we are in, no need to hydrate
            noRehydrate: true,
            // We want to be able to discard to before creating a new draft.
            keepInitialState: true,
            skipCache: true,
          }
        )

        return result
      }

      if (!doItNow) {
        // Only send the diff, and only when not explicitly asking to save now.
        const diff = diffLegend(lastPersisted, data)
        return await this.legendStore.patch(this.legendVersion.id, diff)
      }

      return await this.legendStore.save(this.legendVersion.id, data)
    } catch (error) {
      const conflictParams = getConflictParams(error)
      if (conflictParams) {
        return this.versionManager.conflict.resolveConflict(conflictParams)
      }

      throw this.showMessage('There was an error').forError(error)
    } finally {
      runInAction(() => {
        this.lastSaveLatency = Date.now() - started
      })
    }
  }

  /**
   * Persists the current state and receives validation errors.
   *
   * @param {*} additionalProps
   */
  @task.resolved
  async persistAndCheck(additionalProps) {
    const showValidationErrorMessage = (msg) =>
      this.showMessage({
        type: 'error',
        message: msg || 'There are validation errors.',
      }).autoDismiss()

    if (!this.validate()) {
      showValidationErrorMessage()
      return false
    }

    const serialized = {
      ...this.serialize(),
      ...additionalProps,
    }
    const persistResult = await this.queuePersist(
      serialized,
      /* do it now: */ true
    )
    const errors = filterOperationResultErrors(persistResult)
    if (errors && errors.length > 0) {
      showValidationErrorMessage()
      this.receiveErrors(errors)
      return false
    }

    return true
  }

  /**
   * Discards local changes.
   */
  @task.resolved
  async publish(opts) {
    const ready = await this.persistAndCheck({
      version_notes: opts.versionNotes,
    })
    if (!ready) {
      return
    }

    const msg = this.showMessage({
      message: 'Publishing...',
    }).progress()
    const { id, version } = this.legendVersion
    await this.legendStore
      .publish({
        id,
        version,
        concurrency_version: this.legendVersion.concurrency_version,
        version_notes: opts.versionNotes,
      })
      .catch((err) => {
        throw msg.forError(err)
      })
    await this.selectLegendVersion(id, version, {
      skipCache: true,
    })
    this.versionManager.setVersionNotes('')
    msg
      .done(`Successfully published V${this.legendVersion.version}!`)
      .autoDismiss()
  }

  /**
   * Discards local changes.
   */
  @task.resolved
  async discardLocalChanges() {
    await this.persist(this.initialState)
    await this.selectLegendVersion(
      this.legendVersion.id,
      this.legendVersion.version,
      { skipCache: true }
    )
  }

  /**
   * Discards local changes.
   */
  @task.resolved
  async revertToSelectedVersion() {
    const latestVersion = this.versionManager.latestVersion
    console.assert(
      latestVersion,
      'latestVersion is not set during revertToSelectedVersion'
    )
    const serialized = this.serialize()
    serialized.version = latestVersion.version
    serialized.concurrency_version = latestVersion.concurrency_version
    if (latestVersion.published) {
      serialized.version++
    }
    const selectedVersion = this.legendVersion
    const msg = this.showMessage({
      message: `Reverting to V${selectedVersion.version}...`,
    }).progress()
    try {
      await this.legendStore.save(selectedVersion.id, serialized).catch((e) => {
        throw msg.forError(e)
      })
      await this.selectLegendVersion(selectedVersion.id, serialized.version, {
        keepInitialState: true,
        skipCache: true,
      })
      msg
        .done(`Successfully reverted draft to V${selectedVersion.version}`)
        .autoDismiss()
    } catch (error) {
      // An error occured, let's get back on the latest version.
      await this.selectLegendVersion(selectedVersion.id, {
        skipCache: true,
      })
      throw error
    }
  }

  /**
   * Fetches engine symbols. Memoized by the constructor.
   */
  async fetchSymbols() {
    this.engineSymbols = await this.engineAPI.fetchSymbols()
  }

  /**
   * Creates the `queuePersist` method.
   * Ensures that we don't queue any persist requests while doing
   * things like discard and revert.
   */
  _createQueuePersist() {
    let deferreds = []
    const resolveDeferreds = (r) => {
      deferreds.forEach((d) => d.resolve(r))
      deferreds = []
    }
    const rejectDeferreds = (e) => {
      deferreds.forEach((d) => d.reject(e))
      deferreds = []
    }
    const promiseDebounced = debouncePromise(this.persist.bind(this))
    const debounced = debounce(
      (data, doItNow) =>
        Promise.resolve()
          .then(() => !this.busy && promiseDebounced(data, doItNow))
          .then(resolveDeferreds)
          .catch(rejectDeferreds),
      PERSIST_WAIT,
      {
        trailing: true,
        leading: false,
      }
    )

    return task.resolved((serialized, doItNow) => {
      if (doItNow) {
        debounced.cancel()
        return promiseDebounced(serialized, doItNow).then((result) => {
          resolveDeferreds(result)
          return result
        })
      }

      return new Promise((resolve, reject) => {
        deferreds.push({ resolve, reject })
        debounced(serialized)
      })
    })
  }

  /**
   * Syncs the router state when the tab changes.
   *
   * @param {string} tab
   */
  _syncRouter(tab) {
    if (!this.legendVersion) {
      return
    }

    tab = tab.toLowerCase().replace(/_/g, '-')
    browserHistory.push(
      links.editLegend(
        this.rootStore.sessionStore.workspace.slug,
        this.legendVersion.id,
        tab
      )
    )
  }
}

/**
 * Strips out the concurrency version and checks the legends equality.
 *
 * @param {*} left
 * @param {*} right
 */
function isLegendEqual(left, right) {
  const { concurrency_version: _1, ...leftRest } = left
  const { concurrency_version: _2, ...rightRest } = right
  return isEqual(leftRest, rightRest)
}

/**
 * Returns only the attributes that changed at the top level.
 * Due to the snapshotting, this only needs to do a reference check (with the exception
 * of arrays)
 */
function diffLegend(previous, next) {
  previous = previous ?? {}
  next = next ?? {}
  const result = {
    version: next.version,
    concurrency_version: next.concurrency_version,
  }

  for (const key of Object.keys(next)) {
    if (key === 'version' || key === 'concurrency_version') {
      continue
    }

    const prevVal = previous[key]
    const nextVal = next[key]
    if (prevVal === nextVal) {
      continue
    }

    if (Array.isArray(prevVal) && Array.isArray(nextVal)) {
      // Handle arrays.
      if (prevVal.length !== nextVal.length) {
        result[key] = nextVal
        continue
      }

      if (nextVal.some((v, i) => prevVal[i] !== v)) {
        result[key] = nextVal
        continue
      }

      continue
    }
    result[key] = next[key]
  }
  return result
}

/**
 * Gets conflict params for an error.
 *
 * @param {*} err
 */
function getConflictParams(err) {
  if (!err.response) return null
  if (!err.response.data) return null
  if (err.response.data.type !== 'LegendConflict') return null
  return err.response.data
}

function allowedInventoryTypesForLegendServiceType(
  legendServiceType,
  inventoryItemTypeSpec
) {
  switch (legendServiceType) {
    case 'BOOKKEEPING':
      return (
        inventoryItemTypeSpec.bookkeeping != null ||
        inventoryItemTypeSpec.pb_default != null
      )
    case 'CONSULTATION':
      return (
        inventoryItemTypeSpec.consultation != null ||
        inventoryItemTypeSpec.pb_default != null
      )
    case 'TAX':
      return (
        inventoryItemTypeSpec.tax != null ||
        inventoryItemTypeSpec.consultation != null ||
        inventoryItemTypeSpec.pb_default != null
      )
    case 'MISC':
      return inventoryItemTypeSpec.pb_default != null
    default:
      return true
  }
}
