import React from 'react'
import cx from 'classnames'
import Icon from 'components/Icon'
import styles from './RemoveButton.sass'

const RemoveButton = ({ className, children, onClick }) => {
  return (
    <a
      onClick={onClick && ((e) => e.preventDefault() || onClick(e))}
      className={cx('button is-icon-only', styles.root, className)}
    >
      <Icon small material name="close" />
    </a>
  )
}

export default RemoveButton
