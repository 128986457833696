import { Store } from 'libx'
import { task } from 'mobx-task'
import { observable, action, computed } from 'mobx'
import { validationContext, required } from 'validx'

export default class RoleEditorStore extends Store {
  id = null
  @observable
  showing = false

  @observable
  showingTeam = null

  @observable
  name = ''

  @observable
  description = ''

  @observable.ref
  permissions = {}

  validation = validationContext(this, {
    name: [required('Name is required')],
  })

  @computed
  get availablePermissions() {
    const hidePermissions = [
      'VIEW_GROUP_CHAT',
      'WRITE_GROUP_CHAT',
      'VIEW_TEAM_JOBS',
      'DELETE_TEAM_JOBS',
      'CREATE_TEAM_JOBS',
      'UPDATE_TEAM_JOBS',
      'EDIT_TEAM_JOBS_DOCUMENTS',
      'MANAGE_TEAM_BILLING',
      'MANAGE_TEAM_MEMBER',
      'UPDATE_TEAM_MEMBER_SKILLS',
      'START_TEAM_JOBS',
      'VIEW_TEAM_PRICING',
      'TEAM_ANALYTICS_VIEW',
      'ORGANIZATION_VIEW',
      'ORGANIZATION_MANAGE',
      'ADMIN_STAFFING_MANAGE',
    ]
    return this.rootStore.permissionStore.permissions
      .slice()
      .filter((p) => !hidePermissions.includes(p.id))
  }

  @computed
  get availableTeamPermissions() {
    const teamPermissions = [
      'CHAT_ADD',
      'CHAT_VIEW',
      'DOCS_VIEW',
      'JOB_AMEND',
      'JOB_CLOSE',
      'JOB_PICKUP',
      'JOB_UPDATE',
      'DOCS_UPLOAD',
      'JOB_FORFEIT',
      'JOB_ON_HOLD',
      'JOB_TRANSFER',
      'VIEW_PRICING',
      'VIEW_TEAM_JOBS',
      'VIEW_GROUP_CHAT',
      'VIEW_OPPORTUNITIES',
      'START_TEAM_JOBS',
      'CREATE_TEAM_JOBS',
      'PROVIDER_BENEFITS_VIEW',
      'EDIT_TEAM_JOBS_DOCUMENTS',
      'RATING_CLIENT_SUBMIT',
      'RATING_PROVIDER_SUBMIT',
      'DELETE_TEAM_JOBS',
      'DOCS_SELF_REMOVE',
      'UPDATE_TEAM_JOBS',
      'WRITE_GROUP_CHAT',
      'MANAGE_TEAM_MEMBER',
      'MANAGE_TEAM_BILLING',
      'TEAM_ANALYTICS_VIEW',
      'UPDATE_TEAM_MEMBERS',
      'PROMPT_CREATE',
      'CALL_START',
      'CALL_ENABLE_CAMERA',
    ]
    return this.rootStore.permissionStore.permissions
      .slice()
      .filter((p) => teamPermissions.includes(p.id))
  }

  @action
  show(role, showingTeam) {
    this.validation.reset()
    this.showing = true
    this.role = null
    this.name = ''
    this.showingTeam = showingTeam
    this.description = ''
    this.permissions = {}
    if (role) {
      this.role = role
      this.name = role.name
      this.description = role.description
      this.permissions = role.permissions
    }
  }

  @action
  close() {
    this.showing = false
    this.showingTeam = null
  }

  @action
  changePermissions(permissions) {
    this.permissions = permissions
  }

  @task.resolved
  async save() {
    if (!this.validation.reset().validate().isValid) {
      return
    }
    const { roleStore, teamRoleStore } = this.rootStore
    const data = {
      name: this.name,
      description: this.description,
      permissions: this.permissions,
    }
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Saving role...',
      inProgress: true,
    })
    const id = this.role && this.role.id
    try {
      if (this.showingTeam) {
        if (id) {
          await teamRoleStore.update(id, data)
        } else {
          await teamRoleStore.create(data)
        }
      } else {
        if (id) {
          await roleStore.update(id, data)
        } else {
          await roleStore.create(data)
        }
      }
      this.rootStore.rolesScreenStore.loadRoles()
      msg.done('Role saved!').autoDismiss()
    } catch (e) {
      msg.failed('Something went wrong while saving.')
      this.show(this.role)
    }
    this.close()
  }
}
