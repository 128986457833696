import React from 'react'
import { observer } from 'mobx-react'
import styles from 'SearchBar.m.sass'

const SearchBar = observer(function SearchBar({
  value,
  onChange,
  placeholder,
}) {
  return (
    <div className={styles.searchBar}>
      <input
        type="search"
        className={styles.searchInput}
        value={value}
        onChange={onChange}
        placeholder={placeholder || 'Type to search...'}
      />
    </div>
  )
})

export default SearchBar
