import { observable, action } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, required } from 'validx'

/**
 * Amendments UI state.
 */
export default class JobInvoiceState {
  @observable
  showing = false

  @observable
  error = ''

  @observable
  job

  @observable
  reason = ''

  @observable
  stripeId = ''

  // when the value gets passed in from the input field this is actually a string
  // so lets keep the types correct
  @observable
  amount = '0'

  validation = validationContext()

  constructor({ rootStore }) {
    this.rootStore = rootStore
  }

  @action.bound
  init(job) {
    this.job = job
    this.validation.reset()
    this.reason = ''
    this.stripeId = ''
    this.amount = '0'
    this.addInvoice = this.addInvoice.bind(this)
    this.addInvoice.reset()
  }

  @task.resolved
  async addInvoice() {
    const parsedAmount = parseFloat(this.amount.replaceAll(',', ''))

    const rules = {
      amount: [
        () => {
          if (isNaN(parsedAmount) || parsedAmount === 0) {
            return 'Please provide a number.'
          }
          return true
        },
      ],
      stripeId: [required('Please add the invoice id form stripe.')],
      reason: [required('Please specify a reason for doing this.')],
    }

    if (!this.validation.reset().validate(this, rules).isValid) {
      return
    }

    await this.rootStore.jobStore.addInvoice(this.job.id, {
      reason: this.reason,
      amount: parsedAmount,
      stripeId: this.stripeId,
    })

    this.hide()
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }
}
