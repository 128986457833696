import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import { Form, Input, FormGroup } from 'components/Form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import PermissionsEditor from 'components/PermissionsEditor'

@observer
export default class RoleEditor extends React.Component {
  save = () => this.props.store.save()
  close = () => this.props.store.close()
  changePermissions = (permissions) =>
    this.props.store.changePermissions(permissions)

  render() {
    const { store } = this.props
    const pending = store.save.pending
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={this.close}
        position="top"
      >
        <Form onSubmit={this.save}>
          <Dialog>
            <DialogTitle>Role</DialogTitle>
            <DialogContent>
              <Input
                disabled={pending}
                label="Name"
                field="name"
                model={store}
                data-test="role-name-input"
              />
              <Input
                disabled={pending}
                label="Description"
                field="description"
                model={store}
                data-test="role-description-input"
              />
              <FormGroup label="Permissions">
                <PermissionsEditor
                  disabled={pending}
                  availablePermissions={
                    store.showingTeam
                      ? store.availableTeamPermissions.slice()
                      : store.availablePermissions.slice()
                  }
                  value={store.permissions}
                  onChange={this.changePermissions}
                />
              </FormGroup>
            </DialogContent>
            <DialogFooter>
              <DialogButton disabled={pending} onClick={this.close}>
                Cancel
              </DialogButton>
              <DialogButton
                submit
                disabled={pending}
                type="primary"
                data-test="save-workspace-role"
              >
                Save
              </DialogButton>
            </DialogFooter>
          </Dialog>
        </Form>
      </Modal>
    )
  }
}
