import { APIClient } from '@taxfyle/web-commons/lib/misc/APIClient'
import { ENGINE_VERSION_PARAMS } from '../consts/EngineVersion'

/**
 * API specifically for finding published legends.
 */
export class PublishedLegendAPI extends APIClient {
  find({ workspace_id, ...query }) {
    return this.client
      .get(`/v3/workspaces/${workspace_id}/legends`, {
        params: {
          ...ENGINE_VERSION_PARAMS,
          ...query,
          inactive: false,
          draft: false,
        },
      })
      .then(this._data)
  }

  getVersion(id, version, params) {
    return this.client
      .get(`/v3/legends/${id}${version ? `/versions/${version}` : ''}`, {
        ...ENGINE_VERSION_PARAMS,
        ...params,
      })
      .then(this._data)
  }
}
