export default {
  welcome: (workspaceSlug) => `/${workspaceSlug}`,
  analytics: (workspaceSlug) => `/${workspaceSlug}/analytics`,
  userSearch: (workspaceSlug) => `/${workspaceSlug}/users/`,
  teams: (workspaceSlug) => `/${workspaceSlug}/teams/`,
  user: (userId, workspaceSlug) => `/${workspaceSlug}/users/${userId}`,
  jobs: (workspaceSlug) => `/${workspaceSlug}/jobs/`,
  job: (jobId, workspaceSlug) =>
    workspaceSlug ? `/${workspaceSlug}/jobs/${jobId}` : `/jobs/${jobId}`,
  benefits: () => '/benefits',
  scopingJobs: (workspaceSlug) => `/${workspaceSlug}/scoping-jobs`,
  coupons: (workspaceSlug) => `/${workspaceSlug}/coupons/`,
  skills: (workspaceSlug) => `/${workspaceSlug}/skills/`,
  documentTags: (workspaceSlug) => `/${workspaceSlug}/document-tags`,
  skillTags: (workspaceSlug) => `/${workspaceSlug}/skill-tags`,
  downloadMessageHistory: (userId, accessToken) =>
    `/export/messages/${encodeURIComponent(
      userId
    )}?access_token=${accessToken}`,
  downloadReport: (reportId, accessToken) =>
    `/export/report/${reportId}?access_token=${accessToken}`,
  legends: (workspaceSlug) => `/${workspaceSlug}/legends`,
  roles: (workspaceSlug) => `/${workspaceSlug}/roles/`,
  editLegend: (workspaceSlug, id, tab = '') =>
    `/${workspaceSlug}/legends/${id}/edit${tab && `/${tab.toLowerCase()}`}`,
  workspaceManagement: () => `/workspace-management`,
  workspaceManagementDetails: (idOrSlug) => `/workspace-management/${idOrSlug}`,
  notificationPreferences: () => '/notifications/preferences',
  messageBank: (workspaceSlug) => `/${workspaceSlug}/message-bank`,
}
