import { validatable } from '../mixins/validation'
import { observable, computed, action } from 'mobx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { required } from 'validx'

/**
 * Prep and review config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class PrepAndReviewConfigEditor {
  @observable
  @serializable
  enabled = false

  @observable
  @serializable
  previous_pro = false

  @observable
  @serializable
  prep_service_item_id = ''

  @observable
  @serializable
  review_service_item_id = ''

  @observable
  @serializable
  prep_and_review_service_item_id = ''

  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  @computed
  get inventoryItems() {
    return this.legendEditor.allowedWorkspaceInventoryItems
  }

  @computed
  get rules() {
    if (this.enabled) {
      return {
        prep_service_item_id: [required('Prep service item is required')],
        review_service_item_id: [required('Review service item is required')],
        prep_and_review_service_item_id: [
          required('Prep and review service item is required'),
        ],
      }
    } else {
      return {
        prep_service_item_id: [],
        review_service_item_id: [],
        prep_and_review_service_item_id: [],
      }
    }
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
  }

  @action.bound
  changePrepServiceItemId(value) {
    this.prep_service_item_id = value
  }

  @action.bound
  changeReviewServiceItemId(value) {
    this.review_service_item_id = value
  }

  @action.bound
  changePrepAndReviewServiceItemId(value) {
    this.prep_and_review_service_item_id = value
  }

  toJSON() {
    return serialize(this)
  }

  hydrate(prepAndReviewConfig) {
    if (!prepAndReviewConfig) {
      this.enabled = false
      this.prep_service_item_id = ''
      this.review_service_item_id = ''
      this.prep_and_review_service_item_id = ''
      this.previous_pro = false
      return
    }

    const {
      enabled = false,
      prep_service_item_id = '',
      review_service_item_id = '',
      prep_and_review_service_item_id = '',
      previous_pro = false,
    } = prepAndReviewConfig
    this.enabled = enabled
    this.prep_service_item_id = prep_service_item_id
    this.review_service_item_id = review_service_item_id
    this.prep_and_review_service_item_id = prep_and_review_service_item_id
    this.previous_pro = previous_pro
  }
}
