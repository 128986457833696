import { observable, action } from 'mobx'
import { required, validationContext } from 'validx'
import { vif } from 'utils/validx-validators'

export default class KarbonConfigViewModel {
  @observable
  enabled = false

  @observable
  jobClientUserPublicId = undefined

  @observable
  teamId = undefined

  @observable
  legendId = undefined

  @observable
  workspaceLegends = []

  @observable possibleJobClients = []

  @observable possibleTeams = []

  validation = validationContext(this, {
    jobClientUserPublicId: [vif(() => this.enabled, [required()])],
    legendId: [vif(() => this.enabled, [required()])],
  })

  constructor({
    legendStore,
    memberStore,
    teamMemberStore,
    currentUser,
    workspaceId,
  }) {
    this.legendStore = legendStore
    this.memberStore = memberStore
    this.workspaceId = workspaceId
    this.currentUser = currentUser
    this.teamMemberStore = teamMemberStore
  }

  activate(
    enabled = false,
    jobClientUserPublicId = undefined,
    teamId = undefined,
    legendId = undefined
  ) {
    this.enabled = enabled
    this.jobClientUserPublicId = jobClientUserPublicId
    this.teamId = teamId
    this.legendId = legendId

    this.workspaceLegends = this.legendStore.briefLegendsForWorkspace(
      this.workspaceId
    )

    this.memberStore
      .search({
        workspace_id: this.workspaceId,
        limit: 0,
        has_permission: 'JOB_CREATE',
      })
      .then((result) => {
        this.possibleJobClients.push(...result.data)
      })

    if (this.jobClientUserPublicId) {
      this.populatePossibleTeams()
    }
  }

  @action.bound
  populatePossibleTeams() {
    this.possibleTeams = []
    this.teamMemberStore
      .fetchUserTeamMembersByPublicId({
        workspace_id: this.workspaceId,
        user_id: this.jobClientUserPublicId,
      })
      .then((teamMemberships) => {
        this.possibleTeams.push(
          ...teamMemberships.map((teamMember) => teamMember.team)
        )
      })
  }

  @action.bound
  changeJobClient(jobClientUserPublicId) {
    this.jobClientUserPublicId = jobClientUserPublicId
    this.teamId = null
    this.populatePossibleTeams()
  }

  toJSON() {
    if (!this.enabled) {
      return {
        enabled: this.enabled,
        legend_id: '',
        team_id: '',
        job_client_user_public_id: '',
      }
    }

    return {
      enabled: this.enabled,
      legend_id: this.legendId ?? '',
      team_id: this.teamId ?? '',
      job_client_user_public_id: this.jobClientUserPublicId ?? '',
    }
  }
}
