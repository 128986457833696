import React from 'react'
import cx from 'classnames'
import { observer, inject } from 'mobx-react'
import { match } from 'utils/patternMatchUtil'
import { InputControl, SelectControl } from 'legend-builder/components/Controls'
import { SwitchCheckbox } from 'components/SwitchCheckbox'
import styles from './ValueInput.sass'
import { Types } from '../../data/Type'

@inject(['rootStore'])
@observer
export default class ValueInput extends React.Component {
  render() {
    const { block, predefinedOptions } = this.props
    return (
      <div className={cx(styles.root, block && styles.block)}>
        <div className={styles.control}>
          {predefinedOptions
            ? this.renderPredefinedOptions()
            : this.renderTypeInput()}
        </div>
      </div>
    )
  }

  renderPredefinedOptions() {
    const {
      onChange,
      value,
      predefinedOptions,
      rootStore,
      block,
      ...rest
    } = this.props
    return (
      <SelectControl
        value={value}
        onChange={(value) => onChange(value)}
        placeholder="-- select --"
        items={predefinedOptions.map((o) => [o.value, o.text])}
        {...rest}
      />
    )
  }

  renderTypeInput() {
    const {
      type,
      onChange,
      value,
      rootStore,
      predefinedOptions,
      block,
      ...rest
    } = this.props
    const skills = rootStore.legendEditorViewStore.skills
    return match(type, [
      [
        Types.string,
        () => (
          <InputControl
            value={value === null || value === undefined ? '' : value}
            onChange={(e) => onChange(e.target.value)}
            {...rest}
          />
        ),
      ],
      [
        Types.number,
        () => (
          <InputControl
            value={value === null || value === undefined ? '' : value}
            type="number"
            onChange={(e) => {
              const parsed = parseFloat(e.target.value)
              if (Number.isNaN(parsed)) {
                onChange(null)
              } else {
                onChange(parsed)
              }
            }}
            {...rest}
          />
        ),
      ],
      [
        Types.boolean,
        () => (
          <SwitchCheckbox
            trueLabel="Yes"
            falseLabel="No"
            value={value || false}
            onChange={onChange}
            {...rest}
          />
        ),
      ],
      [
        Types.skill,
        () => (
          <SelectControl
            value={value || ''}
            onChange={(value) => onChange(value)}
            {...rest}
          >
            <option value="">-- select skill --</option>
            {skills.map((skill) => (
              <option value={skill.id} key={skill.id}>
                {skill.name}
              </option>
            ))}
          </SelectControl>
        ),
      ],
      [
        match.DEFAULT,
        () => <InputControl {...rest} value="Not applicable" disabled />,
      ],
    ])
  }
}
