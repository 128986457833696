import RoutingContextSourceEditor from '../RoutingContextSourceEditor'
import { serializable, snapshotable } from '../../../data/serialization'
import { computed, observable, action } from 'mobx'
import { Types } from '../../../data/Type'
import { makeDuplicateIdValidation } from '../../../mixins/ids'
import { arrayLength } from 'utils/validx-validators'
import { required, func } from 'validx'

const { idRules, installHook } = makeDuplicateIdValidation(
  (v) => v.legendEditor.allEntities
)

/**
 * Service Item Presence editor.
 */
@snapshotable
export default class ServiceItemPresenceEditor extends RoutingContextSourceEditor {
  static friendlyType = 'Service Item Presence'
  static friendlyDescription = `Brings in the presence of one or more service items`
  static type = 'ServiceItemPresence'

  @observable
  @serializable
  id = 'SERVICE_ITEM_PRESENCE' // Default ID.

  @observable
  @serializable
  inventory_item_ids = []

  @observable
  @serializable
  op = 'Any'

  valueType = Types.boolean

  rules = {
    id: idRules,
    inventory_item_ids: [
      arrayLength(1, 0, 'Please select at least 1 inventory item.'),
    ],
    op: [
      required(),
      func((ctx) => ['Any', 'All'].includes(ctx.value), 'Not a valid op'),
    ],
  }

  constructor() {
    super(...arguments)
    installHook(this)
  }

  @computed
  get entityDescriptors() {
    return [
      {
        type: 'ServiceItemPresence',
        entity: this,
      },
    ]
  }

  @action.bound
  setInventoryItems(ids) {
    this.inventory_item_ids = ids
  }
}
