import { serializable } from 'legend-builder/data/serialization'
import { observable } from 'mobx'
import { validateObject } from 'utils/validx-validators'
import MutatorEditor from 'legend-builder/effects/MutatorEditor'

export default class CopyInventoryItemMutatorEditor extends MutatorEditor {
  type = 'CopyInventoryItem'

  /**
   * The inventory item to copy from.
   */
  @observable
  @serializable
  source

  rules = {
    target: [
      validateObject(
        'Please select the service item element you wish to copy to.'
      ),
    ],
    source: [
      validateObject(
        'Please select the service item element you wish to copy from.'
      ),
    ],
  }

  constructor(attrs, opts) {
    super(null, opts)

    this.source = this.legendEditor.createSymbolConnector({
      context: this.effect.context,
      required: true,
      predicates: [
        (entityDescriptor) =>
          this.isCompatibleVariableType(
            entityDescriptor.type,
            entityDescriptor.entity.valueType
          ),
      ],
    })

    this.set(attrs, opts)
    this.target.bubbleErrorsTo(this, 'target')
    this.source.bubbleErrorsTo(this, 'source')
  }

  isCompatibleVariableType(type, valueType) {
    return type === 'InventoryItem'
  }

  parse({ target, source, ...attrs }) {
    this.target.hydrate(target)
    this.source.hydrate(source)
    return attrs
  }
}
