import { Store } from 'libx'
import { action, computed } from 'mobx'
import BillingProfile from '@taxfyle/web-commons/lib/billing/BillingProfile'
import BillingMethod from '@taxfyle/web-commons/lib/billing/BillingMethod'
import Transaction from '@taxfyle/web-commons/lib/billing/Transaction'
import PayoutMethod from '@taxfyle/web-commons/lib/billing/PayoutMethod'
import ConfirmDialogState from '@taxfyle/web-commons/lib/components/ConfirmDialog/ConfirmDialogState'
import { task } from 'mobx-task'
import memoize from 'memoizee'
import env from '../misc/env'
import { extractMessageFromError } from 'utils/errorUtil'

export default class BillingStore extends Store {
  billingProfiles = this.collection({
    model: BillingProfile,
  })

  billingMethods = this.collection({
    model: BillingMethod,
  })

  payoutMethods = this.collection({
    model: PayoutMethod,
  })

  transactions = this.collection({
    model: Transaction,
  })

  constructor() {
    super(...arguments)

    this.deleteBillingMethodDialog = new ConfirmDialogState()
    this.deletePayoutMethodDialog = new ConfirmDialogState()

    this.fetchBillingProfile = this.fetchBillingProfile.wrap((fn) =>
      memoize(fn, { promise: true, length: 2 })
    )
    this.fetchBillingProfileForTeam = this.fetchBillingProfileForTeam.wrap(
      (fn) => memoize(fn, { promise: true, length: 1 })
    )
    this.rootStore.sessionStore.onWorkspaceSelected(async () => {
      this.fetchBillingProfile.clear()
      this.billingProfiles.clear()
      this.billingMethods.clear()
      this.payoutMethods.clear()
    })
  }

  transactionsForJob(ids) {
    ids = ids.filter((x) => !isNaN(x))
    return this.transactions.filter((x) => ids.includes(x.id))
  }

  transactionsForBillingMethod(id) {
    return this.transactions.filter((x) => x.billingMethodId === id)
  }

  individualBillingProfile(userId) {
    return this.billingProfiles.find(
      (x) =>
        x.workspaceId === this.rootStore.sessionStore.workspace.id &&
        x.userId === userId
    )
  }

  teamBillingProfiles(userPublicId) {
    const teamMembers = this.rootStore.teamMemberStore.teamMembersForWorkspaceMemberByPublicId(
      this.rootStore.sessionStore.workspace.id,
      userPublicId
    )
    const teamIds = teamMembers.map((tm) => tm.teamId)
    return this.billingProfiles.filter((x) => teamIds.includes(x.teamId))
  }

  teamBillingProfile(teamId) {
    return this.billingProfiles.find((x) => x.teamId === teamId)
  }

  billingMethodsForTeam(teamId) {
    const profile = this.teamBillingProfile(teamId)
    return profile ? profile.billingMethods.slice() : []
  }

  @computed
  get busy() {
    return (
      this.changeDefaultBillingMethod.pending ||
      this.fetchBillingProfile.pending ||
      this.fetchBillingProfileForTeam.pending ||
      this.addBillingMethodForStripeToken.pending ||
      this.deleteBillingMethod.pending ||
      this.addPayoutMethod.pending ||
      this.deletePayoutMethod.pending ||
      this.showStripeCheckout.pending ||
      this.depositCredit.pending ||
      this.adjustCredit.pending ||
      this.updateBillingMethod.pending
    )
  }

  get billingAPI() {
    return this.rootStore.api.billing
  }

  @task
  async activate(opts = {}) {
    if (opts.teamId) {
      return this.fetchBillingProfileForTeam(opts.teamId)
    }
  }

  /**
   * Fetches all billing profiles for this user.
   */
  async fetchAllBillingProfiles(userId) {
    const individualPromise = this.fetchBillingProfile(
      this.rootStore.sessionStore.workspace.id,
      userId
    )
    const teamMembers = await this.rootStore.teamMemberStore.fetchUserTeamMembersByPublicId(
      {
        user_id: userId,
      }
    )
    return Promise.all([
      individualPromise,
      ...teamMembers.map((tm) => this.fetchBillingProfileForTeam(tm.teamId)),
    ])
  }

  @task.resolved
  async fetchBillingMethods(teamId = null) {
    const profile = await (teamId
      ? this.fetchBillingProfileForTeam(teamId)
      : this.fetchBillingProfile())
    return profile.billingMethods.slice()
  }

  @task.resolved
  fetchBillingProfile(
    workspaceId = this.rootStore.sessionStore.workspace.id,
    userId = this.rootStore.sessionStore.user.public_id
  ) {
    return (
      this.billingAPI
        .getMemberBillingProfile(workspaceId, userId)
        .then(this.billingProfiles.set)
        // ignore the error if there is no billing profile
        .catch((e) => {
          // ignore the error
        })
    )
  }

  @task.resolved
  fetchTransactions(ids = []) {
    ids = ids.filter((x) => !isNaN(x))
    if (ids.length === 0) {
      return
    }
    return this.billingAPI.getTransactionsByIds(ids).then((data) => {
      return this.enhanceData(data.data).then(this.transactions.set)
    })
  }

  @task.resolved
  async getTransactionsForBillingMethod(billingMethodId) {
    return this.billingAPI.getTransactions(billingMethodId).then((data) => {
      return this.enhanceData(data.data).then(this.transactions.set)
    })
  }

  async enhanceData(data) {
    const enhancedData = await Promise.all(
      data.filter(Boolean).map(async (transaction) => {
        if (transaction.metadata.job_id) {
          let job = this.rootStore.jobStore.jobs.get(
            transaction.metadata.job_id
          )
          if (!job) {
            job = await this.rootStore.jobStore.getJob(
              transaction.metadata.job_id
            )

            if (job.ownerTeamId) {
              await this.rootStore.teamStore.fetchTeam(job.ownerTeamId)
            }
          }
        }

        if (transaction.metadata.user_id) {
          await this.rootStore.memberStore.fetchByPublicId({
            user_public_id: transaction.metadata.user_public_id,
            workspace_id: this.rootStore.sessionStore.workspace.id,
          })
        }

        return transaction
      })
    )

    return enhancedData
  }

  @task.resolved
  fetchBillingProfileForTeam(teamId) {
    return this.billingAPI
      .getTeamBillingProfile(teamId)
      .then(this.billingProfiles.set)
  }

  @action.bound
  resetBillingProfiles() {
    this.fetchBillingProfile.clear()
    this.fetchBillingProfileForTeam.clear()
  }

  @task.resolved
  changeDefaultBillingMethod(method, team) {
    const payload = {
      default_billing_method_id: method.id,
    }
    const p = team
      ? this.billingAPI.updateTeamBillingProfile(team.id, payload)
      : this.billingAPI.updateMemberBillingProfile(
          this.individualBillingProfile.workspaceId,
          payload
        )
    return p.then(action(this.billingProfiles.set)).catch((err) => {
      this.rootStore.flashMessageStore.create({
        type: 'error',
        message: `Changing default billing method failed: ${extractMessageFromError(
          err
        )}`,
      })
      console.error(err)
    })
  }

  @task.resolved
  changeDefaultPayoutMethod(method, team) {
    const payload = {
      default_payout_method_id: method.id,
    }
    const p = team
      ? this.billingAPI.updateTeamBillingProfile(team.id, payload)
      : this.billingAPI.updateMemberBillingProfile(
          this.individualBillingProfile.workspaceId,
          payload
        )
    return p.then(action(this.billingProfiles.set)).catch((err) => {
      this.rootStore.flashMessageStore.create({
        type: 'error',
        message: `Changing default payout method failed: ${extractMessageFromError(
          err
        )}`,
      })
      console.error(err)
    })
  }

  @task.resolved
  async showStripeCheckout({ team, ...opts } = {}) {
    const token = await new Promise((resolve) => {
      const handler = (this.handler = checkout({
        token: (token) => {
          this.handler = null
          resolve(token)
        },
        closed: () => {
          this.handler = null
          setTimeout(() => {
            resolve(false)
          }, 100)
        },
      }))

      handler.open({
        currency: 'USD',
        name: this.rootStore.sessionStore.workspace.name,
        description: 'Add Payment Method',
        email: this.rootStore.authStore.user.email,
        panelLabel: 'Add',
        ...opts,
      })
    })

    return this.addBillingMethodForStripeToken(team, token)
  }

  redirectToStripeConnect(team) {
    const ROOT = `${window.location.protocol}//${window.location.host}/callback/stripe-connect`
    const STRIPE_URL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${
      env.STRIPE_CONNECT_ID
    }&scope=read_write&state=${window.btoa(
      JSON.stringify({ teamId: team ? team.id : null })
    )}&redirect_uri=${ROOT}`
    window.location.href = STRIPE_URL
  }

  @task.resolved
  async addBillingMethodForStripeToken(team, token) {
    if (!token) {
      return false
    }
    const profile = team
      ? await this.fetchBillingProfileForTeam(team.id)
      : await this.fetchBillingProfile()
    const payload = {
      type: 'Stripe',
      user_id: profile.userPublicId,
      workspace_id: profile.workspaceId,
      token: token.id,
      team_id: team ? team.id : undefined,
    }
    return this.billingAPI
      .addBillingMethod(payload)
      .then(action(this.billingMethods.set))
      .then((method) =>
        this.changeDefaultBillingMethod(method, team).then(() => method)
      )
  }

  @task.resolved
  async addPayoutMethod(code, team) {
    const profile = team
      ? await this.fetchBillingProfileForTeam(team.id)
      : await this.fetchBillingProfile()
    return this.billingAPI
      .addPayoutMethod({
        type: 'Stripe',
        user_id: profile.userPublicId,
        workspace_id: profile.workspaceId,
        team_id: team ? team.id : undefined,
        code: code,
      })
      .then(action(this.payoutMethods.set))
  }

  @task.resolved
  async deleteBillingMethod(method) {
    if (method.default) {
      return
    }

    if (!(await this.deleteBillingMethodDialog.show())) {
      return
    }

    return this.billingAPI
      .removeBillingMethod(method.id)
      .then(action(() => this.billingMethods.remove(method)))
  }

  @task.resolved
  async deletePayoutMethod(method) {
    if (method.default) {
      return
    }

    if (!(await this.deletePayoutMethodDialog.show())) {
      return
    }

    return this.billingAPI
      .removePayoutMethod(method.id)
      .then(action(() => this.payoutMethods.remove(method)))
  }

  closeCheckout() {
    if (this.handler) {
      this.handler.close()
    }
  }

  @task.resolved
  async addCreditMethod(data) {
    await this.billingAPI
      .addBillingMethod(data)
      .then(action(this.billingMethods.set))
  }

  @task.resolved
  async depositCredit(billingMethodId, data) {
    await this.billingAPI
      .depositCredit(billingMethodId, data)
      .then(this.billingMethods.set)
  }

  @task.resolved
  async adjustCredit(billingMethodId, data) {
    await this.billingAPI
      .adjustCredit(billingMethodId, data)
      .then(this.billingMethods.set)
  }

  @task.resolved
  async updateBillingMethod(billingMethodId, data) {
    await this.billingAPI
      .updateBillingMethod(billingMethodId, data)
      .then(this.billingMethods.set)
  }
}

/**
 * We're lazy-evaling because we're loading Stripe asynchronously.
 */
function checkout(opts) {
  return window.StripeCheckout.configure({
    ...opts,
    key: env.STRIPE_PK,
    image: require('assets/img/taxfyle-icon-256px.png'),
    locale: 'auto',
    billingAddress: true,
    zipCode: true,
  })
}
