import { APIClient } from '@taxfyle/web-commons/lib/misc/APIClient'
import IO from 'socket.io-client'
import memoize from 'memoizee'

/**
 * Creates a Legend client.
 *
 * @param {*} opts
 */
export function createLegendClient(opts) {
  const http = new LegendsAPI(opts)
  const sock = new LegendSocket(opts)
  const bind = (target, method) => target[method].bind(target)
  const setup = (target, methods) =>
    methods.reduce((accum, method) => {
      accum[method] = bind(target, method)
      return accum
    }, {})

  return {
    ...setup(http, [
      'find',
      'findAdmin',
      'create',
      'save',
      'patch',
      'getVersions',
      'getVersion',
      'publish',
      'activate',
      'deactivate',
      'compute',
    ]),
    ...setup(sock, [
      'create',
      'save',
      'patch',
      'getVersion',
      'publish',
      'compute',
    ]),
  }
}

/**
 * Legends HTTP API.
 */
export class LegendsAPI extends APIClient {
  find({ workspace_id, ...query }) {
    return this.client
      .get(`/v3/workspaces/${workspace_id}/legends`, { params: query })
      .then(this._data)
  }

  findAdmin({ workspace_id, ...query }) {
    return this.client
      .get(`/v3/workspaces/${workspace_id}/admin-legends`, { params: query })
      .then(this._data)
  }

  create(payload, params) {
    return this.client.post(`/v3/legends`, payload, { params }).then(this._data)
  }

  save(payload) {
    return this.client
      .put(`/v3/legends/${payload.id}/versions/${payload.version}`, payload)
      .then(this._data)
  }

  patch(payload) {
    return this.client
      .patch(`/v3/legends/${payload.id}/versions/${payload.version}`, payload)
      .then(this._data)
  }

  getVersions(id, params) {
    return this.client
      .get(`/v3/legends/${id}/versions`, { params })
      .then(this._data)
  }

  getVersion(id, version, params) {
    return this.client
      .get(`/v3/legends/${id}${version ? `/versions/${version}` : ''}`, {
        params,
      })
      .then(this._data)
  }

  publish({ id, version, ...payload }, params) {
    return this.client
      .post(`/v3/legends/${id}/versions/${version}/publish`, payload, {
        params,
      })
      .then(this._data)
  }

  activate(id) {
    return this.client.post(`/v3/legends/${id}/activate`).then(this._data)
  }

  deactivate(id) {
    return this.client.post(`/v3/legends/${id}/deactivate`).then(this._data)
  }

  compute(id, version, payload) {
    return this.client
      .post(`/v3/legends/${id}/versions/${version}/compute`, payload)
      .then(this._data)
  }
}

/**
 * The Legend Socket connection.
 *
 * Supports a subset of the API.
 */
export class LegendSocket {
  /**
   * Constructs the socket.
   */
  constructor({ baseURL, getToken }) {
    this.baseURL = baseURL.startsWith('/')
      ? `${window.location.origin}${baseURL}`
      : baseURL
    // Memoize the client per token.
    const getClient = memoize(this.getClient.bind(this), {
      max: 1,
      promise: true,
    })
    this.clearClientCache = () => getClient.clear()
    this.getClient = () => getClient(getToken())
  }

  create(payload, params) {
    return this.send({ type: 'CreateLegend', payload, ...params })
  }

  save(payload) {
    return this.send({ type: 'SaveLegend', payload })
  }

  patch(payload) {
    return this.send({ type: 'PatchLegend', payload })
  }

  getVersion(id, version, params) {
    return this.send({
      type: 'GetLegend',
      payload: { id, version },
      ...params,
    })
  }

  publish(payload, params) {
    return this.send({
      type: 'PublishLegend',
      payload,
      ...params,
    })
  }

  compute(id, version, payload) {
    return this.send({
      type: 'EngineCompute',
      payload: { id, version, ...payload },
    })
  }

  /**
   * Connects to the Legend Socket server.
   *
   * @param {*} token
   */
  async getClient(token) {
    return new Promise((resolve, reject) => {
      const url = new URL(this.baseURL)
      const pathname = url.pathname.endsWith('/')
        ? url.pathname.slice(0, -1)
        : url.pathname
      const io = IO(url.origin, {
        path: `${pathname}/socket.io`,
        transports: ['websocket'],
      })

      io.once('connect', () => {
        io.emit('authenticate', { token }, (result) => {
          if (result.success) {
            return resolve(io)
          }

          reject(toError(result, 'Failed to authenticate: '))
        })
      })
    })
  }

  /**
   * Sends the specified command
   *
   * @param command The command to send
   * @param ct Optional cancellation token
   */
  async send(command, ct) {
    const client = await this.getClient()
    const sendPromise = new Promise((resolve, reject) => {
      return client.emit('Command', command, (result) => {
        if (result.type === 'Success') {
          return resolve(result.result)
        }

        return reject(toError(result.error))
      })
    })

    const result = await Promise.race([
      sendPromise,
      new Promise((resolve) => setTimeout(resolve, 10000)).then(
        () => 'timed out'
      ),
    ])
    if (result === 'timed out') {
      this.clearClientCache()
      if (!ct?.isCancelled) {
        console.warn(
          'Timed out while sending command to Legend API. Trying again.',
          command
        )
        return this.send(command)
      }
      return null
    }
    return result
  }
}

/**
 * Creates an error from a socket response.
 *
 * @param {*} socketResponse
 */
function toError(socketResponse, prefix = '') {
  const error = new Error(prefix + socketResponse.message)
  error.response = socketErrorToResponse(socketResponse)
  return error
}

/**
 * Converts a socket error to a response object.
 * @param {*} err
 */
function socketErrorToResponse(err) {
  return {
    status: err.statusCode || 0,
    data: err,
  }
}
