/**
 * Factory function that returns a filter for the specified job.
 *
 * Users that are **not** members of the job will pass through the filter,
 * while users that **are** members of the job will be filtered out.
 */
export function filterOutAllMembersOf(job) {
  return (user) => !membersOf(job).includes(user.userId)
}

/**
 * Factory function that returns a filter for the specified job.
 *
 * Users that are **not** clients of the job will pass through the filter,
 * while users that **are** clients of the job will be filtered out.
 */
export function filterOutAllClientsOf(job) {
  return (user) => !clientsOf(job).includes(user.userId)
}

/**
 * Factory function that returns a filter for the specified ID array whitelist.
 *
 * Users who do **not** have IDs in the list will pass through the filter,
 * while users that **do have** IDs in the list will be filtered out.
 */
export function filterOutAllIds(ids) {
  return (user) => !ids.includes(user.userId)
}

/**
 *  Returns the IDs of all members on the job.
 */
function membersOf(job) {
  return job.members.map((m) => m.user.userId)
}

/**
 * Returns the IDs of all clients on the job.
 */
function clientsOf(job) {
  return job.members
    .filter((m) => m.type === 'CLIENT')
    .map((m) => m.user.userId)
}
