import { action, computed, observable } from 'mobx'
import { snapshotable } from '../data/serialization'
import RoutingContextSourceEditor from './routing-context-sources/RoutingContextSourceEditor'
import { validatable } from '../mixins/validation'
import { validateElements } from '../../utils/validx-validators'
import { receiveErrors } from '../data/serverErrors'

/**
 * Routing context sources builder.
 */
@validatable()
@snapshotable
export default class RoutingContextSourcesBuilder {
  @observable sources = []

  @observable selectedEditor = null

  rules = {
    sources: [validateElements('There are invalid routing sources')],
  }

  constructor({ legendEditor }) {
    this.legendEditor = legendEditor
  }

  /**
   * Entity descriptors.
   */
  @computed
  get entityDescriptors() {
    return this.sources.flatMap((s) => s.entityDescriptors ?? [])
  }

  /**
   * Whether the editor is disabled.
   * @returns {boolean}
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Whether we can add another UserWorkRequestCount.
   * @returns {boolean}
   */
  @computed
  get canAddUserWorkRequestCount() {
    return !this.sources.some((s) => s.type === 'UserWorkRequestCount')
  }

  @action.bound
  addSource(data) {
    const editor = RoutingContextSourceEditor.create(data, this._sourceOpts())
    this.sources.push(editor)
    this.edit(editor)
  }

  hydrate(sources = []) {
    this.sources.replace(
      sources.map((s) =>
        RoutingContextSourceEditor.fromJS(s, this._sourceOpts())
      )
    )
  }

  toJSON() {
    return this.sources.map((s) => s.toJSON())
  }

  @action.bound
  edit(editor) {
    this.selectedEditor = editor
  }

  @action.bound
  remove(editor) {
    this.sources.remove(editor)
    if (this.selectedEditor === editor) {
      this.edit(null)
    }
  }

  receiveErrors(descriptor) {
    receiveErrors(this.sources, descriptor?.inner)
  }

  _sourceOpts() {
    return { legendEditor: this.legendEditor }
  }
}
