import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import Icon from 'components/Icon'
import styles from './PermissionsEditor.m.sass'

@observer
export default class PermissionsEditor extends React.Component {
  state = { searchText: '' }

  changeHandler = (id, setting, e) => {
    const current = this.props.value || {}
    const checked = e.target.checked
    const result = {
      ...current,
      [id]: checked ? setting : null,
    }

    this.props.availablePermissions.forEach((p) => {
      result[p.id] = result[p.id] || null
    })

    this.props.onChange(result)
  }

  toggleAllowAll = toggleAll.bind(this, 'allow')
  toggleDenyAll = toggleAll.bind(this, 'deny')

  changeSearchText = (e) => {
    const text = e.target.value
    this.setState((prev, props) => {
      return {
        ...prev,
        searchText: text,
      }
    })
  }

  render() {
    const { value = {}, availablePermissions, disabled, readonly } = this.props
    const combined = availablePermissions.map((p) => ({
      ...p,
      value: value[p.id] || null,
    }))
    const filtered = combined.filter((p) =>
      matchesSearch(p, this.state.searchText)
    )

    return (
      <div className={cx(styles.root)}>
        <div className={styles.searchBar}>
          <input
            type="search"
            className={styles.searchInput}
            value={this.state.searchText}
            onChange={this.changeSearchText}
            placeholder="Type to search..."
          />
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Permission</th>
                <th>
                  {!readonly && (
                    <input
                      disabled={disabled}
                      type="checkbox"
                      checked={combined.every((p) => p.value === 'allow')}
                      onChange={this.toggleAllowAll}
                    />
                  )}{' '}
                  Allow
                </th>
                <th>
                  {!readonly && (
                    <input
                      disabled={disabled}
                      type="checkbox"
                      checked={combined.every((p) => p.value === 'deny')}
                      onChange={this.toggleDenyAll}
                    />
                  )}{' '}
                  Deny
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((p) => (
                <tr key={p.id}>
                  <td>
                    <p
                      className={styles.permissionName}
                      title={`Permission identifier: ${p.id}`}
                    >
                      {p.name}
                    </p>
                    <p className={styles.permissionDescription}>
                      {p.description}
                    </p>
                  </td>
                  {readonly
                    ? renderCheckIcon(p, 'allow')
                    : renderCheckbox(p, 'allow', this.changeHandler, disabled)}
                  {readonly
                    ? renderCheckIcon(p, 'deny')
                    : renderCheckbox(p, 'deny', this.changeHandler, disabled)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

function matchesSearch(permission, searchText) {
  if (!searchText) {
    return true
  }

  searchText = searchText.toLowerCase()
  return [permission.name, permission.description, permission.id].some(
    (str) => str.toLowerCase().indexOf(searchText) > -1
  )
}

function toggleAll(setting) {
  const combined = combinePermissions(
    this.props.availablePermissions,
    this.props.value
  )
  const newPerms = combined.every((p) => p.value === setting)
    ? setAll(this.props.availablePermissions, null)
    : setAll(this.props.availablePermissions, setting)

  this.props.onChange(newPerms)
}

function setAll(availablePermissions, setting) {
  return availablePermissions.reduce((result, p) => {
    result[p.id] = setting
    return result
  }, {})
}

function combinePermissions(availablePermissions, value) {
  return availablePermissions.map((p) => ({
    ...p,
    value: value[p.id] || null,
  }))
}

function renderCheckbox(permission, setting, changeHandler, disabled) {
  return (
    <td>
      <input
        disabled={disabled}
        type="checkbox"
        checked={permission.value === setting}
        onChange={changeHandler.bind(null, permission.id, setting)}
        data-test={`${permission.name}-${setting}`}
      />
    </td>
  )
}

function renderCheckIcon(permission, setting) {
  return (
    <td data-test={`${permission.name}-${setting}-readonly`}>
      {permission.value === setting && <Icon material name="check" />}
    </td>
  )
}
