import React from 'react'
import { Helmet } from 'react-helmet-async'
import cx from 'classnames'
import { browserHistory } from 'react-router'
import { observer } from 'mobx-react'
import styles from './Welcome.m.sass'
import link from '../../misc/link'

export default observer(function Welcome({ rootStore, router }) {
  React.useEffect(() => {
    const canViewJobs = rootStore.sessionStore.member.hasPermission(
      'ADMIN_JOB_LIST'
    )
    if (canViewJobs) {
      browserHistory.replace(
        link.jobs(rootStore.sessionStore.member.workspace.slug)
      )
    }
  }, [rootStore])
  return (
    <div className={cx(styles.root)}>
      <Helmet title="Welcome to Worklayer HQ!" />
      <h1 className={styles.title}>Welcome to Worklayer HQ</h1>
      <div className={styles.content}>
        <p>Use the navigation bar on the left to look around.</p>
      </div>
    </div>
  )
})
