import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ErrorLabel from 'components/ErrorLabel'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'

import { Input } from 'components/Form'
import styles from './CloneJobDialog.m.sass'

@observer
export default class CloneJobDialog extends React.Component {
  renderDialog() {
    const { store } = this.props
    if (!store.job) return null

    return (
      <div className={styles.cloneJobDialog}>
        <DialogTitle>Clone this Job</DialogTitle>
        <DialogContent>
          <Input
            model={store}
            field="amount"
            label="Amount"
            help="How many times do you want to clone this job?"
          />
          <ErrorLabel>{store.error}</ErrorLabel>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide}>Close</DialogButton>
          <DialogButton primary onClick={store.cloneJob.bind(store)}>
            Clone
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        className="invoice-modal"
        position="top"
      >
        <Dialog>
          {store.cloneJob.match({
            pending: () => <ContentLoading small />,
            rejected: (err) => <ContentError error={err} />,
            resolved: () => this.renderDialog(),
          })}
        </Dialog>
      </Modal>
    )
  }
}
