import { Model } from 'libx'
import { observable, computed } from 'mobx'
import uniqueId from 'lodash/uniqueId'
import TasksBuilder from './TasksBuilder'
import { validatable } from 'legend-builder/mixins/validation'
import { validateObject } from 'utils/validx-validators'
import { required } from 'validx'
import * as FilterScope from 'legend-builder/consts/FilterScope'
import FiltersBuilder from 'legend-builder/filters/FiltersBuilder'
import {
  serializable,
  serialize,
  snapshotable,
} from 'legend-builder/data/serialization'
import { receiveErrors } from 'legend-builder/data/serverErrors'

/**
 * Milestone editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class MilestoneEditor extends Model {
  cid = uniqueId('milestone-')

  @observable
  @serializable
  title = ''

  @observable
  @serializable
  description = ''

  @serializable
  tasks

  @serializable
  triggerWhen

  rules = {
    title: [required('Title is required')],
    triggerWhen: [validateObject('There are invalid conditions')],
    tasks: [validateObject('There are invalid tasks')],
  }

  constructor(attrs, opts) {
    super({})
    this.legendEditor = opts.legendEditor
    this.milestonesBuilder = opts.milestonesBuilder
    this.triggerWhen = new FiltersBuilder({
      legendEditor: this.legendEditor,
      context: this.milestonesBuilder,
      scope: [FilterScope.SUBMITTED],
    })
    this.tasks = new TasksBuilder(this.legendEditor, this.milestonesBuilder)
    this.set(attrs, opts)
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  @computed
  get tabValidations() {
    return {
      GENERAL:
        !this.validation.getError('title') &&
        !this.validation.getError('description'),
      CONDITIONS:
        !this.validation.getError('triggerWhen') &&
        this.triggerWhen.validation.isValid,
    }
  }

  parse({ triggerWhen, tasks, ...rest }) {
    this.tasks.hydrate(tasks)
    this.triggerWhen.hydrate(triggerWhen)
    return rest
  }

  toJSON() {
    return {
      id: this.id || this.legendEditor.newId(this.cid, this.title),
      ...serialize(this),
    }
  }

  receiveErrors(descriptor = {}) {
    receiveErrors(this, descriptor.inner)
  }

  _taskOpts() {
    return { legendEditor: this.legendEditor }
  }
}

/**
 * Creates a new milestone editor.
 */
MilestoneEditor.create = function create(data, opts) {
  return new MilestoneEditor(data, opts)
}

/**
 * Creates a milestone editor and parses the payload.
 */
MilestoneEditor.fromJS = function fromJS(data, opts) {
  return MilestoneEditor.create(data, {
    parse: true,
    stripUndefined: true,
    ...opts,
  })
}
