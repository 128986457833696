import React from 'react'
import Modal from 'components/Modal'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import ConfirmDialog from 'components/ConfirmDialog'
import { getUnitFormatter } from 'utils/unitFormatter'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import ListSearchBar from 'components/ListSearchBar'
import styles from 'AddCouponToJob.m.sass'

const AddCouponToJobDialog = observer(function AddCouponToJobDialog({ state }) {
  return (
    <Modal isOpened={state.showing} onRequestClose={state.hide} position="top">
      <Dialog>
        {state.init.match({
          pending: () => <ContentLoading small />,
          rejected: (err) => <ContentError error={err} />,
          resolved: () => <CouponList state={state} />,
        })}
      </Dialog>
      <ConfirmDialog
        state={state.addCouponConfirm}
        title="Add Coupon"
        yes="Add"
        danger
        data-test="apply-coupon"
      >
        Do you really want to add this coupon? This action cannot be undone.{' '}
        <strong>I hope you know what you're doing.</strong>
        <br />
        <br />
      </ConfirmDialog>
    </Modal>
  )
})

const CouponItem = observer(function CouponItem({ coupon, state }) {
  const formatUnit = getUnitFormatter(state.job.legend.priceUnit)
  const { offType, maxOff } = coupon
  const amountOffType = offType.type === 'AMOUNT_OFF'
  return (
    <a
      className={styles.item}
      onClick={(e) =>
        e.preventDefault() ||
        (!state.selectCoupon.pending && state.selectCoupon(coupon))
      }
    >
      <div
        className={styles.itemInner}
        data-test={`coupon-item-${coupon.code}`}
      >
        <div className={styles.text}>
          <h3>{coupon.code}</h3>
          <p>{coupon.description}</p>
          {coupon.dateExpires && (
            <p className={styles.expiration}>
              expires {coupon.dateExpires.format('LL LT')}
            </p>
          )}
          {maxOff && amountOffType && maxOff.percentOff && (
            <p className={styles.disclosure}>
              Maximum savings of {maxOff.percentOff}%
            </p>
          )}
          {maxOff && !amountOffType && maxOff.amountOff && (
            <p className={styles.disclosure}>
              Maximum savings of {formatUnit(maxOff.amountOff)}
            </p>
          )}
        </div>
        <div className={styles.info}>
          <p className={styles.value}>
            {coupon.expired
              ? `EXPIRED`
              : coupon.offType.type === 'AMOUNT_OFF'
              ? `${formatUnit(coupon.offType.amountOff)} off`
              : `${coupon.offType.percentOff}% off`}
          </p>
        </div>
      </div>
    </a>
  )
})

const WorkspaceCoupons = observer(function WorkspaceCoupons({ state }) {
  return (
    <>
      <ListSearchBar
        value={state.searchText}
        onChange={action((e) => {
          state.searchText = e.target.value
        })}
        placeholder={'Search for coupon by code or name...'}
        data-test="list-search-bar"
      />
      <div className={styles.contentContainer}>
        {state.coupons.map((coupon, key) => {
          return (
            <div key={key}>
              <CouponItem coupon={coupon} state={state} />
            </div>
          )
        })}
        <InfiniteScrollZone scrollProvider={state.scrollProvider}>
          {({ isDone }) => (isDone ? null : <ContentLoading smaller />)}
        </InfiniteScrollZone>
      </div>
    </>
  )
})

const CouponList = observer(function CouponList({ state }) {
  return (
    <>
      <DialogTitle>Add Coupon To Job</DialogTitle>
      <DialogContent>
        <WorkspaceCoupons state={state} />
      </DialogContent>
      <DialogFooter>
        <DialogButton onClick={() => state.hide()}>Close</DialogButton>
      </DialogFooter>
    </>
  )
})

export default AddCouponToJobDialog
