import { Store } from 'libx'
import { task } from 'mobx-task'
import { computed, action, observable } from 'mobx'
import { makeMomentSorter } from '@taxfyle/web-commons/lib/utils/arrayUtil'
import ConfirmDialogState from 'components/ConfirmDialog/ConfirmDialogState'
import MessageBankReplyEditorStore from './MessageBankReplyEditorStore'

export default class MessageBankScreenStore extends Store {
  @observable
  replyToDelete = null

  @observable _editingVMs = new Map()
  @observable newItemVM = this._createEditorVM()

  constructor() {
    super(...arguments)
    this.confirmDelete = new ConfirmDialogState()

    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.activate.reset()
    })
  }

  get messageBankStore() {
    return this.rootStore.messageBankStore
  }

  @computed
  get editingVMs() {
    return this._editingVMs
  }

  @computed
  get workspaceId() {
    return this.rootStore.sessionStore.workspace.id
  }

  @computed
  get hasMessageBank() {
    return this.messageBankStore.getForWorkspace(this.workspaceId) != null
  }

  @computed
  get messageBankId() {
    const messageBank = this.messageBankStore.getForWorkspace(this.workspaceId)
    return messageBank ? messageBank.id : null
  }

  @computed
  get replies() {
    const data = this.messageBankStore
      .getForWorkspace(this.workspaceId)
      .replies.slice()

    return data.sort(makeMomentSorter('desc', (x) => x.dateCreated))
  }

  @task.resolved
  async onEmptyScreenAddReply() {
    await this.messageBankStore.createForWorkspace(this.workspaceId)
    this.newItemVM.startEditing()
  }

  @task
  activate() {
    return this._fetchMessageBankReplies()
  }

  @action.bound
  create() {
    this.newItemVM.startEditing()
  }

  _createEditorVM(reply = null) {
    return new MessageBankReplyEditorStore(
      this.messageBankStore,
      () => this.messageBankId,
      this.rootStore.flashMessageStore,
      reply
    )
  }

  /**
   * Creates and caches an editing VM
   * from the given reply and shows it.
   *
   * @param {*} reply
   */
  @action.bound
  startEditing(reply) {
    let existingEditingVM = this._editingVMs.get(reply.id)
    if (!existingEditingVM) {
      const editingVM = this._createEditorVM(reply)
      this._editingVMs.set(reply.id, editingVM)
      existingEditingVM = editingVM
    }
    existingEditingVM.startEditing()
  }

  isEditingReply(reply) {
    return this.editingVMs.get(reply.id)?.showing ?? false
  }

  getEditingVMFor(reply) {
    return this.editingVMs.get(reply.id)
  }

  @action.bound
  delete(reply) {
    this.replyToDelete = reply
    return this.confirmDelete.show().then((shouldDelete) => {
      if (!shouldDelete) {
        return
      }

      return this.messageBankStore.deleteReply(this.messageBankId, reply.id)
    })
  }

  @action.bound
  async _fetchMessageBankReplies() {
    const messageBank = await this._getMessageBankForWorkspace()

    if (messageBank) {
      await this.messageBankStore.fetchMessageBankReplies(messageBank.id)
    }
  }

  @action.bound
  async _getMessageBankForWorkspace() {
    const messageBank = await this.messageBankStore
      .fetchWorkspaceMessageBank(this.workspaceId)
      .catch((err) => {
        // If the message bank doesn't exist
        // we want the user bootrap creation of one
        if (err.response.status === 404) {
          return null
        } else {
          throw err
        }
      })
    return messageBank
  }
}
