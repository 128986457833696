import { observable, action } from 'mobx'
import { Model } from 'libx'
import { required } from 'validx'
import uniqueId from 'lodash/uniqueId'
import { validatable } from 'legend-builder/mixins/validation'
import {
  serialize,
  serializable,
  snapshotable,
} from 'legend-builder/data/serialization'
import { validateObject } from 'utils/validx-validators'

/**
 * Used for Select questions.
 */
@snapshotable
@validatable({ liveValidation: true, liveIgnore: ['id'] })
export default class DocumentOption extends Model {
  cid = uniqueId('select-option-')

  @serializable
  id

  @observable
  @serializable
  text = ''

  @serializable
  triggerWhen

  @serializable({ name: 'document_tags' })
  @observable
  documentTags = []

  constructor(attrs, opts) {
    super()
    this.id = uniqueId('DOCUMENT_OPTION_')
    this.question = opts.question
    this.triggerWhen = this.question.createFiltersBuilder()
    this.set(attrs, opts)
  }

  rules = {
    text: [required('Text is required')],
    triggerWhen: [validateObject('Invalid conditions')],
    documentTags: [required('Document tags is required.')],
  }

  parse(json, opts) {
    const { triggerWhen, document_tags, ...attrs } = json || {}
    this.triggerWhen.hydrate(triggerWhen)
    return {
      documentTags: document_tags || [],
      ...attrs,
    }
  }

  toJSON() {
    return {
      ...serialize(this),
    }
  }

  @action.bound
  setDocumentTags(value) {
    this.documentTags = value
  }
}
