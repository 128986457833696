import { observable } from 'mobx'
import { Store } from 'libx'
import { ListInventoryItemsRequest } from '@taxfyle/api-internal/internal/inventory_item_pb'

export default class InventoryItemStore extends Store {
  @observable workspaceInventoryItems = []

  async fetchWorkspaceInventoryItems(workspaceId) {
    const request = new ListInventoryItemsRequest()
      .setWorkspaceId(workspaceId)
      .setFilter('archived=ACTIVE')
      .setPageSize(1000)
    const itemsResult = (
      await this.rootStore.api.inventoryItems.listInventoryItems(request)
    ).toObject()
    this.workspaceInventoryItems = itemsResult.inventoryItemsList
  }
}
