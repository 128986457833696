import { observable, action, computed } from 'mobx'
import identity from 'lodash/identity'
import { required } from 'validx'
import { move } from 'utils/mobx-util'
import DocumentOption from './DocumentOption'
import QuestionEditor from '../../QuestionEditor'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import { addGenericErrorForProperty } from '../../../data/serverErrors'

@snapshotable
export default class DocumentsQuestionEditor extends QuestionEditor {
  static friendlyType = 'Documents'
  static type = 'Documents'
  static viewOrder = 6
  static iconColor = '#0967B9'

  @observable
  @serializable
  question = 'New Documents Question'

  @observable
  @serializable
  description = ''

  @observable
  @serializable((v) => v.map((o) => o.toJSON()))
  options = []

  rules = {
    question: [required('Question is required')],
    options: [this._validateOptions.bind(this)],
  }

  /**
   * Used by the Conditions tab to render a filters builder for each select option in addition to the question conditions.
   */
  @computed
  get triggerWhenDescriptors() {
    return this.options.map((o) => ({
      id: o.id,
      title: o.text || o.id || '(untitled)',
      triggerWhen: o.triggerWhen,
    }))
  }

  @action.bound
  addOption() {
    this.options.push(new DocumentOption({}, { question: this }))
  }

  @action.bound
  removeOption(option) {
    this.options.remove(option)
  }

  @action.bound
  moveByIndex(oldIndex, newIndex) {
    move(this.options, oldIndex, newIndex)
  }

  parse({ options, ...attrs }, opts) {
    return {
      ...super.parse(attrs, opts),
      options: options
        ? options.map(
            (x) => new DocumentOption(x, { parse: true, question: this })
          )
        : undefined,
    }
  }

  /**
   * Adds a generic error about options if present.
   * @param {*} descriptor
   */
  receiveErrors(descriptor) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'options',
      'There are invalid options'
    )
    super.receiveErrors(descriptor)
  }

  _validateOptions() {
    const valid = this.options.map((o) => o.validateAll()).every(identity)
    return valid || 'One or more options are invalid'
  }
}
