import { useDropzone } from '@taxfyle/web-commons/lib/hooks/use-dropzone'
import cx from 'classnames'
import Button from 'components/Button'
import EmptyState from 'components/EmptyState'
import FileButton from 'components/FileButton'
import Icon from 'components/Icon'
import ScrollPanel from 'components/ScrollPanel'
import {
  Widget,
  WidgetContent,
  WidgetHeader,
  WidgetHeaderSegment,
  WidgetTitle,
} from 'components/Widget'
import DocumentPicker from 'documents/components/DocumentPicker'
import DocumentList from 'documents/components/DocumentList'
import { observer } from 'mobx-react'
import React, { useState, useRef, useCallback } from 'react'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import { Dropdown, DropdownItem, DropdownItemIcon } from 'components/Dropdown'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

import './JobDocuments.sass'

/**
 * Documents Widget
 *
 * @param  {JobDetailsStore} props.store
 */
const JobDocuments = ({
  jobDetailsStore,
  documents,
  scrollProvider,
  documentStore,
  loading,
  job,
  stageFiles,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const outsideRef = useRef(null)
  const toggle = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen])
  const close = useCallback(() => setIsOpen(false), [setIsOpen])
  const onButtonClick = toggle

  const [{ isOver }, dropTargetRef] = useDropzone((files) =>
    stageFiles(Array.from(files))
  )
  const userDocumentScrollProvider = documentStore.userDocumentsScrollProviderForUserId(
    job.client.userPublicId
  )

  const downloadDocuments = () => {
    if (jobDetailsStore.checkedDocuments.length === 0) {
      jobDetailsStore.downloadAll()
    } else {
      jobDetailsStore.downloadCheckedDocuments()
    }

    close()
  }

  useOnClickOutside(outsideRef, close)

  return (
    <Widget>
      <WidgetHeader autoCollapse>
        {<WidgetTitle>Documents</WidgetTitle>}
        {!job.completed && (
          <WidgetHeaderSegment>
            <FileButton
              onChange={(files) => stageFiles(Array.from(files))}
              multiple
              data-test="upload-document-button"
            >
              <Button
                size="normal"
                type="primary"
                iconLeft={<Icon material size={14} name="cloud upload" />}
              >
                Upload
              </Button>
            </FileButton>

            <div className="dropdown-container" ref={outsideRef}>
              <Dropdown
                position="right"
                isOpen={isOpen}
                onButtonClick={onButtonClick}
                button={({ className, innerRef, ...props }) => (
                  <a
                    ref={innerRef}
                    className={cx(className, 'button is-icon-only')}
                    {...props}
                  >
                    <Icon material name="more horiz" />
                  </a>
                )}
              >
                <DropdownItem>
                  <DropdownItemIcon>
                    <Icon material name="link" />
                  </DropdownItemIcon>
                  <DocumentPicker
                    userId={job.client.userId}
                    position="right"
                    documentStore={documentStore}
                    emptyText="If you uploaded documents to other jobs (or in the All Documents section) you can link them to this job."
                    searchPlaceholder="Search for existing documents to link to this job..."
                    disabled={jobDetailsStore.linkDocument.pending}
                    closeOnSelect={false}
                    scrollProvider={userDocumentScrollProvider}
                    // Filters out docs already linked.
                    filter={(doc) => job.documents.indexOf(doc) === -1}
                    onDocumentSelect={(document) => {
                      jobDetailsStore.linkDocument(document)
                      close()
                    }}
                    button={(buttonProps) => {
                      const { onClick } = buttonProps
                      return (
                        <span
                          data-test="link-existing-document-button"
                          onClick={onClick}
                        >
                          Link Existing Documents
                        </span>
                      )
                    }}
                  />
                </DropdownItem>
                <DropdownItem onClick={downloadDocuments}>
                  <DropdownItemIcon>
                    <Icon material name="download" />
                  </DropdownItemIcon>
                  {jobDetailsStore.checkedDocuments.length === 0
                    ? 'Download All'
                    : 'Download Checked'}
                </DropdownItem>
              </Dropdown>
            </div>
          </WidgetHeaderSegment>
        )}
      </WidgetHeader>
      <WidgetContent
        loading={loading}
        ref={dropTargetRef}
        className={cx(
          'documents-widget-content',
          'filedrop',
          isOver && 'filedrop-active'
        )}
      >
        {documents.length > 0 ? (
          <ScrollPanel
            maxHeight={500}
            minHeight={300}
            className="job-documents"
          >
            <DocumentList
              emptyText={`This ${T('Web.Common.Job', 'Job')} has no documents.`}
              emptyTitle="Documents"
              documentStore={documentStore}
              scrollProvider={scrollProvider}
              documents={documents}
              useAccessDate
              jobId={job.id}
              onDocumentSelectedChange={jobDetailsStore.updateCheckedDocuments}
            />
          </ScrollPanel>
        ) : (
          <EmptyState
            title="Documents"
            icon={
              !documentStore.rootStore.sessionStore.workspace.corporate && (
                <img
                  src={require('assets/images/empty-documents.svg')}
                  alt="No Documents graphic"
                  style={{ width: 'auto', height: 150 }}
                />
              )
            }
          >
            This job has no documents.
          </EmptyState>
        )}
      </WidgetContent>
    </Widget>
  )
}

export default observer(JobDocuments)
