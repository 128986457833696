import isObject from 'lodash/isObject'
import { isObservableArray, isObservableMap } from 'mobx'
import { createTransformer } from 'mobx-utils'
import { typeToJS } from './Type'

const CONFIGS = new (global.WeakMap || global.Map)()

/**
 * Decorator for marking fields serializable.
 * IMPORTANT: when using with MobX Observable, the order must be `@observable @serializable`.
 *
 * @type {() => any)}
 */
export const serializable = createMethodDecorator(function serializable(
  serializer = null,
  opts = {}
) {
  if (typeof serializer === 'function') {
    opts.serializer = serializer
  } else {
    opts = serializer || {}
  }

  return serializableDecorator

  function serializableDecorator(target, name, descriptor) {
    const cfg = getOrInitConfig(target)
    cfg.fields.push({ name, options: { ...opts } })
    return withDefaultDescriptor(descriptor)
  }
})

/**
 * Ref shortcut. Basically it lets us serialize an ID instead of the graph.
 */
serializable.ref = createMethodDecorator((opts) =>
  serializable({ ref: 'id', ...opts })
)

/**
 * Wraps `toJSON` in a MobX transformer to optimize serialization.
 * Must be applied on the final class, NOT base classes.
 *
 * @param {*} target
 */
export function snapshotable(target) {
  const innerToJSON = target.prototype.toJSON
  const transformer = createTransformer(
    function (that) {
      return innerToJSON.call(that)
    },
    { keepAlive: true }
  )

  target.prototype.toJSON = function transformerBasedToJSON() {
    return transformer(this)
  }

  return target
}

/**
 * Returns a descriptor that, if not specified, is configurable
 * and enumerable.
 */
function withDefaultDescriptor(descriptor) {
  if (descriptor.writable && descriptor.enumerable) {
    return descriptor
  }

  // Makes the property writable if defaults have not been changed.
  // But only if there's no getter.
  if ('get' in descriptor === false) {
    return {
      ...descriptor,
      writable: descriptor.writable === undefined || descriptor.writable,
      enumerable: descriptor.enumerable === undefined || descriptor.enumerable,
    }
  }

  return descriptor
}

/**
 * Gets the config for a target instance.
 * @param {*} target
 */
function getConfig(target) {
  return CONFIGS.get(target)
}

/**
 * Rolls up configs for a prototype chain.
 *
 * @param {Object} target
 * The prototype of a decorated class.
 */
function rollUpConfig(target) {
  const chain = [target]
  let parent = target

  while (parent) {
    parent = Object.getPrototypeOf(parent)
    parent && chain.unshift(parent)
  }

  // Starting from the oldest ancestor, rolls up
  // the configuration so we get fields from the entire chain.
  return chain.reduce(
    (prev, proto) => {
      const cfg = getConfig(proto)
      if (!cfg) {
        return prev
      }

      if (prev) {
        return {
          ...prev,
          ...cfg,
          fields: [...prev.fields, ...cfg.fields],
        }
      }
      return cfg
    },
    { fields: [] }
  )
}

/**
 * Gets or initializes the serializer config for the specified target.
 */
function getOrInitConfig(target) {
  let cfg = getConfig(target)
  if (cfg) {
    return cfg
  }
  cfg = {
    fields: [],
  }
  CONFIGS.set(target, cfg)
  return cfg
}

/**
 * Serializes a MobX-enhanced object, calling toJSON
 * on all possible fields.
 */
export function serialize(data) {
  if (!data) {
    return data
  }

  if (data.map) {
    return data.map(serialize)
  }

  if (!isObject(data)) {
    return data
  }

  const result = {}
  const config = rollUpConfig(Object.getPrototypeOf(data))
  if (config) {
    config.fields.forEach((f) => {
      const val = data[f.name]
      const ref = f.options.ref
      const serializer = f.options.serializer
      const targetName = f.options.name || f.name
      if (serializer) {
        result[targetName] = serializer(val, data, f.name)
      } else if (ref) {
        result[targetName] = (val && val[ref]) || undefined
      } else {
        result[targetName] = serializeValue(val)
      }
    })

    return result
  }

  return data
}

/**
 * Serializes a single value.
 */
export function serializeValue(data) {
  if (!isObject(data)) {
    return data
  }

  if (isObservableArray(data) || isObservableMap(data)) {
    data = data.toJSON()
  }

  if ('toJSON' in data) {
    return data.toJSON()
  }

  if ('name' in data && 'flags' in data && typeof data.flags === 'number') {
    return typeToJS(data)
  }

  return serialize(data)
}

/**
 * Wraps the specified factory in a decorator that works with or without invocation.
 * Basically, it makes it irrelevant whether you use `@dec` or `@dec()`
 */
function createMethodDecorator(factory) {
  return function decorator(target, name, descriptor) {
    if (descriptor && 'configurable' in descriptor) {
      // Invoking as a parameterless decorator.
      return factory()(target, name, descriptor)
    }

    return factory(...arguments)
  }
}
