import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import styles from './PermissionsSummary.m.sass'

const PermissionsSummary = ({ descriptions }) => {
  if (descriptions.length > 4) {
    const allows = descriptions.filter((d) => d.value === 'allow')
    const denies = descriptions.filter((d) => d.value === 'deny')

    const showAllows = allows.length > 0
    const showDenies = denies.length > 0
    return (
      <div className={styles.permissionDescriptions}>
        {!showAllows && !showDenies && (
          <div className={styles.grantSummary}>
            No permissions for this role.
          </div>
        )}
        {showAllows && (
          <div
            className={cx(styles.grantSummary, styles.allow)}
            title={allows.map((x) => x.name).join('\n')}
          >
            {allows.length} allows
          </div>
        )}
        {showAllows && showDenies && <div className={styles.grantSplit} />}
        {showDenies && (
          <div
            className={cx(styles.grantSummary, styles.deny)}
            title={denies.map((x) => x.name).join('\n')}
          >
            {denies.length} denies
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.permissionDescriptions}>
      {descriptions.map((d) => (
        <span
          key={d.id}
          className={cx(styles.permissionDescription, styles[d.value])}
        >
          {d.name}
        </span>
      ))}
    </div>
  )
}

export default observer(PermissionsSummary)
