import { Store } from 'libx'
import { action, observable, computed } from 'mobx'
import { extractMessageFromError } from 'utils/errorUtil'
import { required, validationContext } from 'validx'

export default class AddToTeamStore extends Store {
  @observable
  showing = false

  @observable
  member

  @observable
  teamId = ''

  @observable
  teamRoleId = ''

  @observable
  availableTeamRoles = []

  validation = validationContext(this, {
    teamId: [required('Team is required')],
    teamRoleId: [required('Team Role is required')],
  })

  @computed
  get availableTeams() {
    if (!this.member) {
      return []
    }

    const teamUserIsPartOf = this.rootStore.teamMemberStore
      .teamMembersForWorkspaceMemberByPublicId(
        this.rootStore.sessionStore.workspace.id,
        this.member.userPublicId
      )
      .map((x) => x.teamId)

    return this.rootStore.teamStore.teams.filter(
      (t) =>
        t.workspaceId === this.rootStore.sessionStore.workspace.id &&
        !teamUserIsPartOf.includes(t.id) &&
        t.inactive === false
    )
  }

  @action.bound
  show(member) {
    this.teamId = ''
    this.teamRoleId = ''
    this.member = member

    this.showing = true
  }

  @action.bound
  async selectTeam(teamId) {
    this.teamId = teamId

    this.availableTeamRoles = await this.rootStore.teamRoleStore.find({
      workspace_id: this.rootStore.sessionStore.workspace.id,
    })

    this.teamRoleId = this.availableTeamRoles[0].id
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @action.bound
  save() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const { teamMemberStore } = this.rootStore

    const msg = this.rootStore.flashMessageStore.create({
      message: 'Adding user to team...',
      inProgress: true,
    })
    return teamMemberStore
      .addTeamMemberByPublicId({
        user_id: this.member.userPublicId,
        team_id: this.teamId,
        workspace_id: this.rootStore.sessionStore.workspace.id,
        team_role_id: this.teamRoleId,
      })
      .then(() => {
        msg.done('Added to Team!').autoDismiss()
        this.hide()
      })
      .catch((err) => msg.failed(extractMessageFromError(err)).autoDismiss())
  }
}
