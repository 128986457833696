import { validatable } from 'legend-builder/mixins/validation'
import InfoLinesBuilder from './InfoLinesBuilder'
import { snapshotable } from '../data/serialization'
import { observable } from 'mobx'
import { addGenericErrorForProperty } from '../data/serverErrors'

@snapshotable
@validatable({ liveValidation: true })
export default class InfoOptions {
  @observable infoLines

  rules = {
    infoLines: [
      () => this.infoLines.validateAll() || 'There are invalid lines.',
    ],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.infoLines = new InfoLinesBuilder(this.legendEditor, this)
  }

  hydrate(infoLines) {
    infoLines = infoLines || []
    this.infoLines.hydrate(infoLines)
  }

  toJSON() {
    return this.infoLines.toJSON()
  }

  receiveErrors(descriptor) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'infoLines',
      'There are invalid info lines'
    )
    this.infoLines.receiveErrors(descriptor)
  }
}
