import { Model } from 'libx'
import uniqueId from 'lodash/uniqueId'
import { validatable } from 'legend-builder/mixins/validation'
import { serialize, serializable } from '../data/serialization'
import { receiveErrors } from '../data/serverErrors'
import { observable, computed } from 'mobx'

/**
 * Base mutator editor.
 */
@validatable({ liveValidation: false })
export default class MutatorEditor extends Model {
  cid = uniqueId('mutator-')

  /**
   * The target variable.
   */
  @observable
  @serializable
  target

  /**
   * Constructor.
   *
   * @param {*} attrs the mutator attributes
   * @param {*} opts must have an `effect` property which is the containing effect and a `legendEditor`.
   */
  constructor(attrs, opts) {
    super(attrs, opts)
    this.legendEditor = opts.legendEditor
    this.effect = opts.effect
    this.target = this.legendEditor.createSymbolConnector({
      context: this.effect.context,
      required: true,
      predicates: [
        (entityDescriptor) =>
          this.isCompatibleVariableType(
            entityDescriptor.type,
            entityDescriptor.entity.valueType
          ),
      ],
    })
  }

  /**
   * Used for filtering allowed variables.
   * Can be overridden by a subclass.
   */
  isCompatibleVariableType(type, valueType) {
    return type === 'Variable'
  }

  /**
   * Whether the editor is disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Serializes to a plain object.
   */
  toJSON() {
    const serialized = serialize(this)
    serialized.type = this.type
    return serialized
  }

  /**
   * Receives errors and populates the validation context with them.
   * @param {*} descriptor
   */
  receiveErrors(descriptor = {}) {
    receiveErrors(this, descriptor.inner)
  }

  /**
   * Parses from a plain object.
   */
  parse({ target, ...attrs }) {
    this.target.hydrate(target)
    return attrs
  }
}

/**
 * Creates a question editor of the correct type.
 */
MutatorEditor.create = function create(attrs, opts) {
  const Type = require(`legend-builder/effects/mutators/${attrs.type}/${attrs.type}MutatorEditor`)
    .default
  return new Type(attrs, opts)
}

/**
 * Converts a JS object to the correct question class.
 */
MutatorEditor.fromJS = function fromJS(attrs, opts) {
  return MutatorEditor.create(attrs, {
    parse: true,
    stripUndefined: true,
    ...opts,
  })
}
