import { validatable } from '../mixins/validation'
import memoize from 'lodash/memoize'
import { observable } from 'mobx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { receiveErrors } from '../data/serverErrors'
import { MAPPED_IANA_TIME_ZONES } from '../consts/TimeZones'

/**
 * Gets the system time zone.
 */
const getSystemTimeZone = memoize(function getSystemTimeZone() {
  try {
    return new Intl.DateTimeFormat('en-US').resolvedOptions().timeZone
  } catch (_) {
    return 'UTC'
  }
})

/**
 * Time config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class TimeConfigEditor {
  @observable
  @serializable
  time_zone = getSystemTimeZone()

  constructor(legendEditor) {
    this.legendEditor = legendEditor
  }

  hydrate(timeConfig) {
    if (!timeConfig) {
      this.time_zone = getSystemTimeZone()
      return
    }

    const { time_zone } = timeConfig
    this.time_zone = time_zone || 'UTC'
  }

  availableTimeZones = MAPPED_IANA_TIME_ZONES

  toJSON() {
    return serialize(this)
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    receiveErrors(this, inner)
  }
}
