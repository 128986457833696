import { validatable } from '../mixins/validation'
import { observable, action } from 'mobx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { receiveErrors } from '../data/serverErrors'

/**
 * Rating config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class RatingConfigEditor {
  @observable
  @serializable
  proRatingEnabled = false

  @observable
  @serializable
  platformRatingEnabled = false

  @observable
  @serializable
  customerRatingMethod = 'thumbs'

  ratingMethods = [
    ['stars', 'Stars'],
    ['thumbs', 'Thumbs up/ Thumbs down'],
  ]

  constructor(legendEditor) {
    this.legendEditor = legendEditor
  }

  hydrate(ratingConfig) {
    if (!ratingConfig) {
      return
    }

    const {
      platformRatingEnabled,
      proRatingEnabled,
      customerRatingMethod,
    } = ratingConfig

    this.proRatingEnabled = !!proRatingEnabled
    this.platformRatingEnabled = !!platformRatingEnabled
    this.customerRatingMethod = customerRatingMethod
  }

  toJSON() {
    return {
      ...serialize(this),
    }
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    const { categories, ...rest } = inner
    receiveErrors(this, rest)
  }

  @action.bound
  toggleCheckbox(field, disabled) {
    this[field] = disabled
  }
}
