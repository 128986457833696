import { observable, computed, action, reaction } from 'mobx'
import { required } from 'validx'
import QuestionEditor from '../../QuestionEditor'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import { Types } from 'legend-builder/data/Type'
import { validateObject } from '../../../../utils/validx-validators'

@snapshotable
export default class NumericQuestionEditor extends QuestionEditor {
  static friendlyType = 'Numeric'
  static type = 'Numeric'
  static viewOrder = 3
  static iconColor = '#5AB3FF'

  valueType = Types.number

  @observable
  @serializable
  question = 'New Numeric Question'

  @observable
  @serializable
  description = ''

  @observable
  @serializable((v) => v)
  format = {
    type: 'Integer',
  }

  @serializable
  effect = this.createEffect()

  constructor(attrs, opts) {
    super(null, { ...opts, parse: false })
    this.effect = this.createEffect()
    this.effect.setExpanded(true)
    this.set(attrs, opts)
    reaction(
      () => this.format.places,
      () => this.validate(['format.places'])
    )
  }

  rules = {
    effect: [validateObject('There are invalid effects')],
    question: [required('Question is required')],
    'format.places': [
      () => {
        if (this.format.type !== 'Decimal') {
          return true
        }
        if (this.format.places < 0) {
          return 'Must not be a negative number'
        }
        if (this.format.places > 10) {
          return 'Must be less than 10'
        }

        return true
      },
    ],
  }

  parse(json) {
    const { effect, ...attrs } = json

    this.effect.hydrate(effect)
    return {
      ...super.parse(attrs),
    }
  }

  @computed
  get answerOptions() {
    return {
      type: 'text',
    }
  }

  @computed
  get effectDescriptors() {
    return [
      {
        id: 'effect',
        title: 'Effect',
        effect: this.effect,
      },
    ]
  }

  @action.bound
  changeFormat(type) {
    if (type === 'Decimal') {
      this.format = {
        type,
        places: 2,
      }
      return
    }

    this.format = {
      type,
    }
  }
}
