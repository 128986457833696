import { task } from 'mobx-task'
import { observable, action } from 'mobx'
import { validationContext, required } from 'validx'
import { stringLength } from 'utils/validx-validators'
import { extractMessageFromError } from 'utils/errorUtil'

export default class MessageBankReplyEditorStore {
  @observable
  showing = false

  @observable
  title = ''

  @observable
  content = ''

  @observable
  reply = null

  constructor(
    messageBankStore,
    getMessageBankId,
    flashMessageStore,
    reply = null
  ) {
    this.messageBankStore = messageBankStore
    this.getMessageBankId = getMessageBankId
    this.flashMessageStore = flashMessageStore
    if (reply) {
      this.reply = reply
      this.title = reply.title
      this.content = reply.content
    }
  }

  validation = validationContext(this, {
    title: [
      required('Title is required'),
      stringLength(0, 250, 'Keep it short, max {max} characters.'),
    ],
    content: [
      required('Content is required'),
      stringLength(
        0,
        4000,
        'Thats a wee bit long - max {max} characters please.'
      ),
    ],
  })

  @action
  startEditing() {
    this.showing = true
  }

  @action
  close() {
    this.showing = false
  }

  @action
  reset() {
    this.validation.reset()
    this.showing = false
    this.reply = null
    this.title = ''
    this.content = ''
  }

  @task.resolved
  async save() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const messageBankId = this.getMessageBankId()

    const title = this.title.trim()
    const content = this.content.trim()

    const msg = this.flashMessageStore.create({
      message: 'Saving message...',
      inProgress: true,
    })
    const id = this.reply && this.reply.id
    try {
      if (id) {
        await this.messageBankStore.updateReply(
          messageBankId,
          id,
          title,
          content
        )
      } else {
        await this.messageBankStore.createReply(messageBankId, title, content)
        this.reset()
      }

      msg.done('Message saved!').autoDismiss()
    } catch (e) {
      msg.failed(extractMessageFromError(e)).autoDismiss()
    }
  }
}
