import cx from 'classnames'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './CouponFacetedSearch.m.sass'
import CsvExport from './../../Jobs/FacetedSearch/CsvExport.jsx'

/**
 * Faceted Search component.
 */
const CouponFacetedSearch = observer(({ store }) => {
  const { facets, selectedFacets } = store

  return (
    <div
      className={cx(styles.facetedSearch, !store.showFilter && styles.hidden)}
    >
      <div className={styles.facet}>
        <div className={styles.export}>
          <CsvExport asyncExportMethod={store.loadAllData} />
        </div>
        <div className={styles.header}>Date Submitted/Closed</div>
        <div className={styles.bucket}>
          From:{' '}
          <input
            type="date"
            value={store.fromDate}
            onChange={(e) => store.changeFromDate(e.target.value)}
          />
          <br />
          To:{' '}
          <input
            type="date"
            className={styles.toDate}
            value={store.toDate}
            onChange={(e) => store.changeToDate(e.target.value)}
          />
        </div>
      </div>
      {Array.from(facets).map(([key, value]) => {
        if (value.buckets.lenth === 0) {
          return <></>
        }

        return (
          <div className={styles.facet} key={key}>
            <div className={styles.header}>{key}</div>
            {value.buckets.map((bucket) => {
              const selected = selectedFacets.get(key) || []

              return (
                <label className={styles.bucket} key={bucket.key}>
                  <input
                    type="checkbox"
                    className={styles.bucketCheck}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => store.selectFacet(key, bucket.key)}
                    checked={selected === bucket.key}
                  />
                  {bucket.key}
                </label>
              )
            })}
          </div>
        )
      })}
    </div>
  )
})

CouponFacetedSearch.propTypes = {
  store: PropTypes.object,
}

export default CouponFacetedSearch
