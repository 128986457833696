import { observable } from 'mobx'
import { snapshotable, serializable } from 'legend-builder/data/serialization'
import RynoEditor from 'legend-builder/ryno-editor/RynoEditor'
import { validateObject } from 'utils/validx-validators'
import { func } from 'validx'
import MutatorEditor from '../../MutatorEditor'

/**
 * Replace Mutator.
 */
@snapshotable
export default class ReplaceMutatorEditor extends MutatorEditor {
  type = 'Replace'

  /**
   * The formula
   */
  @observable
  @serializable
  expr

  rules = {
    target: [
      (ctx) => {
        return true
      },
      validateObject('Please select the variable you wish to change.'),
    ],
    expr: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
  }

  /**
   * Constructor.
   * @param {*} attrs
   * @param {*} opts
   */
  constructor(attrs, opts) {
    super(null, opts)
    this.expr = new RynoEditor({
      required: true,
      getSymbolTable: () => this.effect.symbolTable,
      getNeededType: () => this.target.valueType,
    })
    this.set(attrs, opts)
    // Validate the symbol ref when the symbol changes
    this.target.bubbleErrorsTo(this, 'target')
  }

  /**
   * Parses from raw JS.
   */
  parse({ expr, ...rest }) {
    this.expr.setValue(expr)
    return super.parse(rest)
  }
}
