import { validatable } from '../mixins/validation'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { Model } from 'libx'
import uniqueId from 'lodash/uniqueId'
import { computed, observable } from 'mobx'
import FiltersBuilder from '../filters/FiltersBuilder'
import { validateObject } from '../../utils/validx-validators'
import { required } from 'validx'
import { receiveErrors } from '../data/serverErrors'

/**
 * Routing plan editor.
 */
@validatable({ liveValidation: true })
@snapshotable
export class RoutingPlanEditor extends Model {
  cid = uniqueId('routingplan-')

  /**
   * Plan label.
   */
  @serializable
  @observable
  label = ''

  /**
   * Plan description.
   */
  @serializable
  @observable
  description = ''

  /**
   * Duration in minutes.
   * This gets mapped to/from seconds.
   */
  @serializable
  @observable
  duration = 5

  /**
   * The requirements.
   */
  @serializable
  requirements

  rules = {
    label: [required('Label is required')],
    duration: [
      ({ value }) =>
        (value >= 1 && value <= 4320) || // 72 hours
        'Duration must be at least 1 minute and at most 4320 minutes.',
    ],
    requirements: [validateObject('There are invalid requirements.')],
  }

  constructor(attrs, opts) {
    super()
    this.legendEditor = opts.legendEditor
    this.requirements = new FiltersBuilder({
      legendEditor: opts.legendEditor,
      scope: opts.scope,
      context: opts.context,
    })
    this.set(attrs, opts)
  }

  @computed
  get tabValidations() {
    return {
      GENERAL: !(
        this.validation.getError('label') ||
        this.validation.getError('description') ||
        this.validation.getError('duration')
      ),
      REQUIREMENTS: !this.validation.getError('requirements'),
    }
  }

  parse({ requirements, duration, ...rest }) {
    this.requirements.hydrate(requirements)
    return {
      duration: Math.round(duration / 60),
      ...rest,
    }
  }

  toJSON() {
    return {
      id: this.id || this.legendEditor.newId(this.cid, this.label),
      ...serialize(this),
      duration: this.duration * 60,
    }
  }

  /**
   * Receives errors and populates the validation context with them.
   *
   * @param {*} descriptor
   */
  receiveErrors(descriptor) {
    receiveErrors(this, descriptor.inner)
  }
}

RoutingPlanEditor.create = function create(attrs, opts) {
  return new RoutingPlanEditor(attrs, opts)
}

RoutingPlanEditor.fromJS = function fromJS(attrs, opts) {
  return RoutingPlanEditor.create(attrs, {
    ...opts,
    parse: true,
    stripUndefined: true,
  })
}
