import React from 'react'
import EntitySelector from '../EntitySelector'

const UserSelector = ({ memberStore, workspaceId, userFilter, ...other }) => {
  const canViewDetails = memberStore.rootStore.sessionStore.member.hasPermission(
    'ADMIN_USER_READ'
  )

  return (
    <EntitySelector
      {...other}
      pageSize={20}
      loadEntities={(p) =>
        memberStore.find({
          workspace_id: workspaceId,
          populate: false,
          ...p,
        })
      }
      entityFilter={userFilter}
      getOptionLabel={(o) =>
        canViewDetails ? o.detailedDisplayName : o.displayName
      }
      noOptionsMessage={() => 'Type to search for members.'}
      defaultOptions
    />
  )
}

export default UserSelector
