import ConfirmDialog from 'components/ConfirmDialog'
import { Dropdown, DropdownItem, DropdownItemIcon } from 'components/Dropdown'
import Icon from 'components/Icon'
import {
  Table,
  TableHeadCell,
  TableHead,
  TableRow,
  TableBody,
  TableRowLink,
  TableCell,
  SortableHeader,
} from 'components/BetterTable'
import link from 'misc/link'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import prune from 'underscore.string/prune'
import moment from 'moment'
import './JobTable.sass'
import RequirePerms from 'iam/containers/RequirePerms'
import TransferManyJobDialog from '../TransferManyJobDialog'
import { getUnitFormatter } from 'utils/unitFormatter'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'

@observer
class JobTable extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.findChampion = this.findChampion.bind(this)
    this.headers = [
      { label: T('Web.Common.Client', 'Client'), field: 'customer_first.raw' },
      { label: T('Web.Common.Provider', 'Pro'), field: 'provider_first.raw' },
      { label: 'Job Name', field: 'name.raw' },
      { label: 'Job ID' },
      { label: 'Job Type', field: 'legend_name' },
      { label: 'Status', field: 'status_name.raw' },
      { label: 'Waiting on', field: 'waiting_on.keyword' },
      { label: 'Total', field: 'total' },
      { label: 'Teams', field: 'team_names' },
      { label: 'Date Created', field: 'date_created' },
      { label: 'Date Submitted', field: 'date_transmitted' },
      { label: 'Date Deadline', field: 'date_deadline' },
      { label: 'Date Completed', field: 'date_closed' },
    ]
  }

  findChampion(job) {
    const pro = job.providers.find((pro) => pro.role === 'CHAMPION')
    return pro ? prune(pro.user.displayName, 20) : null
  }

  getTeamsForJob(job) {
    if (!job.teamIds.length) {
      return ''
    }
    const teams = job.teamIds
      .map((id) =>
        this.props.store.rootStore.teamStore.teams.find((x) => x.id === id)
      )
      .filter(Boolean)
    return prune(teams.map((x) => x.name).join(','), 20)
  }

  getWaitingOn(job) {
    switch (job.waitingOn) {
      case 'Provider':
        return 'Pro'
      case 'Client':
        return 'Client'
      default:
        return ''
    }
  }

  render() {
    const jobs = this.props.jobs
    const store = this.props.store
    const isJobMenuActionDeprecated = getFeatureToggleClient().variation(
      'HQ.JobMenuActionDeprecated',
      false
    )

    return (
      <div className="job-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell
                style={{
                  padding: '10px 0px 0px 5px',
                }}
              >
                {store.selectedJobs.length > 0 && (
                  <Dropdown
                    position="left"
                    button={
                      <a
                        className="action button is-icon-only"
                        data-test="toggle-header-dropdown-jobs"
                      >
                        <Icon material name="more vert" />
                      </a>
                    }
                  >
                    {this.props.store.showInactive ? (
                      <RequirePerms allOf={['ADMIN_JOB_UPDATE']}>
                        <DropdownItem
                          onClick={() => this.props.store.restoreSelected()}
                          className="normalize-text"
                          data-test="job-list-dropdown-undelete-action"
                        >
                          <DropdownItemIcon
                            material
                            name="add circle outline"
                          />
                          Restore Selected
                        </DropdownItem>
                      </RequirePerms>
                    ) : (
                      <RequirePerms allOf={['ADMIN_JOB_DELETE']}>
                        {!isJobMenuActionDeprecated && (
                          <DropdownItem
                            onClick={() => this.props.store.deleteSelected()}
                            className="normalize-text"
                            data-test="job-list-dropdown-delete-action"
                          >
                            <DropdownItemIcon material name="delete" />
                            Delete Selected
                          </DropdownItem>
                        )}
                      </RequirePerms>
                    )}
                    {!this.props.store.showInactive && (
                      <>
                        <RequirePerms allOf={['ADMIN_JOB_TRANSFER']}>
                          {!isJobMenuActionDeprecated && (
                            <DropdownItem
                              onClick={() => store.transferSelected('client')}
                              className="normalize"
                            >
                              <DropdownItemIcon material name="transform" />
                              Transfer Selected to Client
                            </DropdownItem>
                          )}
                          {!isJobMenuActionDeprecated && (
                            <DropdownItem
                              onClick={() => store.transferSelected('pro')}
                              className="normalize"
                            >
                              <DropdownItemIcon material name="transform" />
                              Transfer Selected to Pro
                            </DropdownItem>
                          )}
                        </RequirePerms>
                        <RequirePerms allOf={['ADMIN_JOB_UPDATE']}>
                          <DropdownItem
                            onClick={() => store.notifyProSelected()}
                            className="normalize"
                          >
                            <DropdownItemIcon
                              material
                              name="notifications_active"
                            />
                            Notify Pros for Selected Jobs
                          </DropdownItem>
                        </RequirePerms>
                      </>
                    )}
                  </Dropdown>
                )}
              </TableHeadCell>
              {this.headers.map((header, index) => (
                <SortableHeader
                  key={index}
                  store={store}
                  field={header.field}
                  label={header.label}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job, index) => {
              const formatUnit = getUnitFormatter(
                job.legend && job.legend.priceUnit
              )
              const checkbox = (
                <input
                  type="checkbox"
                  data-test={`job-list-item-checkbox-${job.id}`}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => store.handleSelection(job.id)}
                  checked={store.selectedJobs.indexOf(job.id) > -1}
                />
              )
              return (
                <TableRowLink
                  data-test={`job-list-item-${job.id}`}
                  key={index}
                  link={
                    !store.showInactive &&
                    link.job(
                      job.id,
                      this.props.store.rootStore.sessionStore.workspace.slug
                    )
                  }
                >
                  <TableCell onClick={(e) => e.stopPropagation()}>
                    {this.props.store.showInactive ? (
                      <RequirePerms allOf={['ADMIN_JOB_UPDATE']}>
                        {checkbox}
                      </RequirePerms>
                    ) : (
                      checkbox
                    )}
                  </TableCell>
                  <TableCell>
                    {job.status === 'IDLE' && <span className="dot" />}
                    {job.client && prune(job.client.displayName, 20)}
                  </TableCell>
                  <TableCell>
                    {job.stale === true && job.status !== 'IDLE' && (
                      <span className="dot" />
                    )}
                    {this.findChampion(job)}
                  </TableCell>
                  <TableCell>{prune(job.name, 60)}</TableCell>
                  <TableCell>{job.shortId}</TableCell>
                  <TableCell>{job.legendName}</TableCell>
                  <TableCell>{getJobStatus(job)}</TableCell>
                  <TableCell>{this.getWaitingOn(job)}</TableCell>
                  <TableCell>{formatUnit(job.total)}</TableCell>
                  <TableCell>{this.getTeamsForJob(job)}</TableCell>
                  <TableCell>
                    {job.dateCreated ? job.dateCreated.format('LL') : null}
                  </TableCell>
                  <TableCell>
                    {job.dateTransmitted
                      ? job.dateTransmitted.format('LLLL')
                      : null}
                  </TableCell>
                  <TableCell>
                    {job.isDeadlineRelevant
                      ? job.dateDeadline.format('lll')
                      : null}
                  </TableCell>
                  <TableCell>
                    {job.dateClosed ? job.dateClosed.format('LL') : null}{' '}
                  </TableCell>
                </TableRowLink>
              )
            })}
          </TableBody>
        </Table>
        <TransferManyJobDialog store={store.transferManyJobState} />
        <ConfirmDialog
          state={store.deleteJobConfirm}
          title="Delete Job(s)"
          yes="Delete"
          data-test="delete-jobs-dialog"
          danger
        >
          <p>Do you really want to delete these jobs?</p>
          <p>
            Documents within these jobs will be deleted if they are not linked
            anywhere else.
          </p>
          <strong> I hope you know what you're doing.</strong>
        </ConfirmDialog>
        <ConfirmDialog
          state={store.notifyProsConfirm}
          title="Notify Pros of Open Jobs?"
          yes="Notify"
          disabled={store.notifyProsConfirm.pending}
          danger
        >
          Do you really want to notify all available{' '}
          {T('Web.Common.Providers', 'Pros')}?{' '}
          <strong>I hope you know what you're doing.</strong>
          <br />
          <br />
        </ConfirmDialog>
      </div>
    )
  }
}

export { JobTable }

function getJobStatus(job) {
  switch (job.status) {
    case 'UNDER_CONSTRUCTION':
      return 'Onboarding'
    case 'INFO_GATHERING':
      return 'Info Gathering'
    case 'UNCLAIMED': {
      return 'Awaiting pickup'
    }
    case 'ON_HOLD':
      return 'On Hold'
    case 'IDLE':
      return 'Idle'
    case 'CLAIMED': {
      if (job.dateDeadline && job.dateDeadline < moment()) {
        return 'Past deadline'
      }
      const milestone = job.milestones.find((x) => !x.completed)
      return milestone ? prune(milestone.title, 55) : 'In Progress'
    }
    case 'CLOSED':
      return 'Closed'
    default:
      return job.status
  }
}
