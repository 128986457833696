import React from 'react'
import { observer } from 'mobx-react'
import NotificationPreferencesEditor from 'notifications/components/NotificationPreferencesEditor'
import PageWrapper from '../../components/PageWrapper'
import { Helmet } from 'react-helmet-async'

@observer
export default class NotificationPreferencesScreen extends React.Component {
  render() {
    const { notificationPreferencesStore } = this.props.rootStore
    return (
      <PageWrapper pageTitle="Notifications">
        <Helmet title="Notifications" />
        <div>
          <NotificationPreferencesEditor store={notificationPreferencesStore} />
        </div>
      </PageWrapper>
    )
  }
}
