import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './PageWrapper.sass'

/**
 * Page title
 */
export const PageTitle = ({ children, center, className }) => (
  <h1 className={cx('page-title', className, center && 'center')}>
    {children}
  </h1>
)

/**
 * A generic page wrapper.
 */
export default function PageWrapper({ children, pageTitle, className }) {
  return (
    <div className={cx('page-wrapper', className)}>
      {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
      {children}
    </div>
  )
}

PageWrapper.propTypes = {
  className: PropTypes.string,
}
