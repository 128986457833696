import { Store } from 'libx'
import handleErrors from 'misc/handleErrors'
import ScopingJob from 'misc/model/ScopingJob'
import { task } from 'mobx-task'

class ScopeWorkStore extends Store {
  scopingJobs = this.collection({
    model: ScopingJob,
    idAttribute: 'id',
  })

  get api() {
    return this.rootStore.api.wunders
  }

  @handleErrors
  getAggregations(showInactive) {
    return this.api.aggregations({
      workspaceId: this.rootStore.sessionStore.workspace.id,
    })
  }

  @task
  async scopeWork(payload) {
    const scopingJob = await this.api.scopeWork(payload)
    return this.scopingJobs.set(scopingJob)
  }

  @task
  async rerun(id) {
    return this.api.rerun(id)
  }

  @task
  async manualInputs(data) {
    const result = await this.api.manualInputs(data)
    return result?.manual_inputs || []
  }

  @handleErrors
  async search(query) {
    const result = await this.api.search(query)
    const scopingJobs = this.scopingJobs.set(result.data)
    return {
      ...result,
      data: scopingJobs,
    }
  }
}

export default ScopeWorkStore
