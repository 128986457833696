import { action, computed, observable } from 'mobx'

export default class StaffingConfigViewModel {
  @observable enabled = false
  @observable staffingManagerUserPublicId = ''
  @observable onboardingRoleId = ''

  @observable potentialManagers = []

  constructor({ roleStore, memberStore, currentUser, workspaceId }) {
    this.roleStore = roleStore
    this.memberStore = memberStore
    this.workspaceId = workspaceId
    this.currentUser = currentUser
  }

  activate(staffingConfig) {
    this.enabled = staffingConfig.enabled || false
    this.staffingManagerUserPublicId =
      staffingConfig.staffing_manager_user_public_id || ''
    this.onboardingRoleId = staffingConfig.onboarding_role_id || ''

    this.memberStore
      .fetchByPublicId({
        workspace_id: this.workspaceId,
        user_public_id: this.currentUser.public_id,
      })
      .then(() => {
        if (this.canViewWorkspaceMembers) {
          this._loadPotentialManagers().then()
        }
      })
    this.roleStore.findByWorkspace(this.workspaceId, { limit: 0 })
  }

  async _loadPotentialManagers() {
    const result = await this.memberStore.search({
      workspace_id: this.workspaceId,
      limit: 0,
      has_permission: 'ADMIN_STAFFING_MANAGE',
    })

    this.potentialManagers.push(...result.data)
  }

  @computed
  get canViewWorkspaceMembers() {
    return this.memberStore.members.find((m) => {
      return (
        m.isCurrentUser &&
        m.workspaceId === this.workspaceId &&
        // member.hasPermission uses sessionStore.workspace
        // and the currently selected workspace may not be in there
        m.computedPermissions.ADMIN_USER_LIST === 'allow'
      )
    })
  }

  @action.bound
  changeManager(manager) {
    this.staffingManagerUserPublicId = manager
  }

  @action.bound
  changeOnboardingRoleId(roleId) {
    this.onboardingRoleId = roleId
  }

  @computed
  get availableRoles() {
    return this.roleStore.forWorkspace(this.workspaceId).map((r) => {
      return {
        value: r.id,
        label: r.name,
      }
    })
  }

  @computed
  get potentialManagerOptions() {
    return this.potentialManagers.map((m) => {
      return {
        value: m.userPublicId,
        label: m.displayName,
      }
    })
  }

  toJSON() {
    return {
      enabled: this.enabled,
      staffing_manager_user_public_id: this.staffingManagerUserPublicId,
      onboarding_role_id: this.onboardingRoleId,
    }
  }
}
