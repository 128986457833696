import { Model } from 'libx'
import uniqueId from 'lodash/uniqueId'
import { observable, computed } from 'mobx'
import { validatable } from 'legend-builder/mixins/validation'
import { serializable, serialize, snapshotable } from '../data/serialization'
import FiltersBuilder from '../filters/FiltersBuilder'
import { required, func } from 'validx'
import { receiveErrors } from '../data/serverErrors'
import RynoEditor from '../ryno-editor/RynoEditor'
import { Types } from '../data/Type'

@snapshotable
@validatable({ liveValidation: true })
export default class InfoLineEditor extends Model {
  cid = uniqueId('info-line-')

  @observable
  @serializable
  title = ''

  @observable
  @serializable
  content

  @observable
  @serializable
  designee = 'Both'

  @serializable
  triggerWhen

  rules = {
    title: [required('Title is required.')],
    content: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
    designee: [
      required(),
      func(
        (ctx) => ['Both', 'Client', 'Provider'].includes(ctx.value),
        'Not a valid designee'
      ),
    ],
    triggerWhen: [
      () => this.triggerWhen.validateAll() || 'There are invalid conditions.',
    ],
  }

  constructor(attrs, opts) {
    super(null, opts)
    this.legendEditor = opts.legendEditor
    this.context = opts.context
    this.triggerWhen = new FiltersBuilder({
      legendEditor: this.legendEditor,
      context: this.context,
      scope: [],
    })
    this.content = new RynoEditor({
      required: true,
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => Types.string,
    })
    this.set(attrs, opts)
  }

  /**
   * Tab validations.
   */
  @computed
  get tabValidations() {
    return {
      GENERAL:
        !this.validation.getError('name') && !this.validation.getError('expr'),
      CONDITIONS: !this.validation.getError('triggerWhen'),
    }
  }

  /**
   * The symbol table to use for the Ryno Semantic Analyzer.
   */
  @computed
  get symbolTable() {
    return this.legendEditor.getSymbolTableFor(this.context)
  }

  @computed
  get lastContentLine() {
    return this.content.lastLine
  }

  parse({ triggerWhen, content, ...line }) {
    this.triggerWhen.hydrate(triggerWhen)
    this.content.setValue(content, true)
    return line
  }

  receiveErrors(descriptor = {}) {
    receiveErrors(this, descriptor.inner)
  }

  toJSON() {
    return serialize(this)
  }
}

/**
 * Creates a new instance of InfoLineEditor.
 */
InfoLineEditor.create = function create(data, opts) {
  return new InfoLineEditor(data, opts)
}

/**
 * Creates a new instance of InfoLineEditor and parses the attributes.
 */
InfoLineEditor.fromJS = function fromJS(data, opts) {
  return new InfoLineEditor(data, {
    ...opts,
    parse: true,
    stripUndefined: true,
  })
}
