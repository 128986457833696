import env from 'misc/env'
import AxiosAPIBase from './AxiosAPIBase'
import { ENGINE_VERSION_PARAMS } from '../consts/EngineVersion'
import { JobAdminServicePromiseClient } from '@taxfyle/api-internal/internal/job_admin_grpc_web_pb'
import { InventoryItemServicePromiseClient } from '@taxfyle/api-internal/internal/inventory_item_grpc_web_pb'
import FormData from 'form-data'
import { createGrpcAuthenticator } from '@taxfyle/web-commons/lib/misc/grpc-authenticator'
import { JobsV3API } from 'jobs/API'
import {
  ListJobQuestionVariablesRequest,
  ArchiveJobRequest,
} from '@taxfyle/api-internal/internal/job_admin_pb'
import QuestionVariableMapper from './mappers/QuestionVariableMapper'
import { BundleServicePromiseClient } from '@taxfyle/api-internal/internal/bundle_grpc_web_pb'
import { enableDevTools } from '@taxfyle/web-commons/lib/utils/grpcUtil'

export default class TaxfyleAPI {
  constructor({ authStore, workRealtime$, togglesInitialized$ }) {
    this.authStore = authStore
    this.jobs = new JobAPI(this)
    this.jobsV3 = new JobsV3API(
      {
        baseURL: env.WORK_DOTNET_API,
        token$: this.authStore.token$,
      },
      workRealtime$,
      togglesInitialized$
    )
    this.users = new UserAPI(this)
    this.iam = new IamAPI(this)
    this.legends = new LegendsAPI(this)
    this.messages = new MessageAPI(this)
    this.billing = new BillingAPI(this)
    this.payoutMethods = new PayoutMethodAPI(this)
    this.jobEvents = new JobEventAPI(this)
    this.jobActions = new JobActionAPI(this)
    this.jobAnalytics = new JobAnalyticsAPI(this)
    this.jobProviders = new JobProviderAPI(this)
    this.analytics = new AnalyticsAPI(this)
    this.userCoupons = new UserCouponAPI(this)
    this.skills = new SkillAPI(this)
    this.translations = new TranslationAPI(this)
    this.userSkills = new UserSkillAPI(this)
    this.impersonation = new ImpersonationAPI(this)
    this.roles = new RoleAPI(this)
    this.permissions = new PermissionAPI(this)
    this.userAccess = new UserAccessAPI(this)
    this.wunders = new WunderAPI(this)
    this.messageBank = new MessageBankAPI(this)
    this.jobAdminV3 = new JobAdminV3Api({
      baseURL: env.WORK_DOTNET_API,
      token$: this.authStore.token$,
    })
    this.inventoryItems = new InventoryItemApi({
      baseURL: env.WORK_DOTNET_API,
      token$: this.authStore.token$,
    })
    this.bundles = new BundleApi({
      baseURL: env.WORK_DOTNET_API,
      token$: this.authStore.token$,
    })
  }
}

class WunderAPI extends AxiosAPIBase {
  host = env.WUNDER_API

  scopeWork(data, opts = {}) {
    const headers = {}
    const reqOpts = {}
    let body = data
    if (data.files) {
      headers['Content-Type'] = 'multipart/form-data'
      body = new FormData()
      const { files, ...rest } = data
      body.append('meta', JSON.stringify({ ...rest }))
      files.forEach((f, i) =>
        body.append(`file${i + 1}`, f, sanitizeFileName(f.name))
      )
      reqOpts.onUploadProgress = opts.onProgress
    }
    return this.client.post(`/v1/scope-work`, body, reqOpts).then((x) => x.data)
  }

  aggregations({ workspaceId }) {
    return this.client
      .get(`/v1/scope-work/aggregations`, {
        params: { workspace_id: workspaceId },
      })
      .then((x) => x.data)
  }

  search(data) {
    return this.client
      .get(`/v1/scope-work/search`, { params: data })
      .then((x) => x.data)
  }

  rerun(id) {
    return this.client
      .post(`/v1/scope-work/${id}/rerun`, {})
      .then((x) => x.data)
  }

  manualInputs(data) {
    return this.client
      .get(`/v1/scope-work/manual-inputs`, {
        params: data,
      })
      .then((x) => x.data)
  }
}

class LegendsAPI extends AxiosAPIBase {
  host = env.LEGEND_API

  getStates() {
    return this.client.get('/states').then((x) => x.data)
  }
}

class UserCouponAPI extends AxiosAPIBase {
  host = env.BILLING_API

  find({ workspaceId, userId }) {
    return this.client
      .get(`/v3/workspaces/${workspaceId}/users/${userId}/coupons`)
      .then((x) => x.data)
  }
}

class JobAdminV3Api {
  constructor(opts) {
    this.client = new JobAdminServicePromiseClient(opts.baseURL)
    enableDevTools(this.client)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  async transferJobToClientTeam(request) {
    return this.client
      .transferJobToClientTeam(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async transferJobToClient(request) {
    return this.client
      .transferJobToClient(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async removeClientTeam(request) {
    return this.client
      .removeClientTeamFromJob(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async completeJob(request) {
    return this.client
      .completeJob(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async cancelJob(request) {
    return this.client
      .cancelJob(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async closeJobAsTest(request) {
    return this.client
      .closeJobAsTest(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async createAmendment(request) {
    return this.client
      .createAmendment(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async transferJobToPool(request) {
    return this.client
      .transferJobToPool(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async transferJobToProvider(request) {
    return this.client
      .transferJobToProvider(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async reopenJob(request) {
    return this.client
      .reopenJob(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async overrideDeadline(request) {
    return this.client
      .overrideDeadline(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async archiveJob(jobId) {
    const request = new ArchiveJobRequest().setId(jobId)
    return this.client
      .archiveJob(request, await this.grpcAuthenticator.getMeta())
      .then(this.toObject)
  }

  async listQuestionVariables(jobId) {
    const request = new ListJobQuestionVariablesRequest().setId(jobId)

    return this.client
      .listJobQuestionVariables(request, await this.grpcAuthenticator.getMeta())
      .then((response) => ({
        variables: response
          .getVariablesList()
          .map(QuestionVariableMapper.fromDto),
      }))
  }

  toObject(res) {
    return res.toObject()
  }
}

class BundleApi {
  constructor(opts) {
    this.client = new BundleServicePromiseClient(opts.baseURL)
    enableDevTools(this.client)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  async listBundles(request) {
    return this.client.listBundles(
      request,
      await this.grpcAuthenticator.getMeta()
    )
  }
}

class InventoryItemApi {
  constructor(opts) {
    this.client = new InventoryItemServicePromiseClient(opts.baseURL)
    enableDevTools(this.client)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  async listInventoryItems(request) {
    return this.client.listInventoryItems(
      request,
      await this.grpcAuthenticator.getMeta()
    )
  }

  async getAllInventoryItemsById(request) {
    return this.client.getManyInventoryItems(
      request,
      await this.grpcAuthenticator.getMeta()
    )
  }

  async importInventoryItems(request) {
    return this.client.importInventoryItems(
      request,
      await this.grpcAuthenticator.getMeta()
    )
  }
}

class JobAPI extends AxiosAPIBase {
  host = env.ADMIN_API
  path = 'admin/jobs'

  get client() {
    return mutateJobClientWithSupportedEngineVersionParam(super.client)
  }

  get feathersConnectionParams() {
    return ENGINE_VERSION_PARAMS
  }
}

class JobActionAPI extends AxiosAPIBase {
  host = env.ADMIN_API
  path = 'admin/job-actions'

  get client() {
    return mutateJobClientWithSupportedEngineVersionParam(super.client)
  }

  get feathersConnectionParams() {
    return ENGINE_VERSION_PARAMS
  }
}

class JobAnalyticsAPI extends AxiosAPIBase {
  host = env.ADMIN_API
  path = 'admin/jobs/analytics'
}

class JobProviderAPI extends AxiosAPIBase {
  host = env.ADMIN_API
  path = 'admin/jobs/providers'

  get(id, limit = 25, cursor = null) {
    return this.client
      .get('admin/jobs/providers/' + id, {
        params: { limit, cursor },
      })
      .then((x) => x.data)
  }
}

class JobEventAPI extends AxiosAPIBase {
  host = env.ADMIN_API
  path = 'job-events'

  find(data) {
    return this.client
      .get(`/job-events`, { params: data.query })
      .then((x) => x.data)
  }

  get client() {
    return mutateJobClientWithSupportedEngineVersionParam(super.client)
  }

  get feathersConnectionParams() {
    return ENGINE_VERSION_PARAMS
  }
}

class AnalyticsAPI extends AxiosAPIBase {
  host = '/'
  path = 'api/analytics'

  get(data) {
    return this.client
      .get(`api/analytics/1`, { params: data })
      .then((x) => x.data)
  }
}

class UserAPI extends AxiosAPIBase {
  host = env.USER_API
  path = 'v1/users'

  batchGet(ids) {
    return this.client.post(`v1/users/query`, { ids }).then((x) => x.data)
  }
}

class IamAPI extends AxiosAPIBase {
  host = env.IAM_API

  getUserWorkspaceMemberships(userId, query) {
    return this.client
      .get(`v3/users/${userId}/workspace-memberships`, {
        params: query,
      })
      .then((x) => x.data)
  }

  getUserWorkspaceMembershipsByPublicId(userId, query) {
    return this.client
      .get(`v3.1/users/${userId}/workspace-memberships`, {
        params: query,
      })
      .then((x) => x.data)
  }
}

class TranslationAPI extends AxiosAPIBase {
  host = env.IAM_API

  getAll(workspaceId) {
    return this.client
      .get(`/v3/translations/${workspaceId}`)
      .then((response) => response.data)
  }

  upsert(data) {
    return this.client
      .put(`/v3/translations`, data)
      .then((response) => response.data)
  }

  remove(id) {
    return this.client
      .delete(`/v3/translations/${id}`)
      .then((response) => response.data)
  }
}

class MessageAPI extends AxiosAPIBase {
  host = env.MESSAGE_API

  getConversationById(id) {
    return this.client
      .get(`/v3/conversations/${id}`)
      .then((response) => response.data)
  }

  getMessages(id, params) {
    return this.client
      .get(`/v3/conversations/${id}/messages`, {
        params,
      })
      .then((response) => response.data)
  }

  createMessage(conversationId, message) {
    return this.client
      .post(`/v3/conversations/${conversationId}/messages/admin`, {
        text: message,
      })
      .then((response) => response.data)
  }

  deleteMessage(id) {
    return this.client
      .delete(`/v3/messages/${id}`)
      .then((response) => response.data)
  }

  createNote(conversationId, message) {
    return this.client
      .post(`/v3/conversations/${conversationId}/messages/adminNote`, {
        text: message,
      })
      .then((response) => response.data)
  }

  isOnline(userIds) {
    return this.client
      .post(`/v3/users/status/id-query`, userIds)
      .then((response) => response.data)
  }
}

class MessageBankAPI extends AxiosAPIBase {
  host = env.MESSAGE_API

  /**
   * Create a new message bank for the given workspace
   *
   * @param {string} workspaceId
   * @returns
   */
  createMessageBank(workspaceId) {
    return this.client
      .post('/v3/message-banks', {
        workspace_id: workspaceId,
      })
      .then((response) => response.data)
  }

  findForWorkspace(workspaceId) {
    return this.client
      .get(`/v3/message-banks/find`, {
        params: { workspace_id: workspaceId },
      })
      .then((response) => response.data)
  }

  getReplies(messageBankId) {
    return this.client
      .get(`/v3/message-bank-replies`, {
        params: { message_bank_id: messageBankId },
      })
      .then((response) => response.data)
  }

  createReply(data) {
    return this.client
      .post(`/v3/message-bank-replies`, data)
      .then((response) => response.data)
  }

  deleteReply(id) {
    return this.client
      .delete(`/v3/message-bank-replies/${id}`)
      .then((response) => response.data)
  }

  updateReply(id, data) {
    return this.client
      .patch(`/v3/message-bank-replies/${id}`, data)
      .then((response) => response.data)
  }
}

class PayoutMethodAPI extends AxiosAPIBase {
  host = env.BIGMONEY_API

  getByUserPublicId(userPublicId) {
    return this.client
      .get(`/payouts/v1/users/${userPublicId}/payout-method`)
      .then((response) => response.data)
  }

  getStatusByUserPublicId(userPublicId) {
    return this.client
      .get(`/payouts/v1/users/${userPublicId}/payout-method/status`)
      .then((response) => response.data)
  }
}

class BillingAPI extends AxiosAPIBase {
  host = env.BILLING_API

  getMemberBillingProfile(workspaceId, userId) {
    return this.client
      .get(`/v3/workspaces/${workspaceId}/billing-profiles/${userId || 'me'}`)
      .then((response) => response.data)
  }

  getTeamBillingProfile(teamId) {
    return this.client
      .get(`/v3/teams/${teamId}/billing-profile`)
      .then((response) => response.data)
  }

  updateMemberBillingProfile(workspaceId, data, userId) {
    return this.client
      .patch(
        `/v3/workspaces/${workspaceId}/billing-profiles/${userId || 'me'}`,
        data
      )
      .then((response) => response.data)
  }

  updateTeamBillingProfile(teamId, data) {
    return this.client
      .patch(`/v3/teams/${teamId}/billing-profile`, data)
      .then((response) => response.data)
  }

  addBillingMethod(data) {
    return this.client
      .post(`/v3/billing-methods`, data)
      .then((response) => response.data)
  }

  removeBillingMethod(billingMethodId) {
    return this.client
      .delete(`/v3/billing-methods/${billingMethodId}`)
      .then((response) => response.data)
  }

  addPayoutMethod(data) {
    return this.client
      .post(`/v3/payout-methods`, data)
      .then((response) => response.data)
  }

  removePayoutMethod(payoutMethodId) {
    return this.client
      .delete(`/v3/payout-methods/${payoutMethodId}`)
      .then((response) => response.data)
  }

  charge(data) {
    return this.client
      .post(`/v3/charges`, data)
      .then((response) => response.data)
  }

  updateBillingMethod(billingMethodId, data) {
    return this.client
      .patch(`/v3/billing-methods/${billingMethodId}`, data)
      .then((response) => response.data)
  }

  depositCredit(billingMethodId, data) {
    return this.client
      .post(`/v3/billing-methods/${billingMethodId}/deposit`, data)
      .then((response) => response.data)
  }

  adjustCredit(billingMethodId, data) {
    return this.client
      .post(`/v3/billing-methods/${billingMethodId}/adjust`, data)
      .then((response) => response.data)
  }

  getTransactions(billingMethodId) {
    return this.client
      .get(`/v3/billing-methods/${billingMethodId}/transactions`)
      .then((response) => response.data)
  }

  getTransactionsByIds(ids) {
    return this.client
      .post(`/v3/transactions/id-query`, { ids })
      .then(this._data)
  }

  getPayoutMethod(userPublicId) {
    return this.client
      .get(`/v3/users/${userPublicId}/payout-method`)
      .then((response) => response.data)
  }

  getPayoutMethodStatus(userPublicId) {
    return this.client
      .get(`/v3/users/${userPublicId}/payout-method/status`)
      .then((response) => response.data)
  }
}

class SkillAPI extends AxiosAPIBase {
  host = env.USER_API
  path = 'v1/skills'
}

class RoleAPI extends AxiosAPIBase {
  host = env.USER_API
  path = 'v1/roles'
}

class PermissionAPI extends AxiosAPIBase {
  host = env.USER_API
  path = 'v1/permissions'
}

class UserAccessAPI extends AxiosAPIBase {
  host = env.USER_API
  path = 'v1/users/:userId/access'
}

class ImpersonationAPI extends AxiosAPIBase {
  host = env.USER_API
  path = 'v1/impersonation'
}

class UserSkillAPI extends AxiosAPIBase {
  host = env.USER_API

  async patch(id, data, query = {}) {
    const { userId, ...queryRest } = query
    return this.client
      .patch(`/v1/users/${userId}/skills`, data, { params: queryRest })
      .then((r) => r.data)
  }
}

function mutateJobClientWithSupportedEngineVersionParam(innerClient) {
  innerClient.interceptors.request.use((config) => {
    config.params = config.params || {}
    Object.assign(config.params, ENGINE_VERSION_PARAMS)

    return config
  })
  return innerClient
}

/**
 * Sanitizes a file name.
 * @param {string} str
 */
function sanitizeFileName(str) {
  return str.replace(/\//g, '_').replace(/\\/g, '_')
}
