import { toJS, computed, observable } from 'mobx'
import { Model } from 'libx'
import * as ducttape from './ducttape'

export default class LegendVersion extends Model {
  questions = []
  milestones = []
  infoLines = []
  workspace_id = null

  @observable
  name = ''

  @observable
  description = ''

  @observable
  version = 0

  @observable
  concurrency_version = 0

  @observable
  legend_id = null

  @observable
  last_updated_by_id = null

  @observable
  last_updated_by_public_id = null

  @observable
  date_modified = null

  @observable
  date_published = null

  @observable
  published = false

  @observable
  version_notes = null

  @observable
  view_order = 0

  @computed
  get workspace() {
    return this.rootStore.workspaceStore.workspaces.get(this.workspace_id)
  }

  constructor(attrs, opts) {
    super(null, opts)
    this.set(attrs, opts)
  }

  @computed
  get legend() {
    return this.rootStore.legendStore.legends.get(this.id)
  }

  @computed
  get isLatestVersion() {
    const versions = this.rootStore.legendStore.versionsFor(this.id)
    return versions[0].version === this.version
  }

  @computed
  get lastUpdatedBy() {
    if (!this.legend) {
      return null
    }
    return this.rootStore.memberStore.forWorkspaceAndUserPublicId(
      this.workspace_id,
      this.last_updated_by_public_id
    )
  }

  parse(json) {
    if (json.last_updated_by_id) {
      this.rootStore.memberStore.setAndFetch({
        workspace_id: json.workspace_id || this.workspace_id,
        user_id: json.last_updated_by_id,
        user_public_id: json.last_updated_by_public_id,
      })
    }
    return ducttape.fromLegacyLegend(json)
  }

  toJSON() {
    const { rootStore, set, pick, parse, toJSON, ...rest } = this
    return ducttape.toLegacyLegend(toJS(rest))
  }
}
