import { collection } from '@taxfyle/web-commons/lib/utils/collection'
import { pageActivityStream } from '@taxfyle/web-commons/lib/utils/domUtil'
import { BackgroundDataLoader } from '@taxfyle/web-commons/lib/utils/bufferUtil'
import { Store } from 'libx'
import compose from 'lodash/fp/compose'
import flatten from 'lodash/fp/flatten'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import uniq from 'lodash/fp/uniq'
import memoizee from 'memoizee'
import Conversation from 'messaging/Conversation'
import Message from 'messaging/Message'
import { observable } from 'mobx'
import { Subject } from 'rxjs'
import { concatMap } from 'rxjs/operators'

/**
 * Extracts Auth0 ID's from messages by looking at the sender
 * and recipients.
 *
 * @param  {Message[]} messages
 *
 * @return {string[]}
 * The ID's.
 */
const extractAuthPublicIdsFromMessages = compose(
  uniq,
  flatten,
  map((x) => [x.user_public_id, ...x.statuses.map((m) => m.user_public_id)])
)

/**
 * Message Store
 */
class MessageStore extends Store {
  @observable
  messages

  onlineStatusSubject = new Subject() // will get a string

  /**
   * Constructor.
   */
  constructor() {
    super(...arguments)
    this.getOnlineStatuses = this.getOnlineStatuses.bind(this)
    this.messages = this.collection({
      model: Message,
      idAttribute: 'id',
    })
    this.conversations = collection({
      create: (attrs) =>
        new Conversation(
          this.rootStore.memberStore.setAndFetch.bind(
            this.rootStore.memberStore
          )
        ),
    })

    this.bufferUtil = new BackgroundDataLoader(
      pageActivityStream(),
      this.rootStore.authStore.stale$
    )
    this.bufferUtil.start((x) => this.getOnlineStatuses(x))

    const messagingAPI = this.rootStore.messagingAPI

    messagingAPI.events$.pipe(concatMap((e) => this.handleEvent(e))).subscribe()

    this.fetchConversation = memoizee(this.fetchConversation.bind(this), {
      promise: true,
      length: 1,
    })
  }

  async handleEvent(data) {
    if (!data.type) {
      return
    }
    switch (data.type) {
      case 'PARTICIPANT_STATUS_CHANGED': {
        // the events can come in out of order...
        // lets just call the endpoint to get the correct one
        this.enqueueOnlineStatusSync(data.payload.user_public_id)

        break
      }
    }
  }

  get api() {
    return this.rootStore.api.messages
  }

  /**
   * Gets messages in the store.
   *
   * @param {string} conversationId
   */
  messagesIn(conversationId) {
    return this.messages.filter((x) => x.conversationId === conversationId)
  }

  primeConversation(props) {
    if (!props || !props.id) {
      return undefined
    }

    return this.conversations.set(props)
  }

  async fetchConversation(conversationId) {
    return this.api
      .getConversationById(conversationId)
      .then((data) => this.conversations.set(data))
  }

  /**
   * Loads all messages in the given conversation.
   *
   * @param  {string} conversationId
   * @return {Messages[]}
   */
  async fetchMessagesForConversation(conversationId, limit = 1000) {
    await this.fetchConversation(conversationId)
    const messages = sortBy('date_created')(
      (await this.api.getMessages(conversationId, { limit })).data
    )
    const authIds = extractAuthPublicIdsFromMessages(messages)
    const job = this.rootStore.jobStore.forConversation(conversationId)
    if (job) {
      // Make sure the users are in the store before we try to retrieve them.
      await this.rootStore.memberStore.fetchManyByPublicId(
        authIds.map((userId) => ({
          workspace_id: job.workspaceId,
          user_public_id: userId,
        }))
      )
    }

    await Promise.all(
      messages.map((messageDto) => this._maybeFetchPrompt(messageDto)),
      messages.map((messageDto) =>
        this._maybeFetchProgressionStep(messageDto, job.id)
      )
    )

    return this.messages.set(messages)
  }

  async sendNote({ conversationId, text }) {
    const message = await this.api.createNote(conversationId, text)
    return this.messages.set(message)
  }

  async sendMessage({ conversationId, text }) {
    const message = await this.api.createMessage(conversationId, text)
    return this.messages.set(message)
  }

  async deleteMessage(id) {
    await this.api.deleteMessage(id)
    return this.messages.remove(id)
  }

  enqueueOnlineStatusSync(id) {
    this.bufferUtil.load(id)
  }

  async getOnlineStatuses(ids) {
    const result = await this.api.isOnline(ids)

    await Promise.all(
      result.map(async (s) => {
        if (s) {
          let user = this.rootStore.userStore.users.find(
            (u) => u.public_id === s.user_public_id
          )
          if (!user) {
            user = await this.rootStore.userStore.fetchByPublicId(
              s.user_public_id
            )
          }
          if (user) {
            const isOnline =
              this.rootStore.sessionStore.user.public_id === s.user_public_id ||
              s.online

            user.set({
              online: isOnline,
              lastSynced: new Date(),
            })
          }
        }
      })
    )

    return result
  }

  async _maybeFetchPrompt(messageDto) {
    if (messageDto.content?.type !== 'EXTERNAL_REFERENCE') {
      return
    }

    if (messageDto.content.external_type !== 'worklayer.jobs.Prompt') {
      return
    }

    try {
      await this.rootStore.promptStore.getPrompt(messageDto.content.external_id)
    } catch (error) {
      console.error(
        `Loading prompt ${messageDto.content.external_id} failed`,
        error
      )
    }
  }

  async _maybeFetchProgressionStep(messageDto, jobId) {
    if (messageDto.content?.type !== 'EXTERNAL_REFERENCE') {
      return
    }

    if (messageDto.content.external_type !== 'worklayer.jobs.Progression') {
      return
    }

    try {
      await this.rootStore.jobProgressionStore.fetchProgression(jobId)
    } catch (error) {
      console.error(
        `Loading progression step ${messageDto.content.external_id} failed`,
        error
      )
    }
  }
}

export default MessageStore
