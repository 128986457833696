export const DEFAULT = Symbol('Default Match')

/**
 * Throws an error describing what case was not found.
 */
function noMatch(input) {
  throw new Error(`No match for "${input}"`)
}

/**
 * Matches on an object.
 */
function objectMatch(input, cases) {
  return Object.prototype.hasOwnProperty.call(cases, input)
    ? cases[input]
    : DEFAULT in cases
    ? cases[DEFAULT]
    : noMatch(input)
}

/**
 * Matches on an array.
 */
function arrayMatch(input, cases) {
  const found =
    cases.find(([key]) => key === input) ||
    cases.find(([key]) => key === DEFAULT)
  return found ? found[1] : noMatch()
}

/**
 * Very simple pattern matching on an object of cases.
 */
export function match(input, cases) {
  const result = Array.isArray(cases)
    ? arrayMatch(input, cases)
    : objectMatch(input, cases)

  return typeof result === 'function' ? result() : result
}

match.DEFAULT = DEFAULT
