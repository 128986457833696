import ContentLoading from 'components/ContentLoading'
import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import Searchbar from 'components/Searchbar'
import { SearchbarRadioButton } from 'components/SearchbarRadioGroup'
import ScreenProtector from 'iam/containers/ScreenProtector'
import { observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import RequirePerms from 'iam/containers/RequirePerms'
import CircularIconButton from 'components/CircularIconButton'
import FacetedSearch from './FacetedSearch'
import { JobTable } from './JobTable'
import cx from 'classnames'

import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'

import './Jobs.sass'

@observer
export default class JobsScreen extends React.Component {
  constructor() {
    super(...arguments)
    this.store = this.props.rootStore.jobsScreenStore
  }

  componentDidMount() {
    this.store.activate()
    const canViewTeams = this.props.rootStore.sessionStore.member.hasPermission(
      'ADMIN_TEAMS_VIEW'
    )
    if (canViewTeams) {
      this.props.rootStore.teamStore.findTeams({
        workspace_id: this.props.rootStore.sessionStore.workspace.id,
      })
    }
  }

  renderJobs() {
    const noData = this.store.jobs.length === 0

    return (
      <div>
        <div className="jobs-container">
          {noData ? (
            <EmptyState title="Jobs" className="full-width">
              No jobs to list.
            </EmptyState>
          ) : (
            <JobTable jobs={this.store.jobs} store={this.store} />
          )}
          <FacetedSearch store={this.store} />
        </div>
        <InfiniteScrollZone scrollProvider={this.store.scrollProvider}>
          {({ isDone }) => (isDone ? null : <ContentLoading smaller />)}
        </InfiniteScrollZone>
      </div>
    )
  }

  renderRadioButton(prop, text) {
    return (
      <SearchbarRadioButton
        onClick={() => this.store.changeJobFilter(prop)}
        checked={this.store.status === prop}
      >
        {text}
      </SearchbarRadioButton>
    )
  }

  render() {
    return (
      <ScreenProtector allOf={['ADMIN_JOB_LIST']}>
        <div className="jobs-screen">
          <Helmet title="Jobs" />
          <div className="ListArea">
            <Searchbar
              placeholder={T('Web.Dashboard.Search', 'Search for jobs')}
              value={this.store.searchText}
              onChange={this.store.changeSearchText}
              data-test="job-search-field"
            >
              <RequirePerms allOf={['ADMIN_JOB_LIST']}>
                <CircularIconButton
                  small
                  info
                  onClick={this.store.toggleShowInactive}
                  className={cx(
                    'searchButtonSpacing',
                    !this.store.showInactive && 'notSelectedButton'
                  )}
                >
                  <Icon name="minus-circle" size={16} />
                </CircularIconButton>
              </RequirePerms>
              <IconButton onClick={this.store.toggleFilter}>
                <Icon material name="filter_list" />
              </IconButton>
            </Searchbar>

            {this.store.scrollProvider.fetch.pending &&
            this.store.jobs.length === 0 ? (
              <ContentLoading />
            ) : (
              this.renderJobs()
            )}
          </div>
        </div>
      </ScreenProtector>
    )
  }
}
