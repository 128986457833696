import React from 'react'
import { observer } from 'mobx-react'
import { AsyncPaginate } from 'react-select-async-paginate'

/**
 * A react-select-based dropdown to search for paged entities.
 */

export default observer(function EntitySelector({
  pageSize = 10,
  getOptionLabel,
  entityFilter,
  allowEmpty,
  loadEntities,
  value,
  ...other
}) {
  const internalStateRef = React.useRef({
    options: allowEmpty ? [{ id: '-1' }] : [],
    cursor: '',
  })

  const getOptionalValueInternal = React.useCallback((o) => o.id, [])
  const getOptionLabelInternal = React.useCallback(
    (o) => {
      if (o.id === '-1') {
        return 'None'
      }
      return getOptionLabel(o)
    },
    [getOptionLabel]
  )

  const loadOptions = React.useCallback(
    async (search, prevOptions) => {
      const internalState = internalStateRef.current

      // Load options
      if (internalState.cursor != null) {
        const result = await loadEntities({
          limit: pageSize,
          after: internalState.cursor,
          search_text: search,
        })
          .then((result) => {
            internalState.cursor = result.cursor
            return result.data
          })
          .then((data) => (entityFilter ? data.filter(entityFilter) : data))

        internalState.options.push(...result)
      }

      // Filter options if searching
      let filteredOptions = internalState.options
      if (search) {
        const searchLower = search.toLowerCase()
        filteredOptions = internalState.options.filter((o) =>
          getOptionLabel(o)?.toLowerCase()?.includes(searchLower)
        )
      }

      // Ensure only new options are returned
      const hasMore =
        internalState.cursor !== null ||
        prevOptions.length < filteredOptions.length
      const slicedOptions = filteredOptions.slice(
        prevOptions.length,
        prevOptions.length + pageSize
      )

      return {
        options: slicedOptions,
        hasMore: hasMore,
      }
    },
    [pageSize, getOptionLabel, allowEmpty, entityFilter]
  )

  return (
    <AsyncPaginate
      {...other}
      loadOptions={loadOptions}
      value={value || null}
      debounceTimeout={300}
      getOptionValue={getOptionalValueInternal}
      getOptionLabel={getOptionLabelInternal}
    />
  )
})
