import { computed } from 'mobx'
import { task } from 'mobx-task'
import TeamManagementStore from 'iam/TeamManagementStore'
import { sortArray } from 'utils/sortUtil'
import values from 'lodash/values'

export default class HQTeamManagementStore extends TeamManagementStore {
  /**
   * All teams of a workspace
   */
  @computed
  get teams() {
    return this.rootStore.teamStore.teams
  }

  /**
   * Fetches the teams.
   */
  @task
  async activate() {
    const canViewTeams = this.rootStore.sessionStore.member.hasPermission(
      'ADMIN_TEAMS_VIEW'
    )
    if (canViewTeams) {
      await this.rootStore.teamStore.findTeams({
        workspace_id: this.rootStore.sessionStore.workspace.id,
      })
    }
  }

  @computed
  get filtered() {
    if (this.searchText === '') {
      return sortArray(values(this.teams.items), this.sortField, this.sortOrder)
    }
    return sortArray(
      this.teams.filter(
        (s) =>
          s.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          s.owner.member.displayName
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
      ),
      this.sortField,
      this.sortOrder
    )
  }
}
