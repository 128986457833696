import React from 'react'
import { observer } from 'mobx-react'
import { ListItem, ListItemSegment, ListItemTitle } from 'components/ListItem'
import './styles.sass'

export default observer(function TeamListItem({ team }) {
  return (
    <ListItem className="team-list-item">
      <ListItemSegment pad stretch>
        <ListItemTitle>
          <span className="friendly-name">{team.name}</span>
        </ListItemTitle>
      </ListItemSegment>
    </ListItem>
  )
})
