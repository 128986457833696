import { Store } from 'libx'
import { browserHistory } from 'react-router'
import links from 'misc/link'

// Triggers the notification actions.
// Reason this is it's own store is so we can reuse the notification stuff.
export default class NotificationActionStore extends Store {
  showPreferences() {
    browserHistory.push(links.notificationPreferences())
  }

  triggerAction(notification) {
    const action = notification.action
    switch (action.type) {
      case 'ViewJob':
        browserHistory.push(links.job(action.job_id))
        break
    }
  }
}
