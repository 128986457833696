import { validatable } from '../mixins/validation'
import { observable } from 'mobx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { receiveErrors } from '../data/serverErrors'

/**
 * Staleness config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class StalenessConfigEditor {
  @observable
  @serializable
  enabled = false

  @observable
  @serializable
  recurring = false

  @observable
  @serializable
  stale_after = 0

  constructor(legendEditor) {
    this.legendEditor = legendEditor
  }

  hydrate(cfg) {
    cfg = cfg || {}
    this.enabled = cfg.enabled || false
    this.stale_after = cfg.stale_after || 0
    this.recurring = cfg.recurring || false
  }

  toJSON() {
    return serialize(this)
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    receiveErrors(this, inner)
  }
}
