import { Model } from 'libx'
import { observable, action } from 'mobx'
import { validationContext, required } from 'validx'

export default class MemberMetadataFieldsBuilder {
  @observable
  inputs = []

  validation = validationContext(this, {
    inputs: [
      ({ value }) =>
        value.every((input) => input.validation.reset().validate().isValid) ||
        'Some inputs are invalid.',
    ],
  })

  activate(inputs = [], workspaceId, workspaceSlug) {
    this.inputs.replace(
      inputs.map((i) => createMemberMetadataFieldsEditor(i, this))
    )
  }

  toJSON() {
    return this.inputs.map((i) => i.toJSON())
  }

  @action.bound
  add(data) {
    this.inputs.push(createMemberMetadataFieldsEditor(data, this))
  }

  @action.bound
  save(editor) {
    const context = editor.validation.reset().validate()
    if (context.errorsMap.size === 0) {
      editor.editing = false
    }
  }

  @action.bound
  cancelEditing(editor) {
    if (editor.isNew) {
      this.inputs.pop()
    } else {
      // reset values
      editor.title = editor.previousValues.title
      editor.description = editor.previousValues.description
      editor.type = editor.previousValues.type
      editor.active = editor.previousValues.active
      editor.previousValues = {}
      editor.permissions = editor.previousValues.permissions
    }

    editor.editing = false
  }
}

class MemberMetadataFieldsEditor extends Model {
  isNew = true
  previousValues = {}

  @observable
  type = 'TEXT'

  @observable
  title = ''

  @observable
  description = ''

  @observable
  active = true

  @observable
  editing = false

  @observable
  id = ''

  @observable
  permissions = ['ADMIN_WRITE', 'USER_READ', 'USER_WRITE']

  validation = validationContext(this, {
    title: [required()],
    type: [required()],
    permissions: [],
    description: [],
  })

  toJSON() {
    return {
      title: this.title,
      type: this.type,
      description: this.description,
      active: this.active,
      permissions: this.permissions || [],
      id:
        this.id ||
        this.title
          .replace(/\W+/g, ' ')
          .split(/ |\B(?=[A-Z])/)
          .map((word) => word.toUpperCase())
          .join('_'),
    }
  }

  @action.bound
  startEditing() {
    this.isNew = false
    this.previousValues = {
      title: this.title,
      description: this.description,
      type: this.type,
      active: this.active,
      permissions: this.permissions,
    }
    this.editing = true
  }

  parse({
    title = '',
    type = 'TEXT',
    description = '',
    active = true,
    editing = false,
    id = '',
    permissions = ['ADMIN_WRITE', 'USER_READ', 'USER_WRITE'],
  }) {
    return { title, type, description, active, editing, id, permissions }
  }
}

function createMemberMetadataFieldsEditor(data, builder) {
  return new MemberMetadataFieldsEditor(data, {
    builder,
    parse: true,
    stripUndefined: true,
  })
}
