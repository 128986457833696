import cx from 'classnames'
import { observer } from 'mobx-react'
import Link from 'components/Link'
import React from 'react'
import styles from './BetterTable.m.sass'

function sortStatus(field, store) {
  if (!field) {
    return ''
  }
  return store.sortField && store.sortField === field
    ? 'sortable ' + store.sortOrder
    : 'sortable'
}

function sortToggle(field, store) {
  if (!field) {
    return
  }

  let order = !store.sortOrder
    ? 'desc'
    : store.sortOrder === 'desc'
    ? 'asc'
    : null

  if (store.sortField && store.sortField !== field) {
    order = 'desc'
  }

  store.changeSort(order === null ? null : field, field === null ? null : order)
}

export const SortableHeader = observer(function SortableHeader({
  store,
  label,
  field,
}) {
  return (
    <TableHeadCell
      className={sortStatus(field, store)}
      onClick={() => sortToggle(field, store)}
    >
      {label}
      <i
        className={
          store.sortField === field ? 'fa fa-sort-' + store.sortOrder : ''
        }
      />
    </TableHeadCell>
  )
})

export const Table = ({ children, className, ...props }) => {
  return (
    <div className={cx(styles.table, className)} {...props}>
      {children}
    </div>
  )
}

export const TableHead = ({ children, className, ...props }) => {
  return (
    <div className={cx(styles.thead, className)} {...props}>
      {children}
    </div>
  )
}

export const TableHeadCell = ({ children, className, ...props }) => {
  return (
    <div className={cx(styles.th, className)} {...props}>
      {children}
    </div>
  )
}

export const TableBody = ({ children, className, ...props }) => {
  return (
    <div className={cx(styles.tbody, className)} {...props}>
      {children}
    </div>
  )
}

export const TableRow = ({ children, className, ...props }) => {
  return (
    <div className={cx(styles.tr, className)} {...props}>
      {children}
    </div>
  )
}

export const TableCell = ({ children, className, ...props }) => {
  return (
    <div className={cx(styles.td, className)} {...props}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export const TableRowLink = ({ children, link, className, ...props }) => {
  return (
    <Link
      to={link}
      className={cx(styles.tr, styles.isLink, className)}
      {...props}
    >
      {children}
    </Link>
  )
}

export const TableCellLink = ({ children, link, className, ...props }) => {
  return (
    <Link
      to={link}
      className={cx(styles.td, styles.isLink, className)}
      {...props}
    >
      {children}
    </Link>
  )
}
