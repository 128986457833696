import { observable, computed, action } from 'mobx'

/**
 * Job transfer UI state.
 */
class JobTransferState {
  @observable
  provider = null

  @observable
  availableTeams = []

  @observable
  teamId = ''

  @observable
  isTransfering = false

  @observable
  showing = false

  @observable
  reason = ''

  @observable
  error = ''

  @observable
  target = 'client'

  constructor(detailsStore, memberStore, teamMemberStore) {
    this.detailsStore = detailsStore
    this.memberStore = memberStore
    this.teamMemberStore = teamMemberStore
    this.init()
  }

  @computed
  get job() {
    return this.detailsStore.job
  }

  @computed
  get didChangeTarget() {
    if (this.provider === null) return false

    const currentPro = this.detailsStore.job.members.find(
      (x) => x.role === 'CHAMPION' && x.type === 'PROVIDER'
    )

    if (this.detailsStore.job) {
      return this.target === 'client'
        ? this.detailsStore.job.client !== this.provider ||
            this.teamId !== this.detailsStore.job.ownerTeamId
        : this.detailsStore.job.provider !== this.provider ||
            (currentPro === undefined && this.teamId !== '') ||
            (currentPro && currentPro.teamId !== this.teamId)
    }
    return false
  }

  @action.bound
  init(target) {
    this.target = target || 'client'
    this.provider = null
    if (this.job) {
      this.provider =
        this.target === 'client' ? this.job.client : this.job.provider
    }

    this.isTransfering = false
    this.reason = ''
    this.teamId = ''
    this.availableTeams = []
  }

  @action.bound
  show(target) {
    this.init(target)
    this.showing = true
  }

  @action.bound
  hideJobTransfer() {
    this.showing = false
  }

  @action.bound
  async selectProvider(user) {
    this.teamId = ''
    this.provider = user
    this.availableTeams = await this.teamMemberStore.fetchUserTeamMembersByPublicId(
      {
        workspace_id: user.workspaceId,
        user_id: user.userPublicId,
      }
    )
  }

  @action.bound
  async transfer() {
    this.error = ''
    if (!this.provider) return
    this.isTransfering = true
    try {
      if (this.target === 'client') {
        await this.detailsStore.rootStore.jobStore.transferJobToClient(
          this.job.id,
          this.provider,
          this.reason,
          this.teamId
        )
      } else {
        await this.detailsStore.rootStore.jobStore.transferJobToPro(
          this.job.id,
          this.provider,
          this.reason
        )
      }

      this.hideJobTransfer()
    } catch (err) {
      this.error = err.message
      this.isTransfering = false
    }
  }
}

export default JobTransferState
