import React from 'react'
import cx from 'classnames'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import { ListItem, ListItemSegment } from 'components/ListItem'
import Icon from 'components/Icon'
import Avatar from 'components/Avatar'
import './CollaboratorListItem.sass'
import { Dropdown, DropdownItem, DropdownItemIcon } from 'components/Dropdown'
import ConfirmDialog from 'components/ConfirmDialog'
import ConfirmDialogState from 'components/ConfirmDialog/ConfirmDialogState'
import { Input } from 'components/Form'
import RequirePerms from 'iam/containers/RequirePerms'

@observer
export default class CollaboratorListItem extends React.Component {
  @observable
  reason = ''

  @action
  resetReason() {
    this.reason = ''
  }

  removeProviderConfirm = new ConfirmDialogState()

  remove = async () => {
    this.resetReason()
    if (await this.removeProviderConfirm.show()) {
      this.props.onRemoveClick(this.reason)
    }
  }

  render() {
    const { role, type, user, canManage } = this.props
    return (
      <ListItem className={cx('collaboratorListItem')}>
        <ListItemSegment pad>
          <Avatar url={user.avatar} fallback={user.avatarFallback} size={32} />
        </ListItemSegment>
        <ListItemSegment stretch>
          <div className="userDisplayName">
            <span className="userDisplayNameLabel">{user.displayName}</span>
            {role === 'CHAMPION' && (
              <Icon
                material
                name="star"
                title="Champion"
                className="providerTypeIcon"
                size={16}
                data-test={`collaborator-list-item-champion-${user.userId}`}
              />
            )}
          </div>
          <div
            className="userDetails"
            data-test={`collaborator-list-item-${user.userId}`}
            data-test-collaborator-type={type}
            data-test-collaborator-role={role}
          >
            <RequirePerms allOf={['ADMIN_USER_READ', 'ADMIN_PRO_PI_VIEW']}>
              <span className="userEmail">{user.email}</span>
              &nbsp; &bull; &nbsp;
              <span className="userId">{user.userId}</span>
            </RequirePerms>
          </div>
        </ListItemSegment>
        {canManage && role !== 'CHAMPION' && (
          <ListItemSegment pad>
            <Dropdown
              position="right"
              button={({ className, innerRef, ...props }) => (
                <a
                  ref={innerRef}
                  className={cx(className, 'button is-icon-only')}
                  {...props}
                >
                  <Icon material name="more vert" />
                </a>
              )}
            >
              <DropdownItem onClick={this.remove}>
                <DropdownItemIcon>
                  <Icon material name="remove circle" />
                </DropdownItemIcon>
                Remove
              </DropdownItem>
            </Dropdown>
          </ListItemSegment>
        )}
        <ConfirmDialog
          state={this.removeProviderConfirm}
          title="Remove Pro"
          yes="Remove"
          danger
        >
          Do you really want to remove <strong>{user.displayName}</strong>?
          <br />
          <br />
          <Input
            model={this}
            field="reason"
            label="Reason"
            help="Why are you removing the Pro? This text is public and can be seen by the Client and Pros."
          />
        </ConfirmDialog>
      </ListItem>
    )
  }
}
