import { action, observable, computed } from 'mobx'

import InfiniteScrollProvider from 'data/InfiniteScrollProvider'

export default class UserCouponListModalState {
  scrollProviders = new Map()

  @observable
  showing = false

  @observable
  coupon

  @observable
  userCoupons = []

  @observable
  sortField = null

  @observable
  sortOrder = null

  @computed
  get scrollProvider() {
    const existing = this.scrollProviders.get()

    if (existing) {
      return existing
    }

    const provider = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: 10,
      fetchItems: this._fetchUserCoupons.bind(this),
    })

    this.scrollProviders.set(provider)

    return provider
  }

  constructor(userCouponStore, sessionStore) {
    this.userCouponStore = userCouponStore
    this.sessionStore = sessionStore
  }

  @action.bound
  changeSort(field, order) {
    this.sortField = field
    this.sortOrder = order
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false

    this.scrollProvider.reset()
    this.userCoupons.replace([])
  }

  @action.bound
  async loadAllData() {
    let hasMore = await this.scrollProvider.more()

    while (hasMore && hasMore.data && hasMore.data.length !== 0) {
      hasMore = await this.scrollProvider.more()
    }

    if (this.userCoupons.length === 0) {
      return
    }

    return {
      filename: 'export.csv',
      data: this.userCoupons.map((x) => {
        return {
          ...x,
          coupon: x.coupon,
          dateRedeemed: x.dateRedeemed.format('LL LT'),
          displayOffType: x.coupon.offType.displayType,
          displayAmountOff:
            x.coupon.offType.type === 'AMOUNT_OFF'
              ? `$${x.coupon.offType.amountOff} off`
              : `${x.coupon.offType.percentOff}% off`,
          jobNames: x.jobs.map((job) => job.name).join(', '),
        }
      }),
      headers: [
        {
          label: 'Code',
          key: 'coupon.code',
        },
        {
          label: 'Job',
          key: 'jobNames',
        },
        {
          label: 'Member',
          key: 'member.displayName',
        },
        {
          label: 'Date Redeemed',
          key: 'dateRedeemed',
        },
        {
          label: 'Off Type',
          key: 'displayOffType',
        },
        {
          label: 'Amount Off',
          key: 'displayAmountOff',
        },
      ],
    }
  }

  async _fetchUserCoupons(p) {
    const result = await this.userCouponStore.fetchClaimsForCoupon({
      ...p,
      redeemed: true,
      couponId: this.coupon.id,
    })

    this.userCoupons.push(...result.data)

    return result
  }
}
