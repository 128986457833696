import { observable, action } from 'mobx'
import WorkspaceEditorState from '../../containers/WorkspaceEditor/WorkspaceEditorState'
import { task } from 'mobx-task'

export default class NewWorkspaceState {
  type = 'new'

  @observable
  workspace

  constructor({ workspace, workspaceManagementStore, rootStore }) {
    this.workspace = workspace
    this.rootStore = rootStore
    this.workspaceManagementStore = workspaceManagementStore
    this.editor = new WorkspaceEditorState({
      rootStore,
      onSaved: this.onSaved,
    })
  }

  @action.bound
  onSaved(workspace) {
    this.rootStore.flashMessageStore
      .create({ message: 'Workspace created!', type: 'success' })
      .autoDismiss()
    this.workspaceManagementStore.select(workspace)
  }

  @task.resolved
  async activate() {
    await this.editor.activate()
  }
}
