import { Store } from 'libx'
import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'

export default class UserRolesEditorStore extends Store {
  @observable
  member = null

  @observable
  showing = false

  @observable
  role = null

  @action.bound
  show(member) {
    this.rootStore.permissionStore.find()
    this.rootStore.roleStore.find({
      workspace_id: this.rootStore.sessionStore.workspace.id,
    })
    this.member = member
    this.role = member ? member.roles.slice()[0] : null
    this.showing = true
  }

  @computed
  get busy() {
    return (
      this.addRole.pending || this.removeRole.pending || this.changeRole.pending
    )
  }

  @computed
  get allRoles() {
    return this.rootStore.roleStore.forWorkspace(
      this.rootStore.sessionStore.workspace.id
    )
  }

  @computed
  get availableRoles() {
    return this.rootStore.roleStore
      .forWorkspace(this.rootStore.sessionStore.workspace.id)
      .filter((x) => this.roles.includes(x) === false)
  }

  @computed
  get roles() {
    return this.member ? this.member.roles.slice() : []
  }

  @action.bound
  hide() {
    this.showing = false
  }

  hasRole(id) {
    return this.member.roles.some((x) => x.id === id)
  }

  @task.resolved
  addRole(roleId) {
    return this.rootStore.memberStore.addRoleByPublicId(
      {
        workspace_id: this.member.workspaceId,
        user_id: this.member.userPublicId,
      },
      roleId
    )
  }

  get isWorkspaceMemberSingleRole() {
    return getFeatureToggleClient().variation(
      'HQ.WorkspaceMemberSingleRole',
      false
    )
  }

  @task.resolved
  async changeRole(roleId) {
    // If the current role is selected, do nothing
    if (this.roles.find((r) => r.id === roleId)) {
      return
    }

    this.role = this.allRoles.find((r) => r.id === roleId)

    const result = await this.rootStore.memberStore.addRoleByPublicId(
      {
        workspace_id: this.member.workspaceId,
        user_id: this.member.userPublicId,
      },
      roleId
    )

    Promise.all(
      this.roles
        .filter((role) => role.id !== roleId)
        .map((role) => this.removeRole(role.id))
    )

    return result
  }

  @task.resolved
  removeRole(roleId) {
    return this.rootStore.memberStore.removeRoleByPublicId(
      {
        workspace_id: this.member.workspaceId,
        user_id: this.member.userPublicId,
      },
      roleId
    )
  }
}
