import { Model } from 'libx'
import { observable } from 'mobx'
import { omitUndefined } from 'utils/objectUtil'

/**
 * Published Legend.
 */
export default class PublishedLegend extends Model {
  @observable id = ''
  @observable version = 0
  @observable workspaceId = ''
  @observable name = ''
  @observable description = ''
  @observable imageUrl = null

  parse(json = {}) {
    return omitUndefined({
      id: json.id,
      version: json.version,
      workspaceId: json.workspace_id,
      name: json.name,
      description: json.description,
      imageUrl: json.image_url,
    })
  }
}
