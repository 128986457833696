import 'intl'
import 'intl/locale-data/jsonp/en'
import AppRouter from './shell/AppRouter'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { autorun } from 'mobx'
import { browserHistory } from 'react-router'
import RootStore from './stores'
import { Provider } from 'mobx-react'
import link from 'misc/link'
import { desaturate, lighten, darken, saturate, parseToRgb } from 'polished'
import './favicon.ico'
import { HelmetProvider } from 'react-helmet-async'
import {
  getFeatureToggleClient,
  initializeToggleClient,
} from 'misc/featureToggles'
import Dictionary from '@taxfyle/web-commons/lib/utils/dictionary'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import env from 'misc/env'

const featureToggleClient = getFeatureToggleClient()

// Americurh, fuck yeah!
moment.locale('en-US')

/**
 * initialize feature toggle client and
 * then render the app.
 */
initializeToggleClient(featureToggleClient)
  .then(() => undefined)
  .catch((err) =>
    console.error('Error initializing feature toggle client', err)
  )
  .finally(() => initApp())

function setBrandingVariables(workspace) {
  const primary =
    workspace.branding &&
    workspace.branding.colors &&
    workspace.branding.colors.primary

  const themePrimary = primary || null
  const primaryDarken = saturate(0.3, darken(0.1, primary))
  const primaryLighten = desaturate(0.1, lighten(0.3, primary))

  function toRgbStr(color) {
    const asRgb = parseToRgb(color)
    return `${asRgb.red}, ${asRgb.green}, ${asRgb.blue}`
  }

  document.body.style.setProperty('--theme-base', themePrimary)
  document.body.style.setProperty('--theme-darken', primaryDarken)
  document.body.style.setProperty('--theme-lighten', primaryLighten)
  document.body.style.setProperty('--theme-base-rgb', toRgbStr(themePrimary))
  document.body.style.setProperty('--theme-darken-rgb', toRgbStr(primaryDarken))
  document.body.style.setProperty(
    '--theme-lighten-rgb',
    toRgbStr(primaryLighten)
  )
}

function initApp() {
  const rootStore = new RootStore()
  rootStore.onTogglesInitialized()

  if (process.env.NODE_ENV !== 'production') {
    window.rootStore = rootStore
  }

  // When the session's currentUser is set to null, redirect to login.
  autorun(() => {
    if (rootStore.authStore.currentUser === null) {
      browserHistory.go('/login')
    }
  })

  rootStore.sessionStore.onWorkspaceSwitched(() => {
    browserHistory.push(
      `${link.welcome(rootStore.sessionStore.workspace.slug)}`
    )
  })

  rootStore.sessionStore.onWorkspaceSelected(async (workspace, member) => {
    Dictionary.setTranslations(
      rootStore.translationStore.dictonaryForWorkspace(workspace.id)
    )

    LogRocket.identify(member.userId, {
      name: `${member.givenName} ${member.familyName}`,
      email: member.email,

      // Add your own custom user variables here, ie:
      workspaceId: member.workspaceId,
    })

    setBrandingVariables(workspace)
  })

  if (env.LOGROCKET_KEY) {
    LogRocket.init(env.LOGROCKET_KEY, {
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf('/messages') !== -1) {
            request.body = null
          }
          return request
        },
        responseSanitizer: (response) => {
          if (response.url.toLowerCase().indexOf('/messages') !== -1) {
            response.body = null
          }
          return response
        },
      },
    })
    setupLogRocketReact(LogRocket)
  }

  const render = (Component) => {
    ReactDOM.render(
      <HelmetProvider>
        <Provider rootStore={rootStore}>
          <Component rootStore={rootStore} />
        </Provider>
      </HelmetProvider>,
      document.getElementById('app')
    )
  }

  render(AppRouter)
}
