import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import {
  List,
  ListItem,
  ListItemBody,
  ListItemContent,
  ListItemActions,
  ItemDescription,
} from 'legend-builder/components/List'
import RemoveButton from 'legend-builder/components/RemoveButton'
import PermissionsSummary from 'components/PermissionsSummary'
import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'
import styles from './EditUserRolesModal.m.sass'
import { Dropdown, DropdownItem } from 'components/Dropdown'
import Icon from 'components/Icon'
@observer
export default class EditUserRolesModal extends React.Component {
  get isWorkspaceMemberSingleRole() {
    return getFeatureToggleClient().variation(
      'HQ.WorkspaceMemberSingleRole',
      false
    )
  }

  render() {
    const store = this.props.store
    if (!store.member) {
      return <div />
    }
    return (
      <Modal
        onRequestClose={() => store.hide()}
        isOpened={store.showing}
        position="top"
      >
        <Dialog>
          <DialogTitle>Edit member roles</DialogTitle>
          <DialogContent>
            {this.isWorkspaceMemberSingleRole ? (
              <Dropdown
                stretch
                className={styles.dropdown}
                button={<RoleDropdownButton role={store.role} />}
              >
                {store.allRoles.map((role) => (
                  <DropdownItem
                    data-test={`${role.name}-item`}
                    key={role.id}
                    onClick={() => store.changeRole(role.id)}
                  >
                    <ItemDescription title={role.name}>
                      {role.description}
                    </ItemDescription>
                  </DropdownItem>
                ))}
              </Dropdown>
            ) : (
              <List
                title={`${store.member.displayName} — Roles`}
                dropdownItems={store.availableRoles.map((role) => ({
                  id: role.id,
                  title: role.name,
                  description: role.description,
                }))}
                onAdd={(item) => store.busy || store.addRole(item.id)}
                disabled={store.availableRoles.length === 0 || store.busy}
              >
                {store.roles.map((role) => (
                  <ListItem key={role.id}>
                    <ListItemBody flex center>
                      <ListItemContent pad>
                        <p className={styles.name}>{role.name}</p>
                        <p className={styles.description}>{role.description}</p>
                      </ListItemContent>
                      <ListItemContent pad>
                        <div className={styles.permissionDescriptions}>
                          <PermissionsSummary
                            descriptions={role.permissionDescriptions}
                          />
                        </div>
                      </ListItemContent>
                      <ListItemActions>
                        <RemoveButton
                          disabled={store.busy}
                          onClick={() => store.removeRole(role.id)}
                        />
                      </ListItemActions>
                    </ListItemBody>
                  </ListItem>
                ))}
              </List>
            )}
          </DialogContent>
          <DialogFooter>
            <DialogButton disabled={store.busy} onClick={() => store.hide()}>
              Close
            </DialogButton>
          </DialogFooter>
        </Dialog>
      </Modal>
    )
  }
}

const RoleDropdownButton = observer(function RoleDropdownButton({
  onClick,
  innerRef,
  role,
}) {
  return (
    <div className={styles.roleDropdown}>
      <a
        data-test="add-button"
        ref={innerRef}
        onClick={onClick}
        className={styles.roleDropdownSelected}
      >
        {role?.name || 'Select a role'}
        <Icon size={16} auto material name="expand_more" />
      </a>
    </div>
  )
})
