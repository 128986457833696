import { observable, action, reaction, computed } from 'mobx'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import identity from 'lodash/identity'
import {
  analyze,
  createParser,
  createLexer,
  SymbolTable,
  Variance,
  unify,
  hasErrors,
} from '@taxfyle/ryno'
import { Types } from '../data/Type'

/**
 * Ryno editor model.
 */
export default class RynoEditor {
  /**
   * Underlying value.
   */
  @observable value = ''

  /**
   * Ryno diagnostics from analyzing.
   */
  @observable.ref diagnostics = []

  /**
   * The last used symbol table.
   */
  @observable.ref lastUsedSymbolTable = null

  /**
   * The last line of the formula.
   * Info Lines List Items use this to give the user an idea
   * of what the formula is all about.
   */
  @observable lastLine = ''

  /**
   * When the view mounts, it registers a callback stored here.
   */
  _selectRangeImpl = identity

  /**
   * Constructor for the Ryno Editor model.
   */
  constructor({
    getSymbolTable,
    getNeededType,
    diagnosticsFilter,
    required,
  } = {}) {
    this.required = typeof required === 'function' ? required : () => required
    this.getSymbolTable = getSymbolTable
    this.getNeededType = getNeededType
    this.diagnosticsFilter = diagnosticsFilter || identity
    this.scheduleAnalyze = debounce(this.analyze.bind(this), 400, {
      leading: false,
      trailing: true,
    })
    reaction(
      () => [this.value, this.getNeededType(), this.getSymbolTable()],
      this.scheduleAnalyze
    )
  }

  /**
   * Sorted diagnostics.
   */
  @computed
  get sortedDiagnostics() {
    return sortBy(this.diagnostics, 'startPos')
  }

  /**
   * Autocomplete list.
   */
  @computed
  get autocompleteList() {
    const table = this.lastUsedSymbolTable || this.getSymbolTable()
    const result = table
      .listIdentifierSymbols()
      .map((symbol) => symbol.name)
      .filter(Boolean)
    return sortBy(result, (x) => x.toLowerCase())
  }

  /**
   * Analyzes the code.
   */
  @action.bound
  analyze() {
    if (!this.value) {
      this.diagnostics = []
      this.lastUsedSymbolTable = null
      if (this.required()) {
        this.diagnostics.push({
          severity: 'error',
          message: 'This formula is required',
        })
        return false
      }
      return true
    }

    const ast = this.parseSource()
    this.setLastLine(ast)
    const table = new SymbolTable(
      'sub',
      1,
      this.getSymbolTable && this.getSymbolTable()
    )
    this.lastUsedSymbolTable = table
    const result = analyze(ast, table)

    const diagnostics = [...ast.diagnostics, ...result.diagnostics].filter(
      this.diagnosticsFilter
    )
    const neededType = this.getNeededType && this.getNeededType()
    // Only unify the end result if the diagnostics are clean.
    if (neededType && !diagnostics.some((d) => d.severity === 'error')) {
      const unifyResult = unify(
        result.type || Types.nothing,
        neededType,
        table,
        Variance.Covariant
      )
      if (hasErrors(unifyResult)) {
        diagnostics.push(
          ...unifyResult.errors.map((m) => ({
            severity: 'error',
            message: `Incompatible result type: ${m}`,
          }))
        )
      }
    }
    this.diagnostics = diagnostics
    return diagnostics.some((x) => x.severity === 'error') === false
  }

  /**
   * Sets the `lastLine` property which can be used by UI to render the last expression.
   *
   * @param {*} ast
   */
  setLastLine(ast) {
    if (ast.expressions.length === 0) {
      this.lastLine = ''
      return
    }

    if (ast.diagnostics.some((d) => d.severity === 'error')) {
      this.lastLine = `(there's a syntax error in your formula)`
      return
    }

    const lastExpr = ast.expressions[ast.expressions.length - 1]
    const source = this.getFixedValue()
    this.lastLine = source.substring(lastExpr.startPos)
  }

  /**
   * Sets the value.
   *
   * @param {string} value The formula.
   * @param {boolean} triggerAnalyze Whether to trigger an analysis after setting.
   */
  @action.bound
  setValue(value, triggerAnalyze) {
    this.value = (value || '').toString()
    if (triggerAnalyze) {
      this.analyze()
    }
  }

  /**
   * Selects the diagnostic text range in the view.
   */
  selectRangeForDiagnostic(diagnostic) {
    if (diagnostic.loc) {
      this._selectRangeImpl(diagnostic.loc.start, diagnostic.loc.end)
    }
  }

  /**
   * Parses the current value to an AST.
   */
  parseSource() {
    const value = this.getFixedValue()
    return createParser(createLexer(value)).parseProgram()
  }

  /**
   * Returns the value.
   */
  toJSON() {
    return this.getFixedValue()
  }

  /**
   * Replaces tabs with spaces.
   */
  getFixedValue() {
    return (this.value || '').replace(/\t/g, '  ')
  }

  /**
   * Binds the vie whelpers.
   */
  bindViewHelpers(helpers = {}) {
    this._selectRangeImpl = helpers.selectRange || identity
  }
}
