import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ContentLoading from 'components/ContentLoading'
import ErrorLabel from 'components/ErrorLabel'
import { FormGroup, Input, Help, getError, Select } from 'components/Form'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import QuestionGrid from 'jobs/components/QuestionsEditor'
import { HtmlClass } from 'components/ClassName'
import styles from './CreateJobModal.m.sass'
import UserSelector from 'components/UserSelector'
import { filterOutAllIds } from 'utils/userSelectorFilterUtil'

@observer
export default class CreateJobModal extends React.Component {
  renderDialog() {
    const { store } = this.props
    if (!store.user) return false
    return (
      <div className={styles.jobCreateDialog}>
        <DialogTitle>Create Job</DialogTitle>
        <DialogContent>
          <p>
            <strong>IMPORTANT:</strong> Jobs submitted from HQ default to
            external billing. Meaning, if payment is required from the client
            the job must be submitted from the client portal.
          </p>
          <hr />
          <Input
            label="Name"
            placeholder="The Name of the Job"
            model={store}
            field="name"
            help="The main name of the Job."
          />
          {store.billUserAllowed && (
            <Input
              label="Bill External"
              type="checkbox"
              disabled={store.billingExternalDisabled}
              model={store}
              field="billExternal"
              help="Should we charge the default billing method or will this be handled manually."
            />
          )}
          {store.allowProSelection && (
            <FormGroup label="Assign a provider to the job:">
              <UserSelector
                allowEmpty
                userFilter={filterOutAllIds([store.user.userId])}
                workspaceId={store.rootStore.sessionStore.workspace.id}
                memberStore={store.rootStore.memberStore}
                name="new-provider"
                onChange={store.selectProvider}
                value={store.assignToPro}
              />
            </FormGroup>
          )}
          {store.availableTeams.length > 0 && (
            <Select
              model={store}
              field="teamId"
              label="Team"
              disabled={store.canChange}
              help="Should this be assigned to a team?"
            >
              <option value="">None</option>
              {store.availableTeams.map((t) => (
                <option key={t.id} value={t.team.id}>
                  {t.team.name}
                </option>
              ))}
            </Select>
          )}
          <Select
            label="Legend"
            help="The Legend for the Workflow. You cannot change the Legend after selecting one."
            model={store}
            field="selectedLegend"
            onChange={(e) => store.selectLegend(e.target.value)}
            value={store.selectedLegend.id}
            disabled={store.canChange}
          >
            <option key={0} value="">
              None
            </option>
            {store.legends.map((t) => (
              <option key={t.id} value={t.id}>
                {t.name}
              </option>
            ))}
          </Select>
          {store.job && (
            <FormGroup label="Questions">
              <QuestionGrid
                questions={store.questions}
                answers={store.answers}
                disabled={store.fetchQuestions.pending}
                loading={
                  store.questions.length === 0 && store.fetchQuestions.pending
                }
                onChange={store.setAnswers}
                onUpdate={store.fetchQuestions}
              />
              <Help error={getError(store, 'answers')}></Help>
            </FormGroup>
          )}
          <ErrorLabel>
            {store.error ||
              (store.hasBreaker ? 'Cannot save a job with a breaker.' : null)}
          </ErrorLabel>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide}>Cancel</DialogButton>
          <DialogButton
            primary
            onClick={store.save}
            disabled={store.hasBreaker || store.busy}
          >
            Save & Close
          </DialogButton>
          <DialogButton
            danger
            onClick={store.submit}
            disabled={store.hasBreaker || store.busy}
          >
            Submit & Close
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        className="job-create-modal"
        position="center"
        dismissOnOutsideClick={false}
      >
        <HtmlClass className="disable-scrolling" />
        <Dialog>{store.busy ? <ContentLoading /> : this.renderDialog()}</Dialog>
      </Modal>
    )
  }
}
