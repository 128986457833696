import React from 'react'
import ScreenProtector from 'iam/containers/ScreenProtector'
import { observer } from 'mobx-react'
import PageWrapper from 'components/PageWrapper'
import styles from './Analytics.m.sass'
import EmptyState from 'components/EmptyState'

/**
 * Analytics.
 */
@observer
class Analytics extends React.Component {
  get analyticsScreenStore() {
    return this.props.rootStore.analyticsScreenStore
  }

  componentDidMount() {
    this.analyticsScreenStore.update()
  }

  renderUnavailable() {
    return (
      <EmptyState className={styles.emptyState} title="Analytics">
        Analytics is temporarily unavailable. Please try again later.
      </EmptyState>
    )
  }

  render() {
    return (
      <ScreenProtector allOf={['ADMIN_ANALYTICS_VIEW']}>
        <PageWrapper className={styles.root}>
          <div className={styles.widgetWrapper}>
            {this.analyticsScreenStore.error ? (
              this.renderUnavailable()
            ) : (
              <iframe
                src={this.analyticsScreenStore.iframeUrl}
                className={styles.dashboardWidget}
                frameBorder={0}
              />
            )}
          </div>
        </PageWrapper>
      </ScreenProtector>
    )
  }
}

export default Analytics
