import ContentLoading from 'components/ContentLoading'
import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import Searchbar from 'components/Searchbar'
import ScreenProtector from 'iam/containers/ScreenProtector'
import { observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import FacetedSearch from './FacetedSearch'
import styles from './Scoping.m.sass'
import { ScopingJobTable } from './ScopingTable'

@observer
export default class ScopingScreen extends React.Component {
  constructor() {
    super(...arguments)
    this.store = this.props.rootStore.scopingJobsScreenStore
  }

  componentDidMount() {
    this.store.activate()
  }

  renderJobs() {
    const noData = this.store.scopingJobs.length === 0

    return (
      <div>
        <div className={styles.jobsContainer}>
          {noData ? (
            <EmptyState title="Scoping Jobs" className={styles.fullWidth}>
              No scoping jobs to list.
            </EmptyState>
          ) : (
            <ScopingJobTable
              scopingJobs={this.store.scopingJobs}
              store={this.store}
            />
          )}
          <FacetedSearch store={this.store} />
        </div>
        <InfiniteScrollZone scrollProvider={this.store.scrollProvider}>
          {({ isDone }) => (isDone ? null : <ContentLoading smaller />)}
        </InfiniteScrollZone>
      </div>
    )
  }

  render() {
    return (
      <ScreenProtector allOf={['ADMIN_SCOPE_WORK_VIEW']}>
        <div className={styles.jobsScreen}>
          <Helmet title="Scoping" />
          <div className={styles.listArea}>
            <Searchbar
              placeholder="Search for Scoping Jobs"
              value={this.store.searchText}
              onChange={this.store.changeSearchText}
              data-test="scoping-job-search-field"
            >
              <IconButton onClick={this.store.toggleFilter}>
                <Icon material name="filter_list" />
              </IconButton>
            </Searchbar>

            {this.store.scrollProvider.fetch.pending &&
            this.store.scopingJobs.length === 0 ? (
              <ContentLoading />
            ) : (
              this.renderJobs()
            )}
          </div>
        </div>
      </ScreenProtector>
    )
  }
}
