import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import UserSelector from 'components/UserSelector'
import ContentLoading from 'components/ContentLoading'
import ErrorLabel from 'components/ErrorLabel'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import './TransferJobDialog.sass'
import { Input, Select } from 'components/Form'
import { filterOutAllClientsOf } from 'utils/userSelectorFilterUtil'

@observer
class TransferJobDialog extends React.Component {
  componentDidMount() {
    // store is a JobTransferState
    this.props.store.init()
  }

  renderDialog() {
    const { store } = this.props
    if (!store.job) {
      return null
    }
    return (
      <div className="transfer-job-dialog">
        <DialogTitle>Transfer job</DialogTitle>
        <DialogContent>
          <label>Current {store.target === 'client' ? 'Client' : 'Pro'}</label>
          <div className="transfer-job-dialog__current-provider">
            {store.target === 'client'
              ? store.job.client.displayName
              : store.job.provider?.displayName}
          </div>

          <br />

          <label htmlFor="transfer-job-dialog__new-provider">
            New {store.target === 'client' ? 'Client' : 'Pro'}
          </label>
          <div className="transfer-job-dialog__new-cpa-container">
            <UserSelector
              userFilter={filterOutAllClientsOf(store.job)}
              workspaceId={store.job.workspaceId}
              memberStore={store.memberStore}
              name="new-provider"
              onChange={store.selectProvider}
              value={store.provider}
            />
            {store.availableTeams.length > 0 && store.target === 'client' && (
              <Select
                model={store}
                field="teamId"
                label="To Team"
                help="Should this be assigned to a team?"
              >
                <option value="">None</option>
                {store.availableTeams.map((t) => (
                  <option key={t.id} value={t.team.id}>
                    {t.team.name}
                  </option>
                ))}
              </Select>
            )}
          </div>
          {store.job.paidAmount !== store.job.total &&
            store.target === 'client' && (
              <div className="warning">
                Make sure that the Client and/or Team you are transferring to
                has a valid billing method!
              </div>
            )}
          <ErrorLabel>{store.error}</ErrorLabel>

          <br />

          <Input
            model={store}
            field="reason"
            label="Reason"
            help="Why are you transferring? This text is public and can be seen by the Client and Pros."
          />
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hideJobTransfer}>Cancel</DialogButton>
          <DialogButton
            danger
            onClick={store.transfer}
            disabled={!store.didChangeTarget}
          >
            Transfer
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  renderTransferringStatus() {
    const { store } = this.props
    return (
      <div className="transferring-job">
        <div className="transferring-job__description">
          Transferring job to <strong>{store.provider.displayName}</strong>
          ...
          <div>
            <ContentLoading small />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hideJobTransfer}
        position="top"
      >
        <Dialog>
          {store.isTransfering
            ? this.renderTransferringStatus()
            : this.renderDialog()}
        </Dialog>
      </Modal>
    )
  }
}

export default TransferJobDialog
