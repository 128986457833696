/**
 * Replaces a URL segment with the specified value.
 * @example
 *   replaceSegment('/users/:id', 'id', '123) === '/users/123'
 */
export function replaceSegment(path, key, value) {
  let replaced = path
  const needle = ':' + key
  do {
    replaced = replaced.replace(needle, value)
  } while (replaced.indexOf(needle) > -1)
  return replaced
}

/**
 * Given an object, iterates it's own props and replaces the segments.
 */
export function replaceSegments(path, obj) {
  if (Array.isArray(obj)) {
    return path
  }

  return Object.keys(obj).reduce(
    (prev, cur) => replaceSegment(prev, cur, obj[cur]),
    path
  )
}
