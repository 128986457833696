import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import {
  SortableHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TableRowLink,
} from 'components/BetterTable'
import Icon from 'components/Icon'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import prune from 'underscore.string/prune'
import styles from './ScopingJobTable.m.sass'
import { toJobLink } from 'utils/generateJobLinkUtil'

// 70 was chosen for high threshold because when there are too many K1 and Rentals the CPA normally writes: SEE STATEMENT XYZ
// but we cannot parse this yet - so in those cases we lower the confidence level by 30 so the high treshold will hit
const THRESHOLD = 70

@observer
class ScopingJobTable extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.headers = [
      { label: T('Web.Common.Client', 'Client'), field: 'user_name.keyword' },
      { label: 'Job Type', field: 'legend_name.keyword' },
      { label: 'Job Name' },
      { label: 'Created By', field: 'created_by_name.keyword' },
      { label: 'Date Completed', field: 'date_completed' },
      { label: 'Date Last Execution', field: 'date_last_execution' },
      { label: 'Status', field: 'status_name.keyword' },
    ]
  }

  render() {
    const scopingJobs = this.props.scopingJobs
    const store = this.props.store

    return (
      <div className="job-table">
        <Table>
          <TableHead>
            <TableRow>
              {this.headers.map((header, index) =>
                header.field ? (
                  <SortableHeader
                    key={index}
                    store={store}
                    field={header.field}
                    label={header.label}
                  />
                ) : (
                  <TableHeadCell key={index}>{header.label}</TableHeadCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {scopingJobs.map((job, index) => {
              return (
                <TableRowLink
                  data-test={`scoping-job-list-item-${job.id}`}
                  key={index}
                  {...(job.jobId
                    ? toJobLink(
                        job.jobId,
                        this.props.store.rootStore.sessionStore.workspace.slug
                      )
                    : {})}
                >
                  <TableCell>{prune(job.member?.displayName, 20)}</TableCell>
                  <TableCell>{job.legendName}</TableCell>
                  <TableCell>
                    {job.status === 'COMPLETED'
                      ? prune(job.jobName, 40)
                      : prune(job.fileName, 40)}
                  </TableCell>
                  <TableCell>{prune(job.createdBy?.displayName, 20)}</TableCell>
                  <TableCell>
                    {job.dateCompleted ? job.dateCompleted.format('LL') : null}
                  </TableCell>
                  <TableCell>
                    {job.dateLastExecution
                      ? job.dateLastExecution.format('LL LT')
                      : null}
                  </TableCell>
                  <TableCell>
                    {job.statusName}
                    {job.status === 'COMPLETED' && job.confidence <= THRESHOLD && (
                      <div className={styles.spacing}>
                        <span title={`Confidence Level ${job.confidence}`}>
                          <Icon material name="info" small />
                        </span>
                      </div>
                    )}

                    {job.errorMessage && (
                      <div className={styles.spacing}>
                        <span title={job.errorMessage}>
                          <Icon material name="error" small />
                        </span>
                        <span onClick={() => store.rerun(job.id)} title="Rerun">
                          <Icon material name="play_circle_filled" small />
                        </span>
                      </div>
                    )}
                  </TableCell>
                </TableRowLink>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export { ScopingJobTable }
