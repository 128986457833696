/**
 * 
 * A function that requires all words separated by space to be part of the element we are searching in.
 
 * @param {Array<T>} data - a list of elements to filter by
 * @param {string} text - the text we are searching for
 * @param {(entry: T, s: string) => boolean} matches - the method to check if an element should be filtered or not 
 */
export function matchAll(data, text, matches) {
  const textParts = text.toLowerCase().split(' ')

  const result = data.filter((entry) => {
    let hasAllParts = 0
    for (const search of textParts) {
      if (matches(entry, search)) {
        hasAllParts++
      }
    }
    return hasAllParts === textParts.length
  })
  return result
}
