import * as React from 'react'
import { browserHistory } from 'react-router'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import links from 'misc/link'
import { task } from 'mobx-task'
import { observer } from 'mobx-react'

/**
 * Redirects from /jobs/:id to /:workspaceSlug/jobs/:id
 */
@observer
export default class JobRedirect extends React.Component {
  componentDidMount() {
    this.getWorkspaceSlugFromJob(this.props.params.jobId)
  }

  @task
  async getWorkspaceSlugFromJob(jobId) {
    if (!jobId) {
      return null
    }
    try {
      const job = await this.props.rootStore.jobStore.getJob(jobId)
      browserHistory.replace(links.job(job.id, job.workspace.slug))
    } catch {
      browserHistory.replace(
        links.welcome(this.props.rootStore.sessionStore.workspace.slug)
      )
    }
  }

  render() {
    return this.getWorkspaceSlugFromJob.match({
      pending: () => <ContentLoading />,
      rejected: (err) => <ContentError error={err} />,
      resolved: (result) => <ContentLoading />,
    })
  }
}
