import { observable, action } from 'mobx'
import uniqueId from 'lodash/uniqueId'
import { snapshotable } from '../data/serialization'
import { validatable } from '../mixins/validation'
import { receiveErrors } from '../data/serverErrors'

/**
 * String list model.
 */
@snapshotable
@validatable()
export default class StringList {
  @observable items = []

  @action.bound
  hydrate(items) {
    this.items = (items || []).map((value) => new StringListItem(value))
    // Always have one item.
    if (this.items.length === 0) {
      this.add()
    }
  }

  @action.bound
  add() {
    this.items.push(new StringListItem())
  }

  @action.bound
  clear() {
    this.items.replace([])
  }

  @action.bound
  remove(item) {
    this.items.remove(item)
  }

  toJSON() {
    return this.items
      .map((x) => x.toJSON())
      .map((x) => x && x.trim())
      .filter(Boolean)
  }

  receiveErrors(descriptor = {}) {
    const { inner, ...rest } = descriptor
    receiveErrors(this, { items: rest })
    receiveErrors(this.items, inner)
  }
}

@snapshotable
@validatable()
class StringListItem {
  cid = uniqueId('stringlist-item-')

  @observable
  value = ''

  constructor(value = '') {
    this.value = value
  }

  toJSON() {
    return this.value
  }

  receiveErrors(descriptor) {
    this.validation.addErrors({ value: descriptor.errors || [] })
  }
}
