import { extractMessageFromError } from '@taxfyle/web-commons/lib/utils/errorUtil'
import { Store } from 'libx'
import env from 'misc/env'
import links from 'misc/link'
import { action, computed, observable } from 'mobx'
import { task } from 'mobx-task'
import InfiniteScrollProvider, {
  usingSkipLimit,
} from 'data/InfiniteScrollProvider'
import { match, DEFAULT } from 'utils/patternMatchUtil'

export default class UserDetailsScreenStore extends Store {
  @observable
  member = null

  @observable
  userData = null

  @observable
  userCoupons = []

  @observable
  jobCount = null

  @observable
  memberships = []

  @observable
  payoutMethod = null

  @observable
  payoutMethodStatus = 'Unknown'

  @observable
  selectedClientFilter = this.filterOptions[0]

  @observable
  selectedProFilter = this.filterOptions[0]

  @observable
  isPayoutsEnabled = false

  filterOptions = [
    { label: 'All', value: 'ALL' },
    { label: 'Under Construction', value: 'UNDER_CONSTRUCTION' },
    { label: 'Unclaimed', value: 'UNCLAIMED' },
    { label: 'On Hold', value: 'ON_HOLD' },
    { label: 'Claimed', value: 'CLAIMED' },
    { label: 'Closed', value: 'CLOSED' },
  ]

  constructor(opts) {
    super(opts)
    this.activate = this.activate.bind(this)
    this.impersonate = this.impersonate.bind(this)
    this.isPayoutsEnabled = opts.isPayoutsEnabled
  }

  @task
  async activate(publicId) {
    this.setMember(null)
    this.setPayoutMethod(null)

    const {
      jobStore,
      memberStore,
      teamMemberStore,
      teamStore,
      userCouponStore,
      documentStore,
      sessionStore,
      billingStore,
      authStore,
    } = this.rootStore

    this.documentScrollProvider = documentStore.getScrollProvider(
      publicId,
      (data) => documentStore.fetchUserDocuments({ user_id: publicId, ...data })
    )
    const hasAnyPerm = (...p) => sessionStore.member.hasSomePermissions(p)

    this.clientJobsScrollProvider = {}
    this.proJobsScrollProvider = {}

    this.filterOptions.forEach((x) => {
      this.clientJobsScrollProvider[x.value] = new InfiniteScrollProvider({
        memoizeInitial: true,
        limit: 30,
        fetchItems: usingSkipLimit((data) =>
          jobStore.fetchJobsForCustomer({
            user_id: publicId,
            status: x.value === 'ALL' ? null : x.value,
            ...data,
          })
        ),
      })

      this.proJobsScrollProvider[x.value] = new InfiniteScrollProvider({
        memoizeInitial: true,
        limit: 30,
        fetchItems: usingSkipLimit((data) =>
          jobStore.fetchJobsForProvider({
            user_id: publicId,
            status: x.value === 'ALL' ? null : x.value,
            ...data,
          })
        ),
      })
    })

    // Used to set initial value of User type field in Edit User modal
    const userData = await this.rootStore.userDataStore.getByPublicId(publicId)
    this.setUserData(userData)

    const [count, memberships] = await Promise.all([
      jobStore.getCurrentJobCount(publicId, sessionStore.workspace.id),
      hasAnyPerm('ADMIN_WORKSPACE_MANAGE')
        ? authStore.fetchWorkspaceMembershipsByPublicId(publicId)
        : [],
      memberStore
        .fetchByPublicId({
          workspace_id: sessionStore.workspace.id,
          user_public_id: publicId,
        })
        .then(this.setMember),
      teamMemberStore.fetchUserTeamMembersByPublicId({
        workspace_id: sessionStore.workspace.id,
        user_id: publicId,
      }),
      hasAnyPerm('ADMIN_TEAMS_VIEW') &&
        teamStore.findTeams({
          workspace_id: sessionStore.workspace.id,
        }),
      hasAnyPerm('ADMIN_BILLING_VIEW', 'ADMIN_BILLING_MANAGE') &&
        billingStore.fetchAllBillingProfiles(publicId),
      hasAnyPerm('ADMIN_USER_JOB_LIST', 'ADMIN_JOB_LIST') &&
        this.clientJobsScrollProvider[this.selectedClientFilter.value].fetch(),
      hasAnyPerm('ADMIN_USER_JOB_LIST', 'ADMIN_JOB_LIST') &&
        this.proJobsScrollProvider[this.selectedProFilter.value].fetch(),
      hasAnyPerm('ADMIN_COUPONS_MANAGE') &&
        userCouponStore
          .fetchUserCouponsByPublicId(publicId)
          .then(this.setCoupons),
      hasAnyPerm('ADMIN_DOCUMENT_MANAGE') &&
        this.documentScrollProvider.fetch(),
    ])
    this.jobCount = count
    this.memberships = memberships

    if (this.rootStore.sessionStore.user.platformAdmin) {
      await this.loadPayoutMethod(publicId)
    }

    if (!this.payoutMethod || !this.rootStore.sessionStore.user.platformAdmin) {
      await this.loadPayoutMethodStatus(publicId)
    }
  }

  @computed
  get payoutMethodLabel() {
    if (!this.payoutMethod || !this.rootStore.sessionStore.user.platformAdmin) {
      return this.payoutMethodStatus
    }

    return this.isPayoutsEnabled
      ? this.payoutMethod?.stripe_account_id
      : this.payoutMethod?.stripe_user_id
  }

  @computed
  get clientJobs() {
    return this.clientJobsScrollProvider[this.selectedClientFilter.value].items
  }

  @computed
  get providerJobs() {
    return this.proJobsScrollProvider[this.selectedProFilter.value].items
  }

  @action.bound
  changeClientFilter(option) {
    this.selectedClientFilter = option
    if (
      !this.clientJobsScrollProvider[this.selectedClientFilter.value].isDone
    ) {
      this.clientJobsScrollProvider[this.selectedClientFilter.value].more()
    }
  }

  @action.bound
  changeProFilter(option) {
    this.selectedProFilter = option
    if (!this.proJobsScrollProvider[this.selectedProFilter.value].isDone) {
      this.proJobsScrollProvider[this.selectedProFilter.value].more()
    }
  }

  @action.bound
  setMember(member) {
    this.member = member
  }

  @action.bound
  setUserData(userData) {
    this.userData = userData
  }

  @action.bound
  setCoupons(userCoupons) {
    this.userCoupons = userCoupons
  }

  @action.bound
  setPayoutMethod(payoutMethod) {
    this.payoutMethod = payoutMethod
  }

  @action.bound
  setPayoutMethodStatus(res) {
    this.payoutMethodStatus = match(res.status, {
      CONNECTED: 'Connected',
      NOT_CONNECTED: 'Not Connected',
      [DEFAULT]: 'Unknown',
    })
  }

  @action.bound
  showUserUpdate() {
    this.rootStore.editUserStore.show(this.member)
  }

  @action.bound
  showSkillsEditor() {
    this.rootStore.skillEditorStore.showUser(this.member)
  }

  @action.bound
  showRolesEditor() {
    this.rootStore.userRolesEditorStore.show(this.member)
  }

  @action.bound
  showPermissionsEditor() {
    this.rootStore.userPermissionsEditorStore.show(this.member)
  }

  @action.bound
  showCreateJobEditor() {
    this.rootStore.createJobEditorStore.init(this.member)
    this.rootStore.createJobEditorStore.show()
  }

  @action.bound
  showScopeWorkModal() {
    this.rootStore.scopeWorkEditorStore.init(this.member)
    this.rootStore.scopeWorkEditorStore.show()
  }

  @action.bound
  showTeamAddModal() {
    this.rootStore.addToTeamStore.show(this.member)
  }

  @action.bound
  downloadMessageHistory() {
    this.rootStore.flashMessageStore
      .create('Pulling message history in a new Browser tab')
      .autoDismiss()
    window.open(
      links.downloadMessageHistory(
        this.member.userId,
        this.rootStore.authStore.token
      ),
      '_blank'
    )
  }

  async loadPayoutMethodStatus(userPublicId) {
    if (this.isPayoutsEnabled) {
      return this.rootStore.payoutMethodStore
        .fetchPayoutMethodStatus(userPublicId)
        .then(this.setPayoutMethodStatus)
        .catch((err) => {
          console.error('Error loading payout method status', err)
        })
    }
  }

  async loadPayoutMethod(userPublicId) {
    if (this.isPayoutsEnabled) {
      return this.rootStore.payoutMethodStore
        .fetchPayoutMethod(userPublicId)
        .then(this.setPayoutMethod)
        .catch((err) => {
          console.error('Error loading payout method', err)
        })
    }
  }

  @computed
  get activeTeams() {
    if (!this.member) {
      return []
    }

    const userTeamMemberships = this.rootStore.teamMemberStore
      .teamMembersForWorkspaceMemberByPublicId(
        this.rootStore.sessionStore.workspace.id,
        this.member.userPublicId
      )
      .map((x) => x.teamId)

    return this.rootStore.teamStore.teams.filter(
      (t) =>
        t.workspaceId === this.rootStore.sessionStore.workspace.id &&
        userTeamMemberships.includes(t.id) &&
        t.inactive === false
    )
  }

  @computed
  get shouldShowBilling() {
    return this.rootStore.sessionStore.workspace.features.billing.enabled
  }

  @computed
  get payoutMethods() {
    if (!this.member || !this.shouldShowBilling) {
      return []
    }
    const result = []
    const ind = this.rootStore.billingStore.individualBillingProfile(
      this.member.userId
    )
    if (ind) {
      result.push(...ind.payoutMethods)
    }
    const teamBps = this.rootStore.billingStore.teamBillingProfiles(
      this.member.userPublicId
    )
    if (teamBps) {
      teamBps.forEach((x) => result.push(...x.payoutMethods))
    }
    return result
  }

  @computed
  get documents() {
    return this.rootStore.documentStore.forUserAsAdmin(this.member.userId)
  }

  async impersonate(target) {
    const workspaceDomain = this.member.workspace.domains[0].domain
    const callbacks = {
      // All workspaces have a domain.
      client: workspaceDomain
        ? `${window.location.protocol}//${workspaceDomain}`
        : env.CLIENT_PORTAL_URL,
      provider: env.PROVIDER_PORTAL_URL,
    }
    const waitFn = `document.write('Please wait, generating impersonation URL.')`
    const newTab = window.open('javascript:' + waitFn, '_blank')
    const result = await this.rootStore.userStore.api.impersonation
      .impersonate({
        user_id: this.member.userPublicId,
        workspace_id: this.rootStore.sessionStore.workspace.id,
        callback: callbacks[target],
      })
      .catch((err) => {
        const msg = extractMessageFromError(err)
        this.rootStore.flashMessageStore
          .create({ type: 'error', message: msg })
          .autoDismiss()
        newTab.close()
        throw err
      })

    newTab.location.assign(result.url)
  }

  @task
  uploadFiles(files, createdForUserId) {
    return this.rootStore.documentStore.newDocument(Array.from(files), {
      createdForUserId,
      workspaceId: this.rootStore.sessionStore.workspace.id,
    })
  }
}
