import { observable } from 'mobx'

export default class ScopeWorkConfigViewModel {
  @observable
  scopeWorkNotificationSlackChannelIds = []

  activate(scopeWorkNotificationSlackChannelIds = []) {
    this.scopeWorkNotificationSlackChannelIds = scopeWorkNotificationSlackChannelIds.join(
      ', '
    )
  }

  toJSON() {
    return {
      scope_work_notification_slack_channel_ids: this.scopeWorkNotificationSlackChannelIds
        .split(',')
        .map((id) => id.trim()),
    }
  }
}
