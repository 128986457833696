import React from 'react'
import cx from 'classnames'
import prefix from 'utils/prefix'
import './styles.sass'

export const TabPills = ({ children, className }) => (
  <div className={cx('tab-pills', className)}>{children}</div>
)

const modifiers = prefix('tab-pill--')

export const TabPill = ({ children, active, className, ...rest }) => (
  <button
    className={cx('tab-pill', modifiers({ active }), className)}
    {...rest}
  >
    {children}
  </button>
)
