import { observable } from 'mobx'
import { Store } from 'libx'
import { ListBundlesRequest } from '@taxfyle/api-internal/internal/bundle_pb'

export default class BundleStore extends Store {
  @observable workspaceBundles = []

  async fetchWorkspaceBundles(workspaceId) {
    const request = new ListBundlesRequest()
      .setWorkspaceId(workspaceId)
      .setFilter('archived=ACTIVE')
      .setPageSize(1000)
    const itemsResult = (
      await this.rootStore.api.bundles.listBundles(request)
    ).toObject()
    this.workspaceBundles = itemsResult.bundlesList
  }
}
