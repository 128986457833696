import { observable, action } from 'mobx'
import { task } from 'mobx-task'

export default class ConflictResolution {
  @observable isOpened = false
  @observable lastChangedBy = null

  constructor(legendStore, legendEditor, memberStore) {
    this.legendStore = legendStore
    this.legendEditor = legendEditor
    this.memberStore = memberStore
    this.keep = this.keep.bind(this)
    this.force = this.force.bind(this)
  }

  /**
   * Entrypoint, shows the dialog and returns a promise.
   */
  @action
  resolveConflict(params) {
    this.activate(params)
    this.isOpened = true
    return new Promise((resolve) => {
      if (this._resolve) {
        this._resolve(false)
      }
      this._resolve = resolve
    })
  }

  @action
  close(value) {
    this._resolve(value)
    this._resolve = null
    this.isOpened = false
  }

  @task
  async activate(params) {
    this.lastChangedBy = await this.memberStore.setAndFetch({
      workspace_id: params.workspace_id,
      user_id: params.last_updated_by_id,
      user_public_id: params.last_updated_by_public_id,
    })
  }

  @task.resolved
  keep() {
    return this.respond(false)
  }

  @task.resolved
  force() {
    return this.respond(true)
  }

  @task.resolved
  async respond(value) {
    const { id } = this.legendEditor.legendVersion
    // Allow the UI time to show the loading indicator (this is for large legends that hold up the thread while serializing)
    await new Promise((resolve) => setTimeout(resolve, 10))
    if (value === true) {
      // Force our changes. Do a persist.
      await this.legendEditor.persist(
        {
          ...this.legendEditor.serialize(),
          force: true,
        },
        true
      )
    }
    // Fetch latest
    await this.legendEditor.selectLegendVersion(id, null, {
      skipCache: true,
    })
    this.close(value)
  }
}
