import { Store } from 'libx'
import Notify from 'notifyjs'
import { observable, runInAction } from 'mobx'
import { localStorage as storage } from 'misc/storage'

export default class DesktopNotificationStore extends Store {
  @observable
  isSupported = false

  @observable
  isEnabled = false

  constructor() {
    super(...arguments)
    this.isSupported = Notify.needsPermission === false || Notify.isSupported()

    this.rootStore.authStore.onLogin(() => {
      // Restore enabled state.
      const stored = storage.getItem('desktopNotifications')
      const enabled = stored !== 'disabled'
      runInAction(() => {
        this.isEnabled = enabled
      })
      this.setEnabled(enabled)
    })
  }

  async setEnabled(enabled) {
    if (enabled) {
      if (this.isEnabled && Notify.needsPermission === false) {
        return
      }

      enabled = await this._getPermission()
    }

    storage.setItem('desktopNotifications', enabled ? 'enabled' : 'disabled')
    runInAction(() => {
      this.isEnabled = enabled
    })

    if (this.isEnabled) {
      this.show({
        title: 'Nice!',
        body: 'Desktop notifications have been enabled. You rock!',
        tag: 'notifications-enabled',
      })
    }
  }

  _getPermission() {
    return new Promise((resolve) => {
      if (!Notify.needsPermission) {
        return resolve(true)
      }

      if (!this.isSupported) {
        return resolve(false)
      }

      Notify.requestPermission(
        () => resolve(true),
        () => resolve(false)
      )
    })
  }

  show(opts) {
    // if (!this.isEnabled) {
    //   return
    // }
    // const notification = new Notify(opts.title, {
    //   tag: opts.tag,
    //   body: opts.body,
    //   icon: opts.icon || require('assets/images/taxfyle-icon-256px.png'),
    //   badge: require('assets/images/taxfyle-icon-256px.png'),
    //   timeout: opts.timeout || 5,
    //   notifyClick: () => {
    //     window.focus()
    //     if (opts.onClick) {
    //       opts.onClick()
    //     }
    //     notification.close()
    //   }
    // })
    // notification.show()
  }
}
