import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet-async'
import PageWrapper, { PageTitle } from 'components/PageWrapper'
import {
  Table,
  TableHeadCell,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from 'components/BetterTable'
import { Dropdown, DropdownItem } from 'components/Dropdown'
import Button from 'components/Button'
import Icon from 'components/Icon'
import RoleEditor from './RoleEditor'
import ConfirmDialog from 'components/ConfirmDialog'
import ContentLoading from 'components/ContentLoading'
import ScreenProtector from 'iam/containers/ScreenProtector'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import RequirePerms from 'iam/containers/RequirePerms'
import PermissionsSummary from 'components/PermissionsSummary'
import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'
import styles from './RolesScreen.m.sass'

@observer
export default class RolesScreen extends React.Component {
  componentDidMount() {
    this.props.rootStore.rolesScreenStore.activate()
  }

  get isPlatformRolesEnabled() {
    return (
      this.props.rootStore.sessionStore.workspace.config?.platform_roles_config
        ?.enabled &&
      getFeatureToggleClient().variation('IamAPI.UsePlatformRoles', false)
    )
  }

  render() {
    const { rolesScreenStore, roleEditorStore } = this.props.rootStore
    return (
      <ScreenProtector allOf={['ADMIN_ROLE_LIST']}>
        <PageWrapper className={cx(styles.root)}>
          <PageTitle center>
            Workspace Roles &nbsp;
            {!this.isPlatformRolesEnabled && (
              <RequirePerms allOf={['ADMIN_ROLE_MODIFY']}>
                <Button
                  iconLeft={<Icon material name="add" />}
                  type="primary"
                  onClick={rolesScreenStore.createRole.bind(rolesScreenStore)}
                  data-test="create-role"
                >
                  Create Role
                </Button>
              </RequirePerms>
            )}
          </PageTitle>
          <Helmet title="Roles" />
          <div className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Role</TableHeadCell>
                  <TableHeadCell>Description</TableHeadCell>
                  <TableHeadCell>Permissions</TableHeadCell>
                  <TableHeadCell className={styles.alignRight}>
                    Actions
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rolesScreenStore.roles.map((r) => (
                  <RoleItem
                    onEditClick={rolesScreenStore.edit.bind(
                      rolesScreenStore,
                      r
                    )}
                    onDeleteClick={rolesScreenStore.deleteRole.bind(
                      rolesScreenStore,
                      r
                    )}
                    role={r}
                    key={r.id}
                  />
                ))}
              </TableBody>
            </Table>
            <InfiniteScrollZone
              scrollProvider={rolesScreenStore.scrollProvider}
            >
              {({ isDone }) => (isDone ? null : <ContentLoading smaller />)}
            </InfiniteScrollZone>
          </div>
          <ConfirmDialog
            state={rolesScreenStore.confirmDelete}
            title="Delete role?"
            danger
            position="top"
          >
            {rolesScreenStore.roleToDelete && (
              <p>
                Do you really want to delete the role{' '}
                <strong>{rolesScreenStore.roleToDelete.name}</strong>?
              </p>
            )}
          </ConfirmDialog>
          <PageTitle center>
            Team Roles &nbsp;
            <RequirePerms allOf={['ADMIN_ROLE_MODIFY']}>
              <Button
                iconLeft={<Icon material name="add" />}
                type="primary"
                onClick={rolesScreenStore.createTeamRole.bind(rolesScreenStore)}
                data-test="create-team-role"
              >
                Create Team Role
              </Button>
            </RequirePerms>
          </PageTitle>
          <Helmet title="Roles" />
          <div className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Role</TableHeadCell>
                  <TableHeadCell>Description</TableHeadCell>
                  <TableHeadCell>Permissions</TableHeadCell>
                  <TableHeadCell className={styles.alignRight}>
                    Actions
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rolesScreenStore.teamRoles.map((r) => (
                  <RoleItem
                    onEditClick={rolesScreenStore.editTeamRole.bind(
                      rolesScreenStore,
                      r
                    )}
                    onDeleteClick={rolesScreenStore.deleteTeamRole.bind(
                      rolesScreenStore,
                      r
                    )}
                    role={r}
                    key={r.id}
                  />
                ))}
              </TableBody>
            </Table>
            <InfiniteScrollZone
              scrollProvider={rolesScreenStore.scrollProvider}
            >
              {({ isDone }) => (isDone ? null : <ContentLoading smaller />)}
            </InfiniteScrollZone>
          </div>
          <ConfirmDialog
            state={rolesScreenStore.confirmDelete}
            title="Delete role?"
            danger
            position="top"
          >
            {rolesScreenStore.roleToDelete && (
              <p>
                Do you really want to delete the role{' '}
                <strong>{rolesScreenStore.roleToDelete.name}</strong>?
              </p>
            )}
          </ConfirmDialog>
          <RoleEditor store={roleEditorStore} />
        </PageWrapper>
      </ScreenProtector>
    )
  }
}

const RoleItem = observer(({ role, onEditClick, onDeleteClick }) => (
  <TableRow className={styles.roleItem}>
    <TableCell>{role.name}</TableCell>
    <TableCell>{role.description}</TableCell>
    <TableCell>
      <PermissionsSummary descriptions={role.permissionDescriptions} />
    </TableCell>
    <TableCell className={styles.alignRight}>
      <Dropdown
        position="right"
        button={
          <a className="button is-icon-only">
            <Icon material name="more vert" />
          </a>
        }
      >
        <RequirePerms allOf={['ADMIN_ROLE_MODIFY']}>
          <DropdownItem onClick={onEditClick}>Edit</DropdownItem>
        </RequirePerms>
        <RequirePerms allOf={['ADMIN_ROLE_REMOVE']}>
          <DropdownItem onClick={onDeleteClick}>Delete</DropdownItem>
        </RequirePerms>
      </Dropdown>
    </TableCell>
  </TableRow>
))
