import { Model } from 'libx'
import uniqueId from 'lodash/uniqueId'
import FiltersBuilder from './FiltersBuilder'
import { validatable } from 'legend-builder/mixins/validation'
import { serialize, serializable } from '../data/serialization'
import { computed } from 'mobx'
import { receiveErrors } from '../data/serverErrors'

/**
 * Base filter editor.
 */
@validatable({ liveValidation: true })
export default class FilterEditor extends Model {
  cid = uniqueId('filter-')

  /**
   * See `FiltersBuilder.scope` for an explanation on what this is.
   */
  scope = []

  /**
   * See `FiltersBuilder.context` for an explanation on what this is.
   */
  context = null

  /**
   * Type.
   */
  @serializable
  type

  constructor(attrs, opts) {
    super()
    this.legendEditor = opts.legendEditor
    this.scope = opts.scope || []
    this.context = opts.context
    this.set(attrs, opts)
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  toJSON() {
    return serialize(this)
  }

  createSubFilter(data, opts) {
    return create(data, _filterOpts(this, opts))
  }

  parseSubFilter(data, opts) {
    return fromJS(data, _filterOpts(this, opts))
  }

  createSubBuilder(opts) {
    return new FiltersBuilder(_filterOpts(this, opts))
  }

  /**
   * Receives errors and populates the validation context with them.
   *
   * @param {*} descriptor
   */
  receiveErrors(descriptor) {
    receiveErrors(this, descriptor.inner)
  }
}

/**
 * Creates a new routing filter editor of the right type.
 */
function create(data, opts) {
  const Type = require(`legend-builder/filters/types/${data.type}/${data.type}Editor.js`)
    .default
  return new Type(data, opts)
}

FilterEditor.create = create

/**
 * Converts JSON data to a routing filter instance.
 */
function fromJS(data, opts) {
  return FilterEditor.create(data, {
    ...opts,
    parse: true,
    stripUndefined: true,
  })
}

FilterEditor.fromJS = fromJS

/**
 * Creates an options object to be passed to sub filters and builders.
 *
 * @param {FilterEditor} editor
 */
function _filterOpts(editor, opts) {
  return {
    legendEditor: editor.legendEditor,
    scope: editor.scope,
    context: editor.context,
    ...opts,
  }
}
