import { Store } from 'libx'
import { task } from 'mobx-task'

import PayoutMethod from '@taxfyle/web-commons/lib/billing/PayoutMethod'

export default class PayoutMethodStore extends Store {
  payoutMethods = this.collection({
    model: PayoutMethod,
  })

  get payoutMethodsAPI() {
    return this.rootStore.api.payoutMethods
  }

  constructor() {
    super(...arguments)

    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.payoutMethods.clear()
    })
  }

  getPayoutMethodByUserPublicId(userPublicId) {
    return this.payoutMethods.find(
      (payoutMethod) => payoutMethod.userPublicId === userPublicId
    )
  }

  /**
   * Fetches payout method for a user using BigMoney API.
   */
  @task.resolved
  fetchPayoutMethod(userPublicId) {
    return this.payoutMethodsAPI
      .getByUserPublicId(userPublicId)
      .then(this.payoutMethods.set)
      .catch((err) => {
        if (err.response?.status === 404) {
          return null
        }

        throw err
      })
  }

  /**
   * Fetches payout method status for a user using BigMoney API.
   */
  @task.resolved
  fetchPayoutMethodStatus(userPublicId) {
    return this.payoutMethodsAPI.getStatusByUserPublicId(userPublicId)
  }
}
