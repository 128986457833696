import { serializable } from 'legend-builder/data/serialization'
import { action, computed, observable } from 'mobx'
import { validateObject } from 'utils/validx-validators'
import MutatorEditor from 'legend-builder/effects/MutatorEditor'

export default class InventoryItemMutatorEditor extends MutatorEditor {
  type = 'InventoryItem'

  /**
   * The operator. Can be `Inc`, `Dec`, `Mult`, or `CourtesyMult`.
   */
  @observable
  @serializable
  op = 'Inc'

  @observable
  @serializable
  quantity

  rules = {
    target: [
      validateObject(
        'Please select the service item element you wish to change.'
      ),
    ],
  }

  constructor(attrs, opts) {
    super(null, opts)
    this.set(attrs, opts)
    this.target.bubbleErrorsTo(this, 'target')
  }

  isCompatibleVariableType(type, valueType) {
    return type === 'InventoryItem'
  }

  @action.bound
  setOperator(op) {
    this.op = op
  }

  @computed
  get isMultiplier() {
    return this.op === 'CourtesyMult' || this.op === 'Mult'
  }
}
