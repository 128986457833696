import * as React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import styles from './Variables.m.sass'
import TypeValue from 'components/TypeValue'
import EmptyState from '@taxfyle/web-commons/lib/components/EmptyState'

export default observer(function Variables({ variables }) {
  return (
    <div className={cx(styles.root)}>
      {variables.length === 0 && (
        <EmptyState title="Variables">
          <p>
            <strong>Legend Variables</strong> allow you to compute useful
            information based on <strong>Questions</strong>.
          </p>
          <p>
            When a Job is using a Legend that utilizes Variables, they will show
            up here.
          </p>
        </EmptyState>
      )}
      {variables.map((variable) => (
        <div key={variable.id} className={styles.item}>
          <div className={styles.info}>
            <span
              className={styles.displayName}
              title={`${variable.id} (${typeToFriendlyString(
                variable.valueType
              )})`}
            >
              {variable.label || variable.id}
            </span>
          </div>
          <div className={styles.value}>
            <Variable variable={variable} />
          </div>
        </div>
      ))}
    </div>
  )
})

const Variable = observer(function Variable({ variable }) {
  if (variable.valueType.type === 'List') {
    return (
      <span className={styles.list}>
        {variable.value.map((item, i) => (
          <span className={styles.listItem} key={i}>
            <TypeValue value={item} type={variable.valueType.itemType} />
            {i < variable.value.length - 1 && ', '}
          </span>
        ))}
      </span>
    )
  }

  return <TypeValue value={variable.value} type={variable.valueType} />
})

function typeToFriendlyString(type) {
  if (type.type === 'List') {
    return `List of ${typeToFriendlyString(type.itemType)}`
  }
  return type.type
}
