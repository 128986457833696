import { Store } from 'libx'
import JobEvent from '@taxfyle/web-commons/lib/jobs/JobEvent'
import { action } from 'mobx'
import { makeMomentSorter } from 'utils/arrayUtil'
import { match } from 'utils/patternMatchUtil'
import { browserHistory } from 'react-router'
import links from 'misc/link'

const byDateCreated = makeMomentSorter('desc', (x) => x.dateCreated)

export default class EventStore extends Store {
  constructor() {
    super(...arguments)
    this.jobEvents = this.collection({
      model: JobEvent,
    })

    this.rootStore.api.jobEvents.on('created', this.onRemoteUpdate)
  }

  @action.bound
  onRemoteUpdate(data) {
    const isNew = !this.jobEvents.get(data.id)
    const event = this.jobEvents.set(data)
    if (isNew) {
      const opts = this._getNotificationOpts(event)
      if (opts) {
        this.rootStore.desktopNotificationStore.show(opts)
      }
    }
  }

  jobEventsFor(jobId) {
    return this.jobEvents.filter((x) => x.jobId === jobId).sort(byDateCreated)
  }

  fetchJobEvents(jobId) {
    return this.rootStore.api.jobEvents
      .find({ query: { job: jobId } })
      .then(action(this.jobEvents.set))
  }

  _getNotificationOpts(event) {
    const notifyOpts = {
      timeout: 20,
      onClick: () =>
        browserHistory.push(
          links.job(event.jobId, this.rootStore.sessionStore.workspace.slug)
        ),
    }
    const job = event.job
    // Don't show notifications for events the current user initiated.
    if (!job || event.triggeredBy === this.rootStore.authStore.user) {
      return null
    }

    return match(event.eventType, {
      UNCLAIMED: () => ({
        ...notifyOpts,
        title:
          job.client === event.triggeredBy
            ? 'New job submitted'
            : `${job.name} transferred to pool`,
        body:
          job.client === event.triggeredBy
            ? `${job.name} was submitted`
            : `${event.triggeredBy.displayName} transferred the job to the pool.`,
        icon: event.triggeredBy.avatar,
      }),
      CLAIMED: () => ({
        ...notifyOpts,
        title: `${job.name} has been assigned`,
        body: `${job.name} assigned to ${job.provider.displayName}.`,
        icon: job.provider.avatar,
      }),
      IDLE: () => ({
        ...notifyOpts,
        title: `${job.name} has gone Idle`,
        body: `${job.name} needs attention from ${job.client.displayName}.`,
        icon: job.provider.avatar,
      }),
      UPDATED: () => ({
        ...notifyOpts,
        title: `${job.name} updated`,
        body: `${event.triggeredBy.displayName} updated the details of ${job.name}`,
        icon: event.triggeredBy.avatar,
      }),
      AMENDED: () => ({
        ...notifyOpts,
        title: `${job.name} amended`,
        body: `${event.triggeredBy.displayName} amended ${job.name}`,
        icon: event.triggeredBy.avatar,
      }),
      REOPENED: () => ({
        ...notifyOpts,
        title: `${job.name} reopened`,
        body: `${event.triggeredBy.displayName} reopened ${job.name}`,
        icon: event.triggeredBy.avatar,
      }),
      TRANSFERRED: () => ({
        ...notifyOpts,
        title: `${job.name} transferred`,
        body: event.newProvider
          ? `Transferred to ${event.newProvider.displayName}`
          : event.newClient
          ? `Transferred to ${event.newClient.displayName}`
          : 'job transferred',
        icon: event.newProvider
          ? event.newProvider.avatar
          : event.newClient
          ? event.newClient.avatar
          : '',
      }),
      FORFEITED: () => ({
        ...notifyOpts,
        title: `${job.name} released`,
        body: `${job.name} was released by ${event.previousProvider.displayName}`,
        icon: event.previousProvider.avatar,
      }),
      CLOSED: () => ({
        ...notifyOpts,
        title: `${job.name} was closed`,
        body: `${job.name} was closed by ${event.triggeredBy.displayName}.`,
        icon: event.triggeredBy.avatar,
      }),
      [match.DEFAULT]: () => null,
    })
  }
}
