import { ConfirmDialogState } from 'components/ConfirmDialog'
import InfiniteScrollProvider, {
  usingSkipLimit,
} from 'data/InfiniteScrollProvider'
import { Store } from 'libx'
import debounce from 'lodash/debounce'
import { action, observable } from 'mobx'
import TransferManyJobState from '../../screens/Jobs/TransferManyJobDialog/TransferManyJobState'
import moment from 'moment'
import Cookies from 'js-cookie'

export default class JobsScreenStore extends Store {
  @observable
  jobs = []

  @observable
  searchText = ''

  @observable
  sortField = null

  @observable
  sortOrder = null

  @observable
  selectedJobs = []

  @observable
  facets = new Map()

  @observable
  selectedFacets = new Map()

  @observable
  fromDate = ''

  @observable
  toDate = ''

  @observable
  showFilter = true

  @observable
  showInactive = false

  constructor() {
    super(...arguments)
    this.deleteJobConfirm = new ConfirmDialogState()
    this.notifyProsConfirm = new ConfirmDialogState()

    this.debouncedFetchJobs = debounce(this.findJobs, 200)
    this.scrollProvider = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: 30,
      fetchItems: usingSkipLimit(this._loadJobs.bind(this)),
    })
    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.scrollProvider.reset()
      this.jobs.replace([])
      this.selectedJobs.replace([])
      this.searchText = ''

      let defaultFilter = Cookies.get('default-jobs-filter')
      if (defaultFilter) {
        defaultFilter = JSON.parse(defaultFilter)
        this.searchText = defaultFilter.searchText
        this.sortField = defaultFilter.sortField
        this.sortOrder = defaultFilter.sortOrder
        for (const obj of Object.keys(defaultFilter.selectedFacets)) {
          this.selectedFacets.set(obj, defaultFilter.selectedFacets[obj])
        }
        this.toDate = defaultFilter.toDate
        this.fromDate = defaultFilter.fromDate
      }
    })

    this.transferManyJobState = new TransferManyJobState(
      this,
      this.rootStore.memberStore,
      this.rootStore.teamMemberStore
    )
  }

  async activate() {
    this.findJobs()
    this.loadAggregations()
    this.deleteJobConfirm.hide()
    this.notifyProsConfirm.hide()
  }

  @action.bound
  async loadAggregations() {
    const aggs = await this.rootStore.jobStore.getAggregations(
      this.showInactive
    )
    if (aggs.aggregations) {
      this.facets.replace(aggs.aggregations)
    }
  }

  @action.bound
  async loadAllData() {
    let hasMore = await this.loadMore()
    while (hasMore && hasMore.data && hasMore.data.length !== 0) {
      hasMore = await this.loadMore()
    }

    if (this.jobs.length === 0) {
      return
    }

    const getTeamsForJob = (j) => {
      if (!j.teamIds.length) {
        return ''
      }
      const teams = j.teamIds
        .map((id) => this.rootStore.teamStore.teams.find((x) => x.id === id))
        .filter(Boolean)
      return teams.map((x) => x.name).join(',')
    }

    return {
      filename: 'export.csv',
      data: this.jobs.map((x) => {
        return {
          ...x,
          clientDisplayName: x.client && x.client.displayName,
          providerDisplayName: x.provider && x.provider.displayName,
          teams: getTeamsForJob(x),
          dateCreated: x.dateCreated ? moment(x.dateCreated).format('LL') : '',
          dateClosed: x.dateClosed ? moment(x.dateClosed).format('LL') : '',
          dateTransmitted: x.dateTransmitted
            ? moment(x.dateTransmitted).format('LL')
            : '',
          dateDeadline: x.dateDeadline
            ? moment(x.dateDeadline).format('lll')
            : '',
        }
      }),
      headers: [
        { label: 'Id', key: 'id' },
        { label: 'Short Id', key: 'shortId' },
        { label: 'Name', key: 'name' },
        { label: 'Client', key: 'clientDisplayName' },
        { label: 'Pro', key: 'providerDisplayName' },
        { label: 'Legend Name', key: 'legendName' },
        { label: 'Status', key: 'status' },
        { label: 'Create Date', key: 'dateCreated' },
        { label: 'Date Completed', key: 'dateClosed' },
        { label: 'Date Submitted', key: 'dateTransmitted' },
        { label: 'Date Deadline', key: 'dateDeadline' },
        { label: 'Teams', key: 'teams' },
        { label: 'Total', key: 'total' },
      ],
    }
  }

  @action.bound
  async handleSelection(id) {
    if (this.selectedJobs.indexOf(id) > -1) {
      this.selectedJobs.remove(id)
    } else {
      this.selectedJobs.push(id)
    }
  }

  @action.bound
  async deleteSelected() {
    if (!(await this.deleteJobConfirm.show())) {
      return
    }
    await this.rootStore.jobStore.deleteJobs(this.selectedJobs)
    this.jobs = this.jobs.filter((obj) => {
      return !this.selectedJobs.includes(obj.id)
    })

    // clean selected jobs
    this.selectedJobs = []

    // give elastic search some time
    setTimeout(this.loadAggregations, 1000)
  }

  async restoreSelected() {
    await this.rootStore.jobStore.restoreJobs(this.selectedJobs)
    this.jobs = this.jobs.filter((obj) => {
      return !this.selectedJobs.includes(obj.id)
    })
    // give elastic search some time
    setTimeout(this.loadAggregations, 1000)
  }

  @action.bound
  async transferSelected(target) {
    this.transferManyJobState.show(target)
  }

  @action.bound
  async notifyProSelected() {
    if (!(await this.notifyProsConfirm.show())) {
      return
    }

    for (const jobId of this.selectedJobs) {
      const j = this.jobs.find((x) => x.id === jobId)
      if (j && j.status === 'UNCLAIMED') {
        await this.rootStore.jobStore.notifyPros(jobId)
      }
    }
  }

  @action.bound
  async toggleFilter() {
    this.showFilter = !this.showFilter
  }

  @action.bound
  saveFilters() {
    Cookies.set(
      'default-jobs-filter',
      JSON.stringify({
        searchText: this.searchText,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        selectedFacets: this.selectedFacets,
        fromDate: this.fromDate,
        toDate: this.toDate,
      }),
      { expires: 365 }
    )

    this.rootStore.flashMessageStore
      .create({
        message: 'Filter saved',
        type: 'success',
      })
      .autoDismiss()
  }

  @action.bound
  selectFacet(key, value) {
    const currentSelection = this.selectedFacets.get(key)
    if (currentSelection && currentSelection.includes(value)) {
      if (currentSelection.length === 1) {
        this.selectedFacets.delete(key)
      } else {
        currentSelection.remove(value)
      }
    } else {
      if (!currentSelection) {
        this.selectedFacets.set(key, [value])
      } else {
        currentSelection.push(value)
      }
    }
    this.debouncedFetchJobs()
  }

  @action.bound
  changeFromDate(value) {
    this.fromDate = value
    this.debouncedFetchJobs()
  }

  @action.bound
  changeToDate(value) {
    this.toDate = value
    this.debouncedFetchJobs()
  }

  @action
  async findJobs() {
    this.scrollProvider.reset()
    this.jobs.replace([])
    this.selectedJobs.replace([])
    await this.scrollProvider.fetch()
  }

  /**
   * Loads more users.
   */
  loadMore() {
    return this.scrollProvider.more()
  }

  @action.bound
  changeSort(field, order) {
    this.sortField = field
    this.sortOrder = order
    this.findJobs()
  }

  @action.bound
  changeSearchText(newText) {
    this.searchText = newText
    this.debouncedFetchJobs()
  }

  @action.bound
  toggleShowInactive() {
    this.showInactive = !this.showInactive
    this.findJobs()
    this.loadAggregations()
  }

  /**
   * Actual load call, called by the paging manager.
   *
   * @param  {object} paging
   * @return {object}
   */
  async _loadJobs(paging) {
    const workspace = this.rootStore.sessionStore.workspace
    const sort = !this.sortField
      ? undefined
      : { [this.sortField]: { order: this.sortOrder } }

    const fields = this.selectedFacets.toJSON()
    const searchText = this.searchText && this.searchText.trim()

    const params = {
      limit: paging.limit,
      skip: paging.skip,
      workspace_id: workspace && workspace.id,
      searchText: searchText,
      $sort: sort,
      archived: this.showInactive,
      ...(this.fromDate instanceof Date && {
        fromDate: this.fromDate.toLocaleDateString('en-US'),
      }),
      ...(this.toDate instanceof Date && {
        toDate: this.toDate.toLocaleDateString('en-US'),
      }),
      ...fields,
    }

    const result = await this.rootStore.jobStore.find(params)

    // this is for ensure we don't display the archived job when we
    // delete it, it takes some seconds for elastic search
    // to update the index
    this.jobs.push(
      ...result.data.filter((job) => job.archived === this.showInactive)
    )

    return result
  }
}
