import React from 'react'
import EntitySelector from '../EntitySelector'

const SkillSelector = ({ skillStore, workspaceId, ...other }) => {
  return (
    <EntitySelector
      {...other}
      defaultOptions
      pageSize={100}
      getOptionLabel={(o) => o.name}
      noOptionsMessage={() => 'Type to search for skills.'}
      closeMenuOnSelect={false}
      loadEntities={(p) =>
        skillStore.find({
          workspace_id: workspaceId,
          ...p,
        })
      }
    />
  )
}

export default SkillSelector
