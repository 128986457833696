import cx from 'classnames'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './FacetedSearch.m.sass'
import CsvExport from './CsvExport'
import Icon from 'components/Icon'
import DateTimeInput from 'components/DateTimeInput'

/**
 * Faceted Search component.
 */
@observer
export default class FacetedSearch extends React.Component {
  static defaultProps = {
    facets: new Map(),
    show: true,
  }

  getWaitingOnLabel(name) {
    return name === 'Provider' ? 'Pro' : name
  }

  getLabel(key, name) {
    return key === 'Waiting on' ? this.getWaitingOnLabel(name) : name
  }

  render() {
    const { store } = this.props
    const { facets, selectedFacets } = store

    return (
      <div
        className={cx(styles.facetedSearch, !store.showFilter && styles.hidden)}
      >
        <div className={styles.facet}>
          <div className={styles.export}>
            <CsvExport asyncExportMethod={store.loadAllData} />
          </div>
          <div
            className={cx(styles.export, styles.link)}
            onClick={() => store.saveFilters()}
          >
            <Icon size={21} material name={'filter_alt'} />
            <span>Save filter</span>
          </div>
          <div className={styles.header}>Date Submitted/Closed</div>
          <div className={styles.bucket}>
            From:{' '}
            <DateTimeInput
              type="date"
              onChange={(e) => store.changeFromDate(e)}
              className={styles.input}
              value={store.fromDate}
            />
            <br />
            To:{' '}
            <DateTimeInput
              type="date"
              onChange={(e) => store.changeToDate(e)}
              className={styles.input}
              value={store.toDate}
            />
          </div>
        </div>
        {Array.from(facets).map(([key, value]) => {
          if (value.buckets.length === 0) {
            return null
          }
          return (
            <div className={styles.facet} key={key}>
              <div className={styles.header}>{key}</div>
              {value.buckets.map((bucket) => {
                const name = bucket.key_as_string
                  ? bucket.key_as_string
                  : bucket.key
                const selected = selectedFacets.get(key) || []
                return (
                  <div className={styles.bucket} key={bucket.key}>
                    <input
                      type="checkbox"
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => store.selectFacet(key, name)}
                      checked={selected.includes(name)}
                    />
                    {this.getLabel(key, name)} ({bucket.doc_count})
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }
}

FacetedSearch.propTypes = {
  store: PropTypes.object,
}
