import { Store } from 'libx'
import { task } from 'mobx-task'
import { computed, action, observable } from 'mobx'
import ConfirmDialogState from 'components/ConfirmDialog/ConfirmDialogState'
import InfiniteScrollProvider from 'data/InfiniteScrollProvider'
import { extractMessageFromError } from 'utils/errorUtil'
import Papa from 'papaparse'

export default class DocumentTagsScreenStore extends Store {
  @observable
  tagToDelete = null

  constructor() {
    super(...arguments)
    this.confirmDelete = new ConfirmDialogState()
    this.scrollProvider = new InfiniteScrollProvider({
      limit: 50,
      fetchItems: this._loadDocumentTags.bind(this),
    })

    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.scrollProvider.reset()
    })
    this.onChangeFile = this.onChangeFile.bind(this)
  }

  @computed
  get tags() {
    return this.rootStore.documentTagStore.forWorkspace(
      this.rootStore.sessionStore.workspace.id
    )
  }

  @task
  activate() {
    return this.loadDocumentTags()
  }

  @action.bound
  create() {
    this.rootStore.documentTagEditorStore.show()
  }

  @action.bound
  edit(tag) {
    return this.rootStore.documentTagEditorStore.show(tag)
  }

  @action.bound
  delete(tag) {
    this.tagToDelete = tag
    return this.confirmDelete.show().then((shouldDelete) => {
      if (!shouldDelete) {
        return
      }

      return this.rootStore.documentTagStore.delete(tag.id)
    })
  }

  @action.bound
  async loadAllData() {
    let hasMore = await this.loadMore()
    while (hasMore && hasMore.data && hasMore.data.length !== 0) {
      hasMore = await this.loadMore()
    }

    if (this.tags.length === 0) {
      return
    }

    return {
      filename: 'document-tags.csv',
      data: this.tags.map((x) => {
        return [x.label.trim()]
      }),
    }
  }

  loadMore() {
    return this.scrollProvider.more()
  }

  _loadDocumentTags(paging) {
    return this.rootStore.documentTagStore.fetchTags({
      ...paging,
      workspace_id: this.rootStore.sessionStore.workspace.id,
    })
  }

  loadDocumentTags() {
    return this.scrollProvider.fetch()
  }

  @action.bound
  async onChangeFile(files) {
    const csv = await new Promise((resolve, reject) => {
      Papa.parse(files[0], {
        complete: (results) => {
          resolve(results.data)
        },
      })
    })

    let doneCount = 0

    const formatMessage = () =>
      `Creating document tags, ${doneCount} of ${csv.length}...`

    const msg = this.rootStore.flashMessageStore.create({
      inProgress: true,
      message: formatMessage(),
    })

    const results = await Promise.all(
      csv.map((e) => {
        if (e.length !== 1) {
          return {
            name: e.toString(),
            error: 'Bad formatting',
          }
        } else if (this.isUnique(e[0])) {
          const data = {
            label: e[0].trim(),
            color: '#C0C0C8',
            workspace_id: this.rootStore.sessionStore.workspace.id,
          }
          return this.rootStore.documentTagStore
            .create(data)
            .then(() => {
              doneCount++
              msg.set({ message: formatMessage() })
              return { e, success: true }
            })
            .catch((err) => {
              return { name: e[0], error: err }
            })
        } else {
          return { name: e[0], error: 'Tag already exists.' }
        }
      })
    )

    const failed = results.filter((r) => r.error)
    if (failed.length === 0) {
      msg
        .done(
          doneCount === 1
            ? 'Document Tags imported!'
            : `Imported ${doneCount} document tags!`
        )
        .autoDismiss()
      this.close()
      return
    }

    const errorMessages = failed
      .map((f) => `${f.name} - ${extractMessageFromError(f.error)}`)
      .join('\n')

    msg.warn(
      doneCount > 0
        ? `Imported ${doneCount} document tags, but ${failed.length} didn't go through. ${errorMessages}`
        : `None of the document tags were imported. ${errorMessages}`
    )
  }

  isUnique(label) {
    const tags = this.rootStore.documentTagsScreenStore.tags
    label = label.trim().toLowerCase()
    return !tags.find((tag) => label === tag.label.trim().toLowerCase())
  }
}
