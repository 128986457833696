import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import cx from 'classnames'
import moment from 'moment'
import ConfirmDialog from 'components/ConfirmDialog'
import { Dropdown, DropdownItem, DropdownItemIcon } from 'components/Dropdown'
import Icon from 'components/Icon'
import FloatingLabel from 'components/FloatingLabel'
import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableRow,
  TableCell,
  SortableHeader,
} from 'components/BetterTable'
import styles from './CouponList.m.sass'

const CouponList = observer(({ coupons, store }) => {
  const headers = [
    { label: 'Code', field: 'code' },
    { label: 'Type' },
    { label: 'Description', field: 'description' },
    { label: 'Off Type' },
    { label: 'Duration' },
    { label: 'Coupon Status' },
    { label: 'Date Created', field: 'date_created' },
    { label: 'Created By' },
    { label: 'Redeemed' },
  ]

  return (
    <div className={styles.couponList}>
      <Table className={styles.table}>
        <TableHead className={styles.tableHeader}>
          <TableRow className={styles.row}>
            <TableHeadCell>
              {store.selectedCoupons.length > 0 && (
                <Dropdown
                  className={styles.actions}
                  position="left"
                  button={
                    <a className="action button is-icon-only">
                      <Icon material name="more vert" />
                    </a>
                  }
                >
                  <DropdownItem
                    className={styles.normalize}
                    onClick={store.deleteSelected.bind(store)}
                  >
                    <DropdownItemIcon material name="delete" />
                    Delete Selected
                  </DropdownItem>
                </Dropdown>
              )}
            </TableHeadCell>
            {headers.map((header, index) => (
              <SortableHeader
                key={index}
                store={store}
                field={header.field}
                label={header.label}
              />
            ))}
            <TableHeadCell>Actions</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {coupons.map((coupon, index) => {
            coupon.model = coupon.isNew
              ? { couponType: {}, offType: {}, dateCreated: moment() }
              : coupon.model

            return (
              <TableRow
                key={index}
                className={styles.row}
                data-test={`coupon-${coupon.model.code}`}
              >
                <TableCell>
                  {coupon.model.couponType.type !== 'REFERRAL_CODE' && (
                    <input
                      type="checkbox"
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => store.handleSelection(coupon.id)}
                      checked={store.selectedCoupons.indexOf(coupon.id) > -1}
                    />
                  )}
                </TableCell>
                <TableCell>{coupon.model.code}</TableCell>
                <TableCell>
                  <FloatingLabel
                    className={cx(
                      styles.couponType,
                      styles[coupon.model.displayType]
                    )}
                  >
                    {coupon.model.displayType}
                  </FloatingLabel>
                </TableCell>
                <TableCell>{coupon.model.description}</TableCell>
                <TableCell>{coupon.model.offType.displayType}</TableCell>
                <TableCell>{coupon.model.duration}</TableCell>
                <TableCell>{coupon.model.status}</TableCell>
                <TableCell>
                  {coupon.model.dateCreated.format('LL LT')}
                </TableCell>
                <TableCell>{coupon.model.createdBy?.displayName}</TableCell>
                <TableCell>{coupon.model.redeemedCount}</TableCell>
                <TableCell>
                  <Dropdown
                    position="right"
                    button={
                      <a
                        className="action button is-icon-only"
                        data-test={`coupon-action-button-${coupon.model.code}`}
                      >
                        <Icon
                          material
                          name="more vert"
                          className={styles.icon}
                        />
                      </a>
                    }
                  >
                    <DropdownItem
                      className="normalize"
                      onClick={store.editCoupon.bind(this, coupon)}
                      data-test={`coupon-edit-dropdown-option-${coupon.model.code}`}
                    >
                      Edit
                    </DropdownItem>
                    {coupon.model.couponType.type !== 'REFERRAL_CODE' && (
                      <DropdownItem
                        className="normalize"
                        onClick={store.destroyCoupon.bind(this, coupon)}
                        data-test={`coupon-delete-dropdown-option-${coupon.model.code}`}
                      >
                        Delete
                      </DropdownItem>
                    )}
                    <DropdownItem
                      className="normalize"
                      onClick={store.userCoupons.bind(this, coupon)}
                      data-test={`coupon-users-dropdown-option-${coupon.model.code}`}
                    >
                      Users
                    </DropdownItem>
                  </Dropdown>
                  <ConfirmDialog
                    state={coupon.confirmDestruction}
                    title="Delete coupon"
                    danger
                    data-test="confirm-dialog"
                  >
                    <div className="content">
                      <p>
                        Do you really want to delete{' '}
                        <strong>{coupon.model.code}</strong>?
                      </p>
                      <p>
                        Anyone who has not redeemed this coupon will lose it,
                        and if you create a new coupon with the same code,
                        anyone who have already used this one will also be able
                        to use the new one (this might be what you want).
                      </p>
                    </div>
                  </ConfirmDialog>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <ConfirmDialog
        state={store.deleteCouponsConfirm}
        title="Delete coupon"
        danger
      >
        <div className="content">
          <p>Do you really want to delete these coupon(s)?</p>
          <p>
            Anyone who has not redeemed these coupons will lose it, and if you
            create a new coupon with the same code, anyone who have already used
            this one will also be able to use the new one (this might be what
            you want).
          </p>
        </div>
      </ConfirmDialog>
    </div>
  )
})

CouponList.propTypes = {
  coupons: PropTypes.array.isRequired,
  store: PropTypes.object.isRequired,
}

export default CouponList
