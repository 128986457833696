import React from 'react'
import { observer } from 'mobx-react'

import RequirePerms from 'iam/containers/RequirePerms'
import { FlexContainer, FlexItem } from 'components/Flex'
import ConfirmDialog from 'components/ConfirmDialog'
import Icon from 'components/Icon'
import CircularIconButton from 'components/CircularIconButton'
import { Dropdown, DropdownItem } from 'components/Dropdown'
import { PageTitle } from 'components/PageWrapper'

import TransferJobDialog from '../TransferJobDialog'
import JobUpdateDialog from '../JobUpdateDialog'
import DraftJobUpdateDialog from '../DraftJobUpdateDialog'
import JobInvoiceDialog from '../JobInvoiceDialog'
import AmendmentsDialog from '../AmendmentsDialog'
import AddProviderDialog from '../AddProviderDialog'
import UpdateDeadlineDialog from '../UpdateDeadlineDialog'
import { Input, Select } from 'components/Form'

import './JobDetailsHeader.sass'
import AssignJobToTeamDialog from '../AssignJobToTeamDialog'
import CloneJobDialog from '../CloneJobDialog'
import ChargeJobDialog from '../ChargeJobDialog'
import AddCouponToJobDialog from '../AddCouponToJobDialog'
import { getUnitFormatter } from 'utils/unitFormatter'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'

/**
 * Job Details Header
 *
 * @param  {JobDetailsStore} props.store
 */
const JobDetailsHeader = ({ store }) => {
  const formatUnit = getUnitFormatter(
    store.job.legend && store.job.legend.priceUnit
  )

  const isJobMenuActionDeprecated = getFeatureToggleClient().variation(
    'HQ.JobMenuActionDeprecated',
    false
  )

  return (
    <FlexContainer className="job-details-header">
      <FlexItem stretch>
        <PageTitle>{store.pageTitle}</PageTitle>
      </FlexItem>
      <FlexItem>
        <Dropdown
          position="right"
          button={
            <CircularIconButton outline awesome data-test="open-job-menu">
              <Icon name="cog" />
            </CircularIconButton>
          }
        >
          {isJobMenuActionDeprecated && (
            <DropdownItem onClick={() => store.openInWorkPortal()}>
              View in WorkPortal
            </DropdownItem>
          )}

          {!isJobMenuActionDeprecated &&
            store.job.status !== 'CLOSED' &&
            store.job.status !== 'UNDER_CONSTRUCTION' && (
              <RequirePerms
                anyOf={[
                  'ADMIN_JOB_SUPPORTING_PROVIDER_MANAGE',
                  'ADMIN_JOB_CHAMPION_PROVIDER_MANAGE',
                ]}
              >
                <DropdownItem onClick={() => store.showAddProvider()}>
                  Add {T('Web.Common.Provider', 'Pro')}
                </DropdownItem>
              </RequirePerms>
            )}
          <RequirePerms allOf={['ADMIN_JOB_TRANSFER']}>
            {!isJobMenuActionDeprecated &&
              !store.job.completed &&
              store.job.provider && (
                <DropdownItem onClick={store.initiateJobTransferPro}>
                  Transfer to {T('Web.Common.Provider', 'Pro')}
                </DropdownItem>
              )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_TRANSFER']}>
            {!isJobMenuActionDeprecated && !store.job.completed && (
              <DropdownItem onClick={store.initiateJobTransferClient}>
                Transfer to {T('Web.Common.Client', 'Client')}
              </DropdownItem>
            )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_TRANSFER']}>
            {!isJobMenuActionDeprecated &&
              (store.job.status === 'CLAIMED' ||
                store.job.status === 'IDLE') && (
                <DropdownItem onClick={store.transferToPool}>
                  Transfer to Pool
                </DropdownItem>
              )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_UPDATE']}>
            {!isJobMenuActionDeprecated && !store.job.completed && (
              <DropdownItem onClick={store.initiateJobUpdate}>
                Update Job
              </DropdownItem>
            )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_ON_HOLD']}>
            {!isJobMenuActionDeprecated &&
              (store.job.status === 'CLAIMED' ||
                store.job.status === 'ON_HOLD' ||
                store.job.status === 'IDLE') && (
                <DropdownItem onClick={store.toggleHoldJob}>
                  {store.job.status === 'CLAIMED' || store.job.status === 'IDLE'
                    ? 'Place Job On Hold'
                    : 'Resume Job'}
                </DropdownItem>
              )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_CLOSE']}>
            {!isJobMenuActionDeprecated &&
              (store.job.status === 'UNCLAIMED' ||
                store.job.status === 'ON_HOLD' ||
                store.job.status === 'CLAIMED' ||
                store.job.status === 'IDLE') && (
                <DropdownItem
                  data-test="job-view-close-job"
                  onClick={store.closeJob}
                >
                  Close Job
                </DropdownItem>
              )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_UPDATE']}>
            {!isJobMenuActionDeprecated && store.job.status === 'UNCLAIMED' && (
              <DropdownItem onClick={store.notifyPros}>
                Notify {T('Web.Common.Providers', 'Pros')}
              </DropdownItem>
            )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_UPDATE']}>
            {!isJobMenuActionDeprecated && (
              <DropdownItem onClick={store.initiateAddInvoiceToJob}>
                Add Manual Invoice
              </DropdownItem>
            )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_UPDATE']}>
            {!isJobMenuActionDeprecated &&
              !store.job.completed &&
              store.job.dateDeadline && (
                <DropdownItem onClick={store.initiateUpdateDeadline}>
                  Update Deadline
                </DropdownItem>
              )}
          </RequirePerms>

          <RequirePerms allOf={['ADMIN_ASSIGN_TEAM']}>
            {!isJobMenuActionDeprecated && !store.job.completed && (
              <DropdownItem onClick={store.initiateAssignJobToTeam}>
                Transfer to/off {T('Web.Common.Client', 'Client')} Team
              </DropdownItem>
            )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_CREATE']}>
            {!isJobMenuActionDeprecated && (
              <DropdownItem onClick={store.initiateCloneJob}>
                Clone Job
              </DropdownItem>
            )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_AMEND']}>
            {!isJobMenuActionDeprecated && !store.job.completed && (
              <DropdownItem onClick={store.initiateAmendments}>
                Amendments
              </DropdownItem>
            )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_BILLING_MANAGE']}>
            {!isJobMenuActionDeprecated &&
              store.job.couponCodes.length === 0 &&
              store.rootStore.sessionStore.workspace.features.billing
                .couponsEnabled && (
                <DropdownItem
                  onClick={store.initiateAddCouponToJob}
                  data-test="job-view-add-coupon"
                >
                  Add Coupon
                </DropdownItem>
              )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_REOPEN']}>
            {!isJobMenuActionDeprecated && store.job.completed && (
              <DropdownItem
                data-test="job-view-reopen-job"
                onClick={store.reopenJob}
              >
                Reopen Job
              </DropdownItem>
            )}
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_JOB_DELETE']}>
            {!isJobMenuActionDeprecated && (
              <DropdownItem onClick={store.deleteJob}>Delete Job</DropdownItem>
            )}
          </RequirePerms>
        </Dropdown>
      </FlexItem>
      <TransferJobDialog store={store.jobTransferState} />
      <JobUpdateDialog store={store.legacyJobUpdateState} />
      <JobUpdateDialog store={store.jobUpdateState} />
      <DraftJobUpdateDialog store={store.draftJobUpdateState} />
      <JobInvoiceDialog store={store.jobInvoiceState} />
      <AssignJobToTeamDialog store={store.assignJobToTeamState} />
      <CloneJobDialog store={store.cloneJobState} />
      <ChargeJobDialog store={store.chargeJobState} />
      <AmendmentsDialog store={store.amendmentsState} />
      <AddProviderDialog store={store.addProviderState} />
      <UpdateDeadlineDialog store={store.updateDeadlineState} />
      <AddCouponToJobDialog state={store.addCouponToJobState} />
      <ConfirmDialog
        state={store.reopenJobConfirm}
        title="Reopen Job"
        yes="Reopen"
        danger
        data-test="job-reopen-confirm"
        disabled={store.reopening}
      >
        Do you really want to reopen this job?{' '}
        <strong>I hope you know what you're doing.</strong>
        <br />
        <br />
        <Input
          model={store}
          field="reason"
          label="Reason"
          data-test="job-reopen-reason"
          help="Why are you reopening? This text is public and can be seen by the Client and Pros."
        />
      </ConfirmDialog>
      <ConfirmDialog
        state={store.notifyProsConfirm}
        title="Notify Pros of Open Job?"
        yes="Notify"
        disabled={store.notifyProsConfirm.pending}
        danger
      >
        Do you really want to notify all available{' '}
        {T('Web.Common.Providers', 'Pros')}?{' '}
        <strong>I hope you know what you're doing.</strong>
        <br />
        <br />
      </ConfirmDialog>
      <ConfirmDialog
        state={store.deleteMessageConfirm}
        title="Delete Message"
        yes="Delete"
        danger
      >
        <p>
          Are you sure you want to delete this message? This cannot be undone.
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        state={store.transferToPoolConfirm}
        title="Transfer to Pool"
        yes="Transfer"
        danger
      >
        Do you really want to transfer this job back to the pool?
        <strong> I hope you know what you're doing.</strong>
        <br />
        <br />
        <Input
          model={store}
          field="reason"
          label="Reason"
          help="Why are you transferring to the pool? This text is public and can be seen by the Client and Pros."
        />
      </ConfirmDialog>
      <ConfirmDialog
        state={store.closeJobConfirm}
        title="Close Job"
        yes="Close"
        data-test="job-close-confirm"
        danger
      >
        Do you really want to close this job?
        <div style={{ color: 'red' }}>
          The resolution is only for filtering jobs. You have to amend the job
          before you close it!
        </div>
        <br />
        <Select
          model={store}
          field="resolution"
          label="Resolution"
          help="The resolution for this job."
          data-test="job-close-resolution"
        >
          {(store.job.status === 'CLAIMED' ||
            store.job.status === 'ON_HOLD' ||
            store.job.status === 'IDLE') && <option value="DONE">Done</option>}
          <option value="CANCELLED">Cancelled</option>
          <option value="TEST">Test</option>
        </Select>
        {store.job.total !== store.job.paidAmount && store.shouldShowBilling && (
          <>
            <Input
              label={
                store.job.total > store.job.paidAmount
                  ? `Bill Final Amount (${formatUnit(
                      store.job.total - store.job.paidAmount
                    )})`
                  : `Refund Final Amount (${formatUnit(
                      store.job.paidAmount - store.job.total
                    )})`
              }
              type="checkbox"
              model={store}
              field="doBilling"
              data-test="job-close-do-billing"
              help="Should we charge/refund the default billing method for the outstanding amount?"
            />
            <Select
              label="Billing Method"
              field="billingMethodId"
              data-test="job-close-billing-method"
              model={store}
            >
              <option key="default" value="default">
                Default
              </option>
              {store.billingMethods.map((pm) => (
                <option key={pm.id} value={pm.id}>
                  {pm.type === 'Credit'
                    ? 'Team Credit'
                    : `${pm.brand} ending in ${pm.last4}`}
                </option>
              ))}
            </Select>
          </>
        )}
        <Input
          model={store}
          field="reason"
          label="Reason"
          data-test="job-close-reason"
          help="Why are you closing the job? This text is public and can be seen by the Client and Pros."
        />
      </ConfirmDialog>
      <ConfirmDialog
        state={store.deleteJobConfirm}
        title="Delete Job"
        yes="Delete"
        danger
      >
        <p>Do you really want to delete this job?</p>
        <p>
          Documents within this job will be deleted if they are not linked
          anywhere else.
        </p>
        <strong> I hope you know what you're doing.</strong>
      </ConfirmDialog>
    </FlexContainer>
  )
}

export default observer(JobDetailsHeader)
