import { useCallback, useEffect } from 'react'

/**
 * Like `useObservableStream`, we just don't care about the value, and invoke a
 * callback on every item emitted.
 *
 * @template T
 * @param {Observable<T>} source
 * @param {(value: T) => unknown} next
 * @param {readonly unknown[]} deps
 */
export function useObservableCallback(source, next, deps) {
  deps = deps || []
  next = useCallback(next || (() => undefined), deps)
  useEffect(() => {
    const source$ = typeof source === 'function' ? source() : source
    const subscription = source$?.subscribe(next)
    return () => {
      subscription?.unsubscribe()
    }
  }, [next, ...deps])
}
