import { observable, action, computed } from 'mobx'
import { editor, field } from 'data/editor'
import { required } from 'validx'
import { task } from 'mobx-task'

/**
 * Amendments UI state.
 */
export default class AmendmentsState {
  @observable
  showing = false

  @observable
  editor = null

  @observable
  error = ''

  @observable
  confirmation = null

  @observable
  job

  constructor({ rootStore }) {
    this.rootStore = rootStore
    this.confirmAmendment = this.confirmAmendment.bind(this)
  }

  @computed
  get amendments() {
    if (!this.job) return []
    return this.job.amendments
  }

  @action.bound
  init(job) {
    this.job = job
    this.confirmation = null
    this.editor = null
    this.confirmAmendment.reset()
  }

  @action.bound
  newAmendment() {
    this.editor = new AmendmentEditor(null, {
      cancel: () => this.cancelNew(),
      save: (attrs) => this.saveAmendment(attrs),
    })
  }

  @action.bound
  cancelNew() {
    this.editor = null
  }

  @action.bound
  saveAmendment(attrs) {
    this.confirmation = attrs
  }

  @task.resolved
  async confirmAmendment() {
    return this.rootStore.jobStore
      .createAmendment(this.job.id, this.confirmation)
      .then(
        action(() => {
          this.confirmation = null
          this.cancelNew()
        })
      )
  }

  @action.bound
  backToEditor() {
    this.confirmation = null
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }
}

@editor()
class AmendmentEditor {
  @field
  description = ''

  @field
  internalNote = ''

  @field
  customerAmount = 0

  @field
  providerAmount = 0

  @field
  visibility = ''

  // noinspection JSUnusedLocalSymbols
  constructor(model, opts) {
    this.opts = opts
    this.cancelEditing = this.cancelEditing.bind(this)
    this.save = this.save.bind(this)
  }

  validationRules = {
    description: [
      required(
        'Description is required, the Client/Pro should know what they are paying/getting paid for.'
      ),
    ],
    internalNote: [required('Internal note is required')],
    visibility: [required('Visibility is required')],
    customerAmount: [required('Client amount is required')],
    providerAmount: [required('Pro amount is required')],
  }

  cancelEditing() {
    this.opts.cancel()
  }

  save(attrs) {
    return this.opts.save({ ...attrs })
  }
}
