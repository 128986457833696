import React from 'react'
import { ListItem, ListItemSegment } from 'components/ListItem'
import Avatar from 'components/Avatar'
import Link from 'components/Link'
import './styles.sass'
import {
  consolidateSchedules,
  shortenStates,
} from '@taxfyle/web-commons/lib/utils//jobUtil'
import { generateAvatarFallback } from 'utils/dummyImageUtil'

export const JobItem = ({ children, to, onClick, target }) => (
  <Link className="unstyled job-item" to={to} target={target} onClick={onClick}>
    <ListItem className="job-item-inner" border clickable>
      {children}
    </ListItem>
  </Link>
)

export const JobItemAvatar = ({ picture, name }) => {
  const fallback = generateAvatarFallback(name)
  return (
    <ListItemSegment pad className="job-item-avatar">
      <Avatar
        size={38}
        url={picture || fallback}
        title={name}
        fallback={fallback}
      />
    </ListItemSegment>
  )
}

export const JobItemText = ({ title, forms, unreadCount, states, status }) => {
  forms = consolidateSchedules(forms)
  states = shortenStates(states)
  return (
    <ListItemSegment pad className="job-item-text" stretch>
      <div className="job-item-text__title">
        {title}
        {!!unreadCount && (
          <div className="job-item-text__unread-count">{unreadCount}</div>
        )}
      </div>
      <div className="job-item-text__forms">
        {forms.map((item, i) => (
          <span className="has-bullet" key={i}>
            {item}
          </span>
        ))}
      </div>
      <div className="job-item-text__states">
        <span className="has-bullet">{status}</span>
      </div>
    </ListItemSegment>
  )
}

export const JobItemStat = ({ title, subtitle }) => (
  <ListItemSegment pad className="job-item-stat">
    <div className="job-item-stat__title">{title}</div>
    <div className="job-item-stat__subtitle">{subtitle}</div>
  </ListItemSegment>
)
