import QuestionEditor from '../../QuestionEditor'
import { snapshotable } from 'legend-builder/data/serialization'
import {
  receiveErrors,
  addGenericErrorForProperty,
} from 'legend-builder/data/serverErrors'
import { validateObject } from 'utils/validx-validators'
import { observable } from 'mobx'
import { serializable } from '../../../data/serialization'

@snapshotable
export default class EffectQuestionEditor extends QuestionEditor {
  static friendlyType = 'Effect'
  static type = 'Effect'
  static viewOrder = 3
  static optionalId = true
  static isMetaElement = true
  static iconColor = '#763EF4'

  /**
   * Effect label.
   */
  @observable
  @serializable
  label = ''

  rules = {
    id: [], // Removes the ID rules.
    effect: [validateObject('There are invalid effects.')],
  }

  constructor(attrs, opts) {
    super(null, { ...opts, parse: false })
    this.effect = this.createEffect()
    this.effect.setExpanded(true)
    this.set(attrs, opts)
    this.effectDescriptors = [
      {
        id: 'effect',
        title: 'Effect',
        effect: this.effect,
      },
    ]
  }

  parse(attrs, opts) {
    const rest = this.effect.hydrate(attrs)
    return {
      ...super.parse(rest, opts),
    }
  }

  toJSON() {
    return {
      ...super.toJSON(...arguments),
      ...this.effect.toJSON(),
      id: undefined, // Effects don't have IDs.
    }
  }

  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'effect',
      'There are invalid effects.'
    )
    receiveErrors(this.effect, descriptor.inner)
    super.receiveErrors(descriptor)
  }
}
