import { Model } from 'libx'
import { observable } from 'mobx'
import moment from 'moment'
import uniqueId from 'lodash/uniqueId'
import { omitUndefined } from 'utils/objectUtil'
import { AmendmentVisibility } from '@taxfyle/api-internal/internal/job_admin_pb'

export default class Amendment extends Model {
  cid = uniqueId('amendment-')

  @observable
  id

  @observable
  dateCreated

  @observable
  visibility = 'INTERNAL'

  @observable
  description = ''

  @observable
  internalNote = ''

  @observable
  creator

  @observable
  customerAmount

  @observable
  providerAmount

  parse(json) {
    return omitUndefined({
      id: json.date_created,
      dateCreated: moment(json.date_created),
      visibility: json.visibility,
      description: json.description,
      internalNote: json.internal_note,
      creator: this.rootStore.userStore.users.set(json.creator),
      customerAmount: json.customer_amount,
      providerAmount: json.provider_amount,
    })
  }
}

export function mapVisibilityToGrpcRequest(visiblity) {
  switch (visiblity) {
    case 'PUBLIC':
      return AmendmentVisibility.PUBLIC
    case 'INTERNAL':
      return AmendmentVisibility.INTERNAL
    default:
      throw new Error('This should never happen!')
  }
}
