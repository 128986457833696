import { Store } from 'libx'
import PublishedLegend from './PublishedLegend'
import env from 'misc/env'
import { PublishedLegendAPI } from './API'

/**
 * Store for retrieving published legends.
 */
export default class PublishedLegendStore extends Store {
  api = new PublishedLegendAPI({
    baseURL: env.LEGEND_API,
    getToken: this.rootStore.authStore.getToken,
  })

  publishedLegends = this.collection({
    model: PublishedLegend,
  })

  /**
   * Fetches published legends for the workspace.
   *
   * @param workspaceId
   * @param params
   * @returns {Promise<{data}>}
   */
  async fetchPublishedLegends(workspaceId, params) {
    const found = await this.api.find({
      workspace_id: workspaceId,
      ...params,
    })

    return {
      ...found,
      data: this.publishedLegends.set(found.data),
    }
  }

  /**
   * Fetches the specified published Legend version.
   *
   * @param {string} legendId The ID of the Legend to fetch.
   * @param {number | null} legendVersion The version of the Legend, or
   *  `null` to get the latest published version.
   * @param skipCache If `true` it won't use the in memory cache
   * @returns {Promise<PublishedLegend>}
   */
  async fetchPublishedLegendVersion(
    legendId,
    legendVersion = null,
    skipCache = false
  ) {
    const cached = this.publishedLegends.get(legendId)
    // making sure the cached model is not just from the parse but the full object

    if (!skipCache && cached && cached.workspaceId) {
      return cached
    }
    const legend = await this.api.getVersion(legendId, legendVersion)
    return this.publishedLegends.set(legend)
  }
}
