import React from 'react'
import { observer } from 'mobx-react'
import { Form, Input, TextArea } from 'components/Form'
import ShadowBox from 'components/ShadowBox'
import Button from 'components/Button'

export default observer(function ReplyEditor({ editor }) {
  return (
    <ShadowBox className="skill-edit-view">
      <Form onSubmit={() => editor.save()} disabled={editor.disabled}>
        <Input
          model={editor}
          field="title"
          label="Message title"
          help={`${250 - editor.title.length} characters left`}
        />
        <TextArea
          className="textarea"
          rows={15}
          label="Message"
          field="content"
          model={editor}
          help={`${4000 - editor.content.length} characters left`}
        />
        <Button type="primary" submit disabled={editor.disabled}>
          Save
        </Button>
        &nbsp;
        <Button onClick={() => editor.close()}>Cancel</Button>
      </Form>
    </ShadowBox>
  )
})
