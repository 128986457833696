import { Store } from 'libx'
import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'

export default class UserPermissionsEditorStore extends Store {
  @observable
  user = null

  @observable
  showing = false

  @observable.ref
  permissions = {}

  @action.bound
  show(user) {
    this.user = user
    this.permissions = { ...user.memberPermissions }
    this.showing = true
  }

  @computed
  get availablePermissions() {
    const hidePermissions = [
      'VIEW_GROUP_CHAT',
      'WRITE_GROUP_CHAT',
      'VIEW_TEAM_JOBS',
      'CREATE_TEAM_JOBS',
      'UPDATE_TEAM_JOBS',
      'MANAGE_TEAM_BILLING',
      'MANAGE_TEAM_MEMBER',
      'UPDATE_TEAM_MEMBER_SKILLS',
      'START_TEAM_JOBS',
      'VIEW_TEAM_PRICING',
      'TEAM_ANALYTICS_VIEW',
      'ORGANIZATION_VIEW',
      'ORGANIZATION_MANAGE',
      'ADMIN_STAFFING_MANAGE',
    ]
    return this.rootStore.permissionStore.permissions
      .slice()
      .filter((p) => !hidePermissions.includes(p.id))
  }

  @action
  changePermissions(permissions) {
    this.permissions = permissions
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @task.resolved
  @action
  async save() {
    await this.rootStore.memberStore.updatePermissionsByPublicId(
      {
        workspace_id: this.rootStore.sessionStore.workspace.id,
        user_id: this.user.userPublicId,
      },
      this.permissions
    )
    this.hide()
  }
}
