import { observable, action, computed } from 'mobx'
import identity from 'lodash/identity'
import { required } from 'validx'
import { move } from 'utils/mobx-util'
import SelectOption from './SelectOption'
import QuestionEditor from '../../QuestionEditor'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import { Types } from 'legend-builder/data/Type'
import { addGenericErrorForProperty } from '../../../data/serverErrors'
import { validateObject } from '../../../../utils/validx-validators'

@snapshotable
export default class SelectQuestionEditor extends QuestionEditor {
  static friendlyType = 'Select'
  static type = 'Select'
  static viewOrder = 2
  static iconColor = '#007BE7'

  @observable
  @serializable
  question = 'New Select Question'

  @observable
  @serializable
  description = ''

  @observable
  @serializable((v) => v.map((o) => o.toJSON()))
  options = []

  @observable
  @serializable
  multi = false

  @serializable
  effect

  rules = {
    effect: [validateObject('There are invalid effects')],
    question: [required('Question is required')],
    options: [this._validateOptions.bind(this)],
  }

  constructor(attrs, opts) {
    super(null, opts)
    this.effect = this.createEffect()
    this.set(attrs, opts)
  }

  /**
   * Used by the UI to determine how to prompt for a possible answer.
   */
  @computed
  get answerOptions() {
    return {
      type: 'select',
      options: this.options.map((o) => ({
        id: o.id,
        value: o.id,
        text: o.text,
      })),
    }
  }

  /**
   * Used by the Effects tab to render an editor for each possible effect.
   */
  @computed
  get effectDescriptors() {
    const selectEffectDescriptors = this.multi
      ? [
          {
            id: this.id,
            title: `${this.id} (will run before option effects)`,
            effect: this.effect,
          },
        ]
      : []
    return [
      ...selectEffectDescriptors,
      ...this.options.map((o) => ({
        id: o.id,
        title: o.text || o.id || '(untitled)',
        effect: o.effect,
      })),
    ]
  }

  /**
   * Used by the Conditions tab to render a filters builder for each select option in addition to the question conditions.
   */
  @computed
  get triggerWhenDescriptors() {
    return this.options.map((o) => ({
      id: o.id,
      title: o.text || o.id || '(untitled)',
      triggerWhen: o.triggerWhen,
    }))
  }

  @computed
  get valueType() {
    return this.multi ? Types.list(Types.string) : Types.string
  }

  @action.bound
  addOption() {
    this.options.push(new SelectOption({}, { question: this }))
  }

  @action.bound
  removeOption(option) {
    this.options.remove(option)
  }

  @action.bound
  moveByIndex(oldIndex, newIndex) {
    move(this.options, oldIndex, newIndex)
  }

  parse(json, opts) {
    if (!json) {
      return undefined
    }

    const { effect, options, ...attrs } = json
    this.effect.hydrate(effect)
    if (options) {
      this.options.replace(
        options.map((x) => new SelectOption(x, { parse: true, question: this }))
      )
    }

    return {
      ...super.parse(attrs, opts),
    }
  }

  /**
   * Adds a generic error about options if present.
   * @param {*} descriptor
   */
  receiveErrors(descriptor) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'options',
      'There are invalid options'
    )
    super.receiveErrors(descriptor)
  }

  _validateOptions() {
    const valid = this.options.map((o) => o.validateAll()).every(identity)
    return valid || 'One or more options are invalid'
  }
}
