import { match } from 'utils/patternMatchUtil'
import { InventoryItemPriceType } from '@taxfyle/api-internal/internal/inventory_item_pb'
import { decimalToNumber } from '@taxfyle/web-commons/lib/utils/grpcUtil'

/**
 * Computes the price and pro price for the job from the given inventory items.
 *
 * @param {*} inventoryItems
 * The list of inventory items with which to compute the price.
 *
 * @returns Computed price information.
 */
export function computePrice(inventoryItems, basePrice = 0) {
  const prices = orderInventoryItems(inventoryItems)
    .map(InventoryItem)
    .reduce(compute, { price: basePrice, proPrice: 0 })

  return {
    price: parseFloat(prices.price.toFixed(2)),
    proPrice: parseFloat(prices.proPrice.toFixed(2)),
  }

  /**
   * Computes job prices for inventory items.
   *
   * @param {{ price: number, proPrice: number}} totals
   * The accumulated totals - includes the price and pro price.
   *
   * @param {*} item
   * The current inventory item.
   *
   * @returns {{ price: number, proPrice: number}}
   * A new total object.
   */
  function compute(totals, item) {
    const itemPrice = item.getPriceValue(totals.price) * item.clientQuantity
    const proPrice = item.getProPriceValue(totals.proPrice) * item.proQuantity

    const newPrice = itemPrice + totals.price
    const newProPrice = proPrice + totals.proPrice

    return {
      price: newPrice,
      proPrice: newProPrice,
    }
  }
}

/**
 * Computes the Prep and Review price and pro price for the job.
 *
 * @param {*} inventoryItems
 * The list of inventory items with which to compute the price.
 *
 * @param {*} allowedWorkspaceInventoryItems
 * The list of inventory items allowed in the workspace.
 *
 * @param {*} legendOptions
 * The legend options to use for the computation.
 *
 * @returns Computed Prep and Review price information.
 */
export function computeReviewPricing(
  inventoryItems,
  allowedWorkspaceInventoryItems,
  legendOptions,
  bundle
) {
  // Check the prep and review config and whether it's enabled.
  const prepAndReviewConfig = legendOptions?.prep_and_review_config
  if (
    !inventoryItems?.length ||
    !prepAndReviewConfig ||
    prepAndReviewConfig?.enabled === false
  ) {
    return {
      reviewPrice: null,
      reviewProPrice: null,
    }
  }

  // Get both review and prep and review workspace inventory items using the config items.
  const reviewInventoryItem = allowedWorkspaceInventoryItems?.find(
    (i) => i.id === prepAndReviewConfig?.review_service_item_id
  )
  const prepAndReviewInventoryItem = allowedWorkspaceInventoryItems?.find(
    (i) => i.id === prepAndReviewConfig?.prep_and_review_service_item_id
  )

  // Shouldn't happen but just in case.
  if (!reviewInventoryItem || !prepAndReviewInventoryItem) {
    return {
      reviewPrice: null,
      reviewProPrice: null,
    }
  }

  // Get the review and prep and review items from the inventory items list.
  const reviewPreviewerItem = inventoryItems.find(
    (i) => i.id === reviewInventoryItem.id
  )
  const prepAndReviewPreviewerItem = inventoryItems.find(
    (i) => i.id === prepAndReviewInventoryItem.id
  )

  // Let's check if the prep and review item or review item is found in the previewer inventory items.
  const hasPrepAndReview =
    Boolean(prepAndReviewInventoryItem) && Boolean(prepAndReviewPreviewerItem)
  const hasReview = Boolean(reviewInventoryItem) && Boolean(reviewPreviewerItem)

  if (hasPrepAndReview || !hasReview) {
    return {
      reviewPrice: null,
      reviewProPrice: null,
    }
  }

  // If there's a bundle, we need to take into account the included quantities of the items in the bundle.
  let filteredInventoryItems = inventoryItems
  if (bundle) {
    filteredInventoryItems = inventoryItems.map((item) => {
      const isBaseItem = bundle.baseItem.inventoryItemId === item.id
      if (isBaseItem) {
        return {
          ...item,
          // Base item is always quantity 1.
          quantity: Math.max(0, item.quantity - 1),
        }
      }

      const includedItem = bundle.includedItemsList.find(
        (i) => i.inventoryItemId === item.id
      )
      if (includedItem) {
        return {
          ...item,
          quantity: Math.max(0, item.quantity - includedItem.includedQuantity),
        }
      }
      return item
    })
  }

  // Let's compute the review price since we will need it in either case below.
  // Get all the tax workspace inventory items.
  const taxPreviewerInventoryItems = allowedWorkspaceInventoryItems.filter(
    (i) =>
      i.typeSpecification?.spec?.tax !== undefined &&
      inventoryItems.some((item) => item?.id === i?.id)
  )

  // Filter out the inventory item that matches any of the tax previewer inventory items.
  const defaultInventoryItems = inventoryItems.filter(
    (i) => !taxPreviewerInventoryItems.some((item) => item.id === i.id)
  )
  const inventoryItemPrices = computePrice(defaultInventoryItems)

  // Now get the review price from the type spec.
  const reviewPrice =
    taxPreviewerInventoryItems.reduce((total, i) => {
      const previewItem = filteredInventoryItems.find(
        (item) => item.id === i.id
      )
      const itemReviewPrice =
        decimalToNumber(i.typeSpecification?.spec?.tax?.reviewPrice) *
        Math.max(0, previewItem.quantity - i.courtesyAmount)
      return total + itemReviewPrice
    }, 0) + inventoryItemPrices.price
  const reviewProPrice =
    taxPreviewerInventoryItems.reduce((total, i) => {
      const previewItem = inventoryItems.find((item) => item.id === i.id)
      const itemReviewPrice =
        decimalToNumber(i.typeSpecification?.spec?.tax?.reviewProPrice) *
        previewItem.quantity
      return total + itemReviewPrice
    }, 0) + inventoryItemPrices.proPrice

  return {
    reviewPrice: parseFloat(reviewPrice.toFixed(2)),
    reviewProPrice: parseFloat(reviewProPrice.toFixed(2)),
  }
}

/**
 * Orders a list of inventory items such that flat items are first,
 * rate (percentage) items are second, and fee items are last.
 *
 * @param {*} inventoryItems
 * A list of inventory items.
 *
 * @returns
 * An ordered list of inventory items.
 */
function orderInventoryItems(inventoryItems) {
  const flatItems = inventoryItems.filter(
    (i) => i.priceType === InventoryItemPriceType.FLAT
  )

  const markupItems = inventoryItems.filter(
    (i) => i.priceType === InventoryItemPriceType.MARKUP
  )

  const markdownItems = inventoryItems.filter(
    (i) => i.priceType === InventoryItemPriceType.MARKDOWN
  )

  const feeItems = inventoryItems.filter(
    (i) => i.priceType === InventoryItemPriceType.FEE
  )

  return [...flatItems, ...markupItems, ...markdownItems, ...feeItems]
}

/**
 * A domain-like representation of an Inventory Item.
 *
 * Remarks: One should not typically operate on proto/DTO types like this,
 * but there isn't an existing separation in the application currently, the
 * "type" is isolated to this file, and any semblance of good practices went
 * out the window when JavaScript was chosen for this project anyway.
 *
 * @param {*} item
 * The Inventory Item proto/DTO type.
 *
 * @returns
 * A behavior-rich Inventory Item "domain"-like model.
 */
function InventoryItem(item) {
  return {
    /**
     * Computes the price for the inventory item based on the job price.
     *
     * @param {number} jobTotal
     * The total for the job.
     *
     * @returns {number}
     * The price value for the inventory item.
     */
    getPriceValue(jobTotal) {
      return match(item.priceType, {
        [InventoryItemPriceType.FEE]: () => item.price,
        [InventoryItemPriceType.FLAT]: () => item.price,
        [InventoryItemPriceType.MARKUP]: () => item.price * jobTotal,
        [InventoryItemPriceType.MARKDOWN]: () => -1 * item.price * jobTotal,
      })
    },

    /**
     * Computes the pro price for the inventory item based on the job's pro cut.
     *
     * @param {number} proPrice
     * The pro price/cut for the job.
     *
     * @returns {number}
     * The pro price value for the inventory item.
     */
    getProPriceValue(proPrice) {
      return match(item.priceType, {
        [InventoryItemPriceType.FEE]: () => 0,
        [InventoryItemPriceType.FLAT]: () => item.proPrice,
        [InventoryItemPriceType.MARKUP]: () => item.proPrice * proPrice,
        [InventoryItemPriceType.MARKDOWN]: () => -1 * item.proPrice * proPrice,
      })
    },

    /**
     * The Inventory Item quantity applicable to the client.
     *
     * @returns {number}
     */
    get clientQuantity() {
      return Math.max(0, item.quantity - item.courtesyQuantity)
    },

    /**
     * The Inventory Item quantity applicable to the pro.
     *
     * @returns {number}
     */
    get proQuantity() {
      return item.quantity
    },
  }
}
