import { Store } from 'libx'
import { task } from 'mobx-task'
import { observable, action } from 'mobx'
import { validationContext, required } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'

export default class DocumentTagEditorStore extends Store {
  @observable
  showing = false

  @observable
  label = ''

  @observable
  color = ''

  validation = validationContext(this, {
    label: [required('Label is required')],
  })

  @action
  show(tag) {
    this.validation.reset()
    this.showing = true
    this.tag = null
    this.label = ''
    this.color = '#C0C0C8'
    if (tag) {
      this.tag = tag
      this.label = tag.label
      if (tag.color) {
        this.color = tag.color
      }
    }
  }

  @action
  close() {
    this.showing = false
  }

  @task.resolved
  async save() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    if (!this.isUnique(this.label)) {
      this.validation.addErrors({
        label: ['Label already exists for this workspace'],
      })
      return
    }
    const documentTagStore = this.rootStore.documentTagStore
    const data = {
      label: this.label.trim(),
      color: this.color,
      workspace_id: this.rootStore.sessionStore.workspace.id,
    }
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Saving tag...',
      inProgress: true,
    })
    const id = this.tag && this.tag.id
    try {
      if (id) {
        await documentTagStore.update(id, data)
      } else {
        await documentTagStore.create(data)
      }

      msg.done('Tag saved!').autoDismiss()
      this.close()
    } catch (e) {
      msg.failed(extractMessageFromError(e)).autoDismiss()
    }
  }

  isUnique(label) {
    const tags = this.rootStore.documentTagsScreenStore.tags
    label = label.trim().toLowerCase()
    return !tags.find((tag) => label === tag.label.trim().toLowerCase())
  }
}
