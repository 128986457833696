import RoutingContextSourceEditor from '../RoutingContextSourceEditor'
import { serializable, snapshotable } from '../../../data/serialization'
import { computed, observable } from 'mobx'
import { Types } from '../../../data/Type'
import { makeDuplicateIdValidation } from '../../../mixins/ids'

const { idRules, installHook } = makeDuplicateIdValidation(
  (v) => v.legendEditor.allEntities
)

/**
 * User Work Request Count editor.
 */
@snapshotable
export default class UserWorkRequestCountEditor extends RoutingContextSourceEditor {
  static friendlyType = 'User Work Request Count'
  static friendlyDescription = `Brings in the candidate's Work Request count`
  static type = 'UserWorkRequestCount'

  @observable
  @serializable
  id = 'USER_WORK_REQUEST_COUNT' // Default ID.

  valueType = Types.number

  rules = {
    id: idRules,
  }

  constructor() {
    super(...arguments)
    installHook(this)
  }

  @computed
  get entityDescriptors() {
    return [
      {
        type: 'UserWorkRequestCount',
        entity: this,
      },
    ]
  }
}
