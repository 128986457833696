import { validatable } from '../mixins/validation'
import { observable, computed, action } from 'mobx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { receiveErrors } from '../data/serverErrors'
import { Types } from '../data/Type'
import RynoEditor from '../ryno-editor/RynoEditor'
import { func } from 'validx'

/**
 * Title config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class TitleConfigEditor {
  @observable
  @serializable
  expr

  rules = {
    expr: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.expr = new RynoEditor({
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => Types.string,
    })
  }

  @computed
  get symbolTable() {
    return this.legendEditor.getSymbolTableFor(this)
  }

  hydrate(titleConfig) {
    if (!titleConfig) {
      this.expr.setValue('')
      return
    }

    const { expr } = titleConfig
    this.expr.setValue(expr, true)
  }

  @action.bound
  useTemplate() {
    this.expr.setValue(
      `
      `.trim()
    )
  }

  toJSON() {
    return serialize(this)
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    receiveErrors(this, inner)
  }
}
