import cx from 'classnames'
import { FormGroup } from 'components/Form'
import React from 'react'
import styles from './Table.m.sass'

export const Table = ({ children, searchText, onSearchTextChange }) => {
  return (
    <FormGroup>
      <div className={cx(styles.root)}>
        {onSearchTextChange && (
          <div className={styles.searchBar}>
            <input
              type="search"
              className={styles.searchInput}
              value={searchText}
              onChange={(e) => onSearchTextChange(e.target.value)}
              placeholder="Type to search..."
            />
          </div>
        )}

        <div className={styles.tableContainer}>
          <table className={styles.table}>{children}</table>
        </div>
      </div>
    </FormGroup>
  )
}

export const TableHead = ({ children }) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  )
}

export const TableHeader = ({
  children,
  centered,
  canSort = false,
  sortOrder = 'asc',
  onSort = () => null,
}) => {
  if (canSort) {
    return (
      <th
        className={cx(centered && styles.centeredText, styles.pointer)}
        onClick={() => {
          switch (sortOrder) {
            case 'none':
              onSort('asc')
              break
            case 'asc':
              onSort('desc')
              break
            default:
              onSort('none')
          }
        }}
      >
        {children}
        {canSort && sortOrder !== 'none' && (
          <i className={cx(styles.sortable, 'fa', `fa-sort-${sortOrder}`)} />
        )}
      </th>
    )
  }
  return <th className={cx(centered && styles.centeredText)}>{children}</th>
}

export const TableBody = ({ children }) => {
  return <tbody>{children}</tbody>
}

export const TableRow = ({ children }) => {
  return <tr>{children}</tr>
}

export const TableCell = ({ children, centered }) => {
  return <td className={cx(centered && styles.centeredText)}>{children}</td>
}
