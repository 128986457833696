import { action, computed, observable, reaction } from 'mobx'

const DATA_SOURCE_INPUTS_KEY = 'previewer.datasourceinputs'

/**
 * Data Source inputs part of the previewer.
 */
export default class DataSourceInputs {
  /**
   * Routing data source inputs. We are reusing the questions
   * infrastructure.
   */
  @observable routingContextDataSourceInputs = []

  /**
   * Constructor.
   *
   * @param legendEditor The Legend Editor.
   * @param getComputeResult Reactive getter for the compute result.
   */
  constructor(legendEditor, getComputeResult) {
    this.legendEditor = legendEditor
    this.getComputeResult = getComputeResult
    // Whenever the inputs change, write them to storage.
    reaction(
      () => this.routingContextDataSourceInputs.slice(),
      (routingContextDataSourceInputs) =>
        this.legendEditor.storage.setItem(this._mapDataSourceInputsKey(), {
          routingContextDataSourceInputs,
        }),
      { delay: 500 }
    )
  }

  /**
   * The routing context data sources.
   */
  @computed
  get routingContextDataSources() {
    return this.getComputeResult().routingContextDataSources ?? []
  }

  /**
   * Mapped routing context data source inputs for the compute payload.
   */
  @computed
  get mappedRoutingContextDataSourceInputs() {
    return this.routingContextDataSourceInputs.map((x) => ({
      id: x.id,
      value: x.answer,
    }))
  }

  /**
   * Maps the routing context data sources as questions that can be used
   * with the questions editor. This is because we are reusing the
   * question editors for simplicity, but the API sucks in non-Wortal.
   */
  @computed
  get routingContextDataSourcesAsQuestions() {
    const sources = this.getComputeResult().routingContextDataSources ?? []
    return sources.map((dataSource) => {
      switch (dataSource.type) {
        case 'UserWorkRequestCount': {
          return {
            id: dataSource.id,
            type: 'NUMERIC',
            question: `${dataSource.id} (User Work Request Count)`,
            format: { type: 'Integer' },
          }
        }
        default: {
          return {
            id: dataSource.id,
            type: 'BREAKER',
            question: 'Unknown data source type',
          }
        }
      }
    })
  }

  /**
   * Replaces the routing data source inputs with the specified value.
   */
  @action.bound
  setRoutingContextDataSourceInputs(value) {
    this.routingContextDataSourceInputs = value
  }

  /**
   * Clears the routing data source inputs.
   */
  @action.bound
  clearRoutingContextDataSourceInputs() {
    this.setRoutingContextDataSourceInputs([])
  }

  /**
   * Activates the inputs state.
   */
  @action.bound
  activate() {
    const stored =
      this.legendEditor.storage.getItem(this._mapDataSourceInputsKey()) || {}
    this.routingContextDataSourceInputs =
      stored.routingContextDataSourceInputs || []
  }

  /**
   * Maps the storage key.
   *
   * @returns {string}
   * @private
   */
  _mapDataSourceInputsKey() {
    return `${DATA_SOURCE_INPUTS_KEY}.${this.legendEditor.legendVersion.id}`
  }
}
