import { Store } from 'libx'
import { action, computed, observable } from 'mobx'
import { task } from 'mobx-task'
import { extractMessageFromError } from 'utils/errorUtil'
import { matchAll } from 'utils/searchUtil'
import { sortArray } from 'utils/sortUtil'

export const skillsTableColumns = [
  {
    key: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    key: 'description',
    label: 'Description',
    sortable: true,
  },
  {
    key: 'tags',
    label: 'Tags',
    sortable: false,
  },
]

export default class AddSkillsStore extends Store {
  constructor() {
    super(...arguments)
    this.handleChange = this.handleChange.bind(this)
  }

  @observable
  showing = false

  @observable
  searchText = ''

  // true means desc, false means asc
  @observable
  sortOrder = 'none'

  @observable
  sortField = skillsTableColumns[0].key

  @observable
  memberIds = []

  @observable
  selectedElements = []

  @task
  async activate() {
    return this.rootStore.skillStore.find()
  }

  @task.resolved
  async handleChange(skill, checked) {
    let doneCount = 1

    const formatMessage = () =>
      `${checked ? 'Adding' : 'Removing'} skill, ${doneCount} of ${
        this.memberIds.length
      }...`

    const msg = this.rootStore.flashMessageStore.create({
      inProgress: true,
      message: formatMessage(),
    })

    if (checked) {
      this.selectedElements.push(skill)
    } else {
      this.selectedElements = this.selectedElements.filter(
        (x) => x.id !== skill.id
      )
    }
    for (const memberId of this.memberIds) {
      if (checked) {
        await this.rootStore.memberStore
          .addSkillByPublicId(
            {
              workspace_id: this.rootStore.sessionStore.workspace.id,
              user_id: memberId,
            },
            skill.id
          )
          .catch((err) => {
            this.rootStore.flashMessageStore
              .create(extractMessageFromError(err))
              .failed()
          })
      } else {
        await this.rootStore.memberStore
          .removeSkillByPublicId(
            {
              workspace_id: this.rootStore.sessionStore.workspace.id,
              user_id: memberId,
            },
            skill.id
          )
          .catch((err) => {
            this.rootStore.flashMessageStore
              .create(extractMessageFromError(err))
              .failed()
          })
      }
      doneCount++
      msg.set({ message: formatMessage() })
    }

    msg.done(`Completed.`).autoDismiss()
  }

  @computed
  get filteredElements() {
    const data = this.rootStore.skillStore
      .forWorkspace(this.rootStore.sessionStore.workspace.id)
      .slice()

    if (this.searchText === '') {
      return this.sortOrder === 'none'
        ? data
        : sortArray(data, this.sortField, this.sortOrder === 'asc')
    }

    const filtered = matchAll(
      data,
      this.searchText,
      (entry, search) =>
        entry.name.toLowerCase().includes(search.toLowerCase()) ||
        entry.description.toLowerCase().includes(search.toLowerCase()) ||
        entry.tags
          .map((t) => t.label.toLowerCase())
          .join(' ')
          .includes(search.toLowerCase())
    )

    return this.sortOrder === 'none'
      ? filtered
      : sortArray(filtered, this.sortField, this.sortOrder === 'asc')
  }

  @action.bound
  sort(field, order) {
    this.sortField = field
    this.sortOrder = order
  }

  @action.bound
  setSearchText(val) {
    this.searchText = val
  }

  @action.bound
  hide() {
    this.showing = false
    this.memberIds = []
    this.selectedElements = []
  }

  @action.bound
  showForUsers(memberIds) {
    this.showing = true
    this.memberIds = memberIds
    this.selectedElements = []
  }
}
