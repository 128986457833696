import { action, observable } from 'mobx'

export default class RoomsConfigViewModel {
  @observable enabled = false

  @action
  activate(roomsConfig) {
    this.enabled = roomsConfig.enabled || false
  }

  toJSON() {
    return {
      enabled: this.enabled,
    }
  }
}
