import uniqueId from 'lodash/uniqueId'
import { observable, computed, action } from 'mobx'
import { Model } from 'libx'
import { validatable } from 'legend-builder/mixins/validation'
import { makeDuplicateIdValidation } from 'legend-builder/mixins/ids'
import FiltersBuilder from 'legend-builder/filters/FiltersBuilder'
import { serialize, serializable } from 'legend-builder/data/serialization'
import QuestionsBuilder from './QuestionsBuilder'
import EffectEditor from '../effects/EffectEditor'
import { validateObject } from 'utils/validx-validators'
import { receiveErrors, addGenericErrorForProperty } from '../data/serverErrors'
import { asapScheduler, Subject } from 'rxjs'
import { observeOn, share } from 'rxjs/operators'

const { idRules, installHook } = makeDuplicateIdValidation(
  (v) => v.legendEditor.allEntities
)

const baseRules = {
  id: idRules,
  triggerWhen: [validateObject('There are invalid conditions.')],
}

/**
 * Base class for all question editors.
 * Gets the legend editor injected.
 */
@validatable(
  {
    // Validate fields as they change, except `id` because we use an interceptor to
    // validate and update it.
    liveValidation: true,
    liveIgnore: ['id'],
  },
  baseRules
)
export default class QuestionEditor extends Model {
  /**
   * Client-only ID.
   */
  cid = uniqueId('question-')

  /**
   * The last known ID.
   */
  lastKnownId = null

  /**
   * Data scope.
   */
  scope = []

  /**
   * Controls the collapsed state.
   */
  @observable
  collapsed = false

  /**
   * The builder that contains this question.
   */
  @observable
  parentQuestionsBuilder = null

  /**
   * Question ID.
   */
  @observable
  @serializable
  id = ''

  /**
   * Conditions
   */
  @serializable
  triggerWhen

  /**
   * Type.
   */
  @serializable
  type

  /**
   * Backing subject.
   */
  idFieldFocusRequestSubject = new Subject()

  /**
   * Used by the UI to focus the input field of the ID when asked to.
   */
  idFieldFocusRequest$ = this.idFieldFocusRequestSubject.pipe(
    observeOn(asapScheduler),
    share()
  )

  /**
   * Constructor for the Question Editor base.
   *
   * @param {*} attrs
   * @param {*} opts
   */
  constructor(attrs, opts) {
    super()
    this.legendEditor = opts.legendEditor
    this.parentQuestionsBuilder = opts.parentQuestionsBuilder
    this.scope = opts.scope || []
    this.remove = this.remove.bind(this)
    this.duplicate = this.duplicate.bind(this)
    this.triggerWhen = this.createFiltersBuilder()
    this.set(attrs, opts)
    this.idFieldFocusRequest$.subscribe()
    installHook(this)
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Default answer options.
   */
  @computed
  get answerOptions() {
    return false // does not support picking an answer
  }

  /**
   * Determines if this question is a child of the specified question.
   */
  isChildOf(question) {
    let parentQuestion = this
    do {
      if (parentQuestion === question) {
        return true
      }

      parentQuestion = parentQuestion.parentQuestionsBuilder.parentQuestion
    } while (parentQuestion)
    return false
  }

  /**
   * Parses a plain object to data.
   */
  parse({ id, triggerWhen = [], ...attrs }) {
    // Each condition gets the condition builder for the question injected.
    // The builder itself references the question, so this way the conditions
    // can query the question props as well as the legend editor.
    // This is used for determining what questions are available for certain conditions.
    this.triggerWhen.hydrate(triggerWhen)
    return {
      id,
      ...attrs,
      ...(id && { lastKnownId: id }),
    }
  }

  /**
   * Creates a new QuestionsBuilder, passing in the parent builder and the legend editor.
   */
  createSubQuestionsBuilder() {
    return new QuestionsBuilder({
      legendEditor: this.legendEditor,
      parentQuestion: this,
      scope: this.scope,
    })
  }

  /**
   * Creates a filters builder suitable for questions.
   */
  createFiltersBuilder(opts) {
    return new FiltersBuilder({
      // These are used for building conditions and mutations.
      // They read data from the question itself (to prevent having to manage multiple copies)
      // therefore it is injected.
      legendEditor: this.legendEditor,
      scope: this.scope,
      context: this,
      ...opts,
    })
  }

  /**
   * Create an effect editor.
   */
  createEffect() {
    return EffectEditor.create(null, {
      legendEditor: this.legendEditor,
      context: this,
    })
  }

  /**
   * Serializes the question state to a plain JS object.
   */
  toJSON() {
    return serialize(this)
  }

  /**
   * Removes this question.
   */
  remove() {
    this.parentQuestionsBuilder.remove(this)
  }

  /**
   * Duplicates this question.
   */
  duplicate() {
    this.parentQuestionsBuilder.duplicate(this)
  }

  /**
   * Focus the ID field of the property editor.
   */
  @action.bound
  focusIdField() {
    this.idFieldFocusRequestSubject.next()
  }

  /**
   * Collapse/Expand
   */
  @action
  toggleCollapsed(collapsed) {
    if (!this.constructor.collapsible) {
      return
    }
    this.collapsed = collapsed === undefined ? !this.collapsed : collapsed
  }

  /**
   * Receives errors and populates the validation context with them.
   * @param {*} descriptor
   */
  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor.inner && descriptor.inner.triggerWhen,
      'triggerWhen',
      'There are invalid conditions.'
    )
    receiveErrors(this, descriptor.inner)
  }
}

/**
 * Creates a question editor of the correct type.
 */
QuestionEditor.create = function create(attrs, opts) {
  const Type = require(`legend-builder/questions/types/${attrs.type}/${attrs.type}QuestionEditor`)
    .default
  return new Type(attrs, opts)
}

/**
 * Converts a JS object to the correct question class.
 */
QuestionEditor.fromJS = function fromJS(attrs, opts) {
  return QuestionEditor.create(attrs, {
    parse: true,
    stripUndefined: true,
    ...opts,
  })
}
