import { action, computed, observable } from 'mobx'
import FiltersBuilder from '../filters/FiltersBuilder'
import * as FilterScope from '../consts/FilterScope'
import { validatable } from '../mixins/validation'
import { validateObject } from '../../utils/validx-validators'
import { snapshotable } from '../data/serialization'
import { addGenericErrorForProperty, receiveErrors } from '../data/serverErrors'
import { RoutingPlansBuilder } from './RoutingPlansBuilder'
import { filter } from 'rxjs/operators'

@snapshotable
@validatable({ liveValidation: true })
export default class RoutingEditor {
  /**
   * The selected editor, used to render the inspector.
   * Can be either `requirements` or one of the routing plans.
   */
  @observable selectedEditor = null

  rules = {
    requirements: [validateObject('There are invalid filters')],
    plans: [validateObject('There are invalid routing plans')],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.requirements = new FiltersBuilder({
      legendEditor,
      scope: FilterScope.ALL,
      context: this,
    })
    this.plans = new RoutingPlansBuilder({
      legendEditor,
      routingEditor: this,
      scope: FilterScope.ALL,
      context: this,
    })
    // Select the requirements as the current editor on init.
    this.edit(this.requirements)

    // When any of the editors are removed, clear the inspector
    // if the removed element was being inspected.
    this.plans.remove$
      .pipe(filter((removed) => removed === this.selectedEditor))
      .subscribe(() => this.edit(null))
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Routing plans are entities.
   */
  @computed
  get entityDescriptors() {
    return this.plans.plans.map((p) => ({ type: 'RoutingPlan', entity: p }))
  }

  /**
   * Hydrates the state.
   *
   * @param routing
   */
  @action
  hydrate(routing) {
    routing = routing || {
      requirements: [],
      plans: [],
    }

    this.requirements.hydrate(routing.requirements)
    this.plans.hydrate(routing.plans)
  }

  /**
   * Serializes to a plain object.
   */
  toJSON() {
    return {
      requirements: this.requirements.toJSON(),
      plans: this.plans.toJSON(),
    }
  }

  /**
   * Starts editing.
   *
   * @param editor
   */
  @action.bound
  edit(editor) {
    this.selectedEditor = editor
  }

  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor.inner?.requirements,
      'requirements',
      'There are invalid filters'
    )
    addGenericErrorForProperty(
      this,
      descriptor.inner?.plans,
      'plans',
      'There are invalid routing plans'
    )
    receiveErrors(this, descriptor.inner)
  }
}
