import { action, computed, observable, reaction } from 'mobx'

const RESPONSES_KEY = 'previewer.intakeResponses'

/**
 * Question inputs part of the previewer.
 */
export default class QuestionInputs {
  /**
   * Intake responses.
   */
  @observable intakeResponses = []

  /**
   * Constructor
   * @param legendEditor The Legend Editor.
   * @param getComputeResult Reactive getter for the compute result.
   */
  constructor(legendEditor, getComputeResult) {
    this.legendEditor = legendEditor
    this.getComputeResult = getComputeResult
    // Whenever the intake changes, write them to storage.
    reaction(
      () => this.intakeResponses.slice(),
      (responses) =>
        this.legendEditor.storage.setItem(this._makeResponsesKey(), responses),
      { delay: 500 }
    )
  }

  /**
   * Questions.
   */
  @computed
  get questions() {
    return this.getComputeResult().questions || []
  }

  /**
   * Replaces the intake with the specified value
   */
  @action.bound
  setIntake(value) {
    this.intakeResponses = value
  }

  /**
   * Activates the question inputs editor.
   */
  @action.bound
  activate() {
    this.intakeResponses =
      this.legendEditor.storage.getItem(this._makeResponsesKey()) || []
  }

  /**
   * Clear the answers.
   */
  @action.bound
  clearAnswers() {
    this.intakeResponses = []
  }

  /**
   * Makes a Responses key for local storage.
   */
  _makeResponsesKey() {
    return `${RESPONSES_KEY}.${this.legendEditor.legendVersion.id}`
  }
}
