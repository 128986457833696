import { observable, computed, action } from 'mobx'

/**
 * Job transfer UI state.
 */
class TransferManyJobState {
  @observable
  provider = null

  @observable
  availableTeams = []

  @observable
  teamId = ''

  @observable
  isTransfering = false

  @observable
  showing = false

  @observable
  reason = ''

  @observable
  error = ''

  @observable
  target = 'client'

  constructor(jobsScreenStore, memberStore, teamMemberStore) {
    this.jobsScreenStore = jobsScreenStore
    this.memberStore = memberStore
    this.teamMemberStore = teamMemberStore
    this.init()
  }

  @computed
  get selectedJobs() {
    return this.jobsScreenStore.selectedJobs
  }

  @computed
  get didChangeTarget() {
    if (this.provider === null) return false
    return true
  }

  @action.bound
  init(target) {
    this.target = target || 'client'
    this.provider = null
    this.isTransfering = false
    this.reason = ''
    this.teamId = ''
    this.availableTeams = []
    this.error = ''
  }

  @action.bound
  show(target) {
    this.init(target)
    this.showing = true
  }

  @action.bound
  hideJobTransfer() {
    this.showing = false
  }

  @action.bound
  async selectProvider(user) {
    this.provider = user
    this.teamId = ''
    this.availableTeams = await this.teamMemberStore.fetchUserTeamMembersByPublicId(
      {
        workspace_id: user.workspaceId,
        user_id: user.userPublicId,
      }
    )
  }

  @action.bound
  async transfer() {
    this.error = ''
    if (!this.provider) return
    this.isTransfering = true
    try {
      for (const jobId of this.selectedJobs) {
        if (this.target === 'client') {
          await this.jobsScreenStore.rootStore.jobStore.transferJobToClient(
            jobId,
            this.provider,
            this.reason,
            this.teamId
          )
        } else {
          await this.jobsScreenStore.rootStore.jobStore.transferJobToPro(
            jobId,
            this.provider,
            this.reason
          )
        }
      }

      this.jobsScreenStore.selectedJobs.replace([])

      this.hideJobTransfer()
    } catch (err) {
      this.error = err.message
      this.isTransfering = false
    }
  }
}

export default TransferManyJobState
