import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import link from 'misc/link'
import Icon from 'components/Icon'
import Link from 'components/Link'
import IconButton from 'components/IconButton'
import { Dropdown, DropdownItem, DropdownItemIcon } from 'components/Dropdown'
import Avatar from 'components/Avatar'
import NotificationsViewToggler from 'notifications/components/NotificationsViewToggler'
import './AppHeader.sass'

/**
 * Application header.
 */
@observer
class AppHeader extends React.Component {
  render() {
    const { store } = this.props
    const session = store.sessionStore
    const authStore = store.authStore
    const sidebarStore = store.sidebarStore
    const workspaceSelectorStore = store.workspaceSelectorStore
    const logo = session.workspace.branding.logos.primary_white
    const [firstName, lastName] = session.member.displayName.split(' ')
    const notificationsViewStore = store.multiWorkspaceNotificationStore.forWorkspace(
      session.workspace.id
    )

    return (
      <header className="app-header">
        <nav className="navigation">
          <div className="sidebar-toggler">
            <IconButton
              className="icon-button"
              onClick={() => sidebarStore.toggle()}
            >
              <Icon color="#fff" material name="menu" />
            </IconButton>
          </div>

          <Link className="branding" to={link.welcome(session.workspace.slug)}>
            <img className="logo" src={logo} alt={session.workspace.name} />
          </Link>

          <div className="filler" />
          {session.member && (
            <>
              <NotificationsViewToggler
                className="navbar-notification"
                store={notificationsViewStore}
              >
                <a
                  href="#"
                  onClick={(e) =>
                    e.preventDefault() || notificationsViewStore.toggle()
                  }
                  className={cx(
                    'action button is-icon-only is-white notifications-button',
                    notificationsViewStore.showing === false && 'is-faded'
                  )}
                >
                  <Icon
                    material
                    name={
                      notificationsViewStore.unreadCount === 0
                        ? 'notifications none'
                        : 'notifications'
                    }
                  />
                </a>
              </NotificationsViewToggler>
              <div className="user-info">
                <div className="user-avatar">
                  <Avatar size={28} url={session.member.avatar} />
                </div>
                <div className="user-display-name user-display-name-web">
                  {`${firstName} ${lastName}`}
                </div>
                <div className="user-display-name user-display-name-mobile">
                  {firstName}
                </div>
                <div className="user-actions">
                  <Dropdown
                    position="right"
                    button={
                      <a
                        className="action button is-icon-only is-white"
                        data-test="toggle-header-dropdown-app"
                      >
                        <Icon material name="more vert" />
                      </a>
                    }
                  >
                    {session.workspace.config.custom_menu &&
                      session.workspace.config.custom_menu.items
                        .filter((e) => e.target === 'All' || e.target === 'HQ')
                        .map(
                          (e, i) =>
                            (e.audience === '0' ||
                              session.member.roleIds.includes(e.audience)) && (
                              <DropdownItem
                                href={e.url}
                                target="_blank"
                                key={i}
                              >
                                <DropdownItemIcon material name="link" />
                                {e.label}
                              </DropdownItem>
                            )
                        )}
                    <DropdownItem
                      onClick={() => workspaceSelectorStore.showSelector()}
                    >
                      <DropdownItemIcon material name="compare arrows" />
                      Switch Workspace
                    </DropdownItem>

                    <DropdownItem
                      onClick={() => authStore.logout()}
                      data-test="logout"
                    >
                      <DropdownItemIcon material name="power settings new" />
                      Log out
                    </DropdownItem>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
        </nav>
      </header>
    )
  }
}

export default AppHeader
