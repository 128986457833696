import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet-async'
import PageWrapper, { PageTitle } from 'components/PageWrapper'
import Button from 'components/Button'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import IconButton from 'components/IconButton'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import Icon from 'components/Icon'
import TagEditor from './TagEditor'
import ConfirmDialog from 'components/ConfirmDialog'
import ScreenProtector from 'iam/containers/ScreenProtector'
import styles from './SkillTagsScreen.m.sass'

@observer
export default class SkillTagsScreen extends React.Component {
  componentDidMount() {
    this.props.rootStore.skillTagsScreenStore.activate()
  }

  render() {
    const { skillTagsScreenStore, skillTagEditorStore } = this.props.rootStore
    return (
      <ScreenProtector allOf={['ADMIN_SKILLS_TAGS_MANAGE']}>
        <PageWrapper className={cx(styles.root)}>
          <PageTitle center>
            Skill Tags &nbsp;
            <Button
              iconLeft={<Icon material name="add" />}
              type="primary"
              onClick={skillTagsScreenStore.create.bind(skillTagsScreenStore)}
            >
              Create Tag
            </Button>
          </PageTitle>
          <Helmet title="Skill Tags" />

          <List
            items={skillTagsScreenStore.tags}
            scrollProvider={skillTagsScreenStore.scrollProvider}
            onEditClick={skillTagsScreenStore.edit}
            onDeleteClick={skillTagsScreenStore.delete}
          />
          <TagEditor store={skillTagEditorStore} />
          <ConfirmDialog
            state={skillTagsScreenStore.confirmDelete}
            title="Delete tag?"
            danger
          >
            {skillTagsScreenStore.tagToDelete && (
              <p>
                Do you really want to delete the tag{' '}
                <strong>{skillTagsScreenStore.tagToDelete.label}</strong>?
              </p>
            )}
          </ConfirmDialog>
        </PageWrapper>
      </ScreenProtector>
    )
  }
}

const List = observer(
  ({ items, scrollProvider, onEditClick, onDeleteClick }) => {
    return (
      <div className={styles.listContainer}>
        {scrollProvider.fetch.match({
          pending: () => <ContentLoading />,
          rejected: (err) => <ContentError large error={err} />,
          resolved: () => (
            <div className={styles.list}>
              {items.map((item) => (
                <Item
                  key={item.id}
                  item={item}
                  onEditClick={() => onEditClick(item)}
                  onDeleteClick={() => onDeleteClick(item)}
                />
              ))}
              <InfiniteScrollZone scrollProvider={scrollProvider}>
                {({ isDone }) => !isDone && <ContentLoading smaller />}
              </InfiniteScrollZone>
            </div>
          ),
        })}
      </div>
    )
  }
)

const Item = observer(({ item, onEditClick, onDeleteClick }) => {
  return (
    <div className={styles.item}>
      <div className={styles.info}>
        <p className={styles.title}>{item.label}</p>
      </div>
      <div className={styles.actions}>
        <IconButton onClick={onEditClick}>
          <Icon material name="edit" />
        </IconButton>
        &nbsp;
        <IconButton onClick={onDeleteClick}>
          <Icon material name="delete" />
        </IconButton>
      </div>
    </div>
  )
})
