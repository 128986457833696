import QuestionEditor from '../../QuestionEditor'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import { validateObject } from '../../../../utils/validx-validators'
import { addGenericErrorForProperty } from '../../../data/serverErrors'
import { observable, action } from 'mobx'

/**
 * Path question editor.
 */
@snapshotable
export default class PathQuestionEditor extends QuestionEditor {
  static friendlyType = 'Path'
  static type = 'Path'
  static viewOrder = 1
  static collapsible = true
  static isMetaElement = true
  static iconColor = '#4C00DC'

  @serializable
  questions

  @observable
  @serializable
  label = ''

  rules = {
    questions: [validateObject('There are invalid questions.')],
  }

  constructor(attrs, opts) {
    super({}, opts)
    this.questions = this.createSubQuestionsBuilder()
    this.set(attrs, opts)
  }

  /**
   * Adds inventory items to the path
   */
  @action.bound
  addInventoryItems() {
    this.legendEditor.allowedWorkspaceInventoryItems.forEach((i) => {
      const itemFound = this.questions.allQuestions.find(
        (q) => q.inventory_item_id === i.id
      )
      if (!itemFound) {
        const newItemQuestion = this.questions.add('InventoryItem')
        newItemQuestion.changeInventoryItem(i.id)
      }
    })
  }

  /**
   * Used by the QuestionsBuilder to build a complete list of questions.
   */
  getAllSubQuestions() {
    return this.questions.flattenedQuestions
  }

  parse({ questions, ...attrs }, opts) {
    if (questions) {
      this.questions.hydrate(questions)
    }
    return {
      ...super.parse(attrs, opts),
    }
  }

  /**
   * Adds a generic error about questions if present.
   * @param {*} descriptor
   */
  receiveErrors(descriptor) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'questions',
      'There are invalid questions'
    )
    super.receiveErrors(descriptor)
  }

  toJSON() {
    return super.toJSON()
  }
}
