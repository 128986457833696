import { observable, action, computed } from 'mobx'

export default class JobProgressionConfigViewModel {
  @observable
  enabled = false

  @observable
  legends = {}

  @observable
  taxOrLegacyTypeWorkspaceLegends = []

  activate(enabled = false, legends = {}, workspaceLegends = []) {
    this.enabled = enabled
    this.taxOrLegacyTypeWorkspaceLegends = workspaceLegends

    this.legends = this.initializeLegends(legends, workspaceLegends)
  }

  @computed
  get jobProgressionFlows() {
    const flows = this.taxOrLegacyTypeWorkspaceLegends
      .filter((legend) => legend.inactive === false)
      .map((legend) => {
        return Object.create({
          id: legend.id,
          name: legend.name,
          draft: !legend.published,
        })
      })

    return flows
  }

  @action.bound
  initializeLegends(initialLegends, workspaceLegends) {
    const workspaceLegendIds = workspaceLegends
      .filter((legend) => legend.inactive === false)
      .map((legend) => legend.id)
    const legends = Object.assign({}, initialLegends)

    workspaceLegendIds.forEach((id) => {
      if (!Object.hasOwn(legends, id)) {
        legends[id] = ProgressionFlows.None
      }
    })

    return legends
  }

  @action.bound
  toggleJobProgressionEnabled() {
    this.enabled = !this.enabled
  }

  @action.bound
  updateLegendJobProgression(legendId, progressionFlow) {
    if (!Object.hasOwn(this.legends, legendId)) {
      return
    }

    this.legends[legendId] = this.matchProgressionFlow(progressionFlow)
  }

  toJSON() {
    return {
      enabled: this.enabled,
      legends: this.legends,
    }
  }

  matchProgressionFlow(progressionFlow) {
    switch (progressionFlow) {
      case ProgressionFlows.OutsourcingProgression:
        return ProgressionFlows.OutsourcingProgression
      case ProgressionFlows.RetailProgression:
        return ProgressionFlows.RetailProgression
      case ProgressionFlows.Essential:
        return ProgressionFlows.Essential
      default:
        return ProgressionFlows.None
    }
  }
}

export const ProgressionFlows = {
  OutsourcingProgression: 'OUTSOURCING_PROGRESSION',
  RetailProgression: 'RETAIL_PROGRESSION',
  Essential: 'ESSENTIAL',
  None: 'NONE',
}
