import { Store } from 'libx'
import { action, computed, observable } from 'mobx'
import { validatable } from 'legend-builder/mixins/validation'
import TaskEditor from './TaskEditor'
import { snapshotable } from '../data/serialization'
import { addGenericErrorForProperty, receiveErrors } from '../data/serverErrors'

/**
 * Tasks builder.
 */
@snapshotable
@validatable()
export default class TasksBuilder extends Store {
  @observable
  tasks = []

  rules = {
    tasks: [
      () =>
        this.tasks.every((c) => c.validateAll()) || 'There are invalid tasks.',
    ],
  }

  constructor(legendEditor, milestonesBuilder) {
    super({})
    this.legendEditor = legendEditor
    this.milestonesBuilder = milestonesBuilder
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  get selectedTask() {
    return this.milestonesBuilder.selectedTask
  }

  @action
  edit(editor) {
    this.milestonesBuilder.edit(editor)
  }

  @action
  addTask() {
    const task = TaskEditor.create({}, this._taskOpts())
    this.tasks.push(task)
    this.edit(task)
  }

  @action
  removeTask(task) {
    if (this.selectedTask === task) {
      this.edit(null)
    }
    this.tasks.remove(task)
  }

  hydrate(tasks = []) {
    this.tasks.replace(
      tasks.map((attrs) => TaskEditor.fromJS(attrs, this._taskOpts()))
    )
  }

  toJSON() {
    return this.tasks.map((x) => x.toJSON())
  }

  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'tasks',
      'There are invalid tasks'
    )
    receiveErrors(this.tasks, descriptor.inner)
  }

  _taskOpts() {
    return {
      legendEditor: this.legendEditor,
      milestonesBuilder: this.milestonesBuilder,
    }
  }
}
