import axios from 'axios'
import { replaceSegment, replaceSegments } from '../utils/urlUtil'
import { getFeathersEventManager } from '@taxfyle/web-commons/lib/misc/APIClient'

export default class AxiosAPIBase {
  constructor(api) {
    this.api = api
  }

  get client() {
    return axios.create({
      baseURL: this.host.startsWith('/')
        ? `${window.location.origin}${this.host}`
        : this.host,
      headers: {
        ...(this.api.authStore.token && {
          Authorization: `Bearer ${this.api.authStore.token}`,
        }),
      },
    })
  }

  get feathersConnection() {
    return getFeathersEventManager(
      this.host.startsWith('/')
        ? `${window.location.origin}${this.host}`
        : this.host,
      this.api.authStore.token,
      this.feathersConnectionParams || {}
    )
  }

  get service() {
    return this.feathersConnection.app.service(this.path)
  }

  on(event, handler) {
    this.api.authStore.onLogin(() => {
      this.feathersConnection.subscribe(this.path, event, handler)
    })
    return this
  }

  off(event, handler) {
    this.api.authStore.onLogin(() => {
      this.service.off(event, handler)
    })
    return this
  }

  async get(id, query) {
    return this.client
      .get(this.preparePath(id, null, query), { params: query })
      .then((r) => r.data)
  }

  async find(query) {
    return this.client
      .get(this.preparePath(null, null, query), { params: query })
      .then((x) => x.data)
  }

  async create(data, query) {
    return this.client
      .post(this.preparePath(null, data, query), data)
      .then((r) => r.data)
  }

  async update(id, data, query) {
    return this.client
      .put(this.preparePath(id, data, query), data, {
        params: query,
      })
      .then((r) => r.data)
  }

  async patch(id, data, query) {
    return this.client
      .patch(this.preparePath(id, data, query), data, {
        params: query,
      })
      .then((r) => r.data)
  }

  async remove(id, query) {
    return this.client
      .delete(this.preparePath(id, null, query), { params: query })
      .then((r) => r.data)
  }

  preparePath(id, data, query) {
    let path = this.path
    if (id) {
      const old = path
      path = replaceSegment(path, 'id', id)
      if (path === old) {
        path = path + '/' + id
      }
    }
    if (query) {
      path = replaceSegments(path, query)
    }
    if (data) {
      path = replaceSegments(path, data)
    }

    return path
  }
}
