import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import { Form, Input } from 'components/Form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'

@observer
export default class TagEditor extends React.Component {
  save = () => this.props.store.save()
  close = () => this.props.store.close()

  render() {
    const { store } = this.props
    const pending = store.save.pending
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={this.close}
        position="top"
      >
        <Form onSubmit={this.save}>
          <Dialog>
            <DialogTitle>Document Tag</DialogTitle>
            <DialogContent>
              <Input
                disabled={pending}
                label="Label"
                field="label"
                model={store}
              />
            </DialogContent>
            <DialogFooter>
              <DialogButton disabled={pending} onClick={this.close}>
                Cancel
              </DialogButton>
              <DialogButton submit disabled={pending} type="primary">
                Save
              </DialogButton>
            </DialogFooter>
          </Dialog>
        </Form>
      </Modal>
    )
  }
}
