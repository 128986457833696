import { observable, action, computed } from 'mobx'
import { validatable } from 'legend-builder/mixins/validation'
import { tabManager } from 'legend-builder/mixins/tab-manager'

import InfoLineEditor from './InfoLineEditor'
import { serialize, snapshotable } from '../data/serialization'
import { receiveErrors, addGenericErrorForProperty } from '../data/serverErrors'

@snapshotable
@tabManager('GENERAL')
@validatable({ liveValidation: true })
export default class InfoLinesBuilder {
  @observable
  selectedLine = null

  @observable
  lines = []

  rules = {
    lines: [
      () =>
        this.lines.map((l) => l.validateAll()).every(Boolean) ||
        'There are invalid lines.',
    ],
  }

  constructor(legendEditor, context) {
    this.legendEditor = legendEditor
    this.context = context
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  @action.bound
  add() {
    const editor = InfoLineEditor.create({}, this._lineOpts())
    this.lines.push(editor)
    this.edit(editor)
  }

  @action.bound
  edit(editor) {
    if (!editor || this.lines.indexOf(editor) === -1) {
      this.selectedLine = null
      return
    }

    this.selectedLine = editor
  }

  @action.bound
  remove(editor) {
    this.lines.remove(editor)
    if (this.selectedLine === editor) {
      this.edit(null)
    }
  }

  hydrate(lines = []) {
    this.lines.replace(
      lines.map((l) => InfoLineEditor.fromJS(l, this._lineOpts()))
    )
  }

  toJSON() {
    return serialize(this.lines)
  }

  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'lines',
      'There are invalid info lines'
    )
    receiveErrors(this.lines, descriptor.inner)
  }

  _lineOpts() {
    return {
      legendEditor: this.legendEditor,
      context: this.context,
    }
  }
}
