import { observable, action, computed } from 'mobx'
import { required, validationContext } from 'validx'
import { vif } from 'utils/validx-validators'
import env from 'misc/env'

function generateRandomString() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

export default class TermsOfUseConfigViewModel {
  @observable enabled = false
  @observable customEnabled = false
  @observable customSource = null
  @observable contentfulSourceConfig = null
  @observable workspaceId = null
  @observable showOnLogin = false
  @observable hideOnCustomerPortal = false
  @observable hideOnWortal = false

  validation = validationContext(this, {
    contentfulSourceConfig: [
      vif(() => this.hasCustomSource && this.isSourceContentful, [
        ({ value }) =>
          value.validation.reset().validate().isValid ||
          'Contentful Terms of Use source configuration is invalid ',
      ]),
    ],
  })

  constructor(workspaceId) {
    this.workspaceId = workspaceId
  }

  activate(
    enabled = false,
    customSource = null,
    sourceConfigs = [],
    showOnLogin = false,
    hideOnCustomerPortal = false,
    hideOnWortal = false
  ) {
    this.enabled = enabled
    this.customSource = customSource
    this.customEnabled = this.hasCustomSource
    this.showOnLogin = showOnLogin
    this.hideOnCustomerPortal = hideOnCustomerPortal
    this.hideOnWortal = hideOnWortal

    const contentfulConf =
      sourceConfigs.find((s) => s.sourceType === 'contentful') ?? null

    if (contentfulConf) {
      this.contentfulSourceConfig = new ContentfulTermsSourceConfig()
      this.contentfulSourceConfig.activate(
        sourceConfigs.find((s) => s.sourceType === 'contentful')
      )
    }

    if (!this.workspaceId) {
      this.enabled = true
    }
  }

  @computed
  get hasCustomSource() {
    return this.customSource != null
  }

  @computed
  get isSourceContentful() {
    return this.customSource === 'contentful'
  }

  @action.bound
  setCustomSource(source) {
    this.customSource = source
  }

  @action.bound
  toggleCustomSource() {
    // For now we set the custom source to contentful.
    // When we support other sources we can remove
    // the specificity
    if (!this.hasCustomSource) {
      this.customEnabled = true
      this.setContentfulSource()
    } else {
      this.customEnabled = false
      this.customSource = null
    }
  }

  @action.bound
  setContentfulSource() {
    // Make sure we have a contentful terms source config
    if (!this.contentfulSourceConfig) {
      this.contentfulSourceConfig = new ContentfulTermsSourceConfig()
      this.contentfulSourceConfig.generateNewSecret()
    }

    this.customSource = 'contentful'
  }

  toJSON() {
    const sourceConfigs = []

    if (this.contentfulSourceConfig) {
      sourceConfigs.push(this.contentfulSourceConfig.toJSON())
    }

    return {
      url: null,
      enabled: this.enabled,
      custom_source: this.customSource,
      show_on_login: this.showOnLogin,
      source_configs: sourceConfigs,
      hide_on_customer_portal: this.hideOnCustomerPortal,
      hide_on_wortal: this.hideOnWortal,
    }
  }
}

export class ContentfulTermsSourceConfig {
  sourceType = 'contentful'
  @observable secret = null

  validation = validationContext(this, {
    secret: [required()],
  })

  activate(sourceConfig = {}) {
    this.secret = sourceConfig.secret ?? generateRandomString()
  }

  @computed
  get webhookUrl() {
    const url = new URL(
      '/v3/contentful/publish-terms-webhook',
      env.IAM_API_EXTERNAL
    )
    return url.toString()
  }

  @action.bound
  generateNewSecret() {
    this.secret = generateRandomString()
  }

  toJSON() {
    return {
      sourceType: this.sourceType,
      secret: this.secret,
    }
  }
}
