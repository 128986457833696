import { APIClient } from '@taxfyle/web-commons/lib/misc/APIClient'

/**
 * Engine API client.
 */
export class EngineAPI extends APIClient {
  /**
   * Fetch engine symbols.
   */
  async fetchSymbols() {
    return this.client.get('/v3/engine/symbols').then(this._data)
  }
}
