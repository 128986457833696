import { intercept } from 'mobx'
import { stringLength, validId } from 'utils/validx-validators'

/**
 * Creates utilities for preventing duplicate IDs.
 * The object being checked must use `cid`s.
 *
 * @param {Function} collectionGetter
 * Invoked with the object being validated, should
 * return the collection used to check for dupes.
 */
export function makeDuplicateIdValidation(collectionGetter) {
  const COLLECTION = Symbol('collection')

  const idRules = [
    stringLength(3, 64, 'ID must be between {min} and {max} characters'),
    validId({ skipIfEmpty: true }),
    isNotDuplicateId,
  ]

  return {
    idRules,
    installHook,
  }

  /**
   * validx validator that checks for duplicate IDs
   */
  function isNotDuplicateId({ value, obj }) {
    const upped = (value || '').toUpperCase()
    const existing = (obj[COLLECTION] || collectionGetter(obj)).filter((x) => {
      if (x.cid === obj.cid) {
        return false
      }
      return x.id && x.id.toUpperCase() === upped
    })
    if (existing.length > 0 && existing.some((q) => q.cid !== obj.cid)) {
      return 'Duplicate IDs are not allowed.'
    }

    return true
  }

  /**
   * Installs the validate-on-change hook.
   *
   * @param {Object} that Object to install in
   */
  function installHook(that) {
    intercept(that, 'id', validateIdChange.bind(that))
  }

  /**
   * mobx interceptor installed by `installHook`.
   */
  function validateIdChange(change) {
    // Validate just the ID.
    this.validation.clearErrors('id').validate(
      {
        id: change.newValue,
        // Pass in the collection so the duplicate validator has a source to reference IDs against.
        [COLLECTION]: collectionGetter(this),
        // Used to detect dupes.
        cid: this.cid,
      },
      {
        id: idRules,
      }
    )

    if (this.validation.errors.id && this.validation.errors.id.length > 0) {
      return null
    }

    return change
  }
}
