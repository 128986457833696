import React from 'react'
import { observer } from 'mobx-react'
import { ListItem, ListItemSegment } from 'components/ListItem'
import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import Button from 'components/Button'
import ContentLoading from 'components/ContentLoading'
import { Dropdown, DropdownItem, DropdownItemIcon } from 'components/Dropdown'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import styles from './QualifiedProviders.m.sass'
import link from 'misc/link'
import ContentError from '@taxfyle/web-commons/lib/components/ContentError'

@observer
export default class QualifiedProviders extends React.Component {
  render() {
    const store = this.props.store
    return (
      <div className={styles.container}>
        {store.qualifiedProviders.map((p) => (
          <QualifiedProvider store={store} provider={p} key={p.id} />
        ))}

        {store.fetchAllQualifiedProvidersTasks.match({
          pending: () => <ContentLoading small />,
          resolved: () => {
            if (store.qualifiedProviders.length === 0) {
              return (
                <div className={styles.searchButton}>
                  <Button
                    type="primary"
                    onClick={() => store.fetchQualifiedProviders()}
                  >
                    {T('Web.QualifiedProviders.Find', 'Find Pros')}
                  </Button>
                  &nbsp;
                  <Button
                    type="primary"
                    onClick={() => store.fetchAllQualifiedProviders()}
                    data-test="button-find-all-qualified-providers"
                  >
                    {T('Web.QualifiedProviders.FindAll', 'Find All Pros')}
                  </Button>
                </div>
              )
            }
            if (store.qualifiedProviders.length > 0 && store.providersCursor) {
              return (
                <div className={styles.searchButton}>
                  <Button
                    type="primary"
                    onClick={() => store.fetchQualifiedProviders()}
                  >
                    Find More {T('Web.Common.Providers', 'Pros')}
                  </Button>
                </div>
              )
            }
            return (
              <div className={styles.searchButton}>
                <Button onClick={() => store.clearQualifiedPros()}>
                  Clear
                </Button>
              </div>
            )
          },
          rejected: (err) => (
            <ContentError
              // Make sure to trigger the right action on retry.
              onRetryClick={() =>
                store.fetchQualifiedProviders.rejected
                  ? store.fetchQualifiedProviders()
                  : store.fetchAllQualifiedProviders()
              }
              error={err}
            />
          ),
        })}
      </div>
    )
  }
}

const QualifiedProvider = observer(({ store, provider }) => {
  const meta = provider.memberMetadata || {}
  const customFields = Object.keys(meta)
    .sort()
    .reduce((result, currentValue) => {
      const value = meta[currentValue]
      const customMetadataFields = store.rootStore.sessionStore.workspace
        .custom_metadata_fields || { member_metadata_fields: [] }
      const field = customMetadataFields.member_metadata_fields.find(
        (x) => x.id === currentValue
      )

      if (!field) {
        return result
      }

      return (
        (result.length === 0 ? result : `${result} | `) +
        `${field?.title}: ${value}`
      )
    }, '')

  return (
    <ListItem border>
      <ListItemSegment pad>
        <Avatar
          size={35}
          url={provider.avatar}
          fallback={provider.avatarFallback}
        />
      </ListItemSegment>
      <ListItemSegment>
        <div className={styles.proTitle}>{provider.displayName}</div>
        {customFields && <div className={styles.proInfo}>{customFields}</div>}
      </ListItemSegment>
      <ListItemSegment stretch />
      <ListItemSegment pad>
        <Dropdown
          position="right"
          button={({ innerRef, ...buttonProps }) => (
            <button
              ref={innerRef}
              {...buttonProps}
              className="button is-icon-only"
              title={`Add ${T('Web.Common.Providers', 'Pros')}`}
              data-test={`qualified-provider-item-dropdown-toggle-${provider.userId}`}
            >
              <Icon material name="more horiz" />
            </button>
          )}
        >
          <DropdownItem
            to={link.user(
              provider.userPublicId,
              store.rootStore.sessionStore.workspace.slug
            )}
          >
            <DropdownItemIcon>
              <Icon material name="person" />
            </DropdownItemIcon>
            View Profile
          </DropdownItem>
          {!store.isJobMenuActionDeprecated && (
            <DropdownItem onClick={() => store.showAddProvider(provider)}>
              <DropdownItemIcon>
                <Icon material name="add" />
              </DropdownItemIcon>
              Add {T('Web.Common.Provider', 'Provider')}
            </DropdownItem>
          )}
        </Dropdown>
      </ListItemSegment>
    </ListItem>
  )
})
