import { observable, computed } from 'mobx'
import { required } from 'validx'
import { serializable, snapshotable } from 'legend-builder/data/serialization'
import QuestionEditor from '../../QuestionEditor'

@snapshotable
export default class BreakerQuestionEditor extends QuestionEditor {
  static friendlyType = 'Breaker'
  static type = 'Breaker'
  static viewOrder = 5
  static iconColor = '#645EFE'

  @observable
  @serializable
  question = 'New Breaker'

  @observable
  @serializable
  description = ''

  rules = {
    question: [required('Title is required')],
  }

  @computed
  get answerOptions() {
    return false
  }
}
