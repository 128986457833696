import Button from 'components/Button'
import { HtmlClass } from 'components/ClassName'
import ContentError from 'components/ContentError'
import ContentLoading from 'components/ContentLoading'
import {
  Dialog,
  DialogButton,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'components/Dialog'
import FileButton from 'components/FileButton'
import { Select, getError, FormGroup, Help } from 'components/Form'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import QuestionGrid from 'jobs/components/QuestionsEditor'
import { observer } from 'mobx-react'
import React from 'react'
import styles from './ScopeWorkModal.m.sass'
import noop from 'lodash/noop'
import UserSelector from 'components/UserSelector'
import { filterOutAllIds } from 'utils/userSelectorFilterUtil'

@observer
export default class ScopeWorkModal extends React.Component {
  renderDialog() {
    const { store } = this.props

    return (
      <div className={styles.scopeWorkCreateDialog}>
        <DialogTitle>
          Scope Work for User: {store.user?.displayName}
        </DialogTitle>
        <DialogContent>
          <FormGroup
            label="Supporting Client"
            help="Would you like to add a supporting client to these jobs?"
          >
            <UserSelector
              allowEmpty
              userFilter={filterOutAllIds([store.user?.userId])}
              workspaceId={store.rootStore.sessionStore.workspace.id}
              memberStore={store.rootStore.memberStore}
              name="new-supporting-client"
              onChange={store.selectSupportingClient}
              value={store.supportingClient}
            />
          </FormGroup>
          {store.availableTeams.length > 0 && (
            <Select
              model={store}
              field="teamId"
              label="Team"
              disabled={store.canChange}
              help="Should these jobs be assigned to a team?"
              className={styles.selectDropdown}
            >
              <option value="">None</option>
              {store.availableTeams.map((t) => (
                <option key={t.id} value={t.team.id}>
                  {t.team.name}
                </option>
              ))}
            </Select>
          )}
          <Select
            label="Legend"
            help="The Legend for the Scoping."
            onChange={(e) => store.selectLegend(e.target.value)}
            disabled={store.canChange}
            value={store.selectedLegendId}
            className={styles.selectDropdown}
          >
            <option key={0} value="">
              None
            </option>
            {store.legends.map((t) => (
              <option key={t.id} value={t.id}>
                {t.name}
              </option>
            ))}
          </Select>
          <Help className={styles.error}>
            {getError(store, 'selectedLegendId')}
          </Help>
          {store.selectedLegendId !== '' && (
            <>
              <Select
                label="Template"
                help="The template for the Scoping."
                onChange={(e) => store.selectTemplate(e.target.value)}
                disabled={store.canChange}
                value={store.selectedTemplateId}
                className={styles.selectDropdown}
              >
                <option key={0} value="">
                  None
                </option>
                {store.templates.map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </Select>
              <Help className={styles.error}>
                {getError(store, 'selectedTemplateId')}
              </Help>
            </>
          )}
          {store.manualQuestions.length > 0 && (
            <FormGroup label="Manual Input Questions">
              <QuestionGrid
                questions={store.manualQuestions}
                answers={store.answers}
                disabled={store.fetchData.pending}
                onChange={(answers) => store.setAnswers(answers)}
                onUpdate={noop}
              />
              <Help className={styles.error}>{getError(store, 'answers')}</Help>
            </FormGroup>
          )}

          <ul className={styles.list}>
            {store.files.map((x, i) => (
              <li key={i}>{x.name}</li>
            ))}
          </ul>
          <FileButton
            onChange={(files) => store.selectFiles(files)}
            multiple
            accept=".pdf"
          >
            <Button
              className="file-button"
              iconLeft={<Icon material small name="file upload" />}
            >
              Upload
            </Button>
          </FileButton>
          <Help className={styles.error}>{getError(store, 'files')}</Help>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide}>Cancel</DialogButton>
          <DialogButton danger onClick={store.submit} disabled={store.busy}>
            Submit
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        className="job-create-modal"
        position="center"
        dismissOnOutsideClick={false}
      >
        <HtmlClass className="disable-scrolling" />
        <Dialog>
          {store.submit.match({
            pending: () => <ContentLoading small />,
            rejected: (err) => <ContentError error={err} />,
            resolved: () => this.renderDialog(),
          })}
        </Dialog>
      </Modal>
    )
  }
}
