import { observable, computed } from 'mobx'
import { validatable } from 'legend-builder/mixins/validation'
import { required, func } from 'validx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { asNumber } from 'utils/numberUtil'
import { stringLength, validateObject } from '../../utils/validx-validators'
import RatingConfigEditor from './RatingConfigEditor'
import TimeConfigEditor from './TimeConfigEditor'
import DeadlineConfigEditor from './DeadlineConfigEditor'
import TitleConfigEditor from './TitleConfigEditor'
import StalenessConfigEditor from './StalenessConfigEditor'
import PurgeConfigEditor from './PurgeConfigEditor'
import { receiveErrors } from '../data/serverErrors'
import PayoutConfigEditor from './PayoutConfigEditor'
import BillingConfigEditor from './BillingConfigEditor'
import DataSourcesEditor from '../data-sources/DataSourcesEditor'
import MultiJobFlowConfigEditor from './MultiJobFlowConfigEditor'
import SpecsConfigEditor from './SpecsConfigEditor'
import PrepAndReviewConfigEditor from './PrepAndReviewConfigEditor'

@snapshotable
@validatable({ liveValidation: true })
export default class LegendOptions {
  @observable
  @serializable
  name = ''

  @observable
  @serializable
  description = ''

  @observable
  @serializable
  image_url = ''

  @observable
  @serializable
  service_type

  @observable
  @serializable
  sendNotificationsOnSubmit = false

  @observable
  @serializable((v) => asNumber(v))
  delayNotification = 0

  @observable
  @serializable((v) => asNumber(v))
  view_order = 0

  @observable
  @serializable
  priceUnit = ''

  @observable
  @serializable
  skipBilling = false

  @observable
  @serializable
  alwaysEnableVoiceCall = false

  @serializable
  ratingConfig

  @serializable
  time_config

  @observable
  @serializable
  targetRoles = []

  @serializable
  deadline_config

  @serializable
  title_config

  @serializable
  specs_config

  @serializable
  staleness

  @serializable
  purge

  @serializable
  payout_config

  @serializable
  billing_config

  @serializable
  multi_job_flow_config

  @serializable
  prep_and_review_config

  @serializable
  data_sources

  @observable
  @serializable
  allowProSelection = false

  @observable
  @serializable
  showAvailabilityCount = false

  rules = {
    name: [required()],
    service_type: [
      func(() => this.service_type != null, 'Service type is required.'),
    ],
    priceUnit: [stringLength(0, 10)],
    time_config: [validateObject('Time configuration is invalid.')],
    deadline_config: [validateObject('Deadline configuration is invalid.')],
    title_config: [validateObject('Title configuration is invalid.')],
    specs_config: [validateObject('Specs configuration is invalid.')],
    staleness: [validateObject('Staleness configuration is invalid.')],
    purge: [validateObject('Purge configuration is invalid.')],
    payout_config: [validateObject('Payout configuration is invalid.')],
    billing_config: [validateObject('Billing configuration is invalid.')],
    multi_job_flow_config: [
      validateObject('Multi-Job Flow configuration is invalid.'),
    ],
    prep_and_review_config: [
      validateObject('Prep and Review configuration is invalid.'),
    ],
    data_sources: [validateObject('Data sources configuration is invalid.')],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.ratingConfig = new RatingConfigEditor(this.legendEditor)
    this.time_config = new TimeConfigEditor(this.legendEditor)
    this.deadline_config = new DeadlineConfigEditor(this.legendEditor)
    this.title_config = new TitleConfigEditor(this.legendEditor)
    this.specs_config = new SpecsConfigEditor(this.legendEditor)
    this.staleness = new StalenessConfigEditor(this.legendEditor)
    this.purge = new PurgeConfigEditor(this.legendEditor)
    this.payout_config = new PayoutConfigEditor(this.legendEditor)
    this.billing_config = new BillingConfigEditor(this.legendEditor)
    this.data_sources = new DataSourcesEditor(this.legendEditor)
    this.multi_job_flow_config = new MultiJobFlowConfigEditor(this.legendEditor)
    this.prep_and_review_config = new PrepAndReviewConfigEditor(
      this.legendEditor
    )
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  @computed
  get availableRoles() {
    return this.legendEditor.rootStore.roleStore
      .forWorkspace(this.legendEditor.rootStore.sessionStore.workspace.id)
      .map((r) => [r.id, r.name])
  }

  @computed
  get isLegacyOrNewLegend() {
    return (
      this.legendEditor.versionManager.latestPublishedVersion?.service_type ==
      null
    )
  }

  @computed
  get serviceTypes() {
    const serviceTypes = [
      ['CONSULTATION', 'Consultation'],
      ['TAX', 'Tax'],
      ['BOOKKEEPING', 'Bookkeeping'],
      ['MISC', 'Misc'],
    ]
    return serviceTypes.sort((a, b) => a[1].localeCompare(b[1]))
  }

  hydrate({
    ratingConfig,
    deadline_config,
    title_config,
    specs_config,
    time_config,
    staleness,
    purge,
    payout_config,
    billing_config,
    multi_job_flow_config,
    prep_and_review_config,
    data_sources,
    service_type,
    ...legend
  } = {}) {
    this.ratingConfig.hydrate(ratingConfig)
    this.deadline_config.hydrate(deadline_config)
    this.title_config.hydrate(title_config)
    this.specs_config.hydrate(specs_config)
    this.time_config.hydrate(time_config)
    this.staleness.hydrate(staleness)
    this.purge.hydrate(purge)
    this.payout_config.hydrate(payout_config)
    this.billing_config.hydrate(billing_config)
    this.data_sources.hydrate(data_sources)
    this.multi_job_flow_config.hydrate(multi_job_flow_config)
    this.prep_and_review_config.hydrate(prep_and_review_config)
    this.service_type = service_type

    Object.assign(this, legend)
  }

  toJSON() {
    return {
      ...serialize(this),
      excludeLegacyYears: true,
      excludeLegacyStates: true,
    }
  }

  receiveErrors(descriptor) {
    receiveErrors(this, descriptor?.inner)
  }
}
