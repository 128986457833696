import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet-async'
import PageWrapper, { PageTitle } from 'components/PageWrapper'
import Button from 'components/Button'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import IconButton from 'components/IconButton'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import Icon from 'components/Icon'
import TagEditor from './TagEditor'
import ConfirmDialog from 'components/ConfirmDialog'
import ScreenProtector from 'iam/containers/ScreenProtector'
import styles from './DocumentTagsScreen.m.sass'
import CsvExport from '../Jobs/FacetedSearch/CsvExport'
import FileButton from 'components/FileButton'

@observer
export default class DocumentTagsScreen extends React.Component {
  componentDidMount() {
    this.props.rootStore.documentTagsScreenStore.activate()
  }

  render() {
    const {
      documentTagsScreenStore,
      documentTagEditorStore,
    } = this.props.rootStore
    return (
      <ScreenProtector allOf={['ADMIN_DOCUMENT_TAGS_MANAGE']}>
        <PageWrapper className={cx(styles.root)}>
          <PageTitle center>
            Document Tags &nbsp;
            <Button
              iconLeft={<Icon material name="add" />}
              type="primary"
              onClick={documentTagsScreenStore.create.bind(
                documentTagsScreenStore
              )}
            >
              Create Tag
            </Button>
            <CsvExport
              asyncExportMethod={documentTagsScreenStore.loadAllData}
              style={{ marginBottom: '0px' }}
            >
              <Button
                iconLeft={<Icon material name="cloud_download" />}
                type="primary"
              >
                Export
              </Button>
            </CsvExport>
            &nbsp;
            <FileButton
              className={styles.button}
              onChange={documentTagsScreenStore.onChangeFile}
              multiple={false}
              accept=".csv"
            >
              <Button
                iconLeft={<Icon material name="cloud_upload" />}
                type="primary"
              >
                Import
              </Button>
            </FileButton>
          </PageTitle>
          <Helmet title="Document Tags" />

          <List
            items={documentTagsScreenStore.tags}
            scrollProvider={documentTagsScreenStore.scrollProvider}
            onEditClick={documentTagsScreenStore.edit}
            onDeleteClick={documentTagsScreenStore.delete}
          />
          <TagEditor store={documentTagEditorStore} />
          <ConfirmDialog
            state={documentTagsScreenStore.confirmDelete}
            title="Delete tag?"
            danger
          >
            {documentTagsScreenStore.tagToDelete && (
              <p>
                Do you really want to delete the tag{' '}
                <strong>{documentTagsScreenStore.tagToDelete.label}</strong>?
              </p>
            )}
          </ConfirmDialog>
        </PageWrapper>
      </ScreenProtector>
    )
  }
}

const List = observer(
  ({ items, scrollProvider, onEditClick, onDeleteClick }) => {
    return (
      <div className={styles.listContainer}>
        {scrollProvider.fetch.match({
          pending: () => <ContentLoading />,
          rejected: (err) => <ContentError large error={err} />,
          resolved: () => (
            <div className={styles.list}>
              {items.map((item) => (
                <Item
                  key={item.id}
                  item={item}
                  onEditClick={() => onEditClick(item)}
                  onDeleteClick={() => onDeleteClick(item)}
                />
              ))}
              <InfiniteScrollZone scrollProvider={scrollProvider}>
                {({ isDone }) => !isDone && <ContentLoading smaller />}
              </InfiniteScrollZone>
            </div>
          ),
        })}
      </div>
    )
  }
)

const Item = observer(({ item, onEditClick, onDeleteClick }) => {
  return (
    <div className={styles.item}>
      <div className={styles.info}>
        <p className={styles.title}>{item.label}</p>
      </div>
      <div className={styles.actions}>
        <IconButton onClick={onEditClick}>
          <Icon material name="edit" />
        </IconButton>
        &nbsp;
        <IconButton onClick={onDeleteClick}>
          <Icon material name="delete" />
        </IconButton>
      </div>
    </div>
  )
})
