import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ErrorLabel from 'components/ErrorLabel'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'

import { Select } from 'components/Form'
import styles from './AssignJobToTeamDialog.m.sass'

@observer
export default class AssignJobToTeamDialog extends React.Component {
  renderDialog() {
    const { store } = this.props
    if (!store.job) return null

    return (
      <div className={styles.assignJobToTeamDialog}>
        <DialogTitle>Assign Job to Team or Remove Team</DialogTitle>
        <DialogContent>
          <Select
            model={store}
            field="teamId"
            label="Team"
            help="To which team should this be assigned to?"
          >
            <option key={0} value="">
              None
            </option>
            {store.availableTeams.map((t) => (
              <option key={t.id} value={t.team.id}>
                {t.team.name}
              </option>
            ))}
          </Select>
          {store.job.paidAmount !== store.job.total && (
            <div className={styles.warning}>
              Make sure that the Client and/or Team you are transferring to has
              a valid billing method!
            </div>
          )}
          <ErrorLabel>{store.error}</ErrorLabel>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide}>Close</DialogButton>
          <DialogButton primary onClick={store.assignJobToTeam.bind(store)}>
            Assign
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        className="invoice-modal"
        position="top"
      >
        <Dialog>
          {store.assignJobToTeam.match({
            pending: () => <ContentLoading small />,
            rejected: (err) => <ContentError error={err} />,
            resolved: () => this.renderDialog(),
          })}
        </Dialog>
      </Modal>
    )
  }
}
