import { ConfirmDialogState } from 'components/ConfirmDialog'
import { Store } from 'libx'
import { observable, computed, action } from 'mobx'
import debounce from 'lodash/debounce'
import InfiniteScrollProvider from 'data/InfiniteScrollProvider'
import InviteUserModalState from '../../screens/UserSearch/InviteUserModal/InviteUserModalState'
import CreateUserModalState from '../../screens/UserSearch/CreateUserModal/CreateUserModalState'

/**
 * State for the user search screen.
 */
class UserSearchStore extends Store {
  @observable
  searchText = ''

  @observable
  users = []

  @observable
  showInactive = false

  @observable
  sortField = null

  @observable
  sortOrder = null

  @observable
  userIds = ''

  @observable
  selectedUsers = []

  @observable
  showingAddUserBox = false

  @observable
  facets = new Map()

  @observable
  selectedFacets = new Map()

  @observable
  showFilter = false

  /**
   * Constructor.
   *
   * @param {string} type
   * What type of users are we looking for? Can be one of `UserType`.
   */
  constructor() {
    super(...arguments)
    this.deleteUsersConfirm = new ConfirmDialogState()
    this.deactivateUsersConfirm = new ConfirmDialogState()
    this.inviteUserModalState = new InviteUserModalState({
      roleStore: this.rootStore.roleStore,
      skillStore: this.rootStore.skillStore,
      teamStore: this.rootStore.teamStore,
      teamRoleStore: this.rootStore.teamRoleStore,
      invitationStore: this.rootStore.invitationStore,
      flashMessageStore: this.rootStore.flashMessageStore,
      sessionStore: this.rootStore.sessionStore,
    })
    this.createUserModalState = new CreateUserModalState({
      roleStore: this.rootStore.roleStore,
      skillStore: this.rootStore.skillStore,
      teamStore: this.rootStore.teamStore,
      teamRoleStore: this.rootStore.teamRoleStore,
      memberStore: this.rootStore.memberStore,
      flashMessageStore: this.rootStore.flashMessageStore,
      sessionStore: this.rootStore.sessionStore,
    })
    this.debouncedFetchUsers = debounce(this.findUsers, 200)
    this.scrollProvider = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: 50,
      fetchItems: this._loadUsers.bind(this),
    })
    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.scrollProvider.reset()
      this.users.replace([])
      this.searchText = ''
    })
  }

  /**
   * Show we show that we have no users?
   */
  @computed
  get showNoUsers() {
    return this.users.length === 0 && !this.scrollProvider.fetch.pending
  }

  /**
   * Called when the view is being shown.
   */
  async activate() {
    this.deleteUsersConfirm.hide()
    this.deactivateUsersConfirm.hide()
    if (this.users.length > 0) {
      return
    }

    if (this.rootStore.sessionStore.member.hasPermission('ADMIN_ROLE_LIST')) {
      await this.rootStore.roleStore.find()

      const f = {
        Roles: {
          buckets: this.rootStore.roleStore
            .forWorkspace(this.rootStore.sessionStore.workspace.id)
            .map((r) => {
              return {
                key: r.name,
              }
            }),
        },
      }

      this.facets.replace(f)
    }

    this.findUsers()
  }

  @action.bound
  showSkillsEditor() {
    this.rootStore.addSkillsStore.showForUsers(this.selectedUsers)
  }

  @action.bound
  showAddRolesDialog() {
    this.rootStore.addRolesStore.showForUsers(this.selectedUsers)
  }

  @action.bound
  toggleShowInactive() {
    this.showInactive = !this.showInactive
    this.findUsers()
  }

  @action.bound
  changeSort(field, order) {
    this.sortField = field
    this.sortOrder = order
    this.findUsers()
  }

  @action.bound
  async handleSelection(id) {
    if (this.selectedUsers.indexOf(id) > -1) {
      this.selectedUsers.remove(id)
    } else {
      this.selectedUsers.push(id)
    }
  }

  @action.bound
  async deactivateSelected() {
    if (!(await this.deactivateUsersConfirm.show())) {
      return
    }

    const msg = this.rootStore.flashMessageStore.create({
      message: 'Deactivating user(s)...',
      inProgress: true,
    })
    return Promise.all(
      this.selectedUsers.map(async (userPublicId) => {
        const member = this.rootStore.memberStore.forWorkspaceAndUserPublicId(
          this.rootStore.sessionStore.workspace.id,
          userPublicId
        )
        if (!member) {
          return
        }
        for (const skill of member.skills) {
          await this.rootStore.memberStore.removeSkillByPublicId(
            {
              workspace_id: this.rootStore.sessionStore.workspace.id,
              user_id: userPublicId,
            },
            skill.id
          )
        }
        for (const role of member.roles) {
          await this.rootStore.memberStore.removeRoleByPublicId(
            {
              workspace_id: this.rootStore.sessionStore.workspace.id,
              user_id: userPublicId,
            },
            role.id
          )
        }
      })
    )
      .then(async () => {
        msg.done('User(s) deactivated.').autoDismiss()
      })
      .catch((err) => {
        msg.failed(err.message).autoDismiss()
        throw err
      })
  }

  @action.bound
  async deleteSelected() {
    if (!(await this.deleteUsersConfirm.show())) {
      return
    }

    const msg = this.rootStore.flashMessageStore.create({
      message: 'Deleting user(s)...',
      inProgress: true,
    })
    return Promise.all(
      this.selectedUsers.map((userId) =>
        this.rootStore.memberStore.updateByPublicId(
          {
            user_id: userId,
            workspace_id: this.rootStore.sessionStore.workspace.id,
          },
          { inactive: true }
        )
      )
    )
      .then(async () => {
        this.findUsers()
        msg.done('User(s) deleted.').autoDismiss()
      })
      .catch((err) => {
        msg.failed(err.message).autoDismiss()
        throw err
      })
  }

  @action.bound
  async toggleFilter() {
    this.showFilter = !this.showFilter
  }

  @action.bound
  selectFacet(key, value) {
    const currentSelection = this.selectedFacets.get(key)
    if (currentSelection && currentSelection === value) {
      this.selectedFacets.delete(key)
    } else {
      this.selectedFacets.set(key, value)
    }
    this.findUsers()
  }

  @action.bound
  async activateSelected() {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Adding user(s)...',
      inProgress: true,
    })
    return Promise.all(
      this.selectedUsers.map((userPublicId) =>
        this.rootStore.memberStore.updateByPublicId(
          {
            user_id: userPublicId,
            workspace_id: this.rootStore.sessionStore.workspace.id,
          },
          { inactive: false }
        )
      )
    )
      .then(async () => {
        this.findUsers()
        msg.done('User(s) added.').autoDismiss()
      })
      .catch((err) => {
        msg.failed(err.message).autoDismiss()
        throw err
      })
  }

  @action.bound
  changeSearchText(newSearchText) {
    this.searchText = newSearchText
    this.scrollProvider.reset()
    this.debouncedFetchUsers()
  }

  @action.bound
  changeAddUsersText(newUsersText) {
    this.userIds = newUsersText.target.value
  }

  /**
   * Loads the first users.
   */
  @action
  async findUsers() {
    this.scrollProvider.reset()
    this.users.replace([])
    this.selectedUsers.replace([])
    await this.scrollProvider.fetch()
  }

  /**
   * Loads more users.
   */
  loadMore() {
    return this.scrollProvider.more()
  }

  /**
   * Actual load call, called by the paging manager.
   *
   * @param  {object} paging
   * @return {object}
   */
  async _loadUsers(paging) {
    const result = await this.rootStore.memberStore.search({
      workspace_id: this.rootStore.sessionStore.workspace.id,
      after: paging.after,
      limit: paging.limit,
      search_text: this.searchText,
      inactive: this.showInactive,
      ...(this.sortField && {
        order_by: `${this.sortField} ${this.sortOrder}`,
      }),
      ...(this.selectedFacets.get('Roles') && {
        has_role_id: this.rootStore.roleStore.roles.find(
          (r) => r.name === this.selectedFacets.get('Roles')
        ).id,
      }),
    })

    const newUsers = result.data.filter((u) => this.users.indexOf(u) === -1)
    this.users.push(...newUsers)
    return result
  }
}

export default UserSearchStore
