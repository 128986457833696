import cx from 'classnames'
import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import ConversationContainer from '@taxfyle/web-commons/lib/messaging/containers/ConversationContainer'
import { CallingConversationContext } from '@taxfyle/web-commons/lib/messaging/calling/CallingConversationViewModel'
import AmendmentsTable from 'components/AmendmentsTable'
import ContentError from 'components/ContentError'
import ContentLoading from 'components/ContentLoading'
import { CardGrid, CardGridItem } from '../../components/CardGrid'
import { TabPills, TabPill } from 'components/TabPills'
import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import PageWrapper from 'components/PageWrapper'
import {
  Widget,
  WidgetContent,
  WidgetHeader,
  WidgetHeaderSegment,
  WidgetTitle,
} from 'components/Widget'
import DocumentSidebar from 'documents/components/DocumentSidebar'
import RequirePerms from 'iam/containers/RequirePerms'
import ScreenProtector from 'iam/containers/ScreenProtector'
import EventCategoryDropdown from 'jobs/components/Events/EventCategoryDropdown'
import JobEventList from 'jobs/components/Events/JobEventList'
import QuestionsEditor from 'jobs/components/QuestionsEditor'
import MilestonesView from 'jobs/views/MilestonesView'
import { MessageComposer } from 'messaging/components/MessageComposer'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Helmet } from 'react-helmet-async'
import InfoLine from './InfoLine'
import JobDetailsHeader from './JobDetailsHeader'
import JobDocuments from './JobDocuments'
import JobSummary from './JobSummary'
import CollaboratorListItem from './CollaboratorListItem'
import QualifiedProviders from './QualifiedProviders'
import UserCard from './UserCard'
import Variables from './Variables'

import CsvExport from '../Jobs/FacetedSearch/CsvExport'
import './JobDetails.sass'
import PromptDialog from '@taxfyle/web-commons/lib/jobs/components/Prompts/PromptDialog'
import SendPromptDialog from '@taxfyle/web-commons/lib/jobs/components/Prompts/creation/SendPromptDialog'
import Prompts from './Prompts'
import { isDisplayDocumentTagsToggleEnabled } from 'screens/DocumentTags/document-tags-toggle'

@observer
export default class JobDetails extends Component {
  get jobDetailsStore() {
    return this.props.rootStore.jobDetailsStore
  }

  componentDidMount() {
    this.activate()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.jobId !== this.props.params.jobId) {
      this.activate()
    }
  }

  activate() {
    this.jobDetailsStore.activate(this.props.params.jobId)
  }

  renderPageHeader() {
    const store = this.jobDetailsStore
    if (!store.pageTitle) return null

    return <JobDetailsHeader store={store} />
  }

  renderDeprecationBanner() {
    const store = this.jobDetailsStore

    return (
      <div
        className={cx(
          'deprecation-banner',
          store.rootStore.sidebarStore.showing && 'deprecation-banner--offset'
        )}
      >
        <p>To perform job actions switch to Work Portal.</p>

        <button onClick={() => store.openInWorkPortal()}>Take Me There</button>
      </div>
    )
  }

  renderUserCards() {
    const store = this.jobDetailsStore
    if (!store.job) return null
    const championClient = store.job.members.find(
      (x) => x.type === 'CLIENT' && x.role === 'CHAMPION'
    )
    const clientTeam = store.job.ownerTeamId
      ? store.rootStore.teamStore.teams.find(
          (x) => x.id === store.job.ownerTeamId
        )
      : null
    const championProvider = store.job.members.find(
      (x) => x.type === 'PROVIDER' && x.role === 'CHAMPION'
    )
    const providerTeam =
      championProvider && championProvider.teamId
        ? store.rootStore.teamStore.teams.find(
            (x) => x.id === championProvider.teamId
          )
        : null
    const canSeeProPi = store.rootStore.sessionStore.member.hasPermission(
      'ADMIN_PRO_PI_VIEW'
    )
    return (
      <div className="columns is-desktop">
        <div className="column">
          <UserCard
            user={championClient.user}
            title={T('Web.Common.Client', 'Client')}
            titleTheme="green-300"
            teamName={clientTeam && clientTeam.name}
            hidePersonalInformation={false}
            data-test="job-details-client-name"
          />
        </div>
        <div className="column">
          {championProvider && (
            <UserCard
              user={championProvider.user}
              title={T('Web.Common.Provider', 'Pro')}
              teamName={providerTeam && providerTeam.name}
              hidePersonalInformation={!canSeeProPi}
              data-test="job-details-provider-name"
            />
          )}
        </div>
      </div>
    )
  }

  renderJobSummary() {
    const store = this.jobDetailsStore
    if (!store.job) return null

    return <JobSummary store={store} />
  }

  renderConversation() {
    const store = this.jobDetailsStore

    return (
      <Widget>
        <WidgetHeader>
          <WidgetTitle>Conversation</WidgetTitle>
          <WidgetHeaderSegment>
            <button
              className="button with-margin-right is-icon-only"
              title="Download Conversation"
              onClick={() => store.downloadConversation()}
            >
              <Icon material name="download" />
            </button>
            <button
              className="button is-icon-only"
              title="Refresh Conversation"
              onClick={() => store.refreshConversation()}
            >
              <Icon material name="refresh" />
            </button>
          </WidgetHeaderSegment>
        </WidgetHeader>
        <CallingConversationContext.Provider
          value={this.props.rootStore.callingConversationViewModel}
        >
          <WidgetContent loading={store.activate.pending}>
            {store.activate.resolved &&
            store.job.conversationId &&
            store.messages.length > 0 ? (
              /* "me" is the Client */
              <ConversationContainer
                messages={store.messages}
                unreadMessagesCount={0}
                onDelete={store.deleteMessage}
                onMarkAsRead={(message) => {}}
                onDocumentClick={(message) =>
                  this.props.rootStore.documentStore.viewDocument(
                    message.document
                  )
                }
                onViewPrompt={store.rootStore.promptDialogStore.show}
                isReadOnly={true}
                me={store.rootStore.sessionStore.member}
                scopeChangeRequests={[]}
              />
            ) : (
              <EmptyState
                title="It's awfully quiet around here..."
                className="minHeight"
                icon={
                  !this.props.rootStore.sessionStore.workspace.corporate && (
                    <img
                      src={require('assets/images/empty-chat.svg')}
                      alt="No Messages graphic"
                      style={{ width: 'auto', height: 150 }}
                    />
                  )
                }
              >
                This {T('Web.Common.Job', 'job')} has no messages yet.
              </EmptyState>
            )}
            {store.job.conversationId && store.job.status !== 'CLOSED' && (
              <RequirePerms allOf={['ADMIN_CONVERSATION_MANAGE']}>
                <MessageComposerContainer store={store} />
              </RequirePerms>
            )}
          </WidgetContent>
        </CallingConversationContext.Provider>
      </Widget>
    )
  }

  renderEvents() {
    const store = this.jobDetailsStore
    if (!store.job) return null

    return (
      <Widget>
        <WidgetHeader>
          <EventCategoryDropdown
            value={store.eventCategory}
            onChange={store.changeEventCategory}
            big
          />
        </WidgetHeader>
        <WidgetContent
          loading={store.fetchEvents.pending}
          scrollable
          style={{ height: 383 }}
        >
          {store.fetchEvents.match({
            rejected: (err) => <ContentError error={err} small />,
            resolved: () => (
              <JobEventList
                events={store.job.events}
                category={store.eventCategory}
              />
            ),
          })}
        </WidgetContent>
      </Widget>
    )
  }

  renderInfoLines() {
    const store = this.jobDetailsStore
    if (!store.job) return null

    return (
      <Widget title="Info Lines">
        <WidgetContent scrollable style={{ height: 200 }}>
          {store.job.infoLines.length === 0 ? (
            <EmptyState title="No specific information for this job" />
          ) : (
            store.job.infoLines.map((infoLine, i) => (
              <InfoLine infoLine={infoLine} key={i} />
            ))
          )}
        </WidgetContent>
      </Widget>
    )
  }

  renderMilestones() {
    const store = this.jobDetailsStore
    if (!store.job) return null
    return (
      <Widget title={T('Web.Common.Milestones', 'Milestones')}>
        <WidgetContent scrollable style={{ maxHeight: 383 }}>
          {store.milestonesViewState.milestones.length === 0 ? (
            <EmptyState title={`No ${T('Web.Common.Milestones')}`}>
              This {T('Web.Common.Job', 'Job')} does not have any{' '}
              {T('Web.Common.Milestones', 'milestones').toLowerCase()}.
            </EmptyState>
          ) : (
            <MilestonesView state={store.milestonesViewState} />
          )}
        </WidgetContent>
      </Widget>
    )
  }

  renderCapturedInformation() {
    const store = this.jobDetailsStore
    if (!store.job) return null
    return (
      <Widget>
        <WidgetHeader>
          <WidgetTitle>Captured Information</WidgetTitle>
          <WidgetHeaderSegment>
            <TabPills>
              <TabPill
                active={store.capturedInfoTab === 'QUESTIONS'}
                onClick={() => store.changeCapturedInfoTab('QUESTIONS')}
              >
                Questions
              </TabPill>
              <TabPill
                active={store.capturedInfoTab === 'VARIABLES'}
                onClick={() => store.changeCapturedInfoTab('VARIABLES')}
              >
                Variables
              </TabPill>
            </TabPills>
          </WidgetHeaderSegment>
        </WidgetHeader>
        <WidgetContent loading={store.fetchQuestions.pending}>
          {store.capturedInfoTab === 'VARIABLES' ? (
            <Variables variables={store.variables} />
          ) : (
            <QuestionsEditor
              questions={store.questions}
              answers={store.job.answers}
              readOnly
              borderless
            />
          )}
        </WidgetContent>
      </Widget>
    )
  }

  renderDocuments() {
    const store = this.jobDetailsStore
    if (!store.job) return null

    return (
      <JobDocuments
        documents={store.job.documents}
        scrollProvider={store.documentsScrollProvider}
        documentStore={store.rootStore.documentStore}
        loading={store.activate.pending}
        stageFiles={store.stageFiles}
        job={store.job}
        jobDetailsStore={store}
      />
    )
  }

  renderAmendments() {
    const store = this.jobDetailsStore
    if (!store.job) return null

    return (
      <Widget>
        <WidgetHeader>
          <WidgetTitle>Amendments</WidgetTitle>
          <WidgetHeaderSegment>
            {!store.isJobMenuActionDeprecated && store.job.status !== 'CLOSED' && (
              <RequirePerms allOf={['ADMIN_JOB_AMEND']}>
                <button
                  className="button is-icon-only"
                  title="Add amendment"
                  onClick={() => store.newAmendment()}
                >
                  <Icon material name="add" />
                </button>
              </RequirePerms>
            )}
          </WidgetHeaderSegment>
        </WidgetHeader>
        <WidgetContent>
          {store.job.amendments.length === 0 ? (
            <EmptyState title="No amendments yet">
              When amendments are added, they show up here.
            </EmptyState>
          ) : (
            <AmendmentsTable
              noBorder
              amendments={store.job.amendments}
              store={store}
            />
          )}
        </WidgetContent>
      </Widget>
    )
  }

  renderProviders() {
    const store = this.jobDetailsStore

    if (!store.job) return null

    return (
      <Widget>
        <WidgetHeader>
          <WidgetTitle>{T('Web.Common.Providers', 'Pros')}</WidgetTitle>
          <WidgetHeaderSegment>
            {store.job.status !== 'CLOSED' &&
              store.job.status !== 'UNDER_CONSTRUCTION' && (
                <RequirePerms
                  anyOf={[
                    'ADMIN_JOB_SUPPORTING_PROVIDER_MANAGE',
                    'ADMIN_JOB_CHAMPION_PROVIDER_MANAGE',
                  ]}
                >
                  {!store.isJobMenuActionDeprecated && (
                    <button
                      className="button is-icon-only"
                      title={`Add ${T('Web.Common.Provider', 'Pro')}`}
                      onClick={() => store.showAddProvider()}
                    >
                      <Icon material name="add" />
                    </button>
                  )}
                </RequirePerms>
              )}
          </WidgetHeaderSegment>
        </WidgetHeader>
        <WidgetContent>
          {store.job.providers.length === 0 ? (
            <EmptyState title={`No ${T('Web.Common.Providers', 'Pros')} yet`}>
              When a {T('Web.Common.Provider', 'Pro')} picks up the job, they
              show up here.
            </EmptyState>
          ) : (
            store.job.providers.map((p) => (
              <CollaboratorListItem
                key={p.user.userId}
                user={p.user}
                role={p.role}
                type={p.type}
                onRemoveClick={(reason) => store.removeProvider(p.user, reason)}
                canManage={
                  (p.role === 'CHAMPION' &&
                    store.hasAllPermissions(
                      'ADMIN_JOB_CHAMPION_PROVIDER_MANAGE'
                    )) ||
                  (p.role === 'SUPPORTING' &&
                    store.hasAllPermissions(
                      'ADMIN_JOB_SUPPORTING_PROVIDER_MANAGE'
                    ))
                }
              />
            ))
          )}
        </WidgetContent>
      </Widget>
    )
  }

  renderClients() {
    const store = this.jobDetailsStore

    if (!store.job) return null

    return (
      <Widget>
        <WidgetHeader>
          <WidgetTitle>{T('Web.Common.Clients', 'Clients')}</WidgetTitle>
        </WidgetHeader>
        <WidgetContent>
          {store.job.members
            .filter((x) => x.type === 'CLIENT')
            .map((p) => (
              <CollaboratorListItem
                key={p.user.userId}
                user={p.user}
                role={p.role}
                type={p.type}
                canManage={false}
              />
            ))}
        </WidgetContent>
      </Widget>
    )
  }

  renderQualifiedProviders() {
    const store = this.jobDetailsStore

    if (!store.job) {
      return null
    }

    return (
      <Widget>
        <WidgetHeader>
          <WidgetTitle>
            {T('Web.QualifiedProviders', 'Qualified Pros')}
          </WidgetTitle>

          <WidgetHeaderSegment>
            <CsvExport asyncExportMethod={store.loadAllData} />
          </WidgetHeaderSegment>
        </WidgetHeader>
        <WidgetContent>
          <QualifiedProviders store={store} />
        </WidgetContent>
      </Widget>
    )
  }

  renderPrompts() {
    const store = this.jobDetailsStore

    if (!store.job) {
      return null
    }

    return (
      <Widget>
        <WidgetHeader>
          <WidgetTitle>Prompts</WidgetTitle>
          <WidgetHeaderSegment>
            {store.canSendPrompt && (
              <RequirePerms anyOf={['ADMIN_JOB_UPDATE']}>
                <button
                  className="button is-icon-only"
                  title={`Send Prompt`}
                  onClick={() => store.showSendPrompt()}
                >
                  <Icon material name="add" />
                </button>
              </RequirePerms>
            )}
          </WidgetHeaderSegment>
        </WidgetHeader>
        <WidgetContent>
          <Prompts store={store} />
        </WidgetContent>
      </Widget>
    )
  }

  render() {
    const store = this.jobDetailsStore
    const toggleStore = getFeatureToggleClient()

    const displayDeprecationBanner = toggleStore.variation(
      'HQ.DisplayDeprecationBanner',
      false
    )

    const showTags = isDisplayDocumentTagsToggleEnabled()

    return (
      <ScreenProtector allOf={['ADMIN_JOB_VIEW']}>
        {displayDeprecationBanner && this.renderDeprecationBanner()}
        <PageWrapper className="JobDetails">
          <Helmet title={store.pageTitle} />
          {this.renderPageHeader()}
          {!store.job ? (
            <ContentLoading />
          ) : (
            <>
              <br />
              {this.renderUserCards()}
              <div className="columns">
                <div className="column">{this.renderJobSummary()}</div>
              </div>
              <CardGrid>
                <CardGridItem>{this.renderCapturedInformation()}</CardGridItem>
                <CardGridItem>{this.renderInfoLines()}</CardGridItem>
                <RequirePerms allOf={['ADMIN_DOCUMENT_MANAGE']}>
                  <CardGridItem>{this.renderDocuments()}</CardGridItem>
                </RequirePerms>
                <CardGridItem>{this.renderMilestones()}</CardGridItem>
                <CardGridItem>{this.renderEvents()}</CardGridItem>
                <CardGridItem>{this.renderAmendments()}</CardGridItem>
                <RequirePerms
                  anyOf={[
                    'ADMIN_JOB_CHAMPION_PROVIDER_MANAGE',
                    'ADMIN_JOB_SUPPORTING_PROVIDER_MANAGE',
                  ]}
                >
                  <CardGridItem>{this.renderClients()}</CardGridItem>
                  <CardGridItem>{this.renderProviders()}</CardGridItem>
                </RequirePerms>
                <RequirePerms allOf={['ADMIN_JOB_CHAMPION_PROVIDER_MANAGE']}>
                  <CardGridItem>{this.renderQualifiedProviders()}</CardGridItem>
                </RequirePerms>
                <CardGridItem>{this.renderPrompts()}</CardGridItem>
                <RequirePerms allOf={['ADMIN_CONVERSATION_VIEW']}>
                  <CardGridItem>{this.renderConversation()}</CardGridItem>
                </RequirePerms>
              </CardGrid>
              <DocumentSidebar
                documentStore={store.rootStore.documentStore}
                showTags={showTags}
              />
              <PromptDialog store={store.rootStore.promptDialogStore} />
              <SendPromptDialog store={store.rootStore.sendPromptDialogStore} />
            </>
          )}
        </PageWrapper>
      </ScreenProtector>
    )
  }
}

const MessageComposerContainer = observer(({ store }) => (
  <MessageComposer
    value={store.composerText}
    onChange={store.changeComposerText}
    onSend={store.sendMessage}
    disabled={store.activate.pending}
    downloadConversation={store.downloadConversation}
  />
))
