import { escapeQuotes } from '@taxfyle/ryno/lib/utils/string-util'
import { action, computed, observable } from 'mobx'
import { func } from 'validx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { receiveErrors } from '../data/serverErrors'
import { Types } from '../data/Type'
import { validatable } from '../mixins/validation'
import RynoEditor from '../ryno-editor/RynoEditor'

const LIST_OF_STRING = Types.list(Types.string)

/**
 * Specs config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class SpecsConfigEditor {
  @observable
  @serializable
  expr

  rules = {
    expr: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.expr = new RynoEditor({
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => LIST_OF_STRING,
    })
  }

  /**
   * Whether automatic migration from outputs is possible.
   */
  @computed
  get canMigrateFromOutputs() {
    if (this.expr.getFixedValue()) {
      // Can only migrate when we don't have an expr there already.
      return false
    }

    return this.allEffects.some(
      (e) => e.output.toJSON().length > 0 || e.removes.toJSON().length > 0
    )
  }

  /**
   * Whether we are using dynamic specs yet.
   * TODO: Remove when we fully remove support for outputs
   * @returns {boolean}
   */
  @computed
  get usingDynamicSpecs() {
    // We are using it if an expression has been defined or if there are no outputs.
    return (
      Boolean(this.expr.getFixedValue()) || this.canMigrateFromOutputs === false
    )
  }

  /**
   * Getter for all Effect Editors for the questions.
   * Used to execute a migration from Outputs (V1) to Specs (v2)
   * @returns {*}
   */
  @computed
  get allEffects() {
    return this.legendEditor.questions
      .flatMap((q) => q.effectDescriptors?.map((e) => e.effect))
      .filter(Boolean)
  }

  @computed
  get symbolTable() {
    return this.legendEditor.getSymbolTableFor(this)
  }

  hydrate(specsConfig) {
    this.expr.setValue(specsConfig?.expr ?? '', true)
  }

  toJSON() {
    return serialize(this)
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    receiveErrors(this, inner)
  }

  /**
   * Migrates from Outputs to Specifications.
   * TODO: This is temporary and will be removed once we do an automatic Legend migration
   *   For now, this just helps The Boys migrate over at their leisure.
   */
  @action.bound
  migrate() {
    const specsVariableName = window.prompt(
      'I am going to add a new variable to hold the Specs. What would you like to name the Specs variable?',
      'SPECS'
    )
    if (!specsVariableName) {
      return
    }

    if (this.symbolTable.getIdentifierSymbol(specsVariableName)) {
      window.alert('That ID is already taken. Aborting.')
      return
    }

    const confirmed = window.confirm(
      'IMPORTANT: If you have not already done so, please CANCEL and TAKE A BACK UP of the current state of the Legend.\n\nAre you ready to migrate?'
    )

    if (!confirmed) {
      return
    }

    for (const effect of this.allEffects) {
      const outputItems = effect.output.toJSON()
      const removesItems = effect.removes.toJSON()
      if (outputItems.length > 0) {
        effect.mutators.add('List', toListOp('Add', outputItems))
        effect.output.clear()
      }
      if (removesItems.length > 0) {
        effect.mutators.add('List', toListOp('Remove', removesItems))
        effect.removes.clear()
      }
    }
    const variableElement = this.legendEditor.questionsBuilder.add(
      'Variable',
      this.legendEditor.questionsBuilder.questions[0] ?? null
    )
    variableElement.set({
      id: specsVariableName,
      label: `Work required for this request`,
      variableValueType: LIST_OF_STRING,
    })
    this.expr.setValue(specsVariableName, true)

    function toListOp(op, items) {
      const toRynoStringLiteral = (item) => `"${escapeQuotes(item)}"`
      const multi = items.length > 1
      const expr = multi
        ? `[\n  ` +
          items.map((item) => `${toRynoStringLiteral(item)}`).join('\n  ') +
          '\n]'
        : toRynoStringLiteral(items[0])
      return {
        multi,
        expr,
        op,
        target: specsVariableName,
      }
    }
  }
}
