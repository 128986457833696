import groupBy from 'lodash/groupBy'
import { observable, action, computed } from 'mobx'
import { validationContext, required } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'

/**
 * When you want to add a new language just add it here!
 */
export const LOCALES = [
  {
    label: 'English (US)',
    key: 'en_us',
  },
  // {
  //   label: 'Deutsch (DE)',
  //   key: 'de_de'
  // }
]

/**
 * Will do its own save operations right when you hit save/delete.
 *
 */
export class TranslationFieldsEditor {
  @observable
  inputs = []

  constructor(translationStore, flashMessageStore) {
    this.translationStore = translationStore
    this.flashMessageStore = flashMessageStore
  }

  activate(workspaceId) {
    this.workspaceId = workspaceId

    const grouped = groupBy(this.translations, (x) => x.key)

    // create translation fields
    const fields = Object.keys(grouped).map((key) => {
      const values = grouped[key]
      const translationField = new TranslationField({ key: key })
      for (const value of values) {
        translationField.values[value.locale] = value.value
      }
      return translationField
    })

    this.inputs.replace(fields)
  }

  @computed
  get translations() {
    return this.translationStore.forWorkspace(this.workspaceId)
  }

  @action.bound
  add() {
    this.inputs.push(new TranslationField({ editing: true }))
  }

  @action.bound
  remove(field) {
    const msg = this.flashMessageStore.create({
      message: 'Deleting Translation...',
      inProgress: true,
    })
    // gotta do this for every locale
    return Promise.all(
      LOCALES.map((locale) => {
        const translation = this.translations.find(
          (x) => x.locale === locale.key && x.key === field.key
        )
        if (translation) {
          return this.translationStore.remove(translation.id)
        }
        return null
      })
    )
      .then(() => {
        this.inputs.splice(this.inputs.indexOf(field), 1)
        msg.done('Translation deleted.').autoDismiss()
      })
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  @action.bound
  save(field) {
    const context = field.validation.reset().validate()
    if (!context.isValid) {
      return
    }

    const msg = this.flashMessageStore.create({
      message: 'Creating Translation...',
      inProgress: true,
    })
    // gotta do this for every locale
    return Promise.all(
      LOCALES.map((locale) =>
        this.translationStore.upsert({
          locale: locale.key,
          key: field.key,
          value: field.values[locale.key],
          workspace_id: this.workspaceId,
        })
      )
    )
      .then(() => {
        field.editing = false
        msg.done('Translation created.').autoDismiss()
      })
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  @action.bound
  cancelEditing(field) {
    if (field.isNew) {
      this.inputs.pop()
    } else {
      // reset values
      field.key = field.previousValues.key
      field.values = field.previousValues.values
      field.previousValues = {}
    }

    field.editing = false
  }
}

class TranslationField {
  isNew = true
  previousValues = {}

  @observable
  editing = false

  @observable
  key = ''

  @observable
  values = {}

  constructor({ key, editing }) {
    this.editing = editing
    this.key = key
    for (const locale of LOCALES) {
      this.values[locale] = ''
    }
  }

  validation = validationContext(this, {
    key: [required()],
  })

  @action.bound
  startEditing() {
    this.previousValues = {
      key: this.key,
      values: this.values,
    }
    this.isNew = false
    this.editing = true
  }
}
