import React from 'react'
import { observer } from 'mobx-react'
import Avatar from 'components/Avatar'
import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableRow,
  TableRowLink,
  TableCell,
  SortableHeader,
} from 'components/BetterTable'
import link from 'misc/link'
import './UserList.sass'
import moment from 'moment'
import RequirePerms from 'iam/containers/RequirePerms'
import prune from 'underscore.string/prune'
import { Dropdown, DropdownItem, DropdownItemIcon } from 'components/Dropdown'
import Icon from 'components/Icon'
import ConfirmDialog from 'components/ConfirmDialog'
import cx from 'classnames'
import AddRolesDialog from '../AddRolesDialog'
import AddSkillsDialog from '../AddSkillsDialog'

const na = (str) => str || <small className="muted">N/A</small>

@observer
class UserItem extends React.Component {
  render() {
    const { user, store } = this.props
    const userUrl = link.user(
      user.userPublicId,
      user.rootStore.sessionStore.workspace.slug
    )
    return (
      <TableRowLink link={userUrl}>
        <UserSelect user={user} store={store} />
        <TableCell>
          <Avatar url={user.avatar} size={32} fallback={user.avatarFallback} />
          &nbsp; &nbsp; &nbsp;
          <span className="name" data-test={`user-name-${user.displayName}`}>
            {prune(user.displayName, 30)}
          </span>
          <div className={cx('presence-indicator', user.online && 'online')} />
        </TableCell>

        <RequirePerms allOf={['ADMIN_USER_READ']}>
          <TableCell>{na(user.email)}</TableCell>
          <TableCell>{na(user.phone)}</TableCell>
          <TableCell>{user.userId}</TableCell>
        </RequirePerms>
        <TableCell>
          {user.lastLogin
            ? moment(user.lastLogin).format('LL LT')
            : 'Not Recorded'}
        </TableCell>
      </TableRowLink>
    )
  }
}

@observer
class UserSelect extends React.Component {
  render() {
    const { user, store } = this.props

    return (
      <TableCell onClick={(e) => e.stopPropagation()}>
        <input
          type="checkbox"
          onClick={(e) => {
            e.stopPropagation()
          }}
          onChange={() => store.handleSelection(user.userPublicId)}
          checked={store.selectedUsers.indexOf(user.userPublicId) > -1}
        />
      </TableCell>
    )
  }
}

@observer
class UsersList extends React.Component {
  render() {
    const { users } = this.props.store
    return (
      <div className="UserList">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell
                style={{
                  padding: '10px 0px 0px 5px',
                }}
              >
                {this.props.store.selectedUsers.length > 0 && (
                  <Dropdown
                    position="left"
                    button={
                      <a
                        className="action button is-icon-only"
                        data-test="toggle-header-dropdown-users"
                      >
                        <Icon material name="more vert" />
                      </a>
                    }
                  >
                    <RequirePerms allOf={['ADMIN_USER_MODIFY']}>
                      {this.props.store.showInactive ? (
                        <DropdownItem
                          onClick={() => this.props.store.activateSelected()}
                          className="normalize-text"
                        >
                          <DropdownItemIcon
                            material
                            name="add circle outline"
                          />
                          Activate Selected
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          onClick={() => this.props.store.deleteSelected()}
                          className="normalize-text"
                        >
                          <DropdownItemIcon material name="delete" />
                          Delete Selected
                        </DropdownItem>
                      )}
                    </RequirePerms>
                    {!this.props.store.showInactive && (
                      <>
                        <RequirePerms allOf={['ADMIN_USER_MODIFY']}>
                          <DropdownItem
                            onClick={() =>
                              this.props.store.deactivateSelected()
                            }
                            className="normalize-text"
                          >
                            <DropdownItemIcon material name="block" />
                            Remove Roles & Skills
                          </DropdownItem>
                        </RequirePerms>
                        <RequirePerms allOf={['ADMIN_USER_SKILLS_MODIFY']}>
                          <DropdownItem
                            onClick={() => this.props.store.showSkillsEditor()}
                            className="normalize-text"
                          >
                            <DropdownItemIcon
                              material
                              name="add circle outline"
                            />
                            Add Skills
                          </DropdownItem>
                        </RequirePerms>
                        <RequirePerms allOf={['ADMIN_USER_ROLES_MODIFY']}>
                          <DropdownItem
                            onClick={() =>
                              this.props.store.showAddRolesDialog()
                            }
                            className="normalize-text"
                          >
                            <DropdownItemIcon
                              material
                              name="add circle outline"
                            />
                            Add Roles
                          </DropdownItem>
                        </RequirePerms>
                      </>
                    )}
                  </Dropdown>
                )}
              </TableHeadCell>
              <SortableHeader
                store={this.props.store}
                label="Name"
                field="given_name"
              />
              <RequirePerms allOf={['ADMIN_USER_READ']}>
                <SortableHeader
                  store={this.props.store}
                  label="Email"
                  field="email"
                />
                <SortableHeader
                  store={this.props.store}
                  label="Phone"
                  field="phone"
                />
                <SortableHeader
                  store={this.props.store}
                  label="User ID"
                  field="user_id"
                />
              </RequirePerms>
              <SortableHeader
                store={this.props.store}
                label="Last Login"
                field="last_login"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <UserItem
                user={user}
                key={user.userId}
                store={this.props.store}
                data-test={`user-name-${user.name}`}
              />
            ))}
          </TableBody>
        </Table>
        <ConfirmDialog
          state={this.props.store.deleteUsersConfirm}
          title="Delete User(s)"
          yes="Delete"
          danger
        >
          <p>
            Do you really want to delete these users? They won't be able to
            access this workspace anymore.
          </p>
        </ConfirmDialog>
        <ConfirmDialog
          state={this.props.store.deactivateUsersConfirm}
          title="Deactivate User(s)"
          yes="Deactivate"
          danger
        >
          <p>
            Do you really want to remove all roles and skills from these users?
          </p>
        </ConfirmDialog>
        <AddSkillsDialog store={this.props.store.rootStore.addSkillsStore} />
        <AddRolesDialog store={this.props.store.rootStore.addRolesStore} />
      </div>
    )
  }
}

export default UsersList
