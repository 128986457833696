import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import UserSelector from 'components/UserSelector'
import ErrorLabel from 'components/ErrorLabel'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import './AddProviderDialog.sass'
import { Input, Select } from 'components/Form'
import RequirePerms from 'iam/containers/RequirePerms'
import { filterOutAllMembersOf } from 'utils/userSelectorFilterUtil'

@observer
class AddProviderDialog extends React.Component {
  componentDidMount() {
    // store is a JobTransferState
    this.props.store.init()
  }

  renderDialog() {
    const { store } = this.props
    return (
      <div className="addProviderDialog">
        <DialogTitle>Add Pro</DialogTitle>
        <DialogContent>
          <label htmlFor="addProviderDialog__providerToAdd">Pro to add</label>
          <div className="addProviderDialog__providerToAdd">
            <UserSelector
              userFilter={filterOutAllMembersOf(store.job)}
              workspaceId={
                store.job.workspaceId // disabled={store.addProvider.pending}
              }
              memberStore={store.memberStore}
              name="addProviderDialog__providerToAdd"
              onChange={store.selectProvider.bind(store)}
              value={store.provider}
            />
            {store.availableTeams.length > 0 && (
              <Select
                model={store}
                field="teamId"
                label="To Team"
                help="Should this be assigned to a team?"
              >
                <option value="">None</option>
                {store.availableTeams.map((t) => (
                  <option key={t.id} value={t.team.id}>
                    {t.team.name}
                  </option>
                ))}
              </Select>
            )}
          </div>

          <Input
            model={store}
            disabled={store.addProvider.pending}
            field="reason"
            label="Reason"
            help="Why are you adding another Pro? This text is public and can be seen by the Client and all Pros."
          />

          <RequirePerms allOf={['ADMIN_JOB_CHAMPION_PROVIDER_MANAGE']}>
            <Select
              model={store}
              field="type"
              label="Provider Role"
              help="Should this Provider be the main Pro or just helping out?"
            >
              <option value="CHAMPION">Champion</option>
              <option value="SUPPORTING">Support</option>
            </Select>
          </RequirePerms>

          <ErrorLabel>
            {store.addProvider.error && store.addProvider.error.message}
          </ErrorLabel>
          <br />
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide.bind(store)}>Cancel</DialogButton>
          <DialogButton
            danger
            onClick={store.addProvider.bind(store)}
            disabled={store.addProvider.pending}
          >
            Add
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        position="top"
      >
        <Dialog>{this.renderDialog()}</Dialog>
      </Modal>
    )
  }
}

export default AddProviderDialog
