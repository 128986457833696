import { action, computed, observable } from 'mobx'
import { snapshotable } from '../data/serialization'
import { validatable } from '../mixins/validation'
import { validateElements } from '../../utils/validx-validators'
import { RoutingPlanEditor } from './RoutingPlanEditor'
import { Subject } from 'rxjs'
import { tabManager } from '../mixins/tab-manager'
import { addGenericErrorForProperty, receiveErrors } from '../data/serverErrors'

/**
 * Routing plans builder.
 */
@validatable()
@tabManager('GENERAL')
@snapshotable
export class RoutingPlansBuilder {
  /**
   * Routing plans collection.
   */
  @observable.shallow plans = []

  /**
   * Emits when an item is removed.
   */
  remove$ = new Subject()

  rules = {
    plans: [validateElements('There are invalid routing plans')],
  }

  constructor({ legendEditor, routingEditor, scope, context }) {
    this.legendEditor = legendEditor
    this.routingEditor = routingEditor
    this.scope = scope
    this.context = context
  }

  /**
   * The selected plan, if any.
   */
  @computed
  get selectedPlan() {
    const editor = this.routingEditor.selectedEditor
    return editor instanceof RoutingPlanEditor ? editor : null
  }

  /**
   * Whether the editor is disabled.
   *
   * @returns {*}
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Whether we can add a new plan.
   * @returns {boolean}
   */
  @computed
  get canAddPlan() {
    return this.plans.length < 10
  }

  /**
   * Adds a new routing plan.
   */
  @action.bound
  add() {
    if (!this.canAddPlan) {
      return
    }
    const plan = RoutingPlanEditor.create({}, this._routingPlanOpts())
    this.plans.push(plan)
    this.edit(plan)
  }

  /**
   * Starts editing the plan.
   *
   * @param plan
   */
  @action.bound
  edit(plan) {
    this.routingEditor.edit(plan)
  }

  /**
   * Removes the specified plan.
   *
   * @param plan
   */
  @action.bound
  remove(plan) {
    this.plans.remove(plan)
    this.remove$.next(plan)
  }

  /**
   * Hydrates the editor,
   * @param plans
   */
  hydrate(plans = []) {
    this.plans.replace(
      plans.map((p) => RoutingPlanEditor.fromJS(p, this._routingPlanOpts()))
    )
  }

  toJSON() {
    return this.plans.map((plan) => plan.toJSON())
  }

  receiveErrors(descriptor) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'plans',
      'There are invalid plans'
    )
    receiveErrors(this.plans, descriptor.inner)
  }

  _routingPlanOpts() {
    return {
      legendEditor: this.legendEditor,
      scope: this.scope,
      context: this.context,
    }
  }
}
