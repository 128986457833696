import { observable, action } from 'mobx'
import { task } from 'mobx-task'

/**
 * Assign job to a team UI state.
 */
export default class AssignJobToTeamState {
  @observable
  showing = false

  @observable
  error = ''

  @observable
  job

  @observable
  teamId = ''

  @observable
  availableTeams = []

  constructor({ rootStore }) {
    this.rootStore = rootStore
  }

  @action.bound
  async init(job) {
    this.job = job
    const member = this.job.members.find(
      (x) => x.role === 'CHAMPION' && x.type === 'CLIENT'
    )

    this.availableTeams = await this.rootStore.teamMemberStore.fetchUserTeamMembersByPublicId(
      {
        workspace_id: member.user.workspaceId,
        user_id: member.user.userPublicId,
      }
    )
    this.teamId = this.job.ownerTeamId || ''

    this.assignJobToTeam.reset()
  }

  @task.resolved
  async assignJobToTeam() {
    await this.rootStore.jobStore.assignJobToTeam(this.job.id, {
      teamId: this.teamId,
    })

    this.hide()
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }
}
