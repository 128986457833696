import { observable, action, computed } from 'mobx'
import { task } from 'mobx-task'
import InfiniteScrollProvider from '@taxfyle/web-commons/lib/data/InfiniteScrollProvider'
import ConfirmDialogState from '@taxfyle/web-commons/lib/components/ConfirmDialog/ConfirmDialogState'
import { validatable } from '../mixins/validation'
import { stringLength } from 'utils/validx-validators'
import ConflictResolution from './ConflictResolution'

/**
 * Version manager.
 */
@validatable({ liveValidation: true })
export default class VersionManager {
  /**
   * Whether or not the version picker is visible.
   */
  @observable
  showing = false

  /**
   * Version notes.
   */
  @observable
  versionNotes = ''

  /**
   * Controls whether the publish view is showing.
   */
  @observable
  showingPublishView = false

  /**
   * Validation rules.
   */
  rules = {
    versionNotes: [
      stringLength(
        1,
        500,
        'Version notes should be between {min} and {max} characters'
      ),
    ],
  }

  /**
   * Initializes the state of the version manager.
   *
   * @param {*} legendEditor
   * @param {*} legendStore
   */
  constructor(legendEditor, legendStore, memberStore) {
    this.legendEditor = legendEditor
    this.legendStore = legendStore
    this.conflict = new ConflictResolution(
      legendStore,
      legendEditor,
      memberStore
    )
    this.discardLocalChanges = this.discardLocalChanges.bind(this)
    this.revertToSelectedVersion = this.revertToSelectedVersion.bind(this)
    this.publish = this.publish.bind(this)
    this.fetchLimit = 10
    this.versionScroller = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: this.fetchLimit,
      fetchItems: this._fetchVersions.bind(this),
    })
    this.confirmRevert = new ConfirmDialogState()
  }

  /**
   * The selected version model.
   */
  @computed
  get selectedVersion() {
    return this.legendEditor.legendVersion
  }

  /**
   * The selected legend.
   */
  @computed
  get legendId() {
    return this.selectedVersion ? this.selectedVersion.id : null
  }

  /**
   * The selected version number.
   */
  @computed
  get selectedVersionNumber() {
    return this.selectedVersion ? this.selectedVersion.version : 0
  }

  /**
   * Determines whether there are changes to the legend in this session.
   */
  @computed
  get isDirty() {
    return this.legendEditor.isDirty
  }

  /**
   * Determines whether this version is a draft.
   */
  @computed
  get isDraft() {
    return !this.selectedVersion.published
  }

  /**
   * Whether or not we are viewing the latest version (draft or live)
   */
  @computed
  get isViewingLatestVersion() {
    const selected = this.selectedVersion
    if (!selected) {
      return false
    }

    const latestVersion = this.latestVersion
    if (!latestVersion) {
      return false
    }

    return latestVersion.version === selected.version
  }

  /**
   * Whether or not we are viewing a previous version (that is not a draft)
   */
  @computed
  get isViewingPreviousVersion() {
    const selected = this.selectedVersion
    if (!selected) {
      return false
    }

    const latestPublishedVersion = this.latestPublishedVersion
    if (!latestPublishedVersion) {
      return false
    }

    return latestPublishedVersion.version >= selected.version
  }

  /**
   * Whether or not we are viewing the latest published version.
   */
  @computed
  get isViewingLiveVersion() {
    return this.latestPublishedVersion === this.selectedVersion
  }

  /**
   * The available versions for the legend.
   */
  @computed
  get versions() {
    return this.legendStore.versionsFor(this.legendId)
  }

  /**
   * The latest published version.
   */
  @computed
  get latestPublishedVersion() {
    const latest = this.latestVersion
    if (!latest) {
      return null
    }

    if (latest.published) {
      return latest
    }

    return (
      this.versions.find(
        (v) => v.version === latest.version - 1 && v.published
      ) || null
    )
  }

  /**
   * The latest version.
   */
  @computed
  get latestVersion() {
    const latest = this.versions[0]
    return latest || null
  }

  /**
   * Gets the publish status for a version.
   */
  getPublishStatusForVersion(version) {
    const isLive = version === this.latestPublishedVersion
    return !version.published ? 'DRAFT' : isLive ? 'PUBLISHED' : 'PREVIOUS'
  }

  /**
   * Close the picker.
   */
  @action.bound
  close() {
    this.showing = false
  }

  /**
   * Show the picker.
   */
  @action.bound
  show() {
    this.showing = true
  }

  /**
   * Activates the manager.
   */
  @task
  async activate() {
    this.setVersionNotes('')
    this.versionScroller.reset()
    // Do 2 fetches so short lists reach the end quicker and so won't show
    // a loading indicator.
    await this.versionScroller.fetch()
    // Don't wait for the last one.
    this.versionScroller.more()
    // Populate version notes for initial version.
    if (this.latestVersion && this.latestVersion.version === 1) {
      this.setVersionNotes('Initial version')
    }
  }

  /**
   * Shows the publish view.
   */
  @action.bound
  showPublishView() {
    this.validation.reset()
    this.legendEditor.persistAndCheck()
    this.showingPublishView = true
  }

  /**
   * Hides the publish view.
   */
  @action.bound
  hidePublishView() {
    this.showingPublishView = false
  }

  /**
   * Sets the version notes.
   */
  @action.bound
  setVersionNotes(value) {
    this.versionNotes = value
  }

  /**
   * Selects the specified version.
   * @param {*} version
   */
  @task.resolved
  async selectVersion(version) {
    if (this.selectedVersionNumber === version.version) {
      this.close()
      return
    }

    // Wait for the next tick so the UI can show the spinner.
    // This is because switching legends may hold up the
    // UI thread for large legends, and would then not show the spinner.
    await new Promise((resolve) => setTimeout(resolve, 10))

    await this.legendEditor.selectLegendVersion(version.id, version.version, {
      keepInitialState: true,
    })
    this.close()
  }

  /**
   * Discards local changes to the ones that were loaded on editor init.
   */
  @task.resolved
  async discardLocalChanges() {
    return this.legendEditor.discardLocalChanges()
  }

  /**
   * Discards local changes to the ones that were loaded on editor init.
   */
  @task.resolved
  async revertToSelectedVersion() {
    if (await this.confirmRevert.show()) {
      return this.legendEditor.revertToSelectedVersion()
    }
  }

  /**
   * Discards local changes to the ones that were loaded on editor init.
   */
  @task.resolved
  async publish() {
    if (!this.validateAll()) {
      return
    }
    await this.legendEditor.publish({
      versionNotes: this.versionNotes,
    })
    this.hidePublishView()
  }

  /**
   * Fetches legend versions.
   * @param {*} params
   */
  _fetchVersions(params) {
    return this.legendStore.fetchLegendVersions(this.legendId, params)
  }
}
