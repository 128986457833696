import { action, observable, computed, runInAction } from 'mobx'
import { task } from 'mobx-task'
import uniq from 'lodash/uniq'
import { validationContext, func, pattern } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'

export default class InviteUserModalState {
  @observable showing = false
  @observable email = ''
  @observable roleId = null
  @observable target = 'Customer_Portal'
  @observable skills = []
  @observable team = null
  @observable teamRoleId = ''

  validation = validationContext(this, {
    email: [
      func(
        () => this.emailAddresses.length > 0,
        'Please enter at least one email address'
      ),
      func(() => {
        const fn = pattern('email')
        const invalidEmails = this.emailAddresses
          .map((e) => {
            const isValidEmail =
              fn({ obj: this, field: 'email', value: e }) === true
            return isValidEmail ? false : e
          })
          .filter(Boolean)
        return (
          invalidEmails.length === 0 ||
          `The following emails are invalid: ${invalidEmails.join(', ')}`
        )
      }),
    ],
  })

  constructor({
    roleStore,
    skillStore,
    teamStore,
    teamRoleStore,
    invitationStore,
    flashMessageStore,
    sessionStore,
  }) {
    this.invitationStore = invitationStore
    this.flashMessageStore = flashMessageStore
    this.roleStore = roleStore
    this.skillStore = skillStore
    this.teamStore = teamStore
    this.teamRoleStore = teamRoleStore
    this.sessionStore = sessionStore
    this.sendInvite = this.sendInvite.bind(this)
  }

  @computed
  get roles() {
    return this.roleStore.forWorkspace(this.sessionStore.workspace.id)
  }

  @computed
  get teamRoles() {
    return this.teamRoleStore.forWorkspace(this.sessionStore.workspace.id)
  }

  @action.bound
  show() {
    this.showing = true
    this.email = ''
    this.activate()
    this.roleId = this.roles[0].id
  }

  defaultTeamRole() {
    const guestRoleId = this.teamRoles?.find(
      (teamRole) => teamRole.name === 'Guest'
    )?.id
    this.selectTeamRoleId(guestRoleId || '')
  }

  @computed
  get emailAddresses() {
    return uniq(
      this.email
        .split(/[,\n]+/g)
        .map((e) => e.trim().toLowerCase())
        .filter(Boolean)
    )
  }

  @task
  async activate() {
    await this.roleStore.find()
    await this.teamRoleStore
      .fetchTeamRolesForWorkspace(this.sessionStore.workspace.id)
      .then(() => this.defaultTeamRole())
  }

  @action.bound
  selectSkills(skills) {
    this.skills = skills
  }

  @action.bound
  selectTeam(team) {
    this.team = team
  }

  @action.bound
  selectTeamRoleId(teamRoleId) {
    this.teamRoleId = teamRoleId
  }

  @task.resolved
  async sendInvite() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    // Track how many we have sent.
    let doneCount = 0
    const emails = this.emailAddresses
    const totalCount = emails.length
    // Computes a message every time a request is done
    const formatMessage = () =>
      `Sending invitations, ${doneCount} of ${totalCount}...`
    const msg = this.flashMessageStore.create({
      inProgress: true,
      message: formatMessage(),
    })

    const results = await Promise.all(
      emails.map((email) =>
        email === 'fail@test.com'
          ? Promise.resolve({
              email,
              error: new Error('Oh my, shit happened'),
            })
          : this.invitationStore
              .invite({
                workspace_id: this.sessionStore.workspace.id,
                workspace_role_id: this.roleId,
                email: email,
                target: this.target,
                skill_ids: this.skills?.map((s) => s.id),
                team_id: this.team?.id !== '-1' ? this.team?.id : undefined,
                team_role_id: this.teamRoleId,
              })
              .then(() => {
                doneCount++
                msg.set({ message: formatMessage() })
                return { email, success: true }
              })
              .catch((err) => {
                return { email, error: err }
              })
      )
    )

    const failed = results.filter((r) => r.error)
    if (failed.length === 0) {
      msg
        .done(doneCount === 1 ? 'Invite sent!' : `Sent ${doneCount} invites!`)
        .autoDismiss()
      this.close()
      return
    }

    // Report failures, set the email field to the emails that failed.
    runInAction(() => {
      this.email = failed.map((f) => f.email).join(', ')
    })
    const errorMessages = failed
      .map((f) => extractMessageFromError(f.error))
      .join('. ')

    msg.warn(
      doneCount > 0
        ? `Sent ${doneCount} invites, but ${failed.length} didn't go through. ${errorMessages}`
        : `None of the invites were sent. ${errorMessages}`
    )
  }

  @action.bound
  close() {
    this.showing = false
    this.skills = []
    this.team = null
  }
}
