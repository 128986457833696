import { action, computed, observable, runInAction } from 'mobx'
import { task } from 'mobx-task'
import { func, required, validationContext } from 'validx'

class ScopeWorkEditorStore {
  @observable showing = false
  @observable error = ''

  @observable user = null
  @observable supportingClient = ''
  @observable teamId = ''
  @observable availableTeams = []
  @observable legends = []
  @observable selectedLegendId = ''
  @observable selectedLegendVersion = ''
  @observable selectedTemplateId = ''
  @observable files = []

  @observable answers = []

  @observable legendVersion = null
  @observable manualQuestions = []

  legend = null
  template = null

  validation = validationContext()

  validationRules = {
    selectedLegendId: [required('Legend is required')],
    selectedLegendVersion: [required('Legend is required')],
    selectedTemplateId: [required('Template is required')],
    files: [func(() => this.files.length > 0, 'Please add at least one file.')],
  }

  constructor({ rootStore }) {
    this.rootStore = rootStore
    this.flashMessageStore = rootStore.flashMessageStore
    this.fetchData = this.fetchData.bind(this)
  }

  @action.bound
  init(user) {
    this.validation.reset()
    this.legends = []
    this.files = []
    this.availableTeams = []
    this.teamId = ''
    this.error = ''
    this.user = user
    this.supportingClient = ''
    this.submit = this.submit.bind(this)
    this.selectLegend = this.selectLegend.bind(this)
    this.selectedLegendId = ''
    this.selectedLegendVersion = ''
    this.selectedTemplateId = ''
    this.answers = []
    this.manualQuestions = []

    this.fetchData()
  }

  @task
  async fetchData() {
    const legends = await this.rootStore.publishedLegendStore.fetchPublishedLegends(
      this.rootStore.sessionStore.workspace.id,
      {
        limit: 1000,
      }
    )
    const availableTeams = await this.rootStore.teamMemberStore.fetchUserTeamMembersByPublicId(
      {
        workspace_id: this.user.workspaceId,
        user_id: this.user.userPublicId,
      }
    )

    runInAction(() => {
      this.legends = legends.data
      this.availableTeams = availableTeams
    })
  }

  @action.bound
  async selectLegend(legendId) {
    this.selectedLegendId = legendId
    this.legend = this.legends.find((x) => x.id === legendId)
    if (!this.legend) {
      this.selectedLegendVersion = ''
      this.legendVersion = null
      return
    }
    this.selectedLegendVersion = this.legend.version

    this.legendVersion = await this.rootStore.legendStore.fetchLegendVersion(
      legendId,
      this.selectedLegendVersion
    )
  }

  @action.bound
  async selectTemplate(templateId) {
    this.selectedTemplateId = templateId
    this.template = this.legendVersion.templates.find(
      (x) => x.id === templateId
    )
    if (!this.template) {
      this.manualQuestions.replace([])
      return
    }

    const questions = await this.rootStore.scopeWorkStore.manualInputs({
      workspace_id: this.rootStore.sessionStore.workspace.id,
      legend_id: this.legend.id,
      legend_version: this.selectedLegendVersion,
      template_id: templateId,
    })

    this.manualQuestions.replace(questions)
    this.answers.replace(questions.map((x) => ({ id: x.id, answer: x.answer })))
  }

  @action.bound
  setAnswers(answers) {
    this.answers = answers
  }

  @action.bound
  selectSupportingClient(member) {
    this.supportingClient = member
  }

  @action.bound
  selectFiles(files) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i))
    }
  }

  @computed
  get templates() {
    if (!this.legendVersion) {
      return []
    }

    return this.legendVersion?.templates ?? []
  }

  @computed
  get busy() {
    return (
      this.selectLegend.pending || this.fetchData.pending || this.submit.pending
    )
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @task.resolved
  async submit() {
    const data = {}
    this.answers.forEach(({ id, answer }) => {
      data[id] = answer
    })

    const rules = {
      ...this.validationRules,
      answers: [
        () =>
          Object.values(data).length === this.manualQuestions.length ||
          'Please answer all questions.',
      ],
    }

    if (!this.validation.reset().validate(this, rules).isValid) {
      return
    }

    await this.rootStore.scopeWorkStore.scopeWork({
      workspace_id: this.rootStore.sessionStore.workspace.id,
      user_id: this.user.userPublicId,
      supporting_client_user_public_id: this.supportingClient.userPublicId,
      team_id: this.teamId,
      legend_id: this.selectedLegendId,
      legend_version: this.selectedLegendVersion,
      template_id: this.selectedTemplateId,
      data: data,
      files: this.files,
    })
    this.hide()
  }
}

export default ScopeWorkEditorStore
