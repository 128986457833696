import { Store } from 'libx'
import { task } from 'mobx-task'
import { computed, action, observable } from 'mobx'
import ConfirmDialogState from 'components/ConfirmDialog/ConfirmDialogState'
import InfiniteScrollProvider from 'data/InfiniteScrollProvider'

export default class RolesScreenStore extends Store {
  @observable
  roleToDelete = null

  constructor() {
    super(...arguments)
    this.confirmDelete = new ConfirmDialogState()
    this.scrollProvider = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: 50,
      fetchItems: this._loadRoles.bind(this),
    })

    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.scrollProvider.reset()
    })
  }

  @computed
  get roles() {
    return this.rootStore.roleStore.forWorkspace(
      this.rootStore.sessionStore.workspace.id
    )
  }

  @computed
  get teamRoles() {
    return this.rootStore.teamRoleStore.forWorkspace(
      this.rootStore.sessionStore.workspace.id
    )
  }

  @task
  activate() {
    return this.loadRoles()
  }

  @action
  createRole() {
    this.rootStore.roleEditorStore.show(null, false)
  }

  @action
  createTeamRole() {
    this.rootStore.roleEditorStore.show(null, true)
  }

  @action
  edit(role) {
    return this.rootStore.roleEditorStore.show(role, false)
  }

  @action
  editTeamRole(role) {
    return this.rootStore.roleEditorStore.show(role, true)
  }

  @action
  deleteRole(role) {
    this.roleToDelete = role
    return this.confirmDelete.show().then((shouldDelete) => {
      if (!shouldDelete) {
        return
      }

      return this.rootStore.roleStore.destroy(role.id)
    })
  }

  @action
  deleteTeamRole(role) {
    this.roleToDelete = role
    return this.confirmDelete.show().then((shouldDelete) => {
      if (!shouldDelete) {
        return
      }

      return this.rootStore.teamRoleStore.destroy(role.id)
    })
  }

  async _loadRoles(paging) {
    await this.rootStore.roleStore.find({
      limit: paging.limit,
      after: paging.after,
      workspace_id: this.rootStore.sessionStore.workspace.id,
    })
    await this.rootStore.teamRoleStore.find({
      limit: paging.limit,
      after: paging.after,
      workspace_id: this.rootStore.sessionStore.workspace.id,
    })
    return true
  }

  loadRoles() {
    return this.scrollProvider.fetch({})
  }
}
