import React from 'react'

import { observer } from 'mobx-react'
import link from 'misc/link'
import { browserHistory } from 'react-router'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import Modal from 'components/Modal'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import ContentLoading from 'components/ContentLoading'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  SortableHeader,
} from 'components/BetterTable'
import EmptyState from 'components/EmptyState'

import CsvExport from '../../Jobs/FacetedSearch/CsvExport'
import styles from './UserCouponListModal.m.sass'

const UserCouponList = observer(({ state }) => {
  const headers = [
    { label: 'Code' },
    { label: 'Job' },
    { label: 'Member' },
    { label: 'Date Redeemed' },
    { label: 'Off Type' },
    { label: 'Amount Off' },
  ]

  const goToJob = (job) => {
    state.userCoupons = []

    browserHistory.push(link.job(job.id, state.sessionStore.workspace.slug))
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <SortableHeader
              key={index}
              store={state}
              field={header.field}
              label={header.label}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {state.userCoupons.map((userCoupon, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{userCoupon.coupon.code}</TableCell>
              <TableCell>
                {userCoupon.jobs.map((job) => {
                  return (
                    <p
                      className={styles.pointer}
                      onClick={() => goToJob(job)}
                      key={job.id}
                    >
                      {job.name}
                    </p>
                  )
                })}
              </TableCell>
              <TableCell>{userCoupon.member.displayName}</TableCell>
              <TableCell>{userCoupon.dateRedeemed.format('LL LT')}</TableCell>
              <TableCell>{userCoupon.coupon.offType.displayType}</TableCell>
              <TableCell>
                {userCoupon.coupon.offType.type === 'AMOUNT_OFF'
                  ? `$${userCoupon.coupon.offType.amountOff} off`
                  : `${userCoupon.coupon.offType.percentOff}% off`}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
})

const UserCouponListModal = observer(({ state }) => {
  const isEmpty = state.userCoupons.length === 0
  const renderCoupons = () => {
    return (
      <>
        <div>
          <UserCouponList state={state} />
        </div>
      </>
    )
  }

  const renderEmpty = () => {
    return (
      <EmptyState title="Users..">
        Couldn't find any user that matches your query.
      </EmptyState>
    )
  }

  return (
    <Modal isOpened={state.showing} onRequestClose={state.hide} position="top">
      <Dialog>
        <DialogTitle>Users </DialogTitle>
        <DialogContent className={styles.content}>
          {isEmpty && state.scrollProvider.isDone
            ? renderEmpty()
            : renderCoupons()}
          <InfiniteScrollZone scrollProvider={state.scrollProvider}>
            {({ isDone }) => (isDone ? null : <ContentLoading smaller />)}
          </InfiniteScrollZone>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={state.hide}>Cancel</DialogButton>

          <div className="DialogButton">
            <button className={styles.csvExport}>
              <CsvExport asyncExportMethod={state.loadAllData} />
            </button>
          </div>
        </DialogFooter>
      </Dialog>
    </Modal>
  )
})

export default UserCouponListModal
