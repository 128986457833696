import cx from 'classnames'
import Button from 'components/Button'
import ConfirmDialog from 'components/ConfirmDialog'
import { Form, Input, FormGroup } from 'components/Form'
import Icon from 'components/Icon'
import { ListItem, ListItemSegment, ListItemTitle } from 'components/ListItem'
import ShadowBox from 'components/ShadowBox'
import RequirePerms from 'iam/containers/RequirePerms'
import { observer } from 'mobx-react'
import React from 'react'
import TagSelect from 'documents/components/TagSelect'

import './SkillEditor.sass'

const SkillEditor = ({ editor }) => {
  return (
    <div className={cx('skill-editor')}>
      {editor.editing ? (
        <SkillEditView editor={editor} />
      ) : (
        <SkillDisplayView
          skill={editor.model}
          onEditClick={() => editor.startEditing()}
          onDestroyClick={() => editor.destroy()}
          confirmState={editor.confirmDestruction}
        />
      )}
    </div>
  )
}

const SkillDisplayView = observer(
  ({ skill, onEditClick, onDestroyClick, confirmState }) => (
    <ShadowBox className="skill-display-view">
      <ListItem>
        <ListItemSegment pad stretch>
          <ListItemTitle>{skill.name}</ListItemTitle>
          <p>{skill.description || 'no description'}</p>
          <p>
            {skill.tags.map((tag) => (
              <span key={tag.id} className="tag">
                {tag.label}
              </span>
            ))}
          </p>
        </ListItemSegment>
        <ListItemSegment pad>
          <RequirePerms allOf={['ADMIN_SKILLS_MANAGE']}>
            <a className="button is-icon-only" onClick={onEditClick}>
              <Icon material name="edit" />
            </a>
            &nbsp;
          </RequirePerms>
          <RequirePerms allOf={['ADMIN_SKILLS_REMOVE']}>
            <a className="button is-icon-only" onClick={onDestroyClick}>
              <Icon material name="delete" />
            </a>
          </RequirePerms>
        </ListItemSegment>
      </ListItem>
      <ConfirmDialog
        state={confirmState}
        danger
        position="top"
        title="Delete skill"
      >
        Do you really want to delete <strong>{skill.name}</strong>?
      </ConfirmDialog>
    </ShadowBox>
  )
)

const SkillEditView = observer(({ editor }) => (
  <ShadowBox className="skill-edit-view">
    <Form onSubmit={() => editor.save()} disabled={editor.disabled}>
      <Input model={editor} field="name" label="Skill name" />
      <Input
        model={editor}
        field="description"
        label="Description (optional)"
      />
      <FormGroup label="Tags">
        <TagSelect
          documentTagStore={editor.options.parentStore.rootStore.skillTagStore}
          value={editor.tagIds}
          onChange={editor.setTagIds}
          placeholder="Select tags that apply to this skill"
          disabled={editor.disabled}
        />
      </FormGroup>
      <Button type="success" submit disabled={editor.disabled}>
        Save
      </Button>
      &nbsp;
      <Button onClick={() => editor.cancelEditing()}>Cancel</Button>
    </Form>
  </ShadowBox>
))

export default observer(SkillEditor)
