import { observable } from 'mobx'
import {
  serializable,
  snapshotable,
  serialize,
} from 'legend-builder/data/serialization'
import FilterEditor from '../../FilterEditor'
import { receiveErrors } from '../../../data/serverErrors'

@snapshotable
export default class ConjunctionEditor extends FilterEditor {
  static friendlyName = 'Conjunction'
  static friendlyDescription = 'Tests whether all/any of the subfilters pass.'

  @observable
  @serializable
  op = 'All'

  constructor(attrs, opts) {
    super(null, { ...opts, parse: false })
    this.subRules = this.createSubBuilder()
    this.set(attrs, opts)
  }

  rules = {
    subRules: [
      () => this.subRules.validateAll() || 'Some filters are invalid.',
    ],
  }

  parse({ rules, ...json }) {
    this.subRules.hydrate(rules)
    return json
  }

  receiveErrors(descriptor) {
    const { inner = {} } = descriptor
    receiveErrors(this, {
      subRules: inner.rules,
      op: inner.op,
    })
  }

  toJSON() {
    return {
      ...serialize(this),
      rules: this.subRules.toJSON(),
    }
  }
}
