import { decimalToNumber } from '@taxfyle/web-commons/lib/utils/grpcUtil'

const mapper = {
  fromDto: (variableValue) => {
    const result = {
      id: variableValue.getId(),
      label: variableValue.getLabel(),
      valueType: {},
    }

    switch (variableValue.getType()) {
      case 'Number':
        result.valueType.type = 'Number'
        result.value = decimalToNumber(
          variableValue.getValue().getNumericValue().getValue()
        )
        break
      case 'String':
        result.valueType.type = 'String'
        result.value = variableValue.getValue().getStringValue().getValue()
        break
      case 'Boolean': {
        result.valueType.type = 'Boolean'
        result.value = variableValue.getValue().getBooleanValue().getValue()
        break
      }
      case 'NumberList':
        result.valueType.type = 'List'
        result.valueType.itemType = { type: 'Number' }
        result.value = variableValue
          .getValue()
          .getNumericListValue()
          .getValueList()
          .map((v) => decimalToNumber(v))
        break
      case 'StringList':
        result.valueType.type = 'List'
        result.valueType.itemType = { type: 'String' }
        result.value = variableValue
          .getValue()
          .getStringListValue()
          .getValueList()
        break
      case 'BooleanList':
        result.valueType.type = 'List'
        result.valueType.itemType = { type: 'Boolean' }
        result.value = variableValue
          .getValue()
          .getBooleanListValue()
          .getValueList()
        break
    }

    return result
  },
}

export default mapper
