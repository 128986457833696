import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ErrorLabel from 'components/ErrorLabel'
import Button from 'components/Button'
import Icon from 'components/Icon'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import AmendmentEditor from './AmendmentEditor'
import { getUnitFormatter } from 'utils/unitFormatter'
import AmendmentsTable from 'components/AmendmentsTable'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import RequirePerms from 'iam/containers/RequirePerms'

import './AmendmentsDialog.sass'

@observer
export default class AmendmentsDialog extends React.Component {
  renderDialog() {
    const { store } = this.props
    if (!store.job) return null
    const formatUnit = getUnitFormatter(
      store.job.legend && store.job.legend.priceUnit
    )
    return (
      <div className="amendments-dialog">
        <DialogTitle>Amendments</DialogTitle>
        <DialogContent>
          {store.amendments.length === 0 ? (
            <NoAmendments />
          ) : (
            <AmendmentsTable amendments={store.amendments} store={store} />
          )}
          <RequirePerms allOf={['ADMIN_JOB_AMEND']}>
            <div className="has-spacing-top--less">
              {store.editor ? (
                store.confirmation ? (
                  <Confirmation
                    {...store.confirmation}
                    formatUnit={formatUnit}
                    onCancel={() => store.backToEditor()}
                    onSave={() => store.confirmAmendment()}
                  />
                ) : (
                  <AmendmentEditor editor={store.editor} />
                )
              ) : (
                <Button
                  type="primary"
                  iconLeft={<Icon material name="add" />}
                  onClick={() => store.newAmendment()}
                >
                  Add Amendment
                </Button>
              )}
            </div>
          </RequirePerms>
          <ErrorLabel>{store.error}</ErrorLabel>
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide}>Close</DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        className="amendments-modal"
        position="top"
      >
        <Dialog>
          {this.props.store.confirmAmendment.match({
            pending: () => <ContentLoading small />,
            rejected: (err) => <ContentError error={err} />,
            resolved: () => this.renderDialog(),
          })}
        </Dialog>
      </Modal>
    )
  }
}

let Confirmation = ({
  providerAmount,
  customerAmount,
  description,
  internalNote,
  visibility,
  onCancel,
  formatUnit,
  onSave,
}) => (
  <div className="amendment-confirmation message is-danger">
    <div className="message-header">Please read before continuing</div>
    <div className="message-body content">
      <p>
        So here's the deal; this is
        {visibility === 'PUBLIC' ? (
          <span>
            {' '}
            a <strong>public</strong> amendment (Pros and Clients can see it)
          </span>
        ) : (
          <span>
            {' '}
            an <strong>internal</strong> amendment (only HQ can see it)
          </span>
        )}
        {customerAmount === 0 ? (
          false
        ) : customerAmount > 0 ? (
          <span>
            , charging the Client{' '}
            <strong>{formatUnit(Math.abs(customerAmount))}</strong>
          </span>
        ) : (
          <span>
            , refunding the Client{' '}
            <strong>{formatUnit(Math.abs(customerAmount))}</strong>
          </span>
        )}
        {providerAmount === 0 ? (
          false
        ) : providerAmount > 0 ? (
          <span>
            , and the Pro will receive{' '}
            <strong>
              an additional {formatUnit(Math.abs(providerAmount))}
            </strong>
          </span>
        ) : (
          <span>
            , and the Pro will be{' '}
            <strong>penalized {formatUnit(Math.abs(providerAmount))}</strong>
          </span>
        )}
        .
      </p>
      <p>The description is as follows:</p>
      <blockquote>{description}</blockquote>
      <p>And the internal note is:</p>
      <blockquote>{internalNote}</blockquote>
      <p>Does this sound right?</p>
      <div>
        <button className="button is-danger" onClick={() => onSave()}>
          Yes, save it
        </button>
        &nbsp;
        <button className="button" onClick={() => onCancel()}>
          No, go back
        </button>
      </div>
    </div>
  </div>
)

Confirmation = observer(Confirmation)

const NoAmendments = () => (
  <div className="no-amendments content">
    <p>
      If the Client wants additional services, the Pro for reasons wants more
      earnings, or the Client wants a refund or reduced services, you can add
      amendments to the job here.
    </p>
    <p>
      <strong>IMPORTANT:</strong> any amendments that affect the Client's price
      will automatically affect the Pro's cut based on their cut rate. However,
      if you wish for the Pro to receive something in addition to their cut (or
      wish to penalize them), you can use the Pro Amount field.
    </p>
  </div>
)
