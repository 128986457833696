import React from 'react'
import cx from 'classnames'
import { isChildrenEmpty } from '../../utils/reactUtil'
import styles from './CardGrid.m.sass'

/**
 " Card Grid
 */
export function CardGrid({ children }) {
  return <div className={cx(styles.root)}>{children}</div>
}

/**
 " Card Grid Item
 */
export function CardGridItem({ children, big }) {
  if (isChildrenEmpty(children)) {
    return null
  }

  return (
    <div className={cx(styles.cardGridItem, big && styles.big)}>{children}</div>
  )
}
