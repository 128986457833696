import { action, observable, computed, runInAction } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, pattern, required } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'
import { stringLength } from 'utils/validx-validators'

export default class CreateUserModalState {
  @observable showing = false
  @observable email = ''
  @observable given_name = ''
  @observable family_name = ''
  @observable phone = ''
  @observable roleId = null
  @observable portal = 'CustomerPortal'
  @observable skills = []
  @observable team = null
  @observable teamRoleId = ''
  @observable completeRegistrationEmail = true
  @observable memberMetadata = {}

  validation = validationContext(this, {
    email: [
      required('Email is required'),
      pattern({ pattern: 'email' }),
      stringLength(0, 320),
    ],
    family_name: [required('Last name is required'), stringLength(2, 40)],
    given_name: [required('First name is required'), stringLength(2, 40)],
    phone: [required('Phone is required'), stringLength(0, 20)],
  })

  constructor({
    roleStore,
    invitationStore,
    flashMessageStore,
    sessionStore,
    memberStore,
    skillStore,
    teamStore,
    teamRoleStore,
  }) {
    this.memberStore = memberStore
    this.flashMessageStore = flashMessageStore
    this.roleStore = roleStore
    this.sessionStore = sessionStore
    this.skillStore = skillStore
    this.teamStore = teamStore
    this.teamRoleStore = teamRoleStore
    this.createUser = this.createUser.bind(this)
  }

  @computed
  get memberMetaDataFields() {
    return (
      this.sessionStore.workspace.custom_metadata_fields
        ?.member_metadata_fields || []
    ).filter((x) => x.active)
  }

  @computed
  get roles() {
    return this.roleStore
      .forWorkspace(this.sessionStore.workspace.id)
      .sort((a, b) => a.id - b.id)
  }

  @computed
  get teamRoles() {
    return this.teamRoleStore.forWorkspace(this.sessionStore.workspace.id)
  }

  @action
  onMetadataChange(fieldId, value) {
    this.memberMetadata[fieldId] = value
  }

  @action.bound
  show() {
    this.showing = true
    this.email = ''
    this.phone = ''
    this.family_name = ''
    this.given_name = ''
    this.activate()
    this.roleId = this.roles[0].id
    this.completeRegistrationEmail = true
    this.skills = []
    this.team = null
    this.portal = 'CustomerPortal'
  }

  selectDefaultTeamRole() {
    const guestRoleId = this.teamRoles?.find(
      (teamRole) => teamRole.name === 'Guest'
    )?.id
    this.selectTeamRoleId(guestRoleId || '')
  }

  @task
  async activate() {
    await this.roleStore.find()
    await this.teamRoleStore
      .fetchTeamRolesForWorkspace(this.sessionStore.workspace.id)
      .then(() => this.selectDefaultTeamRole())
  }

  @action.bound
  selectSkills(skills) {
    this.skills = skills
  }

  @action.bound
  selectTeam(team) {
    this.team = team
  }

  @action.bound
  selectTeamRoleId(teamRoleId) {
    this.teamRoleId = teamRoleId
  }

  @task.resolved
  async createUser() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const msg = this.flashMessageStore.create({
      inProgress: true,
      message: 'Creating User',
    })

    return this.memberStore
      .create({
        email: this.email,
        given_name: this.given_name,
        family_name: this.family_name,
        phone: this.phone,
        role_id: this.roleId,
        workspace_id: this.sessionStore.workspace.id,
        skill_ids: this.skills.map((s) => s.id),
        team_id: this.team?.id,
        team_role_id: this.team ? this.teamRoleId : undefined,
        portal: this.portal,
        complete_registration_email: this.completeRegistrationEmail,
        member_metadata: this.memberMetadata,
      })
      .then(() => {
        msg.done('User Created.').autoDismiss()
        runInAction(async () => {
          await this.memberStore.rootStore.userSearchStore.findUsers()
        })
        this.close()
      })
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
      })
  }

  @action.bound
  close() {
    this.showing = false
  }
}
