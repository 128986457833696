/**
 * Requires Provider data.
 */
export const PROVIDER = 'Provider'

/**
 * Job was submitted.
 */
export const SUBMITTED = 'Submitted'

/**
 * All scopes.
 */
export const ALL = [PROVIDER, SUBMITTED]
