import cx from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import styles from './FacetedSearch.m.sass'

/**
 * Faceted Search component.
 */
export default observer(function ScopingFacetedSearch({ store }) {
  const { facets, selectedFacets } = store

  return (
    <div
      className={cx(styles.facetedSearch, !store.showFilter && styles.hidden)}
    >
      <div className={styles.facet}>
        {facets.map((facet) => {
          if (facet.buckets.length === 0) {
            return null
          }
          return (
            <div className={styles.facet} key={facet.name}>
              <div className={styles.header}>{facet.name}</div>
              {facet.buckets.map((bucket) => {
                const value = bucket.value
                const selected = selectedFacets.get(facet.field) || []
                return (
                  <div className={styles.bucket} key={value}>
                    <input
                      type="checkbox"
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => store.selectFacet(facet.field, value)}
                      checked={selected.includes(value)}
                    />
                    {value} ({bucket.count})
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
})
