import { validatable } from '../mixins/validation'
import { observable, computed, reaction, action } from 'mobx'
import { serializable, serialize, snapshotable } from '../data/serialization'

/**
 * Multi-Job Flow config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class MultiJobFlowConfigEditor {
  @observable
  @serializable
  enabled = false

  @observable
  @serializable
  question_id = ''

  @observable
  @serializable
  trigger_answer = ''

  @observable
  @serializable
  final_answer = ''

  @observable
  @serializable
  previous_pro = false

  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  @computed
  get questions() {
    // We should only allow questions that are single select to be eligible
    // for controlling this multi-job flow
    return this.legendEditor.questions.filter(
      (q) => q.type === 'Select' && q.multi === false
    )
  }

  @computed
  get answerOptions() {
    if (!this.question_id) {
      return []
    }
    return this.questions.find((o) => o.id === this.question_id)?.options || []
  }

  @computed
  get finalAnswerOptions() {
    return this.answerOptions.filter((o) => o.id !== this.trigger_answer)
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor

    reaction(
      () => this.question_id,
      action(() => {
        this.trigger_answer = ''
        this.final_answer = ''
      })
    )
  }

  toJSON() {
    return serialize(this)
  }

  hydrate(multiJobFlowConfig) {
    if (!multiJobFlowConfig) {
      this.enabled = false
      this.question_id = ''
      this.trigger_answer = ''
      this.final_answer = ''
      this.previous_pro = false
      return
    }

    const {
      enabled = false,
      question_id = '',
      trigger_answer = '',
      final_answer = '',
      previous_pro = false,
    } = multiJobFlowConfig
    this.enabled = enabled
    this.question_id = question_id
    this.trigger_answer = trigger_answer
    this.final_answer = final_answer
    this.previous_pro = previous_pro
  }
}
