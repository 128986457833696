import { observable, action, intercept, observe } from 'mobx'
import { Model } from 'libx'
import { required } from 'validx'
import uniqueId from 'lodash/uniqueId'
import { validatable } from 'legend-builder/mixins/validation'
import {
  serialize,
  serializable,
  snapshotable,
} from 'legend-builder/data/serialization'
import { generateIdFromText } from 'utils/idUtil'
import { validateObject } from 'utils/validx-validators'
import { receiveErrors } from '../../../data/serverErrors'

/**
 * Used for Select questions.
 */
@snapshotable
@validatable({ liveValidation: true, liveIgnore: ['id'] })
export default class SelectOption extends Model {
  cid = uniqueId('select-option-')

  @observable
  @serializable
  id = ''

  @observable
  @serializable
  text = ''

  @serializable
  triggerWhen

  constructor(attrs, opts) {
    super()
    this.question = opts.question
    this.effect = this.question.createEffect()
    this.triggerWhen = this.question.createFiltersBuilder()
    this.set(attrs, opts)
    intercept(this, 'id', this._validateIdChange.bind(this))
    observe(this, 'text', this._setIdBasedOnText.bind(this))
  }

  rules = {
    id: [
      required(),
      // validId({ skipIfEmpty: true, allowStartNumber: true }),
      this._isNotDuplicateId.bind(this),
    ],
    text: [required('Text is required')],
    effect: [validateObject('Invalid effect')],
    triggerWhen: [validateObject('Invalid conditions')],
  }

  parse(json, opts) {
    const { triggerWhen, ...attrs } = this.effect.hydrate(json)
    this.triggerWhen.hydrate(triggerWhen)
    return {
      ...attrs,
    }
  }

  toJSON() {
    return {
      ...serialize(this),
      ...this.effect.toJSON(),
    }
  }

  receiveErrors(descriptor = {}) {
    const { id, text, triggerWhen, ...effectDescriptor } = descriptor.inner
    receiveErrors(this, { id, text, triggerWhen })
    this.effect.receiveErrors({ inner: effectDescriptor })
  }

  _isNotDuplicateId({ value }) {
    // If we just switched types, ID types are not consistent.
    // For the best experience, convert IDs to string when comparing.
    if (typeof value !== 'string') {
      value = '' + value
    }
    const upped = (value || '').toUpperCase()
    const stringifyId = (id) =>
      (typeof id === 'string' ? id : id + '').toUpperCase()
    return this.question.options.some(
      (o) => stringifyId(o.id) === upped && o !== this
    )
      ? 'Duplicate ID.'
      : true
  }

  _validateIdChange(change) {
    this.validation.clearErrors('id').validate(
      {
        id: change.newValue,
      },
      {
        id: this.rules.id,
      }
    )

    if (this.validation.errors.id) {
      return null
    }

    return change
  }

  @action
  _setIdBasedOnText() {
    if (!this.id) {
      const id = generateIdFromText('OPT', this.text)
      this.id = id
    }
  }
}
