import { validatable } from '../mixins/validation'
import { observable, computed } from 'mobx'
import { snapshotable } from '../data/serialization'
import { asNumber } from 'utils/numberUtil'
import { Types } from '../data/Type'
import RynoEditor from '../ryno-editor/RynoEditor'
import { func } from 'validx'
import { validateObject } from '../../utils/validx-validators'

/**
 * Billing config editor.
 * NOTE: At some point we wanna move the remaining billing props in here.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class BillingConfigEditor {
  rules = {
    upfrontChargeConfig: [
      validateObject('The upfront charge config is invalid'),
    ],
  }

  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.upfrontChargeConfig = new UpfrontChargeConfig(() => this.symbolTable)
  }

  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  @computed
  get symbolTable() {
    return this.legendEditor.getSymbolTableFor(this)
  }

  hydrate(billingConfig) {
    if (!billingConfig) {
      this.upfrontChargeConfig.hydrate({})
      return
    }

    const { upfront_charge_calculation = {} } = billingConfig
    this.upfrontChargeConfig.hydrate(upfront_charge_calculation)
  }

  toJSON() {
    return {
      upfront_charge_calculation: this.upfrontChargeConfig.toJSON(),
    }
  }

  receiveErrors(descriptor = {}) {
    this.upfrontChargeConfig.receiveErrors(
      descriptor.inner?.upfront_charge_calculation
    )
  }
}

@snapshotable
@validatable({ liveValidation: true })
class UpfrontChargeConfig {
  /**
   * If using dynamic upfront charge, this is the expression to compute it.
   */
  @observable
  expr

  /**
   * If using legacy upfront charge, this is the amount rate (upfront charge factor)
   */
  @observable
  amountRate = 0

  /**
   * Whether we are using dynamic upfront charge.
   */
  @observable
  dynamic = false

  rules = {
    expr: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
    amountRate: [
      ({ value, obj }) => {
        if (obj.dynamic) {
          return true
        }
        const v = asNumber(value)
        if (v >= 0 && v <= 1) {
          return true
        }
        return 'Upfront charge must be between 0 and 1.'
      },
    ],
  }

  constructor(getSymbolTable) {
    this.expr = new RynoEditor({
      getSymbolTable,
      required: () => this.dynamic,
      getNeededType: () => Types.number,
    })
  }

  hydrate(upfrontChargeCalculationConfig = {}) {
    this.dynamic = upfrontChargeCalculationConfig.type === 'Dynamic'
    if (this.dynamic) {
      this.expr.setValue(upfrontChargeCalculationConfig.expr, true)
    } else {
      this.amountRate = upfrontChargeCalculationConfig.amount_rate ?? 0
    }
  }

  toJSON() {
    return this.dynamic
      ? { type: 'Dynamic', expr: this.expr.toJSON() }
      : { type: 'Legacy', amount_rate: asNumber(this.amountRate) }
  }

  receiveErrors(descriptor) {
    const inner = descriptor?.inner ?? {}
    this.validation.addErrors({
      amountRate: inner?.amount_rate?.errors,
      expr: inner?.expr?.errors,
    })
  }
}
