/**
 * String length validator.
 * @param {number} min
 * @param {number} max
 * @param {string} msg
 */
import { startsWithValidCharacter } from './idUtil'

export function stringLength(min = 0, max = 0, msg) {
  const msgMin = 'Must be at least {min} characters long'
  const msgMax = 'Must be at most {max} characters long'
  const msgBoth = 'Must be at least {min} and at most {max} characters long'
  if (!msg) {
    if (min === 0 && max !== 0) {
      msg = msgMax
    } else if (min !== 0 && max === 0) {
      msg = msgMin
    } else {
      msg = msgBoth
    }
  }
  max = max === 0 ? Number.MAX_SAFE_INTEGER : max
  return function validateStringLength({ value }) {
    value = '' + value
    return (
      (value.length <= max && value.length >= min) ||
      _template(msg, { min, max })
    )
  }
}

/**
 * Valid IDs are alphanumeric with underscores.
 */
export function validId(opts = {}) {
  return function validIdValidator({ value }) {
    if (opts.skipIfEmpty && typeof value !== 'number' && !value) {
      return true
    }

    if (!startsWithValidCharacter(value)) {
      return 'IDs must start with a letter.'
    }
    return (
      /^[a-zA-Z0-9_]+$/.test(value) ||
      'IDs are alphanumeric and can contain underscores ("_")'
    )
  }
}

/**
 * Array length validator.
 * @param {number} min
 * @param {number} max
 * @param {string}} msg
 */
export function arrayLength(min = 0, max = 0, msg) {
  const msgMin = 'Must contain at least {min} elements'
  const msgMax = 'Must contain at most {max} elements'
  const msgBoth = 'Must contain at least {min} and at most {max} elements'
  if (!msg) {
    if (min === 0 && max !== 0) {
      msg = msgMax
    } else if (min !== 0 && max === 0) {
      msg = msgMin
    } else {
      msg = msgBoth
    }
  }
  max = max === 0 ? Number.MAX_SAFE_INTEGER : max
  return function validateStringLength({ value }) {
    return (
      (value.length <= max && value.length >= min) ||
      _template(msg, { min, max })
    )
  }
}

/**
 * Replaces {key} elements with `data[key]`.
 * @param {string} tpl
 * @param {Object} data
 */
function _template(tpl, data) {
  return Object.keys(data).reduce(
    (accum, key) => accum.replace(`{${key}}`, data[key]),
    tpl
  )
}

/**
 * Validates all elements in the array.
 * @param {*} message
 */
export function validateElements(message = 'There are invalid elements') {
  return function validateElementsValidator({ value }) {
    return (
      value
        .map((v) => {
          v.validation.reset()
          return v.validateAll()
        })
        .every(Boolean) || message
    )
  }
}

/**
 * Validates an object that has a `validateAll` and a `validation` context.
 *
 * @param {*} message
 */
export function validateObject(message = 'This field is invalid.') {
  return function validateObjectValidator({ value }) {
    if (value && value.validateAll) {
      value.validation.reset()
      value.validateAll()
      return value.validation.isValid || message
    }

    return true
  }
}

/**
 * Runs the given `rules` if the `condition` returns `true`.
 *
 * @param {*} condition a function given a context
 * @param {*} rules an array of rules
 */
export function vif(condition, rules) {
  return (context) => {
    if (condition(context)) {
      const validationResults = rules.map((r) => r(context))
      const found = validationResults.find((x) => x !== true)
      if (found === undefined) {
        return true
      }

      return found || 'One of the rules had a validation error.'
    }
    return true
  }
}

/**
 * Runs the rules in serial.
 * @param {*} rules
 */
export function serial(rules) {
  return (context) => {
    for (const rule of rules) {
      const result = rule(context)
      if (result !== true) {
        return result
      }
    }
    return true
  }
}

/**
 * Asserts that the getter returns clean diagnostics.
 *
 * @param {*} getter
 */
export function validateDiagnostics(getter, prefix = '') {
  return (context) => {
    const diagnostics = getter(context) || []
    const first = diagnostics[0]
    if (!first) {
      return true
    }
    return prefix + first.message
  }
}
