import { observable, action, computed, toJS } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, required } from 'validx'
import { FlashMessageTypes } from '@taxfyle/web-commons/lib/flash-messages/FlashMessageStore'

class JobUpdateState {
  @observable showing = false
  @observable error = ''
  @observable reason = ''
  @observable availableStates = []
  @observable job
  @observable name
  @observable billExternal = true
  @observable assignToPro = null

  validation = validationContext()

  constructor({ rootStore }) {
    this.rootStore = rootStore
    this.fetchQuestions = this.fetchQuestions.bind(this)
  }

  @action.bound
  init(job) {
    this.validation.reset()
    this.reason = ''

    this.job = job
    this.name = job.name
    this.save = this.save.bind(this)
    this.submit = this.submit.bind(this)
    this.billExternal = job.billingType && job.billingType === 'EXTERNAL'
    this.assignToPro = job.request_pro_id || null
    this.initialLoad()
  }

  @computed
  get questions() {
    return this.rootStore.questionStore.questionsForJob(this.job.id)
  }

  @computed
  get answers() {
    return this.questions.filter((q) => q.isAnswered)
  }

  @computed
  get hasBreaker() {
    return this.questions.some((q) => q.isBreaker)
  }

  get billUserAllowed() {
    return (
      this.rootStore.sessionStore.workspace.features.billing.enabled &&
      (!this.job || !this.job.legend.skipBilling)
    )
  }

  @task
  async initialLoad() {
    await this.maybeUpgradeLegend()
    await this.fetchQuestions()
  }

  @task
  async fetchQuestions() {
    await this.rootStore.questionStore.fetchQuestions(
      this.job.id,
      this.questions
    )
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @action.bound
  selectYears(years) {
    this.filingYears = years
  }

  @action.bound
  selectStates(states) {
    this.states = states
  }

  @action.bound
  setAnswers(answers) {
    for (const answer of answers) {
      const question = this.questions.find((q) => q.id === answer.id)
      question.setAnswer(answer.answer)
    }
  }

  @computed
  get busy() {
    return (
      this.fetchQuestions.pending || this.save.pending || this.submit.pending
    )
  }

  @task.resolved
  async save() {
    const rules = {
      reason: [
        required(
          'Please specify a reason for this update so the Client knows we are not just hustling them.'
        ),
      ],
    }
    if (this.job.status !== 'UNDER_CONSTRUCTION') {
      this.questions
        .filter((q) => !q.isDocument)
        .forEach((q) => {
          rules[q.id] = [
            () => (!q.isAnswered ? 'Please answer this question.' : true),
          ]
        })
    }

    if (!this.validation.reset().validate(this, rules).isValid) {
      return
    }

    await this.rootStore.jobStore.updateJob(this.job.id, {
      name: this.name,
      billing_type: this.billExternal ? 'EXTERNAL' : 'STRIPE',
      answers: toJS(this.answers.map((a) => ({ id: a.id, answer: a.answer }))),
      reason: this.reason,
      requested_pro_id:
        this.assignToPro && this.assignToPro.userPublicId !== '-1'
          ? this.assignToPro.userPublicId
          : null,
    })

    this.hide()
  }

  @task.resolved
  async submit() {
    const rules = {
      reason: [
        required(
          'Please specify a reason for this update so the Client knows we are not just hustling them.'
        ),
      ],
    }
    this.questions
      .filter((q) => !q.isDocument)
      .forEach((q) => {
        rules[q.id] = [
          () => (!q.isAnswered ? 'Please answer this question.' : true),
        ]
      })
    if (!this.validation.reset().validate(this, rules).isValid) {
      return
    }

    await this.rootStore.jobStore.updateJob(this.job.id, {
      name: this.name,
      status: 'UNCLAIMED',
      billing_type:
        // check the checkbox
        // or
        // if we are not allowed to bill the user default to EXTERNAL
        this.billExternal || !this.billUserAllowed ? 'EXTERNAL' : 'STRIPE',
      answers: toJS(this.answers.map((a) => ({ id: a.id, answer: a.answer }))),
      reason: this.reason,
      requested_pro_id:
        this.assignToPro && this.assignToPro.userPublicId !== '-1'
          ? this.assignToPro.userPublicId
          : null,
    })

    this.hide()
  }

  @action.bound
  async selectProvider(user) {
    this.assignToPro = user
  }

  /**
   * Attempts to upgrade the Legend version to the latest published version
   * if it is out of date for a draft job.
   * @returns {Promise<void>}
   */
  async maybeUpgradeLegend() {
    // If the Job is currently a draft, attempt to upgrade the Legend to the latest
    // version.
    if (this.job.status !== 'UNDER_CONSTRUCTION') {
      return
    }

    const latestPublishedLegend = await this.rootStore.publishedLegendStore.fetchPublishedLegendVersion(
      this.job.legendId,
      null,
      true
    )

    if (!this.showing) {
      // We closed the dialog while we were waiting, so don't do anything else.
      return
    }

    if (latestPublishedLegend.version <= this.job.legendVersion) {
      // It's up to date, nothing to do here.
      return
    }

    const msg = this.rootStore.flashMessageStore.create({
      type: FlashMessageTypes.DEFAULT,
      inProgress: true,
      message: 'There is a new version of the Legend; upgrading...',
    })
    try {
      await this.rootStore.jobStore.changeLegendVersion(
        this.job.id,
        latestPublishedLegend.id,
        latestPublishedLegend.version
      )
      msg.done('The Legend was upgraded!').autoDismiss(10000)
    } catch (err) {
      msg.forError(err)
      throw err
    }
  }
}

export default JobUpdateState
