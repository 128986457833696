import { Model } from 'libx'
import { observable, action, computed } from 'mobx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import StringList from 'legend-builder/string-list/StringList'
import { validatable } from '../mixins/validation'
import { receiveErrors } from '../data/serverErrors'
import RynoEditor from '../ryno-editor/RynoEditor'
import { Types } from '../data/Type'
import { func } from 'validx'
import { validateObject } from 'utils/validx-validators'
import MutatorsBuilder from './MutatorsBuilder'

/**
 * Effect editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class EffectEditor extends Model {
  @observable
  @serializable
  price

  @observable
  @serializable
  output = new StringList()

  @observable
  @serializable
  removes = new StringList()

  /**
   * Convenience property to persist the expanded state of the accordion
   * in the UI.
   */
  @observable
  expanded = false

  /**
   * Mutators.
   */
  @observable
  @serializable
  mutators

  rules = {
    price: [
      func(
        (ctx) => (ctx.value.value ? ctx.value.analyze() : true),
        'There are formula errors.'
      ),
    ],
    mutators: [validateObject('There are invalid mutators.')],
  }

  /**
   * Initializes the editor.
   */
  constructor(attrs, opts) {
    super()
    this.legendEditor = opts.legendEditor
    this.context = opts.context
    this.mutators = new MutatorsBuilder(this, this.legendEditor)
    this.price = new RynoEditor({
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => Types.number,
    })
    this.set(attrs, opts)
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Whether we can use outputs.
   * TODO: Remove once we are fully migrated away from legacy outputs
   */
  @computed
  get outputsEnabled() {
    // I don't condone this type of dependency in everyday code.
    return !this.legendEditor.legendOptions.specs_config?.usingDynamicSpecs
  }

  @computed
  get isNumericQuestion() {
    return this.context.type === 'Numeric'
  }

  @computed
  get isSelectQuestion() {
    return this.context.type === 'Select'
  }

  /**
   * The symbol table to use for the Ryno Semantic Analyzer.
   */
  @computed
  get symbolTable() {
    return this.legendEditor.getSymbolTableFor(this.context)
  }

  /**
   * Hydrates props, returns those that were not used.
   */
  hydrate({ price, output, removes, mutators, ...rest } = {}) {
    this.price.setValue(price, true)
    this.output.hydrate(output)
    this.removes.hydrate(removes)
    this.mutators.hydrate(mutators)
    return rest
  }

  /**
   * Serializes to json using the serializable attributes.
   */
  toJSON() {
    return serialize(this)
  }

  /**
   * Sets the expanded state.
   *
   * @param {boolean} value
   */
  @action.bound
  setExpanded(value) {
    this.expanded = value
  }

  /**
   * Receives errors.
   */
  receiveErrors(descriptor = {}) {
    receiveErrors(this, descriptor.inner)
  }

  /**
   * Sets the price.
   *
   * @param {*} price
   */
  @action.bound
  setPrice(price) {
    this.price = price
  }
}

/**
 * Creates a new effect editor.
 */
function create(data, opts) {
  return new EffectEditor(data, opts)
}

EffectEditor.create = create

/**
 * Converts JSON data to an effect editor instance.
 */
function fromJS(data, opts) {
  return EffectEditor.create(data, {
    ...opts,
    parse: true,
    stripUndefined: true,
  })
}

EffectEditor.fromJS = fromJS
