import React from 'react'
import { Helmet } from 'react-helmet-async'
import { observer } from 'mobx-react'
import Searchbar from 'components/Searchbar'
import ContentLoading from 'components/ContentLoading'
import ScreenProtector from 'iam/containers/ScreenProtector'
import InfiniteScrollZone from 'components/InfiniteScrollZone'
import EmptyState from 'components/EmptyState'
import UserList from './UserList'
import CircularIconButton from 'components/CircularIconButton'
import Icon from 'components/Icon'
import InviteUserModal from './InviteUserModal'
import CreateUserModal from './CreateUserModal'
import RequirePerms from 'iam/containers/RequirePerms'
import UserFacetedSearch from './UserFacetedSearch'
import cx from 'classnames'

import styles from './UserSearch.m.sass'

@observer
class UserSearch extends React.Component {
  constructor(props) {
    super(...arguments)
    this.store = props.rootStore.userSearchStore
  }

  componentDidMount() {
    this.store.activate()
  }

  renderUserList() {
    return (
      <>
        <div className={styles.usersContainer}>
          <UserList store={this.store} type={this.store.type} />
          <UserFacetedSearch store={this.store} />
        </div>
        <InfiniteScrollZone scrollProvider={this.store.scrollProvider}>
          {({ isDone }) => (isDone ? null : <ContentLoading smaller />)}
        </InfiniteScrollZone>
      </>
    )
  }

  renderNoUsers() {
    return (
      <EmptyState title="Awww maan..">
        Couldn't find any users that matches your query.
      </EmptyState>
    )
  }

  render() {
    const searchPlaceholder = 'Search for users'
    const pageTitle = 'Users'
    return (
      <ScreenProtector allOf={['ADMIN_USER_LIST']}>
        <div className={styles.root}>
          <Helmet title={pageTitle} />
          <div className={styles.fixed}>
            <Searchbar
              placeholder={searchPlaceholder[this.store.type]}
              value={this.store.searchText}
              onChange={this.store.changeSearchText}
            >
              <RequirePerms allOf={['ADMIN_USER_LIST']}>
                <CircularIconButton
                  small
                  info
                  onClick={this.store.toggleShowInactive}
                  style={{ marginRight: '10px' }}
                  className={cx(
                    !this.store.showInactive && styles.notSelectedButton
                  )}
                >
                  <Icon name="user-times" size={16} />
                </CircularIconButton>
              </RequirePerms>
              <RequirePerms allOf={['ADMIN_USER_CREATE']}>
                <CircularIconButton
                  small
                  info
                  onClick={this.store.inviteUserModalState.show}
                  style={{ marginRight: '10px' }}
                >
                  <Icon name="envelope" size={16} />
                </CircularIconButton>
              </RequirePerms>
              <RequirePerms allOf={['ADMIN_USER_CREATE']}>
                <CircularIconButton
                  small
                  info
                  onClick={this.store.createUserModalState.show}
                  style={{ marginRight: '10px' }}
                >
                  <Icon name="plus" size={16} />
                </CircularIconButton>
              </RequirePerms>
              <CircularIconButton small info onClick={this.store.toggleFilter}>
                <Icon name="filter" size={16} />
              </CircularIconButton>
            </Searchbar>
            <InviteUserModal state={this.store.inviteUserModalState} />
            <CreateUserModal state={this.store.createUserModalState} />
          </div>

          <div className={styles.content}>
            {this.store.showNoUsers ? (
              this.renderNoUsers()
            ) : this.store.scrollProvider.fetch.pending &&
              this.store.users.length === 0 ? (
              <ContentLoading />
            ) : (
              this.renderUserList()
            )}
          </div>
        </div>
      </ScreenProtector>
    )
  }
}

export default UserSearch
