import { computed, observable, action, reaction } from 'mobx'
import { task } from 'mobx-task'
import debounce from 'lodash/debounce'
import { debouncePromise } from '@taxfyle/web-commons/lib/utils/promiseUtil'
import { extractMessageFromError } from 'utils/errorUtil'
import { TabManager } from 'legend-builder/mixins/tab-manager'
import { Types } from 'legend-builder/data/Type'
import QuestionInputs from './QuestionInputs'
import SkillInputs from './SkillInputs'
import DataSourceInputs from './DataSourceInputs'
import { computeReviewPricing, computePrice } from './price-computer'
import { mostRelevantBundle } from './bundle-computer'

/**
 * Legend Previewer.
 */
export default class Previewer {
  outputTabs = new TabManager('COMPUTATIONS')

  /**
   * Result from last run.
   */
  @observable.ref computeResult = { type: 'Loading' }

  /**
   * Initializes the previewer.
   *
   * @param {*} legendEditor
   * @param {*} legendStore
   */
  constructor(legendEditor, legendStore) {
    this.legendEditor = legendEditor
    this.legendStore = legendStore
    this.compute = this.compute.wrap((f) => debouncePromise(f.bind(this)))
    this.scheduleCompute = debounce(() => this.compute(), 150, {
      leading: false,
      trailing: true,
    })
    this.questionInputs = new QuestionInputs(
      this.legendEditor,
      () => this.computeResult
    )
    this.skillInputs = new SkillInputs(this.legendEditor)
    this.dataSourceInputs = new DataSourceInputs(
      this.legendEditor,
      () => this.computeResult
    )
    // Whenever we start previewing, or when the legend concurrency version changes,
    // run the computation.
    reaction(
      () => [
        this.legendEditor.currentTab === 'PREVIEW',
        this.legendEditor.legendVersion?.concurrency_version,
      ],
      ([isPreviewing]) => isPreviewing && this.scheduleCompute()
    )
    // Whenever compute payload changes, schedule a compute.
    reaction(() => this.computePayload, this.scheduleCompute)
  }

  /**
   * The Work Request data.
   */
  @computed
  get workRequest() {
    return {
      date_submitted: new Date(),
      intake_responses: this.questionInputs.intakeResponses.slice(),
      task_responses: [],
    }
  }

  /**
   * The payload to pass to Compute.
   * Making it reactive so we schedule a compute when it changes.
   */
  @computed
  get computePayload() {
    return {
      work_request: this.workRequest,
      skills: this.skillInputs.mappedSkills,
      // We are reusing the questions editor for this, hence the mapping.
      routing_context_data_source_inputs: this.dataSourceInputs
        .mappedRoutingContextDataSourceInputs,
    }
  }

  /**
   * Whether both review and prep and review items from the config are in the inventory items.
   */
  @computed
  get isReviewAndPrepAndReviewItemsInvalid() {
    if (!this.legendEditor.legendOptions?.prep_and_review_config?.enabled) {
      return false
    }

    const reviewServiceItemId = this.legendEditor.legendOptions
      ?.prep_and_review_config?.review_service_item_id
    const prepAndReviewServiceItemId = this.legendEditor.legendOptions
      ?.prep_and_review_config?.prep_and_review_service_item_id
    const inventoryItems = this.computeResult.inventoryItems

    return (
      inventoryItems?.some((item) => item.id === reviewServiceItemId) &&
      inventoryItems?.some((item) => item.id === prepAndReviewServiceItemId)
    )
  }

  /**
   * Whether review item from the config is in the inventory items.
   */
  @computed
  get isReviewItemInInventoryItems() {
    if (!this.legendEditor.legendOptions?.prep_and_review_config?.enabled) {
      return false
    }

    const reviewServiceItemId = this.legendEditor.legendOptions
      ?.prep_and_review_config?.review_service_item_id
    const inventoryItems = this.computeResult.inventoryItems

    return inventoryItems?.some((item) => item.id === reviewServiceItemId)
  }

  /**
   * Activates the previewer, initializing it's state.
   */
  @action.bound
  activate() {
    this.questionInputs.activate()
    this.skillInputs.activate()
    this.dataSourceInputs.activate()
    this.compute()
  }

  /**
   * Sets the compute result.
   *
   * @param {*} value
   */
  @action.bound
  setComputeResult(value) {
    this.computeResult = value
  }

  /**
   * Goes back to the editor.
   */
  @action.bound
  goToEditor() {
    this.legendEditor.setTab('OPTIONS')
  }

  /**
   * Computes the legend using the current parameters.
   *
   * Returns an object describing the result.
   *
   * @param {CancellationToken} cancellationToken passed in by the `debouncePromise` wrapper
   */
  @task
  async compute(cancellationToken) {
    const { id, version } = this.legendEditor.legendVersion || {}
    if (!id) {
      this.setComputeResult({
        type: 'Loading',
      })
      return
    }

    try {
      const result = await this.legendStore.api.compute(
        id,
        version,
        this.computePayload,
        cancellationToken
      )

      if (cancellationToken.isCancelled) {
        return
      }

      const bundle = mostRelevantBundle(
        this.legendEditor.workspaceBundles,
        result.inventory_items.map((i) => i.id)
      )

      if (bundle === null) {
        const inventoryItems = (result.inventory_items || []).map((item) => {
          const editorItem = this.legendEditor.allowedWorkspaceInventoryItems.find(
            (i) => i.id === item.id
          )

          return {
            ...item,
            name: editorItem.name,
            shortId: editorItem.shortId,
            price: decimalToNumber(editorItem.price),
            proPrice: editorItem.proPrice
              ? decimalToNumber(editorItem.proPrice)
              : 0,
            courtesyQuantity:
              editorItem.courtesyAmount * (item.courtesyMultiplier ?? 1),
            quantity: item.quantity,
            priceType: editorItem.priceType,
          }
        })

        const inventoryItemPrices = computePrice(inventoryItems)
        const reviewPrices = computeReviewPricing(
          inventoryItems,
          this.legendEditor.allowedWorkspaceInventoryItems,
          this.legendEditor.legendOptions
        )

        this.setComputeResult({
          type: 'Success',
          questions: result.intake_questions ?? [],
          infoLines: result.info_lines ?? [],
          inventoryItems: inventoryItems,
          milestones: result.milestones ?? [],
          specs: result.specs ?? [],
          amount: result.amount,
          matchInspection: result.match_inspection,
          payoutAmount: result.payout_amount || 0,
          upfrontChargeAmount: result.upfront_charge_amount || 0,
          routingPlans: result.routing_plans,
          routingContextDataSources: result.routing_context_data_sources,
          variables: (result.variables || []).map((variable) => ({
            ...variable,
            valueType: Types.fromJS(variable.valueType),
          })),
          deadline: result.deadline ? new Date(result.deadline) : null,
          title: result.title,
          price: inventoryItemPrices.price,
          proPrice: inventoryItemPrices.proPrice,
          reviewPrice: reviewPrices.reviewPrice,
          reviewProPrice: reviewPrices.reviewProPrice,
        })
      } else {
        const nonBaseItemInventoryItemEngineOutputs = result.inventory_items.filter(
          (i) => i.id !== bundle.baseItem.inventoryItemId
        )

        const nonBaseItemInventoryItems = nonBaseItemInventoryItemEngineOutputs.map(
          (item) => {
            const editorItem = this.legendEditor.allowedWorkspaceInventoryItems.find(
              (i) => i.id === item.id
            )

            const itemInBundle = bundle.includedItemsList.find(
              (i) => i.inventoryItemId === item.id
            )

            const courtesyQuantity =
              itemInBundle !== undefined
                ? itemInBundle.includedQuantity
                : editorItem.courtesyAmount

            return {
              ...item,
              name: editorItem.name,
              shortId: editorItem.shortId,
              price: decimalToNumber(editorItem.price),
              proPrice: editorItem.proPrice
                ? decimalToNumber(editorItem.proPrice)
                : 0,
              courtesyQuantity:
                courtesyQuantity * (item.courtesyMultiplier ?? 1),
              quantity: item.quantity,
              priceType: editorItem.priceType,
            }
          }
        )

        const baseItemFromEngineOutput = result.inventory_items.find(
          (i) => i.id === bundle.baseItem.inventoryItemId
        )
        const baseItemFromEditor = this.legendEditor.allowedWorkspaceInventoryItems.find(
          (i) => i.id === bundle.baseItem.inventoryItemId
        )
        const baseItemInventoryItem = {
          ...baseItemFromEngineOutput,
          name: baseItemFromEditor.name,
          shortId: baseItemFromEditor.shortId,
          price: decimalToNumber(baseItemFromEditor.price),
          proPrice: baseItemFromEditor.proPrice
            ? decimalToNumber(baseItemFromEditor.proPrice)
            : 0,
          courtesyQuantity: baseItemFromEngineOutput.quantity,
          quantity: baseItemFromEngineOutput.quantity,
          priceType: baseItemFromEditor.priceType,
        }
        const inventoryItems = [
          baseItemInventoryItem,
          ...nonBaseItemInventoryItems,
        ]

        const bundlePrice =
          decimalToNumber(bundle.price) * baseItemInventoryItem.quantity
        const reviewPrices = computeReviewPricing(
          inventoryItems,
          this.legendEditor.allowedWorkspaceInventoryItems,
          this.legendEditor.legendOptions,
          bundle
        )

        const inventoryItemPrices = computePrice(inventoryItems, bundlePrice)

        this.setComputeResult({
          type: 'Success',
          questions: result.intake_questions ?? [],
          infoLines: result.info_lines ?? [],
          inventoryItems: inventoryItems,
          milestones: result.milestones ?? [],
          specs: result.specs ?? [],
          amount: result.amount,
          matchInspection: result.match_inspection,
          payoutAmount: result.payout_amount || 0,
          upfrontChargeAmount: result.upfront_charge_amount || 0,
          routingPlans: result.routing_plans,
          routingContextDataSources: result.routing_context_data_sources,
          variables: (result.variables || []).map((variable) => ({
            ...variable,
            valueType: Types.fromJS(variable.valueType),
          })),
          deadline: result.deadline ? new Date(result.deadline) : null,
          title: result.title,
          bundleName: bundle.name,
          price: inventoryItemPrices.price,
          proPrice: inventoryItemPrices.proPrice,
          reviewPrice: bundlePrice + reviewPrices.reviewPrice,
          reviewProPrice: reviewPrices.reviewProPrice,
        })
      }
    } catch (error) {
      this.setComputeResult(mapComputeError(error))
    }
  }
}

/**
 * Maps a compute error to an object.
 *
 * @param {*} error
 */
function mapComputeError(error) {
  if (!error.response || !error.response.data) {
    return {
      type: 'Error',
      message: extractMessageFromError(error),
      errors: [],
    }
  }

  const data = error.response.data
  return {
    type: 'Error',
    message: data.message,
    errors: data.errors || [],
  }
}

/**
 * Converts a decimal into a number.
 *
 * @param {*} decimal
 */
function decimalToNumber(decimal) {
  const nanoFactor = 1_000_000_000
  return decimal.units + decimal.nanos / nanoFactor
}
