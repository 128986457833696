import { Model } from 'libx'
import { validatable } from '../../mixins/validation'
import { serializable, serialize } from '../../data/serialization'
import { computed } from 'mobx'
import { receiveErrors } from '../../data/serverErrors'

/**
 * Routing context source editor.
 */
@validatable({ liveValidation: true })
export default class RoutingContextSourceEditor extends Model {
  /**
   * Type.
   */
  @serializable
  type

  constructor(attrs, opts) {
    super()
    this.legendEditor = opts.legendEditor
    this.set(attrs, opts)
  }

  /**
   * Whether the editor is disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  toJSON() {
    return serialize(this)
  }

  receiveErrors(descriptor) {
    receiveErrors(this, descriptor.inner)
  }
}

/**
 * Creates a Routing Context Source editor.
 */
RoutingContextSourceEditor.create = function create(attrs, opts) {
  const Type = require(`./${attrs.type}/${attrs.type}Editor.js`).default
  return new Type(attrs, opts)
}

/**
 * Parses from a JSON object.
 *
 * @param data
 * @param opts
 * @returns {*}
 */
RoutingContextSourceEditor.fromJS = function fromJS(data, opts) {
  return RoutingContextSourceEditor.create(data, {
    ...opts,
    parse: true,
    stripUndefined: true,
  })
}
