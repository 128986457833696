import { action, computed, observable, reaction } from 'mobx'
import orderBy from 'lodash/orderBy'

const SKILLS_KEY = 'previewer.selectedSkills'

/**
 * Skills part of the previewer.
 */
export default class SkillInputs {
  /**
   * The selected skill IDs.
   */
  @observable selectedSkillIds = []

  /**
   * Constructor.
   * @param legendEditor
   */
  constructor(legendEditor) {
    this.legendEditor = legendEditor
    // Whenever the skill IDs change, write them to storage.
    reaction(
      () => this.selectedSkillIds.slice(),
      (skillIds) =>
        this.legendEditor.storage.setItem(this._makeSkillsKey(), skillIds),
      { delay: 500 }
    )
  }

  /**
   * Mapped skills for the compute payload.
   */
  @computed
  get mappedSkills() {
    return this.selectedSkillIds
      .map((id) => {
        const skill = this.availableSkills.find((s) => s.id === id)
        return skill ? { name: skill.name, id: skill.id } : null
      })
      .filter(Boolean)
  }

  /**
   * The currently available skills.
   */
  @computed
  get availableSkills() {
    return orderBy(this.legendEditor.skills.slice(), ['name', 'asc'])
  }

  /**
   * Whether all skills are selected.
   * @returns {boolean}
   */
  @computed
  get allSkillsSelected() {
    return this.availableSkills.length === this.selectedSkillIds.length
  }

  /**
   * Activates the Skill Inputs.
   */
  @action.bound
  activate() {
    this.selectedSkillIds =
      this.legendEditor.storage.getItem(this._makeSkillsKey()) || []
  }

  /**
   * Toggles a skill selection.
   *
   * @param {*} skillId
   */
  @action.bound
  toggleSkillSelection(skillId) {
    if (this.selectedSkillIds.includes(skillId)) {
      this.selectedSkillIds.remove(skillId)
    } else {
      this.selectedSkillIds.push(skillId)
    }
  }

  /**
   * Selects all skills.
   */
  @action.bound
  selectAllSkills() {
    this.selectedSkillIds = this.availableSkills.map((s) => s.id)
  }

  /**
   * Clears all skill selections.
   */
  @action.bound
  clearAllSkills() {
    this.selectedSkillIds = []
  }

  /**
   * Makes a Skills key for local storage.
   */
  _makeSkillsKey() {
    return `${SKILLS_KEY}.${this.legendEditor.legendVersion.id}`
  }
}
