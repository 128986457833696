import {
  Dialog,
  DialogButton,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'components/Dialog'
import ErrorLabel from 'components/ErrorLabel'
import { DatePicker } from 'components/Form'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import React from 'react'
import './UpdateDeadlineDialog.m.sass'

@observer
class UpdateDeadlineDialog extends React.Component {
  componentDidMount() {
    this.props.store.init()
  }

  renderDialog() {
    const { store } = this.props
    return (
      <div className="updateDeadlineDialog">
        <DialogTitle>Update Deadline</DialogTitle>
        <DialogContent>
          <DatePicker
            type="datetime-local"
            model={store}
            field="deadline"
            label="Deadline"
            help="Update the deadline for this job."
          />
          <ErrorLabel>
            {store.updateDeadline.error && store.updateDeadline.error.message}
          </ErrorLabel>
          <br />
        </DialogContent>
        <DialogFooter>
          <DialogButton onClick={store.hide.bind(store)}>Cancel</DialogButton>
          <DialogButton
            danger
            onClick={store.updateDeadline.bind(store)}
            disabled={store.updateDeadline.pending}
          >
            Update
          </DialogButton>
        </DialogFooter>
      </div>
    )
  }

  render() {
    const { store } = this.props
    return (
      <Modal
        isOpened={store.showing}
        onRequestClose={store.hide}
        position="center"
      >
        <Dialog>{this.renderDialog()}</Dialog>
      </Modal>
    )
  }
}

export default UpdateDeadlineDialog
