import { validatable } from '../mixins/validation'
import { computed } from 'mobx'
import { task } from 'mobx-task'
import yaml from 'js-yaml'
import omit from 'lodash/omit'
import * as ducttape from 'legend-builder/data/ducttape'
import flattenDeep from 'lodash/flattenDeep'
import {
  GetManyInventoryItemsRequest,
  ImportInventoryItemsRequest,
  InventoryItem as InventoryItemProto,
  InventoryItemPriceType as InventoryItemPriceTypeProto,
  InventoryItemProVisibility as InventoryItemProVisibilityProto,
  InventoryItemSupportingItem as InventoryItemSupportingItemProto,
  InventoryItemTypeSpecification as InventoryItemTypeSpecificationProto,
} from '@taxfyle/api-internal/internal/inventory_item_pb'
import { Decimal } from '@taxfyle/api-internal/shared/types/decimal_pb'
import * as timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'

/**
 * State for the Transfer view.
 */
@validatable()
export default class TransferViewState {
  constructor(legendEditor) {
    this.legendEditor = legendEditor
    this.importVersion = this.importVersion.bind(this)
    this.exportVersion = this.exportVersion.bind(this)
    this.getManyInventoryItemsByIds = this.getManyInventoryItemsByIds.bind(this)
    this.importInventoryItems = this.importInventoryItems.bind(this)
  }

  @computed
  get editorDisabled() {
    return this.legendEditor.disabled || this.busy
  }

  @computed
  get busy() {
    return this.importVersion.pending || this.exportVersion.pending
  }

  @task.resolved
  async exportVersion() {
    // Gives us time to show a loading indicator.
    return new Promise((resolve) => setTimeout(resolve, 50)).then(async () => {
      const version = this.legendEditor.legendVersion
      const serialized = ducttape.toLegacyLegend(
        this.legendEditor.serialize({
          serializeSkillNames: true,
        })
      )

      const allQuestions = flattenDeep(
        serialized.stories.map((s) =>
          s?.questions?.map(ducttape.extractQuestions)
        )
      )
      const inventoryItemIdsInLegend = allQuestions
        .map((s) => s?.inventory_item_id)
        .filter((item) => item !== '' && item !== null && item !== undefined)

      const inventoryItemsInLegendResponse = await this.getManyInventoryItemsByIds(
        inventoryItemIdsInLegend
      )
      serialized.includedInventoryItems = inventoryItemsInLegendResponse.filter(
        (i) => i.item
      )
      const content = toYaml(serialized)
      const filename = `[${version.workspace.slug}] ${version.name} (V${version.version}).yaml`
      download(filename, 'application/yaml; charset=utf-8', content)
    })
  }

  @task.resolved
  async importVersion(files) {
    const file = files[0]
    const msg = this.legendEditor
      .showMessage({
        message: `Importing "${file.name}", please wait..`,
      })
      .progress()

    try {
      const content = await readFile(file)
        .then(readYaml)
        .then((x) =>
          omit(x, ['id', 'version', 'concurrency_version', 'workspace_id'])
        )
        .then(ducttape.fromLegacyLegend)

      const importResults = await this.importInventoryItems(
        content.includedInventoryItems.map((i) => i.item)
      )

      if (importResults !== null) {
        importResults.forEach((importResult) =>
          ducttape.replaceInventoryItems(content, importResult)
        )
      }

      // The content is basically serialized, so we can queue a persist and have it run right now.
      await this.legendEditor.queuePersist(content, true)
      const current = this.legendEditor.legendVersion
      await this.legendEditor.selectLegendVersion(current.id, current.version, {
        keepInitialState: true, // This lets us use Discard Changes.
        skipCache: true,
      })
      await this.legendEditor.fetchWorkspaceInventoryItems()
      msg.done(`Successfully imported "${file.name}"!`).autoDismiss()
    } catch (err) {
      msg.failed(
        'It seems this file is corrupted or not compatible with this version of Legend Builder.'
      )

      console.error(err)
    }
  }

  @task.resolved
  async getManyInventoryItemsByIds(ids) {
    const workspaceId = this.legendEditor.rootStore.sessionStore.workspace.id
    const request = new GetManyInventoryItemsRequest()
      .setWorkspaceId(workspaceId)
      .setIdsList(ids)
    const itemsResult = (
      await this.legendEditor.rootStore.api.inventoryItems.getAllInventoryItemsById(
        request
      )
    ).toObject()

    return itemsResult.inventoryItemsList
  }

  @task.resolved
  async importInventoryItems(inventoryItems) {
    const protos = inventoryItems.map(mapInventoryItemToProto)
    const workspaceId = this.legendEditor.rootStore.sessionStore.workspace.id
    const request = new ImportInventoryItemsRequest()
      .setItemsToImportList(protos)
      .setWorkspaceId(workspaceId)
    const result = await this.legendEditor.rootStore.api.inventoryItems.importInventoryItems(
      request
    )

    return result.toObject().newItemsList
  }
}

/**
 * Reads a file's content.
 *
 * @param {*} file
 */
async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = (err) => reject(err)
    reader.readAsText(file)
  })
}

/**
 * Reads yaml.
 */
function readYaml(content) {
  return yaml.safeLoad(content)
}

/**
 * Creates a YAML string from the specified object.
 *
 * @param {*} obj
 */
function toYaml(obj) {
  return yaml.safeDump(obj, {
    indent: 2,
    noArrayIndent: false,
    noCompatMode: false,
    noRefs: true,
    skipInvalid: true,
  })
}

/**
 * Trigger a download.
 *
 * @param {*} filename
 * @param {*} contentType
 * @param {*} text
 */
function download(filename, contentType, text) {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    `data:${contentType},${encodeURIComponent(text)}`
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

function mapInventoryItemToProto(i) {
  const dateCreated = i.dateCreated
  const dateArchived = i.dateArchived
  const archivedBy = i.archivedBy
  const updatedBy = i.updatedBy
  const dateUpdated = i.dateUpdated
  const price = i.price
  const proPrice = i.proPrice
  const workspaceId = i.workspaceId

  const proto = new InventoryItemProto()
    .setId(i.id)
    .setRootId(i.rootId)
    .setArchived(i.archived)
    .setProVisibility(mapInventoryItemProVisibilityToProto(i.proVisibility))
    .setName(i.name)
    .setDescription(i.description)
    .setVersion(i.version)
    .setCourtesyAmount(i.courtesyAmount)
    .setPrice(new Decimal().setUnits(price.units).setNanos(price.nanos))
    .setProPrice(
      proPrice != null
        ? new Decimal().setUnits(proPrice.units).setNanos(proPrice.nanos)
        : new Decimal().setUnits(0).setNanos(0)
    )
    .setCreatedBy(i.createdBy)
    .setDateCreated(
      new timestamp_pb.Timestamp()
        .setSeconds(dateCreated.seconds)
        .setNanos(dateCreated.nanos)
    )
    .setShortId(i.shortId)
    .setPriceType(mapInventoryItemPriceTypeToProto(i.priceType))
    .setVersionId(i.versionId)
    .setSupportingItemsList(i.supportingItems?.map(mapSupportingItemToProto))

  if (i.typeSpecification != null) {
    proto.setTypeSpecification(
      mapInventoryItemTypeSpecificationToProto(i.typeSpecification)
    )
  }

  if (dateArchived != null && archivedBy != null) {
    proto
      .setDateArchived(
        new timestamp_pb.Timestamp()
          .setSeconds(dateArchived.seconds)
          .setNanos(dateArchived.nanos)
      )
      .setArchivedBy(new StringValue().setValue(archivedBy.value))
  }

  if (dateUpdated != null && updatedBy != null) {
    proto
      .setDateUpdated(
        new timestamp_pb.Timestamp()
          .setSeconds(dateUpdated.seconds)
          .setNanos(dateUpdated.nanos)
      )
      .setUpdatedBy(new StringValue().setValue(updatedBy.value))
  }

  if (workspaceId !== '' && workspaceId !== null && workspaceId !== undefined) {
    proto.setWorkspaceId(workspaceId)
  }

  return proto
}

function mapInventoryItemPriceTypeToProto(priceType) {
  switch (priceType) {
    case 0:
      return InventoryItemPriceTypeProto.FLAT
    case 1:
      return InventoryItemPriceTypeProto.FEE
    case 2:
      return InventoryItemPriceTypeProto.MARKUP
    case 3:
      return InventoryItemPriceTypeProto.MARKDOWN
  }
}

function mapInventoryItemProVisibilityToProto(proVisibility) {
  switch (proVisibility) {
    case 0:
      return InventoryItemProVisibilityProto.HIDDEN
    case 1:
      return InventoryItemProVisibilityProto.VISIBLE
  }
}

function mapSupportingItemToProto(item) {
  const supportingItem = new InventoryItemSupportingItemProto()
  const spec = new InventoryItemSupportingItemProto.Spec()

  switch (item.spec.type) {
    case 0:
      spec.setDocument(
        new InventoryItemSupportingItemProto.Spec.Document().setTypeShortId(
          item.spec.documentType.shortId
        )
      )
      break
    default:
      throw new Error("Unsupported supporting item's spec type")
  }

  supportingItem.setSpec(spec)
  supportingItem.setRequired(item.required)
  return supportingItem
}

function mapInventoryItemTypeSpecificationToProto(item) {
  const proto = new InventoryItemTypeSpecificationProto()
  const protoSpec = new InventoryItemTypeSpecificationProto.TypeOptions()

  const supportedTypeSpec = {
    pb_default: () => {
      if (item.spec.pb_default) {
        return protoSpec.setDefault(
          new InventoryItemTypeSpecificationProto.TypeOptions().setDefault(null)
        )
      }
    },
    bookkeeping: () => {
      if (item.spec.bookkeeping) {
        return protoSpec.setBookkeeping(
          new InventoryItemTypeSpecificationProto.TypeOptions.Bookkeeping().setRecurring(
            item.spec.bookkeeping.recurring
          )
        )
      }
    },
    consultation: () => {
      if (item.spec.consultation) {
        return protoSpec.setConsultation(
          new InventoryItemTypeSpecificationProto.TypeOptions.Consultation().setLengthInMinutes(
            item.spec.consultation.lengthInMinutes
          )
        )
      }
    },
    tax: () => {
      if (item.spec.tax) {
        return protoSpec.setTax(
          new InventoryItemTypeSpecificationProto.TypeOptions.Tax()
            .setReviewPrice(
              new Decimal()
                .setUnits(item.spec.tax.reviewPrice.units)
                .setNanos(item.spec.tax.reviewPrice.nanos)
            )
            .setReviewProPrice(
              new Decimal()
                .setUnits(item.spec.tax.reviewProPrice.units)
                .setNanos(item.spec.tax.reviewProPrice.nanos)
            )
        )
      }
    },
  }

  const typeSpec = Object.keys(item.spec).find(
    (type) => supportedTypeSpec[type]
  )
  if (!typeSpec) {
    throw new Error('Invalid inventory item type specification provided')
  }

  const spec = supportedTypeSpec[typeSpec]

  proto.setSpec(spec())
  return proto
}
