import { Model, Store } from 'libx'
import { collection } from '@taxfyle/web-commons/lib/utils/collection'
import moment from 'moment'
import { observable } from 'mobx'

export default class MessageBankStore extends Store {
  constructor() {
    super(...arguments)

    this.messageBanks = collection({
      create: () =>
        new MessageBank(
          this.rootStore.memberStore.setAndFetch.bind(
            this.rootStore.memberStore
          )
        ),
    })
  }

  get api() {
    return this.rootStore.api.messageBank
  }

  /**
   * Gets the message bank for the given workspace.
   *
   * @param {string} workspaceId
   * @returns
   */
  getForWorkspace(workspaceId) {
    return (
      this.messageBanks.find((mb) => mb.workspaceId === workspaceId) ?? null
    )
  }

  /**
   * Returns the workspace's message bank.
   *
   * @param workspaceId
   * @returns
   */
  async fetchWorkspaceMessageBank(workspaceId) {
    return this.api.findForWorkspace(workspaceId).then(this.messageBanks.set)
  }

  /**
   * Creates a message bank for the given workspace.
   * @param {string} workspaceId
   */
  async createForWorkspace(workspaceId) {
    return this.api.createMessageBank(workspaceId).then(this.messageBanks.set)
  }

  /**
   * Gets the replies for the given message bank.
   * @param {string} messageBankId
   * @returns
   */
  async fetchMessageBankReplies(messageBankId) {
    const messageBank = this.messageBanks.get(messageBankId)
    const replies = await this.api
      .getReplies(messageBankId)
      .then((data) => data.data)

    if (messageBank && replies) {
      return messageBank.replies.set(replies)
    }

    return []
  }

  /**
   * Creates a reply in a message bank
   *
   * @param {string} messageBankId
   * @param {string} title
   * @param {string} content
   * @returns
   */
  async createReply(messageBankId, title, content) {
    return this.api
      .createReply({
        message_bank_id: messageBankId,
        title,
        content,
      })
      .then((reply) => this.messageBanks.get(messageBankId).replies.set(reply))
  }

  /**
   * Updates a reply in a message bank
   * @param {string} messageBankId
   * @param {string} replyId
   * @param {string} title
   * @param {string} content
   * @returns
   */
  async updateReply(messageBankId, id, title, content) {
    return this.api
      .updateReply(id, {
        title,
        content,
      })
      .then((reply) => this.messageBanks.get(messageBankId).replies.set(reply))
  }

  /**
   * Deletes a reply in a message bank
   * @param {string} messageBankId
   * @param {string} replyId
   * @returns
   */
  async deleteReply(messageBankId, replyId) {
    return this.api
      .deleteReply(replyId)
      .then(() => this.messageBanks.get(messageBankId).replies.remove(replyId))
  }
}

export class MessageBank extends Model {
  @observable id = ''
  @observable workspaceId = ''
  @observable dateCreated = null
  @observable dateModified = null

  constructor(primeMember) {
    super()

    this.primeMember = primeMember
  }

  replies = collection({
    create: () => new MessageBankReply(this, this.primeMember),
  })

  parse(json) {
    return {
      id: json.id,
      workspaceId: json.workspace_id,
      dateCreated: moment(json.date_created || this.dateCreated),
      dateModified: moment(json.date_modified || this.dateModified),
    }
  }
}

export class MessageBankReply extends Model {
  @observable id = ''
  @observable title = ''
  @observable content = ''
  @observable authorId = ''
  @observable authorPublicId = ''
  @observable dateCreated = null
  @observable dateModified = null
  @observable member = null

  constructor(messageBank, primeMember) {
    super()
    this.messageBank = messageBank
    this.primeMember = primeMember
  }

  parse(json) {
    return {
      id: json.id,
      title: json.title,
      content: json.content,
      authorId: json.author_id,
      authorPublicId: json.author_public_id,
      dateCreated: moment(json.date_created || this.dateCreated),
      dateModified: moment(json.date_modified || this.dateModified),
      member: this.primeMember({
        user_id: json.author_id,
        user_public_id: json.author_public_id,
        workspace_id: this.messageBank.workspaceId,
      }),
    }
  }
}
