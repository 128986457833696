import deburr from 'lodash/deburr'
import { CharacterCodes } from '@taxfyle/ryno/lib/types/character-codes'

export function generateIdFromText(prefix, text) {
  if (!text) {
    return ''
  }

  const newId = deburr(text)
    .toUpperCase()
    .replace(/\s/g, '_')
    .replace(/[',]/g, '')
    .replace(/[^a-zA-Z0-9_]/g, '_')
    .trim()
    .replace(/_{3,}/, '__')
    .replace(/^[_]+/, '')
    .replace(/[_]+$/, '')

  if (!startsWithValidCharacter(newId)) {
    return `${prefix}_${newId}`
  }

  return newId
}

/**
 * Specifically checks if the ID starts with a valid character.
 *
 * @param {string} id The ID.
 *  MUST be set and be a valid string as we don't check it!
 */
export function startsWithValidCharacter(id) {
  const code = id.charCodeAt(0)
  // NOTE: We purposely do not check for underscore despite it being
  // a valid starting character because we don't want people to do that.
  return (
    (code >= CharacterCodes.A && code <= CharacterCodes.Z) ||
    (code >= CharacterCodes.a && code <= CharacterCodes.a)
  )
}
