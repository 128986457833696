import { SwitchCheckbox } from 'components/SwitchCheckbox'
import ValueInput from 'legend-builder/components/ValueInput'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import without from 'lodash/without'
import { action, toJS } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'

import {
  CheckboxControl,
  CheckList,
  CheckListItem,
  DurationControl,
  Form,
  FormGroup,
  InputControl,
  SelectControl,
  MultiSelectControl,
  SearchableSelectControl,
} from '../Controls'
import styles from './ContainerControls.m.sass'

/**
 * Uses Context to pass the model to the child container controls.
 */
@observer
export class FormContainer extends React.Component {
  render() {
    return <Form {...this.props} />
  }
}

export const FormGroupContainer = observer(function FormGroupContainer({
  model,
  field,
  ...rest
}) {
  return (
    <FormGroup
      errors={
        model.validation &&
        model.validation.errors &&
        model.validation.errors[field]
      }
      {...rest}
    />
  )
})

@observer
export class SelectContainer extends React.Component {
  @action.bound
  change(value) {
    this.props.model[this.props.field] = value
  }

  render() {
    const {
      placeholder,
      field,
      model,
      multi,
      fg,
      ...rest
    } = extractFormGroupProps(this.props)

    return (
      <FormGroupContainer {...fg}>
        {multi ? (
          <MultiSelectControl
            value={model[field]}
            onChange={this.change}
            placeholder={placeholder}
            {...rest}
          />
        ) : (
          <SelectControl
            value={model[field]}
            onChange={this.change}
            placeholder={placeholder}
            {...rest}
          />
        )}
      </FormGroupContainer>
    )
  }
}

export const SearchableSelectContainer = observer(
  ({ model, field, items, onChange, placeholder, disabled, label, help }) => {
    return (
      <FormGroupContainer model={model} field={field} label={label} help={help}>
        <SearchableSelectControl
          value={model[field]}
          items={items}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      </FormGroupContainer>
    )
  }
)

@observer
export class QuestionSelectContainer extends React.Component {
  render() {
    const { questions, ...rest } = this.props
    const items = questions.filter((x) => x.id).map((q) => [q.id, q.id])
    return <SelectContainer placeholder="---" {...rest} items={items} />
  }
}

@observer
export class AnswerInputContainer extends React.Component {
  render() {
    const { question, ...rest } = this.props
    const na = <InputControl value="Not applicable" disabled />
    if (!question) {
      return na
    }
    const opts = question.answerOptions
    if (!opts) {
      return na
    }
    switch (opts.type) {
      case 'text':
        return (
          <InputContainer
            {...rest}
            value={rest.value || ''}
            placeholder={opts.placeholder || 'Enter a value'}
          />
        )
      case 'select': {
        const answers = opts.options
        const items = (answers && answers.map((a) => [a.value, a.text])) || []
        return <SelectContainer {...rest} items={items} placeholder="---" />
      }
      default:
        return na
    }
  }
}

@observer
export class InputContainer extends React.Component {
  @action.bound
  change(e) {
    this.props.model[this.props.field] = e.target.value
  }

  render() {
    const { items, fg, field, model, ...rest } = extractFormGroupProps(
      this.props
    )
    const value = model[field]
    return (
      <FormGroupContainer {...fg}>
        <InputControl
          value={value === undefined || value === null ? '' : value}
          onChange={this.change}
          {...rest}
        />
      </FormGroupContainer>
    )
  }
}

@observer
export class CheckboxContainer extends React.Component {
  @action.bound
  change(e) {
    this.props.model[this.props.field] = e.target.checked
  }

  render() {
    const { fg, field, model, ...rest } = extractFormGroupProps(this.props)
    return (
      <FormGroupContainer {...fg} inline before>
        <CheckboxControl
          checked={!!model[field]}
          onChange={this.change}
          {...rest}
        />
      </FormGroupContainer>
    )
  }
}

@observer
export class SwitchCheckboxContainer extends React.Component {
  @action.bound
  change(field) {
    this.props.model[field] = !this.props.model[field]
  }

  render() {
    const { fg, model, field, ...rest } = extractFormGroupProps(this.props)
    return (
      <FormGroupContainer {...fg} inlineSwitch>
        {Array.isArray(field) ? (
          field.map((f) => (
            <SwitchCheckbox
              key={f}
              success
              trueLabel="On"
              falseLabel="Off"
              value={model[f]}
              className={styles.spaceLeft}
              onChange={this.change.bind(this, f)}
              {...rest}
            />
          ))
        ) : (
          <SwitchCheckbox
            success
            trueLabel="On"
            falseLabel="Off"
            value={model[field]}
            onChange={this.change.bind(this, field)}
            {...rest}
          />
        )}
      </FormGroupContainer>
    )
  }
}

@observer
export class CheckListContainer extends React.Component {
  onClick(id) {
    const { model, field } = this.props
    const value = model[field]
    const idx = value.indexOf(id)
    if (idx !== -1) {
      this.update(without(toJS(value), id))
    } else {
      this.update([...toJS(value), id])
    }
  }

  update = action((newVal) => {
    this.props.model[this.props.field] = newVal
  })

  render() {
    const {
      fg,
      field,
      model,
      items,
      disabled,
      ...rest
    } = extractFormGroupProps(this.props)
    const value = model[field]
    return (
      <FormGroupContainer {...fg}>
        <CheckList {...rest}>
          {items.map(([id, label]) => (
            <CheckListItem
              key={id}
              disabled={disabled}
              checked={value.indexOf(id) > -1}
              onClick={this.onClick.bind(this, id)}
            >
              {label || id}
            </CheckListItem>
          ))}
        </CheckList>
      </FormGroupContainer>
    )
  }
}

@observer
export class ValueInputContainer extends React.Component {
  update = action((newVal) => {
    this.props.model[this.props.field] = newVal
  })

  render() {
    const { fg, field, model, ...rest } = extractFormGroupProps(this.props)
    const value = model[field]
    return (
      <FormGroupContainer {...fg}>
        <ValueInput value={value} onChange={this.update} {...rest} />
      </FormGroupContainer>
    )
  }
}

@observer
export class DurationContainer extends React.Component {
  update = action((newVal) => {
    this.props.model[this.props.field] = newVal
  })

  render() {
    const { fg, field, model, ...rest } = extractFormGroupProps(this.props)
    const value = model[field]
    return (
      <FormGroupContainer {...fg}>
        <DurationControl value={value} onChange={this.update} {...rest} />
      </FormGroupContainer>
    )
  }
}

function extractFormGroupProps(props) {
  const fields = [
    'label',
    'help',
    'stretch',
    'inline',
    'before',
    'errors',
    'padless',
    'labelAsSpan',
  ]

  const fg = pick(props, [...fields, 'model', 'field'])
  const rest = omit(props, fields)
  return { fg, ...rest }
}
