import { observable, action } from 'mobx'
import { snapshotable, serializable } from 'legend-builder/data/serialization'
import { Types } from 'legend-builder/data/Type'
import RynoEditor from 'legend-builder/ryno-editor/RynoEditor'
import { isListType } from '@taxfyle/ryno'
import { validateObject } from 'utils/validx-validators'
import { func } from 'validx'
import MutatorEditor from '../../MutatorEditor'

/**
 * List Mutator.
 */
@snapshotable
export default class ListMutatorEditor extends MutatorEditor {
  type = 'List'

  /**
   * The operator. Can be `Add` or `Remove`.
   */
  @observable
  @serializable
  op = 'Add'

  /**
   * The formula
   */
  @observable
  @serializable
  expr

  /**
   * Whether we are adding one or many items.
   */
  @observable
  @serializable
  multi = false

  rules = {
    target: [
      (ctx) => {
        return true
      },
      validateObject('Please select the variable you wish to change.'),
    ],
    expr: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
  }

  /**
   * Constructor.
   * @param {*} attrs
   * @param {*} opts
   */
  constructor(attrs, opts) {
    super(null, opts)
    this.expr = new RynoEditor({
      required: true,
      getSymbolTable: () => this.effect.symbolTable,
      getNeededType: () =>
        this.multi
          ? this.target.valueType
          : (isListType(this.target.valueType) &&
              this.target.valueType.elementType) ||
            Types.list(Types.nothing),
    })
    this.set(attrs, opts)
    // Validate the symbol ref when the symbol changes
    this.target.bubbleErrorsTo(this, 'target')
  }

  isCompatibleVariableType(type, valueType) {
    return type === 'Variable' && isListType(valueType)
  }

  /**
   * Sets the operator.
   * @param {*} op
   */
  @action.bound
  setOperator(op) {
    this.op = op
  }

  /**
   * Sets the multi field.
   * @param {*} multi
   */
  @action.bound
  setMulti(multi) {
    this.multi = multi
  }

  /**
   * Parses from raw JS.
   */
  parse({ expr, ...rest }) {
    this.expr.setValue(expr)
    return super.parse(rest)
  }
}
