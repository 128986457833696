import moment from 'moment'
import replaceAll from 'underscore.string/replaceAll'

function valueIsEmpty(value) {
  return (
    value === false || value === undefined || value === null || value === ''
  )
}

const _dateValidatorMessages = {
  isBefore: 'Must be before {date}',
  isAfter: 'Must be after {date}',
  isSameOrAfter: 'Must be the same as or after {date}',
  isSameOrBefore: 'Must be the same as or before {date}',
}

/**
 * Makes a date validator that uses moment methods.
 *
 * @param  {string} method
 * Moment method name.
 *
 * @return {Function}
 * The validator factory function
 */
function makeDateValidator(method) {
  return function dateValidator(other, msg) {
    return function validateDate(opts) {
      let value = opts.value

      // Not our job
      if (valueIsEmpty(value) || valueIsEmpty(other)) {
        return true
      }

      if (typeof other === 'string') {
        // Model property
        other = opts.obj[other]

        // Still not our job.
        if (valueIsEmpty(other)) {
          return true
        }
      } else {
        // Moment
        other = moment(other)
      }

      value = moment(value)
      if (value[method](other)) {
        return true
      }

      let message = msg || _dateValidatorMessages[method]
      message = replaceAll(message, '{date}', other.format('LL LT'))
      return message
    }
  }
}

export const isBefore = makeDateValidator('isBefore')
export const isSameOrBefore = makeDateValidator('isSameOrBefore')
export const isAfter = makeDateValidator('isAfter')
export const isSameOrAfter = makeDateValidator('isSameOrAfter')
export const isSame = makeDateValidator('isSame')
