import { moveItem } from 'mobx-utils'

/**
 * Moves an item in an ObservableArray from one position to another.
 *
 * @param {ObservableArray<any>} target
 * @param {Number} fromIndex
 * @param {Number} toIndex
 */
export function move(target, fromIndex, toIndex) {
  return moveItem(target, fromIndex, toIndex)
}
