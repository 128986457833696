import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import styles from './InfoLine.m.sass'

const InfoLine = ({ infoLine }) => {
  return (
    <div className={cx(styles.root)}>
      <div className={styles.title}>{infoLine.title}</div>
      <div className={styles.content}>{infoLine.content}</div>
    </div>
  )
}

export default observer(InfoLine)
