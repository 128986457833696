import { validatable } from '../mixins/validation'
import { observable } from 'mobx'
import { serializable, serialize, snapshotable } from '../data/serialization'
import { receiveErrors } from '../data/serverErrors'

/**
 * Purge config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class PurgeConfigEditor {
  @observable
  @serializable
  enabled = false

  @observable
  @serializable
  purge_after = 0

  /**
   * Since there's currently only one prop (enabled) for the Documents purge config,
   * we just put it on here and rewrite it when we (de)serialize.
   */
  @observable
  @serializable(
    (enabled) => ({
      enabled: true /* TODO: dont hardcode when we add more purgeables */,
    }),
    { name: 'documents' }
  )
  purgeDocuments = false

  constructor(legendEditor) {
    this.legendEditor = legendEditor
  }

  hydrate(cfg) {
    cfg = cfg || {}
    this.enabled = cfg.enabled || false
    this.purge_after = cfg.purge_after || 0
    this.purgeDocuments = (cfg.documents && cfg.documents.enabled) || false
  }

  toJSON() {
    return serialize(this)
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    const { documents, ...rest } = inner
    receiveErrors(this, {
      ...rest,
      purgeDocuments: documents && documents.inner.enabled,
    })
  }
}
