/**
 * HQ's supported engine version.
 * @type {string}
 */
export const SUPPORTED_ENGINE_VERSION = '1.15.1'

/**
 * Params to add to a query string.
 */
export const ENGINE_VERSION_PARAMS = {
  supported_engine_version: SUPPORTED_ENGINE_VERSION,
}
