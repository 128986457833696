import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import { Select } from 'components/Form'

@observer
export default class AddToTeamModal extends React.Component {
  componentDidMount() {
    this.store.hide()
  }

  get store() {
    return this.props.store
  }

  render() {
    return (
      <Modal
        onRequestClose={() => this.store.hide()}
        isOpened={this.store.showing}
        position="top"
      >
        <Dialog>
          <DialogTitle>Add User To Team</DialogTitle>
          <DialogContent>{this.renderEditor()}</DialogContent>
          <DialogFooter style={{ marginTop: -20 }}>
            <DialogButton onClick={() => this.store.hide()}>
              Cancel
            </DialogButton>
            <DialogButton primary onClick={() => this.store.save()}>
              Save
            </DialogButton>
          </DialogFooter>
        </Dialog>
      </Modal>
    )
  }

  renderEditor() {
    const store = this.store

    if (!store.member) {
      return null
    }
    return (
      <div>
        <Select
          label="Team"
          help="To which team do you want to add the User?"
          value={store.teamId}
          onChange={(e) => store.selectTeam(e.target.value)}
        >
          <option key={0} value=""></option>
          {this.store.availableTeams.map((t) => (
            <option key={t.id} value={t.id}>
              {t.name}
            </option>
          ))}
        </Select>
        <Select
          label="Team Role"
          disabled={store.teamId === ''}
          field="teamRoleId"
          model={store}
          help="Which role should this user have?"
        >
          {this.store.availableTeamRoles.map((t) => (
            <option key={t.id} value={t.id}>
              {t.name}
            </option>
          ))}
        </Select>
      </div>
    )
  }
}
