import { observable, action } from 'mobx'
import WorkspaceEditorState from '../../containers/WorkspaceEditor/WorkspaceEditorState'
import { task } from 'mobx-task'

export default class WorkspaceDetailsState {
  type = 'view'

  @observable
  workspace

  constructor({ workspace, rootStore, workspaceManagementStore }) {
    this.workspace = workspace
    this.rootStore = rootStore

    this.editor = new WorkspaceEditorState({
      rootStore,
      onSaved: this.onSaved,
      onSecretSaved: this.onSecretSaved,
      onSecretDeleted: this.onSecretDeleted,
      newWorkspace: workspaceManagementStore.newWorkspace,
    })
  }

  @task.resolved
  async activate() {
    await this.rootStore.workspaceStore.fetchWorkspace(this.workspace.id, {
      include_secrets: true,
      include_domains: true,
    })

    await this.rootStore.workspaceSecretStore.activate(this.workspace.id)

    await this.rootStore.translationStore.fetchAll(this.workspace.id)

    await this.editor.activate(this.workspace)
  }

  @action.bound
  onSaved() {
    this.rootStore.flashMessageStore
      .create({ message: 'Workspace saved!', type: 'success' })
      .autoDismiss()
  }

  @action.bound
  onSecretSaved() {
    this.rootStore.flashMessageStore
      .create({ message: 'Workspace secret saved!', type: 'success' })
      .autoDismiss()
  }

  @action.bound
  onSecretDeleted() {
    this.rootStore.flashMessageStore
      .create({ message: 'Workspace secret deleted!', type: 'success' })
      .autoDismiss()
  }
}
