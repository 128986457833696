import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import {
  FormContainer as Form,
  InputContainer as Input,
  SelectContainer as Select,
} from 'legend-builder/components/ContainerControls'
import NumericInput from 'components/NumericInput'
import RequirePerms from 'iam/containers/RequirePerms'
import {
  InputControl,
  Label,
  Help,
  FormGroup,
  CheckboxControl,
} from 'legend-builder/components/Controls'
import styles from './EditUserModal.m.sass'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'

@observer
export default class EditUserModal extends React.Component {
  componentDidMount() {
    this.store.hide()
  }

  get store() {
    return this.props.store
  }

  render() {
    return (
      <Modal
        onRequestClose={() => this.store.hide()}
        isOpened={this.store.showing}
        position="top"
      >
        <Dialog>
          <Form onSubmit={this.store.save.bind(this.store)}>
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent>{this.renderEditor()}</DialogContent>
            <DialogFooter style={{ marginTop: -20 }}>
              <DialogButton onClick={() => this.store.hide()}>
                Cancel
              </DialogButton>
              <DialogButton submit primary>
                Save
              </DialogButton>
            </DialogFooter>
          </Form>
        </Dialog>
      </Modal>
    )
  }

  renderEditor() {
    const store = this.store
    const { form } = store
    if (!store.member) {
      return null
    }

    return (
      <div>
        <Input model={form} field="givenName" label="First name" />
        <Input model={form} field="familyName" label="Last name" />
        <RequirePerms allOf={['ADMIN_USER_READ']}>
          <Input
            type="email"
            model={form}
            field="email"
            label="Email address"
          />
          <Input type="phone" model={form} field="phone" label="Phone number" />
        </RequirePerms>
        {store.rootStore.authStore.user.platformAdmin && (
          <Select
            model={form}
            field="userType"
            label="User type"
            items={[
              ['USER', 'User'],
              ['PRO', 'Pro'],
            ]}
          />
        )}
        {store.member.workspace.features.tos.enabled && (
          <Input
            model={form}
            field="approvedTermsVersion"
            label="Approved terms version"
          />
        )}
        <Input
          type="number"
          min={0}
          model={form}
          field="jobCapacity"
          label={`Global Job Capacity`}
          help={`The maximum amount of "In Progress" or "Idle" jobs a ${T(
            'Web.Common.Provider',
            'Pro'
          )} can have in all workspaces (0 = unlimited)`}
        />
        <Input
          type="number"
          min={0}
          model={form}
          field="maxConcurrentProviderJobs"
          label={`Workspace Job Capacity`}
          help={`How many concurrent jobs per workspace can a user have where they are the ${T(
            'Web.Common.Provider',
            'Pro'
          )}? (0 = unlimited)`}
        />
        <Input model={form} field="referrer" label="Referrer" />
        {store.inputFields.map((field, index) => (
          <FormGroup key={index}>
            <Label>{field.title}</Label>
            {this.memberMetadataField(field, store)}
          </FormGroup>
        ))}
      </div>
    )
  }

  memberMetadataField(field, store) {
    const value = store.form.memberMetadata[field.id]
    switch (field.type) {
      case 'NUMERIC':
        return (
          <>
            <NumericInput
              precision={2}
              min={0}
              step={1}
              value={value}
              disabled={!field.permissions.includes('ADMIN_WRITE')}
              onChange={(v) => store.onMetadataChange(field.id, v)}
            >
              {(inputProps, actions) => (
                <InputControl
                  onKeyPress={actions.keyPressPreventNonNumeric}
                  type="text"
                  {...inputProps}
                />
              )}
            </NumericInput>
            <Help>{field.description}</Help>
          </>
        )
      case 'TEXT':
        return (
          <>
            <InputControl
              type="text"
              value={value || ''}
              disabled={!field.permissions.includes('ADMIN_WRITE')}
              onChange={(e) => store.onMetadataChange(field.id, e.target.value)}
            />
            <Help>{field.description}</Help>
          </>
        )
      case 'BOOL':
        return (
          <div className={styles.checkboxContainer}>
            <CheckboxControl
              checked={value}
              disabled={!field.permissions.includes('ADMIN_WRITE')}
              onChange={(e) => store.onMetadataChange(field.id, !value)}
            />
            <Help>{field.description}</Help>
          </div>
        )
      default:
        return <></>
    }
  }
}
